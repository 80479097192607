import { FC, Fragment } from "react";
import { CircularProgress, Divider, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { IDataSource, IDataSourceWithConnected } from "src/interfaces/IData";
import { ConnectorCard } from "src/components/ConnectorsComponent/ConnectorCard";
import { ConnectorStepper } from "../ConnectorStepper";
import { IDictionary } from "src/interfaces/IDictionary";
import { DataSourceCategory } from "src/enums/dataSourceCategory";

interface ISelectDataChannelsStep {
    isLoading: boolean;
    currentService: string[];
    activeStep: number;
    isConnectorsConncted: boolean;
    preparedDataSources: IDataSourceWithConnected[];
    addChannelHandler: (dataSource: IDataSource) => void;
    removeChannelHandler: (dataSource: IDataSourceWithConnected) => void;
    handleNext: () => void;
    handleBack: () => void;
    handleStep: (step: number) => void;
}

export const SelectDataChannelsStep: FC<ISelectDataChannelsStep> = ({
    isLoading,
    preparedDataSources,
    addChannelHandler,
    removeChannelHandler,
    currentService,
    activeStep,
    isConnectorsConncted,
    handleNext,
    handleBack,
    handleStep,
}) => {
    const dataSourceCategories = [DataSourceCategory.ECOMMERCE, DataSourceCategory.ADVERTISING, DataSourceCategory.ANALYTICS];

    const updateDataSourceGroup: IDictionary<IDataSourceWithConnected[]> = preparedDataSources.length
        ? dataSourceCategories.reduce((acc, category) => {
              acc[category] = preparedDataSources.filter((dataSource) => dataSource.categories.includes(category));
              return acc;
          }, {} as IDictionary<IDataSourceWithConnected[]>)
        : {};

    return (
        <Fragment>
            <Stack textAlign="center" spacing={1}>
                <Typography variant="h2">First, tell us which data sources you use!</Typography>
                <Typography variant="body1">
                    Select all of your e-commerce, advertising, and analytics partners. You will securely authorize each
                    channel in the next steps.
                </Typography>
            </Stack>
            <Stack gap={4}>
                <ConnectorStepper
                    activeStep={activeStep}
                    backBtnDisabled={activeStep === 0 || !isConnectorsConncted}
                    continueBtnDisabled={activeStep === 0 && !isConnectorsConncted}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleStep={handleStep}
                />
                <Stack gap={1} alignSelf="center" width="640px">
                    <Stack width="100%" gap={4}>
                        {isLoading ? (
                            <Stack justifyContent="center" direction="row" gap={1} mt={1}>
                                <CircularProgress size={24} disableShrink /> <Typography>Fetching Data...</Typography>
                            </Stack>
                        ) : (
                            dataSourceCategories.map((dataSourceCategory) => {
                                return (
                                    <Stack key={dataSourceCategory}>
                                        <Stack gap={1}>
                                            <Typography variant="h6">{dataSourceCategory}</Typography>
                                            <Divider variant="fullWidth" sx={{ height: "2px", width: "100%" }} />
                                        </Stack>
                                        {updateDataSourceGroup[dataSourceCategory]?.map((dataSource) => (
                                            <ConnectorCard
                                                key={dataSource.programmaticName}
                                                dataSource={dataSource}
                                                addChannelHandler={addChannelHandler}
                                                removeChannelHandler={removeChannelHandler}
                                                currentService={currentService}
                                            />
                                        ))}
                                    </Stack>
                                );
                            })
                        )}
                    </Stack>
                </Stack>
            </Stack>
        </Fragment>
    );
};
