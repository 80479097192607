export enum SubscriptionStatus {
    ACTIVE = "Active",
    INACTIVE = "Inactive",
    INCOMPLETE = "Incomplete",
    INCOMPLETE_EXPIRED = "Incomplete Expired",
    CANCELED = "Canceled",
    UNPAID = "Unpaid",
    PAST_DUE = "Past Due",
    TRIALING = "Trialing",
}
