import { GlobalStyles, Step, StepLabel, Stepper, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import welcomeImage from "src/assets/Prescient-Onboarding-Illustration 1.png";
import { PageHeader } from "src/components/PageHeader/PageHeader.v2";
import { CustomDialog } from "src/components/core/CustomDialog/CustomDialog";
import { CONNECTORS_PATH } from "src/consts/path/path";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import { getActiveConnectorStep } from "src/services/onboardingPage/onboardingPage";

const steps = [
    {
        label: "E-commerce",
        description: "Link Shopify, Amazon, etc., for modeling",
    },
    {
        label: "Advertising",
        description: "Connect all ad platforms for full view",
    },
    {
        label: "Analytics",
        description: "Google Analytics 4 adds context beyond ads",
    },
];

export const OnboardingAdminHomePage = ({ showPageHeader = false }: { showPageHeader?: boolean }) => {
    const navigate = useNavigate();

    const connectors = useSelector(connectorsSelector);
    const currentOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    let orgOnboardingState = [false, false, false];

    if (currentOrg?.onboardingState) {
        const { eCommerce, paidMarketing, webAnalytics } = currentOrg.onboardingState;
        orgOnboardingState = [eCommerce === "complete", paidMarketing === "complete", webAnalytics === "complete"];
    }

    const initialStep = getActiveConnectorStep(connectors) - 1; // due 3 steps, -1
    const completedSteps = orgOnboardingState.reduce((acc, step, index) => {
        if (step === true) {
            acc.push(index);
        }
        return acc;
    }, [] as number[]);

    const gotoConnectorPage = () => {
        navigate(`/org/${currentOrg.code}${CONNECTORS_PATH}`);
    };

    return (
        <main>
            <GlobalStyles styles={{ body: { backgroundColor: "#ffffff" } }} />
            {showPageHeader && <PageHeader pageHeading="Home" showBreadcrumbs={false} />}
            <Stack
                justifyContent="center"
                sx={{ padding: "48px 153px 48px" }}
                direction="row"
                spacing={2}
                flexWrap="wrap-reverse"
            >
                <Stack
                    direction="row"
                    gap={5}
                    sx={(theme) => ({
                        [theme.breakpoints.down("sm")]: {
                            flexDirection: "column-reverse",
                        },
                    })}
                >
                    <CustomDialog
                        title={
                            <>
                                Welcome to Prescient!
                                <br /> Let’s begin by connecting all of your marketing channels.
                            </>
                        }
                        instanceSlot={
                            <>
                                <Typography>
                                    We’ll start by helping you connect your Ecommerce, Advertising, and Google Analytics
                                    so that we can set up your Prescient Dashboard with MMM metrics.
                                </Typography>
                                <Stepper
                                    sx={{
                                        " .MuiStepConnector-line": {
                                            display: "none",
                                        },
                                        gap: "8px",
                                    }}
                                    activeStep={initialStep}
                                    orientation="vertical"
                                    nonLinear={true}
                                >
                                    {steps.map((step, index) => (
                                        <Step
                                            expanded={true}
                                            key={step.label}
                                            completed={completedSteps.includes(index)}
                                        >
                                            <StepLabel sx={{ padding: 0 }}>
                                                <Typography variant="body2"> {step.label}</Typography>{" "}
                                                <Typography variant="caption">{step.description}</Typography>
                                            </StepLabel>
                                        </Step>
                                    ))}
                                </Stepper>
                            </>
                        }
                        actions={{
                            1: {
                                label: "Connect Channels",
                                onClick: gotoConnectorPage,
                                style: {
                                    width: "100%",
                                    "& .MuiButtonBase-root": {
                                        width: "100%",
                                    },
                                },
                            },
                        }}
                        closeIcon={false}
                    />
                    <img
                        style={{ alignSelf: "center" }}
                        src={welcomeImage}
                        alt="Welcome to Prescient"
                        width={"411px"}
                        height={"411px"}
                    />
                </Stack>
            </Stack>
        </main>
    );
};
