import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";

import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import { Box, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import {
    useCreateCompanyCampaignsMutation,
    useDeleteCompanyCampaignsMutation,
    useLazyGetCompanyCampaignsQuery,
    useLazyGetCampaignsForInclusionsQuery,
} from "src/reduxState/apis/campaignInclusionApi";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import { CampaignInclusionTable } from "src/components/CampaignInclusions/CampaignInclusionTable/CampaignInclusionTable";
import { ICampaignForInclusion, ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";
import Loader from "src/components/core/Loader/Loader";
import { ICompanyCampaign } from "src/interfaces/entities/ICompanyCampaign";
import { CustomModal } from "src/components/CustomModal/CustomModal";
import { IDictionary } from "src/interfaces/IDictionary";
import { dataSourcesByProgrammaticNameSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import {
    getChipsValues,
    getFilteredCampaignInclusionData,
    sortedCampaigns,
} from "src/services/campaignInclusion/campaignInclusion";
import { capitalizeFirstLetter, toCamelCase } from "src/services/utils";

const useStyles = () => ({
    boxStyle: css({
        border: "1px solid rgba(0, 0, 0, 0.23)",
        padding: "30px 30px 50px",
        borderRadius: "5px",
        backgroundColor: "white",
        color: "#000000",
    }),
    heading1Style: css({ margin: 0, color: "inherit", fontSize: "28px" }),
});

export const CampaignInclusion: React.FC = () => {
    const classes = useStyles();
    const { enqueueSnackbar } = useSnackbar();

    const dataSources = useSelector(dataSourcesByProgrammaticNameSelector);
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);

    const [
        getCampaignsForInclusions,
        {
            data: campaignsForInclusions = [],
            isLoading: campaignInclusionLoading,
            isFetching: campaignInclusionFetching,
        },
    ] = useLazyGetCampaignsForInclusionsQuery();
    const [createCompanyCampaigns, { isLoading: isCreateLoading }] = useCreateCompanyCampaignsMutation();
    const [deleteCompanyCampaigns, { isLoading: isDeleteLoading }] = useDeleteCompanyCampaignsMutation();
    const [getCompanyCampaigns, { isLoading: companyCampaignsLoading, isFetching: companyCampaignsFetching }] =
        useLazyGetCompanyCampaignsQuery();

    const [unMappedCampaigns, setUnMappedCampaigns] = useState<ICampaignForInclusionWithMap[]>([]);
    const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);
    const [selectCampaign, setSelectCampaign] = useState<ICampaignForInclusionWithMap | null>(null);

    const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<number>(0);

    useEffect(() => {
        if (!currentCompany?.id) {
            return;
        }
        Promise.all([
            getCompanyCampaigns({ orgId: currentCompany.id }).unwrap(),
            getCampaignsForInclusions({
                orgId: currentCompany.id,
            }).unwrap(),
        ])
            .then((res: [ICompanyCampaign[], ICampaignForInclusion[]]) => {
                const companyCampaigns = res[0];
                const campaignsForInclusions = res[1];
                const currentCompanyCampaignIds = new Set(companyCampaigns.map((c) => c.campaignId.toString()));

                const { currentCompanyCampaigns, allDataSetInCompanyCampaigns } = campaignsForInclusions.reduce(
                    (acc, campaignForInclusions) => {
                        const findCampaign = companyCampaigns.find(
                            (c) => c.campaignId.toString() === campaignForInclusions.campaignId,
                        );
                        const isInCompanyCampaigns = currentCompanyCampaignIds.has(campaignForInclusions.campaignId);

                        // If campaign is manually set and included in company campaigns then add it to Inclusions else add it to Exclusions without any condition
                        if (findCampaign?.manuallySet && campaignForInclusions.isIncluded && isInCompanyCampaigns) {
                            acc.currentCompanyCampaigns.push({
                                ...campaignForInclusions,
                                manuallySet: findCampaign.manuallySet,
                                companyCampaignId: findCampaign.companyCampaignId.toString(),
                                createdAt: findCampaign.createdAt,
                            });
                        } else if (!campaignForInclusions.isIncluded) {
                            acc.allDataSetInCompanyCampaigns.push({ ...campaignForInclusions, manuallySet: false });
                        }

                        return acc;
                    },
                    { currentCompanyCampaigns: [], allDataSetInCompanyCampaigns: [] } as IDictionary<
                        ICampaignForInclusionWithMap[]
                    >,
                );
                setUnMappedCampaigns(sortedCampaigns([...currentCompanyCampaigns, ...allDataSetInCompanyCampaigns]));
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar("Something went wrong!", {
                    id: uniqueId(),
                    variant: "error",
                });
            });
    }, [currentCompany?.id]);

    const closeDeleteModal = () => {
        setIsOpenDeleteModal(false);
        setSelectCampaign(null);
    };

    const deleteCompanyCampaignAction = async (selectCampaign: ICampaignForInclusionWithMap) => {
        if (selectCampaign.companyCampaignId && currentCompany?.id) {
            await deleteCompanyCampaigns({
                orgId: currentCompany.id,
                companyCampaignId: selectCampaign.companyCampaignId,
            })
                .then(() => {
                    setUnMappedCampaigns((unMappedCampaigns) => {
                        const updatedCampaigns = unMappedCampaigns.map((c) =>
                            c.campaignId === selectCampaign.campaignId
                                ? { ...c, isIncluded: false, manuallySet: false, companyCampaignId: "" }
                                : c,
                        );
                        return sortedCampaigns(updatedCampaigns);
                    });
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                })
                .finally(() => {
                    closeDeleteModal();
                });
        }
    };

    const campaignInclusionsBtnAction = async (selectedRow: ICampaignForInclusionWithMap) => {
        if (selectedRow.manuallySet && selectedRow.companyCampaignId) {
            setIsOpenDeleteModal(true);
            setSelectCampaign(selectedRow);
        } else {
            if (!currentCompany?.id) {
                return;
            }
            await createCompanyCampaigns({
                orgId: currentCompany.id,
                body: [selectedRow.campaignId],
            })
                .unwrap()
                .then((res) => {
                    const updatedCampaigns = unMappedCampaigns.map((c) =>
                        c.campaignId === selectedRow.campaignId
                            ? {
                                  ...c,
                                  manuallySet: true,
                                  companyCampaignId: res.data[0].id,
                                  createdAt: res.data[0].attributes.created_at,
                              }
                            : c,
                    );
                    setUnMappedCampaigns(sortedCampaigns(updatedCampaigns));
                })
                .catch(() => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                });
        }
    };

    const bulkCampaignSaveBtnAction = async (
        addCampaigns: ICampaignForInclusionWithMap[],
        removeCampaigns: ICampaignForInclusionWithMap[],
    ) => {
        const addCampaignIds = addCampaigns.map((c) => c.campaignId);
        const removeCampaignIds = removeCampaigns.map((c) => c.campaignId);

        if (removeCampaignIds.length) {
            await removeCampaigns.forEach((c) => {
                deleteCompanyCampaignAction(c);
            });
        }

        if (addCampaignIds.length && currentCompany?.id) {
            await createCompanyCampaigns({
                orgId: currentCompany.id,
                body: addCampaignIds,
            })
                .unwrap()
                .then((res: any) => {
                    setUnMappedCampaigns((unMappedCampaigns) => {
                        const updatedCampaigns = unMappedCampaigns.map((uc) => {
                            if (addCampaignIds.includes(uc.campaignId)) {
                                const resWithId = res.data.map((r: any) => ({
                                    ...r.attributes,
                                    companyCampaignId: r.id,
                                }));
                                const findId = resWithId.filter((r: any) => r.campaign_id === uc.campaignId);
                                return {
                                    ...uc,
                                    manuallySet: true,
                                    companyCampaignId: findId[0]?.companyCampaignId,
                                    createdAt: findId[0].created_at,
                                };
                            } else {
                                return uc;
                            }
                        });
                        return sortedCampaigns(updatedCampaigns);
                    });
                });
        }

        setSelectedRows([]);
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const handleChangeFilterValue = (filterValue: number) => {
        setSelectedRows([]);
        setFilter(filterValue);
    };

    const finalCampaigns: ICampaignForInclusionWithMap[] = getFilteredCampaignInclusionData(
        unMappedCampaigns,
        search,
        filter,
    );

    const dataForTable = finalCampaigns.map((c) => {
        return {
            ...c,
            cleanChannelName: c.channel
                ? dataSources[c.channel.toLowerCase()]?.name || capitalizeFirstLetter(toCamelCase(c.channel))
                : "",
        };
    });

    const isLoading =
        campaignInclusionLoading || companyCampaignsLoading || campaignInclusionFetching || companyCampaignsFetching;

    return (
        <Stack gap="20px" sx={{ marginTop: 3 }}>
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <h1 className={classes.heading1Style}>Campaign Inclusions</h1>
                    </Box>
                    <Typography color="text.secondary">
                        Select and Save which campaigns you want to include in modeling and reporting for{" "}
                        <b>{currentCompany.displayName}</b>
                    </Typography>
                    {campaignsForInclusions && (
                        <Stack>
                            <SubHeader
                                onSearchChange={handleSearchChange}
                                title="campaign(s)"
                                chosenItemsLength={0}
                                chipsValues={getChipsValues(unMappedCampaigns)}
                                itemsLength={finalCampaigns?.length || 0}
                                isEventPerform={false}
                                multiple={false}
                                onChipsChangeSingle={handleChangeFilterValue}
                            />
                            <CampaignInclusionTable
                                data={dataForTable}
                                campaignInclusionsBtnAction={campaignInclusionsBtnAction}
                                isLoading={isCreateLoading || isDeleteLoading}
                                bulkCampaignSaveBtnAction={bulkCampaignSaveBtnAction}
                                setSelectedRows={setSelectedRows}
                                selectedRows={selectedRows}
                            />
                        </Stack>
                    )}
                </>
            )}
            {selectCampaign && isOpenDeleteModal && (
                <CustomModal
                    title="Remove Campaign?"
                    subTitle=""
                    details={
                        <Typography
                            component="li"
                            variant="body2"
                            sx={{ textIndent: "unset!important", marginTop: "-20px" }}
                        >
                            Do you want to remove campaign <b>{selectCampaign.campaignName}</b> from inclusions on{" "}
                            {currentCompany.displayName}?
                        </Typography>
                    }
                    buttonName="Delete"
                    handleAction={() => deleteCompanyCampaignAction(selectCampaign)}
                    selectedConnector={selectCampaign}
                    closeModal={closeDeleteModal}
                    disableBtn={isDeleteLoading}
                    type="error"
                />
            )}
        </Stack>
    );
};
