import React from "react";
import { Button, Tooltip, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { addDays, format, isPast } from "date-fns";
import { toDate } from "date-fns-tz";

import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";
import { capitalizeFirstLetter, formatValue, handleClickWithTextSelectionCheck } from "../../../services/utils";
import { FORMATS } from "../../../enums/Formats";
import { IScenario } from "../../../interfaces/entities/IScenario";
import { OptimizationTab, TIMEFRAME_RADIO_OPTIONS } from "../../../consts/optimizationPage/optimizationPage";
import { cursor } from "../../../assets/styles/commonStyle";

interface IOptimizationRow {
    optimization: IScenario;
    currentTab?: OptimizationTab;
    disableTracking: boolean;

    onDeleteOptimization: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
    onEditOptimization: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
    handleTracking: (scenario: IScenario) => void;
    onArchiveScenario: (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => void;
}

export const OptimizationRow: React.FC<IOptimizationRow> = ({
    optimization,
    currentTab,
    onDeleteOptimization,
    onEditOptimization,
    onArchiveScenario,
    handleTracking,
    disableTracking = false,
}) => {
    const classes = useStylesForRowInTable();

    const todayDate = toDate(optimization.createdAt || new Date());
    const isScenarioExpired = isPast(addDays(todayDate, optimization.forecastTimeframe));

    const getTimeframeValue = () =>
        TIMEFRAME_RADIO_OPTIONS.find((o) => o.value === optimization.forecastTimeframe)?.label ||
        `${optimization.forecastTimeframe} Days`;

    const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleTracking(optimization);
    };

    const trackingDataBlockPeriod = 172800000; // 2 days
    const opCreatedAt = new Date(optimization.createdAt || 0);
    let trackingEnabled = +new Date() - +opCreatedAt > trackingDataBlockPeriod;
    const anyNullForecasts = optimization.scenarioCampaigns?.filter((a) => a.forecastedRevenue === null) || [];
    const optimizationTrackingDisabledDueToError =
        optimization.jobStatus === "completed" && anyNullForecasts.length > 0;

    let trackingTooltip = "";

    if (optimizationTrackingDisabledDueToError) {
        trackingTooltip = "Tracking is not available as there was an error running this optimization.";
        trackingEnabled = false;
    } else if (!trackingEnabled) {
        trackingTooltip = "Your tracking will be available within the next 48 hours";
    }

    let actionArray = [{ title: "Delete", onClick: onDeleteOptimization, data: optimization, icon: "trashIcon" }];

    if (currentTab === OptimizationTab.Scenarios) {
        actionArray = [
            ...actionArray,
            { title: "Archive", onClick: onArchiveScenario, data: optimization, icon: "archiveIcon" },
        ];
    }

    if (currentTab === OptimizationTab.Archived) {
        actionArray = [
            ...actionArray,
            {
                title: "Archive Restore",
                onClick: onArchiveScenario,
                data: optimization,
                icon: "archiveRestoreIcon",
            },
        ];
    }

    return (
        <TableRow
            className={`${classes.bodyRow} ${cursor("pointer")}`}
            hover
            tabIndex={-1}
            onClick={(e) => handleClickWithTextSelectionCheck(onEditOptimization)(e, optimization)}
        >
            <TableCell align="left" className={classes.tableCell}>
                <Typography variant="body2" fontSize="15px">
                    {optimization.name}
                </Typography>
                <Typography variant="body2" fontSize="15px" sx={{ opacity: 0.5 }}>
                    {optimization.description}
                </Typography>
            </TableCell>
            <TableCell>
                <Typography variant="body2" fontSize="15px">
                    {optimization.creatorName}
                </Typography>
                <Typography variant="body2" fontSize="15px" sx={{ opacity: 0.5 }}>
                    {optimization.createdAt && format(toDate(optimization.createdAt), "M/d/yyyy 'at' hh:mm a")}
                </Typography>
            </TableCell>
            <TableCell className={classes.tableCell}>Fixed Budget</TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {formatValue(Number(optimization.budget), FORMATS.DOLLAR, 0)}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {optimization.scenarioLevel} campaign{(optimization.scenarioLevel || 0) > 1 ? "s" : ""}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                {getTimeframeValue()}
            </TableCell>
            <TableCell align="right" className={classes.tableCell}>
                <Tooltip arrow title={trackingTooltip}>
                    <Typography component="span" sx={{ cursor: "not-allowed" }}>
                        {optimizationTrackingDisabledDueToError ? (
                            <Button
                                disabled={disableTracking || !trackingEnabled}
                                sx={{ textTransform: "initial" }}
                                variant={"contained"}
                                onClick={(e) => handleClick(e)}
                            >
                                Tracking Not Available
                            </Button>
                        ) : isScenarioExpired ? (
                            <Button
                                disabled={disableTracking || !trackingEnabled}
                                sx={{ textTransform: "initial", color: "black" }}
                                variant={"outlined"}
                                onClick={(e) => handleClick(e)}
                            >
                                Tracking Complete
                            </Button>
                        ) : (
                            <Button
                                disabled={disableTracking || !trackingEnabled}
                                sx={{ textTransform: "initial" }}
                                variant={"contained"}
                                onClick={(e) => handleClick(e)}
                            >
                                See Tracking
                            </Button>
                        )}
                    </Typography>
                </Tooltip>
            </TableCell>
            <TableActionCell actionIcons={actionArray} />
        </TableRow>
    );
};
