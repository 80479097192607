import React, { useState } from "react";
import FormHelperText from "@mui/material/FormHelperText";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";

import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { TextField, TextFieldProps } from "@mui/material";

interface IPasswordProps {
    classes?: any;
    helperText?: React.ReactNode;
}

export const Password = ({
    error,
    helperText,
    id,
    classes = {},
    fullWidth = true,
    ...rest
}: IPasswordProps & TextFieldProps) => {
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const getType = () => {
        return showPassword ? "text" : "password";
    };
    const inputId: string = id || `field-${name}`;

    const inputProps = {
        ...rest,
        error,
        id: inputId,
        title: "",
        type: getType(),
    };

    return (
        <FormControl>
            <TextField
                InputProps={{
                    endAdornment: (
                        <InputAdornment position="end">
                            <IconButton
                                aria-label="toggle password visibility"
                                onClick={togglePassword}
                                edge="end"
                                size="large"
                            >
                                {showPassword ? (
                                    <SVGIconRenderer strokeColor="rgba(0, 0, 0, 0.54)" icon="eyeIcon" />
                                ) : (
                                    <SVGIconRenderer strokeColor="rgba(0, 0, 0, 0.54)" icon="eyeOffIcon" />
                                )}
                            </IconButton>
                        </InputAdornment>
                    ),
                }}
                {...inputProps}
            />
            {helperText && (
                <FormHelperText error={error} id="password-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
