import * as React from "react";
import { object, string } from "yup";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { HeaderForm } from "../core/Form/HeaderForm";
import { Field } from "../core/Field/Field";
import { DropDown } from "../core/DropDown/DropDown";
import { HELP_PROBLEMS_TYPE, IHelpPageValues } from "../../containers/Help/HelpPage";
import { withRouter, IWithRouterProps } from "../ComponentWithRouterProp/ComponentWithRouterProp";
import { fieldClass } from "../../layouts/SimpleForm";
import { FooterForm } from "../core/Form/FooterForm";
import { LOGIN_PATH } from "../../consts/path/path";

interface IHelpFormProps extends FormikConfig<IHelpPageValues>, IWithRouterProps {}

const HelpForm: React.FC<IHelpFormProps> = (props) => {
    const { ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                email: string().email("Please, enter a valid e-mail address").required("E-mail is required"),
                message: string().required("Please, describe your problem"),
            })}
            {...rest}
        >
            {({
                errors,
                handleChange,
                touched,
                values,
                isSubmitting,
                setFieldValue,
                setFieldTouched,
            }: FormikProps<IHelpPageValues>) => {
                const hasEmailError = Boolean(touched.email && errors.email);
                const hasProblemError = Boolean(touched.problem && errors.problem);
                const hasMessageerror = Boolean(touched.message && errors.message);
                const hasError = hasEmailError || hasProblemError || hasMessageerror;

                return (
                    <Form>
                        <HeaderForm title="Help" close onClose={() => props.navigate(LOGIN_PATH)} />
                        <Field
                            id="email"
                            name="email"
                            label="E-mail"
                            value={values.email}
                            error={hasError}
                            helperText={hasError ? errors.email : ""}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("email")}
                            placeholder="youremail@domain.com"
                            fullWidth
                            classes={{
                                root: fieldClass,
                            }}
                        />
                        <DropDown
                            name="problem"
                            label="Problem"
                            options={[
                                { label: HELP_PROBLEMS_TYPE.login, value: HELP_PROBLEMS_TYPE.login },
                                { label: HELP_PROBLEMS_TYPE.connectors, value: HELP_PROBLEMS_TYPE.connectors },
                                { label: HELP_PROBLEMS_TYPE.other, value: HELP_PROBLEMS_TYPE.other },
                            ]}
                            value={values.problem}
                            onChange={(e: React.ChangeEvent<HTMLSelectElement>) =>
                                setFieldValue("problem", e.target.value)
                            }
                            className={fieldClass}
                        />
                        <Field
                            id="message"
                            name="message"
                            label="Message"
                            multiline
                            value={values.message}
                            error={hasMessageerror}
                            helperText={hasMessageerror ? errors.message : null}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("message")}
                            placeholder="Please, describe your problem"
                            fullWidth
                            classes={{
                                root: fieldClass,
                            }}
                        />
                        <FooterForm
                            submitBtnProps={{
                                disabled: Boolean(
                                    isSubmitting ||
                                        hasMessageerror ||
                                        hasProblemError ||
                                        hasProblemError ||
                                        values.email === "",
                                ),
                                isLoading: isSubmitting,
                                text: "Send Message",
                            }}
                            cancelBtnProps={{
                                text: "Cancel",
                                onCancelBtnClick: () => props.navigate(LOGIN_PATH),
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withRouter(HelpForm);
