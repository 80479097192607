import React, { ReactElement } from "react";
import { Stack, Typography } from "@mui/material";

const InfoComponent = ({ info, value }: { info: string; value: string | ReactElement }) => {
    return (
        <Stack
            direction="row"
            gap="40px"
            justifyContent="space-between"
            color="#070707"
            sx={(theme) => ({
                [theme.breakpoints.down("md")]: {
                    flexDirection: "column",
                    gap: "2px",
                    justifyContent: "start",
                },
            })}
        >
            <Typography color="inherit" fontSize="12px" fontWeight="bold" width="100px">
                {info}
            </Typography>
            <Typography
                color="inherit"
                width="170px"
                fontSize="15px"
                sx={(theme) => ({
                    wordWrap: "break-word",
                    [theme.breakpoints.down("md")]: {
                        width: "100%",
                    },
                })}
            >
                {value || "-"}
            </Typography>
        </Stack>
    );
};

export default InfoComponent;
