import find from "lodash/find";
import groupBy from "lodash/groupBy";
import inRange from "lodash/inRange";
import startCase from "lodash/startCase";
import queryString from "query-string";

import {
    EMAIL_ATTRIBUTION_HEADERS,
    EMAIL_PERFORMANCE_FILTER_LIST,
    SAMPLE_IN_EMAIL_METRIC_ATTRIBUTION_TABLE,
} from "../../consts/emailPage/emailPage";
import { CAMPARE_CONDITIONS } from "../../consts/performancePaidPage/performancePaidPage";
import { compare, getFilterChipValues, getFormattedMetricQuery, stringToCapitalize } from "../utils";
import { IEmail } from "../../interfaces/entities/IEmail";
import {
    calculateSum,
    formatArrayWithCamelCase,
    getDataSourceName,
} from "../performancePage/performancePage";
import { IDictionary } from "../../interfaces/IDictionary";
import { IEmailPercentageRows, IEmailPercentageTableData } from "../../interfaces/emailDetails/IEmailPercentage";

export const calculateRate = (val1: number, val2: number) => {
    if (val1 && val2) {
        return (val1 / val2) * 100;
    }
    return 0;
};

export const formatEmailResponse = (emailResponse: IEmail[]) => {
    const allValidEmailColumns = [
        "channelReportedRevenue",
        "emailSends",
        "emailOpen",
        "emailOpenUnique",
        "emailClicks",
        "emailClicksUnique",
        "emailBounces",
        "emailUnsubscribes",
        "conversions",
        "reportedOrders",
        "openRate",
        "clickRate",
        "bounceRate",
        "unsubscribeRate",
        "rpc",
        "aov",
    ];

    const updatedEmailResponse = formatArrayWithCamelCase(emailResponse).map((campaign: any) => {
        const presentMetrics = Object.keys(campaign);
        let obj = { ...campaign };
        allValidEmailColumns.map((f) => {
            if (presentMetrics.includes(f)) {
                obj = { ...obj, [f]: campaign[f] };
            } else {
                obj = { ...obj, [f]: 0 };
            }
            obj.openRate = calculateRate(obj.emailOpen, obj.emailSends);
            obj.clickRate = calculateRate(obj.emailClicks, obj.emailSends);
            obj.bounceRate = calculateRate(obj.emailBounces, obj.emailSends);
            obj.unsubscribeRate = calculateRate(obj.emailUnsubscribes, obj.emailSends);
            obj.rpc = obj.channelReportedRevenue && obj.emailClicks ? obj.channelReportedRevenue / obj.emailClicks : 0;
            obj.aov = obj.channelReportedRevenue && obj.conversions ? obj.channelReportedRevenue / obj.conversions : 0;
        });
        return obj;
    });
    return updatedEmailResponse;
};

export const getTotalAmountForEmail = (array: IEmail[]) => {
    const objectWithTotalsValues: IEmail = {
        campaignId: "",
        campaignName: "Total",
    };
    if (array.length) {
        const keys = Object.keys(array[0]);
        const excludedKeys = ["campaignId", "campaignName", "connectorName", "type"];

        keys.forEach((k) => {
            if (!excludedKeys.includes(k)) {
                objectWithTotalsValues[k] = +array.reduce(
                    (previousValue, currentValue) => previousValue + (currentValue[k] || 0),
                    0,
                );
            }
        });
        return objectWithTotalsValues;
    }
    return objectWithTotalsValues;
};

export const percentageCalculationForEmail = ({ currentRow, pastRow }: { currentRow: any; pastRow: any }) => {
    let copyRow = currentRow ? { ...currentRow } : { ...pastRow };

    const keys = Object.keys(copyRow);
    const excludeCellFromPercentage = ["campaignId", "campaignName", "status", "startDate", "endDate", "type"];

    keys.forEach((key) => {
        if (!excludeCellFromPercentage.includes(key)) {
            const percentages = +(((currentRow[key] - pastRow[key]) / pastRow[key]) * 100);

            if (isFinite(percentages)) {
                copyRow = {
                    ...copyRow,
                    [key]: percentages,
                };
            } else {
                copyRow = {
                    ...copyRow,
                    [key]: 100,
                };
            }
        }
    });
    return copyRow;
};

export const applyMetricFilter = (locationFilterObj: any, performanceData: any, HEADERS: any) => {
    // metric filter groupBy metric names with operation(<, >, =)
    let resultPerformanceData = [...performanceData];

    const metricArr = getFormattedMetricQuery(locationFilterObj);
    const groupedByMetricData = groupBy(metricArr, "metric");

    if (Object.keys(groupedByMetricData).length) {
        Object.keys(groupedByMetricData).map((filterD) => {
            const noEqualArr = groupedByMetricData[filterD].filter((d) => d?.condition !== "eq");
            const equalArr = groupedByMetricData[filterD].filter((d) => d?.condition === "eq");

            if (groupedByMetricData[filterD].length === 1) {
                const { metric, condition, value }: any = groupedByMetricData[filterD][0];
                resultPerformanceData = resultPerformanceData.filter((d: any) => {
                    const fixedData = HEADERS.find((h: { id: any }) => h.id === metric)?.fixed;
                    if (d[metric] || d[metric] === 0) {
                        return compare(
                            parseFloat(d[metric].toFixed(fixedData || 0)),
                            CAMPARE_CONDITIONS[condition],
                            parseFloat(value),
                        );
                    }
                });
            } else if (groupedByMetricData[filterD].length === 2) {
                if (noEqualArr.length === 1 && equalArr.length === 1) {
                    resultPerformanceData = resultPerformanceData.filter(
                        (data: { [x: string]: { toFixed: (arg0: number) => number } }) => {
                            if (noEqualArr[0] && equalArr[0]) {
                                return compare(
                                    data[noEqualArr[0].metric].toFixed(0),
                                    CAMPARE_CONDITIONS[noEqualArr[0].condition] + "=",
                                    Number(equalArr[0].value),
                                );
                            }
                        },
                    );
                } else if (noEqualArr.length === 2) {
                    resultPerformanceData = resultPerformanceData.filter((data: any) => {
                        const firstVl = noEqualArr[0];
                        const secondVal = noEqualArr[1];

                        let rangeResult;
                        if (firstVl?.condition === "lt") {
                            rangeResult = inRange(
                                data[firstVl.metric].toFixed(0),
                                Number(firstVl.value),
                                Number(secondVal?.value),
                            );
                        }
                        if (firstVl?.condition === "gt") {
                            rangeResult = inRange(
                                data[firstVl.metric].toFixed(0),
                                Number(secondVal?.value),
                                Number(firstVl.value),
                            );
                        }
                        return rangeResult;
                    });
                }
            } else if (groupedByMetricData[filterD].length === 3 && noEqualArr.length === 2 && equalArr.length === 1) {
                resultPerformanceData = resultPerformanceData.filter((data: any) => {
                    if (equalArr[0]) {
                        return compare(Number(data[equalArr[0].metric].toFixed(0)), "all", Number(equalArr[0].value));
                    }
                });
            }
        });
    }

    return resultPerformanceData;
};

export const applyCampaignNameFilter = (locationFilterObj: any, performanceData: any, FILTER_LIST: any) => {
    let resultPerformanceData = [...performanceData];

    if (locationFilterObj[FILTER_LIST[1].items[0].key]) {
        const containsArr = locationFilterObj[FILTER_LIST[1].items[0].key].split(",");
        containsArr.forEach((d: string) => {
            resultPerformanceData = resultPerformanceData.filter((data: any) => {
                return data.campaignName.toLowerCase().includes(d.toLowerCase());
            });
        });
    }

    if (locationFilterObj[FILTER_LIST[1].items[1].key]) {
        const containsArr = locationFilterObj[FILTER_LIST[1].items[1].key].split(",");
        containsArr.forEach((d: string) => {
            resultPerformanceData = resultPerformanceData.filter((data: any) => {
                return !data.campaignName.toLowerCase().includes(d.toLowerCase());
            });
        });
    }

    return resultPerformanceData;
};

export const getFilteredEmailPeformance = (performanceData: any, search: any, filterObj: any) => {
    if (performanceData && performanceData.length) {
        let resultPerformanceData = [...performanceData];

        if (filterObj && Object.keys(filterObj).length) {
            // metric filter(<, >, =)
            resultPerformanceData = applyMetricFilter(filterObj, performanceData, EMAIL_ATTRIBUTION_HEADERS);

            // type exclude filter
            if (filterObj[EMAIL_PERFORMANCE_FILTER_LIST[0].key]) {
                resultPerformanceData = resultPerformanceData.filter((data: any) => {
                    const fixSearch = `${data.type.toLowerCase()}`;
                    return !filterObj[EMAIL_PERFORMANCE_FILTER_LIST[0].key].includes(fixSearch.toLowerCase());
                });
            }

            // campaign name contains and not contains filter
            resultPerformanceData = applyCampaignNameFilter(
                filterObj,
                resultPerformanceData,
                EMAIL_PERFORMANCE_FILTER_LIST,
            );
        }

        if (search) {
            resultPerformanceData = resultPerformanceData.filter((data: any) => {
                const fixSearch = `${data.campaignName.toLowerCase()} ${data.connectorName.toLowerCase()}`;
                return fixSearch.includes(search.toLowerCase());
            });
        }
        return resultPerformanceData;
    }
    return [];
};

export const getComparisionEmailPerformanceData = (
    currentData: IEmail[],
    pastData: IEmail[],
    search: string,
    filterQueryParamsObj: queryString.ParsedQuery<string>,
    withoutFilter: boolean,
) => {
    let finalData: IEmailPercentageRows = {};
    let currentFilteredData: IEmail[] = [];
    let pastFilteredData: IEmail[] = [];

    if (currentData && pastData) {
        if (withoutFilter) {
            currentFilteredData = currentData;
            pastFilteredData = pastData;
        } else {
            currentFilteredData = getFilteredEmailPeformance(currentData, search, filterQueryParamsObj);
            pastFilteredData = getFilteredEmailPeformance(pastData, search, filterQueryParamsObj);
        }

        const allCampaignIds: Array<string | undefined | null> = [...currentFilteredData, ...pastFilteredData].map(
            (d) => d.campaignId,
        );

        const uniqueIds = [...new Set(allCampaignIds)];

        if (uniqueIds) {
            uniqueIds.map((campaignId: string | undefined | null) => {
                if (campaignId) {
                    const cd = currentFilteredData.find((c) => c.campaignId === campaignId);
                    const pd = pastFilteredData.find((c) => c.campaignId === campaignId);

                    const availableRow = cd || pd;
                    const dummyObj = {
                        ...availableRow,
                        ...SAMPLE_IN_EMAIL_METRIC_ATTRIBUTION_TABLE,
                    };

                    // if campaignId available in past data and not in current then 0 stored in current and vice versa
                    finalData = { ...finalData, [campaignId]: { past: pd || dummyObj, current: cd || dummyObj } };
                }
            });
        }
    }

    return {
        finalData,
        currentFilteredData,
        pastFilteredData,
    } as IEmailPercentageTableData;
};

export const getCheckBoxValue = (paramValue: string, normalValue: string) => {
    return paramValue ? paramValue.includes(normalValue) : false;
};

export const getConditionValue = (paramKey: string, paramValue: string) => {
    const paramValueArr = paramValue?.split(",");
    if (paramValueArr.length) {
        const cArr = paramValueArr.map((a) => ({
            conditionType: paramKey,
            conditionValue: a,
        }));
        return cArr;
    } else {
        return [];
    }
};

export const getEmailFilterList = (queryParamsObj: any) => {
    const { type_exclude, contains, not_contains } = queryParamsObj;
    const channelList = {
        id: EMAIL_PERFORMANCE_FILTER_LIST[0].key,
        title: EMAIL_PERFORMANCE_FILTER_LIST[0].title,
        key: EMAIL_PERFORMANCE_FILTER_LIST[0].key,
        chipCondition: EMAIL_PERFORMANCE_FILTER_LIST[0].condition,
        type: EMAIL_PERFORMANCE_FILTER_LIST[0].type,
        items: EMAIL_PERFORMANCE_FILTER_LIST[0].items.map((item: any) => {
            return {
                id: item.key,
                name: item.title,
                value: !getCheckBoxValue(type_exclude, item.key),
            };
        }),
        open: true,
    };

    let conditionInputList: Array<{
        conditionType: string;
        conditionValue: string;
    }> = [];

    if (contains) {
        conditionInputList = [
            ...getConditionValue(EMAIL_PERFORMANCE_FILTER_LIST[1].items[0].key, contains),
            ...conditionInputList,
        ];
    }
    if (not_contains) {
        conditionInputList = [
            ...getConditionValue(EMAIL_PERFORMANCE_FILTER_LIST[1].items[1].key, not_contains),
            ...conditionInputList,
        ];
    }

    const filterList = [
        {
            ...channelList,
        },
        {
            id: EMAIL_PERFORMANCE_FILTER_LIST[1].key,
            title: EMAIL_PERFORMANCE_FILTER_LIST[1].title,
            key: EMAIL_PERFORMANCE_FILTER_LIST[1].key,
            type: EMAIL_PERFORMANCE_FILTER_LIST[1].type,
            items: [
                ...conditionInputList,
                {
                    conditionType: "contains",
                    conditionValue: "",
                },
            ],
            open: !!conditionInputList.length,
        },
        {
            id: EMAIL_PERFORMANCE_FILTER_LIST[2].key,
            title: EMAIL_PERFORMANCE_FILTER_LIST[2].title,
            key: EMAIL_PERFORMANCE_FILTER_LIST[2].key,
            type: EMAIL_PERFORMANCE_FILTER_LIST[2].type,
            items: [
                {
                    metric: [
                        ...getFormattedMetricQuery(queryParamsObj),
                        {
                            metric: "",
                            condition: "",
                            value: "",
                        },
                    ],
                },
            ],
            open: !!getFormattedMetricQuery(queryParamsObj).length,
        },
    ];
    return filterList;
};

const mapMetricNames = () => {
    let mericObj = {};
    EMAIL_ATTRIBUTION_HEADERS.forEach((c) => {
        mericObj = { ...mericObj, [c.id]: c.label };
    });
    return mericObj;
};

export const METRIC_NAMES: { [key: string]: string } = mapMetricNames();

export const getEmailFilterChipList = (
    filterList: Array<{ items: any[]; key: string; title: any; chipCondition?: string | undefined }>,
) => {
    const queryParamsObj = queryString.parse(window.location.search);
    let finalChipData: any[] = [];
    const metricParam = getFormattedMetricQuery(queryParamsObj);
    filterList.forEach((list: { items: any[]; key: string; title: any; chipCondition?: string }) => {
        if (location.search.includes(list.key) && list.key !== "campaign_id") {
            finalChipData = [
                ...finalChipData,
                {
                    values: getFilterChipValues(list, list.key === EMAIL_PERFORMANCE_FILTER_LIST[0].key ? false : true),
                    label: list.title,
                    condition: list.chipCondition,
                    key: list.key,
                },
            ];
        }
    });
    if (queryParamsObj[EMAIL_PERFORMANCE_FILTER_LIST[1].items[0].key]) {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj[EMAIL_PERFORMANCE_FILTER_LIST[1].items[0].key],
                condition: EMAIL_PERFORMANCE_FILTER_LIST[1].items[0].condition,
                label: EMAIL_PERFORMANCE_FILTER_LIST[1].title,
                key: EMAIL_PERFORMANCE_FILTER_LIST[1].items[0].key,
            },
        ];
    }
    if (queryParamsObj[EMAIL_PERFORMANCE_FILTER_LIST[1].items[1].key]) {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj[EMAIL_PERFORMANCE_FILTER_LIST[1].items[1].key],
                condition: EMAIL_PERFORMANCE_FILTER_LIST[1].items[1].condition,
                label: EMAIL_PERFORMANCE_FILTER_LIST[1].title,
                key: EMAIL_PERFORMANCE_FILTER_LIST[1].items[1].key,
            },
        ];
    }
    if (metricParam.length) {
        metricParam.forEach((m) => {
            if (m) {
                const a = {
                    values: m.value,
                    condition: CAMPARE_CONDITIONS[m.condition],
                    label: METRIC_NAMES[m.metric],
                    key: `filter[${m.metric}][${m.condition}]`,
                    isArray: true,
                };
                finalChipData = [...finalChipData, a];
            }
        });
    }
    if (queryParamsObj.campaign_id && typeof queryParamsObj.campaign_id === "string") {
        finalChipData = [
            ...finalChipData,
            {
                values: queryParamsObj.campaign_id.replace(/,/g, ", "),
                condition: EMAIL_PERFORMANCE_FILTER_LIST[2].condition,
                label: EMAIL_PERFORMANCE_FILTER_LIST[2].title,
                key: EMAIL_PERFORMANCE_FILTER_LIST[2].key,
            },
        ];
    }
    return finalChipData.flat(1);
};

export const calculateSumOfMetrics = (data: any) => {
    const sumOfValuesObj: { [key: string]: number } = { emailOpen: 0, emailSend: 0, impressions: 0 };
    sumOfValuesObj.emailOpen = calculateSum("EMAIL_OPEN", data);
    sumOfValuesObj.emailSend = calculateSum("EMAIL_SENDS", data);
    sumOfValuesObj.emailClicks = calculateSum("EMAIL_CLICKS", data);
    sumOfValuesObj.emailBounces = calculateSum("EMAIL_BOUNCES", data);
    sumOfValuesObj.emailUnsubscribes = calculateSum("EMAIL_UNSUBSCRIBES", data);
    sumOfValuesObj.channelReportedRevenue = calculateSum("CHANNEL_REPORTED_REVENUE", data);
    sumOfValuesObj.conversions = calculateSum("CONVERSIONS", data);

    sumOfValuesObj[`Open Rate - Prescient`] = calculateRate(sumOfValuesObj.emailOpen, sumOfValuesObj.emailSend);
    sumOfValuesObj[`Click Rate - Prescient`] = calculateRate(sumOfValuesObj.emailClicks, sumOfValuesObj.emailSend);
    sumOfValuesObj[`Bounce Rate - Prescient`] = calculateRate(sumOfValuesObj.emailBounces, sumOfValuesObj.emailSend);
    sumOfValuesObj[`Unsubscribe Rate - Prescient`] = calculateRate(
        sumOfValuesObj.emailUnsubscribes,
        sumOfValuesObj.emailSend,
    );
    sumOfValuesObj["RPC - Prescient"] =
        sumOfValuesObj.channelReportedRevenue && sumOfValuesObj.emailClicks
            ? sumOfValuesObj.channelReportedRevenue / sumOfValuesObj.emailClicks
            : 0;
    sumOfValuesObj["AOV - Prescient"] =
        sumOfValuesObj.channelReportedRevenue && sumOfValuesObj.conversions
            ? sumOfValuesObj.channelReportedRevenue / sumOfValuesObj.conversions
            : 0;
    return sumOfValuesObj;
};

export const getUpdatedChartDataForCustomChartForEmail = (apiData: any, customValue: string[], source: string) => {
    const depedentMetrics = ["open_rate", "click_rate", "bounce_rate", "unsubscribe_rate"];
    const revenueDependMetrics = ["aov", "rpc"];
    const columnKey: IDictionary = {
        open_rate: "openRate",
        click_rate: "clickRate",
        bounce_rate: "bounceRate",
        unsubscribe_rate: "unsubscribeRate",
    };
    let data: any[] = [];
    let legendValues: { [key: string]: number } = {};
    const grpByMetricName = groupBy(apiData, "metricName");

    customValue.map((customVal) => {
        if (depedentMetrics.includes(customVal) || revenueDependMetrics.includes(customVal)) {
            const filteredData: any[] = Object.values(grpByMetricName)
                .flat(1)
                .filter((c: any) => c.connectorName === source);

            legendValues = {
                ...legendValues,
                ...calculateSumOfMetrics(filteredData),
            };
            const groupByDate = groupBy(filteredData, "date");
            Object.values(groupByDate).forEach((d) => {
                const emailSends = find(d, (n) => n.metricName === "EMAIL_SENDS");
                const reportedRevenue = find(d, (n) => n.metricName === "CHANNEL_REPORTED_REVENUE");

                const obj = {
                    open_rate: find(d, (n) => n.metricName === "EMAIL_OPEN"),
                    click_rate: find(d, (n) => n.metricName === "EMAIL_CLICKS"),
                    bounce_rate: find(d, (n) => n.metricName === "EMAIL_BOUNCES"),
                    unsubscribe_rate: find(d, (n) => n.metricName === "EMAIL_UNSUBSCRIBES"),
                };

                const reportedRevenueObj = {
                    conversions: find(d, (n) => n.metricName === "CONVERSIONS"),
                    emailClicks: obj.click_rate,
                };

                Object.entries(reportedRevenueObj).forEach(([key, value]) => {
                    if (revenueDependMetrics.includes(customVal) && value && reportedRevenue) {
                        const formattedKey = key === "conversions" ? "AOV" : "RPC";
                        data = [
                            ...data,
                            {
                                ...reportedRevenue,
                                metricName: formattedKey,
                                name: `${formattedKey} - Prescient`,
                                value: value.value && reportedRevenue.value ? reportedRevenue.value / value.value : 0,
                            },
                        ];
                    }
                });
                Object.entries(obj).forEach(([key, value]) => {
                    if (depedentMetrics.includes(customVal) && value && emailSends) {
                        const formatMetricName = METRIC_NAMES[columnKey[key] || ""];
                        data = [
                            ...data,
                            {
                                ...emailSends,
                                metricName: customVal,
                                name: `${formatMetricName} - Prescient`,
                                value: calculateRate(value.value, emailSends.value),
                            },
                        ];
                    }
                });
            });
        } else {
            Object.entries(grpByMetricName).forEach(([key, value]: any) => {
                if (customValue.includes(key.toLowerCase())) {
                    value.forEach((c: any) => {
                        if (c) {
                            const formattedKey = stringToCapitalize(startCase(key)).replace(/ /g, "");
                            const formatMetricName =
                                METRIC_NAMES[formattedKey.charAt(0).toLowerCase() + formattedKey.slice(1)];
                            const legendValueKey = `${formatMetricName} - ${
                                c.modelDerived ? "Prescient" : getDataSourceName(c.connectorName)
                            }`;

                            legendValues[legendValueKey] = +apiData
                                .filter((d: any) => {
                                    return c.connectorName === d.connectorName;
                                })
                                .reduce(
                                    (previousValue: any, currentValue: { value: number; metricName: string }) =>
                                        currentValue.metricName.toLowerCase() === key.toLowerCase()
                                            ? previousValue + (currentValue.value || 0)
                                            : previousValue,
                                    0,
                                )
                                .toFixed(2);
                            data = [
                                ...data,
                                {
                                    ...c,
                                    name: legendValueKey,
                                },
                            ];
                        }
                    });
                }
            });
        }
    });
    return { data, legendValues };
};
