import { Fragment, useEffect, useMemo, useState } from "react";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";

import { SubHeader } from "src/components/SubHeader/SubHeader.v2";
import Loader from "src/components/core/Loader/Loader";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { useLazyGetAllDataSourcesQuery } from "src/reduxState/apis/dataSourcesApi";
import { getSearchedAndFilteredDataSources } from "src/services/dataSources/dataSources";
import { DataSourceTable } from "src/components/DataSource/DataSourceTable/DataSourceTable";
import { IDataSourceForm } from "src/interfaces/IData";

export const DataSources = ({
    openEditDataSourceModal,
    openDeleteModal,
}: {
    openEditDataSourceModal: (dataSource: IDataSourceForm) => void;
    openDeleteModal: (dataSource: IDataSourceForm) => void;
}) => {
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const [getAllDataSources, { isLoading }] = useLazyGetAllDataSourcesQuery();
    const [search, setSearch] = useState<string>("");

    useEffect(() => {
        getAllDataSources({});
    }, []);

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const filteredData = useMemo(
        () => getSearchedAndFilteredDataSources(supportedDataSources, search),
        [supportedDataSources, search],
    );

    return (
        <Stack>
            {isLoading ? (
                <Loader />
            ) : (
                <Fragment>
                    <SubHeader
                        itemsLength={filteredData.length}
                        title="Data Source(s)"
                        onSearchChange={handleSearchChange}
                    />
                    <DataSourceTable
                        data={filteredData}
                        editClick={openEditDataSourceModal}
                        deleteClick={openDeleteModal}
                    />
                </Fragment>
            )}
        </Stack>
    );
};
