import { ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";

export type Choice = "asc" | "desc";

export interface IHeadCell {
    id: keyof ICampaignForInclusionWithMap;
    label: string;
}

export const CAMPAIGN_INCLUSIONS_HEADER: IHeadCell[] = [
    {
        id: "cleanChannelName",
        label: "Channel Name",
    },
    {
        id: "campaignId",
        label: "Id",
    },
    {
        id: "campaignName",
        label: "Campaign Name",
    },
];

export const CAMPAIGN_INCLUSION_CHIPS_VALUE = [
    {
        key: 0,
        label: "All",
        isAll: true,
    },
    {
        key: 1,
        label: "Inclusions",
        isAll: false,
    },
    {
        key: 2,
        label: "Exclusions",
        isAll: false,
    },
];
