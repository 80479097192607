import React, { FC } from "react";

import { formatValue } from "src/services/utils";
import { ExportSheet } from "./ExportSheet";
import { getConfidenceLevel } from "src/services/optimizationPage/optimization";
import { ISheetData } from "src/interfaces/entities/IScenario";

interface IExportOutcomeSheet {
    sheetData: ISheetData;
}

export const ExportOutcomeSheet: FC<IExportOutcomeSheet> = ({ sheetData }) => {
    const { header, data, filename, compareToLabel, selectedScenario } = sheetData;
    const sheetNames = [
        "Roll-Up of Results (Outcome Screen)",
        "Channel Level Recommendations",
        "Campaign Level Recommendations",
    ];

    const generateChannelRows = (rows: any[], currentSheet: string, headers: any) => {
        return rows.map((row: any) => {
            const obj: any = {};

            const generateCell = (column: any) => {
                if (!column.hidden) {
                    let item = formatValue(row[column.id], column.sign, column.fixed);

                    if (column.id === "confidenceScore") {
                        item = row[column.id]
                            ? `${(row[column.id] * 100).toFixed(0)}% ${getConfidenceLevel(row[column.id])}`
                            : "";
                    }

                    if (currentSheet === "Channel Level Recommendations" && column.id === "campaignName") {
                        item = row[column.id];
                    }

                    if (currentSheet === "Campaign Level Recommendations" && column.id === "campaignId") {
                        item = row[column.id] ? `=""${row[column.id]}""` : "";
                    }

                    obj[column.id] = item;
                }
            };

            headers.forEach(generateCell);

            return obj;
        });
    };
    return (
        <ExportSheet
            sheets={[
                data[0],
                generateChannelRows(data[1], sheetNames[1], header[1]),
                generateChannelRows(data[2], sheetNames[2], header[2]),
            ]}
            header={header}
            sheetNames={sheetNames}
            filename={filename}
            selectedScenario={selectedScenario}
        />
    );
};
