import React from "react";
import { FormControl, Select, InputLabel, MenuItem, Button } from "@mui/material";
import { css } from "@emotion/css";
import { useLocation, useNavigate } from "react-router";
import queryString from "query-string";

import { DATA_STATE_LIST, SUBSCRIPTION_STATUS } from "../../consts/OrganizaionTable/OrganizationTable";
import { handleDeleteParamKey } from "../../services/performancePage/performancePage";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";

const useStyles = () => ({
    selectInput: css({
        minWidth: 210,
        backgroundColor: "white",
        ".MuiInputBase-root": {
            height: "42px",
        },
        label: {
            fontSize: "15px",
        },
    }),
    resetButton: css({
        textTransform: "inherit",
        boxShadow: "none",
        fontSize: "15px",
    }),
});

interface IOrganizationsFilter {
    handleFilter: (
        paramKey: string,
        item: {
            id: string;
        },
    ) => void;
}

export const OrganizationsFilter: React.FC<IOrganizationsFilter> = ({ handleFilter }) => {
    const classes = useStyles();
    const { search } = useLocation();
    const navigate = useNavigate();
    const queryParamsObj = queryString.parse(search);

    const { dataState, subscriptionStatus } = queryParamsObj;

    const handleChange = (event: any) => {
        const { name, value } = event.target;
        handleDeleteParamKey(name, navigate);
        handleFilter(name, { id: value.toLowerCase() });
    };

    const handleResetFilter = () => {
        handleDeleteParamKey("dataState", navigate);
        handleDeleteParamKey("subscriptionStatus", navigate);
    };

    const clearFilter = (key: string) => {
        handleDeleteParamKey(key, navigate);
    };

    const getInputValue = (value: string | Array<string | null> | null) => {
        return typeof value === "string" ? value.toUpperCase() : "";
    };

    const isFilterApplied = Object.keys(queryParamsObj).length !== 0;

    return (
        <>
            <FormControl className={classes.selectInput} size="small">
                <InputLabel id="date-state">Data State</InputLabel>
                <Select
                    labelId="date-state"
                    value={getInputValue(dataState)}
                    name="dataState"
                    label="Data State"
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                        "& .MuiSelect-iconOutlined": { display: getInputValue(dataState) ? "none" : "" },
                    }}
                    endAdornment={
                        getInputValue(dataState) && (
                            <span className="MuiIconButton-root" onClick={() => clearFilter("dataState")}>
                                <SVGIconRenderer
                                    icon="closeIcon"
                                    strokeColor="rgba(0, 0, 0, 0.54)"
                                    height="1em"
                                    width="1em"
                                />
                            </span>
                        )
                    }
                >
                    {DATA_STATE_LIST.map((item) => (
                        <MenuItem key={item.id} value={item.id}>
                            {item.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            <FormControl className={classes.selectInput} size="small">
                <InputLabel id="subscription-status">Subscription Status</InputLabel>
                <Select
                    labelId="subscription-status"
                    name="subscriptionStatus"
                    value={getInputValue(subscriptionStatus)}
                    label="Subscription Status"
                    onChange={handleChange}
                    displayEmpty
                    sx={{
                        "& .MuiSelect-iconOutlined": { display: getInputValue(subscriptionStatus) ? "none" : "" },
                    }}
                    endAdornment={
                        getInputValue(subscriptionStatus) && (
                            <span className="MuiIconButton-root" onClick={() => clearFilter("subscriptionStatus")}>
                                <SVGIconRenderer
                                    icon="closeIcon"
                                    strokeColor="rgba(0, 0, 0, 0.54)"
                                    height="1em"
                                    width="1em"
                                />
                            </span>
                        )
                    }
                >
                    {SUBSCRIPTION_STATUS.map((status) => (
                        <MenuItem key={status.id} value={status.id}>
                            {status.label}
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
            {isFilterApplied && (
                <Button className={classes.resetButton} variant="contained" onClick={handleResetFilter}>
                    Reset
                </Button>
            )}
        </>
    );
};
