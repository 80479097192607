import { css, cx } from "@emotion/css";
import isObject from "lodash/isObject";
import * as React from "react";

import { MediaBlender } from "../../../utils/mediaBlender";

export enum FlexDirection {
    Column = "column",
    ColumnReverse = "column-reverse",
    Row = "row",
    RowReverse = "row-reverse",
}

export enum FlexWrap {
    NoWrap = "nowrap",
    Wrap = "wrap",
    WrapReverse = "wrap-reverse",
}

export enum AlignItems {
    Baseline = "baseline",
    Center = "center",
    End = "flex-end",
    Start = "flex-start",
    Stretch = "stretch",
}

export enum JustifyContent {
    Center = "center",
    End = "flex-end",
    SpaceAround = "space-around",
    SpaceBetween = "space-between",
    SpaceEvenly = "space-evenly",
    Start = "flex-start",
}

export interface IFlexBreakpoints {
    xxs?: string | number | FlexWrap;
    xs?: string | number | FlexWrap;
    sm?: string | number | FlexWrap;
    md?: string | number | FlexWrap;
    lg?: string | number | FlexWrap;
    xlg?: string | number | FlexWrap;
}

export interface IFlexProps {
    alignItems?: AlignItems;
    direction?: FlexDirection;
    justifyContent?: JustifyContent;
    wrap?: FlexWrap | IFlexBreakpoints;
    spacing?: string | IFlexBreakpoints;
    children: any;
    className?: string;
}

const flexClass = (alignItems: AlignItems, direction: FlexDirection, justifyContent: JustifyContent) => {
    return css`
        height: auto;
        display: flex;
        padding: 0;
        align-items: ${alignItems};
        flex-direction: ${direction};
        justify-content: ${justifyContent};
        &:last-child {
            margin-bottom: 0;
        }
    `;
};

const wrapClass = (wrap: IFlexBreakpoints | FlexWrap) => {
    if (isObject(wrap)) {
        const wrapXxs = wrap.xxs;
        const wrapXs = wrap.xs;
        const wrapSm = wrap.sm;
        const wrapMd = wrap.md;
        const wrapLg = wrap.lg;

        const wrapBase = wrapXxs || wrapXs || wrapSm || wrapMd || wrapLg || "nowrap";

        return css`
            flex-wrap: ${wrapBase};

            ${wrapXxs &&
            MediaBlender.up("xxs")(`
        flex-wrap: ${wrapXxs};
      `)}

            ${wrapXs &&
            MediaBlender.up("xs")(`
        flex-wrap: ${wrapXs};
      `)}

      ${wrapXs &&
            MediaBlender.up("xs")(`
        flex-wrap: ${wrapXs};
      `)}

      ${wrapSm &&
            MediaBlender.up("sm")(`
        flex-wrap: ${wrapSm};
      `)}

      ${wrapMd &&
            MediaBlender.up("md")(`
        flex-wrap: ${wrapMd};
      `)}

      ${wrapLg &&
            MediaBlender.up("lg")(`
        flex-wrap: ${wrapLg};
      `)}
        `;
    } else {
        return css`
            flex-wrap: ${wrap};
        `;
    }
};

const spacingClass = (spacing: string | IFlexBreakpoints) => {
    if (isObject(spacing)) {
        const spacingXs = spacing.xs;
        const spacingSm = spacing.sm;
        const spacingMd = spacing.md;
        const spacingLg = spacing.lg;

        const spacingBase = spacingXs || spacingSm || spacingMd || spacingLg || "0px";

        return css`
            margin: -${spacingBase} 0 ${spacingBase} -${spacingBase};

            & > * {
                padding: ${spacingBase} 0 0 ${spacingBase};
            }

            ${spacingXs &&
            MediaBlender.up("xs")(`
        margin: -${spacingXs} 0 ${spacingXs} -${spacingXs};

        & > * {
          padding: ${spacingXs} 0 0 ${spacingXs};
        }
      `)}

            ${spacingSm &&
            MediaBlender.up("sm")(`
        margin: -${spacingSm} 0 ${spacingSm} -${spacingSm};

        & > * {
          padding: ${spacingSm} 0 0 ${spacingSm};
        }
      `)}

      ${spacingMd &&
            MediaBlender.up("md")(`
        margin: -${spacingMd} 0 ${spacingMd} -${spacingMd};

        & > * {
          padding: ${spacingMd} 0 0 ${spacingMd};
        }
      `)}

      ${spacingLg &&
            MediaBlender.up("lg")(`
        margin: -${spacingLg} 0 ${spacingLg} -${spacingLg};

        & > * {
          padding: ${spacingLg} 0 0 ${spacingLg};
        }
      `)}
        `;
    } else {
        return css`
            margin: -${spacing} 0 ${spacing} -${spacing};

            & > * {
                padding: ${spacing} 0 0 ${spacing};
            }
        `;
    }
};

export const Flex: React.FC<IFlexProps> = (props) => {
    const {
        alignItems = AlignItems.Center,
        direction = FlexDirection.Row,
        justifyContent = JustifyContent.Start,
        spacing = "0px",
        wrap = FlexWrap.NoWrap,
        children,
        className,
    } = props;
    const wrapStyle = wrap && wrapClass(wrap);
    const spacingStyle = spacing && spacingClass(spacing);
    const flextStyle = cx(flexClass(alignItems, direction, justifyContent), spacingStyle, wrapStyle, className);

    return <div className={flextStyle}>{children}</div>;
};
