import { FC, Fragment } from "react";
import { Button, CircularProgress, LinearProgress, Skeleton, Typography, linearProgressClasses } from "@mui/material";
import { Stack } from "@mui/system";
import styled from "styled-components";

import { IGoal } from "../../../../interfaces/entities/IGoal";
import { capitalizeFirstLetter } from "../../../../services/utils";
import { ContextType } from "../../../../consts/HomePage.ts/HomePage";
import { IGoalItem } from "../../../../interfaces/IGoal/IGoalItem";
import { GoalProcess } from "../GoalProcess";

interface IDetailGoalModal {
    closeGoalDetailModal: () => void;
    openGoalModal: (goal: IGoal) => void;
    selectedGoal: IGoalItem;
    classes: any;
}

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 45,
    [`& .${linearProgressClasses.bar}`]: {
        backgroundImage: "linear-gradient(to right, rgba(1, 148, 255, 1) , rgba(11, 151, 134, 1))",
    },
}));

export const DetailGoalModal: FC<IDetailGoalModal> = ({
    closeGoalDetailModal,
    openGoalModal,
    selectedGoal,
    classes,
}) => {
    const isNullReportingData = selectedGoal.reportingData === null;

    return selectedGoal ? (
        <Fragment>
            <Stack gap="30px" my="0">
                <Typography variant="h2">{selectedGoal.title}</Typography>
                {selectedGoal.context !== ContextType.PORTFOLIO && (
                    <Stack gap="10px">
                        <Typography fontWeight="bold" fontSize="18px">
                            {capitalizeFirstLetter(selectedGoal.context)}(s):{" "}
                        </Typography>
                        <Typography>{selectedGoal.selectedCampaignsOrChannels.join(", ")}</Typography>
                    </Stack>
                )}
                {isNullReportingData ? (
                    <GoalProcess />
                ) : (
                    <Stack gap="20px">
                        <Typography fontSize="18px">{selectedGoal.currentReportedTitle}</Typography>
                        <BorderLinearProgress
                            variant="determinate"
                            value={selectedGoal.goalPercentage > 100 ? 100 : selectedGoal.goalPercentage}
                        />
                    </Stack>
                )}
            </Stack>

            <Stack direction="row" gap="10px" justifyContent="space-between">
                <Button
                    sx={{
                        color: "black",
                    }}
                    className={classes.actionBtn}
                    variant="outlined"
                    onClick={() => openGoalModal(selectedGoal)}
                >
                    Edit Goal
                </Button>
                <Button className={classes.actionBtn} variant="contained" onClick={closeGoalDetailModal}>
                    Done
                </Button>
            </Stack>
        </Fragment>
    ) : (
        <></>
    );
};
