import { DataState } from "../../enums/DataState";
import { OrganizationState } from "../../enums/OrganizationState";
import { SubscriptionStatus } from "../../enums/SubscriptionStatus";
import { SubscriptionStatusKeys } from "../../enums/SubscriptionStatusKeys";

export type Choice = "asc" | "desc";

export interface IOrganizationCell {
    id: string;
    name: string;
    dataState: string;
    subscriptionStatus: string;
    customerSuccessManagerEmail: string;
    membersCount: string;
    connectorsCount: string;
    createdAt: Date;
    activation: string;
}

interface IHeadCell {
    id: keyof IOrganizationCell;
    label: string;
    width: string;
    neumeric: boolean;
}

export const ORGANIZATION_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "name",
        label: "Organization",
        width: "auto",
        neumeric: false,
    },
    {
        id: "id",
        label: "ID",
        width: "auto",
        neumeric: false,
    },
    {
        id: "dataState",
        label: "Data State",
        width: "auto",
        neumeric: false,
    },
    {
        id: "subscriptionStatus",
        label: "Subscription Status",
        width: "auto",
        neumeric: false,
    },
    {
        id: "customerSuccessManagerEmail",
        label: "Customer Success Manager",
        width: "auto",
        neumeric: false,
    },
    {
        id: "membersCount",
        label: "Members",
        width: "auto",
        neumeric: true,
    },
    {
        id: "connectorsCount",
        label: "Connectors",
        width: "auto",
        neumeric: true,
    },
    {
        id: "createdAt",
        label: "Created",
        width: "auto",
        neumeric: true,
    },
];

export const DATA_STATE_LIST: Array<{
    id: keyof typeof OrganizationState;
    label: OrganizationState;
    disabled: (current: DataState) => boolean;
}> = [
    {
        id: DataState.ACTIVE_MODELING,
        label: OrganizationState.ACTIVE_MODELING,
        disabled: (current: DataState) => current === DataState.PAUSED,
    },
    {
        id: DataState.AWAITING_DATA_QA,
        label: OrganizationState.AWAITING_DATA_QA,
        disabled: (current: DataState) => current === DataState.PAUSED,
    },
    {
        id: DataState.SYNCING_DATA,
        label: OrganizationState.SYNCING_DATA,
        disabled: (current: DataState) => current === DataState.PAUSED,
    },
    {
        id: DataState.NO_DATA,
        label: OrganizationState.NO_DATA,
        disabled: (current: DataState) => current === DataState.PAUSED,
    },
    {
        id: DataState.PAUSED,
        label: OrganizationState.PAUSED,
        disabled: () => false,
    },
    {
        id: DataState.UNPAUSED,
        label: OrganizationState.UNPAUSED,
        disabled: (current: DataState) => current !== DataState.PAUSED,
    },
];

export const SUBSCRIPTION_STATUS = [
    { label: SubscriptionStatus.ACTIVE, id: SubscriptionStatusKeys.ACTIVE },
    { label: SubscriptionStatus.INACTIVE, id: SubscriptionStatusKeys.INACTIVE },
    { label: SubscriptionStatus.INCOMPLETE, id: SubscriptionStatusKeys.INCOMPLETE },
    { label: SubscriptionStatus.INCOMPLETE_EXPIRED, id: SubscriptionStatusKeys.INCOMPLETE_EXPIRED },
    { label: SubscriptionStatus.CANCELED, id: SubscriptionStatusKeys.CANCELED },
    { label: SubscriptionStatus.UNPAID, id: SubscriptionStatusKeys.UNPAID },
    { label: SubscriptionStatus.PAST_DUE, id: SubscriptionStatusKeys.PAST_DUE },
    { label: SubscriptionStatus.TRIALING, id: SubscriptionStatusKeys.TRIALING },
];
