import React, { useState } from "react";
import { TableBody } from "@mui/material";
import { stableSort, getComparator } from "../../../utils/sort";
import { useStylesTable } from "../../../consts/StylesForTables";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { FeaturesTableHeader } from "../FeaturesTableHeader/FeaturesTableHeader";
import { Choice, ICells } from "../../../consts/featuresPage/featuresPage";
import { FeaturesTableRow } from "../FeaturesTableRow/FeaturesTableRow";
import { IFeature } from "../../../interfaces/entities/IFeature";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { useSelector } from "react-redux";

interface IFeaturesTableView {
    features: IFeature[];
    onEditFeature: (feature: IFeature) => void;
    onDeleteFeature: (feature: IFeature) => void;
}

export const FeaturesTable: React.FC<IFeaturesTableView> = ({ features, onEditFeature, onDeleteFeature }) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState<number>(0);

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>, feature: IFeature) => {
        e.stopPropagation();
        onEditFeature(feature);
    };

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>, feature: IFeature) => {
        e.stopPropagation();
        onDeleteFeature(feature);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: features.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <FeaturesTableHeader onRequestSort={handleRequestSort} choice={order} choiceBy={orderBy} />
            <TableBody className={classes.tableBody}>
                {stableSort(features, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any) => {
                        return (
                            <FeaturesTableRow
                                key={row.id}
                                feature={row}
                                onDeleteFeature={handleDelete}
                                onEditFeature={handleEdit}
                            />
                        );
                    })}
            </TableBody>
        </CustomTableStructure>
    );
};
