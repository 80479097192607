import { FC } from "react";
import { css } from "@emotion/css";
import { Tab, Tabs } from "@mui/material";

import { ActivePerformanceTabs } from "../../../enums/ActivePerfomanceTabs";
import { PerformanceTab } from "./PerformanceTab";
import { IEmail } from "../../../interfaces/entities/IEmail";

const useStyles = () => ({
    tabs: css({
        minHeight: "30px !important",
        borderRight: 0,
        "&:first-child": {
            borderLeft: "1px solid rgba(0, 0, 0, 0.23)",
        },
        "& .MuiTabs-scroller div button": {
            padding: "4px 12px",
            border: "1px solid rgba(0, 0, 0, 0.23)",
            borderLeft: 0,
            minHeight: "30px !important",
            fontSize: "13px !important",
            textTransform: "capitalize",
            "&.Mui-selected": {
                backgroundColor: "#616161",
                color: "white",
                border: 0,
            },
        },
    }),
    tooltip: css({
        fontSize: "14px",
    }),
});

interface IEmailTabHolderComponent {
    tabValue: string | ActivePerformanceTabs;
    handleTabChange: (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => void;
    performanceCampaignData: IEmail;
}

export const EmailTabHolderComponent: FC<IEmailTabHolderComponent> = ({
    tabValue,
    handleTabChange,
    performanceCampaignData,
}) => {
    const classes = useStyles();

    return (
        <>
            <Tabs
                className={classes.tabs}
                value={tabValue}
                onChange={handleTabChange}
                aria-label="tabs"
                TabIndicatorProps={{
                    style: {
                        display: "none",
                    },
                }}
                variant="scrollable"
                scrollButtons={false}
            >
                <Tab value="performance" id="performance-tab" label="Performance" />
            </Tabs>
            {tabValue === "performance" && <PerformanceTab performanceCampaignData={performanceCampaignData} />}
        </>
    );
};
