import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface IKargoCtvModalContent {
    classes: any;
    displayName: string;
}

export const KargoCtvModalContent: FC<IKargoCtvModalContent> = ({ classes, displayName }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Added Username and Password from Kargo API OKTA account
                </Typography>
            </Stack>
            <Typography className={classes.info}>
                We use this Username and Password to generate tokens for ingesting your data.
            </Typography>

            {/* Step 2 */}
            <Stack mt={1} direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>Click Save & Test.</Typography>
        </Fragment>
    );
};
