import React from "react";
import { InputAdornment, TextField, Stack } from "@mui/material";
import { useTheme } from "@mui/system";
import { useNavigate, useLocation } from "react-router";
import queryString from "query-string";
import { Range } from "react-date-range";

import { useStylesForCampaignSubHeader } from "../../../components/CampaignComponents/CampaignSubHeader/CampaignSubHeader";

import { Choice, IHeadCell } from "../../../consts/emailPage/emailPage";
import { handleFilteredData } from "../../../services/utils";
import { handleDeleteParamKey } from "../../../services/performancePage/performancePage";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { EmailFilterComponent } from "./EmailFilterComponents/EmailFilterComponent";
import { DraggableEmailColumnSelect } from "./DraggableEmailColumnSelect";
import { EmailFunctionalityTools } from "./EmailFunctionalityTools";
import FilterChips from "../../FilterChips/FilterChips";
import { getEmailFilterChipList, getEmailFilterList } from "../../../services/emailPage/emailPage";
import { IEmail } from "../../../interfaces/entities/IEmail";
import { IEmailPercentageTableData } from "../../../interfaces/emailDetails/IEmailPercentage";

interface IEmailSubHeader {
    originalPerformanceData: IEmail[];
    performanceData: any[];
    selectedTableColumns: IHeadCell[];
    currentDateFilter: Range[];
    order?: Choice;
    orderBy?: string;
    compareToPast: boolean;
    searchCampaign: string;
    setselectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>;

    onSearchChange: (e: React.ChangeEvent<any>) => void;
    handleTableCol: (column: IHeadCell) => void;
    finalPercentagePerformanceData: IEmailPercentageTableData;
}

export const EmailSubHeader: React.FC<IEmailSubHeader> = ({
    originalPerformanceData,
    performanceData,
    selectedTableColumns,
    onSearchChange,
    handleTableCol,
    currentDateFilter,
    compareToPast,
    finalPercentagePerformanceData,
    order,
    orderBy,
    searchCampaign,
    setselectedTableColumns,
}) => {
    const classes = useStylesForCampaignSubHeader(useTheme());
    const navigate = useNavigate();
    const { search } = useLocation();

    const queryParamsObj = queryString.parse(search);

    const filterList = getEmailFilterList(queryParamsObj);
    const filterChipsList = getEmailFilterChipList(filterList);

    const handleFilter = (paramKey: string, item: { id: string }) => {
        handleFilteredData(paramKey, item, navigate);
    };

    const removeFilter = (key: string) => {
        handleDeleteParamKey(key, navigate);
    };

    return (
        <Stack className={classes.subHeader}>
            <Stack direction="row" flexWrap="wrap" justifyContent="space-between" sx={{ gap: "8px" }}>
                <Stack direction="row" flexWrap="wrap" sx={{ gap: "8px" }}>
                    <TextField
                        className={classes.searchField}
                        size="small"
                        id="outlined-basic"
                        label=""
                        variant="outlined"
                        placeholder="Search by campaign"
                        color="primary"
                        InputProps={{
                            startAdornment: (
                                <InputAdornment position="start">
                                    <SVGIconRenderer
                                        width="20px"
                                        height="20px"
                                        strokeColor="rgba(0, 0, 0, 0.26)"
                                        icon="searchIcon"
                                    />
                                </InputAdornment>
                            ),
                        }}
                        value={searchCampaign}
                        onChange={onSearchChange}
                    />
                    <EmailFilterComponent
                        filterList={filterList}
                        handleFilter={handleFilter}
                        selectedTableColumns={selectedTableColumns}
                        filterChipsCount={filterChipsList.length}
                        campaignIdOptions={originalPerformanceData}
                    />
                </Stack>
                <Stack direction="row" flexWrap="wrap" sx={{ gap: "8px" }}>
                    <DraggableEmailColumnSelect
                        handleTableCol={handleTableCol}
                        selectedTableColumns={selectedTableColumns}
                        setselectedTableColumns={setselectedTableColumns}
                    />
                    <EmailFunctionalityTools
                        selectedTableColumns={selectedTableColumns}
                        tableData={performanceData}
                        order={order}
                        orderBy={orderBy}
                        compareToPast={compareToPast}
                        finalPercentagePerformanceData={finalPercentagePerformanceData}
                        currentDateFilter={currentDateFilter}
                    />
                </Stack>
            </Stack>
            <FilterChips filterChipsList={filterChipsList} removeFilter={removeFilter} />
        </Stack>
    );
};
