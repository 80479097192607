import React from "react";
import Avatar from "@mui/material/Avatar";
import useAvatar from "../MemberCard/useAvatar";
import { AVATAR_BG_COLOR, AVATAR_TEXT_COLOR, WHITE } from "src/consts/colors";

interface ICustomAvatarProps {
    avatarUrl: string;
    avatarText: string;
    className?: string;
    sx?: React.CSSProperties;
    size?: string;
}

const CustomAvatar: React.FC<ICustomAvatarProps> = ({ avatarUrl, avatarText, className, sx, size = "40px" }) => {
    const url = useAvatar(avatarUrl);

    return (
        <Avatar
            className={className}
            src={url || ""}
            sx={{
                borderRadius: "50%",
                fontSize: "15px",
                fontWeight: "700",
                lineHeight: "22.5px",
                width: size,
                height: size,
                backgroundColor: url ? `${WHITE} !important` : `${AVATAR_BG_COLOR} !important`,
                color: AVATAR_TEXT_COLOR,
                ...sx,
            }}
        >
            {!url && avatarText.toUpperCase()}
        </Avatar>
    );
};

export default CustomAvatar;
