import React from "react";
import TextField, { TextFieldProps } from "@mui/material/TextField";

export enum FieldTextSize {
    Normal = "normal",
    Small = "small",
}

export const Field: React.FC<TextFieldProps> = ({ variant = "outlined", id, type = "text", ...rest }) => {
    const inputId: string = id || `field-${name}`;
    const inputProps = {
        id: inputId,
        type,
        variant,
        ...rest,
    };

    return <TextField {...inputProps} />;
};
