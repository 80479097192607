import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { IDataSourceForm, IDataSourceWithConnected } from "src/interfaces/IData";
import React, { FC } from "react";
import { dataSourceServiceIsGoogleSheets } from "src/services/dataSources/dataSources";

interface IDataSourceLabel {
    dataSource: IDataSourceForm | IDataSourceWithConnected;
}

export const DataSourceLabel: FC<IDataSourceLabel> = ({ dataSource }) => {
    return (
        <Stack>
            <Typography variant="body1">{dataSource.name}</Typography>
            {dataSourceServiceIsGoogleSheets(dataSource) && (
                <Typography variant="body2" color="text.secondary">
                    Powered by Google Sheets
                </Typography>
            )}
        </Stack>
    );
};
