import { FC } from "react";
import { Button, Checkbox, TableCell, TableRow, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { CAMPAIGN_INCLUSIONS_HEADER } from "src/consts/campaignInclusionsPage/campaignInclusions";
import { ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { imageContainerClass } from "src/consts/StylesForTables";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { handleClickWithTextSelectionCheck } from "src/services/utils";

interface ICampaignInclusionsRow {
    isLoading: boolean;
    row: ICampaignForInclusionWithMap;
    campaignInclusionsBtnAction: (selectedRow: ICampaignForInclusionWithMap) => void;
    isRowSelected: boolean;
    labelId: string;
    onRowClick: (event: React.MouseEvent<unknown>, id: string) => void;
}

export const CampaignInclusionsRow: FC<ICampaignInclusionsRow> = ({
    row,
    isLoading,
    campaignInclusionsBtnAction,
    isRowSelected,
    labelId,
    onRowClick,
}) => {
    const isCampaignAdded = row.manuallySet;

    const campaignInclusionsClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        campaignInclusionsBtnAction(row);
    };

    return (
        <TableRow
            selected={isRowSelected}
            aria-checked={isRowSelected}
            tabIndex={-1}
            onClick={(e) => handleClickWithTextSelectionCheck(onRowClick)(e, row.campaignId)}
            sx={{ cursor: "pointer" }}
            hover
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isRowSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                />
            </TableCell>
            {CAMPAIGN_INCLUSIONS_HEADER.map((header, i) => {
                return (
                    <TableCell key={i}>
                        {header.id === "cleanChannelName" && row.channel ? (
                            <Stack gap="8px" direction="row" alignItems="center" justifyContent="flex-start">
                                <DataSourceAvatar
                                    programmaticName={row.channel.toLowerCase()}
                                    className={imageContainerClass}
                                />
                                <Typography variant="body2">{row[header.id]}</Typography>
                            </Stack>
                        ) : (
                            row[header.id]
                        )}
                    </TableCell>
                );
            })}
            <TableCell align="center">
                <Button
                    onClick={campaignInclusionsClick}
                    variant="outlined"
                    disabled={isLoading}
                    color={isCampaignAdded ? "error" : "primary"}
                    endIcon={
                        <SVGIconRenderer
                            icon={isCampaignAdded ? "trashIcon" : "plusIcon"}
                            strokeColor={isCampaignAdded ? "error" : "primary"}
                            width="18px"
                            height="18px"
                        />
                    }
                    sx={{ textTransform: "inherit" }}
                >
                    {isCampaignAdded ? "Remove" : "Add"}
                </Button>
            </TableCell>
        </TableRow>
    );
};
