export const MEMBERS_CHIPS_VALUE = [
    {
        key: 0,
        label: "All",
        isAll: true,
    },
    {
        key: 1,
        label: "Admins",
        isAll: false,
    },
    {
        key: 2,
        label: "Viewers",
        isAll: false,
    },
];

