import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";

const initialState: string[] = [];

const closedBrokenConnectors = createSlice({
    name: "closedBrokenConnectors",
    initialState,
    reducers: {
        setCloseBrokenConnectorIds: (state, { payload }) => {
            return [...state, payload];
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setCloseBrokenConnectorIds } = closedBrokenConnectors.actions;

export default closedBrokenConnectors.reducer;

// Selectors
export const brokenConnectorsSelector = (state: RootState) => state.closedBrokenConnectors;
