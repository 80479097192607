import React from "react";
import { Typography } from "@mui/material";
import { Box } from "@mui/system";
import { Flex, JustifyContent } from "../core/Flex/Flex";

export const AdBlockerWarning: React.FC = () => {
    return (
        <>
            <Flex justifyContent={JustifyContent.SpaceBetween}>
                <Typography variant="h5" component="h1">
                    <b>Ad Blocker Detected!</b>
                </Typography>
            </Flex>
            <Box sx={{ marginTop: "5px", fontSize: "16px", width: "400px" }}>
                It looks like you might be using an ad blocker. Prescient may not behave correctly with an ad blocker
                enabled. To avoid issues, consider disabling it.
            </Box>
        </>
    );
};
