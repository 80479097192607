import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";
import { logoutUser } from "../actions/auth";

const initialState: boolean = false;

const continueAsSuperAdmin = createSlice({
    name: "continueAsSuperAdmin",
    initialState,
    reducers: {
        setContinueAsSuperAdmin: (state, { payload }) => {
            return payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setContinueAsSuperAdmin } = continueAsSuperAdmin.actions;

export default continueAsSuperAdmin.reducer;

// Selectors
export const continueAsSuperAdminSelector = (state: RootState) => state.continueAsSuperAdmin;
