import { StoreEnhancer, StoreEnhancerStoreCreator, Reducer, PreloadedState, AnyAction, Action } from "redux";
import { IDictionary } from "../../interfaces/IDictionary";
import { RootState } from "../stores/store";

export const actionListenerEnhancer: StoreEnhancer<{}> =
    (createStore: StoreEnhancerStoreCreator): StoreEnhancerStoreCreator<{}> =>
    <S = any, A extends Action = AnyAction>(reducer: Reducer<S, A>, initialState?: PreloadedState<S>) => {
        const actionListeners: IDictionary = {};
        const store = createStore(reducer, initialState);
        const dispatch = store.dispatch;

        store.dispatch = (action) => {
            const result = dispatch(action);
            if (typeof action === "object" && action.type && actionListeners[action.type]) {
                actionListeners[action.type].forEach((listener: (a: AnyAction, s: RootState | {}) => void) =>
                    listener(action, store.getState()),
                );
            }

            return result;
        };

        // @ts-ignore
        store.addActionListener = (actionType: string, listener: () => void) => {
            actionListeners[actionType] = (actionListeners[actionType] || []).concat(listener);

            return () => {
                actionListeners[actionType] = actionListeners[actionType].filter((l: () => void) => l !== listener);
            };
        };
        return store;
    };
