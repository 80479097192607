import React from "react";
import { FormikHelpers } from "formik";
import { IAnnouncement } from "../../../interfaces/entities/IAnnouncement";
import { HeaderSize, Modal } from "../../core/Modal/Modal";
import { AnnouncementForm } from "../AnnouncementForm/AnnouncementForm";

interface IAnnouncementModalForm {
    isOpen: boolean;
    announcement: IAnnouncement | null;
    isFormEditMode: boolean;
    onCloseModal(): void;
    onSubmit(announcement: IAnnouncement, formikHelpers: FormikHelpers<IAnnouncement>): void;
}

export const AnnouncementModalForm: React.FC<IAnnouncementModalForm> = ({
    isOpen,
    announcement,
    onCloseModal,
    isFormEditMode,
    onSubmit,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            headerSize={HeaderSize.SMALL}
            title={isFormEditMode ? "Edit Announcement" : "New Announcement"}
            canOutsideClickClose={false}
            onClose={onCloseModal}
            disableMarginTop={true}
            content={
                <AnnouncementForm
                    initialValues={announcement as IAnnouncement}
                    isEditMode={isFormEditMode}
                    onSubmit={onSubmit}
                />
            }
        />
    );
};
