import React, { FC } from "react";
import { Typography } from "@mui/material";
import { IMember } from "../../../interfaces/entities/IMember";
import { IOrganization } from "../../../interfaces/entities/IOrganization";
import { CustomModal } from "../../CustomModal/CustomModal";

interface IMemberDeleteModal {
    member: IMember;
    currentCompany: IOrganization;
    disableBtn: boolean;
    closeModal: () => void;
    onDelete(...args: any): any;
}

export const MemberDeleteModal: FC<IMemberDeleteModal> = ({
    member,
    currentCompany,
    disableBtn,
    onDelete,
    closeModal,
}) => {
    return (
        <CustomModal
            title="Remove Member?"
            subTitle={`Removing ${member.firstName} ${member.lastName} from ${currentCompany.displayName} will:`}
            details={
                <>
                    <Typography component="li" variant="body2">
                        - Prevent them from accessing {currentCompany.displayName}'s data
                    </Typography>
                    <Typography component="li" variant="body2">
                        - NOT remove access from any other organizations
                    </Typography>
                    <Typography component="li" variant="body2">
                        - NOT delete their Prescient account
                    </Typography>
                </>
            }
            buttonName="Delete"
            handleAction={onDelete}
            selectedConnector={member}
            closeModal={closeModal}
            disableBtn={disableBtn}
            type="error"
        />
    );
};
