import React from "react";
import { Theme } from "@mui/material/styles";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import Paper from "@mui/material/Paper";
import { Stack, useTheme } from "@mui/system";
import { createGlobalStyle } from "styled-components";

import { IPageProps } from "../interfaces/IPageProps";
import logo from "../assets/logo/prescient_icon_stacked_duotone.svg";
import { SVGIconRenderer } from "../components/SVGIconRenderer/SVGIconRenderer";

const useStyles = (theme: Theme, isUpgraded: boolean) => ({
    root: css({
        width: "100%",
        "& form": {
            display: "flex",
            flexDirection: "column",
            gap: isUpgraded ? theme.spacing(3) : "30px",
        },
    }),
    formClass: css({
        padding: isUpgraded ? theme.spacing(4) : "50px",
        backgroundColor: isUpgraded ? "#FFFFFF" : "#f5f5f5",
        width: isUpgraded ? "444px" : "500px",
        [theme.breakpoints.down("sm")]: {
            padding: "30px!important",
            width: "calc(100vw - 100px)!important",
        },
    }),
    containerClass: css({
        padding: "70px 0",
        [theme.breakpoints.down("sm")]: {
            padding: "32px!important",
        },
    }),
    markLogo: css({
        position: "absolute",
        top: "68px",
        width: "100%",
        [theme.breakpoints.down("md")]: {
            display: "none",
        },
    }),
});

export const fromStyles = (theme: Theme) => ({
    fieldClass: css({
        backgroundColor: "white",
    }),
});

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #1B313B;
  }
`;

export const fieldClass = css`
    background-color: white;
`;

interface ISimpleFormProps extends IPageProps {
    children: React.ReactNode;
    isUpgraded?: boolean;
}

export const SimpleForm: React.FC<ISimpleFormProps> = ({ children, isUpgraded = false }) => {
    const classes = useStyles(useTheme(), isUpgraded);
    return (
        <div className={classes.root}>
            <GlobalStyle />
            <Stack
                className={classes.containerClass}
                justifyContent="center"
                flexDirection="column"
                gap="70px"
                alignItems="center"
            >
                <Stack alignSelf="center">
                    <Link to="/" data-cy="logolink">
                        <img alt="logo" src={logo} data-cy="logo" style={{ width: "310px", height: "174px" }} />
                    </Link>
                </Stack>
                <Stack alignItems="center" sx={{ zIndex: 1 }}>
                    <Paper elevation={isUpgraded ? 8 : 1} className={classes.formClass}>
                        {children}
                    </Paper>
                </Stack>
            </Stack>
            <Stack justifyContent="center" alignItems="center" className={classes.markLogo}>
                <SVGIconRenderer icon="markPerscientLogo" />
            </Stack>
        </div>
    );
};
