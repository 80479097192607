import { format } from "date-fns";
import { toDate } from "date-fns-tz";

import { IDictionary } from "../../../interfaces/IDictionary";
import { IAnalytics, IOrganizationWithRole } from "../IAnalytics";
import { vitallyAPiUrl, vitallyApiKey } from "../../../utils/env";
import { PRIMARY_MAIN_COLOR } from "src/consts/colors";

/* tslint:disable */
export class VitallyAnalytics implements IAnalytics {
    public isVitallySupported = false;
    public instance: any = this.getInstance();

    private disabled = false;
    private userId = "";
    private orgId = "";

    private trackedEvents: any[] = [];
    private userEvent = {};

    constructor(mockedVitallyInstance?: IAnalytics | any) {
        if (mockedVitallyInstance) {
            this.instance = mockedVitallyInstance;
            this.isVitallySupported = false;
        } else {
            const script = document.createElement("script");
            script.defer = true;
            script.src = "https://cdn.vitally.io/vitally.js/v1/vitally.js";
            document.getElementsByTagName("body")[0].appendChild(script);
            this.isVitallySupported = true;
            this.init();
        }
    }

    // @ts-ignore
    public getInstance() {
        if (this.isVitallySupported) {
            // @ts-ignore
            !(function (n, t, r) {
                for (
                    // @ts-ignore
                    var i = (n[t] = n[t] || []),
                        // @ts-ignore
                        o = function (r) {
                            i[r] =
                                i[r] ||
                                function () {
                                    for (var n = [], t = 0; t < arguments.length; t++) n[t] = arguments[t];
                                    return i.push([r, n]);
                                };
                        },
                        u = 0,
                        c = ["init", "user", "account", "track", "nps"];
                    u < c.length;
                    u++
                ) {
                    o(c[u]);
                }
            })(window, "Vitally");
            return window.Vitally;
        }
        return this.instance;
    }

    public init() {
        this.getInstance().init(vitallyApiKey, vitallyAPiUrl);
    }

    public login(user: any) {
        if (this.disabled) {
            return;
        }
        this.logEvent("Logged In", {});
    }

    public logout() {
        this.userId = "";
        this.orgId = "";
        this.disabled = false;
        this.trackedEvents = [];
        this.userEvent = {};
    }

    public logEvent(event: string, eventProperties: any = {}) {
        if (this.disabled) {
            return;
        }

        if (this.userId) {
            this.getInstance().track({ event, userId: this.userId, properties: eventProperties });
            return;
        }
        this.trackedEvents = [...this.trackedEvents, { event, userId: this.userId, properties: eventProperties }];
    }

    public setUserProperties(properties: IDictionary<any>): void {}

    public setUser(user: any): void {
        if (this.disabled) {
            return;
        }

        if (user.isSuperadmin) {
            console.log("disabling Vitally because user is a superadmin");
            this.disabled = true;
            return;
        }

        if (!this.orgId) {
            this.userEvent = user;
            return;
        }
        if (user && Object.keys(user).length && this.isVitallySupported && this.userId !== user.id) {
            this.getInstance().nps("survey", {
                productName: "Prescient AI",
                delay: 5000,
                primaryColor: PRIMARY_MAIN_COLOR,
            });
        }
        if (user && Object.keys(user).length && this.orgId) {
            this.userId = user.id;
            this.userEvent = user;
            this.getInstance().user({
                userId: user.id,
                accountId: this.orgId,
                traits: {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    createdAt: format(toDate(user.createdAt), "yyyy-MM-dd"),
                },
            });
        }
    }

    public setCurrentOrganization(organization: IOrganizationWithRole): void {
        if (this.disabled) {
            return;
        }

        this.orgId = organization.id;
        this.getInstance().account({
            accountId: organization.id,
            traits: {
                name: organization.displayName,
                avatar: organization.avatarMediumUrl,
                subscriptionStatus: organization.subscriptionStatus,
            },
        });

        this.setUser(this.userEvent);

        this.trackedEvents.forEach((event) => {
            this.logEvent(event.event, event.properties);
        });

        this.trackedEvents = [];
    }
}
