import { toDate } from "date-fns-tz";
import {
    addDays,
    differenceInDays,
    format,
    getQuarter,
    getYear,
    startOfMonth,
    startOfQuarter,
    startOfWeek,
    startOfYear,
    subDays,
} from "date-fns";
import groupBy from "lodash/groupBy";
import sortBy from "lodash/sortBy";
import isArray from "lodash/isArray";
import replace from "lodash/replace";

import {
    ICampaignData,
    IForecastedTotalMetric,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    INoChangeJob,
    IOptimizationModelResponse,
    IScenario,
    IScenarioFormatted,
    ISelectedTotalMetric,
    ISummary,
} from "../../interfaces/entities/IScenario";
import {
    IForecastTabSeries,
    INCLUDED_TYPE,
    OptimizationTab,
    TIMEFRAME_OPTIONS,
    TIMEFRAME_RADIO_OPTIONS,
} from "../../consts/optimizationPage/optimizationPage";
import { IScenarioApiResponse, IScenarioByIdApiResponse } from "../../interfaces/api/IScenarioResponse";
import { keysToCamelCase } from "../../utils/format";
import {
    calculateROAS,
    formatValue,
    calculatePercentage as calculatePer,
    getPreparedValue,
    getTooltipDateFormat,
    getDemoCampaignName,
} from "../utils";
import { calculatePercentage } from "../performancePage/performancePage";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { ICommonDTO } from "../../interfaces/DTO/ICommonDTO";
import { IJob } from "../../interfaces/entities/IJob";
import { FORMATS } from "src/enums/Formats";
import { IDictionary } from "src/interfaces/IDictionary";
import { IDataSource } from "src/interfaces/IData";
import { getComparator, stableSort } from "src/utils/sort";
import { ICompanyForecastResponse } from "src/interfaces/ICompanyForecastResponse";
import { chartGrpKey } from "src/consts/performancePaidPage/performancePaidPage";
import { ICompanyForecast } from "src/interfaces/ICompanyForecast";
import { PredictedLiftTabs } from "src/components/Optimization/ScenarioOutcome/ScenarioOutcomeMain/OutcomeTabs/PerdictedLiftTab/PerdictedLiftTab";
import { CompanyForecastMetricMapping } from "src/interfaces/companyForecast/ICompanyBacktestFormattedResponse";
import { ICompanyForecastFormattedResponse } from "src/interfaces/companyForecast/ICompanyForecastFormattedResponse";
import { Forecast, ForecastTimeframe } from "src/interfaces/api/ICompanyForecastResponseV1";
import { store } from "src/reduxState/stores/store";

export const forecastTabs = [
    { label: "30 Days", value: "30" },
    { label: "60 Days", value: "60" },
    { label: "90 Days", value: "90" },
];

export const LOW_CONFIDENCE_COLOR = "#ffcf33";
export const MEDIUM_CONFIDENCE_COLOR = "#ed6c02";
export const HIGH_CONFIDENCE_COLOR = "#2e7d32";

export const DRAWER_WIDTH = 300;
export const OPTIMIZATION_JOB = "run_scenario_allocation_optimization";
export const FORECAST_JOB = "run_scenario_campaign_forecast";
export const NO_CHANGE_FORECAST_JOB = "run_scenario_no_change_forecast";
export const COMPANY_FORECAST_TAB = [
    CompanyForecastMetricMapping.trendHolidayMarketing,
    CompanyForecastMetricMapping.marketing,
    CompanyForecastMetricMapping.totalHoliday,
    CompanyForecastMetricMapping.trend,
] as const;
export const PREDICTION_ACCURACY_CARD = [
    CompanyForecastMetricMapping.actualRevenue,
    CompanyForecastMetricMapping.trendHolidayMarketing,
    CompanyForecastMetricMapping.globalAccuracy,
] as const;

export const NO_CHANGE_FORECAST_HEADING = "No Change Forecast";
export const PREDICTED_LIFT_PER_HEADING = "Predicted Lift %";
export const OPTIMIZED_FORECAST_HEADING = "Optimized Forecast";

const filter = {
    recommended: "filter[is_recommendation]=true",
    archived: "filter[is_archived]=true",
};

export const scenatioTableViewTabs = [
    { label: "Scenarios", value: OptimizationTab.Scenarios, filter: "" },
    { label: "Recommended", value: OptimizationTab.Recommended, filter: filter.recommended },
    { label: "Archived", value: OptimizationTab.Archived, filter: filter.archived },
];

export const scenarioBasisSentence: IDictionary = {
    shift: "I want to shift spend within my budget to optimize",
    forecast: "I want to simulate a spend change to forecast performance",
    scale: "I want to scale my media spend",
};

export const initialTabSeries: IForecastTabSeries = {
    revenue: [],
    media: [],
    holidays: [],
    trend: [],
};

export const getReportingDateObj = (timeframeValue: number, createdAt?: string | Date) => {
    const updatedDate = createdAt || new Date();
    return {
        start_date: format(subDays(toDate(updatedDate), timeframeValue + 2), "yyyy-MM-dd"),
        end_date: format(subDays(toDate(updatedDate), 3), "yyyy-MM-dd"),
    };
};

export const campaignSelectClickLogic = (selectedItems: string[], key: string) => {
    const selectedIndex = selectedItems.indexOf(key);
    let newSelected: string[] = [];
    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selectedItems, key);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selectedItems.slice(1));
    } else if (selectedIndex === selectedItems.length - 1) {
        newSelected = newSelected.concat(selectedItems.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selectedItems.slice(0, selectedIndex), selectedItems.slice(selectedIndex + 1));
    }
    return newSelected;
};

export const getSpendHeader = (forecastTimeframe: TIMEFRAME_OPTIONS) => {
    return `Last ${TIMEFRAME_RADIO_OPTIONS.filter((o) => o.value === forecastTimeframe)[0].label} Spend`;
};

export const generateScenaioBody = (scenarioDetails: { orgId: string } & IScenario) => {
    return {
        name: scenarioDetails.name,
        description: scenarioDetails.description,
        scenario_type: scenarioDetails.scenarioType,
        budget: scenarioDetails.budget,
        revenue: scenarioDetails.revenue || "",
        roas: scenarioDetails.roas || "",
        forecast_timeframe: scenarioDetails.forecastTimeframe,
        company_id: scenarioDetails.orgId,
        campaigns_data: scenarioDetails.campaignData.map((data) => ({
            channel: data.channel,
            campaign_id: data.campaignId,
            is_locked: data.isLocked,
            budget_allocated: data.budgetAllocated,
        })),
        min_confidence: scenarioDetails.optimizationConfidence / 100,
        is_private: scenarioDetails.isPrivate,
        is_archived: scenarioDetails.isArchived || false,
    };
};

export const generateScenaioEditBody = (scenarioDetails: { orgId: string; runForecastOnly?: boolean } & IScenario) => {
    return {
        ...generateScenaioBody(scenarioDetails),
        run_forecast_only: scenarioDetails.runForecastOnly || false,
    };
};

const getEntitiesFromIncludes = (included: ICommonDTO[], entities: any) => {
    included?.forEach((obj) => {
        const { id, type, attributes } = obj;
        const transformedAttributes = keysToCamelCase(attributes);
        switch (type) {
            case INCLUDED_TYPE.USERS:
                entities.users = {
                    ...entities.users,
                    [id]: `${transformedAttributes.firstName} ${transformedAttributes.lastName}`,
                };
                break;
            case INCLUDED_TYPE.JOBS:
                entities[INCLUDED_TYPE.JOBS] = {
                    ...entities[INCLUDED_TYPE.JOBS],
                    [id]: {
                        id,
                        ...transformedAttributes,
                    },
                };
        }
    });
    return entities;
};

export const getConfidenceScenarioLevel = (scenarioCampaigns: IOptimizationModelResponse) => {
    if (!scenarioCampaigns) {
        return 0;
    }
    if (scenarioCampaigns.length) {
        const campaignsTotalSpend: any[] = [];
        const totalSpend = scenarioCampaigns.reduce((a, o) => a + +o.budgetAllocated, 0);
        scenarioCampaigns.forEach((campaign) => {
            const weight = +campaign.budgetAllocated / totalSpend;
            campaignsTotalSpend.push(weight * campaign.confidenceScore);
        });
        return campaignsTotalSpend.reduce((a, b) => a + b, 0);
    }
};

const getFormattedJobs = (allJobs: any[]) => {
    return allJobs.map((job) => {
        const allocatedSpend = job.metadata?.forecast_allocations;
        let allocatedData: any[] = [];

        const revenueArr =
            job.metadata?.forecast_model_output && Array.isArray(job.metadata?.forecast_model_output)
                ? job.metadata.forecast_model_output
                : [];

        Object.entries(allocatedSpend || {}).forEach(([spendKey, value]: any) => {
            revenueArr.forEach((r: any) => {
                const revenueKey = r[1].CAMPAIGN_NAME;
                const keyArr = r[1].CAMPAIGN_NAME.split("_");
                const revenueCampaignId = keyArr[keyArr.length - 1];
                const channel = keyArr.slice(0, -2).join("_");
                let isLocked = false;

                job.metadata.locked_campaigns?.forEach((lockedCampaign: string) => {
                    if (lockedCampaign === spendKey) {
                        isLocked = true;
                    }
                });

                if (spendKey === revenueKey) {
                    allocatedData = [
                        ...allocatedData,
                        {
                            campaignId: revenueCampaignId,
                            forecastedRevenue: +r[1].PRED_REV.toFixed(4),
                            budgetAllocated: +(+value).toFixed(4),
                            confidenceScore: +r[1].CONFIDENCE,
                            ...r[1].CONFIDENCE,
                            isLocked,
                            channel,
                        },
                    ];
                }
            });
        });

        return { ...job, campaigns: allocatedData };
    });
};

export const transformOptimization = (scenario: IScenarioByIdApiResponse): IScenarioFormatted => {
    const {
        data: { attributes, id },
        included,
    } = scenario;

    const entities = getEntitiesFromIncludes(included, {
        users: {},
        jobs: {},
    });

    const allJobs = Object.keys(entities.jobs).length
        ? sortBy(
              groupBy(included, "type")
                  .jobs.map((j) => j.attributes)
                  .filter((j) => j.status === "completed"),
              "created_at",
          )
        : [];

    const allJobsExcludeNoChangeJob = allJobs.filter((job) => job.job_name !== NO_CHANGE_FORECAST_JOB);

    const noChangeJobList = allJobs.filter((job) => job.job_name === NO_CHANGE_FORECAST_JOB);

    const formattedAllJobsExcludeNoChangeJob = getFormattedJobs(allJobsExcludeNoChangeJob);

    const formattedNoChangeJob = getFormattedJobs(noChangeJobList);

    // find most recent active job with scenario instead of most date
    const jobStatus =
        Object.values((entities.jobs as IJob[]) || ({} as IJob))?.filter((a: IJob) => {
            return a.jobId === attributes.active_job_id;
        })[0] || {};

    const noChangeJobId = jobStatus.metadata?.scenario?.noChangeJobId || "";
    const noChangeJob = formattedNoChangeJob.find((job) => job.job_id === noChangeJobId);
    const isDemoMode = store.getState().isDemoMode;

    return {
        scenario: keysToCamelCase({
            ...attributes,
            name: isDemoMode ? `Scenario #${scenario.data.id}` : attributes.name,
            budget: +attributes.budget,
            scenarioCampaigns: attributes.scenario_campaigns.map((c) => {
                const demoCampaignName = getDemoCampaignName(c.connector_name);
                return {
                    ...c,
                    budget_allocated: +c.budget_allocated,
                    forecasted_revenue: +c.forecasted_revenue,
                    forecastedRoas: calculateROAS(+c.budget_allocated, +c.forecasted_revenue),
                    campaign_name: isDemoMode ? demoCampaignName : c.campaign_name,
                };
            }),
            campaignData: attributes.scenario_campaigns.map((c) => ({
                channel: c.channel,
                campaignId: c.campaign_id,
                isLocked: c.is_locked || false,
            })),
            id,
            jobStatus: jobStatus?.status || "processing",
            job: jobStatus,
            optimizationConfidence: attributes.min_confidence * 100,
            noChangeJob: noChangeJob || {},
            creatorId: Object.keys(entities.users)[0] || "",
        }),
        jobs: keysToCamelCase(formattedAllJobsExcludeNoChangeJob),
        allNoChangeJobs: keysToCamelCase(formattedNoChangeJob),
    };
};

export const formatScenarioResponse = (responseData: IScenarioApiResponse) => {
    let updatedData: IScenario[] = [];
    if (responseData?.data) {
        const isDemoMode = store.getState().isDemoMode;
        const { data, included, meta } = responseData;

        const entities = getEntitiesFromIncludes(included, {
            users: {},
            jobs: {},
        });

        updatedData = data.map((scenario) => {
            const { attributes } = scenario;
            const scenarioLevel = [...new Set(attributes.scenario_campaigns?.map((c) => c.campaign_id))].length;
            const isScenarioEditable = toDate(meta.last_model_run) <= toDate(attributes.created_at);

            const job: IJob = Object.values((entities.jobs as IJob[]) || ({} as IJob))
                ?.filter((a: IJob) => {
                    return scenario.id === `${a.actedOnId}`;
                })
                .reduce((a: IJob, b: IJob) => {
                    return toDate(a.createdAt) > toDate(b.createdAt) ? a : b;
                }, {} as IJob);

            const creatorId = scenario.relationships?.creator?.data?.id;
            const creatorName = isDemoMode ? "Creator X" : entities[INCLUDED_TYPE.USERS]?.[creatorId] ?? "Prescient AI";

            return keysToCamelCase({
                ...attributes,
                name: isDemoMode ? `Scenario #${scenario.id}` : attributes.name,
                campaignData: attributes.scenario_campaigns.map((c) => ({
                    channel: c.channel,
                    campaignId: c.campaign_id,
                })),
                scenarioCampaigns: attributes.scenario_campaigns.map((sc) => {
                    const demoCampaignName = getDemoCampaignName(sc.connector_name || "Demo Campaign");
                    return {
                        ...sc,
                        campaign_name: isDemoMode ? demoCampaignName : sc.campaign_name,
                    };
                }),
                scenarioLevel,
                id: scenario.id,
                creatorName,
                isScenarioEditable,
                jobStatus: job?.status || "processing",
                job,
            });
        });
    }
    return updatedData;
};

export const getTotalAllocation = (
    campaignData: IMetricAttributionTableValuesTransformedWithSpendAllocation[],
): IForecastedTotalMetric => {
    const totalSpend = campaignData.reduce((a, alloc) => a + (alloc.spend || 0), 0);
    const totalForecastedRevenue = campaignData.reduce((a, alloc) => a + (alloc.forecastedRevenue || 0), 0);
    const totalChange = campaignData.reduce((a, alloc) => a + (alloc.change || 0), 0);
    const totalAllocationSPend = totalSpend - totalChange;
    const totalForecastedRoas = calculateROAS(totalAllocationSPend, totalForecastedRevenue);
    const totalChangePercentages = calculatePercentage(totalAllocationSPend - totalSpend, totalSpend);

    const individualAllocationSpend = campaignData.map((c) => {
        return calculatePercentage(c.allocationSpend, totalAllocationSPend);
    });
    const totalAllocationSpendPercentages = individualAllocationSpend.reduce((c, a) => c + a, 0);

    const totalRevenue = campaignData.reduce(
        (a, alloc) => a + (alloc.trueRevenue && alloc.trueRevenue > 0 ? alloc.trueRevenue : 0),
        0,
    );
    const totalForecastedRevenuePercentages = calculatePercentage(totalForecastedRevenue - totalRevenue, totalRevenue);

    const totalRoas = calculateROAS(totalSpend, totalRevenue);
    const totalRoasPercentages = calculatePercentage(totalForecastedRoas - totalRoas, totalRoas);

    const totalSpendDiff = totalAllocationSPend - totalSpend;
    const totalRevenueDiff = totalForecastedRevenue - totalRevenue;
    const totalRoasDiff = totalForecastedRoas - totalRoas;

    return {
        allocationSpend: totalAllocationSPend,
        spend: totalSpend,
        totalAllocationSpendPercentages,
        change: totalChange,
        changePercentage: totalChangePercentages,
        forecastedRevenue: totalForecastedRevenue,
        forecastedRoas: totalForecastedRoas,
        totalForecastedRevenuePercentages,
        totalRoasPercentages,
        trueRevenue: totalRevenue,
        trueRoas: totalRoas,
        totalSpendDiff,
        totalRevenueDiff,
        totalRoasDiff,
    };
};

export const calculateTotalForSelectedCampaigns = (
    allCampaigns: IMetricAttributionTableValuesTransformed[],
): ISelectedTotalMetric => {
    // calculate spend, revenue and ROAS depend on campaign selection
    const totalObj = { spend: 0, trueRevenue: 0, trueRoas: 0, currentEstimatedDailySpend: 0, currentEstimatedSpend: 0 };
    totalObj.spend = allCampaigns.reduce((a, camapaign) => a + (camapaign.spend || 0), 0);
    totalObj.trueRevenue = allCampaigns.reduce(
        (a, camapaign) => a + ((camapaign.trueRevenue || 0) < 0 ? 0 : camapaign.trueRevenue || 0),
        0,
    );
    totalObj.trueRoas = calculateROAS(totalObj.spend, totalObj.trueRevenue);
    totalObj.currentEstimatedDailySpend = allCampaigns.reduce(
        (prev, curr) => prev + +(curr.currentEstimatedDailySpend || 0).toFixed(0),
        0,
    );
    totalObj.currentEstimatedSpend = allCampaigns.reduce(
        (prev, curr) => prev + +(curr.currentEstimatedSpend || 0).toFixed(0),
        0,
    );
    return totalObj;
};

export const getCleanValueTotalSum = (array: any[], key: string) => {
    return +array.reduce(
        (ac: number, a: { [x: string]: any }) => ac + +(a[key] || 0).toString().replace(/,/g, "") || 0,
        0,
    );
};

export const getPerformanceDataWithAllocationData = (
    scenarioCampaigns: IOptimizationModelResponse,
    campaignData: ICampaignData,
    forecastTimeframe: number,
    performanceData: Array<{
        campaignId: string;
        campaignName: string;
        connectorName: string;
        channelName: string;
        spend: number;
        trueRevenue: number;
        trueRoas: number;
        tacticId?: number;
    }>,
): IMetricAttributionTableValuesTransformedWithSpendAllocation[] => {
    const campaignIds = campaignData?.map((c) => c.campaignId);
    if (!campaignIds) {
        return [];
    }

    const scenarioMultiplier = forecastTimeframe || 0;
    const allocatedCampaignList = performanceData.filter((c) => campaignIds.includes(c.campaignId || ""));
    const totalSpendAllocated = scenarioCampaigns?.reduce((a, c) => a + +c.budgetAllocated, 0) || 0;

    return allocatedCampaignList.map((c) => {
        const modelbitAllocationCampaign = scenarioCampaigns?.find((s) => s.campaignId === c.campaignId);
        if (!modelbitAllocationCampaign) {
            return {
                ...c,
                scenarioCampaignId: undefined,
                allocationSpend: 0,
                forecastedRevenue: 0,
                forecastedRoas: 0,
                allocationPercentage: 0,
                change: 0,
                changePercentage: 0,
                maxPer: 100,
                minPer: 0,
                confidenceScore: 0,
                channel: c.channelName || "",
                isLocked: false,
                isEdited: false,
                totalSpendDiff: 0,
                totalRevenueDiff: 0,
                totalRoasDiff: 0,
            };
        } else {
            const forecastedRevenue = (+modelbitAllocationCampaign.forecastedRevenue || 0) * scenarioMultiplier;
            const forecastedRoas = calculateROAS(
                modelbitAllocationCampaign.budgetAllocated,
                modelbitAllocationCampaign.forecastedRevenue,
            );
            const allocationSpend =
                (scenarioCampaigns?.find((s) => s.campaignId === c.campaignId)?.budgetAllocated || 0) *
                scenarioMultiplier;
            const change = (c.spend || 0) - allocationSpend;
            const isLocked = scenarioCampaigns?.find((s) => s.campaignId === c.campaignId)?.isLocked || false;

            const totalSpendDiff = allocationSpend - c.spend;
            const totalRevenueDiff = forecastedRevenue - c.trueRevenue;
            const totalRoasDiff = forecastedRoas - c.trueRoas;

            const addedValuesObj = {
                allocationSpend,
                forecastedRevenue,
                forecastedRoas,
                allocationPercentage: calculatePercentage(allocationSpend, totalSpendAllocated * scenarioMultiplier),
                change,
                changePercentage: calculatePercentage(allocationSpend - (c.spend || 0), c.spend || 0),
                maxPer: 100,
                minPer: 0,
                confidenceScore: modelbitAllocationCampaign.confidenceScore,
                isLocked,
                isEdited: false,
                totalSpendDiff,
                totalRevenueDiff,
                totalRoasDiff,
            };

            return {
                ...c,
                scenarioCampaignId: modelbitAllocationCampaign.id,
                ...addedValuesObj,
                channel: modelbitAllocationCampaign.channel,
            };
        }
    });
};

export const getTrackedCampaigns = (
    scenarioCampaigns: IOptimizationModelResponse,
    forecastTimeframe: number,
    performanceData: IMetricAttributionTableValuesTransformed[],
): IMetricAttributionTableValuesTransformedWithSpendAllocation[] => {
    const scenarioMultiplier = forecastTimeframe || 0;
    const totalSpendAllocated = scenarioCampaigns?.reduce((a, c) => a + +c.budgetAllocated, 0) || 0;

    return scenarioCampaigns.map((scenarioCampaign) => {
        const forecastedRevenue = (+scenarioCampaign.forecastedRevenue || 0) * scenarioMultiplier;
        const forecastedRoas = calculateROAS(+scenarioCampaign.budgetAllocated, scenarioCampaign.forecastedRevenue);
        const allocationSpend = (+scenarioCampaign.budgetAllocated || 0) * scenarioMultiplier;

        const availablePerformance = performanceData.filter((p) => p.campaignId === scenarioCampaign.campaignId)[0];
        if (availablePerformance) {
            const change = (availablePerformance.spend || 0) - allocationSpend;

            const addedValuesObj = {
                allocationSpend,
                forecastedRevenue,
                forecastedRoas,
                allocationPercentage: calculatePercentage(allocationSpend, totalSpendAllocated * scenarioMultiplier),
                change,
                changePercentage: calculatePercentage(
                    allocationSpend - (availablePerformance.spend || 0),
                    availablePerformance.spend || 0,
                ),
                maxPer: 100,
                minPer: 0,
                confidenceScore: scenarioCampaign.confidenceScore,
                channel: scenarioCampaign.channel,
                isLocked: false,
                isEdited: false,
                totalSpendDiff: 0,
                totalRevenueDiff: 0,
                totalRoasDiff: 0,
            };
            return {
                ...availablePerformance,
                scenarioCampaignId: scenarioCampaign.id,
                ...addedValuesObj,
            };
        } else {
            return {
                ...scenarioCampaign,
                scenarioCampaignId: undefined,
                allocationSpend,
                forecastedRevenue,
                forecastedRoas,
                allocationPercentage: 0,
                change: 0 - allocationSpend,
                changePercentage: 0,
                maxPer: 100,
                minPer: 0,
                confidenceScore: scenarioCampaign.confidenceScore,
                channel: scenarioCampaign.channel,
                isEdited: false,
                totalSpendDiff: 0,
                totalRevenueDiff: 0,
                totalRoasDiff: 0,
            };
        }
    });
};

export const getOutcomePermuatations = (revenue: number, roas: number) => {
    if (revenue > 0 && roas > 0) {
        return "increased revenue and ROAS outcomes";
    } else if (revenue < 0 && roas < 0) {
        return "decreased revenue and ROAS outcomes";
    } else if (revenue < 0 && roas > 0) {
        return "decreased revenue and increased ROAS outcomes";
    } else if (revenue > 0 && roas < 0) {
        return "increased revenue and decreased ROAS outcomes";
    } else {
        return "";
    }
};

export const getSpend = (spend: number) => {
    if (spend >= -2.4 && spend <= 2.4) {
        return "on flat spend";
    } else if (spend <= -2.5) {
        return "on reduced spend";
    } else if (spend >= 2.5) {
        return "on increased spend";
    } else {
        return "";
    }
};

export const getPredictionSentence = (
    revenueChange: number,
    roasChange: number,
    spendPercentage: number,
    selectedScenario: Partial<IScenario>,
) =>
    `This scenario predicts ${getOutcomePermuatations(revenueChange, roasChange)} ${getSpend(
        spendPercentage,
    )} through ${format(
        addDays(toDate(selectedScenario.createdAt || new Date()), selectedScenario.forecastTimeframe || 28),
        "MM/dd/yyyy",
    )}.`;

export const getReportingDataText = (timeframeValue: number, startDate: string, endDate: string) => {
    return `Based on last full ${timeframeValue} days from date ${format(toDate(startDate), "MMM d, yyyy")} to ${format(
        toDate(endDate),
        "MMM d, yyyy",
    )}`;
};

export const getConfidenceLevel = (confidence: number | undefined | null) => {
    // return "" if null or undefined
    // sometimes the code is still sending in null values so we want to account for it.
    if (confidence == null) {
        return "";
    }

    const fixConfidence = +confidence.toFixed(2);
    if (fixConfidence <= 0.5) {
        return "Low";
    } else if (fixConfidence >= 0.51 && fixConfidence <= 0.69) {
        return "Med";
    } else if (fixConfidence >= 0.7 && fixConfidence <= 0.79) {
        return "Med-High";
    } else if (fixConfidence >= 0.8) {
        return "High";
    } else {
        return "";
    }
};

export const getPerChange = (description: string) => {
    const desArray = description.split(" ");
    if (desArray[0] === "No") {
        return `${desArray[desArray.length - 1]}`;
    }
    return `${desArray[0] === "Increase" ? "+" : "-"}${desArray[desArray.length - 1]}`;
};

export const getPacingValue = (
    forecastValue: number,
    totalForecastDays: number,
    actualValue: number,
    createdAt?: string,
    tab?: string,
) => {
    let dayDiff = createdAt ? differenceInDays(new Date(), toDate(createdAt)) : totalForecastDays;

    if (dayDiff > totalForecastDays) {
        dayDiff = totalForecastDays;
    }

    const forecastedAverage = forecastValue && totalForecastDays ? forecastValue / totalForecastDays : 0;
    const actualReportedAverage = actualValue && dayDiff ? actualValue / dayDiff : 0;

    if (tab === "ROAS") {
        return forecastValue < actualValue;
    }
    return forecastedAverage < actualReportedAverage;
};

export const recommendationsSentence = (selectedScenario: IScenario) =>
    selectedScenario?.description.includes("Increase")
        ? "Increase"
        : selectedScenario?.description.includes("Decrease")
        ? "Decrease"
        : "No change";

export const getGroupedConfidenceLevel = (
    scenarioCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[],
) => {
    if (!scenarioCampaigns) {
        return 0;
    }
    if (scenarioCampaigns.length) {
        const campaignsTotalSpend: any[] = [];
        const totalSpend = scenarioCampaigns.reduce((a, o) => a + +o.allocationSpend, 0);
        scenarioCampaigns.forEach((campaign) => {
            const weight =
                +campaign.allocationSpend && totalSpend ? +campaign.allocationSpend / totalSpend : 0.00000001;
            campaignsTotalSpend.push(weight * campaign.confidenceScore);
        });
        return campaignsTotalSpend.reduce((a, b) => a + b, 0);
    }
};

export const valueLabelFormat = (confidence: number) => {
    if (confidence < 50) {
        return "Low Confidence (very risky)";
    } else if (confidence >= 51 && confidence <= 69) {
        return "Medium Confidence (somewhat risky)";
    } else if (confidence >= 70 && confidence <= 79) {
        return "Medium-High Confidence (somewhat safe)";
    } else {
        return "High Confidence (very safe)";
    }
};
export const getPerformanceDataForNoChange = (
    performanceData: any[],
    forecastTimeframe: TIMEFRAME_OPTIONS | 1,
    supportedDataSources: IDataSource[] | string,
    noChangeJob?: INoChangeJob,
) => {
    if (noChangeJob?.campaigns?.length && isArray(supportedDataSources) && supportedDataSources.length) {
        return performanceData.map((pd) => {
            const cleanChannelName =
                supportedDataSources.find((c) => c.service === pd.channel.toLowerCase())?.name || pd.channel;
            const data: any = noChangeJob.campaigns?.find((c) => c.campaignId === pd.campaignId) || {};
            return {
                campaignId: pd.campaignId || "",
                campaignName: pd.campaignName || pd.campaignId || "",
                connectorName: pd.channel || "",
                channelName: cleanChannelName || "",
                spend: (data.budgetAllocated || 0) * forecastTimeframe,
                trueRevenue: (data.forecastedRevenue || 0) * forecastTimeframe,
                trueRoas: calculateROAS(
                    (data.budgetAllocated || 0) * forecastTimeframe,
                    (data.forecastedRevenue || 0) * forecastTimeframe,
                ),
                tacticId: pd.tacticId,
            };
        });
    }
    return [];
};

const getbaseGroupedData = (
    channel: string,
    campaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[],
) => {
    const spend = campaigns.reduce((prev, curr) => prev + (curr.spend ? curr.spend : 0), 0);
    const trueRevenue = campaigns.reduce((prev, curr) => prev + (curr.trueRevenue ? curr.trueRevenue : 0), 0);
    const trueRoas = calculateROAS(spend, trueRevenue);
    const allocationSpend = campaigns.reduce(
        (prev, curr) => prev + (curr.allocationSpend ? curr.allocationSpend : 0),
        0,
    );
    const forecastedRevenue = campaigns.reduce(
        (prev, curr) => prev + (curr.forecastedRevenue ? curr.forecastedRevenue : 0),
        0,
    );
    const forecastedRoas = calculateROAS(allocationSpend, forecastedRevenue);
    const change = +campaigns.reduce((prev, curr) => prev + (curr.change ? curr.change : 0), 0).toFixed(2);
    const totalChange = campaigns.reduce((a, alloc) => a + (alloc.change || 0), 0);

    const confidenceScore = getGroupedConfidenceLevel(campaigns);

    const totalSpend = campaigns.reduce((a, alloc) => a + (alloc.spend || 0), 0);
    const totalAllocationSPend = totalSpend - totalChange;
    const changePercentage = calculatePercentage(totalAllocationSPend - totalSpend, totalSpend);

    const totalSpendDiff = allocationSpend - spend;
    const totalRevenueDiff = forecastedRevenue - trueRevenue;
    const totalRoasDiff = forecastedRoas - trueRoas;

    return {
        spend,
        trueRevenue,
        trueRoas,
        campaigns,
        allocationSpend,
        forecastedRevenue,
        forecastedRoas,
        changePercentage,
        change,
        confidenceScore,
        totalSpendDiff,
        totalRevenueDiff,
        totalRoasDiff,
    };
};

export const getChannelGroupData = (Scampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[]) => {
    const campaigns = stableSort(
        Scampaigns,
        getComparator("asc", "channelName"),
    ) as IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    const channelGrp = groupBy(campaigns, "connectorName");

    // make channel group data with particular campaign belonging
    return Object.entries(channelGrp).map(([channel, campaigns]) => {
        const baseGroupedData = getbaseGroupedData(channel, campaigns);

        return {
            ...baseGroupedData,
            channelName: channel,
            connectorName: campaigns[0].connectorName || "",
            cleanChannelName: campaigns[0].channelName || "",
        };
    });
};

export const getTacticGroupData = (Scampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[]) => {
    const campaigns = stableSort(
        Scampaigns,
        getComparator("asc", "tacticId"),
    ) as IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    const tacticGrp = groupBy(campaigns, "tacticId");

    return Object.entries(tacticGrp).map(([tactic, campaigns]) => {
        const baseGroupedData = getbaseGroupedData(tactic, campaigns);

        return {
            ...baseGroupedData,
            tacticId: campaigns[0].tacticId,
        };
    });
};

export const getSummaryDataDependOnView = (
    totalAllocationCampaigns: IForecastedTotalMetric,
    performanceDataTotal: ISelectedTotalMetric,
): ISummary[] => {
    return [
        {
            title: "Total Spend",
            forecasted: totalAllocationCampaigns.allocationSpend,
            last: performanceDataTotal.spend,
            change: totalAllocationCampaigns.allocationSpend - performanceDataTotal.spend,
            percentageChange: totalAllocationCampaigns.changePercentage,
            unit: FORMATS.DOLLAR,
            fixed: 0,
        },
        {
            title: "Total Revenue",
            forecasted: totalAllocationCampaigns.forecastedRevenue,
            last: performanceDataTotal.trueRevenue,
            change: totalAllocationCampaigns.forecastedRevenue - performanceDataTotal.trueRevenue,
            percentageChange: totalAllocationCampaigns.totalForecastedRevenuePercentages,
            unit: FORMATS.DOLLAR,
            fixed: 0,
        },
        {
            title: "Total ROAS",
            forecasted: totalAllocationCampaigns.forecastedRoas,
            last: performanceDataTotal.trueRoas,
            change: totalAllocationCampaigns.forecastedRoas - performanceDataTotal.trueRoas,
            percentageChange: totalAllocationCampaigns.totalRoasPercentages,
            unit: FORMATS.NUMERIC,
            fixed: 2,
        },
    ];
};

export const getRecommendationsTableHeading = (
    forecastTimeframe: number,
    compareLabel: string,
): IDictionary<Array<{ label: string; id: string }>> => {
    return {
        forecast: [
            { label: "", id: "name" },
            { label: "This Forecast", id: "forecasted" },
        ],
        shift: [
            { label: `Next ${forecastTimeframe} Days Forecasts`, id: "title" },
            { label: `Expected (${compareLabel})`, id: "last" },
            { label: "Optimal (This Scenario)", id: "forecasted" },
        ],
        scale: [
            { label: `Next ${forecastTimeframe} Days Forecasts`, id: "title" },
            { label: `Expected (${compareLabel})`, id: "last" },
            { label: "Optimal (This Scenario)", id: "forecasted" },
        ],
    };
};

export const generateRowsForCSV = (
    rows: ISummary[],
    recommendationsTableHeading: Array<{
        label: string;
        id: string;
    }>,
) => {
    return rows.map((row: any) => {
        const obj: IDictionary = {};

        const generateCell = (column: { label: string; id: string }) => {
            obj[column.id] = formatValue(row[column.id], row.unit, row.fixed);
        };

        recommendationsTableHeading.forEach(generateCell);

        return obj;
    });
};

export const getTimeframeValue = (forecastTimeframe: TIMEFRAME_OPTIONS) =>
    TIMEFRAME_RADIO_OPTIONS.find((o) => o.value === forecastTimeframe)?.label || "";

export const getDateRangeForTimeframe = (selectedScenario: IScenario) => {
    if (selectedScenario) {
        const todayDate = toDate(selectedScenario.createdAt || new Date());
        return `${format(todayDate, "MMM d, yyyy")} - ${format(
            addDays(todayDate, selectedScenario.forecastTimeframe),
            "MMM d, yyyy",
        )}`;
    }
};

export const getTotalCampaignData = ({
    spend = 0,
    trueRevenue = 0,
    trueRoas = 0,
    allocationSpend = 0,
    forecastedRevenue = 0,
    forecastedRoas = 0,
}: Partial<IForecastedTotalMetric> = {}): IDictionary => {
    return {
        campaignName: "Total",
        confidenceScore: "",
        spend: formatValue(spend, FORMATS.DOLLAR, 0),
        trueRevenue: formatValue(trueRevenue, FORMATS.DOLLAR, 0),
        trueRoas: formatValue(trueRoas, FORMATS.NUMERIC, 2),
        allocationSpend: formatValue(allocationSpend, FORMATS.DOLLAR, 0),
        forecastedRevenue: formatValue(forecastedRevenue, FORMATS.DOLLAR, 0),
        forecastedRoas: formatValue(forecastedRoas, FORMATS.NUMERIC, 2),
    };
};

const revenueTabDecorator = (total: number) => {
    return {
        name: `Total`,
        value: total || "--",
        sign: FORMATS.DOLLAR,
        precision: 0,
    };
};

const mediaTabDecorator = (total: number, totalTabValue: { totalForecastingSpend: number }) => {
    return {
        name: "Media",
        value: total ?? "--",
        sign: FORMATS.DOLLAR,
        precision: 0,
        tooltipText: `Forecasted spend -- ${formatValue(totalTabValue.totalForecastingSpend || 0, FORMATS.DOLLAR, 0)}`,
    };
};

const holidaysTabDecorator = (total: number, totalTabValue: { holidayNames: string[] }) => {
    const holidayCount = totalTabValue?.holidayNames?.length || 0;
    const formattedHolidayNames =
        holidayCount > 0 ? totalTabValue.holidayNames.map((holiday) => replace(holiday, /_/g, " ")).join(", ") : "";

    return {
        name: "Holidays",
        value: total ?? "--",
        sign: FORMATS.DOLLAR,
        precision: 0,
        tooltipText: holidayCount > 0 ? formattedHolidayNames : "No holidays in forecast window",
    };
};

const trendTabDecorator = (total: number) => {
    return {
        name: "Trend",
        value: total ?? "--",
        sign: FORMATS.DOLLAR,
        precision: 0,
    };
};

export const COMPANY_FORECAST_TAB_FUNC_DICTIONARY: IDictionary = {
    [CompanyForecastMetricMapping.trendHolidayMarketing]: revenueTabDecorator,
    [CompanyForecastMetricMapping.marketing]: mediaTabDecorator,
    [CompanyForecastMetricMapping.totalHoliday]: holidaysTabDecorator,
    [CompanyForecastMetricMapping.trend]: trendTabDecorator,
};

export const COMPANY_BACKTEST_TAB_FUNC_DICTIONARY: IDictionary = {
    [CompanyForecastMetricMapping.trendHolidayMarketing]: (modeledMetricLabel: string, total: number) => {
        return {
            name: `Predicted Revenue`,
            value: total ?? "--",
            sign: FORMATS.DOLLAR,
            precision: 0,
            percentageChanges: "",
        };
    },
    [CompanyForecastMetricMapping.actualRevenue]: (modeledMetricLabel: string, total: number) => {
        return {
            name: `Actual Revenue`,
            value: total ?? "--",
            sign: FORMATS.DOLLAR,
            precision: 0,
            percentageChanges: "",
        };
    },
    [CompanyForecastMetricMapping.globalAccuracy]: (modeledMetricLabel: string, total: number, smape: number) => {
        return {
            name: "Accuracy Metric",
            value: total ?? "--",
            sign: FORMATS.PERCENT,
            titleAttribute: [
                `Accuracy can also be viewed in terms of prediction error (lower values are better).`,
                `In that case the average daily prediction error is ${formatValue(
                    smape,
                    FORMATS.PERCENT,
                    0,
                )} (as measured by sMAPE — a robust timeseries accuracy metric)`,
                `${
                    total < 75.0
                        ? `Lower accuracy scores can be expected if MMM is missing a significant portion of revenue driving impact, and/or if actual performance does not align with historical performance in the training window, such as an unplanned promotion.`
                        : ``
                }`,
            ],
            precision: 1,
            percentageChanges: "",
        };
    },
};

export const getChartKeys = (data: any) => {
    return Object.keys(data).filter((k) => k !== "accuracy" && k !== "totalForecast" && k !== "dates");
};

export const getTabTotalValue = (forecastKeys: string[], forecast: any, backtest: any, timeframe: number) => {
    const forecasTotalData = {
        values: { media: 0, trend: 0, holidays: 0, revenue: 0 },
        percentage: { media: 0, trend: 0, holidays: 0, revenue: 0, todayTrendPercentage: 0 },
        dateRange: "",
    };
    forecastKeys.forEach((v) => {
        if (v.includes("Channel")) {
            forecasTotalData.values.media += forecast[v].reduce((acc: number, b: any) => {
                return acc + b;
            }, 0);
        } else if (v.includes("trend")) {
            const forecastKeys = getChartKeys(forecast);
            const backtestKeys = getChartKeys(backtest);

            const forecastSeries = getIndexWiseSeries(forecast.dates, forecast, forecastKeys);
            const backtestSeries = getIndexWiseSeries(backtest.dates, backtest, backtestKeys);

            const totalTrendBacktest = backtestSeries.reduce(
                (ac: any, backtest: { trendForecast: any }) => ac + backtest.trendForecast,
                0,
            );
            const totalTrendForecast = forecastSeries.reduce(
                (ac: any, backtest: { trendForecast: any }) => ac + backtest.trendForecast,
                0,
            );

            const trendPercentage = calculatePer(totalTrendForecast, totalTrendBacktest);
            const avgTrendPercentage = calculatePer(totalTrendForecast / timeframe, totalTrendBacktest / timeframe);
            forecasTotalData.values.trend = forecast[v].reduce((acc: number, b: any) => {
                return acc + b;
            }, 0);

            forecasTotalData.percentage.trend = trendPercentage;
            forecasTotalData.percentage.todayTrendPercentage = avgTrendPercentage;
        } else {
            if (!v.includes("Channel") && !v.includes("Revenue") && !v.includes("trend")) {
                forecasTotalData.values.holidays = forecast[v].reduce((acc: number, b: any) => {
                    return acc + b;
                }, 0);
            }
        }
    });
    forecasTotalData.values.revenue = Object.values(forecasTotalData.values).reduce((acc: number, b: any) => {
        return acc + b;
    }, 0);
    forecasTotalData.dateRange = `${format(toDate(forecast.dates[0]), "MMM dd, yyyy")} - ${format(
        toDate(forecast.dates[forecast.dates.length - 1]),
        "MMM dd, yyyy",
    )}`;

    return forecasTotalData;
};

export const getPredictionAccuracyTotalValue = (chartData: ICompanyForecast) => {
    const { backtest, backtestTrue } = chartData;

    const predictedRevenue = backtest.totalForecast.reduce((acc: number, value: number) => acc + value, 0);
    const actualRevenue = backtestTrue.reduce((acc, value) => acc + value, 0);

    const dateRange = `${format(toDate(backtest.dates[0]), "MMM dd, yyyy")} - ${format(
        toDate(backtest.dates[backtest.dates.length - 1]),
        "MMM dd, yyyy",
    )}`;

    return {
        values: {
            predictedRevenue,
            actualRevenue,
            accuracyScore: (backtest.accuracy || 0) * 100,
        },
        percentage: {},
        dateRange,
    };
};

export const getIndexWiseSeries = (dates: any, data: any, keys: string[]) => {
    return dates.map((date: string, index: any) => {
        let allD = {};
        keys.map((k) => {
            allD = { ...allD, ...{ [k]: data[k][index] } };
        });
        return { date, ...allD };
    });
};

export const getCompanyForecastSeries = (chartData: any, supportedDataSources: IDataSource[]) => {
    const forecastKeys = getChartKeys(chartData.forecast);
    const backtestKeys = getChartKeys(chartData.backtest);

    const forecastSeries = getIndexWiseSeries(chartData.forecast.dates, chartData.forecast, forecastKeys);
    const backtestSeries = getIndexWiseSeries(chartData.backtest.dates, chartData.backtest, backtestKeys);

    const allTabSeries = [...backtestSeries, ...forecastSeries].map((s) => {
        const keys = Object.keys(s).filter((s) => s !== "date");
        const d: any = { media: 0, holidays: 0, trend: 0 };
        const mediaKeys = keys.filter((key) => key.includes("Channel"));
        const trendKeys = keys.filter((key) => key.includes("trend"));
        keys.map((key) => {
            if (mediaKeys.includes(key)) {
                d.media += s[key];
                if (Object.keys(d).includes(key)) {
                    d[key] += s[key];
                } else {
                    d[key] = s[key];
                }
            } else if (trendKeys.includes(key)) {
                d.trend += s[key];
            } else {
                if (!key.includes("Channel") && !key.includes("Revenue") && !key.includes("trend")) {
                    d.holidays += s[key];
                    if (Object.keys(d).includes(key)) {
                        d[key] += s[key];
                    } else {
                        d[key] = s[key];
                    }
                }
            }
        });

        return { ...d, date: s.date };
    });

    const finalChartSeriesDataTabWise = {
        revenue: allTabSeries.map((s) => ({
            media: s.media,
            date: s.date,
            holidays: s.holidays,
            trend: s.trend,
        })),
        media: allTabSeries.map((s) => {
            const keys = Object.keys(s).filter((s) => s.includes("Channel"));

            let campaignData = {};
            keys.forEach((key) => {
                const dataSource = supportedDataSources
                    .map((c) => ({ ...c, serviceWithout: c.service.replace("_", "") }))
                    .find(
                        (c: { serviceWithout: any }) =>
                            c.serviceWithout.toLowerCase() === key.toLowerCase().replace("channel", ""),
                    );
                const connectorName = dataSource?.name || key;
                campaignData = { ...campaignData, [connectorName]: s[key] };
            });
            return {
                date: s.date,
                ...campaignData,
            };
        }),
        trend: allTabSeries.map((s) => ({ date: s.date, trend: s.trend })),
        holidays: allTabSeries.map((s) => {
            const keys = Object.keys(s).filter(
                (s) => !s.includes("Channel") && s !== "holidays" && s !== "trend" && s !== "media",
            );
            let campaignData = {};
            keys.forEach((key) => {
                campaignData = { ...campaignData, [key]: s[key] };
            });
            return {
                date: s.date,
                ...campaignData,
            };
        }),
    };

    return finalChartSeriesDataTabWise;
};

export const formatCompanyForecastData = (response: ICompanyForecastResponse) => {
    let foremattedResponse = keysToCamelCase({ ...response.data });

    const totalRevenue: IDictionary = {};
    const forecastTotalRevenue: IDictionary = {};

    // Extracting and combining data for each channel
    for (const key in foremattedResponse.backtest) {
        if (key.includes("Revenue")) {
            const channel = key.split("Revenue")[0]; // Extracting the channel name
            const channelKey = `${channel}Channel`;

            totalRevenue[channelKey] = totalRevenue[channelKey] || [];

            const channelData = foremattedResponse.backtest[key] || [];
            totalRevenue[channelKey] = channelData.map(
                (value: any, i: number) => (totalRevenue[channelKey][i] || 0) + value,
            );
        }
    }

    // Extracting and combining data for each channel
    for (const key in foremattedResponse.forecast) {
        if (key.includes("Revenue")) {
            const channel = key.split("Revenue")[0]; // Extracting the channel name
            const channelKey = `${channel}Channel`;
            forecastTotalRevenue[channelKey] = forecastTotalRevenue[channelKey] || [];

            const channelData = foremattedResponse.forecast[key] || [];
            forecastTotalRevenue[channelKey] = channelData.map(
                (value: any, i: number) => (forecastTotalRevenue[channelKey][i] || 0) + value,
            );
        }
    }

    foremattedResponse = {
        ...foremattedResponse,
        backtest: { ...foremattedResponse.backtest, ...totalRevenue },
        forecast: { ...foremattedResponse.forecast, ...forecastTotalRevenue },
    };
    return foremattedResponse;
};

const grpByLogicForCompanyForecast = (grpByPeriod: any, getStartDateOfGroup: any) => {
    const groupedData = Object.entries(grpByPeriod).map(([day, value]: any) => {
        return {
            ...value[0],
            date: format(getStartDateOfGroup(toDate(value[0].date)), "yyyy-MM-dd"),
            media: value.reduce(
                (previousValue: any, currentValue: { media: any }) => previousValue + (currentValue.media || 0),
                0,
            ),
            holidays: value.reduce(
                (previousValue: any, currentValue: { holidays: any }) => previousValue + (currentValue.holidays || 0),
                0,
            ),
            trend: value.reduce(
                (previousValue: any, currentValue: { trend: any }) => previousValue + (currentValue.trend || 0),
                0,
            ),
        };
    });
    return groupedData.flat(1);
};

export const getSpecificGrpByCompanyForecastData = (sortedValuesByDate: any[], grpBy: string) => {
    let copySeriesDataHolder = [...sortedValuesByDate];

    switch (grpBy) {
        case chartGrpKey.WEEK:
            const weeks = [...sortedValuesByDate].reduce((m: any, o: any) => {
                const obj = { ...o };
                obj.weekDate = format(startOfWeek(toDate(o.date)), "yyyy-MM-dd");
                m.push(obj);
                return m;
            }, []);
            const grpByWeek = groupBy(weeks, "weekDate");
            copySeriesDataHolder = grpByLogicForCompanyForecast(grpByWeek, startOfWeek);
            break;

        case chartGrpKey.MONTH:
            const groupedByMonth = groupBy(sortedValuesByDate, (item) => {
                return item.date.substring(0, 7);
            });
            copySeriesDataHolder = grpByLogicForCompanyForecast(groupedByMonth, startOfMonth);
            break;

        case chartGrpKey.QUATER:
            const groupByQuarter = groupBy(
                sortedValuesByDate.map((d) => ({
                    ...d,
                    quarter: `${getYear(new Date(d.date))}-${getQuarter(new Date(d.date))}`,
                })),
                (item) => {
                    return item.quarter;
                },
            );
            copySeriesDataHolder = grpByLogicForCompanyForecast(groupByQuarter, startOfQuarter);
            break;

        case chartGrpKey.YEAR:
            const groupedByYear = groupBy(sortedValuesByDate, (item) => {
                return getYear(new Date(item.date));
            });
            copySeriesDataHolder = grpByLogicForCompanyForecast(groupedByYear, startOfYear);
            break;

        default:
            break;
    }
    return copySeriesDataHolder;
};

export const getCompanyForecastChartTooltip = (params: any, grpBy: string, todayTrendPercentage: number) => {
    const updatedParams = Array.isArray(params) ? params : [params];
    if (updatedParams[0].componentType === "markLine") {
        return (
            format(new Date(), "EEE, MMM dd") +
            updatedParams
                .map(
                    () =>
                        '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">' +
                        '<span style="display:inline-block;margin-right:4px;border-radius:10px;width:10px;height:10px;background-color:#1859E5;"></span>' +
                        "Trend" +
                        "</span><span style='margin-left:20px'><b>" +
                        formatValue(todayTrendPercentage, FORMATS.PERCENT, 2) +
                        "<span></b></li>",
                )
                .join("")
        );
    }
    const chartdate = getTooltipDateFormat(updatedParams[0] || "", grpBy);
    const tooltipData = updatedParams
        .map(
            (param: any) =>
                '<li style="list-style:none;display:flex;justify-content:space-between;"><span style="display:inline-block">' +
                param.marker +
                param.seriesName +
                "</span><span style='margin-left:20px'><b>" +
                getPreparedValue("$", param.value[1], 2) +
                "<span></b></li>",
        )
        .join("");
    return chartdate + tooltipData;
};

export const filterHolidaysKey = (keys: string[]) => {
    return keys.filter(
        (key) =>
            !key.includes("trendForecast") &&
            !key.includes("Channel") &&
            !key.includes("Revenue") &&
            !key.includes("accuracy") &&
            !key.includes("dates") &&
            !key.includes("totalForecast"),
    );
};

export const tableRowCalculation = (forecast: number, past: number, totalEcommerce: number) => {
    const predictedLiftDollar = forecast - past;
    return {
        actualPast: past,
        predictedFuture: forecast,
        perChange: calculatePercentage(forecast, past),
        predictedLiftDollar,
        predictedLiftPer: predictedLiftDollar && forecast ? (predictedLiftDollar / totalEcommerce) * 100 : 0,
    };
};

export const getPredictedLiftTableBody = (companyForecastResponse: ICompanyForecast, optimizationRevenue: number) => {
    const updatedOptimizationRevenue = optimizationRevenue < 0 ? 0 : optimizationRevenue;

    const forecastKeys = Object.keys(companyForecastResponse.forecast);
    const campaignKeys = forecastKeys.filter((key) => key.includes("Revenue"));
    const holidayKeys = filterHolidaysKey(forecastKeys);

    const totalForecastMediaTotal = campaignKeys
        .map((channel) => companyForecastResponse.forecast[channel].reduce((a: number, c: number) => a + c, 0))
        .reduce((a: number, c: number) => a + c, 0);

    const totalForecastTrendsTotal =
        companyForecastResponse.forecast.trendForecast?.reduce((a: number, c: number) => a + c, 0) || 0;

    const totalForecastholidayTotal = holidayKeys
        .map((holiday) => companyForecastResponse.forecast[holiday].reduce((a: number, c: number) => a + c, 0))
        .reduce((a: number, c: number) => a + c, 0);

    const totalEcommerce =
        updatedOptimizationRevenue + totalForecastMediaTotal + totalForecastTrendsTotal + totalForecastholidayTotal;

    const tableBody = [
        {
            decomposition: PredictedLiftTabs.EcommerceForecast,
            ...tableRowCalculation(
                totalEcommerce,
                totalForecastMediaTotal + totalForecastTrendsTotal + totalForecastholidayTotal,
                totalEcommerce,
            ),
        },
        {
            decomposition: PredictedLiftTabs.OptimizationPredictedLift,
            ...tableRowCalculation(+updatedOptimizationRevenue.toFixed(), 0, totalEcommerce),
        },
        {
            decomposition: PredictedLiftTabs.Media,
            ...tableRowCalculation(totalForecastMediaTotal, totalForecastMediaTotal, totalEcommerce),
        },
        {
            decomposition: PredictedLiftTabs.Trend,
            ...tableRowCalculation(totalForecastTrendsTotal, totalForecastTrendsTotal, totalEcommerce),
        },
        {
            decomposition: PredictedLiftTabs.Holidays,
            ...tableRowCalculation(totalForecastholidayTotal, totalForecastholidayTotal, totalEcommerce),
        },
    ];

    return optimizationRevenue < 0
        ? tableBody.filter((table) => table.decomposition !== PredictedLiftTabs.OptimizationPredictedLift)
        : tableBody;
};

export const getSearchedCampaigns = (campaigns: any[], searchText: string) => {
    return campaigns.filter((c) =>
        `${c.campaignId} ${c.campaignName} ${c.channelName} ${c.connectorName}`
            .toLowerCase()
            .includes(searchText.toLowerCase()),
    );
};

export const formatForecastResponse = (responseData: Forecast): ICompanyForecastFormattedResponse => {
    const underlinedData: ICompanyForecastFormattedResponse = keysToCamelCase(responseData);
    const updatedData = Object.keys(underlinedData).reduce((acc, timeframe) => {
        const castedTimeframe = timeframe as ForecastTimeframe;
        acc[castedTimeframe] = {
            ...underlinedData[castedTimeframe],
            holidayNames: Object.keys(responseData[castedTimeframe].individual_holidays || {}),
        };
        return acc;
    }, {} as ICompanyForecastFormattedResponse);
    return updatedData;
};

export const formatDateRangeForEcommerce = (minAndMaxDate: [string, string] | undefined) => {
    return minAndMaxDate?.[0] && minAndMaxDate?.[1]
        ? `${format(toDate(minAndMaxDate[0]), "MMM dd, yyyy")} - ${format(toDate(minAndMaxDate[1]), "MMM dd, yyyy")}`
        : "";
};
