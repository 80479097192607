import { styled } from "@mui/material/styles";
import RadioGroup, { useRadioGroup } from "@mui/material/RadioGroup";
import FormControlLabel, { FormControlLabelProps } from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import { FormControl } from "@mui/material";

export interface IRadioGroupProps {
    items: Array<{
        label: string;
        value: string;
    }>;
    value: string;
    handleChange: any;
    title: string;
    className: any;
}

interface IStyledFormControlLabelProps extends FormControlLabelProps {
    checked: boolean;
}

const StyledFormControlLabel = styled((props: IStyledFormControlLabelProps) => <FormControlLabel {...props} />)(
    ({ theme, checked }) => ({
        ".MuiFormControlLabel-label": checked && {
            color: theme.palette.primary.main,
        },
    }),
);

const MyFormControlLabel = (props: FormControlLabelProps) => {
    const radioGroup = useRadioGroup();
    let checked = false;
    if (radioGroup) {
        checked = radioGroup.value === props.value;
    }
    return <StyledFormControlLabel checked={checked} {...props} />;
};

export const CustomRadioGroup: React.FC<IRadioGroupProps> = ({ items, handleChange, title, value, className }) => {
    return (
        <FormControl className={className}>
            <label>{title}</label>
            <RadioGroup value={value} onChange={handleChange} row>
                {items.map((item) => (
                    <MyFormControlLabel
                        control={<Radio key={`${item.label}-radio`} />}
                        value={item.value}
                        label={item.label}
                        key={`${item.label}-control`}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};
