import Cookies from "js-cookie";

export const cleanUpUserInfo = (deleteCookies: boolean) => {
    if (deleteCookies) {
        Cookies.remove("session");
        Cookies.remove("currentUser");
        Cookies.remove("token");
    }
    sessionStorage.removeItem("token");
    sessionStorage.removeItem("currentUser");
    localStorage.removeItem("persist:root");
};
