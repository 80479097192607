import baseCreateApi from "src/reduxState/apis/baseCreateApi";

type EmailCode = typeof EMAIL_CODES[keyof typeof EMAIL_CODES];

export const EMAIL_CODES = {
    googleSheetsReady: "googleSheetsReady",
};

const emailRoutes: Record<EmailCode, string> = {
    googleSheetsReady: "google_sheets_ready",
};

export const emailSendingServices = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        sendEmail: builder.mutation({
            query: (args: { code: EmailCode; orgId: string }) => ({
                url: `organizations/${args.orgId}/emails/${emailRoutes[args.code]}`,
                method: "GET",
            }),
        }),
    }),
});

export const { useSendEmailMutation } = emailSendingServices;
