import baseCreateApi from "./baseCreateApi";
import { IRevenueTagResponse } from "../../interfaces/revenueTag/IRevenueTagResponse";

export interface IRevenueTag {
    tagName: string;
    id: string;
}

export const connectors = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getRevenueTag: builder.query({
            query: (args: { orgId: string; connectorId: string }) => ({
                url: `/organizations/${args.orgId}/connectors/${args.connectorId}}/revenue_tags`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IRevenueTagResponse[] }): IRevenueTag[] => {
                return response.data.map((r) => ({ tagName: r.attributes.tag_name, id: r.id }));
            },
        }),
        createRevenueTag: builder.mutation({
            query: (args: { orgId: string; connectorId: string; body: { tagType: string } }) => ({
                url: `/organizations/${args.orgId}/connectors/${args.connectorId}/revenue_tags`,
                method: "POST",
                body: JSON.stringify({ data: { attributes: { tag_name: args.body.tagType }, type: "revenue_tags" } }),
            }),
        }),
        editRevenueTag: builder.mutation({
            query: (args: { orgId: string; connectorId: string; body: { tagType: string } }) => ({
                url: `/organizations/${args.orgId}/connectors/${args.connectorId}/revenue_tags`,
                method: "PUT",
                body: JSON.stringify(args.body.tagType),
            }),
        }),
        deleteRevenueTag: builder.mutation({
            query: (args: { orgId: string; connectorId: string; tagId: string }) => ({
                url: `organizations/${args.orgId}/connectors/${args.connectorId}/revenue_tags/${args.tagId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useLazyGetRevenueTagQuery,
    useCreateRevenueTagMutation,
    useEditRevenueTagMutation,
    useDeleteRevenueTagMutation,
} = connectors;
