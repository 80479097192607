import React from "react";
import Bugsnag from "@bugsnag/js";
import { Button, Typography } from "@mui/material";
import { css } from "@emotion/css";

import { SVGIconRenderer } from "../components/SVGIconRenderer/SVGIconRenderer";

interface IProps {
    children: any;
}

interface IState {
    hasError: boolean;
    errorInfo: string;
}

const errorConent = css`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 10%;
`;

const errorInfoClass = css`
    margin-bottom: 15px !important;
`;

class ErrorBoundary extends React.Component<IProps, IState> {
    public static getDerivedStateFromError(error: any) {
        return { hasError: true, errorInfo: error.toString() };
    }
    constructor(props: any) {
        super(props);
        this.state = { hasError: false, errorInfo: "" };
    }

    public componentDidCatch(error: any) {
        this.setState({
            hasError: true,
            errorInfo: error.toString(),
        });

        if (error) {
            Bugsnag.notify(error);
        }
    }

    public render() {
        const { hasError, errorInfo } = this.state;
        const { children } = this.props;
        if (hasError) {
            return (
                <div className="error-container">
                    <div className={errorConent}>
                        <SVGIconRenderer icon="alertCircleIcon" />
                        <Typography variant="h5" component="h3">
                            Oops... Something went wrong 😟
                        </Typography>
                        <Typography component="p" className={errorInfoClass}>
                            {errorInfo}
                        </Typography>
                        <Button variant="contained" color="primary" onClick={() => window.location.reload()}>
                            Refresh Page
                        </Button>
                    </div>
                </div>
            );
        }

        return children;
    }
}
export default ErrorBoundary;
