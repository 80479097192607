import { css } from "@emotion/css";

import { FORMATS } from "src/enums/Formats";
import { formatValue } from "src/services/utils";

export const getValueSign = (value: number) => {
    return `${value === 0 ? "" : value > 0 ? "+" : ""}`;
};

export const FormattedValueClassName = (value: number) => {
    const classes = () => ({
        increaseText: css({ color: "#2F970B" }),
        decreaseText: css({ color: "#FF0000" }),
    });
    return value !== 0 ? (value > 0 ? classes().increaseText : classes().decreaseText) : "";
};

export const FormattedValue = ({ value, format, precision }: { value: number; format: FORMATS; precision: number }) => {
    return (
        <span className={FormattedValueClassName(+value.toFixed(precision))}>
            ({getValueSign(+value.toFixed(precision))}
            {formatValue(value, format, precision)})
        </span>
    );
};
