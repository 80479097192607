import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { IMember } from "../../interfaces/entities/IMember";
import { selectCurrentlyViewingId, setCurrentlyViewing } from "./organizationSlice";

const initialState: IMember[] = [];

const membersSlice = createSlice({
    name: "members",
    initialState,
    reducers: {
        setMembers: (state, { payload }) => payload,
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const { setMembers } = membersSlice.actions;

export default membersSlice.reducer;

// Selectors
export const membersSelector = (state: RootState) => state.members;

// Admin members selector
export const adminMembersSelector = createSelector([membersSelector, selectCurrentlyViewingId], (members, orgId) =>
    members.filter((m: IMember) => m.rolesByCompanyId?.[orgId]?.role === "admin"),
);
