import React, { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormikHelpers } from "formik";
import queryString from "query-string";
import { Stack } from "@mui/system";
import Bugsnag from "@bugsnag/js";

import { AcceptInvitationForm } from "../../components/AcceptInvitationForm/AcceptInvitationForm";
import { AlignItems } from "../../components/core/Flex/Flex";
import { FlexItem } from "../../components/core/FlexItem/FlexItem";
import { Message } from "../../components/Message/Message";
import { useAcceptInvitationMutation, useLoginApiMutation } from "../../reduxState/apis/authApi";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { IAcceptInvitationValues } from "../../interfaces/IAcceptInvitationValues";
import icon from "../../assets/Illustrations.png";
import { FooterForm } from "../../components/core/Form/FooterForm";
import { LOGIN_PATH, getDefaultRedirectPath } from "../../consts/path/path";
import { useDispatch, useSelector } from "react-redux";
import { loginError } from "src/reduxState/actions/auth";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";

export const AcceptInvitation: React.FC = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const defaultOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);

    const params = queryString.parse(location.search);
    const email = ((params.email || "") as string).replace(" ", "+");

    const [acceptInvitation] = useAcceptInvitationMutation();
    const [loginApi] = useLoginApiMutation();

    const initialValues: IAcceptInvitationValues = {
        invitationToken: queryString.parse(location.search).token as string,
        password: "",
        passwordConfirmation: "",
    };
    const [switchValue, setSwitchValue] = useState<string>("accept-invitation");

    useEffect(() => {
        // redirect to default organization path after accepting the invitation and login successfully
        if (defaultOrg) {
            navigate(`/org/${defaultOrg.code}${getDefaultRedirectPath(defaultOrg)}`);
        }
    }, [defaultOrg]);

    const handleSubmit = async (
        values: IAcceptInvitationValues,
        formikHelpers: FormikHelpers<IAcceptInvitationValues>,
    ) => {
        await acceptInvitation(values)
            .unwrap()
            .then(() => {
                formikHelpers.setSubmitting(false);
                // after accepting the invitation, login the user
                loginApi({ email, password: values.password, remember_me: false })
                    .unwrap()
                    .catch((err: any) => {
                        Bugsnag.notify(err);
                        dispatch(
                            loginError(
                                err.data && err.data.errors
                                    ? err.data.errors[0].detail
                                    : "Error message is not provided",
                            ),
                        );
                        return formikHelpers.setErrors(
                            transformApiErrors({ message: "Password or email is not correct" }),
                        );
                    });
            })
            .catch((err) => {
                // error handling for accepting the invitation
                if (err.status === 422) {
                    setSwitchValue("invalid-token");
                } else {
                    setSwitchValue("server-error");
                }
                formikHelpers.setSubmitting(false);
                formikHelpers.setErrors(transformApiErrors({ message: "Error" }));
            });
    };

    return (
        <FlexItem alignSelf={AlignItems.Center}>
            {switchValue === "accept-invitation" && (
                <AcceptInvitationForm email={email} initialValues={initialValues} onSubmit={handleSubmit} />
            )}
            {switchValue === "invalid-token" && (
                <Stack alignSelf="center" gap="30px">
                    <Message
                        icon={icon}
                        title="Your invitation has expired. 😟 "
                        subtitle="If you need another invite, contact your company admin."
                    />
                    <FooterForm
                        cancelBtnProps={{
                            text: "Back to Login",
                            onCancelBtnClick: () => navigate(LOGIN_PATH),
                            startIcon: true,
                        }}
                    />
                </Stack>
            )}
            {switchValue === "server-error" && (
                <Stack alignSelf="center" gap="30px">
                    <Message icon={icon} title="There was a server error. 😟 " subtitle="Try again later" />
                    <FooterForm
                        cancelBtnProps={{
                            text: "Back to Login",
                            onCancelBtnClick: () => navigate(LOGIN_PATH),
                            startIcon: true,
                        }}
                    />
                </Stack>
            )}
        </FlexItem>
    );
};
