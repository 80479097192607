import React, { FC, useEffect } from "react";
import { CardHeader, DialogContent, IconButton, Paper, Tab, Tabs, Theme } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { Box, useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import {
    closeEmailInsightsmodal,
    handleEmailInsightsModalMinimization,
    emailInsightsModalSelector,
    setEmailInsightsModalActiveTab,
} from "../../../reduxState/slices/emailInsightsModalSlice";
import { selectCurrentlyViewingCode } from "../../../reduxState/slices/organizationSlice";
import { handleDeleteParamKey } from "../../../services/performancePage/performancePage";
import { useStylesForInsightsModal } from "../../../components/CampaignComponents/InsightsModal/InsightsModal";
import { EmailTabHolderComponent } from "./EmailTabHolderComponent";
import { IEmail } from "../../../interfaces/entities/IEmail";
import { ActivePerformanceTabs } from "../../../enums/ActivePerfomanceTabs";

interface IInsightsModal {
    modalInfo: {
        data: IEmail;
        isMinimize: boolean;
        activeTab: ActivePerformanceTabs;
    };
}

export const InsightsModal: FC<IInsightsModal> = ({ modalInfo }) => {
    const dispatch = useDispatch();
    const { data, isMinimize, activeTab } = modalInfo;
    const { campaignName, campaignId } = data;

    const classes = useStylesForInsightsModal(useTheme());
    const insightsModalList = useSelector(emailInsightsModalSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const navigate = useNavigate();

    const resizeWindow = () => {
        const windowSize = window.innerWidth;
        if (insightsModalList.length >= 2 && windowSize < 1300) {
            dispatch(closeEmailInsightsmodal(insightsModalList[1].data.campaignId));
        }
    };

    useEffect(() => {
        // if window size < 1300 then close last opened modal
        resizeWindow();
        window.addEventListener("resize", resizeWindow);
        return () => window.removeEventListener("resize", resizeWindow);
    }, []);

    useEffect(() => {
        dispatch(
            setEmailInsightsModalActiveTab({
                campaignId,
                activeTab,
            }),
        );
    }, [activeTab]);

    const handleCloseModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        handleDeleteParamKey("viewing[]", navigate);
        dispatch(closeEmailInsightsmodal(data.campaignId));
    };

    const handleminimizeModal = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        dispatch(handleEmailInsightsModalMinimization(data.campaignId));
    };

    const handleOpenNew = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
        e.stopPropagation();
        localStorage.setItem("currentEmailCampaignDetails", JSON.stringify(data));
        window.open(`/org/${orgCode}/email/${campaignId}/${activeTab}${window.location.search}`, "_blank");
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => {
        dispatch(
            setEmailInsightsModalActiveTab({
                campaignId,
                activeTab: newValue,
            }),
        );
    };
    return (
        <div style={isMinimize ? { position: "relative", width: "260px" } : {}}>
            <Paper
                component="div"
                elevation={3}
                className={`customInsightsModalClass_${campaignId} ${classes.dialogPosition} ${classes.modalRoot} ${
                    isMinimize && classes.extraClass
                } customInsightsModalClass`}
                id={`customInsightsModalClass_${campaignId}`}
            >
                <CardHeader
                    onClick={handleminimizeModal}
                    className={classes.cardHeader}
                    action={
                        <div>
                            <IconButton
                                color="inherit"
                                onClick={handleminimizeModal}
                                aria-label="minimize"
                                size="small"
                                title="Minimize"
                            >
                                {isMinimize ? (
                                    <SVGIconRenderer icon="maximizeIcon" strokeColor="#BDBDBD" />
                                ) : (
                                    <SVGIconRenderer icon="minimizeIcon" strokeColor="#BDBDBD" />
                                )}
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleOpenNew}
                                aria-label="openInNew"
                                size="small"
                                title="Open in new"
                            >
                                <SVGIconRenderer icon="externalLinkIcon" strokeColor="#BDBDBD" />
                            </IconButton>
                            <IconButton
                                color="inherit"
                                onClick={handleCloseModal}
                                aria-label="close"
                                size="small"
                                title="Close"
                            >
                                <SVGIconRenderer icon="closeIcon" strokeColor="#BDBDBD" />
                            </IconButton>
                        </div>
                    }
                    title={<span title={campaignName || ""}>{campaignName}</span>}
                    id={`insights-header-${campaignId}`}
                />
                <DialogContent className={`${isMinimize ? classes.miniMizeClass : classes.heigthClass}`}>
                    <EmailTabHolderComponent
                        handleTabChange={handleTabChange}
                        tabValue={activeTab}
                        performanceCampaignData={data}
                    />
                </DialogContent>
            </Paper>
        </div>
    );
};
