import React, { useState } from "react";
import { IHeadCell } from "../../../consts/emailPage/emailPage";
import { DraggableColumnSelect } from "../../DraggableColumnSelect/DraggableColumnSelect";

interface IDraggableEmailColumnSelect {
    selectedTableColumns: IHeadCell[];
    setselectedTableColumns: React.Dispatch<React.SetStateAction<IHeadCell[]>>;
    handleTableCol: (column: IHeadCell) => void;
}

export const DraggableEmailColumnSelect: React.FC<IDraggableEmailColumnSelect> = ({
    handleTableCol,
    selectedTableColumns,
    setselectedTableColumns,
}) => {
    const [cards, setCards] = useState(selectedTableColumns.filter((c) => c.id !== "campaignName"));

    const updateColumnsInSettingsApi = (selectedCols: IHeadCell[]) => {};

    return (
        <DraggableColumnSelect
            handleTableCol={handleTableCol}
            selectedTableColumns={selectedTableColumns}
            setselectedTableColumns={setselectedTableColumns}
            updateColumnsInSettingsApi={updateColumnsInSettingsApi}
            onClickHandleOpen={() => {}}
            cards={cards}
            setCards={setCards}
        />
    );
};
