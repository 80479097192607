import React from "react";
import { useSelector } from "react-redux";
import { Navigate, PathRouteProps } from "react-router-dom";

import { userIdSelector } from "../../reduxState/slices/userSlice";

interface ILoggedInRouteProps extends PathRouteProps {
    children?: any;
}

export const AutoLoginRoute: React.FC<ILoggedInRouteProps> = ({ children }) => {
    const isLoggedIn = useSelector(userIdSelector);

    if (isLoggedIn) {
        return <Navigate to={"/"} />;
    }

    return children;
};
