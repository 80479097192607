import React, { useEffect, useState } from "react";
import { FormikHelpers } from "formik";
import { useNavigate, useLocation } from "react-router-dom";
import Snackbar from "@mui/material/Snackbar";
import Bugsnag from "@bugsnag/js";

import { Flex } from "../../components/core/Flex/Flex";
import { ForgotPasswordForm } from "../../components/ForgotPasswordForm/ForgotPasswordForm";
import { LoginForm } from "../../components/LoginForm/LoginForm";
import { useForgotPasswordMutation, useLoginApiMutation } from "../../reduxState/apis/authApi";
import { loginError } from "../../reduxState/actions/auth";
import { useSelector, useDispatch } from "react-redux";
import { isSuperAdminSelector, userStateSelector } from "../../reduxState/slices/userSlice";
import { SUPERADMIN_ORG_PATH } from "../../consts/path/path";
import { IEmailValues } from "../../interfaces/IEmailValues";
import { IPageProps } from "../../interfaces/IPageProps";
import { IUserLoginValues } from "../../interfaces/IUserLoginValues";
import { transformApiErrors } from "../../utils/transformApiErrors";
import {
    selectCompanyIdByCode,
    selectCurrentlyViewingCompanyById,
    setCurrentlyViewing,
} from "../../reduxState/slices/organizationSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { useLazyGetOrganizationsQuery } from "../../reduxState/apis/organizationApi";
import { NO_ORGANIZATION_PATH } from "../../consts/path/path";
import { transformOrganization } from "../../services/organization/organization";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { getDefaultRedirectPath } from "src/consts/path/path";
import { store } from "src/reduxState/stores/store";

interface ILoginPageProps extends IPageProps {}

export const Login: React.FC<ILoginPageProps> = () => {
    const initialValues: IUserLoginValues = {
        email: "",
        password: "",
        remember_me: false,
    };
    const dispatch = useDispatch();
    const [mode, setMode] = useState<string>("login");
    const [rememberMe, setRememberMe] = useState<boolean>(false);
    const [isModalOpen, setModalOpen] = useState<boolean>(false);
    const [loginApi, { error }] = useLoginApiMutation();
    const [forgetPassword, { error: forgotPasswordErrors }] = useForgotPasswordMutation();
    const user = useSelector(userStateSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const defaultOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);

    const navigate = useNavigate();
    const location = useLocation();
    const [getOrganizations] = useLazyGetOrganizationsQuery();
    const isRedirect = location.search && location.search.includes("redirect");
    const orgCodeFromPath = location.search ? location.search.split("/")[1] : "";
    const redirectLocation = location.search && `/${location.search.split("redirect=")[1]}`;

    const getOrgs = () => {
        getOrganizations("")
            .unwrap()
            .then((res) => {
                const orgResponseBody = transformOrganization(res.data);
                if (orgResponseBody) {
                    const validOrg = orgResponseBody.filter((d: IOrganization) => d.code === orgCodeFromPath);
                    if (validOrg.length) {
                        dispatch(setCurrentlyViewing(validOrg[0].code));
                        navigate(redirectLocation);
                        return;
                    } else {
                        if (redirectLocation.includes("adm")) {
                            navigate(redirectLocation);
                            return;
                        } else {
                            dispatch(setCurrentlyViewing(orgResponseBody[0].code));
                            navigate("/404");
                            return;
                        }
                    }
                }
            })
            .catch((error) => {
                throw new Error(`getOrganization error on login ${error}`);
            });
    };

    useEffect(() => {
        if (isSuperAdmin && isRedirect) {
            getOrgs();
        }
    }, [user]);

    useEffect(() => {
        // If location include redirect param
        if (user && defaultOrg && isRedirect) {
            dispatch(setCurrentlyViewing(orgCodeFromPath));
            navigate(redirectLocation);
            return;
        }

        // On login always route superadmins to the admin route.
        if (isSuperAdmin) {
            navigate(SUPERADMIN_ORG_PATH);
            return;
        }

        if (user && defaultOrg) {
            navigate(`/org/${defaultOrg.code}${getDefaultRedirectPath(defaultOrg)}`);
        }
    }, [user, defaultOrg]);

    const handleLogin = () => {
        setMode("login");
    };

    const handleRememberMe = (value: boolean) => {
        setRememberMe(value);
    };

    const handleForgotPassword = () => {
        setMode("forgotPassword");
    };

    const handleEmailSend = () => {
        setMode("login");
        setModalOpen(true);
    };

    const handleEmailClose = () => {
        setModalOpen(false);
    };

    const handleForgotPasswordSubmit = async (values: IEmailValues, formikHelpers: FormikHelpers<IEmailValues>) => {
        await forgetPassword(values.email)
            .unwrap()
            .then(() => {
                handleEmailSend();
            })
            .catch(() =>
                formikHelpers.setErrors(transformApiErrors({ message: "Oops! This email address is incorrect." })),
            );
        formikHelpers.setSubmitting(false);
    };

    const handleSubmit = async (values: IUserLoginValues, formikHelpers: FormikHelpers<IUserLoginValues>) => {
        await loginApi({ email: values.email, password: values.password, remember_me: rememberMe })
            .unwrap()
            .then((res) => {
                const types = res.included.map((i: any) => i.type);
                if (!types.includes("companies")) {
                    navigate(NO_ORGANIZATION_PATH);
                }
            })
            .catch((err: any) => {
                const isWrongEmailOrPassword =
                    err?.status === 422 && err?.data?.errors?.[0]?.detail === "Wrong email or password";
                if (!isWrongEmailOrPassword) {
                    Bugsnag.notify(err);
                }
                dispatch(loginError(err?.data?.errors?.[0]?.detail ?? "Error message is not provided"));
                return formikHelpers.setErrors(transformApiErrors({ message: "Password or email is not correct" }));
            });
        formikHelpers.setSubmitting(false);
    };

    return (
        <React.Fragment>
            {mode === "forgotPassword" && (
                <ForgotPasswordForm
                    initialValues={initialValues}
                    onSubmit={handleForgotPasswordSubmit}
                    onBackToLogin={handleLogin}
                />
            )}
            {mode === "login" && (
                <LoginForm
                    initialValues={initialValues}
                    onSubmit={handleSubmit}
                    onForgotPassword={handleForgotPassword}
                    onKeepLoggedIn={handleRememberMe}
                />
            )}
            <Snackbar
                data-cy="emailSentSnackbar"
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={6000}
                message={
                    <Flex>
                        <SVGIconRenderer icon="mailIcon" style={{ fill: "white", marginRight: "12px" }} />
                        <span>E-mail Sent - Check your spam folder if you don't receive an E-mail soon.</span>
                    </Flex>
                }
                open={isModalOpen}
                onClose={handleEmailClose}
            />
        </React.Fragment>
    );
};
