import React from "react";
import { AppBar, IconButton, Toolbar, Typography } from "@mui/material";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

export const ScenaioAppBar = ({
    handleExitModal,
    heading,
    isCloseDisabled = false,
    isCloseIcon = true,
}: {
    handleExitModal?: any;
    heading: any;
    isCloseIcon?: boolean;
    isCloseDisabled?: boolean;
}) => {
    return (
        <AppBar
            sx={{
                paddingRight: "0!important",
                backgroundColor: "#FFFFFF",
                boxShadow: "inset 0px -1px 0px #D9D9D9",
            }}
            position="sticky"
        >
            <Toolbar sx={{ paddingLeft: "30px!important", paddingRight: "30px!important" }}>
                <Typography sx={{ flex: 1 }} variant="h3" color="#000000">
                    {heading}
                </Typography>
                {isCloseIcon && handleExitModal && (
                    <IconButton
                        sx={{ padding: 0 }}
                        onClick={handleExitModal}
                        aria-label="close"
                        disabled={isCloseDisabled}
                    >
                        <SVGIconRenderer icon="closeIcon" />
                    </IconButton>
                )}
            </Toolbar>
        </AppBar>
    );
};
