import { Store } from "@reduxjs/toolkit";
import { IDictionary } from "../interfaces/IDictionary";
import { IStateObserver } from "../interfaces/analytics/IStateObserver";

const getValuesAndCallback = (store: Store, observers: IStateObserver, previousValues: IDictionary) =>
    Object.keys(observers).reduce((obj, key) => {
        const [selector, callback] = observers[key];

        const previousValue = previousValues[key];
        const value = selector(store.getState());

        if (previousValue !== value) {
            callback(value, previousValue, store.getState());
            obj[key] = value;
        }
        return obj;
    }, previousValues);

export const subscribe =
    (observers: IStateObserver, invokeImmediately = true) =>
    (store: Store) => {
        let values = {};

        if (invokeImmediately) {
            values = getValuesAndCallback(store, observers, values);
        }

        return store.subscribe(() => {
            getValuesAndCallback(store, observers, values);
        });
    };
