import React, { ElementType } from "react";
import Badge from "@mui/material/Badge";
import { NavLink as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Theme } from "@mui/material/styles";
import { DistributiveOmit } from "@mui/types";
import { useTheme } from "@mui/system";

import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../services/utils";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const useStyles = (theme: Theme) => ({
    subListArrow: {
        position: "absolute",
        color: theme.palette.grey[600],
        marginLeft: theme.spacing(3 * -1),
    },
});

interface IListItemLinkProps {
    icon?: React.ReactElement;
    primary: string;
    hasSublist?: boolean;
    to?: string;
    open?: boolean;
    onClick?: React.MouseEventHandler;
    disabled?: boolean;
    className?: string;
    badge?: number;
    id?: string;
}

export function ListItemLink({
    disabled = false,
    hasSublist = false,
    icon,
    onClick,
    open = false,
    primary,
    to,
    className,
    badge = 0,
    id,
    ...rest
}: IListItemLinkProps) {
    const classes = useStyles(useTheme());
    const analyticsService = useAnalyticsService();

    const handleAnalyticsClick = (e: React.MouseEvent) => {
        if (to) {
            analyticsService.logEvent("Primary Nav Clicked", {
                Page: getPathForAnalytics(to),
            });
        }
        onClick && onClick(e);
    };

    let renderLink: ElementType = "li";

    if (to) {
        renderLink = React.useMemo(
            () =>
                React.forwardRef<any, DistributiveOmit<RouterLinkProps, "to">>((itemProps, ref) => (
                    <RouterLink
                        data-cy={`${id?.toLowerCase()}Link`}
                        id={`${id?.toLowerCase()}Link`}
                        to={to}
                        className={({ isActive }) => (isActive ? "active" : "")}
                        ref={ref}
                        {...itemProps}
                    />
                )),
            [to],
        );
    }

    let ListItemComp = (
        <ListItem
            button
            disabled={disabled}
            onClick={handleAnalyticsClick}
            component={renderLink}
            className={className}
        >
            {hasSublist ? (
                open ? (
                    <SVGIconRenderer icon="chevronDownIcon" className={classes.subListArrow} />
                ) : (
                    <SVGIconRenderer icon="arrowRightIcon" />
                )
            ) : null}
            {icon ? (
                <ListItemIcon sx={{ minWidth: (theme) => theme.spacing(6), color: (theme) => theme.palette.grey[600] }}>
                    {icon}
                </ListItemIcon>
            ) : null}
            <Badge
                badgeContent={badge}
                color="primary"
                classes={{
                    anchorOriginTopRightRectangular: "top right",
                }}
            >
                <ListItemText primary={primary} />
            </Badge>
        </ListItem>
    );

    if (renderLink !== "li") {
        ListItemComp = <li style={{ listStyle: "none" }}>{ListItemComp}</li>;
    }

    return ListItemComp;
}
