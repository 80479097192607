import React, { FC, useEffect, useMemo, useState } from "react";
import { flushSync } from "react-dom";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { differenceInDays, format } from "date-fns";
import { Theme, Typography, Button } from "@mui/material";
import { useSnackbar } from "notistack";
import uniqueId from "lodash/uniqueId";
import orderBy from "lodash/orderBy";
import { Stack } from "@mui/system";

import { css } from "@emotion/css";
import GroupBySelection from "src/components/CampaignComponents/InsightsModal/PerformanceTab/GroupBySelection";
import { ChartTemplateComponent } from "src/components/Charts/ChartTemplateComponent/ChartTemplateComponent";
import SimpleReactDateRange from "src/components/DateRangeFilter/SimpleReactDateRange";
import {
    DefaultDateRangeInText,
    getRangeFormattedTitle,
    pastRangeBasedOnCurrentRange,
} from "src/components/DateRangeFilter/reactDateRangeUtils";
import { ExportTableData } from "src/components/ExportTableData/ExportTableData";
import { LightModal } from "src/components/LightModal/LightModal";
import { WHITE } from "src/consts/colors";
import { GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4 } from "src/consts/connectors";
import {
    DataValueView,
    IPerformanceKpiMetricsTabsType,
    KeyKPIPerformanceTabTypes,
    MetricNameTypeForPayload,
    PERFORMANCE_KPI_METRICS_TABS,
    defaultDateRangeForHomePage,
    getMetricTooltipText,
    groupByChannelOption,
} from "src/consts/homeDashboard/homeDashboard";
import { warningModalText } from "src/consts/onboardingPage/onboardingPage";
import { NEW_CONNECTOR_PATH, PERFORMANCE_CHANNELS_PATH, getDefaultRedirectPath } from "src/consts/path/path";
import { chartGrpKey, isCACAvailableForCompany } from "src/consts/performancePaidPage/performancePaidPage";
import { DataSourceCategory } from "src/enums/dataSourceCategory";
import { IDataSource } from "src/interfaces/IData";
import { IDictionary } from "src/interfaces/IDictionary";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { IDateRange, IKpiPerformanceMetric } from "src/interfaces/dashboard/trend";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { useCreateConnectorMutation } from "src/reduxState/apis/connectorsApi";
import {
    useLazyGetAtiributionMetricTimeSeriesByNameQuery,
    useLazyGetMetricTimeSeriesByNameQuery,
} from "src/reduxState/apis/historicalApi";
import {
    connectorsSelector,
    defaultRevenueSourceSelector,
    isAmazonConnectedSelector,
} from "src/reduxState/slices/connectorsSlice";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
    selectCurrentlyViewingId,
} from "src/reduxState/slices/organizationSlice";
import {
    availableDataSourcesSelector,
    dataSourcesByProgrammaticNameSelector,
    supportedDataSourcesSelector,
} from "src/reduxState/slices/supportedDataSourcesSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { IExportHead, exportCSV, prepareDataForCSV } from "src/services/exportCSV";
import { defaulMetricData, getSpecificGrpByEcommerceData } from "src/services/homeDashboard/homeDashboard";
import {
    addChannelNameToData,
    getSelectedTab,
    getTargetOptions,
    mapAttributionSeriesData,
    processApiResponse,
} from "src/services/homeDashboard/homePageTab";
import { buildConnectionCardUrl, capitalizeFirstLetter, getPathForAnalytics } from "src/services/utils";
import { DashboardCardHeader } from "../CardComponents/CardHeader/CardHeader";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { TargetSelect } from "../CardComponents/TargetSelect";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { AbsoluteVsPercentagesSelect } from "../CardComponents/AbsoluteVsPercentagesSelect";

export const useEcommerceStyles = (theme: Theme) => ({
    popper: css({
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-start",
        borderRadius: theme.spacing(0.5),
        padding: theme.spacing(1),
        backgroundColor: WHITE,
        fontSize: "12px",
        letterSpacing: "0.4px",
        boxShadow:
            "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px rgba(0, 0, 0, 0.14), 0px 1px 10px rgba(0, 0, 0, 0.12)",
        color: "rgba(0, 0, 0, 0.6)",
    }),
});

interface IKpiPerformanceCard {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const KpiPerformanceCard: FC<IKpiPerformanceCard> = ({ tooltipRef }) => {
    const navigate = useNavigate();
    const analyticsService = useAnalyticsService();
    const { enqueueSnackbar } = useSnackbar();
    const chartTitle = useMemo(() => "KPI Report", []);

    const connectors: IDataSource[] = useSelector(connectorsSelector);
    const newConnectorsList = useSelector(availableDataSourcesSelector);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const currentCompany: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const dataSources = useSelector(dataSourcesByProgrammaticNameSelector);
    const currentlyViewingId = useSelector(selectCurrentlyViewingId);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const isAmazonConnected = useSelector(isAmazonConnectedSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const partialStateForHome = useSelector(partialStateSelectorForHome);

    const [dateRange, setDateRange] = useState<{
        selection: IDateRange;
        compare: IDateRange;
    }>(defaultDateRangeForHomePage);
    const [ecommerceState, setEcommerceState] = useState<KeyKPIPerformanceTabTypes>("modeled_revenue");
    const [legendTotal, setLegendTotal] = useState<any[]>([]);
    const [seriesData, setSeriesData] = useState<IChartItem[]>([]);
    const [pastSeriesData, setPastSeriesData] = useState<IChartItem[]>([]);
    const [spendData, setSpendData] = useState<IChartItem[]>([]);
    const [pastSpendData, setPastSpendData] = useState<IChartItem[]>([]);
    const [currentApiResponse, setCurrentApiResponse] = useState<IKpiPerformanceMetric>({});
    const [pastApiResponse, setPastApiResponse] = useState<IKpiPerformanceMetric>({});
    const [isLoading, setIsLoading] = useState(false);

    const [grpBy, setGrpBy] = useState(chartGrpKey.DAY);
    const [compareMode, setCompareMode] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [dataSource, setDataSource] = useState<IDataSource | null>(null);
    const [channelGrpBy, setChannelGrpBy] = useState(groupByChannelOption[0].id);
    const [dataValueView, setDataValueView] = useState(DataValueView.Absolute);

    const [getTimeSeries, { isError: isSeriesError }] = useLazyGetMetricTimeSeriesByNameQuery();
    const [getPastTimeSeries] = useLazyGetMetricTimeSeriesByNameQuery();
    const [getAtiributionMetricTimeSeriesByName] = useLazyGetAtiributionMetricTimeSeriesByNameQuery();
    const [getPastAtiributionMetricTimeSeriesByName] = useLazyGetAtiributionMetricTimeSeriesByNameQuery();
    const [createConnector] = useCreateConnectorMutation();

    const { startDate, endDate } = dateRange.selection;
    const { startDate: pastStartDate, endDate: pastEndDate } = dateRange.compare;
    const pastDateString = pastRangeBasedOnCurrentRange([dateRange.selection]);

    const getTimeframe = endDate && startDate ? differenceInDays(endDate, startDate) + 1 : 0;
    const selectedPerformanceKpiTab = getSelectedTab(ecommerceState);
    const isGAMetric = selectedPerformanceKpiTab?.isGA || false;
    const isAttributionTimeSeries = selectedPerformanceKpiTab?.isAttribution || false;
    const csvFileTitle = `${chartTitle} - ${selectedPerformanceKpiTab?.label} (${getRangeFormattedTitle([
        dateRange.selection,
    ])})`;

    const isGrpBySupportedForSpecificMetrics = ["modeled_cac", "modeled_roas"].includes(ecommerceState);

    const fetchTimeSeriesData = (metric: KeyKPIPerformanceTabTypes, isPast: boolean) => {
        const formatStartDate = (date: Date) => format(date, "yyyy-MM-dd");

        const requestData = {
            orgId: currentlyViewingId,
            start_date: isPast ? formatStartDate(pastStartDate) : formatStartDate(startDate),
            end_date: isPast ? formatStartDate(pastEndDate) : formatStartDate(endDate),
            metric_name: metric,
            supportedDataSources,
            dataSources,
        };

        const getTimeSeriesFunction = isPast ? getPastTimeSeries : getTimeSeries;
        const timeSeriesPromise = getTimeSeriesFunction(requestData).unwrap();

        return timeSeriesPromise;
    };

    const fetchAttributionTimeSeriesData = (isPast: boolean) => {
        const formatStartDate = (date: Date) => format(date, "yyyy-MM-dd");
        const findApiMetric = selectedPerformanceKpiTab?.apiMetric || [];

        const requestData = {
            orgId: currentlyViewingId,
            start_date: isPast ? formatStartDate(pastStartDate) : formatStartDate(startDate),
            end_date: isPast ? formatStartDate(pastEndDate) : formatStartDate(endDate),
            metric_names: findApiMetric,
            group_by: isGrpBySupportedForSpecificMetrics ? grpBy : chartGrpKey.DAY,
        };

        const getTimeSeriesFunction = isPast
            ? getAtiributionMetricTimeSeriesByName
            : getPastAtiributionMetricTimeSeriesByName;
        const timeSeriesPromise = getTimeSeriesFunction(requestData).unwrap();

        return timeSeriesPromise;
    };

    const displayGrpByChannelSelection = ["modeled_revenue", "modeled_customers"].includes(ecommerceState);
    const displayDataValueViewSelection = (state?: string) =>
        [
            "modeled_revenue",
            "modeled_customers",
            "spend",
            "channel_reported_revenue",
            "revenue",
            "new_customers",
            "orders",
        ].includes(state || ecommerceState);

    const onTabChangeUpdate = (currentTab: IPerformanceKpiMetricsTabsType) => {
        setEcommerceState(currentTab.id);
        setChannelGrpBy(groupByChannelOption[0].id);
        // reset to absolute when data value view selection hidden
        if (!displayDataValueViewSelection(currentTab.id)) {
            setDataValueView(DataValueView.Absolute);
        }
        analyticsService.logEvent("Dashboard Chart Tab Clicked", {
            Chart: chartTitle,
            "Chart tab": currentTab.label,
        });
    };

    const handleGAConnect = async () => {
        analyticsService.logEvent("Connector Clicked", {
            Page: getPathForAnalytics(location.pathname),
            "Connector type": "Outreach",
            "Connector name": "Google analytics",
        });
        const dataSource = supportedDataSources.find((x) => x.service === GOOGLE_ANALYTICS);
        if (!dataSource) {
            return;
        }

        await createConnector({
            body: {
                data_source_id: dataSource.id,
            },
            orgId: currentlyViewingId,
        })
            .unwrap()
            .then((response: { fivetranCardUri: string }) => {
                const connectionCardUrl = buildConnectionCardUrl(
                    response.fivetranCardUri,
                    GOOGLE_ANALYTICS,
                    `${window.location.origin}/org/${currentCompany.code}${getDefaultRedirectPath(currentCompany)}`,
                );
                window.open(connectionCardUrl, "_self");
            })
            .catch((er) => {
                enqueueSnackbar(`Something went wrong!`, {
                    id: uniqueId(),
                    variant: "error",
                });
                handleModalClose();
            });
    };

    useEffect(() => {
        if (dataSource) {
            if (connectors && connectors.length) {
                handleGAConnect();
            } else {
                analyticsService.logEvent("Fivetran Warning Displayed", {
                    Page: getPathForAnalytics(location.pathname),
                    "Connector categories": dataSource.categories,
                    "Connector name": dataSource.name,
                });
                setIsModalOpen(true);
            }
        }
    }, [dataSource]);

    useEffect(() => {
        setEcommerceState("modeled_revenue");
        setGrpBy(chartGrpKey.DAY);
        setDateRange(defaultDateRangeForHomePage);
        setChannelGrpBy(groupByChannelOption[0].id);
        setSpendData([]);
        setPastSpendData([]);
        setCompareMode(false);
    }, [currentlyViewingId]);

    const fetchMetricAPiData = () => {
        const fetchApiData = async (isPastData: boolean) => {
            setIsLoading(true);
            const selectedMetrics = selectedPerformanceKpiTab?.apiMetric || [];
            let spend: IChartItem[] = isPastData ? pastSpendData : spendData;

            try {
                if (!spend.length) {
                    await fetchTimeSeriesData("spend", isPastData).then((spendResponse) => {
                        const res = spendResponse.data || [];
                        flushSync(() => {
                            if (isPastData) {
                                setPastSpendData(res);
                            } else {
                                setSpendData(res);
                            }
                            spend = [...res];
                        });
                    });
                }

                const dataByMetric: IKpiPerformanceMetric = {
                    ...defaulMetricData,
                    spend,
                };

                // If it's an attribution time series, fetch the data, process it based on the ecommerce state, and update the appropriate API response state.
                if (isAttributionTimeSeries) {
                    const attributionResponse = await fetchAttributionTimeSeriesData(isPastData);
                    const dataToSet = ["modeled_roas", "modeled_cac"].includes(ecommerceState)
                        ? {
                              data: addChannelNameToData(attributionResponse.data, dataSources),
                              meta: attributionResponse.meta,
                          }
                        : addChannelNameToData(attributionResponse.data, dataSources);

                    const apiResponseState = isPastData ? setPastApiResponse : setCurrentApiResponse;

                    apiResponseState({
                        [ecommerceState]: dataToSet,
                        spend,
                    } as IKpiPerformanceMetric);
                } else {
                    if (
                        spendData.length &&
                        selectedMetrics.length > 1 &&
                        selectedMetrics.includes(MetricNameTypeForPayload.Spend)
                    ) {
                        selectedMetrics.splice(selectedMetrics.indexOf(MetricNameTypeForPayload.Spend), 1);
                        dataByMetric.spend = spendData;
                    }

                    if (selectedMetrics.length) {
                        await Promise.all(
                            selectedMetrics.map((metric) =>
                                fetchTimeSeriesData(metric, isPastData).then((res) => {
                                    // @ts-ignore
                                    dataByMetric[metric] = metric === "channel_reported_roas" ? res : res.data;
                                }),
                            ),
                        ).then(() => {
                            if (isPastData) {
                                setPastApiResponse({ ...dataByMetric, spend });
                            } else {
                                setCurrentApiResponse({ ...dataByMetric, spend });
                            }
                        });
                    }
                }
            } catch (error) {
                console.log(error);
                enqueueSnackbar("Something went wrong with KPI Performance!", { id: uniqueId(), variant: "error" });
            } finally {
                setIsLoading(false);
            }
        };

        if (supportedDataSources.length && currentCompany?.id) {
            fetchApiData(false);
            if (compareMode) {
                fetchApiData(true);
            }
        }
    };

    useEffect(() => {
        fetchMetricAPiData();
    }, [supportedDataSources, dateRange, ecommerceState, currentCompany?.id]);

    // Fetches again metric API data when grpBy change, if grouping is supported for specific metrics(modeled_cac and modeled_roas).
    useEffect(() => {
        if (isGrpBySupportedForSpecificMetrics) {
            fetchMetricAPiData();
        }
    }, [grpBy]);

    useEffect(() => {
        let legends: any[] = [];
        const seriesInfo = processApiResponse(
            currentApiResponse,
            channelGrpBy,
            ecommerceState,
            isAttributionTimeSeries,
            spendData,
            getTimeframe,
            dataSources,
        );
        setSeriesData(isAttributionTimeSeries ? mapAttributionSeriesData(seriesInfo.series) : seriesInfo.series);
        legends = seriesInfo.legend;

        if (compareMode && !!Object.keys(pastApiResponse).length) {
            const pastSeriesInfo = processApiResponse(
                pastApiResponse,
                channelGrpBy,
                ecommerceState,
                isAttributionTimeSeries,
                pastSpendData,
                getTimeframe,
                dataSources,
            );
            setPastSeriesData(
                isAttributionTimeSeries ? mapAttributionSeriesData(pastSeriesInfo.series) : pastSeriesInfo.series,
            );

            const currentLegendMap = pastSeriesInfo.legend.reduce((map, item) => {
                map[item.name] = item.value;
                return map;
            }, {} as IDictionary);

            legends = legends.map((item) => {
                const pastValue = currentLegendMap[item.name] || 0; // If name not found in past, default to 0
                return { ...item, pastValue };
            });
        }
        setLegendTotal([
            ...legends.filter((l) => l.name === "Total"),
            ...orderBy(
                legends.filter((l) => l.name !== "Total"),
                ["name"],
            ),
        ]);
    }, [currentApiResponse, pastApiResponse, dateRange, channelGrpBy]);

    let seriesDataHolder = [...seriesData];

    if (compareMode) {
        const updatedPastSeriesData =
            pastSeriesData?.map((d) => ({
                ...d,
                channelName: ["revenue"].includes(ecommerceState)
                    ? `${d.channelName.slice(0, d.channelName.lastIndexOf("-"))} [Past]-${d.channelName
                          .split("-")
                          .pop()}`
                    : `${d.channelName} [Past]`,
            })) || [];
        seriesDataHolder = [...seriesDataHolder, ...updatedPastSeriesData];
    }

    const ecommerceServices = newConnectorsList
        .filter((x) => x.categories.includes(DataSourceCategory.ECOMMERCE))
        .map((x) => x.service)
        .concat([GOOGLE_ANALYTICS, GOOGLE_ANALYTICS_4]);

    const showEmptyState =
        !isLoading &&
        seriesDataHolder.length === 0 &&
        connectors &&
        connectors.filter((y) => ecommerceServices.includes(y.service)).length === 0;

    const sortedValuesByDate = [...seriesDataHolder].sort((a, b) => +new Date(a.date) - +new Date(b.date));

    seriesDataHolder = isGrpBySupportedForSpecificMetrics
        ? [...seriesDataHolder]
        : getSpecificGrpByEcommerceData(sortedValuesByDate, grpBy, ecommerceState);

    const handleGrpByChange = (grp: string) => {
        setGrpBy(grp as chartGrpKey);
    };

    const handleChannelGrpByChange = (grp: string) => {
        setChannelGrpBy(grp);
    };

    const handleDataValueViewChange = (dataView: string) => {
        setDataValueView(dataView as DataValueView);
    };

    const dateRangeChangeHandler = (
        updatedDateRange: {
            selection: IDateRange;
            compare: IDateRange;
        },
        selectedPreset: DefaultDateRangeInText,
    ) => {
        setSpendData([]);
        setPastSpendData([]);
        setDateRange(updatedDateRange);
    };

    const getDataSource = () => {
        const dataSource = newConnectorsList.filter((data) => data.service === GOOGLE_ANALYTICS);
        if (dataSource.length) {
            return dataSource[0];
        }
        return null;
    };

    const handleModalOpen = (dataSource: IDataSource | null) => {
        setDataSource(dataSource);
    };

    const handleModalClose = () => {
        setDataSource(null);
        setIsModalOpen(false);
    };

    const handleCancelClick = () => {
        if (dataSource) {
            analyticsService.logEvent("Fivetran Warning Cancelled", {
                Page: getPathForAnalytics(location.pathname),
                "Connector categories": dataSource.categories,
                "Connector name": dataSource.name,
            });
        }
        handleModalClose();
    };

    const filteredTabs = PERFORMANCE_KPI_METRICS_TABS.map((tab) => ({
        ...tab,
        isHide:
            !isCACAvailableForCompany(defaultRevenueSource, orgCode) &&
            ["modeled_customers", "modeled_cac"].includes(tab.id),
        info: getMetricTooltipText(dataSources[defaultRevenueSource]?.name, isAmazonConnected)[tab.tooltipId] || "",
    }));

    const selectedMetric = PERFORMANCE_KPI_METRICS_TABS.find((t) => t.id === ecommerceState);
    const options = getTargetOptions(defaultRevenueSource, isAmazonConnected);
    const getLabel = options.find((op) => op.id === channelGrpBy)?.label || "";

    const exportKpiCardHandler = () => {
        if (!isLoading) {
            const uniqueChannels = [...new Set(seriesDataHolder.map((c) => c.channelName))];
            const exportColumns = [
                {
                    id: "date",
                    label: `"${capitalizeFirstLetter(grpBy)} ${getLabel === "Total" ? "" : `(${getLabel})`}"`,
                },
                ...uniqueChannels.map((c) => ({
                    ...selectedMetric,
                    id: c,
                    label: c,
                })),
            ];

            const csvData = seriesDataHolder.reduce((acc, item) => {
                if (!acc.has(item.date)) {
                    acc.set(item.date, { date: item.date });
                }
                const dataItem = acc.get(item.date);
                dataItem[item.channelName] = item.value;
                return acc;
            }, new Map());

            const csvDataArray = [
                legendTotal.reduce(
                    (acc, item) => {
                        acc = { ...acc, [item.name]: item.value };
                        return acc;
                    },
                    {
                        date: `Total (From ${`${format(startDate, "MMM d, yyyy")} To ${format(
                            endDate,
                            "MMM d, yyyy",
                        )}`})`,
                    },
                ),
                ,
                ...Array.from(csvData.values()),
            ];

            exportCSV(csvFileTitle, prepareDataForCSV(csvDataArray, exportColumns as IExportHead[]));
        }
    };

    return (
        <HomepageCardContainer>
            <Stack gap={3}>
                <DashboardCardHeader
                    title={chartTitle}
                    tooltipRef={tooltipRef}
                    tooltip="Quickly assess with high level metrics"
                    instances={[
                        <span>View</span>,
                        <GroupBySelection
                            selectedDateRanage={[dateRange.selection] as IDateRange[]}
                            grpBy={grpBy}
                            handleGrpByChange={handleGrpByChange}
                            lable={false}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                        />,
                        <AbsoluteVsPercentagesSelect
                            value={dataValueView}
                            handleDataValueViewChange={handleDataValueViewChange}
                            isDisabled={isLoading || !displayDataValueViewSelection()}
                            isUpgraded={true}
                        />,
                        displayGrpByChannelSelection ? (
                            <TargetSelect
                                value={channelGrpBy}
                                handleChannelGrpByChange={handleChannelGrpByChange}
                                isDisabled={isLoading}
                                chartTitle={chartTitle}
                                isUpgraded={true}
                            />
                        ) : (
                            <></>
                        ),
                        <SimpleReactDateRange
                            compareView={true}
                            startRange={dateRange}
                            applyRangeHandler={(selectedRange, selectedPreset) =>
                                dateRangeChangeHandler(selectedRange, selectedPreset)
                            }
                            isDisabled={isLoading}
                            compareMode={compareMode}
                            setCompareMode={setCompareMode}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                            isTitleInOneLine={true}
                        />,
                        <ExportTableData
                            handleMenuItemClick={() => {}}
                            exportVisibleColumns={exportKpiCardHandler}
                            openExportMenu={false}
                            handleExportToggle={() => {}}
                            handleExportMenuClose={() => {}}
                            noPopup
                            leftMargin={false}
                        />,
                    ]}
                />
                <div>
                    <ChartTemplateComponent
                        style={{ borderRadius: "4px 0 4px 4px" }}
                        title={"E-commerce"}
                        tabs={[...filteredTabs]}
                        onTabChange={onTabChangeUpdate}
                        chartData={{
                            data: seriesDataHolder,
                            legendTotal: showEmptyState ? [] : legendTotal,
                        }}
                        isFetching={isLoading}
                        hasError={isSeriesError}
                        showEmptyState={showEmptyState}
                        emptyDataButtonText={isGAMetric ? "Connect Google Analytics" : "CONNECT PAID CHANNELS"}
                        handleEmptyDataButtonClick={() => {
                            isGAMetric
                                ? handleModalOpen(getDataSource())
                                : navigate(`/org/${currentCompany.code}${NEW_CONNECTOR_PATH}`);
                        }}
                        grpBy={grpBy}
                        id="ecommerce-chart"
                        ecommerceState={ecommerceState}
                        compareMode={compareMode}
                        pastDateString={pastDateString}
                        dataValueView={dataValueView}
                    />
                </div>
                {!partialStateForHome && (
                    <Button
                        variant="contained"
                        sx={{ width: "fit-content", marginLeft: "auto" }}
                        href={`/org/${orgCode}${PERFORMANCE_CHANNELS_PATH}`}
                    >
                        See Performance Details
                    </Button>
                )}
                <LightModal
                    isOpen={isModalOpen}
                    onClose={handleModalClose}
                    title={`Connect Google Analytics`}
                    handleCancelButtonClick={handleCancelClick}
                    cancelButtonName="Cancel"
                    buttonName="Continue"
                    onSubmit={handleGAConnect}
                    content={
                        <div style={{ maxWidth: "552px" }}>
                            <Typography variant="body1">{warningModalText}</Typography>
                        </div>
                    }
                />
            </Stack>
        </HomepageCardContainer>
    );
};
