import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

interface INeonPixelConnectorModalContent {
    classes: any;
}

export const NeonPixelConnectorModalContent: FC<INeonPixelConnectorModalContent> = ({ classes }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Find API Key
                </Typography>
            </Stack>
            <Typography className={classes.info}>Log in to your NeonPixel account.</Typography>
            <Typography className={classes.info}>
                You can retrieve your API key by navigating to the <a href="https://app.neonpixel.co/my-api-key">API Docs</a> page of the Neon Pixel user interface.
            </Typography>
            <Typography className={classes.info}>
                The API key should be at the top of your screen.
            </Typography>
            <Box sx={{ backgroundColor: "white" }} p="1rem">
                <Typography className={classes.info}>
                    NOTE: In case you are unable to find the API Key, please reach out to NeonPixel by email at <b>cs@neonpixel.co</b>.
                </Typography>
            </Box>

            {/* Step 2 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>Enter the API Key you found in Step 1.</Typography>
            <Typography className={classes.info}>Click Save & Test.</Typography>
        </Fragment>
    );
};
