import { DataSourceCategory } from "src/enums/dataSourceCategory";
import { ConnectorStatus, IConnector } from "../../interfaces/IConnector";
import { IDataSource, IDataSourceWithConnected } from "../../interfaces/IData";
import { IOnboardingState } from "../../interfaces/entities/IOnboardingState";
import { filterDataSources } from "../utils";
import { OnboardingStateValue } from "src/consts/onboardingPage/onboardingPage";

export const checkIsUserNew = (state?: IOnboardingState) => {
    if (state) {
        const values = Object.values(state);
        return values.indexOf("complete") < 0;
    }
    return false;
};

export const checkIsIncompletePagesExist = (state?: IOnboardingState) => {
    if (state) {
        const liveStates = (({ eCommerce, webAnalytics, paidMarketing }) => ({ eCommerce, webAnalytics, paidMarketing }))(state);
        const values = Object.values(liveStates);
        return values.indexOf(OnboardingStateValue.incomplete) >= 0;
    }
    return false;
};

export const isDuplicateConnector = (connectors: IConnector[], selectedDataSource: IDataSource) => {
    return connectors.filter((c) => c.dataSource?.programmaticName === selectedDataSource.programmaticName).length > 0;
};

export const getUpdatedDataSources = (
    connectors: IConnector[],
    orgSpecificCleanDataSources: IDataSource[],
    orgSpecificDataSources: IDataSource[],
) => {
    let filteredConnectors: IDataSourceWithConnected[] = [];
    const filteredDataSources = filterDataSources(orgSpecificCleanDataSources, "", [], orgSpecificDataSources);
    if (filteredDataSources.length && connectors && connectors.length) {
        filteredConnectors = filteredDataSources.map((currentDataSource) => ({
            ...currentDataSource,
            isConnected: isDuplicateConnector(connectors, currentDataSource),
            connectorId: connectors.find((c) => c.service === currentDataSource.service)?.id || "",
        }));
    } else {
        filteredConnectors = filteredDataSources.map((currentDataSource) => ({
            ...currentDataSource,
            isConnected: false,
            connectorId: "",
        }));
    }
    return filteredConnectors;
};

export const areAllRequiredConnectorCategoriesConnected = (connectors: Array<Pick<IConnector, "dataSource">>) => {
    const requiredCategories = new Set(["ecommerce", "advertising", "analytics"]);
    const foundCategories = new Set();

    for (const connector of connectors) {
        const categories =
            connector.dataSource?.categories?.filter((c: string) => requiredCategories.has(c.toLowerCase())) || [];
        categories.every((c: string) => foundCategories.add(c));
    }

    if (foundCategories.size === requiredCategories.size) {
        return true;
    }

    return false;
};

export const isIncompleteConnectorExist = (connectors: Array<Pick<IConnector, "status">>) => {
    if (connectors.length === 0) {
        return true;
    }
    return connectors.filter((connector) => connector.status === ConnectorStatus.INCOMPLETE).length > 0;
};

export const getEcommerceConnectorsGrp = (connectors: IConnector[]) =>
    connectors.filter((c) => c.dataSource?.categories?.includes(DataSourceCategory.ECOMMERCE));

export const getAdvertisingConnectorsGrp = (connectors: IConnector[]) =>
    connectors.filter((c) => c.dataSource?.categories?.includes(DataSourceCategory.ADVERTISING));

export const getAnalyticsConnectorsGrp = (connectors: IConnector[]) =>
    connectors.filter((c) => c.dataSource?.categories?.includes(DataSourceCategory.ANALYTICS));

export const getActiveConnectorStep = (connectors: IConnector[]) => {
    const ecommerceConnectors = getEcommerceConnectorsGrp(connectors);
    const advertisingConnectors = getAdvertisingConnectorsGrp(connectors);
    const analyticsConnectors = getAnalyticsConnectorsGrp(connectors);

    if (!areAllRequiredConnectorCategoriesConnected(connectors)) {
        return 0;
    }

    if (isIncompleteConnectorExist(ecommerceConnectors)) {
        return 1;
    }

    if (isIncompleteConnectorExist(advertisingConnectors)) {
        return 2;
    }

    if (isIncompleteConnectorExist(analyticsConnectors)) {
        return 3;
    }

    // when all steps are completed but didn't hit finish button
    return 3;
};

export const getCompletedSteps = (connectors: IConnector[]) => {
    const completedSteps: number[] = [];

    const ecommerceConnectors = getEcommerceConnectorsGrp(connectors);
    const advertisingConnectors = getAdvertisingConnectorsGrp(connectors);
    const analyticsConnectors = getAnalyticsConnectorsGrp(connectors);

    if (areAllRequiredConnectorCategoriesConnected(connectors)) {
        completedSteps.push(0);
    }

    if (!isIncompleteConnectorExist(ecommerceConnectors)) {
        completedSteps.push(1);
    }

    if (!isIncompleteConnectorExist(advertisingConnectors)) {
        completedSteps.push(2);
    }

    if (!isIncompleteConnectorExist(analyticsConnectors)) {
        completedSteps.push(3);
    }

    return completedSteps;
};

export const getInCompletedSteps = (connectors: IConnector[]) => {
    const incompletedSteps: number[] = [];

    const ecommerceConnectors = getEcommerceConnectorsGrp(connectors);
    const advertisingConnectors = getAdvertisingConnectorsGrp(connectors);
    const analyticsConnectors = getAnalyticsConnectorsGrp(connectors);

    if (!areAllRequiredConnectorCategoriesConnected(connectors)) {
        incompletedSteps.push(1);
    }

    if (isIncompleteConnectorExist(ecommerceConnectors)) {
        incompletedSteps.push(2);
    }

    if (isIncompleteConnectorExist(advertisingConnectors)) {
        incompletedSteps.push(3);
    }

    if (isIncompleteConnectorExist(analyticsConnectors)) {
        incompletedSteps.push(4);
    }

    return incompletedSteps;
};

export const getInCompletedStepsText = (steps: number[]) => {
    const result = steps.reduce((res, k, i) => {
        if (i === 0) {
            return k.toString();
        } else if (i === steps.length - 1) {
            return `${res} and ${k}`;
        } else {
            return `${res}, ${k}`;
        }
    }, "");
    return result;
};

export const getActiveStepBasedOnConnectorIdParam = (dataSource?: IDataSourceWithConnected) => {
    if (dataSource) {
        if (dataSource.categories.includes(DataSourceCategory.ECOMMERCE)) {
            return 1;
        } else if (dataSource.categories.includes(DataSourceCategory.ADVERTISING)) {
            return 2;
        } else if (dataSource.categories.includes(DataSourceCategory.ANALYTICS)) {
            return 3;
        }
    }
    return 0;
};
