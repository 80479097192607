import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { useSelector } from "react-redux";

import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { selectCurrentlyViewingCode } from "../../reduxState/slices/organizationSlice";
import { ActivePerformanceTabs } from "../../enums/ActivePerfomanceTabs";
import { getDataFromLocalStorage } from "../../services/utils";
import { EmailTabHolderComponent } from "../../components/EmailComponents/InsightsModal/EmailTabHolderComponent";
import { useStylesForDetailPage } from "../CampaignDetailsPage/CampaignDetailsPage";
import { IEmail } from "../../interfaces/entities/IEmail";

export const EmailCampaignDetailPage = ({ ...rest }) => {
    const classes = useStylesForDetailPage();
    const navigate = useNavigate();
    const params = useParams();
    const currentTab = window.location.pathname.split("/").pop();

    const [tabValue, setTabValue] = useState<string | ActivePerformanceTabs>(currentTab || "performance");
    const orgCode = useSelector(selectCurrentlyViewingCode);

    useEffect(() => {
        setTabValue(currentTab || "performance");
    }, [location.pathname]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => {
        setTabValue(newValue);
        navigate(`/org/${orgCode}/email/${campaignId}/${newValue}${window.location.search}`);
    };
    const performanceCampaignData: IEmail = getDataFromLocalStorage("currentEmailCampaignDetails");
    const campaignId = params.campaignId;

    return campaignId === performanceCampaignData.campaignId ? (
        <div className={classes.performancePage}>
            <PageHeader pageHeading="Campaign Detail Pages" />

            <div className={classes.mainContainer}>
                <EmailTabHolderComponent
                    handleTabChange={handleTabChange}
                    tabValue={tabValue}
                    performanceCampaignData={performanceCampaignData}
                />
            </div>
        </div>
    ) : (
        <></>
    );
};
