import React, { useState } from "react";
import { TableBody } from "@mui/material";
import { useSelector } from "react-redux";

import { MembersTableRow } from "../MembersTableRow/MembersTableRow";
import { MembersTableHeader } from "../MembersTableHeader/MembersTableHeader";
import { Choice, defaultMemberSortBy, ICells } from "../../../consts/membersPage/membersPage";
import { stableSort, getComparator } from "../../../utils/sort";
import { useStylesTable } from "../../../consts/StylesForTables";
import { IMember } from "../../../interfaces/entities/IMember";
import { customSortData } from "../../../services/utils";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";

interface IMembersTableView {
    members: IMember[];
    member: IMember | null;

    onEditMember(member: IMember): void;
    onDeleteMember(member: IMember): void;
    onStatusClick(member: IMember): void;
}

export const MembersTable: React.FC<IMembersTableView> = ({ members, onDeleteMember, onEditMember, onStatusClick }) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const sortMemberByCustomStatus = customSortData(members, "invitationState", defaultMemberSortBy);
    const isAllAcceptedMembers = sortMemberByCustomStatus.every(
        (member) => member.invitationState === "accepted" || member.invitationState === "none",
    );

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState<number>(0);

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            onEditMember(member);
        }
    };

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            onDeleteMember(member);
        }
    };

    const statusClick = (e: React.MouseEvent<HTMLDivElement>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            onStatusClick(member);
        }
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: members.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <MembersTableHeader
                onRequestSort={handleRequestSort}
                choice={order}
                choiceBy={orderBy}
                isAllAcceptedMembers={isAllAcceptedMembers}
            />
            <TableBody className={classes.tableBody}>
                {stableSort(sortMemberByCustomStatus, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((row: any, index: number) => {
                        const labelId = `enhanced-table-checkbox-${index}`;

                        return (
                            <MembersTableRow
                                member={row}
                                key={row.id}
                                labelId={labelId}
                                onDeleteMember={handleDelete}
                                onEditMember={handleEdit}
                                statusClick={statusClick}
                                isAllAcceptedMembers={isAllAcceptedMembers}
                            />
                        );
                    })}
            </TableBody>
        </CustomTableStructure>
    );
};
