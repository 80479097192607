import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";

import { IEmailAttributionTableCells, IHeadCell, EMAIL_TRUE_METRICS } from "../../../consts/emailPage/emailPage";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import {
    openedEmailInsightsModalIdsSelector,
    setEmailInsightsModal,
} from "../../../reduxState/slices/emailInsightsModalSlice";
import { capitalizeFirstLetter, formatValue, handleClickWithTextSelectionCheck } from "../../../services/utils";
import { FORMATS } from "../../../enums/Formats";
import { AVATAR_TEXT_COLOR } from "../../../consts/colors";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { cursor } from "../../../assets/styles/commonStyle";
import { useStylesForCampaignTableRow } from "../../../components/CampaignComponents/CampaignTable/CampaignTableRow";
import { IEmail } from "../../../interfaces/entities/IEmail";

interface IEmailCampaignTableRow {
    row: IEmailAttributionTableCells;
    isImagesInTable?: boolean;
    totalAmount?: IEmail;
    selectedTableColumns: IHeadCell[];
}

export const EmailCampaignTableRow: React.FC<IEmailCampaignTableRow> = ({
    row,
    isImagesInTable,
    totalAmount,
    selectedTableColumns,
}) => {
    const dispatch = useDispatch();
    const openedInsightsModalIds = useSelector(openedEmailInsightsModalIdsSelector);

    const isPerformanceRowSelected = openedInsightsModalIds?.length
        ? openedInsightsModalIds.includes(row.campaignId)
        : false;

    const classes = {
        ...useStylesForRowInTable(),
        ...useStylesForCampaignTableRow(useTheme(), isPerformanceRowSelected),
    };

    const openModalHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (!openedInsightsModalIds.includes(row.campaignId)) {
            dispatch(
                setEmailInsightsModal({
                    data: row,
                    isMinimize: false,
                    activeTab: "performance",
                }),
            );
        }
    };

    return (
        <TableRow
            hover
            id={`${totalAmount ? "sticky-total-amount" : `performance-row-${row.campaignId}`}`}
            className={`${classes.bodyRow} ${classes.selectedRow}  ${
                totalAmount ? classes.totalAmountRow : cursor("pointer")
            }`}
            onClick={!totalAmount ? (e) => handleClickWithTextSelectionCheck(openModalHandler)(e) : () => {}}
        >
            {selectedTableColumns.map((element, index) => {
                const isCampaignNameCell = element.id === "campaignName";
                const isTotalRow = totalAmount && totalAmount.campaignName === "Total";
                const negativeCols = ["channelReportedRevenue"];

                const isImagesInCell = isImagesInTable && isCampaignNameCell;

                const title = totalAmount
                    ? formatValue(totalAmount[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0)
                    : isCampaignNameCell
                    ? row.campaignName || row.campaignId || ""
                    : formatValue(row[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0);

                const currentCellValue = row[element.id] ? row[element.id] : row[element.id] === 0 ? 0 : -1;
                const isTrueMetricCell = EMAIL_TRUE_METRICS.includes(element.id);
                const isNotValidData =
                    !isTotalRow && negativeCols.includes(element.id) && currentCellValue && +currentCellValue < 0;

                return (
                    !element.hidden && (
                        <TableCell
                            component="td"
                            scope="row"
                            key={index}
                            align={element.numeric ? "right" : "left"}
                            title={isCampaignNameCell ? title : ""}
                            sx={{
                                borderBottom: "1px solid #E0E0E0",
                                borderLeft: isTrueMetricCell ? `1px solid ${AVATAR_TEXT_COLOR}` : "",
                                borderRight: isTrueMetricCell ? `1px solid ${AVATAR_TEXT_COLOR}` : "",
                                color: "#000000!important",
                                minHeight: "52px",
                                padding: "15px 20px",
                                position: "relative",
                            }}
                        >
                            <span
                                className={
                                    isCampaignNameCell
                                        ? classes.campaignName
                                        : isImagesInCell
                                        ? `${classes.campaignName} ${classes.channelReportedClass}`
                                        : ""
                                }
                            >
                                {isImagesInCell && (
                                    <SVGIconRenderer
                                        icon={row.type.toLowerCase() === "flow" ? "airIcon" : "sendIcon"}
                                        style={{ marginRight: "10px" }}
                                    />
                                )}

                                <Typography
                                    className={`${totalAmount && isTotalRow ? classes.boldText : classes.usualText} ${
                                        isCampaignNameCell && !isTotalRow && classes.underLine
                                    }`}
                                    variant="body2"
                                    sx={{
                                        flexGrow: "1",
                                    }}
                                >
                                    {element.id === "status" && !totalAmount
                                        ? capitalizeFirstLetter(row.status?.toLocaleLowerCase() || "")
                                        : isNotValidData
                                        ? "N/A"
                                        : title}
                                </Typography>
                            </span>
                        </TableCell>
                    )
                );
            })}
        </TableRow>
    );
};
