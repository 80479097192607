import React, { useState } from "react";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import initialAvatar from "./../../../assets/initialAvatar.png";
import photoIcon from "./../../../assets/photoIcon.png";
import { WHITE } from "../../../consts/colors";

const useStyles = (theme: Theme) => ({
    imageClass: css({
        display: "inline-block",
        width: "160px",
        height: "160px",
        backgroundColor: "white",
        boxSizing: "border-box",
        borderRadius: "80px",
        zIndex: 1,
        backgroundSize: "cover",
    }),
    photoIconClass: css({
        opacity: 0,
        "&:hover": {
            opacity: 1,
        },
    }),
    customAvatarText: css({
        position: "absolute",
        top: "-30px",
        left: "3px",
    }),
    customAvatarBg: css({
        fontSize: "4rem",
        color: WHITE,
        backgroundColor: theme.palette.secondary.light,
    }),
});

const isImage = (file: File) => file.name.match(/\.(jpg|jpeg|png|gif)$/);
let image: File;

interface IProps {
    initial?: string;
    name: string;
    isAvatar?: boolean;
    isDisabled?: boolean;
    onChange(e: React.ChangeEvent<any> | null): void;
    onSelectImage(image: File | null, name: string): void;
}

export const ImageInput: React.FC<IProps> = ({
    onChange,
    initial,
    name,
    isAvatar,
    onSelectImage,
    isDisabled,
}: IProps) => {
    const classes = useStyles(useTheme());
    const [previewImage, setPreviewImage] = useState("");
    const [imageError, setImageError] = useState(false);
    const handleImageChange = () => {
        onSelectImage(image, image.name);
    };

    return (
        <div>
            <div
                className={`${classes.imageClass} ${initial?.length === 1 && !previewImage && classes.customAvatarBg}`}
                style={
                    initial && !previewImage
                        ? { backgroundImage: `url(${initial})` }
                        : previewImage && isAvatar
                        ? { backgroundImage: `url(${previewImage})` }
                        : { backgroundImage: `url(${initialAvatar})` }
                }
            >
                <label
                    htmlFor={name}
                    style={{
                        position: "relative",
                        top: "56px",
                        left: "56px",
                        cursor: "pointer",
                    }}
                >
                    <input
                        id={name}
                        name={name}
                        type="file"
                        accept="image/*"
                        style={{ display: "none" }}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            const files = event.currentTarget.files;

                            if (files && files.length) {
                                const previewImageFile: File = files[0];

                                if (isImage(previewImageFile)) {
                                    setImageError(false);
                                    setPreviewImage(URL.createObjectURL(previewImageFile));
                                    image = previewImageFile;
                                    handleImageChange();
                                    onChange(event);
                                } else {
                                    setImageError(true);
                                    setPreviewImage(initialAvatar);
                                    onChange(null);
                                }
                            }
                        }}
                    />
                    {initial?.length === 1 && !previewImage && (
                        <span className={classes.customAvatarText}>{initial}</span>
                    )}
                    {!isDisabled && <img alt="photoIcon" src={photoIcon} className={classes.photoIconClass} />}
                </label>
            </div>
        </div>
    );
};
