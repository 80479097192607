import React, { useEffect, useState } from "react";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppBar, FormControlLabel, IconButton, Link as MaterialLink, Switch, Toolbar, Typography } from "@mui/material";
import { Theme } from "@mui/material/styles";
import { Stack, useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { SUPERADMIN_ORG_PATH, SETTINGS_PATH, INTERCOM_HELP_CENTER_PATH } from "../../consts/path/path";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userStateSelector,
} from "../../reduxState/slices/userSlice";
import {
    selectPeriodExpiredDaysCount,
    selectIsActiveTrial,
    OrganizationsListedByOrderSelector,
    setCurrentlyViewing,
} from "../../reduxState/slices/organizationSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import companyLogo from "./../../assets/prescientWhiteLogoSecondary.svg";
import { navBarLogoClick } from "../../reduxState/actions/navBarLogoClick";
import { demoModeSelector, setDemoMode } from "../../reduxState/slices/demoModeSlice";
import { useLazyGetOrganizationsQuery } from "../../reduxState/apis/organizationApi";
import { AVATAR_BG_COLOR, AVATAR_TEXT_COLOR, BLACK_COLOR, WHITE } from "../../consts/colors";
import { HeaderTabs } from "./HeaderTabs.v2";
import { AccountSettings } from "./RightHeaderComponents/AccountSettings.v2";
import OrganizationSelection from "./RightHeaderComponents/OrganizationSelection";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import NotificationsList from "./RightHeaderComponents/NotificationsList";
import { useLazyGetAnnouncementsQuery } from "../../reduxState/apis/announcementApi";
import { selectCurrentlyViewingCode } from "../../reduxState/slices/organizationSlice";
import { getDefaultRedirectPath } from "src/consts/path/path";
import { UserRole } from "src/enums/UserRole";

const useStyles = (theme: Theme) => ({
    appbar: css({
        boxShadow: "none",
        height: "80px",
        padding: "0px 30px 0px 20px !important",
        color: WHITE,
        [theme.breakpoints.down("md")]: {
            height: "auto",
            padding: "10px 30px 0px 20px !important",
        },
    }),
    headerWrapper: css({
        width: "100%",
        [theme.breakpoints.down("md")]: {
            flexWrap: "wrap",
            justifyContent: "space-between",
            width: "100%",
            background: "#1B313B",
            color: "white",
            "& .logoHeader": {
                width: "100%",
                display: "flex",
                justifyContent: "space-between",
            },
        },
    }),
    rightPart: css({
        display: "flex",
        alignSelf: "center",
        gap: "15px",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            order: -1,
            marginLeft: "-5px",
        },
    }),
    iconButton: {
        height: "40px",
        width: "40px",
    },
    avatar: css({
        width: "40px",
        height: "40px",
        borderRadius: "50%",
        backgroundColor: AVATAR_BG_COLOR,
        color: AVATAR_TEXT_COLOR,
        fontSize: "15px",
        fontWeight: "700",
        lineHeight: "22.5px",
    }),
    displayRow: css({
        display: "flex",
        flexDirection: "row",
        "& .logoWrapper": {
            marginRight: "40px",
        },
        [theme.breakpoints.down("md")]: {
            "& .logoWrapper": {
                order: -1,
            },
        },
    }),
    customNavBar: css({
        width: "100%",
        display: "flex",
        gap: "10px",
        height: "80px",
        [theme.breakpoints.down("md")]: {
            overflowX: "auto",
        },
    }),
    logoWrapperLink: css({
        display: "flex",
        background: "#1B313B",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            padding: 0,
            marginLeft: "3px",
        },
    }),
    logoClass: css({
        height: "45px",
        width: "140px",
        [theme.breakpoints.down("sm")]: {
            height: "32px",
            width: "100px",
        },
    }),
    extraButtonClass: css({
        width: "100%",
        "& button": {
            width: "100%",
            justifyContent: "space-between",
            "& .MuiButton-endIcon svg": {
                fontSize: "24px",
                color: BLACK_COLOR,
            },
        },
    }),
    menuItemClass: css({
        padding: "10px 16px !important",
        "& .listItemIcon": {
            color: "rgba(0, 0, 0, 1)",
        },
    }),
    expiredTrialClass: css({
        backgroundColor: "#0D1B21",
        borderRadius: "22px",
        padding: "0px 20px 1px",
        whiteSpace: "pre",
    }),
    bellIconMenu: css({
        "& button": {
            minWidth: "40px!important",
            width: "40px!important",
        },
    }),
});

interface ILightHeaderProps {
    position: "fixed" | "absolute" | "relative" | "static" | "sticky" | undefined;
    showTopNavbar: boolean;
}

export const LightHeader: React.FC<ILightHeaderProps> = ({ position = "fixed", showTopNavbar }) => {
    const dispatch = useDispatch();
    const currentUser = useSelector(userStateSelector);
    const organizations: IOrganization[] = useSelector(OrganizationsListedByOrderSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const trialExpiredDaysCount = useSelector(selectPeriodExpiredDaysCount);
    const isTrialActive = useSelector(selectIsActiveTrial);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const navigate = useNavigate();
    const classes = useStyles(useTheme());
    const location = useLocation();
    const [isUpdate, setIsUpdate] = useState(true);

    const [getAnnouncements] = useLazyGetAnnouncementsQuery();

    const [getOrganizations] = useLazyGetOrganizationsQuery();
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const isSuperAdminRoute = location.pathname.match(/\/adm\//);

    const handleDemoMode = () => {
        // if in demo mode we want to route user back to admin
        // then demo mode will get disabled.
        if (isDemoMode) {
            navigate(SUPERADMIN_ORG_PATH);
        }
        dispatch(setDemoMode(!isDemoMode));
    };

    useEffect(() => {
        getAnnouncements({});
    }, []);

    useEffect(() => {
        if (isSuperAdmin) {
            getOrganizations("").then(() => {
                if (!isDemoMode) {
                    setIsUpdate(false);
                }
            });
        }
    }, [isDemoMode]);

    useEffect(() => {
        if (isDemoMode && !isUpdate) {
            setIsUpdate(true);
            dispatch(setCurrentlyViewing(organizations[0].code));
            navigate(`/org/${organizations[0].code}${getDefaultRedirectPath(organizations[0])}`);
        }
    }, [organizations]);

    const handleLogoClick = () => {
        dispatch(navBarLogoClick());
    };

    const handleSettings = () => {
        navigate(`/org/${orgCode}${SETTINGS_PATH}`);
    };

    return (
        <AppBar className={classes.appbar} position={position} id="top-app-bar">
            <Toolbar
                disableGutters
                sx={{
                    justifyContent: "space-between",
                    minHeight: {
                        md: 56,
                        sm: 56,
                        xs: 56,
                    },
                    flexDirection: {
                        md: "column",
                        sm: "column",
                        xs: "column",
                    },
                }}
            >
                <>
                    <div data-cy="mainNav" className={`${classes.displayRow} ${classes.headerWrapper}`}>
                        <div className={`${classes.displayRow} logoWrapper`} style={{ padding: "0" }}>
                            <Link to="/" className={classes.logoWrapperLink} onClick={handleLogoClick}>
                                <img src={companyLogo} className={classes.logoClass} />
                            </Link>
                        </div>
                        <div className={classes.customNavBar}>{showTopNavbar && <HeaderTabs />}</div>
                        <div className={classes.rightPart}>
                            {currentUser.isSuperadmin && (isSuperAdminRoute || isDemoMode) && (
                                <FormControlLabel
                                    control={
                                        <Switch
                                            color="primary"
                                            name="isDemoMode"
                                            checked={isDemoMode}
                                            onChange={handleDemoMode}
                                            size="small"
                                        />
                                    }
                                    label="Demo mode"
                                    style={{ whiteSpace: "nowrap", margin: 0 }}
                                />
                            )}

                            {showTopNavbar && !isSuperAdminRoute && (
                                <>
                                    {isTrialActive && (
                                        <Stack
                                            className={classes.expiredTrialClass}
                                            direction="row"
                                            justifyContent="center"
                                            alignItems="center"
                                            gap="5px"
                                        >
                                            <Typography fontSize="13px" fontWeight="700">
                                                FREE TRIAL
                                            </Typography>
                                            <Typography>&#x2022;</Typography>
                                            <Typography fontSize="13px">
                                                {trialExpiredDaysCount === 0 ? "Last day" : ""}
                                                {trialExpiredDaysCount > 0
                                                    ? `${trialExpiredDaysCount + 1} days remaining`
                                                    : ""}
                                            </Typography>
                                        </Stack>
                                    )}
                                    {(isSuperAdmin || userRole !== UserRole.MEMBER) && (
                                        <IconButton
                                            title="Settings"
                                            color="inherit"
                                            data-cy="settingsIcon"
                                            onClick={handleSettings}
                                        >
                                            <SVGIconRenderer icon="settingsIcon" strokeColor="white" />
                                        </IconButton>
                                    )}

                                    <div className={classes.bellIconMenu}>
                                        <NotificationsList classes={classes} />
                                    </div>

                                    <MaterialLink
                                        data-cy="helpCenterBtn"
                                        target="_blank"
                                        href={INTERCOM_HELP_CENTER_PATH}
                                    >
                                        <IconButton title="Help center" color="inherit">
                                            <SVGIconRenderer icon="helpIcon" strokeColor="white" />
                                        </IconButton>
                                    </MaterialLink>
                                    <OrganizationSelection />
                                </>
                            )}
                            <AccountSettings classes={classes} />
                        </div>
                    </div>
                </>
            </Toolbar>
        </AppBar>
    );
};
