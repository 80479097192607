import { createSlice, createSelector } from "@reduxjs/toolkit";
import { toDate } from "date-fns-tz";

import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";
import { IScenario } from "../../interfaces/entities/IScenario";
import { stringToCapitalize } from "../../services/utils";
import { FORECAST_JOB } from "../../services/optimizationPage/optimization";
import { OptimizationTab } from "src/consts/optimizationPage/optimizationPage";

export interface IScenarioState {
    lastModelRunDate: string;
    scenarios: IScenario[];
    currentlyViewingScenario: IScenario | null;
    isEditable: boolean;
    currentScenarioTab: OptimizationTab;
}

const initialState: IScenarioState = {
    lastModelRunDate: "",
    scenarios: [],
    currentlyViewingScenario: null,
    isEditable: false,
    currentScenarioTab: OptimizationTab.Scenarios,
};

const scenarioSlice = createSlice({
    name: "scenarios",
    initialState,
    reducers: {
        setScenarios: (state, { payload }) => {
            state.lastModelRunDate = payload.lastModelRunDate;
            state.scenarios = payload.data;
        },
        filterScenarioById: (state, { payload }) => {
            state.scenarios = state.scenarios.filter((scenario: any) => scenario.id !== payload.id);
        },
        setCurrentlyViewingScenario: (state, { payload }) => {
            state.currentlyViewingScenario = payload;
            state.isEditable = payload ? toDate(state.lastModelRunDate) <= toDate(payload.createdAt) : false;
        },
        setScenarioTab: (state, { payload }) => {
            state.currentScenarioTab = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const { setScenarios, setCurrentlyViewingScenario, filterScenarioById, setScenarioTab } = scenarioSlice.actions;

export default scenarioSlice.reducer;

export const scenariosSliceSelector = (state: RootState) => state.scenarios;

// Selectors
export const scenariosSelector = createSelector(
    scenariosSliceSelector,
    (scenarioState: IScenarioState) => scenarioState.scenarios,
);

export const scenariolastModelRunDateSelector = createSelector(
    scenariosSliceSelector,
    (scenarioState: IScenarioState) => scenarioState.lastModelRunDate,
);

export const isScenarioEditableSelector = createSelector(
    scenariosSliceSelector,
    (scenarioState: IScenarioState) => scenarioState.isEditable,
);

export const currentlyViewingScenarioSelector = createSelector(
    scenariosSliceSelector,
    (scenarioState: IScenarioState) => scenarioState.currentlyViewingScenario,
);

export const scenarioCurrentTabSelector = createSelector(
    scenariosSliceSelector,
    (scenarioState: IScenarioState) => scenarioState.currentScenarioTab,
);

export const needsToRerunAllocation = createSelector(
    currentlyViewingScenarioSelector,
    (scenario) =>
        Object.keys(scenario?.job || {}).length === 0 ||
        (scenario?.job?.jobName === FORECAST_JOB && scenario?.job?.metadata?.allocationModelSuccess === false),
);

export const needsToRerunForecasting = createSelector(
    currentlyViewingScenarioSelector,
    (scenario) =>
        (scenario?.job?.jobName === FORECAST_JOB || scenario?.job?.jobName === "run_scenario_campaign_forecast") &&
        scenario?.job?.metadata?.forecastModelSuccess === false,
);

// this is ugly but okay until we have better error handling on the backend.
export const getReadableJobError = createSelector(
    [currentlyViewingScenarioSelector, (state, campaigns) => campaigns],
    (scenario, campaigns) => {
        // Check if the job is related to forecasting
        if (scenario?.job?.jobName === FORECAST_JOB) {
            // Check if allocationModelSuccess is explicitly false or null
            if (
                scenario?.job?.metadata?.allocationModelSuccess === false ||
                scenario?.job?.metadata?.allocationModelSuccess === null
            ) {
                if (scenario?.job?.metadata?.allocationModelOutput?.error) {
                    return "Scenario allocation failed due to server error, please try again later.";
                }

                // Extract and process error message if it indicates a specific issue
                let err = scenario?.job?.metadata?.allocationModelOutput?.payload?.error;
                if (err && err.indexOf(".pkl not found") !== -1) {
                    const file = err.slice(err.lastIndexOf("/") + 1, err.indexOf(".pkl"));
                    const [channel, campaignId] = file.split("_SPEND_");
                    err = `Scenario allocation failed due to modeling issues. Try removing the ${stringToCapitalize(
                        channel,
                    )} campaign with the ${
                        campaigns[campaignId] === undefined
                            ? "id of " + campaignId
                            : "name of " + campaigns[campaignId] + " (" + campaignId + ")"
                    }`;
                }

                return err || "Scenario allocation failed due to server error, please try again later.";
            }
        }

        // Check if forecasting failed
        if (
            scenario?.job?.metadata?.forecastModelSuccess === false ||
            scenario?.job?.metadata?.forecastModelSuccess === null
        ) {
            let forecastErr = scenario?.job?.metadata?.forecastModelOutput?.error || "";
            if (forecastErr && forecastErr.indexOf(".pkl not found") !== -1) {
                const file = forecastErr.slice(forecastErr.lastIndexOf("/") + 1, forecastErr.indexOf(".pkl"));
                const [channel, campaignId] = file.split("_SPEND_");
                forecastErr = `Scenario forecasting failed due to modeling issues. Try removing the ${stringToCapitalize(
                    channel,
                )} campaign with the ${
                    campaigns[campaignId] === undefined
                        ? "id of " + campaignId
                        : "name of " + campaigns[campaignId] + " (" + campaignId + ")"
                }`;
            }

            return forecastErr || "Server error while forecasting, please try again later.";
        }

        // Check if there's an issue with allocationModelOutput
        if (scenario?.job?.metadata?.allocationModelOutput?.error) {
            return "Scenario allocation failed due to server error, please try again later.";
        }

        if (
            scenario?.job?.metadata?.allocationModelOutput?.payload?.success === false &&
            scenario?.job?.metadata?.allocationModelOutput?.payload?.error
        ) {
            const error = scenario?.job?.metadata?.allocationModelOutput?.payload?.error;

            if (typeof error === "string") {
                return error || "Scenario allocation failed due to server error, please try again later.";
            }

            // Extract maxCampaignConfidence values and find the minimum
            const confidences = Object.values(error).map((item: any) => item.maxCampaignConfidence);
            if (confidences.length > 0) {
                const lowestConfidence = Math.min(...confidences);

                return `Oops, the scenario encountered an issue. The highest confidence we can guarantee is ${
                    lowestConfidence * 100
                }%. It is recommended to modify the confidence input accordingly`;
            }
        }

        return "";
    },
);
