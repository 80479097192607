import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router";
import { Path } from "react-router-dom";
import { useBlocker } from "./useBlocker";

export function useCallbackPrompt(when: boolean): [boolean, () => void, () => void] {
    const navigate = useNavigate();
    const location = useLocation();
    const [showPrompt, setShowPrompt] = useState(false);
    const [pendingLocation, setPendingLocation] = useState<string | null>(null);
    const [shouldNavigate, setShouldNavigate] = useState(false);

    useEffect(() => {
        if (shouldNavigate && pendingLocation) {
            navigate(pendingLocation);

            // Clean-up state on confirmed navigation
            setShouldNavigate(false);
        }
    }, [shouldNavigate, pendingLocation]);

    const cancelNavigation = useCallback(() => {
        setShowPrompt(false);
        setPendingLocation(null);
    }, []);

    // handle blocking when user click on another route prompt will be shown
    const handleBlockedNavigation = useCallback(
        (nextLocation: Partial<Path>) => {
            // in if condition we are checking next location and current location are equals or not
            if (!shouldNavigate && nextLocation.pathname && nextLocation.pathname !== location.pathname) {
                setShowPrompt(true);
                setPendingLocation(nextLocation.pathname);
                return false;
            }
            return true;
        },
        [shouldNavigate, location],
    );

    const confirmNavigation = useCallback(() => {
        setShowPrompt(false);
        setShouldNavigate(true);
    }, []);

    useBlocker(handleBlockedNavigation, when);

    return [showPrompt, confirmNavigation, cancelNavigation];
}
