import { createSlice } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";

const initialState: boolean = false;

const demoModeSlice = createSlice({
    name: "demoMode",
    initialState,
    reducers: {
        setDemoMode: (state, { payload }) => payload,
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setDemoMode } = demoModeSlice.actions;

export default demoModeSlice.reducer;

// Selectors
export const demoModeSelector = (state: RootState) => state.isDemoMode;
