import { FC, useEffect, useMemo, useState, useRef } from "react";
import { Stack } from "@mui/system";
import { format } from "date-fns";
import { useSelector } from "react-redux";
import groupBy from "lodash/groupBy";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import DataThresholdingOutlinedIcon from "@mui/icons-material/DataThresholdingOutlined";
import { Button } from "@mui/material";

import { DashboardCardHeader } from "../CardComponents/CardHeader/CardHeader";
import { IDateSelectionRange, IHeadCell } from "src/interfaces/dashboard/trend";
import {
    MetricNameTypeForPayload,
    defaultDateRangeForHomePage,
    groupByChannelOption,
} from "src/consts/homeDashboard/homeDashboard";
import { useLazyGetAtiributionMetricTimeSeriesByNameQuery } from "src/reduxState/apis/historicalApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { BaseVsHaloChart, getSeriesData } from "./BaseVsHaloChart";
import { dataSourcesByProgrammaticNameSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { capitalizeFirstLetter, toCamelCase } from "src/services/utils";
import { TargetSelect } from "../CardComponents/TargetSelect";
import SimpleReactDateRange from "src/components/DateRangeFilter/SimpleReactDateRange";
import { DefaultDateRangeInText, getRangeFormattedTitle } from "src/components/DateRangeFilter/reactDateRangeUtils";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { HomePagePartialCard } from "../CardComponents/HomePagePartialCard";
import { ExportTableData } from "src/components/ExportTableData/ExportTableData";
import { IExportHead, exportCSV, prepareDataForCSV } from "src/services/exportCSV";
import { IDictionary } from "src/interfaces/IDictionary";

export interface IBaseHaloColumn {
    channel?: string;
    total?: number;
    base?: number;
    halo?: number;
    haloPercent?: number;
    shopifyBase?: number;
    shopifyHalo?: number;
    amazonBase?: number;
    amazonHalo?: number;
    [key: string]: number | string | undefined;
}

interface IBaseVsHaloCard {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const BaseVsHaloCard: FC<IBaseVsHaloCard> = ({ tooltipRef }) => {
    const orgId = useSelector(selectCurrentlyViewingId);
    const dataSources = useSelector(dataSourcesByProgrammaticNameSelector);
    const partialStateForHome = useSelector(partialStateSelectorForHome);

    const chartTitle = useMemo(() => "Base vs Halo Effect Rollup", []);

    const [startRange, setStartRange] = useState<IDateSelectionRange>(defaultDateRangeForHomePage);
    const [grpBy, setGrpBy] = useState<string>(groupByChannelOption[0].id);

    const [getAtiributionMetricTimeSeriesByName, { data = { data: [] }, isFetching, isLoading }] =
        useLazyGetAtiributionMetricTimeSeriesByNameQuery();

    useEffect(() => {
        setGrpBy(groupByChannelOption[0].id);
        setStartRange(defaultDateRangeForHomePage);
    }, [orgId]);

    useEffect(() => {
        const { startDate, endDate } = startRange.selection;
        getAtiributionMetricTimeSeriesByName({
            orgId,
            start_date: format(startDate, "yyyy-MM-dd"),
            end_date: format(endDate, "yyyy-MM-dd"),
            metric_names: [MetricNameTypeForPayload.FirstOrderRevenue, MetricNameTypeForPayload.SecondOrderRevenue],
        });
    }, [startRange, orgId]);

    const dateRangeChangeHandler = (dateRange: IDateSelectionRange, selectedPreset: DefaultDateRangeInText) => {
        setStartRange(dateRange);
    };

    const handleGrpByChange = (value: string) => {
        setGrpBy(value);
    };

    const grpByTarget = () => {
        const dataWithChannelName = data.data.map((c) => ({
            ...c,
            channelName: dataSources[c.name]?.name || capitalizeFirstLetter(c.name),
        }));
        const groupedData = groupBy(dataWithChannelName, "target");
        return grpBy === "total" ? dataWithChannelName : groupedData[`${grpBy}_revenue`];
    };

    const isApiLoading = isFetching || isLoading;

    const partialHomeElement = (
        <HomePagePartialCard
            icon={
                <DataThresholdingOutlinedIcon
                    color="primary"
                    sx={{
                        width: "56px",
                        height: "56px",
                        opacity: "100%",
                    }}
                />
            }
            heading="Assembling Base vs Halo Effect Rollup"
            description={
                <>
                    Results typically begins to flow 1 week after data verification is complete. Please allow more time
                    for Amazon. <br /> Check back later!
                </>
            }
            actionButton={
                <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    href="https://help.prescient-ai.io/en/articles/9293715-new-feature-tour-homepage#h_8b9ffd8156"
                    target="_blank"
                >
                    Learn More About Base vs Halo Effect Rollup
                </Button>
            }
            style={{
                padding: "var(--4, 32px) 345px",
            }}
        />
    );
    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        setOpenExportMenu(false);
    };

    const seriesData = grpByTarget();

    const metrics: IDictionary = useMemo(
        () => ({
            first_order_revenue: {
                name: "Base",
            },
            second_order_revenue: {
                name: "Halo",
            },
        }),
        [grpBy],
    );

    const processedData = useMemo(() => getSeriesData(seriesData, metrics), [seriesData, metrics]);

    const anchorRef = useRef<HTMLDivElement>(null);

    const exportVisibleColumns = () => {
        const colNames: Array<{ id: keyof IBaseHaloColumn; label: string }> = [
            { id: "channel", label: "Channel" },
            {
                id: "total",
                label: "Total Revenue",
            },
            {
                id: "base",
                label: "Base Revenue",
            },
            {
                id: "halo",
                label: "Halo Revenue",
            },
        ];

        processedData.forEach((key) => {
            colNames.push({
                id: toCamelCase(key.name),
                label: key.name,
            });
        });

        const cols: IExportHead[] = [];
        colNames.forEach((col) => {
            cols.push({
                ...col,
                numeric: true,
                disablePadding: false,
                width: "150px",
            });
        });
        const rows: Array<Record<string, any>> = [];
        processedData.forEach((col) => {
            col.data.forEach((channel) => {
                let row = rows.find((r) => r.channel === channel[1]);
                if (!row) {
                    rows.push({ channel: channel[1], isDollars: true });
                    row = rows[rows.length - 1];
                }
                const amount = channel[2];
                row.total ??= 0;
                row.total += amount;
                const propertyMap: { [key: string]: string[] } = {};

                processedData.forEach((key) => {
                    propertyMap[key.name] ??= [];
                    propertyMap[key.name].push(toCamelCase(key.name));
                    if (key.name.toLowerCase().includes("base")) {
                        propertyMap[key.name].push("base");
                    } else if (key.name.toLowerCase().includes("halo")) {
                        propertyMap[key.name].push("halo");
                    }
                });

                const properties = propertyMap[col.name];
                if (properties) {
                    properties.forEach((prop) => {
                        if (row !== undefined) {
                            row[prop] ??= 0;
                            row[prop] += amount;
                        }
                    });
                }
            });
        });
        const sortedRows = rows.sort((a, b) => {
            if (a.channel.toLowerCase() === "total") {
                return -1;
            }
            if (b.channel.toLowerCase() === "total") {
                return 1;
            }
            return a.channel.localeCompare(b.channel);
        });

        exportCSV(
            "Base vs Halo Effect " + getRangeFormattedTitle([startRange.selection]),
            prepareDataForCSV(sortedRows, cols),
        );
    };

    const [openExportMenu, setOpenExportMenu] = useState(false);

    const handleExportToggle = () => {
        setOpenExportMenu((prevOpen) => !prevOpen);
    };

    const handleExportMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenExportMenu(false);
    };

    return (
        <HomepageCardContainer>
            <Stack gap="15px">
                <DashboardCardHeader
                    title="Base vs Halo Effect Rollup"
                    tooltipRef={tooltipRef}
                    tooltip="Overall base to halo mix for your business"
                    instances={[
                        <TargetSelect
                            value={grpBy}
                            handleChannelGrpByChange={handleGrpByChange}
                            isDisabled={isApiLoading}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                        />,
                        <SimpleReactDateRange
                            compareView={false}
                            startRange={startRange}
                            applyRangeHandler={(selectedRange, selectedPreset) =>
                                dateRangeChangeHandler(selectedRange, selectedPreset)
                            }
                            isDisabled={isApiLoading}
                            chartTitle={chartTitle}
                            isUpgraded={true}
                        />,
                        <ExportTableData
                            handleMenuItemClick={handleMenuItemClick}
                            anchorRef={anchorRef}
                            exportVisibleColumns={exportVisibleColumns}
                            openExportMenu={openExportMenu}
                            handleExportToggle={handleExportToggle}
                            handleExportMenuClose={handleExportMenuClose}
                            noPopup
                        />,
                    ]}
                />
            </Stack>
            {partialStateForHome ? (
                partialHomeElement
            ) : (
                <Stack style={{ width: "98%", overflow: "auto", height: "700px" }}>
                    <BaseVsHaloChart seriesData={grpByTarget()} isFetching={isApiLoading} grpBy={grpBy} />
                </Stack>
            )}
        </HomepageCardContainer>
    );
};
