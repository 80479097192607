import { IDictionary } from "src/interfaces/IDictionary";
import { IOnboardingForm } from "src/interfaces/entities/IOnboardingState";

export enum OnboardingFormTab {
    details = "details",
    onboarding = "onboarding",
}

export enum OnboardingStateValue {
    incomplete = "incomplete",
    complete = "complete",
    ready = "ready",
}

export const onboardingState = {
    ecommerce: false,
    googleAnalytics: false,
    paid: false,
    dataQa: false,
};

export const ONBOARDING_PAGES: IOnboardingForm[] = [
    { label: "Ecommerce", key: "eCommerce", value: OnboardingStateValue.incomplete },
    { label: "Web Analytics", key: "webAnalytics", value: OnboardingStateValue.incomplete },
    { label: "Paid Advertising", key: "paidMarketing", value: OnboardingStateValue.incomplete },
    { label: "Data QA", key: "dataQa", value: OnboardingStateValue.incomplete },
];

export const ONBOARDING_VALUE_OPTIONS = [
    { value: OnboardingStateValue.complete, label: "Complete" },
    { value: OnboardingStateValue.ready, label: "Ready" },
    { value: OnboardingStateValue.incomplete, label: "Incomplete" },
];

export const mappingOnboardingToApiState: IDictionary = {
    eCommerce: "e_commerce",
    webAnalytics: "web_analytics",
    paidMarketing: "paid_marketing",
    dataQa: "data_qa",
};

export const warningModalText =
    "We use Fivetran to securely connect your account. You’ll be re-directed to their site when you click continue.";
