import React, { memo, useMemo } from "react";
import { Alert, AlertTitle, Button, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";
import { useNavigate } from "react-router-dom";

import { BILLING_PATH } from "src/consts/path/path";
import {
    selectCurrentlyViewingCode,
    selectSubscriptionPastDueAndHasAccess,
} from "src/reduxState/slices/organizationSlice";
import { currentUserRoleByCompanyIdSelector } from "src/reduxState/slices/userSlice";
import { UserRole } from "src/enums/UserRole";

const BillingAlert = () => {
    const navigate = useNavigate();
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const subscriptionPastDueAndHasAccess = useSelector(selectSubscriptionPastDueAndHasAccess);

    const showAlert = useMemo(() => {
        const isAdminUser = userRole === UserRole.ADMIN;
        return subscriptionPastDueAndHasAccess && isAdminUser && !location.pathname.includes(BILLING_PATH);
    }, [userRole, subscriptionPastDueAndHasAccess, location.pathname]);

    if (!showAlert) {
        return null;
    }

    const redirectToBilling = () => {
        navigate(`/org/${orgCode}${BILLING_PATH}`);
    };

    return (
        <Stack mb="30px">
            <Alert
                sx={{ alignItems: "center", border: "1px solid rgb(240, 137, 52)" }}
                severity="warning"
                action={
                    <Button color="warning" variant="outlined" onClick={redirectToBilling}>
                        Go to billing settings
                    </Button>
                }
            >
                <AlertTitle>Billing Alert</AlertTitle>
                <Typography>Your subscription is past due. Please update your billing information. </Typography>
            </Alert>
        </Stack>
    );
};

export default memo(BillingAlert);
