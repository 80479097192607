import { createSlice, createSelector } from "@reduxjs/toolkit";
import { ActivePerformanceTabs } from "../../enums/ActivePerfomanceTabs";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";

const initialState: Array<{
    data: IMetricAttributionTableValuesTransformed;
    isMinimize: boolean;
    activeTab: ActivePerformanceTabs;
    pastData: IMetricAttributionTableValuesTransformed;
    activeMetric: string;
}> = [];

const insightsModalSlice = createSlice({
    name: "insightsModal",
    initialState,
    reducers: {
        setInsightsModal: (state, { payload: newInsightsModal }) => {
            let updatedState = [...state];
            // if window size < 1300 then always open one modal at a time otherwise replace second modal with new one
            if (state.length < 2) {
                if (window.innerWidth < 1300) {
                    updatedState = [newInsightsModal];
                } else {
                    updatedState = [...state, newInsightsModal];
                }
            } else {
                updatedState = [state[0], newInsightsModal];
            }
            const uniqueModalsByCampaignIds = [
                ...new Map(updatedState.map((item) => [item.data.campaignId, item])).values(),
            ];
            return uniqueModalsByCampaignIds;
        },
        closeInsightsmodal: (state, { payload: closeCampaignId }) => {
            return state.filter((d) => d.data.campaignId !== closeCampaignId);
        },
        handleInsightsModalMinimization: (state, { payload: minimizeCampaignId }) => {
            return state.map((d) =>
                d.data.campaignId === minimizeCampaignId ? { ...d, isMinimize: !d.isMinimize } : d,
            );
        },
        setInsightsModalActiveTab: (state, { payload }) => {
            const { activeTab, campaignId } = payload;
            return state.map((d) => (d.data.campaignId === campaignId ? { ...d, activeTab } : d));
        },
        setInsightsModalActiveMetric: (state, { payload }) => {
            const { activeMetric, campaignId } = payload;
            return state.map((d) => (d.data.campaignId === campaignId ? { ...d, activeMetric } : d));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const {
    setInsightsModal,
    closeInsightsmodal,
    handleInsightsModalMinimization,
    setInsightsModalActiveTab,
    setInsightsModalActiveMetric,
} = insightsModalSlice.actions;

export default insightsModalSlice.reducer;

// Selectors
export const insightsModalSelector = (state: RootState) => state.insightsModal;

export const openedInsightsModalIdsSelector = createSelector(insightsModalSelector, (data) =>
    data.map((d) => d.data.campaignId),
);
