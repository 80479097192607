import { ReactNode } from "react";
import camelCase from "lodash/camelCase";
import isPlainObject from "lodash/isPlainObject";
import isArray from "lodash/isArray";
import forEach from "lodash/forEach";

export const keysToCamelCase = (obj: any) => {
    let camelCaseObject = {} as any;
    if (isArray(obj)) {
        camelCaseObject = [] as any;
    }

    forEach(obj, (v, k) => {
        if (isPlainObject(v) || isArray(v)) {
            v = keysToCamelCase(v);
        }

        camelCaseObject[camelCase(k)] = v;
    });

    return camelCaseObject;
};

export const removeKeysWithNullValues = (obj: any) => {
    return Object.entries(obj)
        .filter(([_, v]) => v != null)
        .reduce((acc, [k, v]) => ({ ...acc, [k]: v }), {});
};

export const makeRenderable = (value: any): ReactNode => {
    if (!value) {
        return "-";
    }

    if (typeof value === "object") {
        return JSON.stringify(value);
    }

    return value;
};
