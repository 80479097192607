import { Stack } from "@mui/system";
import { DashboardHeader, IDataForPopper } from "./DashboardHeader";
import { Divider } from "@mui/material";
import { Fragment } from "react";

export const DashboardCardHeader = ({
    title,
    tooltip,
    instances,
    processDate,
    isBetaCard = false,
    tooltipRef,
}: {
    title: React.ReactNode;
    tooltip?: React.ReactNode;
    instances: any[];
    processDate?: IDataForPopper[];
    isBetaCard?: boolean;
    tooltipRef: React.RefObject<HTMLDivElement>;
}) => {
    return (
        <Stack gap={2}>
            <Stack direction="row" justifyContent="space-between" alignItems="center" gap={2}>
                <DashboardHeader
                    isBetaCard={isBetaCard}
                    text={title}
                    dataForPopper={processDate}
                    isMarginBottom={false}
                    tooltipRef={tooltipRef}
                    tooltip={tooltip}
                    tooltipPlacement="right"
                />
                <Stack alignItems="center" direction="row" gap={2}>
                    {instances.map((instance, index) => (
                        <Fragment key={index}>{instance}</Fragment>
                    ))}
                </Stack>
            </Stack>
            <Divider />
        </Stack>
    );
};
