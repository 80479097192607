import baseCreateApi from "./baseCreateApi";
import { IDimCampaignResponse } from "src/interfaces/dimCampaignResponse/IDimCampaignResponse";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { formatDimCampaigns } from "src/services/dimCampaigns/dimCampaigns";

export const dimCampaigns = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getDimCampaigns: builder.query({
            query: (args: { orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/dim_campaigns?include=campaign_tactic_type_id,is_included`,
                    method: "GET",
                };
            },
            transformResponse: (response: { data: IDimCampaignResponse[] }): IDimCampaign[] =>
                formatDimCampaigns(response),
        }),
    }),
});

export const { useLazyGetDimCampaignsQuery } = dimCampaigns;
