import { FC } from "react";
import { Typography } from "@mui/material";
import { CustomModal } from "src/components/core/CustomDialog/CustomModal";
import { IConnector } from "src/interfaces/IConnector";
import { IDataSource } from "src/interfaces/IData";

interface IConnectorConnectModal {
    selectedConnector: IConnector | IDataSource;
    connectorName: string;
    closeModal: () => void;
    reconnectConnectorHandler: (connector: any) => void;
    isLoading: boolean;
}

export const ConnectorConnectModal: FC<IConnectorConnectModal> = ({
    selectedConnector,
    connectorName,
    closeModal,
    reconnectConnectorHandler,
    isLoading,
}) => {
    return (
        <CustomModal
            title={`Connect ${connectorName}`}
            instanceSlot={
                <Typography>
                    Securely linking your account via Fivetran. Click 'Continue' to proceed to their site.
                </Typography>
            }
            actions={{
                2: {
                    label: "Cancel",
                    onClick: closeModal,
                    disabled: isLoading,
                },
                1: {
                    label: "Continue",
                    onClick: () => reconnectConnectorHandler(selectedConnector),
                    disabled: isLoading,
                },
            }}
            closeIcon={false}
            closeModal={closeModal}
        />
    );
};
