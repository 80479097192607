import { differenceInDays, subDays } from "date-fns";
import cloneDeep from "lodash/cloneDeep";

import { IDictionary } from "src/interfaces/IDictionary";
import { IDateRange, ITableMetrics, ITrendTableRows, MetricType } from "src/interfaces/dashboard/trend";
import { calculateAverageOrder, calculatePercentage, calculateROAS } from "../utils";
import { FORMATS } from "src/enums/Formats";
import { IMetricSparkValuesType, IMetricsType } from "src/interfaces/entities/IPerformanceTrend";
import { PERFORMANCE_KPI_METRICS_TABS } from "src/consts/homeDashboard/homeDashboard";

export const metricWithDefaultValue: IMetricsType = {
    firstOrderConversions: 0,
    firstOrderRevenue: 0,
    orders: 0,
    secondOrderConversions: 0,
    secondOrderRevenue: 0,
    spend: 0,
    channelReportedRevenue: 0,
};

export const ROWS_NOT_IN_PERFORMANCE_TABLE = [
    {
        id: "spend",
        numeric: true,
        label: "Spend",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "channelReportedRevenue",
        numeric: true,
        label: "Channel Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "channelReportedRoas",
        numeric: true,
        label: "Channel ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
    },
    {
        id: "modeledRevenue",
        numeric: true,
        label: "MMM Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "modeledRoas",
        numeric: true,
        label: "MMM ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
    },
    {
        id: "modeledNewCustomers",
        numeric: true,
        label: "MMM New Customers",
        sign: FORMATS.NUMERIC,
        fixed: 0,
    },
    {
        id: "modeledCac",
        numeric: true,
        label: "MMM CAC",
        sign: FORMATS.DOLLAR,
        fixed: 2,
    },
    {
        id: "aov",
        numeric: true,
        label: "Ecommerce Average Order Value",
        sign: FORMATS.DOLLAR,
        fixed: 2,
    },
    {
        id: "orders",
        numeric: true,
        label: "Ecommerce Orders",
        sign: FORMATS.NUMERIC,
        fixed: 0,
    },
    {
        id: "revenue",
        numeric: true,
        label: "Ecommerce Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
    },
    {
        id: "roas",
        numeric: true,
        label: "Ecommerce ROAS",
        sign: FORMATS.NUMERIC,
        fixed: 2,
    },
];

export const findMetric = (metric: string) => [...ROWS_NOT_IN_PERFORMANCE_TABLE].find((h) => h.id === metric);

export const getPastDateRangeForTrend = (dateRange: IDateRange): Omit<IDateRange, "key"> => {
    const diff = differenceInDays(dateRange.endDate, dateRange.startDate);
    const pastEndDate = subDays(dateRange.startDate, 1);
    const pastStartDate = subDays(pastEndDate, diff);

    return {
        startDate: pastStartDate,
        endDate: pastEndDate,
    };
};

export const calculateAllMetrics = (metricsData: IMetricsType): ITableMetrics => {
    const MMMRevenue = metricsData.firstOrderRevenue + metricsData.secondOrderRevenue;
    const MMMNewCustomers = metricsData.firstOrderConversions + metricsData.secondOrderConversions;

    return {
        spend: metricsData.spend,
        modeledRevenue: MMMRevenue,
        modeledRoas: metricsData.modeledRoas,
        channelReportedRevenue: metricsData.channelReportedRevenue,
        channelReportedRoas: metricsData.channelReportedRoas,
        orders: metricsData.orders,
        aov:
            metricsData.revenue && metricsData.orders
                ? calculateAverageOrder(metricsData.revenue, metricsData.orders)
                : 0,
        modeledNewCustomers: MMMNewCustomers,
        modeledCac: metricsData.modeledCac,
        revenue: metricsData.revenue,
        roas: calculateROAS(metricsData.spend, metricsData.revenue),
    };
};

export const createTableRow = (
    metric: MetricType,
    current: number = 0,
    prior: number = 0,
    sparkLine: number[],
): ITrendTableRows => {
    return {
        metric,
        current,
        prior,
        difference: current - prior,
        change: calculatePercentage(current, prior),
        sparkLine,
        id: PERFORMANCE_KPI_METRICS_TABS.find((tab) => tab.tooltipId === metric)?.id || metric,
    };
};

function calculateTotalByDate(obj1: IMetricsType, obj2: IMetricsType) {
    const result: IDictionary<number> = {};

    // Combine keys from both objects
    const keys = Array.from(new Set([...Object.keys(obj1), ...Object.keys(obj2)]));

    // Iterate through the keys and sum values with the same date keys
    keys.forEach((date) => {
        if (result[date]) {
            result[date] = 0;
        } else {
            result[date] = (obj1[date] || 0) + (obj2[date] || 0);
        }
    });

    return result;
}

export const getTrendTableRows = (
    currentData: IMetricsType,
    pastData: IMetricsType,
    currentRowData: ITableMetrics,
    pastRowData: ITableMetrics,
    metricSparkValues: IMetricSparkValuesType,
) => {
    const currentDataWithValue = {
        ...metricWithDefaultValue,
        ...currentData,
    };
    const pastDataWithValue = {
        ...metricWithDefaultValue,
        ...pastData,
    };

    const metricSparkValuesWithValue: IMetricSparkValuesType = {
        firstOrderConversions: {},
        firstOrderRevenue: {},
        orders: {},
        revenue: {},
        secondOrderConversions: {},
        secondOrderRevenue: {},
        spend: {},
        channelReportedRevenue: {},
        ...cloneDeep(metricSparkValues),
    };

    return Object.keys(currentRowData).map((h: string) => {
        switch (h) {
            case "roas": {
                const currentRoas = calculateROAS(currentDataWithValue.spend, currentDataWithValue.revenue);
                const pastRoas = calculateROAS(pastRowData.spend, pastDataWithValue.revenue);

                const roasPerDate: IDictionary = {};
                Object.keys(metricSparkValuesWithValue.revenue).forEach((date) => {
                    if (metricSparkValuesWithValue.spend[date]) {
                        roasPerDate[date] = calculateROAS(
                            metricSparkValuesWithValue.spend[date],
                            metricSparkValuesWithValue.revenue[date],
                        );
                    }
                });

                return createTableRow(h, currentRoas, pastRoas, Object.values(roasPerDate));
            }
            case "modeledRevenue": {
                const currenTrueRevenue =
                    currentDataWithValue.firstOrderRevenue + currentDataWithValue.secondOrderRevenue;
                const pastTrueRevenue = pastDataWithValue.firstOrderRevenue + pastDataWithValue.secondOrderRevenue;

                return createTableRow(
                    h,
                    currenTrueRevenue,
                    pastTrueRevenue,
                    Object.values(
                        calculateTotalByDate(
                            metricSparkValuesWithValue.firstOrderRevenue,
                            metricSparkValuesWithValue.secondOrderRevenue,
                        ),
                    ),
                );
            }
            case "channelReportedRevenue": {
                return createTableRow(
                    h,
                    currentDataWithValue.channelReportedRevenue,
                    pastDataWithValue.channelReportedRevenue,
                    Object.values(metricSparkValuesWithValue.channelReportedRevenue),
                );
            }
            case "modeledNewCustomers": {
                const currenNewCustomers =
                    currentDataWithValue.firstOrderConversions + currentDataWithValue.secondOrderConversions;
                const pastNewCustomers =
                    pastDataWithValue.firstOrderConversions + pastDataWithValue.secondOrderConversions;

                return createTableRow(
                    h,
                    currenNewCustomers,
                    pastNewCustomers,
                    Object.values(
                        calculateTotalByDate(
                            metricSparkValuesWithValue.firstOrderConversions,
                            metricSparkValuesWithValue.secondOrderConversions,
                        ),
                    ),
                );
            }
            case "aov": {
                const currentAOV = currentDataWithValue.orders
                    ? calculateAverageOrder(currentDataWithValue.revenue, currentDataWithValue.orders)
                    : 0;
                const pastAov = pastRowData.orders
                    ? calculateAverageOrder(pastDataWithValue.revenue, pastRowData.orders)
                    : 0;

                const aov: IDictionary = {};
                Object.keys(metricSparkValuesWithValue.orders).forEach((date) => {
                    if (metricSparkValuesWithValue.spend[date]) {
                        aov[date] = calculateAverageOrder(
                            metricSparkValuesWithValue.revenue[date],
                            metricSparkValuesWithValue.orders[date],
                        );
                    }
                });

                return createTableRow(h, currentAOV, pastAov, Object.values(aov));
            }
            default: {
                return createTableRow(
                    h as MetricType,
                    currentDataWithValue[h] || 0,
                    pastDataWithValue[h] || 0,
                    Object.values(metricSparkValues[h] || {}),
                );
            }
        }
    });
};

export const defaultMetricSortBy = [
    "modeledRevenue",
    "modeledRoas",
    "modeledNewCustomers",
    "modeledCac",
    "spend",
    "channelReportedRevenue",
    "channelReportedRoas",
    "revenue",
    "roas",
    "orders",
    "aov",
];
