import React, { FC, Fragment } from "react";
import { TableCell, Tooltip } from "@mui/material";
import { css } from "@emotion/css";
import { Box } from "@mui/system";

import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const useStyles = () => ({
    iconCell: css({ height: 0, paddingTop: "0!important", paddingBottom: "0!important" }),
    iconWrapper: css({
        display: "flex",
        justifyContent: "flex-end",
        gap: "10px",
        alignItems: "center",
        height: "100%",
        minHeight: "60px",
    }),
    iconClass: css({ height: "100%", display: "flex", alignItems: "center" }),
});

interface ITableActionCell {
    actionIcons: Array<{
        title: string;
        onClick: any;
        data: any;
        icon: string;
        strokeColor?: string;
        id?: string;
    }>;
}

export const TableActionCell: FC<ITableActionCell> = ({ actionIcons }) => {
    const classes = { ...useStyles(), ...useStylesForRowInTable() };

    return (
        <TableCell align="right" className={`${classes.tableCell} ${classes.iconCell}`}>
            <div className={classes.iconWrapper}>
                {actionIcons.map((action: any) => {
                    return (
                        <Fragment key={`${action.data.id}-${action.title}`}>
                            <Tooltip title={action.title}>
                                <Box
                                    data-cy={action.id || ""}
                                    className={classes.iconClass}
                                    onClick={(e) => action.onClick(e, action.data)}
                                >
                                    <SVGIconRenderer icon={action.icon} strokeColor={action.strokeColor} />
                                </Box>
                            </Tooltip>
                        </Fragment>
                    );
                })}
            </div>
        </TableCell>
    );
};
