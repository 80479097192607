import React from "react";
import { Typography } from "@mui/material";
import { CustomModal } from "../../CustomModal/CustomModal";
import { IConnector } from "../../../interfaces/IConnector";

interface IPurgeConnectorModalProps {
    connector: IConnector;
    purgeLoading: boolean;
    closeModal: () => void;
    purgeConnector: (connector: IConnector) => Promise<void>;
}

export const PurgeConnectorModal: React.FC<IPurgeConnectorModalProps> = ({
    closeModal,
    connector,
    purgeConnector,
    purgeLoading,
}) => {
    return (
        <CustomModal
            title="Purge Connector?"
            subTitle={`Purging your ${connector.connectorName} connector will:`}
            details={
                <>
                    <Typography component="li" variant="body2">
                        - Can’t undo a purge without resyncing
                    </Typography>
                </>
            }
            buttonName="Purge"
            handleAction={purgeConnector}
            selectedConnector={connector}
            closeModal={closeModal}
            disableBtn={purgeLoading}
            type="error"
        />
    );
};
