import get from "lodash/get";

import { keysToCamelCase, removeKeysWithNullValues } from "../../utils/format";
import baseCreateApi from "./baseCreateApi";
import { logoutUser } from "../actions/auth";
import { setUser, setUserAfterLoginCheck } from "../slices/userSlice";
import { setOrganizationsOnSignIn, addOrganizations } from "../slices/organizationSlice";
import { USERS, COMPANIES, USERS_ROLES, SETTINGS, FEATURES } from "../../consts/entities";
import { IUserLoginValues } from "../../interfaces/IUserLoginValues";
import { IHelpPageValues } from "../../containers/Help/HelpPage";
import { IResetPasswordValues } from "../../interfaces/IResetPasswordValues";
import { IAcceptInvitationValues } from "../../interfaces/IAcceptInvitationValues";
import { IMember } from "../../interfaces/entities/IMember";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IMemberRole } from "../../interfaces/entities/IMemberRole";
import { setUserSettings } from "../slices/settingsSlice";
import { IFeature } from "../../interfaces/entities/IFeature";
import { setFeatures } from "../slices/featuresSlice";

const getHeaders = () => {
    return {
        Accept: "application/vnd.api+json",
        "Content-Type": "application/vnd.api+json",
    };
};

interface IAuthEntities {
    users: IMember[];
    users_roles: IMemberRole[];
    companies: IOrganization[];
    settings: any[];
    features: IFeature[];
}

export const getEntitiesFromIncludes = (included: any, entities: IAuthEntities): IAuthEntities => {
    included.forEach((obj: any) => {
        const { id, type, relationships, attributes } = obj;
        const transformedAttributes = keysToCamelCase(attributes);
        switch (type) {
            case USERS:
                const companies = get(relationships, "companies.data") || [];
                entities.users.push({
                    id,
                    companies: companies.map((c: { id: string; type: string }) => c.id),
                    ...transformedAttributes,
                } as IMember);
                break;
            case COMPANIES:
                const features = get(relationships, "features.data") || [];
                entities.companies.push({
                    id,
                    features: features.map((f: { id: string; type: string }) => f.id),
                    code: transformedAttributes.name.replace(/\s+/g, "_").toLowerCase(),
                    ...transformedAttributes,
                } as IOrganization);
                break;
            case USERS_ROLES:
                const company = get(relationships, "company.data") || {};
                const user = get(relationships, "user.data") || {};
                entities.users_roles.push({
                    id,
                    userId: user.id,
                    companyId: company.id,
                    ...transformedAttributes,
                } as IMemberRole);
                break;
            case SETTINGS:
                entities.settings.push({
                    id,
                    ...transformedAttributes,
                } as any);
                break;
            case FEATURES:
                entities.features.push({
                    id,
                    ...transformedAttributes,
                } as IFeature);
        }
    });

    const { users, users_roles } = entities;

    if (users.length) {
        const user = users[0];
        user.rolesByCompanyId = users_roles.reduce((acc, item) => Object.assign(acc, { [item.companyId]: item }), {});
    }

    return entities;
};

const setUserHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const {
            data: { data: data, included: included },
        } = await queryFulfilled;

        const entities: IAuthEntities = getEntitiesFromIncludes(included, {
            users: [],
            users_roles: [],
            companies: [],
            settings: [],
            features: [],
        });

        const { users, companies, settings, features } = entities;
        if (users.length) {
            await dispatch(setUser(users[0]));
        }

        if (settings.length) {
            await dispatch(setUserSettings({ settings: settings[0].settings, fromApi: true }));
        }

        if (companies.length) {
            const cleanedCompanies = companies.map((c) => removeKeysWithNullValues(c));
            await dispatch(
                setOrganizationsOnSignIn({ companies: cleanedCompanies, isSuperAdmin: users[0].isSuperadmin }),
            );
        }

        if (features.length) {
            await dispatch(setFeatures(features));
        }
    } catch (error) {
        return error;
    }
};

const checkLoggedInUserHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const {
            data: { data: data, included: included = [] },
        } = await queryFulfilled;

        const entities: IAuthEntities = getEntitiesFromIncludes(included.concat(data), {
            users: [],
            users_roles: [],
            companies: [],
            settings: [],
            features: [],
        });

        const { users, companies, settings, features } = entities;

        if (settings.length) {
            await dispatch(setUserSettings({ settings: settings[0].settings, fromApi: true }));
        }

        if (users.length) {
            await dispatch(setUserAfterLoginCheck(users[0]));
        }

        if (companies.length) {
            const cleanedCompanies = companies.map((c) => removeKeysWithNullValues(c));
            await dispatch(
                addOrganizations({ companies: cleanedCompanies, isSuperAdmin: data.attributes.is_superadmin }),
            );
        }

        if (features.length) {
            await dispatch(setFeatures(features));
        }
    } catch (error) {
        console.error(error);
        return error;
    }
};

export const authApi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        loginApi: builder.mutation({
            query: (credentials: IUserLoginValues) => ({
                url: "/sessions?include=user.companies,user.users_roles,user.user_settings,user.companies.features",
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify({
                    data: {
                        attributes: credentials,
                        type: "sessions",
                    },
                }),
            }),
            onQueryStarted(credentials, { dispatch, queryFulfilled }) {
                setUserHandler({ dispatch, queryFulfilled });
            },
        }),
        logout: builder.mutation({
            query: () => ({
                url: "/sessions",
                method: "DELETE",
                headers: getHeaders(),
            }),
            onQueryStarted(args, { dispatch, queryFulfilled }) {
                dispatch(logoutUser());
            },
        }),
        getLoggedInUser: builder.query({
            query: () => ({
                url: `sessions/me?include=companies,users_roles,user_settings,companies.features`,
                method: "GET",
            }),
            onQueryStarted(args, { dispatch, queryFulfilled }) {
                checkLoggedInUserHandler({ dispatch, queryFulfilled });
            },
        }),
        forgotPassword: builder.mutation({
            query: (email: string) => ({
                url: "passwords",
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify({
                    data: {
                        attributes: {
                            email,
                        },
                        type: "passwords",
                    },
                }),
            }),
        }),
        sendHelpMessage: builder.mutation({
            query: (body: IHelpPageValues) => ({
                url: "support",
                method: "POST",
                headers: getHeaders(),
                body: JSON.stringify(body),
            }),
        }),
        changePassword: builder.mutation({
            query: (body: IResetPasswordValues) => ({
                url: "passwords",
                method: "PATCH",
                headers: getHeaders(),
                body: JSON.stringify({
                    data: {
                        attributes: {
                            password: body.password,
                            password_confirmation: body.passwordConfirmation,
                            reset_password_token: body.resetPasswordToken,
                        },
                        type: "passwords",
                    },
                }),
            }),
        }),
        confirmEmail: builder.mutation({
            query: (token: string) => ({
                url: "confirmations",
                method: "PATCH",
                headers: getHeaders(),
                body: JSON.stringify({
                    data: {
                        attributes: {
                            confirmation_token: token,
                        },
                        type: "passwords",
                    },
                }),
            }),
        }),
        acceptInvitation: builder.mutation({
            query: (body: IAcceptInvitationValues) => ({
                url: "invitations",
                method: "PATCH",
                headers: getHeaders(),
                body: JSON.stringify({
                    data: {
                        attributes: {
                            invitation_token: body.invitationToken,
                            password: body.password,
                            password_confirmation: body.passwordConfirmation,
                        },
                        type: "passwords",
                    },
                }),
            }),
        }),
    }),
});

export const {
    useLoginApiMutation,
    useForgotPasswordMutation,
    useLogoutMutation,
    useGetLoggedInUserQuery,
    useLazyGetLoggedInUserQuery,
    useSendHelpMessageMutation,
    useChangePasswordMutation,
    useConfirmEmailMutation,
    useAcceptInvitationMutation,
} = authApi;
