import { Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import queryString from "query-string";

import { HorizontalBarChart } from "../HorizontalBarChart";
import { ISecondOrderEffects } from "../../../../../interfaces/entities/ISecondOrderEffects";
import { IMetricAttributionTableValuesTransformed } from "../../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { getHaloEffectBreakdownData, getKeyAttributionData } from "../attributionUtils";
import { IDictionary } from "src/interfaces/IDictionary";
import { getPercentChangeInMetric } from "src/services/performancePage/performancePage";
import { PercentageTable } from "../../PerformanceTab/PercentageTable";

export const NewCustomerChart = ({
    chartData,
    comparisonData,
    performanceCampaignData,
}: {
    chartData: ISecondOrderEffects[];
    comparisonData: ISecondOrderEffects[];
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
}) => {
    const filterQueryParamsObj = queryString.parse(window.location.search);

    const keyAttributionFormattedData = getKeyAttributionData(chartData, performanceCampaignData);
    const keys = keyAttributionFormattedData.legendBoxArr.map((r) => r.name);
    const legends = keyAttributionFormattedData.legendBoxArr.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const pastKeyAttributionFormattedData = getKeyAttributionData(comparisonData, performanceCampaignData);
    const pastLegends = pastKeyAttributionFormattedData.legendBoxArr.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);
    const percentageData = getPercentChangeInMetric(legends, pastLegends);

    const haloFormattedData = getHaloEffectBreakdownData(chartData, performanceCampaignData);
    const haloKeys = haloFormattedData.legendBoxArr.map((r) => r.name);
    const haloLegends = haloFormattedData.legendBoxArr.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);

    const haloPastKeyAttributionFormattedData = getHaloEffectBreakdownData(comparisonData, performanceCampaignData);
    const haloPastLegends = haloPastKeyAttributionFormattedData.legendBoxArr.reduce((acc, r) => {
        acc[r.name] = r.metricValue;
        return acc;
    }, {} as IDictionary);
    const haloPercentageData = getPercentChangeInMetric(haloLegends, haloPastLegends);

    const isDisplayTable =
        filterQueryParamsObj.comparison_start_date &&
        filterQueryParamsObj.comparison_end_date &&
        pastLegends &&
        legends;

    return (
        <>
            <Stack gap="20px" className="key-attribution-metrics">
                <Stack>
                    <Typography
                        variant="h2"
                        display="block"
                        gutterBottom
                        color="secondary.main"
                        sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                    >
                        KEY ATTRIBUTION METRICS
                    </Typography>
                    <HorizontalBarChart formattedData={keyAttributionFormattedData} percentageData={percentageData} />
                </Stack>
                {isDisplayTable && (
                    <PercentageTable
                        keys={keys}
                        pastLegendValues={pastLegends}
                        legendValues={legends}
                        metrics={[]}
                        id="attribution-chart"
                    />
                )}
            </Stack>
            <Stack gap="20px" className="halo-effect-metrics">
                <Stack>
                    <Typography
                        variant="h2"
                        display="block"
                        gutterBottom
                        color="secondary.main"
                        sx={{ fontWeight: "bold", letterSpacing: "1px" }}
                    >
                        HALO EFFECT BREAKDOWN
                    </Typography>
                    <HorizontalBarChart formattedData={haloFormattedData} percentageData={haloPercentageData} />
                </Stack>
                {isDisplayTable && (
                    <PercentageTable
                        keys={haloKeys}
                        pastLegendValues={haloPastLegends}
                        legendValues={haloLegends}
                        metrics={[]}
                        id="attribution-chart"
                    />
                )}
            </Stack>
        </>
    );
};
