import campaignsReducer from "../slices/campaignsSlice";
import userReducer from "../slices/userSlice";
import memberReducer from "../slices/membersSlice";
import connectorReducer from "../slices/connectorsSlice";
import organizationReducer from "../slices/organizationSlice";
import notificationsReducer from "../slices/notificationsSlice";
import performanceReducer from "../slices/performanceDetailsSlice";
import performancePageReducer from "../slices/performancePageSlice";
import settingsSlice from "../slices/settingsSlice";
import connectLaterCountSlice from "../slices/connectLaterCountSlice";
import demoModeSlice from "../slices/demoModeSlice";
import addBlockSkip from "../slices/adBlockSlice";
import filterViewSlice from "../slices/filterViewSlice";
import insightsModalSlice from "../slices/insightsModalSlice";
import featuresSlice from "../slices/featuresSlice";
import supportedDataSourcesSlice from "../slices/supportedDataSourcesSlice";
import modelEligibilitySlice from "../slices/modelEligibilitySlice";
import closedBrokenConnectorSlice from "../slices/brokenConnectorSlice";
import dateFilterSlice from "../slices/dateFilterSlice";
import announcementSlice from "../slices/announcementSlice";
import supportedDataSourcesByOrgSlice from "../slices/supportedDataSourcesByOrgSlice";
import emailInsightsModalSlice from "../slices/emailInsightsModalSlice";
import scenarioSlice from "../slices/scenarioSlice";
import continueAsSuperAdminSlice from "../slices/continueAsSuperAdminSlice";
import tacticsSlice from "../slices/tacticsSlice";

const rootReducer = {
    campaigns: campaignsReducer,
    currentUser: userReducer,
    members: memberReducer,
    connectors: connectorReducer,
    organizations: organizationReducer,
    notifications: notificationsReducer,
    performanceDetails: performanceReducer,
    performancePage: performancePageReducer,
    settings: settingsSlice,
    connectLaterCount: connectLaterCountSlice,
    isDemoMode: demoModeSlice,
    adBlockSkipCount: addBlockSkip,
    filterView: filterViewSlice,
    insightsModal: insightsModalSlice,
    emailInsightsModal: emailInsightsModalSlice,
    features: featuresSlice,
    supportedDataSources: supportedDataSourcesSlice,
    modelEligibility: modelEligibilitySlice,
    closedBrokenConnectors: closedBrokenConnectorSlice,
    dateFilter: dateFilterSlice,
    announcements: announcementSlice,
    supportedDataSourcesByOrg: supportedDataSourcesByOrgSlice,
    scenarios: scenarioSlice,
    continueAsSuperAdmin: continueAsSuperAdminSlice,
    tactics: tacticsSlice,
};

export default rootReducer;
