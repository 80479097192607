import React, { FC } from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { object, string } from "yup";
import { Autocomplete, Button, Chip, TextField } from "@mui/material";
import Spinner from "@mui/material/CircularProgress";

import { IFeature } from "../../../interfaces/entities/IFeature";

export interface IFeatureFormProps extends FormikConfig<IFeature> {
    isEditMode?: boolean;
    companyOptions: any[];
}

export const FeatureForm: FC<IFeatureFormProps> = (props) => {
    const { isEditMode, companyOptions, ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                displayName: string().required("Display name is required"),
                description: string().required("Descripion is required"),
            })}
            {...rest}
        >
            {({ errors, handleChange, touched, values, isSubmitting, setFieldValue }: FormikProps<IFeature>) => {
                const hasDisplayNameError = touched.displayName && (errors.displayName || errors.base);
                const hasDescriptionError = touched.description && errors.description;

                const disabledInput = isSubmitting || (Boolean(values.id) && !isEditMode);

                const notMatchSchema = Boolean(!values.displayName.trim().match(/^[a-zA-Z0-9_]{1}[a-zA-Z0-9_\s]*$/));

                return (
                    <Form>
                        <TextField
                            fullWidth={true}
                            id="displayName"
                            name="displayName"
                            disabled={disabledInput}
                            label="Display name"
                            value={values.displayName}
                            onChange={handleChange}
                            placeholder="Display name"
                            sx={{ marginTop: "15px" }}
                            error={!!hasDisplayNameError}
                            helperText={
                                hasDisplayNameError ? errors.displayName : ["Use only letters, numbers, underscores"]
                            }
                        />
                        <TextField
                            fullWidth={true}
                            id="description"
                            name="description"
                            label="Description"
                            disabled={disabledInput}
                            value={values.description}
                            onChange={handleChange}
                            placeholder="Description"
                            sx={{ marginTop: "15px" }}
                            error={!!hasDescriptionError}
                            helperText={hasDescriptionError ? errors.description : ""}
                        />
                        <Autocomplete
                            sx={{ marginTop: "15px", width: "100%" }}
                            multiple
                            id="tags-outlined"
                            options={companyOptions}
                            getOptionLabel={(option) => option.displayName}
                            defaultValue={values.companyInfo}
                            filterSelectedOptions
                            renderInput={(params) => (
                                <TextField {...params} label="Organization" placeholder="Organization" />
                            )}
                            onChange={(e, newValue) =>
                                setFieldValue(
                                    "companies",
                                    newValue.map((v) => v.id),
                                )
                            }
                            renderTags={(value, getTagProps) =>
                                value.map((option, index) => (
                                    <Chip variant="outlined" label={option.displayName} {...getTagProps({ index })} />
                                ))
                            }
                        />

                        <Button
                            sx={{ marginTop: "15px" }}
                            fullWidth={true}
                            variant="contained"
                            type="submit"
                            color="primary"
                            disabled={isSubmitting || notMatchSchema || !values.displayName || !values.description}
                        >
                            {isSubmitting ? <Spinner size={25} /> : isEditMode ? "Save changes" : "Create feature"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
