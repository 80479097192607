import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";

interface IMountainConnectorModalContent {
    classes: any;
    displayName: string;
}

export const MountainConnectorModalContent: FC<IMountainConnectorModalContent> = ({ classes, displayName }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Find API Key
                </Typography>
            </Stack>
            <Typography className={classes.info}>Log in to your {displayName} account.</Typography>
            <Typography className={classes.info}>
                You can retrieve your API key in the Account Settings page of the {displayName} user interface.
            </Typography>
            <Box sx={{ backgroundColor: "white" }} p="1rem">
                <Typography className={classes.info}>
                    NOTE: In case you are unable to find the API Key, please reach out to {displayName} through{" "}
                    <b>info@mountain.com</b> mail address.
                </Typography>
            </Box>

            {/* Step 2 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>Enter the API Key you found in Step 1.</Typography>
            <Typography className={classes.info}>Click Save & Test.</Typography>
        </Fragment>
    );
};
