import { FC } from "react";
import { Button, CircularProgress, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { LoadingButton } from "@mui/lab";

import { optimizationStyle } from "../../../containers/Optimization/optimizationStyle";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

interface IScenarioFooter {
    submitBtnTitle: string;
    cancelBtnTitle?: string;
    isDisplayBackBtn?: boolean;
    isBtnDisabled: boolean;
    isNotValidForm?: boolean;
    handleBackClick?: () => void;
    handleCancelClick: () => void;
    onFinalSubmit: () => void;
    handleResetClick?: () => void;
}

export const ScenarioFooter: FC<IScenarioFooter> = ({
    isDisplayBackBtn = false,
    handleBackClick,
    isBtnDisabled,
    handleCancelClick,
    submitBtnTitle,
    onFinalSubmit,
    cancelBtnTitle = "Cancel",
    isNotValidForm,
}: IScenarioFooter) => {
    const classes = optimizationStyle(useTheme());

    return (
        <Box className={classes.footerContainer}>
            <Stack direction="row" justifyContent="end" gap="20px" className={classes.footerContent}>
                {isDisplayBackBtn && (
                    <Button
                        sx={{
                            marginRight: "auto",
                            justifySelf: "start",
                        }}
                        className={classes.outlinedBtn}
                        variant="outlined"
                        onClick={handleBackClick}
                        startIcon={<SVGIconRenderer icon="arrowLeftIcon" />}
                        disabled={isBtnDisabled}
                    >
                        Back to Scenario Setup
                    </Button>
                )}
                <LoadingButton
                    className={classes.outlinedBtn}
                    variant="outlined"
                    onClick={handleCancelClick}
                    disabled={isBtnDisabled}
                >
                    {cancelBtnTitle}
                </LoadingButton>
                <LoadingButton
                    className={classes.containedBtn}
                    type="submit"
                    endIcon={!isNotValidForm && isBtnDisabled ? <CircularProgress color={"inherit"} size={16} /> : <></>}
                    loading={!isNotValidForm && isBtnDisabled}
                    disabled={isNotValidForm || isBtnDisabled}
                    loadingPosition="end"
                    variant="contained"
                    onClick={onFinalSubmit}
                >
                    {submitBtnTitle}
                </LoadingButton>
            </Stack>
        </Box>
    );
};
