import { useState } from "react";
import { useSelector } from "react-redux";
import { TableBody } from "@mui/material";

import { useStylesTable } from "src/consts/StylesForTables";
import { CustomTableStructure } from "../../core/CustomTableStructure/CustomTableStructure";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { Choice, ICells } from "src/consts/dataServicesPage/dataServicesPage";
import { DataServicesTableHeader } from "./DataServicesTableHeader";
import { getComparator, stableSort } from "src/utils/sort";
import { DataServicesTableRow } from "./DataServicesTableRow";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";

export const DataServicesTable = ({
    data,
    editClick,
    deleteClick,
}: {
    data: IDataService[];
    editClick: (dataService: IDataService) => void;
    deleteClick: (dataService: IDataService) => void;
}) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => setPage(0);

    const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const handleEdit = (e: React.MouseEvent<HTMLButtonElement>, dataService: IDataService) => {
        e.stopPropagation();
        editClick(dataService);
    };

    const handleDelete = (e: React.MouseEvent<HTMLButtonElement>, dataService: IDataService) => {
        e.stopPropagation();
        deleteClick(dataService);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: data.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <DataServicesTableHeader choiceBy={orderBy} choice={order} handleRequestSort={handleRequestSort} />
            <TableBody className={classes.tableBody}>
                {stableSort(data, getComparator(order, orderBy))
                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                    .map((dataService: any, index: number) => {
                        return (
                            <DataServicesTableRow
                                key={dataService.id}
                                dataService={dataService}
                                editClick={handleEdit}
                                deleteClick={handleDelete}
                            />
                        );
                    })}
            </TableBody>
        </CustomTableStructure>
    );
};
