import React, { FC } from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { object, ref, string } from "yup";
import { Typography } from "@mui/material";

import { IAcceptInvitationValues } from "../../interfaces/IAcceptInvitationValues";
import { Field } from "../core/Field/Field";
import { FooterForm } from "../core/Form/FooterForm";
import { Password } from "../core/Field/PasswordV1";
import { PRIMARY_DARK_COLOR } from "src/consts/colors";

export interface IAcceptInvitationFormProps extends FormikConfig<IAcceptInvitationValues> {
    email?: string;
    firstName?: string;
}

export const AcceptInvitationForm: FC<IAcceptInvitationFormProps> = (props) => {
    const { ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                password: string()
                    .required("Password is required")
                    .min(8, "8 total characters as minimum")
                    .matches(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/),
                passwordConfirmation: string()
                    .oneOf([ref("password"), null], "Passwords don’t match. Please check it.")
                    .required("Confirm Password is required"),
            })}
            {...rest}
        >
            {({
                errors,
                handleChange,
                touched,
                values,
                isSubmitting,
                setFieldTouched,
            }: FormikProps<IAcceptInvitationValues>) => {
                const hasPasswordConfirmationError = Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation,
                );
                const hasPasswordError = Boolean(touched.password && errors.password);
                const minLengthError = values.password.length < 8;
                const uppercaseError = Boolean(!values.password.match(/(?=.*?[A-Z])/));
                const lowercaseError = Boolean(!values.password.match(/(?=.*?[a-z])/));
                const numberError = Boolean(!values.password.match(/(?=.*?[0-9])/));

                const isPasswordError =
                    hasPasswordError || minLengthError || uppercaseError || lowercaseError || numberError;

                return (
                    <Form>
                        <Typography variant="h2">Create New Password</Typography>
                        <Field size="small" autoComplete="username" value={props.email} disabled label="Email" />
                        <Password
                            id="password"
                            name="password"
                            label="New password"
                            error={hasPasswordError}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("password")}
                            autoComplete="new-password"
                            size="small"
                        />
                        <Password
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            label="Confirm new password"
                            error={hasPasswordConfirmationError}
                            helperText={hasPasswordConfirmationError ? errors.passwordConfirmation : ""}
                            value={values.passwordConfirmation}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("passwordConfirmation")}
                            autoComplete="new-password"
                            size="small"
                        />
                        <Typography
                            color={`${
                                !Object.keys(touched).length
                                    ? "text.secondary"
                                    : isPasswordError
                                    ? "error.main"
                                    : PRIMARY_DARK_COLOR
                            }`}
                            variant="caption"
                        >
                            Make sure it’s at least 8 characters, 1 uppercase, 1 lowercase, and 1 number.
                        </Typography>
                        <FooterForm
                            submitBtnProps={{
                                disabled:
                                    Boolean(hasPasswordConfirmationError || hasPasswordError) ||
                                    Boolean(!touched.password || !touched.passwordConfirmation),
                                isLoading: isSubmitting,
                                text: "Confirm",
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};
