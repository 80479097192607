import { FC } from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import Spinner from "@mui/material/CircularProgress";
import { css } from "@emotion/css";

import { FORECASTED_CAMPAIGNS_TRACKING_HEADERS, IMetricData } from "../../../consts/optimizationPage/optimizationPage";
import { FORMATS } from "../../../enums/Formats";
import { formatValue, getPreparedValue } from "../../../services/utils";
import { IScenario } from "../../../interfaces/entities/IScenario";
import { getPacingValue } from "../../../services/optimizationPage/optimization";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { DataSourceAvatar } from "../../../components/DataSourceAvatar/DataSourceAvatar";

interface ITrackingTable {
    data: IMetricData;
    unit: FORMATS;
    isPerformanceLoading: boolean;
    tab: string;
    scenarioForTracking: IScenario;
}

const useStyles = () => ({
    tableContainer: css({
        boxShadow: "none",
        border: "1px solid rgba(224, 224, 224, 1)",
    }),
    tableCell: css({
        fontWeight: "700",
        border: "solid rgba(224, 224, 224, 1)",
        borderWidth: "0 0 1px 0",
        fontSize: "15px",
        color: "black",
    }),
    campaignName: css({
        whiteSpace: "nowrap",
        width: "300px",
        overflow: "hidden",
        textOverflow: "ellipsis",
    }),
    avatarImage: css({ width: "32px!important", height: "32px!important" }),
    paperStyle: css({
        width: "100%",
        overflow: "auto",
        boxShadow: "none",
    }),
});

const getTotalMetric = (value: string | number | JSX.Element, key: JSX.Element): JSX.Element => {
    return (
        <Stack direction="row" gap="15px" alignItems="center">
            <Typography variant="h2" component="h2" fontSize="30px" fontWeight="bold" color="black">
                {value}
            </Typography>
            <Typography color="black" variant="subtitle1">
                {key}
            </Typography>
        </Stack>
    );
};

export const TrackingTable: FC<ITrackingTable> = ({ data, isPerformanceLoading, unit, tab, scenarioForTracking }) => {
    const classes = useStyles();

    return isPerformanceLoading ? (
        <Stack height="440px" width="790px" alignItems="center" justifyContent="center">
            <Spinner />
        </Stack>
    ) : (
        <Stack gap="20px">
            <Stack direction="row" gap="40px" flexWrap="wrap" padding="0 10px">
                {getTotalMetric(
                    getPreparedValue(unit, data.total.forecasted, unit === "$" ? 1 : 2),
                    <>
                        Optimized Scenario
                        <br />
                        {tab}
                    </>,
                )}
                {getTotalMetric(
                    getPreparedValue(unit, data.total.actual, unit === "$" ? 1 : 2),
                    <>
                        Actual Modeled
                        <br />
                        {tab}
                    </>,
                )}
                {getTotalMetric(
                    getPreparedValue(FORMATS.PERCENT, data.total.potentialFullfilled, 0),
                    <>
                        Potential <br />
                        Fulfilled
                    </>,
                )}
                {getTotalMetric(
                    data.total.pacing ? (
                        <SVGIconRenderer
                            title="Scenario is on pace with forecast."
                            icon="checkIcon"
                            strokeColor="#2e7d32"
                        />
                    ) : (
                        <SVGIconRenderer
                            title="Scenario is behind pace with forecast."
                            icon="alertTriangleIcon"
                            strokeColor="#ed6c02"
                        />
                    ),
                    <>
                        Total <br />
                        Pacing
                    </>,
                )}
            </Stack>
            <Paper className={classes.paperStyle}>
                <TableContainer sx={{ maxHeight: "440px" }} className={classes.tableContainer}>
                    <Table stickyHeader>
                        <TableHead>
                            <TableRow>
                                {FORECASTED_CAMPAIGNS_TRACKING_HEADERS.map((campaignHeader) => {
                                    return (
                                        <TableCell
                                            key={`main-${campaignHeader.id}`}
                                            align={campaignHeader.id === "campaignName" ? "left" : "right"}
                                            sx={{
                                                whiteSpace:
                                                    campaignHeader.id === "potentialFullfilled" ? "unset" : "nowrap",
                                                width: campaignHeader.id === "potentialFullfilled" ? "50px" : "unset",
                                                backgroundColor: "white",
                                            }}
                                            className={classes.tableCell}
                                        >
                                            {campaignHeader.tabLabel?.[tab] || campaignHeader.label}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {data.individualData?.map((d) => {
                                const p = unit === FORMATS.NUMERIC ? 2 : 0;
                                return (
                                    <TableRow key={d.campaignId}>
                                        <TableCell>
                                            <Stack direction="row" alignItems="center" gap="20px">
                                                <DataSourceAvatar
                                                    programmaticName={d.connectorName}
                                                    className={classes.avatarImage}
                                                />
                                                <Typography
                                                    variant="body2"
                                                    title={d.campaignName}
                                                    className={classes.campaignName}
                                                >
                                                    {d.campaignName}
                                                </Typography>
                                            </Stack>
                                        </TableCell>
                                        <TableCell align="right">{formatValue(d.forecasted, unit, p)}</TableCell>
                                        <TableCell align="right">{formatValue(d.actual, unit, p)}</TableCell>
                                        <TableCell align="right">
                                            {getPacingValue(
                                                d.forecasted,
                                                scenarioForTracking.forecastTimeframe,
                                                d.actual,
                                                scenarioForTracking.createdAt,
                                                tab,
                                            ) ? (
                                                <SVGIconRenderer
                                                    title="Campaign is on pace with forecast."
                                                    icon="checkIcon"
                                                    strokeColor="#2e7d32"
                                                />
                                            ) : (
                                                <SVGIconRenderer
                                                    title="Campaign is behind pace with forecast."
                                                    icon="alertTriangleIcon"
                                                    strokeColor="#ed6c02"
                                                />
                                            )}
                                        </TableCell>
                                        <TableCell align="right">
                                            {formatValue(d.potentialFulfilled, FORMATS.PERCENT, 0)}
                                        </TableCell>
                                    </TableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Stack>
    );
};
