import { ICommonJSONApiResponse } from "./ICommonJSONApiResponse";

export enum ForecastTimeframe {
    THIRTY = "30",
    SIXTY = "60",
    NINETY = "90",
}

export interface IIndividualHolidays {
    [holidayName: string]: number;
}

interface IForecastData {
    individual_holidays: IIndividualHolidays;
    total_holiday: number;
    trend: number;
    trend_holiday_marketing: number;
    marketing: number;
    total_forecasting_spend: number;
    min_and_max_date: string[];
}

export type Forecast = {
    [key in ForecastTimeframe]: IForecastData;
};

export interface ICompanyForecastResponseV1 extends ICommonJSONApiResponse {
    data: Forecast;
}
