import {
    differenceInDays,
    format,
    isSameDay,
    startOfMonth,
    startOfYear,
    subDays,
    subMonths,
    subWeeks,
    subYears,
} from "date-fns";
import { Range, RangeKeyDict, StaticRange } from "react-date-range";
import queryString from "query-string";
import { toDate } from "date-fns-tz";

export const DEFAULT_DATE_RANGE: Range[] = [
    {
        startDate: subDays(new Date(), 30),
        endDate: subDays(new Date(), 1),
        key: "selection",
    },
];

export enum DefaultDateRangeInText {
    TODAY = "Today",
    LAST_7_DAYS = "Last 7 days",
    LAST_14_DAYS = "Last 14 days",
    LAST_30_DAYS = "Last 30 days",
    LAST_90_DAYS = "Last 90 days",
    LAST_12_MONTHS = "Last 12 months",
    THIS_MONTH = "This month",
    THIS_YEAR = "This year",
    CUSTOM = "Custom",
    PREVIOUS_CUSTOM = "Custom",
}

export const definedDates = {
    todayDate: new Date(),
    yesterDay: subDays(new Date(), 1),
    last7Days: subDays(new Date(), 7),
    last14Days: subDays(new Date(), 14),
    last30Days: subDays(new Date(), 30),
    last90Days: subDays(new Date(), 90),
    last12Months: subMonths(new Date(), 12),
    thisMonth: isSameDay(startOfMonth(new Date()), new Date()) ? new Date() : startOfMonth(new Date()),
    thisYear: isSameDay(startOfYear(new Date()), new Date()) ? new Date() : startOfYear(new Date()),
};

export enum PastDateRangeInText {
    PREVIOUS_CUSTOM = "Custom",
    PREVIOUS_PERIOD = "Previous period",
    PREVIOUS_DAY = "Previous day",
    PREVIOUS_WEEK = "Previous week",
    PREVIOUS_MONTH = "Previous month",
    PREVIOUS_QUARTER = "Previous quarter",
    PREVIOUS_YEAR = "Previous year",
    CUSTOM = "Custom",
}

export const getDefinedPastDates = (currentDateFilter: Range[]) => {
    const initialStartDate = currentDateFilter[0].startDate || definedDates.yesterDay;
    const endDateForPrev = initialStartDate ? subDays(initialStartDate, 1) : definedDates.yesterDay;

    const prevPeriod = getPastPeriod(currentDateFilter)[0];

    const today = new Date();
    const quarter = Math.floor(today.getMonth() / 3);

    const startFullQuarter = new Date(today.getFullYear(), quarter * 3 - 3, 1);
    const endFullQuarter = new Date(startFullQuarter.getFullYear(), startFullQuarter.getMonth() + 3, 0);

    return {
        prevPeriod: prevPeriod.startDate,
        prevDate: endDateForPrev,
        startWeekdDate: subWeeks(initialStartDate, 1),
        startMonthDate: subMonths(initialStartDate, 1),
        startQuerterDate: startFullQuarter,
        endQuarterDate: endFullQuarter,
        startYearDate: subYears(initialStartDate, 1),
    };
};

export const getSelectedPreset = (
    dateRange: Range,
    sideBar: any[],
    key: DefaultDateRangeInText = DefaultDateRangeInText.CUSTOM,
) => {
    const { startDate, endDate } = dateRange;
    let selectedPreset = key;
    if (startDate && endDate) {
        sideBar
            .filter((d) => d.label !== key)
            .forEach((s) => {
                const { startDate: staticStartDate, endDate: staticEndDate } = s.range();
                if (
                    staticStartDate &&
                    staticEndDate &&
                    isSameDay(staticStartDate, startDate) &&
                    isSameDay(staticEndDate, endDate) &&
                    s.label
                ) {
                    selectedPreset = s.label;
                }
            });
    }
    return selectedPreset;
};

export const getPastPeriod = (currentDateFilter: Range[], compareToPast?: boolean) => {
    let defaultPastDate = DEFAULT_DATE_RANGE;
    const filterQueryParamsObj = queryString.parse(window.location.search);

    if (filterQueryParamsObj && currentDateFilter[0].startDate && currentDateFilter[0].endDate) {
        if (
            compareToPast &&
            typeof filterQueryParamsObj.comparison_start_date === "string" &&
            typeof filterQueryParamsObj.comparison_end_date === "string"
        ) {
            defaultPastDate = [
                {
                    startDate: toDate(filterQueryParamsObj.comparison_start_date),
                    endDate: toDate(filterQueryParamsObj.comparison_end_date),
                    key: "compare",
                },
            ];
        } else {
            const diff = differenceInDays(currentDateFilter[0].endDate, currentDateFilter[0].startDate);
            const pastEndDate = subDays(currentDateFilter[0].startDate, 1);
            const pastStartDate = subDays(pastEndDate, diff);

            defaultPastDate = [
                {
                    startDate: pastStartDate,
                    endDate: pastEndDate,
                    key: "compare",
                },
            ];
        }
    }

    return defaultPastDate;
};

export const getRangeFormattedTitle = (dateRange: Range[]) => {
    const { startDate, endDate } = dateRange[0];
    if (startDate && endDate) {
        return `${format(startDate, "MMM d, yyyy")} - ${format(endDate, "MMM d, yyyy")}`;
    }
};

export const pastRangeBasedOnCurrentRange = (currentDateInFormat: Range[]) => {
    let defaultPastDate = getPastPeriod(currentDateInFormat, false);
    const filterQueryParamsObj = queryString.parse(window.location.search);
    if (
        filterQueryParamsObj.comparison_start_date &&
        typeof filterQueryParamsObj.comparison_end_date &&
        typeof filterQueryParamsObj.comparison_start_date === "string" &&
        typeof filterQueryParamsObj.comparison_end_date === "string"
    ) {
        defaultPastDate = [
            {
                startDate: toDate(filterQueryParamsObj.comparison_start_date),
                endDate: toDate(filterQueryParamsObj.comparison_end_date),
                key: "selection",
            },
        ];
    }

    return `vs. ${getRangeFormattedTitle(defaultPastDate)}`;
};

const getSelection = (range: Range, definedRange: any) => {
    return range.startDate &&
        range.endDate &&
        definedRange.startDate &&
        definedRange.endDate &&
        isSameDay(range.startDate, definedRange.startDate) &&
        isSameDay(range.endDate, definedRange.endDate)
        ? true
        : false;
};

export const sideBarDateOptions = (
    state: { selection: Range; compare?: Range },
    compareToPast: boolean,
    compareView: boolean,
) => {
    const dateRange = [state.selection];
    const compareDateRange = state.compare ? [state.compare] : [];

    const definedDate = getDefinedPastDates(dateRange);
    let customDateObjects: StaticRange[] = [
        {
            label: DefaultDateRangeInText.LAST_7_DAYS,
            range: () => ({
                startDate: definedDates.last7Days,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.LAST_14_DAYS,
            range: () => ({
                startDate: definedDates.last14Days,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.LAST_30_DAYS,
            range: () => ({
                startDate: definedDates.last30Days,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.LAST_90_DAYS,
            range: () => ({
                startDate: definedDates.last90Days,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.LAST_12_MONTHS,
            range: () => ({
                startDate: definedDates.last12Months,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.THIS_MONTH,
            range: () => ({
                startDate: definedDates.thisMonth,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.THIS_YEAR,
            range: () => ({
                startDate: definedDates.thisYear,
                endDate: definedDates.yesterDay,
                key: "selection",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: DefaultDateRangeInText.CUSTOM,
            range: () => ({
                startDate: new Date(""), // invalid date
                endDate: new Date(""), // invalid date
                key: "custom",
            }),
            isSelected: () => {
                return (
                    getSelectedPreset(dateRange[0], customDateObjects, DefaultDateRangeInText.CUSTOM) ===
                    DefaultDateRangeInText.CUSTOM
                );
            },
            hasCustomRendering: true,
        },
    ];

    const compareOptions: StaticRange[] = [
        {
            label: PastDateRangeInText.PREVIOUS_PERIOD,
            range: () => ({
                startDate: definedDate.prevPeriod,
                endDate: definedDate.prevDate,
                key: "compare",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: PastDateRangeInText.PREVIOUS_YEAR,
            range: () => ({
                startDate: dateRange[0].startDate ? subYears(dateRange[0].startDate, 1) : dateRange[0].startDate,
                endDate: dateRange[0].endDate ? subYears(dateRange[0].endDate, 1) : dateRange[0].endDate,
                key: "compare",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return getSelection(range, definedRange);
            },
        },
        {
            label: PastDateRangeInText.PREVIOUS_CUSTOM,
            range: () => ({
                startDate: new Date(""), // invalid date
                endDate: new Date(""), // invalid date
                key: "compareCustom",
            }),
            hasCustomRendering: true,
            isSelected(range) {
                const definedRange = this.range();
                return (
                    getSelectedPreset(compareDateRange[0], compareOptions, DefaultDateRangeInText.PREVIOUS_CUSTOM) ===
                        DefaultDateRangeInText.PREVIOUS_CUSTOM && !getSelection(range, definedRange)
                );
            },
        },
    ];

    if (compareView) {
        customDateObjects = [
            ...customDateObjects,
            {
                label: "Compare",
                range: () => ({
                    startDate: subYears(new Date(), 2),
                    endDate: subYears(new Date(), 2),
                    key: "compareSwitch",
                    autoFocus: false,
                    showDateDisplay: false,
                }),
                isSelected: () => false,
                hasCustomRendering: true,
            },
        ];
    }

    if (compareToPast) {
        customDateObjects = [...customDateObjects, ...compareOptions];
    }

    return customDateObjects;
};

export const getSelectedDateRange = (key: string, innerKey: string, state: any, item: RangeKeyDict) => {
    let updatedStateObj;
    const nullDate = { startDate: null, endDate: new Date("") };

    if (innerKey && ["custom", "compareCustom"].includes(innerKey)) {
        if (key === "selection") {
            if (innerKey === "custom") {
                updatedStateObj = {
                    ...state,
                    selection: { ...nullDate, key: "selection" },
                };
            } else {
                updatedStateObj = {
                    ...state,
                    compare: { ...nullDate, key: "compare" },
                };
            }
        } else {
            if (innerKey === "custom") {
                updatedStateObj = {
                    ...state,
                    selection: { ...nullDate, key: "selection" },
                };
            } else {
                updatedStateObj = {
                    ...state,
                    compare: { ...nullDate, key: "compare" },
                };
            }
        }
    } else {
        if (key === "selection") {
            if (innerKey === "selection") {
                const defaultPastDate = getPastPeriod([item.selection], false);
                updatedStateObj = {
                    compare: { ...defaultPastDate[0] },
                    selection: { ...item.selection },
                };
            } else {
                updatedStateObj = {
                    ...state,
                    compare: { ...item.selection, key: "compare" },
                };
            }
        }

        if (key === "compare") {
            if (innerKey === "compare") {
                updatedStateObj = {
                    ...state,
                    compare: { ...item.compare },
                };
            } else {
                const defaultPastDate = getPastPeriod([item.compare], false);
                updatedStateObj = {
                    compare: { ...defaultPastDate[0], key: "compare" },
                    selection: { ...item.compare, key: "selection" },
                };
            }
        }
    }
    return updatedStateObj;
};
