import React, { FC, useState } from "react";
import { Typography } from "@mui/material";
import { Choice, ICells } from "../../../consts/eventLogsPage/eventLogsPage";
import { getComparator, stableSort } from "../../../utils/sort";
import { EventLogItem } from "../EventLogItem/EventLogItem";
import { IEventLog } from "../../../interfaces/entities/IEventLog";
import { EventListPagination } from "../EventListPagination/EventListPagination";
import { Modal } from "../../../components/core/Modal/Modal";
import { EventModal } from "../EventModal/EventModal";
import { Container } from "@mui/system";

interface IEventLogTable {
    tableData: IEventLog[];
    order: Choice;
    orderBy: string;
    page: number;
    count: number;
    handleChangePage: (event: unknown, newPage: number) => void;
    handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
}

export const EventLogTable: FC<IEventLogTable> = ({
    tableData,
    order,
    orderBy,
    page,
    count,
    handleChangePage,
    handleChangeRowsPerPage,
}) => {
    const [open, setOpen] = useState("");
    const handleClose = () => setOpen("");

    const data = stableSort(tableData, getComparator(order, orderBy));

    const onClick = (id: string) => {
        setOpen(id);
    };

    const renderedLog = () => {
        const record = tableData.find((row) => row.id === open);
        if (record) {
            return <EventModal detailView={record}></EventModal>;
        } else {
            return <Typography>{open}</Typography>;
        }
    };

    return (
        <>
            <EventListPagination
                paginationProps={{
                    count,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                }}
            >
                <Container sx={{ margin: "1rem auto" }}>
                    {data.map((row: any, index: number, array: any[]) => {
                        return (
                            <EventLogItem
                                key={row.id}
                                prevData={array[index - 1]}
                                eventLog={row}
                                onClick={() => onClick(row.id)}
                            />
                        );
                    })}
                </Container>
            </EventListPagination>

            {open && (
                <Modal
                    isOpen={open !== ""}
                    onClose={handleClose}
                    title={"Event Details"}
                    canOutsideClickClose={true}
                    content={renderedLog()}
                    width="max-content"
                    disableMarginTop={true}
                />
            )}
        </>
    );
};
