import { css } from "@emotion/css";

export const body1 = css`
    letter-spacing: 0.15px !important;
`;

export const titleH2 = (color: string) => css`
    font-style: normal !important;
    font-weight: 300 !important;
    font-size: 48px !important;
    line-height: 120% !important;
    letter-spacing: -0.5px !important;
    color: ${color} !important;
`;

export const subTitleH4 = css`
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 24px !important;
    line-height: 123.5% !important;
    letter-spacing: 0.25px !important;
    color: rgba(0, 0, 0, 0.54) !important;
`;

export const modalTitle = (normal?: boolean) => css`
    font-style: normal !important;
    font-weight: normal !important;
    font-size: ${normal ? "24px" : "18px"}!important;
    line-height: ${normal ? "123.5%" : "160%"}!important;
    letter-spacing: ${normal ? "0.25px" : "0.15px"}!important;
    color: rgba(0, 0, 0, 0.87) !important;
`;

export const body2Class = (color: string) => css`
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 143% !important;
    letter-spacing: 0.15px !important;
    color: ${color}!important;
`;
