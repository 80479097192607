import Spinner from "@mui/material/CircularProgress";
import { css } from "@emotion/css";
import { useNavigate, useLocation } from "react-router-dom";
import queryString from "query-string";
import React, { useEffect } from "react";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { useConfirmEmailMutation } from "../../reduxState/apis/authApi";
import { LOGIN_PATH } from "../../consts/path/path";

const spinnerClass = css`
    text-align: center;
    height: 100vh;

    .bp3-spinner {
        height: 100%;
    }
`;

export const ConfirmEmail: React.FC = () => {
    const [confirmEmail] = useConfirmEmailMutation();
    const navigate = useNavigate();
    const location = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    useEffect(() => {
        confirmEmailAction();
    }, []);

    const confirmEmailAction = async () => {
        const token = queryString.parse(location.search).token as string;
        await confirmEmail(token)
            .unwrap()
            .then(() => {
                enqueueSnackbar("Email was confirmed, please signing in", {
                    id: uniqueId(),
                    variant: "success",
                });
                navigate(LOGIN_PATH, { replace: true });
            })
            .catch((response: any) => {
                const error = response.error.reduce((acc: any, curent: any) => {
                    acc.base = curent.detail;

                    return acc;
                }, {});

                if (error.base) {
                    enqueueSnackbar(error.base, {
                        id: uniqueId(),
                        variant: "error",
                    });
                }
                navigate(LOGIN_PATH, { replace: true });
            });
    };

    return (
        <div className={spinnerClass}>
            <Spinner size={100} />
        </div>
    );
};
