import { css } from "@emotion/css";

export const DATE_RANGE_COLORS = {
    selection: "#0B9786",
    compare: "#FFD639",
};

export const useStylesForDateRange = (isTransparent: boolean = false, isUpgraded: boolean = false) => {
    const bgColor = isTransparent ? "white" : "#275469";
    const fontColor = isTransparent ? "#000000" : "white";
    const border = `1px solid ${isTransparent ? "rgba(0, 0, 0, 0.23)" : "#275469"}`;

    const hoverBgColor = isTransparent ? "transparent !important" : "#316B87 !important";
    const hoverBorder = `1px solid ${isTransparent ? "black" : "transparent"}`;

    const btnStyle = {
        fontSize: "16px",
        minHeight: isUpgraded ? "56px" : "46px",
        padding: "14px 12px !important",

        backgroundColor: bgColor,
        color: fontColor,
        border,
        "& svg": {
            color: fontColor,
        },
        "&:hover": {
            background: hoverBgColor,
            border: hoverBorder,
        },
    };

    const openMenuBtnStyle = isTransparent
        ? {
              border: "1px solid #429685 !important",
          }
        : {
              backgroundColor: DATE_RANGE_COLORS.selection,
              "&:hover": {
                  background: `${DATE_RANGE_COLORS.selection}!important`,
                  opacity: 0.7,
              },
          };

    return {
        dateIconStyle: css({
            marginRight: "8px",
        }),
        buttonClass: css({
            "& #current-date-filter-btn": {
                ...btnStyle,
                "& .MuiButton-endIcon": {
                    marginLeft: "12px",
                },
            },
        }),
        openMenuBtnStyle: css({
            "& #current-date-filter-btn": {
                ...btnStyle,
                ...openMenuBtnStyle,
                "& .MuiButton-endIcon": {
                    marginLeft: "12px",
                },
            },
        }),
    };
};
