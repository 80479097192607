export type Choice = "asc" | "desc";

export interface ICells {
    avatarMediumUrl: string;
    firstName: string;
    lastName: string;
    email: string;
    role: string;
    createdAt: Date;
    isSuperadmin: boolean;
    companyCount: number;
    invitationState: string;
}

interface IHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

export const MEMBERS_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "firstName",
        numeric: false,
        disablePadding: false,
        label: "Name",
    },
    {
        id: "email",
        numeric: false,
        disablePadding: false,
        label: "Email",
    },
    {
        id: "role",
        numeric: false,
        disablePadding: false,
        label: "Role",
    },
    {
        id: "createdAt",
        numeric: false,
        disablePadding: false,
        label: "Created Date",
    },
];

export const defaultMemberSortBy: string[] = ["expired", "pending", "accepted", "none"];
