import React, { useState } from "react";
import { TableBody } from "@mui/material";
import { useSelector } from "react-redux";
import { Choice, defaultMemberSortBy, ICells } from "../../../consts/membersPage/membersPage";
import { stableSort, getComparator } from "../../../utils/sort";
import { useStylesTable } from "../../../consts/StylesForTables";
import { IMember } from "../../../interfaces/entities/IMember";
import { SuperAdminMembersTableHeader } from "../SuperAdminMemberHeader/SuperAdminMemberHeader";
import { SuperAdminMembersTableRow } from "../SuperAdminMemberTableRow/SuperAdminMemberRow";
import { customSortData } from "../../../services/utils";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";

interface IMembersTableView {
    memberList: IMember[];
    selectedMembers: number;
    companies: any[];
    handleSelectAll(): void;
    handleTableRowClick(item: number | string, isAction?: string): void;
    onStatusClick(member: IMember): void;
}

export const SuperAdminMembersTable: React.FC<IMembersTableView> = ({
    handleTableRowClick,
    selectedMembers,
    handleSelectAll,
    companies,
    memberList,
    onStatusClick,
}) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const sortMemberByCustomStatus = customSortData(memberList, "invitationState", defaultMemberSortBy);

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const statusClick = (e: React.MouseEvent<HTMLDivElement>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            onStatusClick(member);
        }
    };
    const handleEdit = (e: React.MouseEvent<any>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            handleTableRowClick(member.id, "edit");
        }
    };

    const handleDelete = (e: React.MouseEvent<any>, member: IMember) => {
        e.stopPropagation();
        if (member) {
            handleTableRowClick(member.id, "delete");
        }
    };

    return (
        <>
            <CustomTableStructure
                paginationProps={{
                    count: memberList.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                }}
            >
                <SuperAdminMembersTableHeader
                    selectedMembers={selectedMembers}
                    handleSelectAll={handleSelectAll}
                    choiceBy={orderBy}
                    choice={order}
                    handleRequestSort={handleRequestSort}
                />
                <TableBody className={classes.tableBody}>
                    {stableSort(sortMemberByCustomStatus, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((member: any, index: number) => {
                            const labelId = `enhanced-table-checkbox-${index}`;
                            return (
                                <SuperAdminMembersTableRow
                                    key={member.id}
                                    onClickAction={handleTableRowClick}
                                    member={member}
                                    labelId={labelId}
                                    companies={companies}
                                    statusClick={statusClick}
                                    editClick={handleEdit}
                                    deleteClick={handleDelete}
                                />
                            );
                        })}
                </TableBody>
            </CustomTableStructure>
        </>
    );
};
