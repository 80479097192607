import React from "react";
import { FormikHelpers } from "formik";
import { FeatureForm } from "../FeaturesFrom/FeaturesFrom";
import { IFeature } from "../../../interfaces/entities/IFeature";
import { HeaderSize, Modal } from "../../core/Modal/Modal";
import { IOrganization } from "../../../interfaces/entities/IOrganization";

interface IFeatureModalForm {
    isOpen: boolean;
    feature: IFeature | null;
    isFormEditMode: boolean;
    companyOptions: IOrganization[];
    onCloseModal(): void;
    onSubmit(features: IFeature, formikHelpers: FormikHelpers<IFeature>): void;
}

export const FeatureModalForm: React.FC<IFeatureModalForm> = ({
    isOpen,
    feature,
    onCloseModal,
    isFormEditMode,
    onSubmit,
    companyOptions,
}) => {
    return (
        <Modal
            isOpen={isOpen}
            headerSize={HeaderSize.SMALL}
            title={isFormEditMode ? "Edit feature" : "New feature"}
            canOutsideClickClose={false}
            onClose={onCloseModal}
            disableMarginTop={true}
            content={
                <FeatureForm
                    initialValues={feature as IFeature}
                    companyOptions={companyOptions}
                    isEditMode={isFormEditMode}
                    onSubmit={onSubmit}
                />
            }
        />
    );
};
