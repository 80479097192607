import { Typography, Stack } from "@mui/material";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";

import CommonStepper from "src/components/CustomStepper/CustomStepper";
import { CONNECTOR_COMPLETION_STEPS } from "src/consts/connectorsPage/connectorsPage";
import { DialogElements } from "src/components/core/CustomDialog/DialogElement";
import { getCompletedSteps } from "src/services/onboardingPage/onboardingPage";
import { IConnector } from "src/interfaces/IConnector";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";

export const ConnectorSuccess = ({ handleNext }: { handleNext: (isFinish?: boolean) => void }) => {
    const navigate = useNavigate();
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const connectors: IConnector[] = useSelector(connectorsSelector);
    const completedSteps: number[] = getCompletedSteps(connectors);

    return (
        <Stack alignSelf="center" sx={{ width: "400px" }}>
            <DialogElements
                title="Got it! We’re compiling and evaluating your data channels."
                instanceSlot={
                    <Stack spacing={2}>
                        <Typography>
                            Next, you will confirm the accuracy from your data channels. Then we will schedule a call to
                            review the data with your team once it is finished importing.
                        </Typography>
                        <CommonStepper
                            steps={CONNECTOR_COMPLETION_STEPS}
                            activeStep={3}
                            orientation="vertical"
                            showConnectorLine={false}
                            style={{ gap: "8px", " .MuiStepLabel-root": { padding: 0 } }}
                            completedSteps={completedSteps}
                        />
                    </Stack>
                }
                actions={{
                    1: {
                        label: "Okay",
                        onClick: handleNext,
                        style: {
                            width: "100%",
                            "& .MuiButtonBase-root": {
                                width: "100%",
                            },
                        },
                    },
                }}
                closeIcon={false}
            />
        </Stack>
    );
};
