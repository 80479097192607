import { array, number, object, string } from "yup";
import addScenarioFormModel from "./addScenarioFormModel";
import { ICampaignData } from "src/interfaces/entities/IScenario";
const {
    formField: { name, forecastTimeframe, campaignData, budget },
} = addScenarioFormModel;

export default [
    object().shape({
        [name.name]: string().required(`${name.requiredErrorMsg}`),
        [forecastTimeframe.name]: string().required(`${forecastTimeframe.requiredErrorMsg}`),
        [campaignData.name]: array().min(1, `${campaignData.requiredErrorMsg}`),
        [budget.name]: number().when("campaignData", {
            is: (campaignData: ICampaignData) => campaignData.length > 0,
            then: number().test("nonZero", "Estimated budget must be greater than 0", (value) => value !== 0),
        }),
    }),
];
