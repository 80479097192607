import React, { useState } from "react";
import { FormikHelpers } from "formik";
import Snackbar from "@mui/material/Snackbar";

import { AlignItems, Flex, FlexDirection, JustifyContent } from "../../components/core/Flex/Flex";
import HelpForm from "../../components/HelpForm/HelpForm";
import { FlexItem } from "../../components/core/FlexItem/FlexItem";

import { useSendHelpMessageMutation } from "../../reduxState/apis/authApi";
import { SVGIconRenderer } from "../../components/SVGIconRenderer/SVGIconRenderer";
export interface IHelpPageValues {
    email: string;
    message: string;
    problem: HELP_PROBLEMS_TYPE;
}

export enum HELP_PROBLEMS_TYPE {
    login = "Log In problems",
    connectors = "The connectors don’t work",
    other = "other",
}

export const HelpPage: React.FC = () => {
    const initialState: IHelpPageValues = {
        email: "",
        message: "",
        problem: HELP_PROBLEMS_TYPE.other,
    };
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const [sendHelpMessage] = useSendHelpMessageMutation();

    const closeSuccessMessage = () => {
        setIsModalOpen(false);
    };

    const showSuccessMessage = () => {
        setIsModalOpen(true);
    };

    const handleSubmit = async (values: IHelpPageValues, formikHelpers: FormikHelpers<IHelpPageValues>) => {
        await sendHelpMessage(values)
            .unwrap()
            .then(() => {
                formikHelpers.setSubmitting(false);
                showSuccessMessage();
            })
            .catch(() => console.log("fail"));
    };

    return (
        <>
            <HelpForm initialValues={initialState} onSubmit={handleSubmit} />
            <Snackbar
                anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                }}
                autoHideDuration={6000}
                message={
                    <Flex>
                        <SVGIconRenderer icon="mailIcon" style={{ fill: "white", marginRight: "12px" }} />
                        <span>Message Sent - Thank you for the feedback!. We will address your problem ASAP.</span>
                    </Flex>
                }
                open={isModalOpen}
                onClose={closeSuccessMessage}
            />
        </>
    );
};
