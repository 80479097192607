import React, { FC, useEffect, useState } from "react";
import "react-date-range/dist/styles.css";
import "react-date-range/dist/theme/default.css";
import { createStaticRanges, DateRangePicker, Range, RangeKeyDict, StaticRange } from "react-date-range";
import { endOfMonth, format, isValid } from "date-fns";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { Button, Switch, Typography, Tooltip } from "@mui/material";
import { Stack } from "@mui/system";

import "./reactDateRangeStyles.css";
import { getDefaultRange, handleDeleteParamKey } from "../../services/performancePage/performancePage";
import { LightMenu } from "../LightMenu/LightMenu";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { convertObjectToQueryParam, getPathForAnalytics } from "../../services/utils";
import {
    getSelectedDateRange,
    getPastPeriod,
    getRangeFormattedTitle,
    getSelectedPreset,
    sideBarDateOptions,
} from "./reactDateRangeUtils";
import {
    formattedCompareDateSelector,
    formattedDateSelector,
    isCompareSelector,
    setCompare,
    setCompareDateFilter,
    setCurrentDateFilter,
} from "../../reduxState/slices/dateFilterSlice";
import { isCampaignAvailableSelector, isModelingAvailableSelector } from "../../reduxState/slices/settingsSlice";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { DATE_RANGE_COLORS, useStylesForDateRange } from "./dateRangeStyle";

interface IProps {
    appendRangeInLocation?: boolean;
    minDate?: Date | undefined;
    compareView: boolean;
}

interface IDateRanges {
    selection: Range;
    compare: Range;
}

const ReactDateRangeSelect: FC<IProps> = ({ minDate, appendRangeInLocation = true, compareView }) => {
    const classes = useStylesForDateRange();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const childRef = React.useRef<React.ElementRef<typeof LightMenu>>(null);

    const filterQueryParamsObj = queryString.parse(window.location.search);
    const formattedDates = useSelector(formattedDateSelector);
    const formattedCompareDates = useSelector(formattedCompareDateSelector);
    const compareToPast = useSelector(isCompareSelector);
    const [isDatePickerMenuOpen, setDatePickerMenu] = useState<boolean>(false);
    const [compareSwitch, setCompareSwitch] = useState(compareToPast);
    const [state, setState] = useState<IDateRanges>({
        selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
        compare: {
            ...getPastPeriod(formattedDates, true)[0],
            key: "compare",
        },
    });
    const sideBar = sideBarDateOptions(state, compareSwitch || false, compareView);
    const staticRanges = [...createStaticRanges(sideBar as StaticRange[])];
    let timeout: ReturnType<typeof setTimeout>;

    useEffect(() => {
        timeout = setTimeout(() => {
            const rdrStaticRangeSelected = document.querySelectorAll(".rdrStaticRangeSelected");
            if (rdrStaticRangeSelected.length) {
                if (rdrStaticRangeSelected.length > 1) {
                    const prevNode = rdrStaticRangeSelected[rdrStaticRangeSelected.length - 1] as HTMLElement;
                    if (prevNode) {
                        const textElements = prevNode.getElementsByTagName("span");
                        prevNode.style.color = DATE_RANGE_COLORS.compare;
                        prevNode.style.boxShadow = "inset -4px 0px 0px rgb(255, 214, 57) !important";

                        if (textElements.length) {
                            textElements[0].style.color = "#000000";
                        }
                    }
                }
                if (rdrStaticRangeSelected && rdrStaticRangeSelected[0]) {
                    const currNode = rdrStaticRangeSelected[0] as HTMLElement;
                    currNode.style.color = DATE_RANGE_COLORS.selection;
                }
            }
            const wrapperDateDisplayClass = document.getElementsByClassName("rdrDateDisplayWrapper");
            if (wrapperDateDisplayClass.length && wrapperDateDisplayClass[0]) {
                if (compareSwitch && compareView) {
                    wrapperDateDisplayClass[0].classList.add("extraText");
                } else {
                    wrapperDateDisplayClass[0].classList.remove("extraText");
                }
            }
        }, 0);
        return () => {
            clearTimeout(timeout);
        };
    }, [state, compareSwitch]);

    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const isModelingAvailable = useSelector(isModelingAvailableSelector);

    const isCompareAvailable = isCampaignAvailable && isModelingAvailable;

    const handleCompareRageSwitch = () => {
        if(isCompareAvailable) {
            setCompareSwitch((prevSwitch) => !prevSwitch);
        }
    };

    const changeCustomRange = (item: IDateRanges) => {
        const { startDate, endDate } = item.selection;
        if (startDate && endDate) {
            dispatch(
                setCurrentDateFilter({
                    date: JSON.stringify([item.selection]),
                }),
            );
            dispatch(
                setCompareDateFilter({
                    compareDate: JSON.stringify([item.compare]),
                }),
            );

            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            const selectedPreset = getSelectedPreset(item.selection, sideBar);

            analyticsService.logEvent("Dates Filtered", {
                Page: getPathForAnalytics(location.pathname, location.search),
                "Preset selected": selectedPreset,
                "Start date": formattedStartDate,
                "End date": formattedEndDate,
            });

            if (appendRangeInLocation) {
                const updateDateFilter = {
                    ...filterQueryParamsObj,
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                };
                navigate({
                    search: convertObjectToQueryParam(updateDateFilter),
                });
            }
            const eventName = `Performance Comparison ${compareSwitch ? "Enabled" : "Disabled"}`;
            analyticsService.logEvent(eventName, {
                Page: getPathForAnalytics(location.pathname),
            });

            if (!compareSwitch) {
                handleDeleteParamKey("comparison_start_date", navigate);
                handleDeleteParamKey("comparison_end_date", navigate);
            }
        }
    };

    const closeDatePicker = () => {
        if (childRef.current) {
            childRef.current.closeDialog();
            handleDatePickerOpenAction(false);
        }
    };

    const handleDatePickerOpenAction = (isOpen: boolean) => {
        setCompareSwitch(compareToPast);
        setDatePickerMenu(isOpen);

        if (isOpen) {
            if (formattedCompareDates[0].startDate && formattedCompareDates[0].endDate) {
                setState({
                    selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
                    compare: {
                        startDate: new Date(formattedCompareDates[0].startDate),
                        endDate: new Date(formattedCompareDates[0].endDate),
                        key: "compare",
                    },
                });
            } else {
                setState({
                    selection: { ...getDefaultRange(formattedDates)[0], key: "selection" },
                    compare: {
                        ...getPastPeriod(formattedDates, true)[0],
                        key: "compare",
                    },
                });
            }
        }
    };

    const applyDateRangeChangesToUI = () => {
        let isAllValidDates = true;
        Object.values(state).forEach((value) => {
            if (!isValid(value.startDate) || !isValid(value.endDate)) {
                isAllValidDates = false;
            }
        });
        if (isAllValidDates) {
            closeDatePicker();
            changeCustomRange(state);
            dispatch(setCompare({ isCompareOn: compareSwitch }));
        }
    };

    const renderDateRangeButtonTitle = () => {
        return (
            <>
                <SVGIconRenderer icon="calendarIcon" className={classes.dateIconStyle} strokeColor={"white"} />
                {compareToPast && compareView ? (
                    <Typography component="div">
                        <Typography sx={{ fontSize: "15px" }}>{getRangeFormattedTitle(formattedDates)}</Typography>
                        <Typography sx={{ color: "rgba(255, 255, 255, 0.75)", fontSize: "13px" }}>
                            {state.compare.startDate && getRangeFormattedTitle(formattedCompareDates)}
                        </Typography>
                    </Typography>
                ) : (
                    getRangeFormattedTitle(formattedDates)
                )}
            </>
        );
    };

    const renderCompareDateRangeButtonTitle = (sidebar: StaticRange) => {
            return (
                <label style={{ width: "100%", cursor: "pointer" }}>
                    {sidebar.label}
                    { isCompareAvailable ? (
                        <Switch onChange={handleCompareRageSwitch} size="small" checked={compareSwitch} />
                    ) : (
                        <Switch
                            size="small"
                            disabled
                            checked={compareSwitch}
                            sx={{ color: "rgba(0, 0, 0, 0.26)" }}
                        />
                    )}
                </label>
            );
    }

    const renderStaticSidebar = (sidebar: StaticRange) => {
        if (sidebar.label === "Compare") {
            return (
                isCompareAvailable ? (renderCompareDateRangeButtonTitle(sidebar)) : (
                        <Tooltip title="Unavailable until modeling has ran">
                            { renderCompareDateRangeButtonTitle(sidebar) }
                        </Tooltip>
                    )
            );
        }
        return <>{sidebar.label}</>;
    };

    const onRangeChangeHandler = (item: RangeKeyDict) => {
        const key = Object.keys(item)[0];
        const innerKey = item[key].key;

        if (innerKey !== "compareSwitch") {
            const updatedStateObj = getSelectedDateRange(key, innerKey || "", state, item);
            setState(updatedStateObj);

            const allInputsArr = document.querySelectorAll(".rdrDateDisplayItem input");
            let focusInput = allInputsArr[0] as HTMLElement;
            if (compareSwitch) {
                if (item[key].key === "compare" || item[key].key === "compareCustom") {
                    focusInput = allInputsArr[2] as HTMLElement;
                }
            }
            focusInput.focus();
        }
    };

    return (
        <LightMenu
            id="current-date-filter-btn"
            dataCy="currentDateFilterBtn"
            title={<>{renderDateRangeButtonTitle()}</>}
            isList={false}
            placement="bottom-end"
            transformOrigin="top right"
            extraButtonClass={isDatePickerMenuOpen ? classes.openMenuBtnStyle : classes.buttonClass}
            closeCustomEndIcon={<SVGIconRenderer icon="chevronUpIcon" strokeColor={"white"} />}
            openCustomEndIcon={<SVGIconRenderer icon="chevronDownIcon" strokeColor={"white"} />}
            ref={childRef}
            onClickHandleOpen={() => handleDatePickerOpenAction(true)}
            menuCloseHandle={() => handleDatePickerOpenAction(false)}
            overFlow={false}
        >
            <Stack className="range-selector">
                <DateRangePicker
                    onChange={(item) => onRangeChangeHandler(item)}
                    renderStaticRangeLabel={(sidebar: StaticRange) => renderStaticSidebar(sidebar)}
                    ranges={compareView && compareSwitch ? [state.selection, state.compare] : [state.selection]}
                    rangeColors={[DATE_RANGE_COLORS.selection, DATE_RANGE_COLORS.compare]}
                    dateDisplayFormat="MMM dd, yyyy"
                    months={2}
                    staticRanges={staticRanges}
                    minDate={minDate ? new Date(minDate) : undefined}
                    direction="vertical"
                    scroll={{ enabled: true }}
                    editableDateInputs={true}
                    showMonthAndYearPickers={false}
                    moveRangeOnFirstSelection={true}
                    retainEndDateOnFirstSelection={false}
                    navigatorRenderer={() => {
                        return <></>;
                    }}
                    calendarFocus="backwards"
                    startDatePlaceholder="From"
                    endDatePlaceholder="To"
                    inputRanges={[]}
                    monthDisplayFormat="MMMM yyyy"
                    maxDate={endOfMonth(new Date())}
                />
                <Stack
                    flexDirection="row"
                    justifyContent="end"
                    gap="15px"
                    sx={{
                        padding: "15px 20px",
                        boxShadow: "inset 0px 1px 0px #bfbfbf",
                        bottom: 0,
                        width: "100%",
                        backgroundColor: "white",
                        borderRadius: "0 0 4px 4px",
                    }}
                >
                    <Button
                        sx={{ textTransform: "inherit", color: "#000000", fontSize: "15px", padding: "7px 30px" }}
                        variant="outlined"
                        onClick={closeDatePicker}
                    >
                        Cancel
                    </Button>
                    <Button
                        sx={{ textTransform: "inherit", fontSize: "15px", padding: "7px 30px" }}
                        variant="contained"
                        onClick={applyDateRangeChangesToUI}
                        id="btn-apply-range"
                        data-cy="btnApplyRange"
                    >
                        Apply
                    </Button>
                </Stack>
            </Stack>
        </LightMenu>
    );
};

export default ReactDateRangeSelect;
