import Spinner from "@mui/material/CircularProgress";
import React, { useEffect, useState } from "react";
import { NotionRenderer } from "react-notion";
import { createGlobalStyle } from "styled-components";

import { GETTING_STARTED_URL } from "../../consts/helpCenterConsts";

const GlobalStyle = createGlobalStyle`
  body {
    background-color: #1B313B!important;
  }
`;

export const GettingStartedPage: React.FC = () => {
    const [data, setData] = useState<any>(null);

    useEffect(() => {
        fetch(`https://notion-api.splitbee.io/v1/page/${GETTING_STARTED_URL}`)
            .then((res) => res.json())
            .then((response) => {
                setData(response);
            });
    }, []);

    return (
        <div>
            <GlobalStyle />
            {data ? (
                <div>
                    <NotionRenderer blockMap={data} />
                </div>
            ) : (
                <Spinner />
            )}
        </div>
    );
};
