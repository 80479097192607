import React, { useContext, useEffect, useState } from "react";
import { Snackbar } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Range } from "react-date-range";

import {
    Choice,
    IHeadCell,
    PerformanceTab,
    getPerformanceWithCAC,
} from "../../../consts/performancePaidPage/performancePaidPage";
import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { IPerformancePercentageTableData } from "../../../interfaces/performanceDetails/IPerformancePercentage";
import { formattedCompareDateSelector, formattedDateSelector } from "../../../reduxState/slices/dateFilterSlice";

import { useLazyGetFilterViewQuery } from "../../../reduxState/apis/filterViewApi";
import { selectCurrentlyViewingId, selectCurrentlyViewingCode } from "../../../reduxState/slices/organizationSlice";
import { getPathForAnalytics, getTotalAmountForPerformanceTable } from "../../../services/utils";
import { getRangeFormattedTitle } from "../../DateRangeFilter/reactDateRangeUtils";
import { getCSVPercentageTable } from "../../../services/performancePage/performancePage";
import { IExportHead, exportCSV, prepareDataForCSV } from "../../../services/exportCSV";
import { getComparator, stableSort } from "../../../utils/sort";
import { exportPerformanceTable } from "../../../reduxState/actions/performanceView";
import { ExportTableData } from "../../../components/ExportTableData/ExportTableData";
import { PerformanceContext } from "../../../containers/CampaignPageV2/CampaignPage";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";

interface ICampaignFunctionalityTools {
    title?: string;
    selectedTableColumns?: IHeadCell[];
    tableData?: IMetricAttributionTableValuesTransformed[];
    order?: Choice;
    orderBy?: string;
    compareToPast?: boolean;
    finalPercentagePerformanceData?: IPerformancePercentageTableData;
    currentDateFilter?: Range[];
    activeTab: PerformanceTab;
}

export const CampaignFunctionalityTools: React.FC<ICampaignFunctionalityTools> = ({
    title,
    selectedTableColumns = [],
    tableData = [],
    order = "desc",
    orderBy = "spend",
    compareToPast = false,
    finalPercentagePerformanceData,
    currentDateFilter,
    activeTab,
}) => {
    const performanceContext = useContext(PerformanceContext);
    const dispatch = useDispatch();
    const formattedDates = useSelector(formattedDateSelector);
    const pastDateFilter = useSelector(formattedCompareDateSelector);
    const connectors = useSelector(connectorsSelector);

    const [getFilterView] = useLazyGetFilterViewQuery();
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);

    const [openToast, setOpenToast] = useState(false);

    const totalAmount = getTotalAmountForPerformanceTable(tableData);
    const [openExportMenu, setOpenExportMenu] = React.useState(false);

    const anchorRef = React.useRef<HTMLDivElement>(null);
    const csvFileTitle = `Paid Campaigns(${getRangeFormattedTitle(formattedDates)?.replace("-", "to")})`;
    const csvComparisonFileTitle = `Paid Campaigns(${
        pastDateFilter && `Compare to ${getRangeFormattedTitle(pastDateFilter)?.replace("-", "to")}`
    } - ${getRangeFormattedTitle(formattedDates)?.replace("-", "to")})`;

    useEffect(() => {
        getFilterView({ orgId: currentOrgId });
    }, [currentOrgId]);

    const exportPerformanceTableEvent = (allColumns: string) => {
        dispatch(
            exportPerformanceTable({
                page: getPathForAnalytics(window.location.pathname, window.location.search),
                allColumns,
            }),
        );
    };

    const updateExportColumnsForTactic = (exportedColumns: IExportHead[]) => {
        let editExportedColumns = exportedColumns.filter(
            (c) => !["channelName", "campaignId", "campaignName"].includes(c.id.toString()),
        );
        editExportedColumns = [
            {
                id: "tacticName",
                numeric: false,
                disablePadding: false,
                label: "Tactic",
                hidden: false,
            },
            ...exportedColumns.filter((c) => c.id !== "tacticName"),
        ];

        return editExportedColumns;
    };

    const exportVisibleColumns = () => {
        exportPerformanceTableEvent("False");
        let exportedColumns: IExportHead[] = [
            {
                id: "channelName",
                numeric: false,
                disablePadding: false,
                label: "Channel Name",
                hidden: false,
                width: "350px",
            },
            ...selectedTableColumns,
        ];

        if (performanceContext?.activeTab === PerformanceTab.Tactics) {
            exportedColumns = updateExportColumnsForTactic(exportedColumns);
        }

        if (compareToPast && finalPercentagePerformanceData && pastDateFilter && currentDateFilter) {
            const finalPercentageDatalist: IMetricAttributionTableValuesTransformed[] = getCSVPercentageTable(
                finalPercentagePerformanceData,
                order,
                orderBy,
                pastDateFilter,
                currentDateFilter,
                activeTab
            );
            exportCSV(csvComparisonFileTitle, prepareDataForCSV(finalPercentageDatalist, exportedColumns));
        } else {
            exportCSV(
                csvFileTitle,
                prepareDataForCSV(
                    [totalAmount].concat(stableSort(tableData, getComparator(order, orderBy))),
                    exportedColumns,
                ),
            );
        }
    };

    const handleMenuItemClick = (event: React.MouseEvent<HTMLLIElement, MouseEvent>, index: number) => {
        if (index === 1) {
            exportPerformanceTableEvent("True");
            let exportedColumns: IExportHead[] = [
                {
                    id: "channelName",
                    numeric: false,
                    disablePadding: false,
                    label: "Channel Name",
                    hidden: false,
                    width: "350px",
                },
                ...getPerformanceWithCAC(performanceContext?.defaultRevenueConnectorSource, connectors, orgCode).map(
                    (header) =>
                        ["campaignId", "tacticName"].includes(header.id) ? { ...header, hidden: false } : header,
                ),
            ];

            if (performanceContext?.activeTab === PerformanceTab.Tactics) {
                exportedColumns = updateExportColumnsForTactic(exportedColumns);
            }

            if (compareToPast && finalPercentagePerformanceData && pastDateFilter && currentDateFilter) {
                const finalPercentageDatalist: IMetricAttributionTableValuesTransformed[] = getCSVPercentageTable(
                    finalPercentagePerformanceData,
                    order,
                    orderBy,
                    pastDateFilter,
                    currentDateFilter,
                );
                exportCSV(
                    csvComparisonFileTitle,
                    prepareDataForCSV(finalPercentageDatalist, exportedColumns as IExportHead[]),
                );
            } else {
                exportCSV(
                    csvFileTitle,
                    prepareDataForCSV(
                        [totalAmount].concat(stableSort(tableData, getComparator(order, orderBy))),
                        exportedColumns as IExportHead[],
                    ),
                );
            }
        } else if (index === 0) {
            exportVisibleColumns();
        }
        setOpenExportMenu(false);
    };

    const handleExportToggle = () => {
        setOpenExportMenu((prevOpen) => !prevOpen);
    };

    const handleExportMenuClose = (event: Event) => {
        if (anchorRef.current && anchorRef.current.contains(event.target as HTMLElement)) {
            return;
        }
        setOpenExportMenu(false);
    };

    return (
        <>
            <>
                <ExportTableData
                    handleMenuItemClick={handleMenuItemClick}
                    anchorRef={anchorRef}
                    exportVisibleColumns={exportVisibleColumns}
                    openExportMenu={openExportMenu}
                    handleExportToggle={handleExportToggle}
                    handleExportMenuClose={handleExportMenuClose}
                />
            </>
            <Snackbar
                open={openToast}
                onClose={() => setOpenToast(false)}
                autoHideDuration={2000}
                message="Link copied!"
                anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
            />
        </>
    );
};
