import { Collapse, Table, TableBody, TableCell, TableContainer, TableRow, Typography, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import { css } from "@emotion/css";

import { cursor } from "src/assets/styles/commonStyle";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { FORECASTED_CAMPAIGNS_HEADERS } from "src/consts/optimizationPage/optimizationPage";
import { FORMATS } from "src/enums/Formats";
import { getConfidenceLevel } from "src/services/optimizationPage/optimization";
import { formatValue, handleClickWithTextSelectionCheck } from "src/services/utils";
import { TableRowsLoader } from "src/components/TableLoader/TableLoader";
import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";

import { OutcomeTableHeader } from "../OutcomeTable/OutcomeTableHeader";
import { OutcomeTableRow } from "../OutcomeTable/OutcomeTableRow";
import { ICard, IScenario } from "src/interfaces/entities/IScenario";
import { FormattedValue } from "../OutcomeTable/FormattedValue";
import { IDictionary } from "src/interfaces/IDictionary";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";

import { store } from "src/reduxState/stores/store";
import { selectTacticById } from "src/reduxState/slices/tacticsSlice";

interface ITacticsRecommendationsTab {
    cards: ICard[];
    scenario: IScenario;
    isTableLoading: boolean;
    totalAllocationCampaigns: IDictionary;
    tacticGroupData: any;
    compareToLabel: string;
}

const tacticTabStyle = () => ({
    recommandarionTabel: css({
        border: "1px solid #D9D9D9",
        backgroundColor: "white",
        borderRadius: "5px",
        "& td, & th": {
            color: "#000",
        },
    }),
    tableBodyStyle: css({
        ".campaignNameCell": {
            width: "450px !important",
            minWidth: "450px !important",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
        },
        ".confidenceCell, .forecastedRevenueCell, .spendCell": {
            fontSize: "15px",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
            width: "150px !important",
            minWidth: "150px !important",
        },
        ".forecastedRoasCell": {
            width: "130px !important",
            minWidth: "130px !important",
        },
    }),
});

export const TacticsRecommendationsTab: FC<ITacticsRecommendationsTab> = ({
    cards,
    tacticGroupData,
    scenario,
    isTableLoading,
    totalAllocationCampaigns,
    compareToLabel,
}) => {
    const classes = { ...tacticTabStyle(), ...optimizationStyle(useTheme()) };
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);

    let filteredHeader = cards.filter((h: any) => h.hidden === false);

    const [openTactic, setOpenTactic] = useState("");
    const [openCampaignId, setOpenCampaignId] = useState("");

    useEffect(() => {
        setOpenCampaignId("");
    }, [compareToLabel, optimizationOutcomeContext?.metricRate]);

    const isForecasted = scenario.scenarioType === "forecast";
    if (isForecasted) {
        filteredHeader = filteredHeader.filter((h: any) => h.id !== "spend");
    }

    const tacticRowClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>, tacticId: string) => {
        e.stopPropagation();
        setOpenTactic((openedId: string) => (tacticId === openedId ? "" : tacticId));
        setOpenCampaignId("");
    };

    const campaignRowClick = (campaignId: string) => {
        setOpenCampaignId((id) => (id === campaignId ? "" : campaignId));
    };

    const tableRowObj = (tacticRow: any): IDictionary => {
        return {
            campaignName: (
                <Stack direction="row" alignItems="center" gap="20px">
                    <div style={{ textAlign: "center" }}>
                        <SVGIconRenderer
                            icon={openTactic === tacticRow.tacticId ? "chevronDownIcon" : "chevronRightIcon"}
                            strokeColor="black"
                        />
                    </div>
                    <Typography className={classes.campaignNameOverflow}>
                        {selectTacticById(store.getState(), tacticRow.tacticId)?.displayName || "Not Set"}
                    </Typography>
                </Stack>
            ),
            confidenceScore: tacticRow.confidenceScore
                ? `${(tacticRow.confidenceScore * 100).toFixed(0)}% ${getConfidenceLevel(tacticRow.confidenceScore)}`
                : "",
            spend: formatValue(tacticRow.spend, FORMATS.DOLLAR, 0),
            trueRevenue: formatValue(tacticRow.trueRevenue, FORMATS.DOLLAR, 0),
            trueRoas: formatValue(tacticRow.trueRoas, FORMATS.NUMERIC, 2),
            allocationSpend: (
                <Typography>
                    {formatValue(tacticRow.allocationSpend, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={tacticRow.totalSpendDiff} format={FORMATS.DOLLAR} precision={0} />
                </Typography>
            ),
            forecastedRevenue: (
                <Typography>
                    {formatValue(tacticRow.forecastedRevenue, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={tacticRow.totalRevenueDiff} format={FORMATS.DOLLAR} precision={0} />
                </Typography>
            ),
            forecastedRoas: (
                <Typography>
                    {formatValue(tacticRow.forecastedRoas, FORMATS.NUMERIC, 2)}
                    <br />
                    <FormattedValue value={tacticRow.totalRoasDiff} format={FORMATS.NUMERIC} precision={2} />
                </Typography>
            ),
        };
    };

    return (
        <Stack gap="20px">
            <TableContainer className={classes.recommandarionTabel}>
                <Table>
                    <OutcomeTableHeader
                        scenario={scenario}
                        isTacticHeader={true}
                        compareToLabel={compareToLabel}
                        cards={cards}
                    />
                    {isTableLoading ? (
                        <TableRowsLoader
                            rowsNum={tacticGroupData.length + 1}
                            colsNum={FORECASTED_CAMPAIGNS_HEADERS.length}
                        />
                    ) : (
                        <TableBody className={classes.tableBodyStyle}>
                            {tacticGroupData.map((tacticRow: any) => {
                                return (
                                    <Fragment key={tacticRow.tacticId}>
                                        <TableRow
                                            role="checkbox"
                                            tabIndex={-1}
                                            className={cursor("pointer")}
                                            hover
                                            onClick={(e) => {
                                                handleClickWithTextSelectionCheck(tacticRowClick)(
                                                    e,
                                                    tacticRow.tacticId,
                                                );
                                            }}
                                            selected={openTactic === tacticRow.tacticId}
                                        >
                                            {filteredHeader.map((element: any, index: number) => {
                                                return (
                                                    <TableCell
                                                        key={element.id}
                                                        className={element.className}
                                                        align={element.align}
                                                    >
                                                        {tableRowObj(tacticRow)[element.id]}
                                                    </TableCell>
                                                );
                                            })}
                                        </TableRow>

                                        <TableRow>
                                            <TableCell style={{ padding: 0 }} colSpan={8}>
                                                <Collapse in={openTactic === tacticRow.tacticId}>
                                                    <Table>
                                                        <TableBody className={classes.tableBodyStyle}>
                                                            {tacticRow.campaigns.map((campaignRow: any) => {
                                                                return (
                                                                    <OutcomeTableRow
                                                                        cards={cards}
                                                                        rowClick={() =>
                                                                            campaignRowClick(campaignRow.campaignId)
                                                                        }
                                                                        key={campaignRow.campaignId}
                                                                        campaignRow={campaignRow}
                                                                        classes={classes}
                                                                        campaignOpenId={openCampaignId}
                                                                        scenario={scenario}
                                                                        campaignData={tacticRow.campaigns}
                                                                        compareToLabel={compareToLabel}
                                                                    />
                                                                );
                                                            })}
                                                        </TableBody>
                                                    </Table>
                                                </Collapse>
                                            </TableCell>
                                        </TableRow>
                                    </Fragment>
                                );
                            })}
                            <TableRow className={classes.totalRow}>
                                {filteredHeader.map((element: any, index: number) => {
                                    return (
                                        <TableCell key={element.id} align={element.align}>
                                            {totalAllocationCampaigns[element.id]}
                                        </TableCell>
                                    );
                                })}
                            </TableRow>
                        </TableBody>
                    )}
                </Table>
            </TableContainer>
        </Stack>
    );
};
