import { forwardRef, useCallback } from "react";
import { useSnackbar, SnackbarContent, CustomContentProps, SnackbarKey } from "notistack";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import IconButton from "@mui/material/IconButton";
import { css } from "@emotion/css";
import { Theme, useTheme } from "@mui/system";

import { PRIMARY_COLOR, TEXT_ERROR_COLOR } from "../../../consts/colors";
import { IDictionary } from "../../../interfaces/IDictionary";
import { Button, ButtonVariant } from "../Button/Button";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

export interface ICusomVariant {
    variantType: "filled" | "outlined";
    variant: "success" | "error" | "info";
}
interface ICustomNotistackProps extends CustomContentProps {
    variantType?: ICusomVariant["variantType"];
    variant: ICusomVariant["variant"];
    action?: boolean;
    customMsg?: any;
    buttonVariant?: ButtonVariant;
    actionHandle?: (params: SnackbarKey) => void;
    customCloseToast?: (params: SnackbarKey) => void;
    id: SnackbarKey;
}

const getVariantToast = (theme: Theme, type: string) => {
    const fixTypes: IDictionary = {
        error_filled: { color: "white", backgroundColor: theme.palette.error.main },
        error_outlined: { color: TEXT_ERROR_COLOR, backgroundColor: "white" },
        success_filled: { color: "white", backgroundColor: PRIMARY_COLOR },
        success_outlined: { color: PRIMARY_COLOR, backgroundColor: "white" },
        info_filled: { color: "white", backgroundColor: "rgb(96, 172, 179)" },
    };
    return fixTypes[type];
};

const useStyles = (theme: Theme, variantType: { color: any; backgroundColor: any }) => ({
    root: css({
        "@media (min-width:600px)": {
            minWidth: "344px !important",
        },
    }),
    card: css({
        backgroundColor: variantType.backgroundColor,
        width: "100%",
    }),
    typography: css({
        color: variantType.color,
        minWidth: "280px",
    }),
    actionRoot: css({
        padding: "8px 8px 8px 16px",
        justifyContent: "space-between",
    }),
    iconRoot: { color: variantType.color, marginLeft: "16px" },
});

const CustomNotistack = forwardRef<HTMLDivElement, ICustomNotistackProps>(
    (
        {
            id,
            message,
            customMsg,
            variant,
            variantType = "outlined",
            action = false,
            actionHandle,
            customCloseToast,
            buttonVariant = ButtonVariant.Text,
            ...other
        },
        ref,
    ) => {
        const classes = useStyles(useTheme(), getVariantToast(useTheme(), `${variant}_${variantType}`));
        const { closeSnackbar } = useSnackbar();

        const handleDismiss = useCallback(() => {
            closeSnackbar(id);
            if (customCloseToast) {
                customCloseToast(id);
            }
        }, [id, closeSnackbar]);

        return (
            <SnackbarContent ref={ref} className={classes.root}>
                <Card className={classes.card}>
                    <CardActions classes={{ root: classes.actionRoot }}>
                        <Typography variant="body2" className={classes.typography} component="div">
                            {message}
                            {customMsg && customMsg}
                        </Typography>

                        <div style={classes.iconRoot}>
                            {action && actionHandle && (
                                <Button
                                    sx={{ color: "inherit" }}
                                    variant={buttonVariant}
                                    onClick={() => actionHandle(id)}
                                >
                                    Fix it
                                </Button>
                            )}
                            <IconButton
                                size="small"
                                onClick={handleDismiss}
                                sx={{ color: "inherit", marginLeft: "5px" }}
                            >
                                <SVGIconRenderer icon="closeIcon" strokeColor="gray" />
                            </IconButton>
                        </div>
                    </CardActions>
                </Card>
            </SnackbarContent>
        );
    },
);

CustomNotistack.displayName = "CustomNotistack";

export default CustomNotistack;
