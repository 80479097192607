import baseCreateApi from "./baseCreateApi";
import { generateAnnouncementsBody } from "../../services/announcements/announcements";
import { formatArrayWithCamelCase } from "../../services/performancePage/performancePage";
import { IAnnouncementsDTO } from "../../interfaces/DTO/IAnnouncementsDTO";
import { IAnnouncement } from "../../interfaces/entities/IAnnouncement";
import { setAnnouncements } from "../slices/announcementSlice";

const setAnnouncementAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setAnnouncements(formatArrayWithCamelCase(res.data)));
        }
    } catch (error) {
        console.error(error);
    }
};

export const announcements = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getAnnouncements: builder.query({
            query: () => ({
                url: `announcements`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setAnnouncementAsyncHandler({ dispatch, queryFulfilled });
            },
            transformResponse: (response: { data: IAnnouncementsDTO[] }): IAnnouncement[] => {
                return formatArrayWithCamelCase(response.data);
            },
        }),
        createAnnouncements: builder.mutation({
            query: (args: { body: IAnnouncement }) => {
                return {
                    url: "announcements",
                    method: "POST",
                    body: JSON.stringify(generateAnnouncementsBody(args.body)),
                };
            },
        }),
        editAnnouncements: builder.mutation({
            query: (args: { params: { id: number }; body: IAnnouncement }) => {
                return {
                    url: `announcements/${args.params.id}`,
                    method: "PATCH",
                    body: JSON.stringify(generateAnnouncementsBody(args.body)),
                };
            },
        }),
        deleteAnnouncements: builder.mutation({
            query: (id: string) => ({
                url: `announcements/${id}`,
                method: "DELETE",
            }),
        }),
        announcementSeen: builder.mutation({
            query: () => {
                return {
                    url: "user/announcements_seen",
                    method: "POST",
                };
            },
        }),
    }),
});

export const {
    useLazyGetAnnouncementsQuery,
    useCreateAnnouncementsMutation,
    useEditAnnouncementsMutation,
    useDeleteAnnouncementsMutation,
    useAnnouncementSeenMutation,
} = announcements;
