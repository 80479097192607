import baseCreateApi from "./baseCreateApi";
import {
    IHistoricalMetricOverviewResponse,
    IHistoricalMetricData,
} from "../../interfaces/api/IHistoricalMetricOverview";
import { IMetricSeriesResponse } from "../../interfaces/api/IHistoricalMetricSeries";
import { IDataSource } from "../../interfaces/IData";
import {
    addMetricsToHistoricalApiData,
    formatHistoricalTimeSeriesResponse,
    formatTrendResponse,
} from "src/services/homeDashboard/homeDashboard";
import { IPerformanceTrendResponse } from "src/interfaces/api/IPerformanceTrendResponse";
import { IPerformanceTrendData } from "src/interfaces/entities/IPerformanceTrend";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { keysToCamelCase } from "src/utils/format";
import { IDictionary } from "src/interfaces/IDictionary";
import { chartGrpKey } from "src/consts/performancePaidPage/performancePaidPage";

export interface IArguments {
    orgId: string;
    start_date: string;
    end_date: string;
    metric_name: string;
    supportedDataSources: IDataSource[];
    dataSourcesByProgrammaticName?: IDictionary<IDataSource>;
}

interface IAttributionMetricArgs {
    orgId: string;
    start_date: string;
    end_date: string;
    metric_names: string[];
    group_by?: chartGrpKey;
}
export interface IHistoricalArgs {
    orgId: string;
    start_date: string;
    end_date: string;
    pastDate?: { start_date: string; end_date: string };
}

interface ITrendArgs {
    orgId: string;
    data: { start_date: string; end_date: string; metric_names: string[]; connectors?: string[]; target: string };
}

export const historicalDetails = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getMetricOverview: builder.query({
            query: (args: IHistoricalArgs) => {
                let apiURL = `organizations/${args.orgId}/historical?start_date=${args.start_date}&end_date=${args.end_date}`;
                if (args.pastDate && args.pastDate.start_date && args.pastDate.end_date) {
                    apiURL = `${apiURL}&comparison_start_date=${args.pastDate.start_date}&comparison_end_date=${args.pastDate.end_date}`;
                }
                return {
                    url: apiURL,
                    method: "GET",
                };
            },
            transformResponse: (
                response: IHistoricalMetricOverviewResponse,
                meta,
                args: IHistoricalArgs,
            ): IHistoricalMetricData => {
                return addMetricsToHistoricalApiData(response.data, args);
            },
        }),
        getMetricTimeSeriesByName: builder.query({
            query: (args: IArguments) => ({
                url: `organizations/${args.orgId}/historical/time_series?metric_name=${args.metric_name}&start_date=${args.start_date}&end_date=${args.end_date}`,
                method: "GET",
            }),
            transformResponse: (
                response: IMetricSeriesResponse,
                meta,
                args: IArguments,
            ): { data: IChartItem[]; meta: any } => {
                return formatHistoricalTimeSeriesResponse(response, args);
            },
        }),
        getAtiributionMetricTimeSeriesByName: builder.query({
            query: (args: IAttributionMetricArgs) => {
                let apiURL = `organizations/${args.orgId}/attribution/time_series?start_date=${args.start_date}&end_date=${args.end_date}`;

                if (args.metric_names.length) {
                    const metricNamesQuery = args.metric_names.map((c: any) => `metric_names[]=${c}`).join("&");
                    apiURL = `${apiURL}&${metricNamesQuery}`;
                }

                if (args.group_by) {
                    apiURL = `${apiURL}&group_by=${args.group_by}`;
                }

                return {
                    url: apiURL,
                    method: "GET",
                };
            },
            transformResponse: (response: IMetricSeriesResponse): { data: IChartItem[]; meta: any } => {
                return {
                    data: keysToCamelCase(response?.data.map((d: any) => ({ ...d, seriesName: d.name }))),
                    meta: keysToCamelCase(response?.meta),
                };
            },
        }),
        getPerformanceTrend: builder.query({
            query: (args: ITrendArgs) => {
                let apiURL = `organizations/${args.orgId}/performance/channel?start_date=${args.data.start_date}&end_date=${args.data.end_date}`;
                if (args.data.metric_names.length) {
                    const metricNamesQuery = args.data.metric_names.map((c) => `metric_names[]=${c}`).join("&");
                    apiURL = `${apiURL}&${metricNamesQuery}`;
                }

                if (args.data.connectors?.length) {
                    const connectorsQuery = args.data.connectors.map((c) => `connectors[]=${c}`).join("&");
                    apiURL = `${apiURL}&${connectorsQuery}`;
                }

                if (args.data.target) {
                    apiURL = `${apiURL}&target=${args.data.target}`;
                }

                return {
                    url: apiURL,
                    method: "GET",
                };
            },
            transformResponse: (response: IPerformanceTrendResponse): IPerformanceTrendData => {
                return formatTrendResponse(response.data);
            },
        }),
    }),
});

export const {
    useLazyGetMetricOverviewQuery,
    useLazyGetMetricTimeSeriesByNameQuery,
    useLazyGetPerformanceTrendQuery,
    useLazyGetAtiributionMetricTimeSeriesByNameQuery,
} = historicalDetails;
