import React, { useState } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import { IMember } from "../../interfaces/entities/IMember";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { userStateSelector } from "../../reduxState/slices/userSlice";
import { getSearchedAndFilteredMembers } from "../../services/SuperAdminMembers/SuperAdminMembers";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { UserForm } from "./UserForm";
import { Button } from "../core/Button/Button";

interface IPermissionForm {
    members: IMember[];
    companies: IOrganization[];

    setMember: (member: IMember) => void;
    member: IMember;
    onCancel(...args: any[]): any;

    onRemove(...args: any[]): any;

    onChangeMode(...args: any[]): any;

    gotToBack(...args: any[]): any;

    onSubmit(...args: any[]): any;
}

export const PermissionForm: React.FC<IPermissionForm> = ({
    members,
    companies,
    onCancel,
    onRemove,
    onChangeMode,
    gotToBack,
    onSubmit,
    setMember,
    member,
}) => {
    const [email, setEmail] = useState("");
    const currentUser = useSelector(userStateSelector);

    const emailChangeHandle = (e: React.SyntheticEvent<Element, Event>, newValue: IMember) => {
        if (newValue) {
            setEmail(newValue.email);
            const findMembers: IMember = getSearchedAndFilteredMembers(members, newValue.email, 0)[0];
            let roles: any[] = [];
            if (findMembers && findMembers.rolesByCompanyId) {
                companies.forEach((c: any) => {
                    if (findMembers && findMembers.companies?.includes(c.id)) {
                        const rolesByCompanyId = findMembers.rolesByCompanyId || {};
                        const roleObj: any = Object.values(rolesByCompanyId).find(
                            (role: any) => role.companyId === c.id,
                        );
                        roles = [...roles, { role: roleObj.role, company: c }];
                    }
                });

                setMember({
                    ...findMembers,
                    roles: roles.length ? roles : [{ role: "", company: null }],
                });
            }
        }
    };

    return (
        <>
            {!email && (
                <>
                    <Autocomplete
                        options={members}
                        renderInput={(params) => <TextField {...params} label="Members" />}
                        getOptionLabel={(option) => option.email || ""}
                        filterSelectedOptions
                        onChange={(e, newValue) => emailChangeHandle(e, newValue)}
                        disableClearable
                    />
                    <Button
                        variant="text"
                        sx={{ marginTop: "40px" }}
                        type="submit"
                        onClick={gotToBack}
                        startIcon={<SVGIconRenderer icon="arrowLeftIcon" strokeColor="primary" />}
                    >
                        Back
                    </Button>
                </>
            )}

            {email && (
                <UserForm
                    initialValues={member as IMember}
                    allCompanies={companies}
                    isEditMode={true}
                    isCurrentUser={Boolean(
                        member && member.id && currentUser && currentUser.id && member.id === currentUser.id,
                    )}
                    selectedCompanies={
                        companies ? companies.filter((c) => member.companies?.includes(c.id)) : ["No organization"]
                    }
                    onSubmit={onSubmit}
                    onRemove={onRemove}
                    onCancel={onCancel}
                    onChangeMode={onChangeMode}
                    gotToBack={() => setEmail("")}
                    isSuperAdminForm={false}
                    showSelectedCompanies={false}
                    showBackButton={true}
                />
            )}
        </>
    );
};
