export type Choice = "asc" | "desc";

export interface ICells {
    id: string;
    name: string;
}

interface IHeadCell {
    id: keyof ICells;
    label: string;
    numeric: boolean;
    align?: "inherit" | "left" | "center" | "right" | "justify";
}

export const DATA_PROVIDER_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "id",
        numeric: false,
        label: "Id",
    },
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
];
