import { FC } from "react";
import { OverridableStringUnion } from "@mui/types";
import { Paper, Table, TableContainer, TablePropsSizeOverrides } from "@mui/material";

import { useStylesTable } from "../../../consts/StylesForTables";
import { CustomTablePagination } from "../CustomTablePagination/CustomTablePagination";

interface ICustomTableStructure {
    paginationProps: {
        count: number;
        page: any;
        handleChangePage: (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => void;
        handleChangeRowsPerPage: (event: React.ChangeEvent<HTMLInputElement>) => void;
        rowsPerPage?: number;
        extraInfo?: string;
        className?: any;
    };
    showPagination?: boolean;
    children: React.ReactNode;

    tableProps?: { id?: string; size: OverridableStringUnion<"small" | "medium", TablePropsSizeOverrides>; className?: string};
    className?: any;
}

const tablePaperStyle = { width: "100%", overflow: "hidden", boxShadow: "none", border: "1px solid #E0E0E0" };

export const CustomTableStructure: FC<ICustomTableStructure> = ({
    children,
    paginationProps,
    showPagination = true,
    tableProps = { size: "medium" },
    className,
}) => {
    const classes = useStylesTable();

    return (
        <Paper className={className} sx={tablePaperStyle}>
            <TableContainer className={classes.tableWrapper}>
                <Table {...tableProps}>{children}</Table>
            </TableContainer>
            { showPagination &&
                <CustomTablePagination {...paginationProps} />
            }
        </Paper>
    );
};
