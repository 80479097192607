import { useState } from "react";
import { Box, Stack } from "@mui/system";
import { css } from "@emotion/css";
import { Accordion, AccordionSummary, Table, TableBody, TableCell, TableRow, Typography } from "@mui/material";

import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";

const useStyles = () => ({
    accordionStyle: css({
        boxShadow: "none",
        ".MuiCollapse-root": {
            height: "calc(100vh - 900px) !important",
            minHeight: "300px !important",
            overflow: "auto",
            "&::-webkit-scrollbar": {
                width: "8px",
            },
            "&::-webkit-scrollbar-thumb": {
                borderRadius: "4px",
                backgroundColor: "#C5C9CD",
            },
        },
    }),
    summaryStyle: css({
        padding: "10px 0",
        borderBottom: "1px solid",
        marginBottom: "20px",
        minHeight: "unset !important",
        ".MuiAccordionSummary-content": {
            marginBottom: "0 !important",
            marginTop: "0 !important",
        },
    }),
    tableCell: css({
        padding: "8px",
    }),
});

export const AllocationHistory = ({ scenarioEventLogs, goToJob, currentJobId }: any) => {
    const [versionExpanded, setVersionExpanded] = useState(true);
    const classes = useStyles();

    return (
        <Box>
            <Accordion
                expanded={versionExpanded}
                className={classes.accordionStyle}
                onChange={() => setVersionExpanded((isExpanded) => !isExpanded)}
            >
                <AccordionSummary
                    expandIcon={<SVGIconRenderer icon="chevronDownIcon" />}
                    aria-controls="panel1bh-content"
                    id="panel1bh-header"
                    className={classes.summaryStyle}
                >
                    <Stack direction="row" gap="8px" alignItems="center">
                        <SVGIconRenderer height="20px" width="20px" icon="historyIcon" />
                        <Typography>Versions</Typography>
                    </Stack>
                </AccordionSummary>
                <Table>
                    <TableBody>
                        {scenarioEventLogs.map((se: any) => {
                            const isActiveJob = currentJobId === se.jobId;
                            return (
                                <TableRow
                                    hover
                                    sx={{ cursor: "pointer" }}
                                    key={se.jobId}
                                    onClick={() => goToJob(se)}
                                    selected={isActiveJob}
                                >
                                    <TableCell
                                        className={classes.tableCell}
                                        sx={{
                                            ".MuiTypography-root": {
                                                fontWeight: isActiveJob ? "bold" : "400",
                                            },
                                        }}
                                    >
                                        {se.msg}
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </Accordion>
        </Box>
    );
};
