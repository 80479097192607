import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import tatariSetupImage1 from "src/assets/tatariSetupImage1.png";
import tatariSetupImage2 from "src/assets/tatariSetupImage2.png";
import tatariSetupImage3 from "src/assets/tatariSetupImage3.png";
import tatariSetupImage4 from "src/assets/tatariSetupImage4.png";

interface ITatariConnectorCredentialModalContent {
    classes: any;
}

export const TatariConnectorCredentialModalContent: FC<ITatariConnectorCredentialModalContent> = ({ classes }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>
            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Enabling Access
                </Typography>
            </Stack>
            <Typography className={classes.info}>
                Log in to <a href="https://www.tatari.tv/login">your dashboard</a>.
            </Typography>
            <Typography className={classes.info}>
                On the bottom left navigation bar, select “Settings” then “Integrations.”
            </Typography>
            <img src={tatariSetupImage1} />
            <Typography className={classes.info}>Select "AWS Data Sharing" from the navigation bar.</Typography>
            <img src={tatariSetupImage2} />
            <Typography className={classes.info}>On the “AWS Data Sharing” page</Typography>
            <Stack pl={2} gap={"10px"}>
                <Typography component="li" className={classes.info}>
                    Scroll to the bottom
                </Typography>
                <Typography component="li" className={classes.info}>
                    In the <b>AWS Account ID</b> box, paste <code>626831056180</code>
                </Typography>
                <img src={tatariSetupImage3} />
            </Stack>
            <Typography className={classes.info}>Using the dropdown, set the Type as Role</Typography>
            <Typography className={classes.info}>
                In the <b>Name</b> box, paste <code>tatari_resource_access_role</code>
            </Typography>
            <Typography className={classes.info}>Click “Add”</Typography>
            {/* Step 2 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Retrieving Your Company Name
                </Typography>
            </Stack>
            <Typography className={classes.info}>Next, we need to retrieve your company name within Tatari</Typography>
            <Typography className={classes.info}>
                On the “AWS Data Sharing” page, scroll up and open the “Policy Setup Instructions” section
            </Typography>
            <Typography className={classes.info}>Scroll down and look for the policy details</Typography>
            <Typography className={classes.info}>a.</Typography> <img src={tatariSetupImage4} />
            <Typography className={classes.info}>
                Your Tatari company name can be found at the very end after <i>/ClientRole-DataShare-</i>
            </Typography>
            <Stack pl={2} gap="10px">
                <Typography component="li" className={classes.info}>
                    For example, in the screenshot above the company name is <code>demo_growth</code>
                </Typography>
                <Typography component="li" className={classes.info}>
                    Note down your Tatari company name
                </Typography>
            </Stack>
            <Typography className={classes.info}>Return to Prescient and enter your Tatari company name</Typography>
            <Typography className={classes.info}>Click Save & Test</Typography>
        </Fragment>
    );
};
