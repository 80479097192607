import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";

import { Choice, ICells, MEMBERS_PAGE_TABLE_HEADERS } from "../../../consts/membersPage/membersPage";
import { UserRole } from "../../../enums/UserRole";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "../../../reduxState/slices/userSlice";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";

interface IEnhancedHeaderProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    choice: Choice;
    choiceBy: string;
    isAllAcceptedMembers: boolean;
}

export const MembersTableHeader = (props: IEnhancedHeaderProps) => {
    const { onRequestSort, choice, choiceBy, isAllAcceptedMembers } = props;
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const isDemoMode = useSelector(demoModeSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const classes = useStylesForHeaderInTable();
    const isActionVisible = !isDemoMode && (isSuperAdmin || userRole !== UserRole.MEMBER);

    const createSortHandler = (property: keyof ICells) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
                {MEMBERS_PAGE_TABLE_HEADERS.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={headCell.id === "createdAt" ? "right" : "left"}
                        sortDirection={choiceBy === headCell.id ? choice : false}
                        className={classes.tableCell}
                    >
                        <TableSortLabel
                            active={choiceBy === headCell.id}
                            direction={choiceBy === headCell.id ? choice : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {choiceBy === headCell.id ? (
                                <Box component="span" style={{ display: "none" }}>
                                    {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {isActionVisible && (
                    <>
                        {!isAllAcceptedMembers && (
                            <TableCell
                                key="invitationState"
                                align="center"
                                className={classes.tableCell}
                                sortDirection={choiceBy === "invitationState" ? choice : false}
                            >
                                <TableSortLabel
                                    active={choiceBy === "invitationState"}
                                    direction={choiceBy === "invitationState" ? choice : "asc"}
                                    onClick={createSortHandler("invitationState")}
                                >
                                    Invitation Status
                                    {choiceBy === "invitationState" ? (
                                        <Box component="span" style={{ display: "none" }}>
                                            {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                        </Box>
                                    ) : null}
                                </TableSortLabel>
                            </TableCell>
                        )}
                        <TableCell key="actions" align="right" className={classes.tableCell}></TableCell>
                    </>
                )}
            </TableRow>
        </TableHead>
    );
};
