import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import format from "date-fns/format";

import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";
import { selectCurrentlyViewingCompanyById } from "../../reduxState/slices/organizationSlice";

export const TrialEnd = () => {
    const analyticsService = useAnalyticsService();
    const currentOrg = useSelector(selectCurrentlyViewingCompanyById);

    useEffect(() => {
        analyticsService.logEvent("Free Trial Ended Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, []);

    return (
        <div>
            <NoCampaignPage
                title="Your free trial has expired."
                subTitle={
                    <>
                        Thanks for testing our Prescient! Your 14 day free trial expired on{" "}
                        {currentOrg?.subscription &&
                            format(new Date(currentOrg.subscription.currentPeriodEnd), "MMMM d, yyyy")}
                        . To activate your account, make sure you've added your payment information via our Billing page
                        and reach out to your customer success manager.
                    </>
                }
                isContinueAsSuperAdminBtn={true}
            />
        </div>
    );
};
