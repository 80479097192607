import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { IPerformanceDetails } from "../../interfaces/entities/IPerformanceDetails";

const initialState: IPerformanceDetails[] = [];

const performanceDetailsSlice = createSlice({
    name: "performanceDetails",
    initialState,
    reducers: {
        setPerformanceDetails: (state, { payload }) => payload,
    },
});

export const { setPerformanceDetails } = performanceDetailsSlice.actions;

export default performanceDetailsSlice.reducer;

// Selectors
export const performanceDetailsSelector = (state: RootState) => state.performanceDetails;
