import {
    BaseQueryFn,
    createApi,
    FetchArgs,
    FetchBaseQueryError,
    fetchBaseQuery,
    retry,
} from "@reduxjs/toolkit/query/react";
import { apiV2 } from "../../utils/env";
import { getToken } from "../../services/utils";
import { logoutUser } from "../actions/auth";
import Bugsnag from "@bugsnag/js";

const baseQuery = fetchBaseQuery({
    baseUrl: apiV2,
    credentials: "include",
    prepareHeaders: (headers, { getState }) => {
        headers.set("Accept", "application/vnd.api+json");
        headers.set("Content-Type", "application/vnd.api+json");

        // delete when we verified it isn't needed anymore
        const accessToken = getToken();
        if (accessToken) {
            headers.set("Authorization", `Bearer ${accessToken}`);
        }

        return headers;
    },
});
const baseQueryWithReauth: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> = async (
    args,
    api,
    extraOptions,
) => {
    try {
        let result = await baseQuery(args, api, extraOptions);

        if (result.error) {
            // @ts-ignore
            if (result.error?.status === 401) {
                // retry the request for now then if fail we will log user out.
                // TODO: try to get new auth token instead, example: https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#automatic-re-authorization-by-extending-fetchbasequery
                result = await baseQuery(args, api, extraOptions);
                if (result.data) {
                    // TODO: retry the initial query when refresh is successful
                    // result = await baseQuery(args, api, extraOptions);
                } else {
                    api.dispatch(logoutUser());
                }
            } else {
                console.warn(result.error);
                if (result.error.status !== 401 && result.error.status !== 403 && result.error.status !== 422) {
                    // @ts-ignore
                    if (result.error?.data?.errors?.length) {
                        // @ts-ignore
                        Bugsnag.notify(new Error(result.error.data.errors[0]?.detail));
                    }
                }
            }
        }
        return result;
    } catch (error) {
        // @ts-ignore
        Bugsnag.notify(error);
        throw error;
    }
};
const baseCreateApi = createApi({
    reducerPath: "api",
    baseQuery: retry(baseQueryWithReauth, { maxRetries: 0 }),
    endpoints: () => ({}),
    tagTypes: ["UserEvents"],
});

export default baseCreateApi;
