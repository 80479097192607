import { FORMATS } from "../../enums/Formats";
import { CAMPARE_CONDITIONS } from "../performancePaidPage/performancePaidPage";

export type CampaignType = "Flow" | "Campaign";

export type Choice = "asc" | "desc";

export const EMAIL_TRUE_METRICS = ["trueRevenue"];

export const NEGATIVE_TREND_GOOD = ["CPM", "CPC"];

export interface IEmailAttributionTableCells {
    connectorName: string;
    insightAvailable: boolean;
    hasSecondOrderEffect: boolean;
    campaignId: string;
    accountId: string;
    status: string;
    startDate: string;
    endDate: string;
    campaignName: string;
    type: CampaignType;

    channelReportedRevenue: number;
    emailSends: number;
    emailOpen: number;
    emailOpenUnique: number;
    emailClicks: number;
    emailClicksUnique: number;
    emailBounces: number;
    emailUnsubscribes: number;
    conversions: number;

    reportedOrders: number;
    openRate: number;
    clickRate: number;
    bounceRate: number;
    unsubscribeRate: number;
    rpc: number;
    aov: number;
}

export interface IHeadCell {
    id: keyof IEmailAttributionTableCells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
    sign?: FORMATS;
    fixed?: number;
    exportFixed?: number; // used to give more granularity in export values.
    hidden?: boolean;
    width?: string;
}

export const EMAIL_ATTRIBUTION_HEADERS: readonly IHeadCell[] = [
    {
        id: "campaignName",
        numeric: false,
        disablePadding: false,
        label: "Name",
        hidden: false,
        width: "350px",
    },
    {
        id: "type",
        numeric: false,
        disablePadding: false,
        label: "Type",
        hidden: false,
    },
    {
        id: "campaignId",
        numeric: false,
        disablePadding: false,
        label: "Campaign Id",
        hidden: true,
    },
    {
        id: "channelReportedRevenue",
        numeric: true,
        disablePadding: false,
        label: "Channel Revenue",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "9%",
    },
    {
        id: "reportedOrders",
        numeric: true,
        disablePadding: false,
        label: "Reported Orders",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
    {
        id: "emailSends",
        numeric: true,
        disablePadding: false,
        label: "Sends",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
        width: "8%",
    },
    {
        id: "emailOpen",
        numeric: true,
        disablePadding: false,
        label: "Total opens",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
    {
        id: "emailOpenUnique",
        numeric: true,
        disablePadding: false,
        label: "Unique Opens",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
    },
    {
        id: "openRate",
        numeric: true,
        disablePadding: false,
        label: "Open Rate",
        sign: FORMATS.PERCENT,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "emailClicks",
        numeric: true,
        disablePadding: false,
        label: "Total Clicks",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
    {
        id: "emailClicksUnique",
        numeric: true,
        disablePadding: false,
        label: "Unique Clicks",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: false,
    },
    {
        id: "clickRate",
        numeric: true,
        disablePadding: false,
        label: "Click Rate",
        sign: FORMATS.PERCENT,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "emailBounces",
        numeric: true,
        disablePadding: false,
        label: "Bounces",
        hidden: true,
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
    },
    {
        id: "bounceRate",
        numeric: true,
        disablePadding: false,
        label: "Bounce Rate",
        sign: FORMATS.PERCENT,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "emailUnsubscribes",
        numeric: true,
        disablePadding: false,
        label: "Unsubscribes",
        sign: FORMATS.NUMERIC,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
    {
        id: "unsubscribeRate",
        numeric: true,
        disablePadding: false,
        label: "Unsubscribe Rate",
        sign: FORMATS.PERCENT,
        fixed: 2,
        exportFixed: 2,
        hidden: false,
    },
    {
        id: "rpc",
        numeric: true,
        disablePadding: false,
        label: "RPC",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
    {
        id: "aov",
        numeric: true,
        disablePadding: false,
        label: "AOV",
        sign: FORMATS.DOLLAR,
        fixed: 0,
        exportFixed: 0,
        hidden: true,
    },
];

export const TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE: any = {
    campaignId: "All Campaigns",
    campaignName: "All Campaigns",
    reportedRevenue: 0,
    reportedOrders: 0,
    sends: 0,
    totalOpens: 0,
    uniqueOpens: 0,
    openRate: 0,
    totalClicks: 0,
    uniqueClicks: 0,
    clickRate: 0,
    bounces: 0,
    bounceRate: 0,
    unsubscribes: 0,
    unsubscribeRate: 0,
    rpc: 0,
    aov: 0,
};

export const SAMPLE_IN_EMAIL_METRIC_ATTRIBUTION_TABLE: any = {
    reportedRevenue: -1,
    reportedOrders: 0,
    sends: 0,
    totalOpens: 0,
    uniqueOpens: 0,
    openRate: 0,
    totalClicks: 0,
    uniqueClicks: 0,
    clickRate: 0,
    bounces: 0,
    bounceRate: 0,
    unsubscribes: 0,
    unsubscribeRate: 0,
    rpc: 0,
    aov: 0,
    hasSecondOrderEffect: false,
};

export const EMAIL_PERFORMANCE_FILTER_LIST: Array<{
    key: string;
    title: string;
    condition: string;
    type: string;
    items: any;
}> = [
    {
        key: "type_exclude",
        title: "Type",
        condition: "is NOT",
        type: "checkbox",
        items: [
            { key: "flow", title: "Flow", condition: CAMPARE_CONDITIONS.eq },
            { key: "campaign", title: "Campaign", condition: CAMPARE_CONDITIONS.eq },
        ],
    },
    {
        key: "campaign_name",
        title: "Campaign Name",
        condition: "contains",
        type: "conditionInputs",
        items: [
            { key: "contains", title: "Contains", condition: "contains" },
            { key: "not_contains", title: "Does not contain", condition: "does NOT contain" },
        ],
    },
    {
        key: "metric",
        title: "Metric",
        condition: "contains",
        type: "metricConditionInputs",
        items: [
            {
                metric: [],
                condition: [
                    { key: "gt", title: CAMPARE_CONDITIONS.gt, condition: CAMPARE_CONDITIONS.gt },
                    { key: "lt", title: CAMPARE_CONDITIONS.lt, condition: CAMPARE_CONDITIONS.lt },
                    { key: "eq", title: CAMPARE_CONDITIONS.eq, condition: CAMPARE_CONDITIONS.eq },
                ],
                value: "",
            },
        ],
    },
];
