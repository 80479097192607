import { ForecastTimeframe, IIndividualHolidays } from "../api/ICompanyForecastResponseV1";

export enum CompanyForecastMetricMapping {
    totalHoliday = "totalHoliday",
    trend = "trend",
    trendHolidayMarketing = "trendHolidayMarketing",
    marketing = "marketing",
    globalAccuracy = "globalAccuracy",
    actualRevenue = "actualRevenue",
    smape = "smape",
    minAndMaxDate = "minAndMaxDate",
}

interface IRevenueModels {
    individualHolidays: IIndividualHolidays | number;
    [CompanyForecastMetricMapping.totalHoliday]: number;
    [CompanyForecastMetricMapping.trend]: number;
    [CompanyForecastMetricMapping.trendHolidayMarketing]: number;
}

interface IMetricDict {
    company: string;
    mape: number;
    [CompanyForecastMetricMapping.smape]: number;
    [CompanyForecastMetricMapping.globalAccuracy]: number;
}

interface IBacktestData {
    actualRevenue: number;
    metricDict: IMetricDict;
    revenueModels: IRevenueModels;
    minAndMaxDate: string[];
}

export type ICompanyBacktestFormattedResponse = {
    [timeframe in ForecastTimeframe]: {
        data: IBacktestData;
    };
};
