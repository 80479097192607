import React, { useEffect, useState } from "react";

import { IHeadCell } from "../../../../consts/emailPage/emailPage";
import { DynamicFilter, IFilterItem } from "../../../DynamicFilter/DynamicFilter";
import { IEmail } from "../../../../interfaces/entities/IEmail";

interface IEmailFilterComponent {
    handleFilter: (
        paramKey: string,
        item: {
            id: string;
        },
        isArray?: boolean,
    ) => void;
    filterList: IFilterItem[];
    selectedTableColumns: IHeadCell[];
    filterChipsCount: number;
    campaignIdOptions: IEmail[];
}

export const EmailFilterComponent: React.FC<IEmailFilterComponent> = ({
    filterList,
    handleFilter,
    selectedTableColumns,
    filterChipsCount,
    campaignIdOptions,
}) => {
    const [currentFilterList, setCurrentFilterList] = useState(filterList);

    const numericColumns = selectedTableColumns.filter(
        (col) => col.hidden === false && col.numeric === true && col.id !== "campaignId",
    );
    const isCampaignIdVisible = selectedTableColumns.find((c) => c.id === "campaignId")?.hidden;

    useEffect(() => {
        setCurrentFilterList(filterList);
    }, [filterChipsCount, filterList]);

    const handleChange = (list: IFilterItem) => {
        const updatedList = currentFilterList.map((l) => (l.id !== list.id ? l : { ...l, open: !l.open }));
        setCurrentFilterList(updatedList);
    };

    return (
        <DynamicFilter
            filterChipsCount={filterChipsCount}
            currentFilterList={currentFilterList}
            isCampaignIdVisible={isCampaignIdVisible}
            handleChange={handleChange}
            handleFilter={handleFilter}
            campaignIdOptions={campaignIdOptions}
            numericColumns={numericColumns}
        />
    );
};
