import { CircularProgress, Paper, Typography } from "@mui/material";

export const CardLoader = ({ height, width }: { height: string; width?: string }) => {
    return (
        <Paper
            elevation={0}
            sx={{
                height,
                width,
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
            }}
        >
            <CircularProgress size={24} disableShrink /> <Typography>Fetching Data...</Typography>
        </Paper>
    );
};
