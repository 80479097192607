import { Typography } from "@mui/material";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { bind } from "decko";
import Spinner from "@mui/material/CircularProgress";

import { Button } from "../core/Button/Button";
import { Field } from "../core/Field/Field";
import { Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { ImageInput } from "../core/ImageInput/ImageInput";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IFeature } from "../../interfaces/entities/IFeature";
import { fullWidthClass, imageInputClass, imageLabel } from "../../assets/styles/commonStyle";

export interface IOrganizationModalProps extends FormikConfig<IOrganization> {
    featuresList: IFeature[];
    isEditMode?: boolean;

    onCancel?(...args: any[]): any;
    onRemove?(...args: any[]): any;
    onChangeMode?(...args: any[]): any;
}

export class SimpleOrganizationForm extends React.Component<IOrganizationModalProps, {}> {
    public state = {
        image: "",
        imageName: "",
        isDeleteModal: false,
        accessLevel: "No Access",
    };

    @bind()
    // @ts-ignore
    public closeModal() {
        this.setState({ isDeleteModal: false });
    }

    public render() {
        const toBase64 = (file: Blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        const getFile = async (url: Blob) => {
            return toBase64(url);
        };
        const { onCancel, onRemove, onChangeMode, isEditMode, featuresList, ...rest } = this.props;
        const imageFunction = async (getImage: any) => {
            return getImage(this.state.image);
        };

        return (
            <Formik
                validationSchema={object().shape({
                    name: string().required("Organization name is required"),
                })}
                {...rest}
            >
                {({
                    errors,
                    handleChange,
                    touched,
                    values,
                    isSubmitting,
                    setFieldValue,
                }: FormikProps<IOrganization>) => {
                    const hasNameError = Boolean(touched.name && (errors.name || errors.base));

                    const disabledInput = isSubmitting;
                    const minLengthError = values.name.length < 2;
                    const notMatchSchema = Boolean(!values.name.trim().match(/^[a-zA-Z0-9_]{1}[a-zA-Z0-9_\s]*$/));
                    const notMatched = minLengthError || notMatchSchema;
                    const avatarUrl =
                        values.avatarMediumUrl && !values.avatarMediumUrl.match(/missing_avatar/)
                            ? values.avatarMediumUrl
                            : values.name && values.name[0].toUpperCase();
                    return (
                        <Form>
                            <Typography className={imageLabel}>Logo</Typography>
                            <div className={imageInputClass}>
                                <ImageInput
                                    onChange={handleChange}
                                    initial={avatarUrl}
                                    onSelectImage={(file, name) => {
                                        this.setState(
                                            {
                                                image: file,
                                                imageName: name,
                                            },
                                            async () => {
                                                setFieldValue("avatar", await imageFunction(getFile));
                                                setFieldValue("avatarName", this.state.imageName);
                                            },
                                        );
                                    }}
                                    isAvatar
                                    name="avatar"
                                />
                            </div>
                            <Flex direction={FlexDirection.Column} spacing={"24px"}>
                                <FlexItem className={fullWidthClass}>
                                    <Field
                                        id="displayName"
                                        name="displayName"
                                        disabled={disabledInput}
                                        label="Display name"
                                        value={values.displayName}
                                        onChange={handleChange}
                                        placeholder="Display name"
                                        className={fullWidthClass}
                                    />
                                </FlexItem>
                                <FlexItem style={{ width: "100%" }}>
                                    <Button
                                        type="submit"
                                        disabled={isSubmitting || notMatched}
                                        className={fullWidthClass}
                                        fullWidth
                                    >
                                        {isSubmitting ? <Spinner size={25} /> : "Save changes"}
                                    </Button>
                                </FlexItem>
                            </Flex>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
