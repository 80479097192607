import baseCreateApi from "./baseCreateApi";

import { IDataSource, IDataSourceForm } from "../../interfaces/IData";
import { setSupportedDataSources } from "../slices/supportedDataSourcesSlice";
import { setSupportedDataSourcesByOrg } from "../slices/supportedDataSourcesByOrgSlice";
import { keysToCamelCase } from "../../utils/format";
import { transformDataSource, updateBodyDataSource } from "src/services/dataSources/dataSources";
import { IDataSourceCategories } from "src/interfaces/api/IDataSourceCategories";
import { IDataSourceInApiResponse, IDataSourceResponse } from "src/interfaces/api/IDataSourceResponse";
import { IDataSourceProviderResponse } from "src/interfaces/api/IDataSourceProviderResponse";
import { IDataProvider } from "src/interfaces/IDataProvider";
import { IDataCategory } from "src/interfaces/IDataCategory/IDataCategory";

const setSupportedDataSourcesAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setSupportedDataSources(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

const setSupportedDataSourcesByOrgAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setSupportedDataSourcesByOrg(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

export const dataSources = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getAllDataSources: builder.query({
            query: () => ({
                url: `/data_sources`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setSupportedDataSourcesAsyncHandler({ dispatch, queryFulfilled });
            },
            keepUnusedDataFor: 3600,
            transformResponse: (response: IDataSourceResponse): IDataSource[] => {
                return transformDataSource(response.data);
            },
        }),
        getSupportedDataSources: builder.query({
            query: (args: { orgId: string }) => ({
                url: `organizations/${args.orgId}/data_sources`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setSupportedDataSourcesByOrgAsyncHandler({ dispatch, queryFulfilled });
            },
            transformResponse: (response: IDataSourceResponse): IDataSource[] => {
                return transformDataSource(response.data);
            },
        }),
        getDataSourceCategories: builder.query({
            query: () => ({
                url: `data_source_categories`,
                method: "GET",
            }),
            transformResponse: (response: IDataSourceCategories): IDataCategory[] => {
                return (
                    response?.data?.map((d) => ({
                        ...keysToCamelCase(d.attributes),
                    })) || []
                );
            },
        }),
        getDataSourceProviders: builder.query({
            query: () => ({
                url: `data_source_providers`,
                method: "GET",
            }),
            transformResponse: (response: IDataSourceProviderResponse): IDataProvider[] => {
                return (
                    response?.data?.map((d) => ({
                        ...keysToCamelCase(d.attributes),
                    })) || []
                );
            },
        }),
        createDataSource: builder.mutation({
            query: (body: IDataSourceForm) => ({
                url: "data_sources",
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "data_sources",
                        attributes: updateBodyDataSource(body),
                    },
                }),
            }),
            transformResponse: (response: { data: IDataSourceInApiResponse }) => {
                return transformDataSource([response.data]);
            },
        }),
        editDataSource: builder.mutation({
            query: (queryArgs: { params: { id: number }; body: IDataSourceForm }) => ({
                url: `data_sources/${queryArgs.params.id}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        type: "data_sources",
                        attributes: updateBodyDataSource(queryArgs.body),
                    },
                }),
            }),
            transformResponse: (response: { data: IDataSourceInApiResponse }) => {
                return transformDataSource([response.data]);
            },
        }),
        deleteDataSource: builder.mutation({
            query: (id: number) => ({
                url: `data_sources/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetDataSourceProvidersQuery,
    useLazyGetAllDataSourcesQuery,
    useLazyGetSupportedDataSourcesQuery,
    useGetDataSourceCategoriesQuery,
    useCreateDataSourceMutation,
    useEditDataSourceMutation,
    useDeleteDataSourceMutation,
} = dataSources;
