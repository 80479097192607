import React, { useState } from "react";
import { Form, Formik, FormikConfig, FormikProps, ErrorMessage } from "formik";
import { FormControl, InputLabel, MenuItem, Select, TextField, Button, Box, Tab } from "@mui/material";
import { TabList, TabPanel, TabContext } from "@mui/lab";
import format from "date-fns/format";
import Stack from "@mui/system/Stack";
import { date, object, ref, string } from "yup";
import { ISubscriptionWithOrg } from "../../containers/Organizations/Organizations";
import { SUBSCRIPTION_STATUS } from "../../consts/OrganizaionTable/OrganizationTable";

interface IOrganizationModalProps extends FormikConfig<ISubscriptionWithOrg> {
    isEditSubscription: boolean;
    deleteSubscription: () => void;
}

const SubmitButton = (props: {
    isSubmitting: boolean;
    isEditSubscription: boolean;
    deleteSubscription: () => void;
}) => {
    const { isSubmitting, isEditSubscription, deleteSubscription } = props;

    return (
        <Stack direction="row" gap="10px">
            {isEditSubscription ? (
                <>
                    <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        style={{ color: "#ffffff" }}
                    >
                        Save
                    </Button>
                    <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="button"
                        color="primary"
                        style={{ color: "#ffffff" }}
                        onClick={deleteSubscription}
                    >
                        Delete subscription
                    </Button>
                </>
            ) : (
                <>
                    <Button
                        variant="contained"
                        disabled={isSubmitting}
                        type="submit"
                        color="primary"
                        style={{ color: "#ffffff", width: "100%" }}
                    >
                        Create subscription
                    </Button>
                </>
            )}
        </Stack>
    );
};

export const SubscriptionModal: React.FC<IOrganizationModalProps> = (props) => {
    const { deleteSubscription, isEditSubscription } = props;
    const [tabValue, setTabValue] = useState("1");

    const customStyles = {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderWidth: "1px",
            },
        },
        "& .MuiFormLabel-root": {
            color: "text.secondary",
            "&.Mui-focused": {
                color: "primary.main",
            },
        },
    };

    // generates accessibility props for tab switcher
    const a11yProps = (index: number) => {
        return {
            id: `simple-tab-${index}`,
            "aria-controls": `simple-tabpanel-${index}`,
        };
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    return isEditSubscription ? (
        <>
            <Formik
                validationSchema={object().shape({
                    externalSubscriptionId: string().nullable().required("Subscription id is required"),
                })}
                {...props}
            >
                {({ handleChange, values, isSubmitting, errors }: FormikProps<ISubscriptionWithOrg>) => {
                    const hasId = Boolean(errors.externalSubscriptionId || errors.base);

                    return (
                        <Form>
                            <Stack gap="20px">
                                <FormControl sx={{ width: "100%" }}>
                                    <TextField
                                        name="externalSubscriptionId"
                                        onChange={handleChange}
                                        label="Subscription id"
                                        variant="outlined"
                                        value={values.externalSubscriptionId || ""}
                                        error={hasId}
                                        helperText={hasId ? errors.externalSubscriptionId : ""}
                                    />
                                </FormControl>

                                <FormControl sx={{ width: "100%" }}>
                                    <InputLabel id="select-type-label">Status</InputLabel>
                                    <Select disabled value={values.status || ""} name="status" label="Status">
                                        {SUBSCRIPTION_STATUS.map((status) => (
                                            <MenuItem key={status.id} value={status.id}>
                                                {status.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>
                                <TextField
                                    name="currentPeriodStart"
                                    variant="outlined"
                                    label="Start Date"
                                    InputLabelProps={{ shrink: true }}
                                    sx={customStyles}
                                    type="datetime-local"
                                    value={
                                        values.currentPeriodStart
                                            ? format(new Date(values.currentPeriodStart), "yyyy-MM-dd'T'HH:mm:ss")
                                            : ""
                                    }
                                    disabled
                                />
                                <TextField
                                    name="currentPeriodEnd"
                                    variant="outlined"
                                    label="End Date"
                                    InputLabelProps={{ shrink: true }}
                                    sx={customStyles}
                                    type="datetime-local"
                                    value={
                                        values.currentPeriodEnd
                                            ? format(new Date(values.currentPeriodEnd), "yyyy-MM-dd'T'HH:mm:ss")
                                            : ""
                                    }
                                    disabled
                                />

                                <SubmitButton
                                    isSubmitting={isSubmitting}
                                    isEditSubscription={isEditSubscription}
                                    deleteSubscription={deleteSubscription}
                                />
                            </Stack>
                        </Form>
                    );
                }}
            </Formik>
        </>
    ) : (
        <>
            <TabContext value={tabValue}>
                <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <TabList onChange={handleTabChange} aria-label="Create subscription tabs">
                        <Tab value="1" label="Attach existing" {...a11yProps(1)} />
                    </TabList>
                </Box>

                <TabPanel value="1" style={{ padding: "20px 0 0 0" }}>
                    <Formik
                        validationSchema={object().shape({
                            externalSubscriptionId: string()
                                .nullable()
                                .required("A Stripe subscription id is required"),
                        })}
                        {...props}
                    >
                        {({ handleChange, values, isSubmitting, errors }: FormikProps<ISubscriptionWithOrg>) => {
                            const hasId = Boolean(errors.externalSubscriptionId || errors.base);
                            return (
                                <Form>
                                    <Stack gap="20px">
                                        <FormControl sx={{ width: "100%" }}>
                                            <TextField
                                                name="externalSubscriptionId"
                                                onChange={handleChange}
                                                label="Stripe Subscription id"
                                                variant="outlined"
                                                value={values.externalSubscriptionId || ""}
                                                error={hasId}
                                                helperText={hasId ? errors.externalSubscriptionId : ""}
                                            />
                                        </FormControl>
                                        <ErrorMessage name="email" render={(msg) => <div>{msg}</div>} />

                                        <SubmitButton
                                            isSubmitting={isSubmitting}
                                            isEditSubscription={isEditSubscription}
                                            deleteSubscription={deleteSubscription}
                                        />
                                    </Stack>
                                </Form>
                            );
                        }}
                    </Formik>
                </TabPanel>
            </TabContext>
        </>
    );
};
