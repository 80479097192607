import { createSelector, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { logoutUser } from "../actions/auth";
import { lastSeenAnnouncementSelector } from "./userSlice";
import { IAnnouncement } from "../../interfaces/entities/IAnnouncement";

export interface IAnnouncementState {
    announcementList: IAnnouncement[];
    announcementIds: string[];
}

const initialState: IAnnouncementState = {
    announcementList: [],
    announcementIds: [],
};

const announcementSlice = createSlice({
    name: "announcements",
    initialState,
    reducers: {
        setAnnouncementIds: (state, { payload }) => {
            state.announcementIds = payload;
        },
        setAnnouncements: (state, { payload }) => {
            state.announcementList = payload;
        },
        editAnnouncement: (state, { payload }) => {
            const selectedIndex = state.announcementList.findIndex((sc) => sc.id === payload.id);
            if (selectedIndex > -1) {
                const updated = [...state.announcementList];
                updated[selectedIndex] = payload;
                state.announcementList = updated;
            }
        },
        deleteAnnouncementById: (state, { payload }) => {
            const selectedIndex = state.announcementList.findIndex((sc) => sc.id === payload);
            if (selectedIndex > -1) {
                state.announcementList.splice(selectedIndex, 1);
            }
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
    },
});

export const { setAnnouncements, editAnnouncement, deleteAnnouncementById, setAnnouncementIds } =
    announcementSlice.actions;

export default announcementSlice.reducer;

const announcementsRootSelector = (state: RootState) => state.announcements;

// Selectors
export const announcementsSelector = createSelector(
    announcementsRootSelector,
    (state: IAnnouncementState) => state.announcementList || [],
);

export const announcementCountSelector = createSelector(
    [announcementsSelector, lastSeenAnnouncementSelector],
    (state: IAnnouncement[], timestamp) =>
        state.reduce((acc: string[], cv: IAnnouncement) => {
            if (timestamp && cv.releaseDate > timestamp) {
                acc.push(cv.id);
            }
            return acc;
        }, []),
);
