import React, { FC } from "react";
import {
    Stepper,
    Step,
    StepLabel,
    Box,
    Orientation,
    Typography,
    StepConnector,
    SxProps,
    Theme,
    StepButton,
} from "@mui/material";

interface ICommonStepperProps {
    steps: Array<{ label: string; description: string; id: string }>;
    activeStep: number;
    orientation?: Orientation;
    alternativeLabel?: boolean;
    isStepFailed?: (step: number) => boolean;
    isStepOptional?: (step: number) => boolean;
    skipped?: number[];
    completedSteps?: number[];
    showConnectorLine?: boolean;
    style?: SxProps<Theme>;
    nonLinear?: boolean;
    handleStep?: (step: number) => void;
    isStepClickDisabled?: boolean;
}

const CommonStepper: FC<ICommonStepperProps> = ({
    steps,
    activeStep,
    orientation = "horizontal",
    isStepFailed,
    alternativeLabel,
    skipped,
    isStepOptional,
    showConnectorLine = true,
    style,
    nonLinear = false,
    isStepClickDisabled = false,
    handleStep,
    completedSteps = [],
}) => {
    const isLabelClickable = !isStepClickDisabled && handleStep;
    return (
        <Stepper
            activeStep={activeStep}
            alternativeLabel={alternativeLabel}
            orientation={orientation}
            connector={showConnectorLine ? <StepConnector sx={{ flex: "1 0 0" }} /> : null}
            sx={{ ...style, flex: "1 0 0" }}
            nonLinear={nonLinear}
        >
            {steps.map((step, index) => {
                const stepProps: { optional?: React.ReactNode; error?: boolean; completed?: boolean } = {};
                const labelProps: {
                    optional?: React.ReactNode;
                    error?: boolean;
                } = {};

                if (isStepOptional && isStepOptional(index)) {
                    labelProps.optional = <Typography variant="caption">Optional</Typography>;
                }
                if (skipped && skipped?.includes(index)) {
                    stepProps.completed = false;
                }
                if (isStepFailed && isStepFailed(index)) {
                    labelProps.optional = (
                        <Typography variant="caption" color="error">
                            Alert message
                        </Typography>
                    );
                    labelProps.error = true;
                }
                if (step.description) {
                    labelProps.optional = <Typography variant="caption">{step.description}</Typography>;
                }
                return (
                    <Step id={step.id} key={step.id} completed={completedSteps.includes(index)} {...stepProps}>
                        <StepLabel
                            {...labelProps}
                            onClick={() => isLabelClickable && handleStep(index)}
                            sx={{ cursor: isLabelClickable ? "pointer" : "default" }}
                        >
                            {step.label}
                        </StepLabel>
                    </Step>
                );
            })}
        </Stepper>
    );
};

export default CommonStepper;
