import React from "react";
import { css } from "@emotion/css";
import styled from "styled-components";

import { AlignItems, Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { WHITE_OPACITY_70, YELLOW } from "../../consts/colors";

interface IMessageProps {
    search: string;
    filters?: string[];
}

const Headline6 = styled.h2`
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    color: ${WHITE_OPACITY_70} !important;
    margin-right: 4px;
`;

const SearchResultWrapper = styled(Headline6)`
    color: ${YELLOW} !important;
    margin-right: 0;
`;

const box = css`
    margin: 0 15px 16px 0;
`;

const endClass = css`
    margin-right: 4px;
`;

const filterClass = css`
    text-transform: capitalize;
    margin-right: 4px !important;
`;

export const SearchResult: React.FC<IMessageProps> = ({ search, filters }) => {
    return (
        <>
            <Flex direction={FlexDirection.Row} alignItems={AlignItems.Start} className={box}>
                <FlexItem>
                    <Headline6>Search results</Headline6>
                </FlexItem>
                <FlexItem className={endClass}>
                    <SearchResultWrapper>"{search}"</SearchResultWrapper>
                </FlexItem>
                <FlexItem>
                    <Headline6> among</Headline6>
                </FlexItem>
                <FlexItem>
                    {filters && filters.length > 0 && (
                        <Flex direction={FlexDirection.Row} alignItems={AlignItems.Center}>
                            <FlexItem>
                                <SearchResultWrapper>"</SearchResultWrapper>
                            </FlexItem>
                            {filters.map((filter) => (
                                <FlexItem key={`flex-item-${filter}`}>
                                    <SearchResultWrapper className={filterClass} key={filter}>
                                        {filter === "member" ? "users" : filter}
                                        {filters.indexOf(filter) !== filters.length - 1 && ", "}
                                    </SearchResultWrapper>
                                </FlexItem>
                            ))}
                            <FlexItem>
                                <SearchResultWrapper>"</SearchResultWrapper>
                            </FlexItem>
                        </Flex>
                    )}
                </FlexItem>
            </Flex>
        </>
    );
};
