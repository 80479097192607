import { isConsentRequired } from "../../services/utils";

/**
 * @type {UserConfig}
 */
const pluginConfig = {
    current_lang: "en",
    mode: isConsentRequired() ? "opt-in" : "opt-out",
    autoclear_cookies: true,
    page_scripts: true,
    force_consent: false,
    gui_options: {
        consent_modal: {
            layout: "box",
            position: "bottom right",
        },
    },

    onFirstAction(userPreferences: any, cookie: any) {
        console.log("onFirstAction", userPreferences, cookie);
        document.location.reload();
    },

    onAccept(savedCookieContent: any) {},

    onChange(cookie: any, changedCookieCategories: string[]) {
        console.log("onChange", cookie, changedCookieCategories);
        document.location.reload();
    },

    languages: {
        en: {
            consent_modal: {
                title: "We use cookies!",
                description:
                    "We use essential cookies to ensure its proper operation and tracking cookies to understand how you interact with it.",
                primary_btn: {
                    text: "Accept all",
                    role: "accept_all",
                },
                secondary_btn: {
                    text: "Manage Settings",
                    role: "settings",
                },
            },
            settings_modal: {
                title: "Cookie Settings",
                save_settings_btn: "Save settings",
                accept_all_btn: "Accept all",
                close_btn_label: "Close",
                cookie_table_headers: [{ col1: "Name" }, { col2: "Service" }],
                blocks: [
                    {
                        title: "Cookie usage 📢",
                        description:
                            'We use cookies to ensure the basic functionalities of the website and to enhance your online experience. You can choose for each category to opt-in/out whenever you want. For more details relative to cookies and other sensitive data, please read the full <a href="#" class="cc-link">privacy policy</a>.',
                    },
                    {
                        title: "Strictly necessary cookies",
                        description:
                            "These cookies are essential for the proper functioning of my website. Without these cookies, the website would not work properly",
                        toggle: {
                            value: "necessary",
                            enabled: true,
                            readonly: true, // cookie categories with readonly=true are all treated as "necessary cookies"
                        },
                    },
                    {
                        title: "Performance and Analytics cookies",
                        description:
                            "These cookies collect information about how you use the website, which pages you visited and which links you clicked on. All of the data is anonymized and cannot be used to identify you",
                        toggle: {
                            value: "analytics",
                            enabled: !isConsentRequired(),
                            readonly: false,
                        },
                        cookie_table: [
                            {
                                col1: `^amp_`,
                                col2: "Amplitude analytics",
                                is_regex: true,
                            },
                            {
                                col1: "^hj",
                                col2: "Hotjar",
                                is_regex: true,
                            },
                            {
                                col1: "^intercom-",
                                col2: "Intercom",
                                is_regex: true,
                            },
                        ],
                    },
                    {
                        title: "More information",
                        description:
                            "For any queries in relation to our policy on cookies and your choices,<br /> please contact your account admin or Prescient representative.",
                    },
                ],
            },
        },
    },
};

export default pluginConfig;
