import React from "react";

interface ISparkline {
    data: number[];
    width: number;
    height: number;
    color: string;
    margin: number;
    style: any;
}

const dataToPoints = ({
    data,
    width = 1,
    height = 1,
    margin = 0,
}: Pick<ISparkline, "data" | "width" | "height" | "margin">) => {
    const max = Math.max.apply(Math, data);
    const min = Math.min.apply(Math, data);
    const len = data.length;
    const vfactor = (height - margin * 2) / (max - min || 2);
    const hfactor = (width - margin * 2) / (len - (len > 1 ? 1 : 0));

    return data.map((d: number, i: number) => ({
        x: i * hfactor + margin,
        y: (max === min ? 1 : max - d) * vfactor + margin,
    }));
};

const Sparklines = ({ data, width, height, margin, color, style }: ISparkline) => {
    if (data.length === 0) { return null; }
    const points = dataToPoints({ data, width, height, margin });
    const linePoints = points.map((p) => [p.x, p.y]).reduce((a, b) => a.concat(b));
    const lineStyle = {
        stroke: color || style.color || "slategray",
        strokeWidth: style.strokeWidth || 1,
        fill: style.fill || "none",
    };
    const svgOpts = {
        width,
        height,
        viewBox: `0 0 ${width} ${height}`,
        preserveAspectRatio: "none",
    };

    return (
        <svg {...svgOpts}>
            <g>
                <polyline points={linePoints.join(" ")} style={lineStyle} />
            </g>
        </svg>
    );
};

export const SparklineChart = ({ data, color }: { data: number[]; color: string }) => {
    return (
        <Sparklines
            data={data.slice(-20)}
            width={60}
            height={25}
            margin={2}
            color={color}
            style={{ fill: "none", strokeWidth: 2 }}
        />
    );
};
