import { FC, ReactElement } from "react";
import { Dialog, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { SVGIconRenderer } from "../../../SVGIconRenderer/SVGIconRenderer";

interface IGoalDialog {
    openCreateGoalModal: boolean;
    closeGoalModal: () => void;
    title: string;
    subTitle?: string;
    content: ReactElement;
    style?: any;
}

export const GoalDialog: FC<IGoalDialog> = ({
    openCreateGoalModal,
    closeGoalModal,
    title,
    content,
    subTitle,
    style,
}) => {
    return (
        <Dialog sx={style} open={openCreateGoalModal}>
            <Stack gap="30px" p="30px">
                <Stack gap="20px">
                    <Stack direction="row" justifyContent="space-between">
                        <Typography variant="h3">
                            {title}
                        </Typography>
                        <Typography onClick={closeGoalModal}>
                            <SVGIconRenderer icon="closeIcon" />
                        </Typography>
                    </Stack>
                    {subTitle && <Typography>{subTitle}</Typography>}{" "}
                </Stack>
                {content}
            </Stack>
        </Dialog>
    );
};
