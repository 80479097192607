import { Avatar, Tooltip } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import { Flex, FlexDirection } from "../../../components/core/Flex/Flex";
import { useStylesForAvatarGrp } from "../../../consts/StylesForTables";
import { IMember } from "../../../interfaces/entities/IMember";
import { IOrganization } from "../../../interfaces/entities/IOrganization";
import { getComparator, stableSort } from "../../../utils/sort";
import { getDefaultRedirectPath } from "src/consts/path/path";
interface IMemberOrganization {
    memberCompanies: any;
    member: IMember;
    isForm?: boolean;
}

export const MemberOrganization: React.FC<IMemberOrganization> = ({ memberCompanies, member, isForm }) => {
    const classes = useStylesForAvatarGrp();

    const getOrgWithRole = (company: any, role: any) => {
        if (role === "member") {
            return `${company.displayName} - Viewer`;
        }
        return `${company.displayName} - ${role.charAt(0).toUpperCase() + role.slice(1)}`;
    };

    const getOrganizations = (orgMember: any, company: any, isGroup: boolean = false) => {
        let label = "";
        if (orgMember.companies && orgMember.companies.length > 0) {
            if (isGroup) {
                const sortedCompanyByName = stableSort(memberCompanies.slice(5), getComparator("asc", "name"));
                sortedCompanyByName.forEach((c: any) => {
                    if (orgMember.companies.includes(c.id)) {
                        const rolesByCompanyId = orgMember.rolesByCompanyId || {};
                        const roleObj: any = Object.values(rolesByCompanyId).find(
                            (role: any) => role.companyId === c.id,
                        );
                        label = label + getOrgWithRole(c, (roleObj && roleObj.role) || "") + "\n";
                    }
                });
                return <span style={{ whiteSpace: "pre-line" }}>{label}</span>;
            }
            return getOrgWithRole(company, orgMember.rolesByCompanyId[company.id].role);
        } else {
            return "No organization";
        }
    };

    return (
        <Flex className={classes.avatarGroupClasses} direction={FlexDirection.RowReverse}>
            {memberCompanies.length ? (
                memberCompanies.map((c: IOrganization, index: number) => {
                    return index < 5 ? (
                        c.avatarMediumUrl && !c.avatarMediumUrl.match(/missing_avatar/) ? (
                            <Tooltip placement="top" arrow key={c.code} title={getOrganizations(member, c)}>
                                <Link to={`/org/${c.code}${getDefaultRedirectPath(c)}`}>
                                    <Avatar src={c.avatarMediumUrl} className={classes.avatarClass} />
                                </Link>
                            </Tooltip>
                        ) : (
                            <Tooltip placement="top" arrow key={c.code} title={getOrganizations(member, c)}>
                                <Link to={`/org/${c.code}${getDefaultRedirectPath(c)}`}>
                                    <Avatar className={`${classes.avatarClass} ${classes.customAvatarBG}`}>
                                        {c.displayName ? c.displayName[0].toUpperCase() : ""}
                                    </Avatar>
                                </Link>
                            </Tooltip>
                        )
                    ) : (
                        index === 5 && (
                            <Tooltip placement="top" arrow key={c.code} title={getOrganizations(member, c, true)}>
                                <Avatar
                                    className={`${classes.avatarClass} ${classes.customAvatarBG} ${classes.gropAvatar}`}
                                >
                                    +{memberCompanies.length - 5}
                                </Avatar>
                            </Tooltip>
                        )
                    );
                })
            ) : isForm ? (
                "-"
            ) : (
                <Tooltip placement="top" arrow title="No Organizations">
                    <span>No Organization</span>
                </Tooltip>
            )}
        </Flex>
    );
};
