import { createSlice, createSelector } from "@reduxjs/toolkit";
import { format } from "date-fns";
import { toDate } from "date-fns-tz";
import { Range } from "react-date-range";

import { DEFAULT_DATE_RANGE, getPastPeriod } from "../../components/DateRangeFilter/reactDateRangeUtils";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";

const initialState: { date: string; compareDate: string; minDate: Date | undefined; isCompareOn: boolean } = {
    date: JSON.stringify(DEFAULT_DATE_RANGE),
    compareDate: JSON.stringify(getPastPeriod(DEFAULT_DATE_RANGE, true)),
    minDate: undefined,
    isCompareOn: location.search.includes("comparison_start_date"),
};

const dateFilterSlice = createSlice({
    name: "dateFilter",
    initialState,
    reducers: {
        setCurrentDateFilter: (state, { payload }) => ({ ...state, date: payload.date }),
        setCompareDateFilter: (state, { payload }) => ({ ...state, compareDate: payload.compareDate }),
        setMinDate: (state, { payload }) => ({ ...state, minDate: payload.minDate }),
        setCompare: (state, { payload }) => ({ ...state, isCompareOn: payload.isCompareOn }),
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const { setCurrentDateFilter, setMinDate, setCompareDateFilter, setCompare } = dateFilterSlice.actions;

export default dateFilterSlice.reducer;

// Selectors
export const currentDateFilterSelector = (state: RootState) => state.dateFilter;

export const formattedDateSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.date);
    const formattedDate: Range[] = [
        {
            startDate: toDate(dateFilterArr[0].startDate),
            endDate: toDate(dateFilterArr[0].endDate),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const formattedCurrentDateStringSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.date);
    const formattedDate = [
        {
            startDate: format(toDate(dateFilterArr[0].startDate), "yyyy-MM-dd"),
            endDate: format(toDate(dateFilterArr[0].endDate), "yyyy-MM-dd"),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const formattedCompareDateSelector = createSelector(currentDateFilterSelector, (state) => {
    if (state.compareDate) {
        const dateFilterArr = JSON.parse(state.compareDate);
        const formattedDate: Range[] = [
            {
                startDate: toDate(dateFilterArr[0].startDate),
                endDate: toDate(dateFilterArr[0].endDate),
                key: dateFilterArr[0].key,
            },
        ];
        return formattedDate;
    }

    // we will default the time range if state.compareDate does not exist here.
    return getPastPeriod(DEFAULT_DATE_RANGE, true);
});

export const formattedCompareDateStringSelector = createSelector(currentDateFilterSelector, (state) => {
    const dateFilterArr = JSON.parse(state.compareDate);
    const formattedDate = [
        {
            startDate: format(toDate(dateFilterArr[0].startDate), "yyyy-MM-dd"),
            endDate: format(toDate(dateFilterArr[0].endDate), "yyyy-MM-dd"),
            key: dateFilterArr[0].key,
        },
    ];
    return formattedDate;
});

export const minDateSelector = createSelector(currentDateFilterSelector, (state) => state.minDate);
export const isCompareSelector = createSelector(currentDateFilterSelector, (state) => state.isCompareOn);
