import React, { memo, Fragment } from "react";
import { Chip, Typography, Theme } from "@mui/material";
import { Stack } from "@mui/system";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

const useStyles = (theme: Theme) => ({
    chipFilterWrapper: css({
        display: "flex",
        gap: "5px",
        flexWrap: "wrap",
        "& .filterChipClass": {
            backgroundColor: "#EDEFF1",
            padding: "6px 12px",
            height: "auto",
            minHeight: "32px",
            border: "1px solid #C4C4C4",
            borderRadius: "4px",
            "& svg": {
                color: "black !important",
                fontSize: "20px !important",
                marginLeft: "10px",
                marginRight: 0,
            },
            "& span": { padding: 0, overflowWrap: "break-word", whiteSpace: "normal", textOverflow: "clip" },
            [theme.breakpoints.down("xs")]: {
                fontSize: "12px",
                height: "auto",
            },
        },
    }),
});

const FilterChips = ({ filterChipsList, removeFilter }: { filterChipsList: any; removeFilter: any }) => {
    const classes = useStyles(useTheme());
    return (
        <Stack>
            <div className={classes.chipFilterWrapper}>
                {filterChipsList.map((c: any) => {
                    const valuesArr = c.values.split(",");
                    const isValuesOverflow = ["excluded_tactics", "excluded_channels"].includes(c.key)
                        ? valuesArr.length > 3
                        : false;
                    return (
                        c.values && (
                            <Chip
                                key={c.key}
                                data-cy={c.key}
                                label={
                                    <span style={{ display: "flex", alignItems: "center", gap: "5px" }}>
                                        <Typography fontWeight="bold" component="span" variant="body2">
                                            {c.label}
                                        </Typography>
                                        <Typography
                                            variant="body2"
                                            color="text.secondary"
                                            component="span"
                                        >{` ${c.condition} `}</Typography>
                                        {c.label === "Campaign name" ? (
                                            <>
                                                {valuesArr.map((val: string, i: number, arr: any[]) => (
                                                    <Fragment key={val}>
                                                        <b>{val}</b>
                                                        {i !== arr.length - 1 && <span> & </span>}
                                                    </Fragment>
                                                ))}
                                            </>
                                        ) : (
                                            <Typography fontWeight="bold" component="span" variant="body2">
                                                {isValuesOverflow
                                                    ? `${valuesArr.slice(0, 3).join(", ")} ...`
                                                    : c.values}
                                            </Typography>
                                        )}
                                    </span>
                                }
                                deleteIcon={<SVGIconRenderer icon="closeCircleIcon" height="16" width="16" />}
                                onDelete={() => removeFilter(c.key)}
                                className="filterChipClass"
                            />
                        )
                    );
                })}
            </div>
        </Stack>
    );
};

export default memo(FilterChips);
