import { FC, useEffect, useState, useCallback } from "react";
import { Button, Card, CircularProgress, Tab, Tabs, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { css } from "@emotion/css";
import { Theme } from "@mui/material";
import { useTheme } from "@mui/system";
import GolfCourseOutlinedIcon from "@mui/icons-material/GolfCourseOutlined";
import AddOutlinedIcon from "@mui/icons-material/AddOutlined";
import AddIcon from "@mui/icons-material/Add";

import { IGoal } from "../../../interfaces/entities/IGoal";
import {
    CONTEXT_OPTION,
    ContextType,
    GOAL_TYPE_OPTION,
    INITAL_GOAL,
    LAST_DAYS_COUNT,
} from "../../../consts/HomePage.ts/HomePage";
import { selectCurrentlyViewingId } from "../../../reduxState/slices/organizationSlice";
import { userIdSelector } from "../../../reduxState/slices/userSlice";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import {
    useCreateGoalMutation,
    useDeleteGoalMutation,
    useEditGoalMutation,
    useLazyGetGoalsQuery,
} from "../../../reduxState/apis/goalApi";
import { CreateGoalForm } from "./GoalModal/CreateGoalForm";
import { GoalDialog } from "./GoalModal/GoalDialog";
import { DetailGoalModal } from "./GoalModal/DetailGoalModal";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { getGoalListWithContext } from "../../../services/goal/goal";
import { getReportingDateObj } from "../../../services/optimizationPage/optimization";
import { useLazyGetPerformancePageQuery } from "../../../reduxState/apis/performancePageApi";
import { getPaidDataSources } from "../../../consts/performancePaidPage/performancePaidPage";
import { GoalItem } from "./GoalItem";
import { IGoalItem } from "../../../interfaces/IGoal/IGoalItem";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { DashboardCardHeader } from "../CardComponents/CardHeader/CardHeader";
import { IDictionary } from "src/interfaces/IDictionary";
import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { HomePagePartialCard } from "../CardComponents/HomePagePartialCard";

export const goalStyle = (theme: Theme) => ({
    paperContainer: css({
        border: "1px solid rgba(0, 0, 0, 0.12)",
    }),
    cardHeader: css({ borderBottom: "3px solid #707375", padding: "16px 10px 16px 0" }),
    cardContent: css({
        minHeight: "100px",
        maxHeight: "975px",
    }),
    formSimpleLabel: css({ color: "#000000", fontSize: "15px" }),
    formInput: css({ width: "220px" }),
    error: css({
        color: "#F44336",
        margin: "3px 14px 0",
    }),
    connectorImage: css({
        width: "32px!important",
        height: "32px!important",
        marginRight: "10px",
    }),
    actionBtn: css({ textTransform: "initial", height: "54px", fontSize: "15px" }),
    loader: css({
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
    }),
    heading: css({
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
        borderBottom: "3px solid rgb(112, 115, 117)",
        marginBottom: "15px",
        height: "68px",
    }),
});

interface IGoals {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const Goals: FC<IGoals> = ({ tooltipRef }) => {
    const classes = goalStyle(useTheme());
    const analyticsService = useAnalyticsService();
    let pollInterval: NodeJS.Timeout | undefined;

    const [openCreateGoalModal, setOpenCreateGoalModal] = useState<boolean>(false);
    const [isOpenGoalDetailModal, setIsOpenGoalDetailModal] = useState<boolean>(false);
    const [selectedGoal, setSelectedGoal] = useState<IGoal | null>(INITAL_GOAL);
    const [goalState, setGoalState] = useState<IGoal[] | null>(null);

    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const userId = useSelector(userIdSelector);
    const supportedDataSources = useSelector(supportedDataSourcesSelector);

    const partialStateForHome = useSelector(partialStateSelectorForHome);

    const [createGoal] = useCreateGoalMutation();
    const [editGoal] = useEditGoalMutation();
    const [deleteGoal] = useDeleteGoalMutation();
    const [getGoals, { isLoading: isGoalListLoading }] = useLazyGetGoalsQuery();
    const [
        getPerformancePage,
        {
            data: allCurrentData = {
                data: [],
                meta: { campaignsDate: "", revenueData: [] },
            },
            isLoading: isLoadingPerformance,
        },
    ] = useLazyGetPerformancePageQuery();

    useEffect(() => {
        setGoalState(null);
        const lastDataDateObj = getReportingDateObj(LAST_DAYS_COUNT);
        if (supportedDataSources.length) {
            getPerformancePage({
                orgId: currentOrgId,
                data: {
                    ...lastDataDateObj,
                    connectors: getPaidDataSources(supportedDataSources),
                },
            });
        }
        getGoalsAction();
    }, [currentOrgId, supportedDataSources]);

    useEffect(() => {
        const hasNullReportingData = (goalState || []).some((goal) => goal.reportingData === null);
        if (hasNullReportingData) {
            pollGoalListing();
        }

        return () => {
            if (pollInterval) {
                clearInterval(pollInterval);
            }
        };
    }, [goalState]);

    const getGoalsAction = useCallback(async () => {
        const goals = await getGoals({ orgId: currentOrgId })
            .unwrap()
            .catch(() => {
                return [];
            });
        setGoalState(goals);
        return goals;
    }, [currentOrgId, getGoals]);

    const pollGoalListing = () => {
        pollInterval = setInterval(async () => {
            const goals = await getGoalsAction();
            const hasNullReportingData = goals.some((goal) => goal.reportingData === null);
            if (!hasNullReportingData) {
                clearInterval(pollInterval);
            }
        }, 3000);
    };

    const goalListWithContext: IGoalItem[] =
        goalState?.length && allCurrentData.data?.length ? getGoalListWithContext(goalState, allCurrentData.data) : [];

    const openGoalModal = (goal: IGoal) => {
        const sGoal = goalListWithContext.find((f) => f.id === goal.id) || INITAL_GOAL;
        setSelectedGoal(sGoal);
        setOpenCreateGoalModal(true);
    };

    const closeGoalModal = () => {
        setOpenCreateGoalModal(false);
        setIsOpenGoalDetailModal(false);
    };

    const goalEventProperty = (goal: IGoal) => {
        const selectedContext = CONTEXT_OPTION.filter((t) => t.value === goal.context)[0]?.label;
        const selectedGoalType = GOAL_TYPE_OPTION.filter((t) => t.value === goal.goalType)[0]?.label;

        const visibility: IDictionary = {
            common: "Public",
            personal: "Private",
        };

        let eventP = {
            "KPI selected": selectedGoalType,
            "Value entered": goal.goalValue,
            "Start date": goal.startDate,
            "End date": goal.endDate,
            "Context selected": selectedContext,
            Visibility: visibility[goal.visibility],
        };
        if (goal.context !== ContextType.PORTFOLIO) {
            eventP = {
                ...eventP,
                [`${selectedContext} selected`]: goal.campaignsOrChannels.map((c: any) => c.label).join(", "),
            };
        }
        return eventP;
    };

    const openGoalDetailModal = (goal: IGoal) => {
        const sGoal = goalListWithContext.find((f) => f.id === goal.id);
        if (sGoal) {
            setSelectedGoal(goal);
            const eventP = goalEventProperty(goal);
            analyticsService.logEvent("Goal Viewed", eventP);
        } else {
            setSelectedGoal(INITAL_GOAL);
        }
        setIsOpenGoalDetailModal(true);
    };

    const closeGoalDetailModal = () => {
        setIsOpenGoalDetailModal(false);
        setSelectedGoal(null);
    };

    const createGoalAction = async (values: IGoal, setSubmitting: (isSubmitting: boolean) => void) => {
        let updatedValues = { ...values };
        let response;
        if (values.context === "campaign") {
            updatedValues = { ...values, campaigns: values.campaignsOrChannels.map((c) => c.campaignId) };
        }
        if (values.context === "channel") {
            updatedValues = { ...values, channels: values.campaignsOrChannels.map((c) => c.connectorName) };
        }
        if (values.id) {
            response = editGoal({
                body: {
                    ...updatedValues,
                    creatorId: userId,
                },
                orgId: currentOrgId,
                goalId: values.id,
            });
        } else {
            response = createGoal({
                body: {
                    ...updatedValues,
                    creatorId: userId,
                },
                orgId: currentOrgId,
            });
        }

        if (response) {
            response.unwrap().then((goal) => {
                if (!values.id) {
                    const eventP = goalEventProperty(values);
                    analyticsService.logEvent("New Goal Created", eventP);
                    setGoalState([goal[0], ...(goalState || [])]);
                } else {
                    setGoalState(
                        (goalState || []).map((g) => {
                            if (g.id === values.id) {
                                return goal[0];
                            } else {
                                return g;
                            }
                        }),
                    );
                }
                closeGoalModal();
                setSubmitting(false);
            });
        }
    };

    const deleteGoalAction = async (goal: IGoal, setSubmitting: (isSubmitting: boolean) => void) => {
        await deleteGoal({
            orgId: currentOrgId,
            goalId: goal.id,
        }).then(() => {
            setGoalState((goalState || []).filter((g) => g.id !== goal.id));
            closeGoalModal();
            setSubmitting(false);
        });
    };

    const partialHomeElement = (
        <HomePagePartialCard
            icon={
                <GolfCourseOutlinedIcon
                    color="primary"
                    sx={{
                        width: "56px",
                        height: "56px",
                        opacity: "100%",
                    }}
                />
            }
            heading="Add Your First Goal"
            description={
                "It’s not too early to create your first goal so that Prescient AI can help you reach your goals."
            }
            actionButton={
                <Button
                    variant="contained"
                    color="primary"
                    size="small"
                    startIcon={<AddOutlinedIcon />}
                    onClick={() => openGoalModal(INITAL_GOAL)}
                >
                    Create Goal
                </Button>
            }
            style={{
                padding: "var(--4, 32px) 345px",
            }}
        />
    );

    const goalContent = (
        <>
            <Card elevation={0} sx={{ padding: 0, width: "462px" }}>
                <Tabs
                    className={classes.cardContent}
                    TabIndicatorProps={{
                        style: {
                            display: "none",
                        },
                    }}
                    orientation={"vertical"}
                    value={0}
                    variant="scrollable"
                    scrollButtons={false}
                    sx={{
                        ".MuiTabs-flexContainer": {
                            gap: "20px",
                        },
                        ".MuiTabs-scroller .Mui-selected": {
                            color: "rgba(0, 0, 0, 0.87)",
                        },
                    }}
                >
                    {isGoalListLoading || isLoadingPerformance || !goalState ? (
                        <Tab component="div" className={classes.loader} label={<CircularProgress disableShrink />} />
                    ) : goalListWithContext?.length ? (
                        goalListWithContext.map((goal) => (
                            <Tab
                                component="div"
                                key={goal.id}
                                sx={(theme) => ({
                                    padding: 0,
                                    textAlign: "left",
                                    color: "rgba(0, 0, 0, 0.87)",
                                    textTransform: "initial",
                                    maxWidth: "100%",
                                })}
                                label={<GoalItem key={goal.id} openEditGoalModal={openGoalDetailModal} goal={goal} />}
                            />
                        ))
                    ) : (
                        <Tab
                            component="div"
                            className={classes.loader}
                            sx={{ cursor: "default" }}
                            label={
                                <Typography textTransform="initial" color="text.secondary">
                                    You don't have any goal created yet.
                                </Typography>
                            }
                        />
                    )}
                </Tabs>
            </Card>
        </>
    );
    return (
        <HomepageCardContainer>
            <DashboardCardHeader
                title="Goals"
                tooltipRef={tooltipRef}
                tooltip="Set goals and track progress"
                instances={[
                    <Button
                        sx={{ minHeight: "59px", minWidth: "59px", padding: 0, borderRadius: "100%" }}
                        variant="outlined"
                        onClick={() => openGoalModal(INITAL_GOAL)}
                    >
                        <AddIcon fontSize="large" sx={{ color: "rgba(0, 0, 0, 1)" }} />
                    </Button>,
                ]}
            />
            {partialStateForHome ? partialHomeElement : goalContent}

            {openCreateGoalModal && selectedGoal && (
                <GoalDialog
                    openCreateGoalModal={openCreateGoalModal}
                    closeGoalModal={closeGoalModal}
                    title={selectedGoal.id ? "Edit Goal" : "Create Goal"}
                    subTitle="Enter the details for the goal you want to create."
                    content={
                        <CreateGoalForm
                            goal={goalListWithContext.filter((f) => f.id === selectedGoal.id)[0] || INITAL_GOAL}
                            onSubmit={createGoalAction}
                            closeGoalModal={closeGoalModal}
                            deleteGoalAction={deleteGoalAction}
                            classes={classes}
                            performanceData={allCurrentData?.data || []}
                        />
                    }
                />
            )}

            {isOpenGoalDetailModal && selectedGoal?.id && goalListWithContext?.length && (
                <GoalDialog
                    openCreateGoalModal={isOpenGoalDetailModal}
                    closeGoalModal={closeGoalDetailModal}
                    title="Goal Detail"
                    content={
                        <DetailGoalModal
                            closeGoalDetailModal={closeGoalDetailModal}
                            openGoalModal={openGoalModal}
                            selectedGoal={goalListWithContext.filter((f) => f.id === selectedGoal.id)[0]}
                            classes={classes}
                        />
                    }
                    style={{ ".MuiPaper-root": { width: "600px" } }}
                />
            )}
        </HomepageCardContainer>
    );
};
