import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

const initialState: any = [];

const notificationsSlice = createSlice({
    name: "notifications",
    initialState,
    reducers: {
        setNotifications: (state, { payload }) => payload,
    },
});

export const { setNotifications } = notificationsSlice.actions;

export default notificationsSlice.reducer;

// Selectors
export const notificationsSelector = (state: RootState) => state.notifications;
