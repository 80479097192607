import baseCreateApi from "./baseCreateApi";
import { formatArrayWithCamelCase } from "../../services/performancePage/performancePage";
import { IEventLogResponse } from "../../interfaces/IEventLogResponse/IEventLogResponse";
import { IEventLog } from "../../interfaces/entities/IEventLog";
interface IEventFormattedResponse {
    data: IEventLog[];
    pagination: {
        current: number;
        previous: number;
        next: number;
        per_page: number;
        pages: number;
        count: number;
    };
}

interface IFilterListResponse {
    data: { auditable_types: string[]; usernames: string[] };
}

interface IFilterList {
    auditableTypes: string[];
    changedBy: string[];
}

export const eventLogsTable = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getEventLogs: builder.query({
            query: ({ params }) => ({
                url: buildUrlFromParams(params),
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: IEventLogResponse): IEventFormattedResponse => {
                return {
                    data: formatArrayWithCamelCase(response.data),
                    pagination: response.meta.pagination,
                };
            },
        }),
    }),
});

export const getFilterOptions = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getFilters: builder.query({
            query: ({}) => ({
                url: "audit_logs/filter",
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: IFilterListResponse): { data: IFilterList } => {
                return {
                    data: {
                        auditableTypes: response.data.auditable_types,
                        changedBy: response.data.usernames.filter((name) => name !== null && name !== ""),
                    },
                };
            },
        }),
    }),
});

const buildUrlFromParams = (params: { page: number; rowsPerPage: number }) => {
    let url = `audit_logs?page=${params.page}&per_page=${params.rowsPerPage}`;
    const urlParams = new URLSearchParams(window.location.search);
    const changedBy: string | null = urlParams.get("changedBy");
    const auditableType: string | null = urlParams.get("auditableType");
    if (changedBy) {
        url += `&changed_by=${changedBy}`;
    }
    if (auditableType) {
        url += `&auditable_type=${auditableType}`;
    }
    return url;
};

export const { useLazyGetEventLogsQuery } = eventLogsTable;
export const { useLazyGetFiltersQuery } = getFilterOptions;
