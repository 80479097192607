import { css, cx } from "@emotion/css";
import isObject from "lodash/isObject";
import * as React from "react";
import { MediaBlender } from "../../../utils/mediaBlender";

import { AlignItems, IFlexBreakpoints, JustifyContent } from "../Flex/Flex";

interface IFlexItemProps {
    alignSelf?: AlignItems;
    justifySelf?: JustifyContent;
    basis?: string | number | IFlexBreakpoints;
    grow?: boolean;
    noShrink?: boolean;
    className?: string;
    style?: object;
    children?: React.ReactNode;
}

const basisClass = (basis: string | number | IFlexBreakpoints) => {
    if (isObject(basis)) {
        const basisXxs = basis.xxs;
        const basisXs = basis.xs;
        const basisSm = basis.sm;
        const basisMd = basis.md;
        const basisLg = basis.lg;
        const basisXlg = basis.xlg;

        const basisBase = basisXxs || basisXs || basisSm || basisMd || basisLg || basisXlg || "auto";

        return css`
            flex-basis: ${basisBase};

            ${basisXxs &&
            MediaBlender.up("xxs")(`
        flex-basis: ${basisXxs};
      `)}

            ${basisXs &&
            MediaBlender.up("xs")(`
        flex-basis: ${basisXs};
      `)}

      ${basisSm &&
            MediaBlender.up("sm")(`
        flex-basis: ${basisSm};
      `)}

      ${basisMd &&
            MediaBlender.up("md")(`
        flex-basis: ${basisMd};
      `)}

      ${basisLg &&
            MediaBlender.up("lg")(`
        flex-basis: ${basisLg};
      `)}

      ${basisXlg &&
            MediaBlender.up("xlg")(`
        flex-basis: ${basisXlg};
      `)}
        `;
    } else {
        return css`
            flex-basis: ${basis};
        `;
    }
};

const flexItemClass = (
    basis: string | number | IFlexBreakpoints,
    grow: boolean,
    noShrink: boolean,
    justifySelf: JustifyContent,
    alignSelf: AlignItems,
) => css`
    position: relative;
    flex-grow: ${grow ? 1 : 0};
    flex-shrink: ${noShrink ? 0 : 1};
    align-self: ${alignSelf};
    justify-self: ${justifySelf};
    min-width: 0;

    ${basis && basisClass(basis)}
`;

export const FlexItem: React.FC<IFlexItemProps> = (props) => {
    const {
        basis = "auto",
        children,
        className,
        justifySelf = JustifyContent.Start,
        alignSelf = AlignItems.Start,
        grow = false,
        noShrink = false,
        ...rest
    } = props;

    const flexItemClassName = className
        ? cx(flexItemClass(basis, grow, noShrink, justifySelf, alignSelf), className)
        : flexItemClass(basis, grow, noShrink, justifySelf, alignSelf);

    return (
        <div {...rest} className={flexItemClassName}>
            {children}
        </div>
    );
};
