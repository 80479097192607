import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";

export type Choice = "asc" | "desc";

export interface IHeadCell {
    id: keyof IDimCampaign;
    label: string;
}

export const CAMPAIGN_TACTICS_HEADER: IHeadCell[] = [
    {
        id: "channel",
        label: "Channel Name",
    },
    {
        id: "campaignId",
        label: "Id",
    },
    {
        id: "campaignName",
        label: "Campaign Name",
    },
];
