import React, { useEffect } from "react";
import { Skeleton } from "@mui/material";
import { Stack } from "@mui/system";
import { useSelector } from "react-redux";

import { IMetricAttributionTableValuesTransformed } from "../../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { useLazyGetAttributionDataQuery } from "../../../../../reduxState/apis/performancePageApi";
import {
    formattedCompareDateStringSelector,
    formattedCurrentDateStringSelector,
} from "../../../../../reduxState/slices/dateFilterSlice";
import { selectCurrentlyViewingId } from "../../../../../reduxState/slices/organizationSlice";
import { ISecondOrderEffectsMetrics } from "../../../../../interfaces/entities/ISecondOrderEffects";
import { NewCustomerChart } from "./NewCustomerChart";

interface INewCustomersMetric {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
}

export const NewCustomersMetric: React.FC<INewCustomersMetric> = ({ performanceCampaignData }) => {
    const { campaignId } = performanceCampaignData;
    const currentDate = useSelector(formattedCurrentDateStringSelector);
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const pastDate = useSelector(formattedCompareDateStringSelector);

    const [
        getAttributionData,
        {
            data: chartData = {
                data: [],
                meta: { comparisonData: [] },
            } as unknown as ISecondOrderEffectsMetrics,
            isUninitialized,
            isFetching,
            isSuccess,
        },
    ] = useLazyGetAttributionDataQuery();
    const isChartDataReceived = Boolean(!isFetching && !isUninitialized && isSuccess);

    useEffect(() => {
        if (campaignId) {
            getAttributionData({
                orgId: currentOrgId,
                campaignId,
                startDate: currentDate[0].startDate,
                endDate: currentDate[0].endDate,
                pastDate: pastDate[0],
                metrics: `metric_names[]=first_order_conversions&metric_names[]=second_order_conversions`,
            });
        }
    }, [campaignId, currentDate]);

    return (
        <Stack flexDirection="column" spacing={4} mt={3} pb={16}>
            {isChartDataReceived && chartData ? (
                <NewCustomerChart
                    chartData={chartData.data}
                    comparisonData={chartData.meta.comparisonData}
                    performanceCampaignData={performanceCampaignData}
                />
            ) : (
                <Skeleton
                    variant="rectangular"
                    animation="wave"
                    width="550px"
                    height="300px"
                    style={{ marginTop: "2rem" }}
                />
            )}
        </Stack>
    );
};
