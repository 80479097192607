import React from "react";
import { Message } from "../../components/Message/Message";
import underConstruction from "../../assets/underConstruction.png";

export const AccessDenied: React.FC = () => {
    return (
        <div>
            <Message
                title="Access Denied"
                subtitle="You do not have sufficient permissions to access this page."
                icon={underConstruction}
            />
        </div>
    );
};
