import { FC, useContext, useMemo, useState } from "react";
import { Typography, useTheme } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { TabPanel } from "@mui/lab";
import queryString from "query-string";
import { useNavigate } from "react-router";
import { useSelector } from "react-redux";

import { optimizationStyle } from "src/containers/Optimization/optimizationStyle";
import { AllocationErrorAlert } from "../AllocationErrorAlert";
import { getReadableJobError } from "src/reduxState/slices/scenarioSlice";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    MetricRate,
    Views,
} from "src/interfaces/entities/IScenario";
import {
    calculateTotalForSelectedCampaigns,
    getChannelGroupData,
    getTacticGroupData,
    getPerformanceDataForNoChange,
    getPerformanceDataWithAllocationData,
    getPredictionSentence,
    getRecommendationsTableHeading,
    getSummaryDataDependOnView,
    getTotalAllocation,
} from "src/services/optimizationPage/optimization";
import { IMetricAttributionTableValuesTransformed } from "src/interfaces/performanceDetails/IMetricAttributionTableResponse";
import { CustomizeRecommendationsModal, ICustomizeCampaigns } from "../CustomizeRecommendationsModal";
import { IScenarioCampaignBody } from "src/reduxState/apis/optimizationApi";
import { IDictionary } from "src/interfaces/IDictionary";
import { store } from "src/reduxState/stores/store";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { calculateROAS, handleFilteredData } from "src/services/utils";
import { FORECASTED_CAMPAIGNS_HEADERS, IForecastCampaignHeadCell } from "src/consts/optimizationPage/optimizationPage";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { IDataSource } from "src/interfaces/IData";
import { getComparator, stableSort } from "src/utils/sort";
import { PerdictedLiftTab } from "./OutcomeTabs/PerdictedLiftTab/PerdictedLiftTab";
import { OverviewTab } from "./OutcomeTabs/OverviewTab/OverviewTab";
import { CustomTabContext } from "src/components/CustomTabContext/CustomTabContext";
import { optimizationPredictedLiftSelector } from "src/reduxState/slices/featuresSlice";
import { BetaTooltip } from "src/components/ConnectorsComponent/NewConnectorComponents/BetaTooltip";

interface IOutcomeContent {
    isAllocationError: boolean;
    selectedScenario: IScenario;
    handleAlert: () => void;
    handleModelingRetry: () => void;
    isPollingAPi: boolean;
    isRecalculateScenarioDisabled: boolean;
    isPerformanceLoading: boolean;
    compareTo: Views;
    setCompareTo: React.Dispatch<React.SetStateAction<Views>>;
    compareToOption: Array<{
        lable: string;
        value: Views;
        disabled?: boolean;
    }>;
    recomendationsModalToggle: () => void;
    isRecomendationsModalOpen: boolean;
    saveCustomizationChanges: (updatedCampaignList: IScenarioCampaignBody[], allocationSpendTotal: number) => void;
    reallocateRemainingBudget: (updatedCampaignList: IScenarioCampaignBody[]) => void;
    isBulkLoading: boolean;
    historyJobError: string;
    campaignDataWithNoChange: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    campaignDataWithLastDays: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
}
export const handleSelectedColsHidden = (recommendationsTableCols: IForecastCampaignHeadCell[]) => {
    const latestQueryParamsObj = queryString.parse(window.location.search);
    const selectedCols: IForecastCampaignHeadCell[] = recommendationsTableCols.map((h) => {
        if (latestQueryParamsObj?.excluded_columns?.includes(h.id)) {
            return { ...h, hidden: true };
        }
        return h;
    });
    return selectedCols;
};

export const OutcomeContent: FC<IOutcomeContent> = (props) => {
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);

    const optimizationPredictedLift = useSelector(optimizationPredictedLiftSelector);

    const {
        isAllocationError,
        selectedScenario,
        handleAlert,
        handleModelingRetry,
        isPollingAPi,
        isRecalculateScenarioDisabled,
        isPerformanceLoading,
        compareTo,
        setCompareTo,
        compareToOption,
        recomendationsModalToggle,
        isRecomendationsModalOpen,
        saveCustomizationChanges,
        reallocateRemainingBudget,
        isBulkLoading,
        historyJobError,
        campaignDataWithNoChange,
        campaignDataWithLastDays,
    } = props;

    const navigate = useNavigate();
    const classes = optimizationStyle(useTheme());
    const [tabValue, setTabValue] = useState("1");
    const [cards, setCards] = useState(handleSelectedColsHidden([...FORECASTED_CAMPAIGNS_HEADERS]));

    const isNoChangeView = compareTo === Views.NoChange;

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
    };

    const handleTableCol = (column: any) => {
        const updatedCards = cards.map((c) => {
            if (c.id === column.id) {
                handleFilteredData("excluded_columns", { id: c.id }, navigate);
                return { ...c, hidden: !c.hidden };
            } else {
                return c;
            }
        });
        setCards(updatedCards);
    };

    const forecastMultipler =
        optimizationOutcomeContext && optimizationOutcomeContext.metricRate === MetricRate.Total
            ? selectedScenario.forecastTimeframe
            : 1;
    const isTotalView = forecastMultipler === selectedScenario.forecastTimeframe;

    const campaignDataBasedOnCompareTo = isNoChangeView ? campaignDataWithNoChange : campaignDataWithLastDays;
    const totalAllocationCampaigns = getTotalAllocation(campaignDataBasedOnCompareTo);

    let increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[] = [];
    let decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[] = [];

    campaignDataBasedOnCompareTo.forEach((campaign) => {
        if (campaign.change > 0) {
            decreaseSpendCampaigns = [...decreaseSpendCampaigns, campaign];
        } else {
            increaseSpendCampaigns = [...increaseSpendCampaigns, campaign];
        }
    });

    const campaignNameDict: IDictionary = {};
    const jobError: string =
        getReadableJobError(
            store.getState(),
            campaignDataBasedOnCompareTo.reduce((a, c) => {
                if (!c.campaignId) {
                    return a;
                }
                a[c.campaignId] = c.campaignName;
                return a;
            }, campaignNameDict),
        ) || historyJobError;

    const forecastCampaign = [
        ...stableSort(increaseSpendCampaigns, getComparator("asc", "campaignName")),
        ...stableSort(decreaseSpendCampaigns, getComparator("asc", "campaignName")),
    ] as IMetricAttributionTableValuesTransformedWithSpendAllocation[];

    const forecastCampaignWithAllSpend = forecastCampaign.map((c) => {
        const noChangeCampaign =
            campaignDataWithNoChange.find((noChange) => noChange.campaignId === c.campaignId)?.spend || 0;
        const lastDaysCampaign =
            campaignDataWithLastDays.find((lastDays) => lastDays.campaignId === c.campaignId)?.spend || 0;

        return {
            ...c,
            noChangeSpend: noChangeCampaign,
            lastDaysSpend: lastDaysCampaign,
        };
    });

    const channelGroupData = useMemo(
        () => getChannelGroupData(campaignDataBasedOnCompareTo),
        [campaignDataBasedOnCompareTo],
    );

    const tacticGroupData = useMemo(
        () => getTacticGroupData(campaignDataBasedOnCompareTo),
        [campaignDataBasedOnCompareTo],
    );

    const performanceDataTotal = calculateTotalForSelectedCampaigns(campaignDataBasedOnCompareTo);
    const getCompareLabel = compareToOption.find((op) => op.value === compareTo)?.lable || "";

    const recommendationsTableHeading = getRecommendationsTableHeading(
        selectedScenario.forecastTimeframe,
        getCompareLabel,
    );

    const exportRecommendationsTableHeading = [
        ...recommendationsTableHeading[selectedScenario.scenarioType],
        { label: "Change", id: "change" },
    ];

    const summaryArray = getSummaryDataDependOnView(totalAllocationCampaigns, performanceDataTotal);

    const predictionSentence = getPredictionSentence(
        summaryArray[1].change,
        summaryArray[2].change,
        summaryArray[0].percentageChange,
        selectedScenario,
    );

    const overviewTab = { value: "1", label: "Overview" };
    const outcomeTabs = optimizationPredictedLift
        ? [
              overviewTab,
              {
                  value: "2",
                  label: (
                      <Stack direction="row" gap="5px">
                          <Typography>Predicted Lift</Typography>
                          <BetaTooltip isTooltip={false} />
                      </Stack>
                  ),
              },
          ]
        : [overviewTab];

    return (
        <>
            <Box component="main" sx={{ flexGrow: 1, overflow: "auto" }}>
                <Stack
                    gap="50px"
                    className={`right-col ${classes.rightSection}`}
                    sx={{
                        padding: "30px 30px 150px 30px",
                        flexGrow: 1,
                        width: "100%!important",
                    }}
                >
                    <Stack>
                        {isAllocationError && selectedScenario?.allocationError && (
                            <AllocationErrorAlert msg={selectedScenario.allocationError} handleAlert={handleAlert} />
                        )}
                        <CustomTabContext tabValue={tabValue} tabs={outcomeTabs} handleChange={handleChange}>
                            <>
                                <TabPanel sx={{ marginTop: "50px", padding: 0 }} value="1">
                                    <OverviewTab
                                        jobError={jobError}
                                        handleModelingRetry={handleModelingRetry}
                                        isPollingAPi={isPollingAPi}
                                        totalAllocationCampaigns={totalAllocationCampaigns}
                                        selectedScenario={selectedScenario}
                                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                                        compareTo={compareTo}
                                        setCompareTo={setCompareTo}
                                        compareToOption={compareToOption}
                                        summaryArray={summaryArray}
                                        recommendationsTableHeading={recommendationsTableHeading}
                                        predictionSentence={predictionSentence}
                                        exportRecommendationsTableHeading={exportRecommendationsTableHeading}
                                        channelGroupData={channelGroupData}
                                        tacticGroupData={tacticGroupData}
                                        recomendationsModalToggle={recomendationsModalToggle}
                                        forecastCampaign={forecastCampaign}
                                        cards={cards}
                                        setCards={setCards}
                                        handleTableCol={handleTableCol}
                                        isRecalculateScenarioDisabled={isRecalculateScenarioDisabled}
                                        isPerformanceLoading={isPerformanceLoading}
                                        increaseSpendCampaigns={increaseSpendCampaigns}
                                        decreaseSpendCampaigns={decreaseSpendCampaigns}
                                    />
                                </TabPanel>
                                <TabPanel sx={{ marginTop: "50px", padding: 0 }} value="2">
                                    <PerdictedLiftTab
                                        scenario={selectedScenario}
                                        summaryArray={summaryArray}
                                        campaignDataBasedOnCompareTo={campaignDataBasedOnCompareTo}
                                    />
                                </TabPanel>
                            </>
                        </CustomTabContext>
                    </Stack>
                </Stack>
            </Box>
            {isRecomendationsModalOpen && selectedScenario && (
                <CustomizeRecommendationsModal
                    recomendationsModalToggle={recomendationsModalToggle}
                    selectedCampaigns={
                        forecastCampaignWithAllSpend.map((c: any) => ({
                            scenarioCampaignId: c.scenarioCampaignId,
                            channel: c.connectorName,
                            campaignId: c.campaignId,
                            allocationSpend: +c.allocationSpend.toFixed(0),
                            isLocked: c.isLocked,
                            campaignName: c.campaignName,
                            allocationPercentage: c.allocationPercentage,
                            isEdited: false,
                            noChangeSpend: c.noChangeSpend,
                            lastDaysSpend: c.lastDaysSpend,
                            connectorName: c.connectorName,
                        })) as ICustomizeCampaigns[]
                    }
                    totalAllocationCampaigns={totalAllocationCampaigns}
                    saveCustomizationChanges={saveCustomizationChanges}
                    reallocateRemainingBudget={reallocateRemainingBudget}
                    isBulkLoading={isBulkLoading}
                    selectedScenario={selectedScenario}
                />
            )}
        </>
    );
};
