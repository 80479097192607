import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { ANNOUNCEMENT_PAGE_TABLE_HEADERS, Choice, ICells } from "../../../consts/announcementPage/announcementPage";

interface IEnhancedHeaderProps {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    choice: Choice;
    choiceBy: string;
}

export const AnnouncementTableHeader = (props: IEnhancedHeaderProps) => {
    const { onRequestSort, choice, choiceBy } = props;
    const classes = useStylesForHeaderInTable();

    const createSortHandler = (property: keyof ICells) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={classes.headerRow}>
                {ANNOUNCEMENT_PAGE_TABLE_HEADERS.map((headCell, index) => (
                    <TableCell
                        key={headCell.id}
                        align={"left"}
                        sortDirection={choiceBy === headCell.id ? choice : false}
                        className={classes.tableCell}
                        width={headCell.id === "description" ? "750px" : "auto"}
                    >
                        <TableSortLabel
                            active={choiceBy === headCell.id}
                            direction={choiceBy === headCell.id ? choice : "asc"}
                            onClick={createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {choiceBy === headCell.id ? (
                                <Box component="span" style={{ display: "none" }}>
                                    {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                <TableCell key="actions" align="right" className={classes.tableCell}></TableCell>
            </TableRow>
        </TableHead>
    );
};
