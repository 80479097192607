import React, { FC, ReactElement, Ref, forwardRef, useState } from "react";
import {
    AppBar,
    Button,
    CircularProgress,
    Dialog,
    IconButton,
    InputLabel,
    List,
    ListItem,
    ListItemText,
    OutlinedInput,
    Slide,
    Toolbar,
    Typography,
} from "@mui/material";
import { Link, useNavigate } from "react-router-dom";
import queryString from "query-string";
import { Stack, useTheme } from "@mui/system";
import { TransitionProps } from "notistack";
import { css } from "@emotion/css";
import { Theme } from "@mui/material/styles";
import { LoadingButton } from "@mui/lab";
import { useSelector } from "react-redux";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query";
import { SerializedError } from "@reduxjs/toolkit";

import { selectCurrentlyViewingCode } from "src/reduxState/slices/organizationSlice";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import companyLogo from "src/assets/prescientMidnightLogoSecondary.png";
import { isCustomConnector } from "src/services/connectors/connectors";
import { ApiError } from "src/containers/Errors/ApiError";
import { ICustomConnectorFormData } from "src/interfaces/ICustomConnectorFormData";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";

const Transition = forwardRef(function Transition(
    props: TransitionProps & {
        children: ReactElement;
    },
    ref: Ref<unknown>,
) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const useStyles = (theme: Theme) => ({
    logoWrapperLink: css({
        display: "flex",
        alignItems: "center",
        [theme.breakpoints.down("md")]: {
            padding: 0,
            marginLeft: "3px",
        },
    }),
    logoClass: css({
        height: "45px",
        width: "140px",
        [theme.breakpoints.down("sm")]: {
            height: "32px",
            width: "100px",
        },
    }),
    appBarClass: css({
        paddingRight: "0!important",
        backgroundColor: "#FFFFFF",
        boxShadow: "inset 0px -1px 0px #D9D9D9",
    }),
    mainFormClass: css({
        backgroundColor: "white",
        padding: "30px",
        borderLeft: "1px solid #D9D9D9",
    }),
    footerClass: css({
        borderTop: "1px solid #D9D9D9",
        width: "100%",
        position: "sticky",
        bottom: 0,
        height: "64px",
        backgroundColor: "white",
    }),
    heading: css({
        fontSize: "1.3rem",
        marginTop: "2rem",
        color: "black",
    }),
    info: css({ color: "rgba(0, 0, 0, 0.6)" }),
});

interface ICreateCustomConnectorModal {
    children: ReactElement;
    submitCredentialsHandler: (formData: ICustomConnectorFormData) => void;
    displayName: string;
    isLoading: boolean;
    error: FetchBaseQueryError | SerializedError | undefined;
    isConfigSetAlready: boolean;
    instructions: any;
    handleformDataChange: (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => void;
    formData: ICustomConnectorFormData;
}

export const CreateCustomConnectorModal: FC<ICreateCustomConnectorModal> = ({
    children,
    submitCredentialsHandler,
    displayName,
    isLoading,
    error,
    isConfigSetAlready,
    instructions,
    handleformDataChange,
    formData,
}) => {
    const classes = useStyles(useTheme());
    const navigate = useNavigate();
    const orgCode = useSelector(selectCurrentlyViewingCode);
    let customError = "";

    const latestQueryParamsObj = queryString.parse(window.location.search);

    const handleApiChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        handleformDataChange(e);
    };

    const connectorName = (latestQueryParamsObj?.connectorName || "") as string;
    const connectorId = (latestQueryParamsObj?.connectorId || "") as string;

    const cancelAction = () => {
        navigate(`/org/${orgCode}/connectors`);
    };

    if (!connectorId || !connectorName) {
        customError = "Connector id/name is missing, Please provide valid params.";
    }

    if (connectorId && error) {
        if ("status" in error) {
            const e = error.data as any;
            customError = "error" in error ? error.error : e.errors[0].detail;
        } else {
            customError = error.message || "";
        }
    }

    if (connectorName && !isCustomConnector(connectorName)) {
        customError = `Couldn't find Connector with "name"=${connectorName}`;
    }

    const isKargo = displayName.includes("Kargo");
    const isPartnerize = displayName.includes("Partnerize");
    const isTatari = displayName.includes("Tatari");

    const isFormValid = isKargo
        ? formData.username && formData.password
        : isPartnerize
        ? formData.apiKey && formData.applicationKey
        : isTatari
        ? formData.companyName
        : formData.apiKey;

    const isDisabled = isLoading || !isFormValid;

    return (
        <Dialog
            PaperProps={{
                style: {
                    backgroundColor: "#f5f5f5",
                    overflow: "hidden",
                },
            }}
            fullScreen
            open={true}
            disableEscapeKeyDown
            TransitionComponent={Transition}
        >
            <AppBar className={classes.appBarClass} position="sticky">
                <Toolbar sx={{ justifyContent: "space-between" }}>
                    <Link to="/" className={classes.logoWrapperLink}>
                        <img src={companyLogo} className={classes.logoClass} />
                    </Link>
                    <IconButton sx={{ padding: 0 }} onClick={cancelAction} aria-label="close">
                        <SVGIconRenderer icon="closeIcon" />
                    </IconButton>
                </Toolbar>
            </AppBar>
            <main>
                {customError ? (
                    <ApiError errMsg={customError} />
                ) : (
                    <Stack direction="row">
                        <Stack width="40%">
                            <Stack
                                gap="0.5rem"
                                sx={{
                                    borderBottom: "1px solid #dfe1e5",
                                    padding: "15px 30px",
                                }}
                                direction="row"
                                alignItems="center"
                            >
                                <DataSourceAvatar
                                    programmaticName={connectorName}
                                    className={{ width: "50px", height: "50px" }}
                                />
                                <Typography color="black" variant="h5" fontSize="1.5rem">
                                    {displayName}
                                </Typography>
                            </Stack>
                            <Stack
                                gap="10px"
                                sx={{
                                    height: "calc(100vh - 210px)",
                                    overflowY: "auto",
                                    padding: "0 30px 30px 30px",
                                }}
                            >
                                <Typography className={classes.heading}>Setup Guide</Typography>
                                <Typography className={classes.info}>
                                    {`Follow our setup guide to connect ${
                                        displayName.includes("Kargo") ? "Kargo" : displayName
                                    }.`}
                                </Typography>
                                <Typography className={classes.heading}>Prerequisites</Typography>
                                {instructions?.prerequisites ? (
                                    typeof instructions.prerequisites === "string" ? (
                                        <Typography className={classes.info}>{instructions.prerequisites}</Typography>
                                    ) : (
                                        instructions.prerequisites.length && (
                                            <List
                                                className={classes.info}
                                                sx={{ listStyle: "decimal", pl: 3, typography: "body1" }}
                                            >
                                                {instructions.prerequisites.map((item: string) => (
                                                    <ListItem sx={{ display: "list-item" }}>
                                                        <ListItemText primary={item} />
                                                    </ListItem>
                                                ))}
                                            </List>
                                        )
                                    )
                                ) : (
                                    <Typography className={classes.info}>
                                        To connect {displayName}, you need a {displayName} account.
                                    </Typography>
                                )}

                                {children}
                            </Stack>
                        </Stack>
                        <Stack flexGrow="1" alignContent="center" className={classes.mainFormClass}>
                            {instructions?.form ? (
                                <>{instructions.form}</>
                            ) : (
                                <Stack>
                                    <InputLabel sx={{ color: "black" }}>API Key</InputLabel>
                                    <OutlinedInput
                                        name="apiKey"
                                        value={formData.apiKey}
                                        placeholder={
                                            isConfigSetAlready ? "***************************" : "Enter API Key"
                                        }
                                        onKeyDown={(e) => {
                                            if (e.key === "Enter" && formData.apiKey) {
                                                submitCredentialsHandler(formData);
                                            }
                                        }}
                                        onChange={handleApiChange}
                                        sx={{ maxWidth: "400px", marginTop: "10px" }}
                                    />
                                </Stack>
                            )}
                        </Stack>
                    </Stack>
                )}
            </main>

            <Stack className={classes.footerClass}>
                <Toolbar sx={{ Width: "100%", justifyContent: "end", gap: "20px" }}>
                    <Button
                        sx={{ textTransform: "inherit", fontSize: "15px", color: "black" }}
                        variant="outlined"
                        onClick={cancelAction}
                        disabled={isLoading}
                    >
                        Cancel
                    </Button>
                    <LoadingButton
                        type="submit"
                        sx={{ textTransform: "inherit", fontSize: "15px" }}
                        endIcon={isLoading ? <CircularProgress color={"inherit"} size={16} /> : <></>}
                        loading={isLoading}
                        disabled={isDisabled}
                        loadingPosition="end"
                        variant="contained"
                        onClick={() => submitCredentialsHandler(formData)}
                    >
                        Save & Test
                    </LoadingButton>
                </Toolbar>
            </Stack>
        </Dialog>
    );
};
