import { Typography } from "@mui/material";
import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { css } from "@emotion/css";

import { useLogoutMutation } from "../../reduxState/apis/authApi";
import { isSuperAdminSelector, userStateSelector } from "../../reduxState/slices/userSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { cleanUpUserInfo } from "../../services/auth/logout";
import { FooterForm } from "../../components/core/Form/FooterForm";
import { LOGIN_PATH } from "../../consts/path/path";

interface INoOrganizations {}

const useStyles = () => ({
    textClass: css({
        fontSize: "14px",
        margin: "10px 0 15px 0",
    }),
    btnClass: css({
        marginBottom: "16px",
    }),
});

const NoOrganizations: React.FC<INoOrganizations> = ({}) => {
    const classes = useStyles();
    const navigate = useNavigate();
    const [logout] = useLogoutMutation();
    const user = useSelector(userStateSelector);
    const analyticsService = useAnalyticsService();
    const isSuperAdmin = useSelector(isSuperAdminSelector);

    const handleLogout = () => {
        if (user.companies?.length) {
            navigate("/");
        } else {
            logout("").then(() => {
                cleanUpUserInfo(true);
                navigate(LOGIN_PATH);
            });
        }
    };

    useEffect(() => {
        if (isSuperAdmin || user.companies?.length) {
            navigate("/");
        }
        analyticsService.logEvent("Organization Missing Page Viewed", {});
    }, []);

    return (
        <div className="noOrganization">
            <Typography fontSize="1.5rem" color="#000000" fontWeight="700">
                Oops! Something went wrong...
            </Typography>
            <Typography className={classes.textClass}>
                Your account isn't a member of any organizations.
                <br />
                Please contact your account admin or Prescient representative.
            </Typography>
            <FooterForm
                submitBtnProps={{
                    text: "Got It",
                    onActionBtnClick: handleLogout,
                }}
            />
        </div>
    );
};

export default NoOrganizations;
