import React, { useState } from "react";
import { Theme } from "@mui/material/styles";
import OutlinedInput, { OutlinedInputProps } from "@mui/material/OutlinedInput";
import FormHelperText from "@mui/material/FormHelperText";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { css } from "@emotion/css";
import { useTheme } from "@mui/system";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

interface IPasswordProps extends OutlinedInputProps {
    classes?: any;
    helperText?: React.ReactNode;
}

const useStyles = (theme: Theme) => ({
    positionEnd: css({
        marginLeft: 0,
        padding: theme.spacing(0.5),
    }),
    input: css({
        borderRadius: `${theme.shape.borderRadius} 0 0 ${theme.shape.borderRadius}`,
    }),
});

export const Password: React.FC<IPasswordProps> = ({
    error,
    helperText,
    id,
    classes = {},
    fullWidth = true,
    ...rest
}) => {
    const defaultStyles = useStyles(useTheme());
    const [showPassword, setShowPassword] = useState<boolean>(false);

    const togglePassword = () => {
        setShowPassword(!showPassword);
    };

    const getType = () => {
        return showPassword ? "text" : "password";
    };
    const inputId: string = id || `field-${name}`;
    const inputProps = {
        ...rest,
        error,
        id: inputId,
        title: "",
        type: getType(),
    };

    return (
        <FormControl className={classes.root || ""} variant="outlined" fullWidth>
            <InputLabel error={error} htmlFor={inputId}>
                {rest.label || "Password"}
            </InputLabel>
            <OutlinedInput
                {...inputProps}
                classes={{
                    root: defaultStyles.input,
                }}
                endAdornment={
                    <InputAdornment
                        classes={{
                            positionEnd: defaultStyles.positionEnd,
                        }}
                        position="end"
                    >
                        <IconButton
                            aria-label="toggle password visibility"
                            onClick={togglePassword}
                            edge="end"
                            size="large"
                        >
                            {showPassword ? <SVGIconRenderer icon="eyeIcon" /> : <SVGIconRenderer icon="eyeOffIcon" />}
                        </IconButton>
                    </InputAdornment>
                }
            />
            {helperText && (
                <FormHelperText error={error} id="password-error">
                    {helperText}
                </FormHelperText>
            )}
        </FormControl>
    );
};
