import { useContext, useEffect } from "react";
import { UNSAFE_NavigationContext as NavigationContext, Path } from "react-router-dom";

const useConfirmExit = (confirmExit: (nextLocation: Partial<Path>) => boolean, when = true) => {
    const { navigator } = useContext(NavigationContext);

    useEffect(() => {
        if (!when) {
            return;
        }

        const originalPush = navigator.push;

        navigator.push = (...args: Parameters<typeof originalPush>) => {
            const pathInfo = args[0] as Partial<Path>;
            const result = confirmExit(pathInfo);

            if (result !== false) {
                originalPush(...args);
            } else {
                originalPush({
                    pathname: location.pathname,
                });
            }
        };

        return () => {
            navigator.push = originalPush;
        };
    }, [navigator, confirmExit, when]);
};

export const useBlocker = (handleBlockedNavigation: (nextLocation: Partial<Path>) => boolean, when = true) => {
    useEffect(() => {
        if (when) {
            window.onbeforeunload = () => "";
        }
        return () => {
            window.onbeforeunload = null;
        };
    }, [when]);

    useConfirmExit(handleBlockedNavigation, when);
};
