import { IDictionary } from "../../interfaces/IDictionary";

export const getFacebookAdsLink = (campaignId: string, accountId: string) => {
    return `https://business.facebook.com/adsmanager/manage/campaigns/edit?act=${accountId}&selected_campaign_ids=${campaignId}`;
};
export const getGoogleAdsLink = (campaignId: string) => {
    return `https://ads.google.com/aw/adgroups?campaignId=${campaignId}`;
};

export const SUPPORTED_EXTERNAL_PLATFORM_LINKS = ["facebook_ads", "google_ads", "adwords"];
export const EXTERNAL_LINK_MAPPINGS: IDictionary = {
    facebook_ads: getFacebookAdsLink,
    google_ads: getGoogleAdsLink,
    adwords: getGoogleAdsLink,
};
export const getManageCampaignLink = (
    campaignConnectorName: string,
    campaignId: string,
    accountId: string | number,
) => {
    const func = EXTERNAL_LINK_MAPPINGS[campaignConnectorName];
    return func && func(campaignId, accountId);
};
