import { IMember } from "../../interfaces/entities/IMember";
import { UserRole } from "../../enums/UserRole";

export const isSelect = (id: number | string, selectedMembers: Array<number | string>) => {
    return selectedMembers.includes(+id);
};

export const generateFilterValue = (filter: number) => {
    switch (filter) {
        case 0:
            return "all";
        case 1:
            return "superadmin";
        default:
            return "";
    }
};

export const getFilteredValue = (
    members: IMember[] | undefined,
    filterToParse: number,
    selectedMembers: Array<number | string>,
) => {
    const filter = generateFilterValue(filterToParse);
    let filteredValue;
    if (members && members.length > 0) {
        filteredValue = members.filter(
            (member) => member.id && (!filter || filter === "all" || filter === member.role),
        );
    }
    if (filteredValue) {
        return filteredValue.map((member: IMember) => ({
            ...member,
            isChecked: isSelect(member.id, selectedMembers),
        }));
    }

    return [];
};

export const getSearchedAndFilteredMembers = (members: IMember[], search: string, filter: number) => {
    if (members && members.length) {
        let resultMembers = members;
        if (search) {
            resultMembers = resultMembers.filter((member: IMember) =>
                `${member.firstName.toLowerCase()} ${member.lastName.toLowerCase()} ${member.email.toLowerCase()}`.includes(
                    search.toLowerCase(),
                ),
            );
        }
        if (filter) {
            if (generateFilterValue(filter) === UserRole.SUPERADMIN) {
                resultMembers = resultMembers.filter((member: IMember) => member.isSuperadmin === true);
            } else {
                resultMembers = resultMembers.filter(
                    (member: IMember) => member.role && member.role.toLowerCase() === generateFilterValue(filter),
                );
            }
        }

        return resultMembers;
    }

    return [];
};
