import { IGetSettingsResponse } from "../../interfaces/settings/settingsResponse";
import { keysToCamelCase } from "../../utils/format";
import baseCreateApi from "./baseCreateApi";

export interface ISettingsBody {
    notificationId: number;
    userId: string;
    enable: boolean;
}

export const settings = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getSettings: builder.query({
            query: () => ({
                url: `user/settings/email`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: IGetSettingsResponse) => {
                return response.data ? response.data.map((d) => keysToCamelCase(d)) : [];
            },
        }),
        editSettings: builder.mutation({
            query: (queryArgs: { body: ISettingsBody }) => ({
                url: `user/settings/email`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            notification_id: queryArgs.body.notificationId,
                            user_id: queryArgs.body.userId,
                            enable: queryArgs.body.enable,
                        },
                        type: "user_settings",
                    },
                }),
            }),
        }),
    }),
});

export const { useLazyGetSettingsQuery, useEditSettingsMutation } = settings;
