import { createSlice, createSelector } from "@reduxjs/toolkit";
import { ActivePerformanceTabs } from "../../enums/ActivePerfomanceTabs";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";
import { IEmail } from "../../interfaces/entities/IEmail";

const initialState: Array<{
    data: IEmail;
    isMinimize: boolean;
    activeTab: ActivePerformanceTabs;
    pastData: IEmail;
}> = [];

const emailInsightsModalSlice = createSlice({
    name: "emailInsightsModal",
    initialState,
    reducers: {
        setEmailInsightsModal: (state, { payload: newInsightsModal }) => {
            let updatedState = [...state];
            // if window size < 1300 then always open one modal at a time otherwise replace second modal with new one
            if (state.length < 2) {
                if (window.innerWidth < 1300) {
                    updatedState = [newInsightsModal];
                } else {
                    updatedState = [...state, newInsightsModal];
                }
            } else {
                updatedState = [state[0], newInsightsModal];
            }
            const uniqueModalsByCampaignIds = [
                ...new Map(updatedState.map((item) => [item.data.campaignId, item])).values(),
            ];
            return uniqueModalsByCampaignIds;
        },
        closeEmailInsightsmodal: (state, { payload: closeCampaignId }) => {
            return state.filter((d) => d.data.campaignId !== closeCampaignId);
        },
        handleEmailInsightsModalMinimization: (state, { payload: minimizeCampaignId }) => {
            return state.map((d) =>
                d.data.campaignId === minimizeCampaignId ? { ...d, isMinimize: !d.isMinimize } : d,
            );
        },
        setEmailInsightsModalActiveTab: (state, { payload }) => {
            const { activeTab, campaignId } = payload;
            return state.map((d) => (d.data.campaignId === campaignId ? { ...d, activeTab } : d));
        },
    },
    extraReducers: (builder) => {
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const {
    setEmailInsightsModal,
    closeEmailInsightsmodal,
    handleEmailInsightsModalMinimization,
    setEmailInsightsModalActiveTab,
} = emailInsightsModalSlice.actions;

export default emailInsightsModalSlice.reducer;

// Selectors
export const emailInsightsModalSelector = (state: RootState) => state.emailInsightsModal;

export const openedEmailInsightsModalIdsSelector = createSelector(emailInsightsModalSelector, (data) =>
    data.map((d) => d.data.campaignId),
);
