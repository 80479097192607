import React, { useEffect } from "react";
import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";

export const AccessRevoked = () => {
    const analyticsService = useAnalyticsService();

    useEffect(() => {
        analyticsService.logEvent("Access Revoked Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, []);

    return (
        <div>
            <NoCampaignPage
                title="Access Revoked"
                subTitle={
                    <>
                        Your account access has been revoked. <br />
                        Please contact <a href="mailto:mike@prescient-ai.io">mike@prescient-ai.io</a> to turn your
                        account access back on.
                    </>
                }
            />
        </div>
    );
};
