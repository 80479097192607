import { TableBody } from "@mui/material";
import { useTheme } from "@mui/system";
import { format } from "date-fns";
import React, { FC, Fragment, useCallback, useEffect, useMemo } from "react";
import { Range } from "react-date-range";
import { useSelector } from "react-redux";

import { connectorsSelector } from "src/reduxState/slices/connectorsSlice";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import {
    Choice,
    IHeadCell,
    PerformanceTab,
    PerformanceTabKeyMapping,
    TOTAL_IN_METRIC_ATTRIBUTION_TABLE,
    getAllPerformanceTableHeader,
} from "../../../consts/performancePaidPage/performancePaidPage";
import { IPerformancePercentageTableData } from "../../../interfaces/performanceDetails/IPerformancePercentage";
import { formattedCompareDateSelector } from "../../../reduxState/slices/dateFilterSlice";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { fixedPerformanceHeader, percentageCalculation } from "../../../services/performancePage/performancePage";
import { getTotalAmountForPerformanceTable } from "../../../services/utils";
import { getComparator, sortNegativeAtLast, stableSort } from "../../../utils/sort";
import { getRangeFormattedTitle } from "../../DateRangeFilter/reactDateRangeUtils";
import { useStylesForCampaignTable, useStylesForEmptyTableBody } from "../CampaignTable/CampaignTable";
import { CampaignTableHeader } from "../CampaignTable/CampaignTableHeader";
import { CampaignPercentageTableRow } from "./CampaignPercentageTableRow";

interface ICampaignPercentageTable {
    selectedTableColumns: IHeadCell[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    currentDateFilter: Range[];
    finalPercentagePerformanceData: IPerformancePercentageTableData;
    setOrder: React.Dispatch<React.SetStateAction<Choice>>;
    order: Choice;
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
    currentCampaignsDate: string;
    handleChannelClick: (row: any) => void;
    activePerformanceTab: PerformanceTab;
    isImagesInTable?: boolean;
}

export const CampaignPercentageTable: FC<ICampaignPercentageTable> = ({
    selectedTableColumns,
    page,
    setPage,
    currentDateFilter,
    finalPercentagePerformanceData,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    currentCampaignsDate,
    handleChannelClick,
    activePerformanceTab,
    isImagesInTable,
}) => {
    const classes = { ...useStylesForCampaignTable(useTheme()), ...useStylesForEmptyTableBody(useTheme()) };

    const rowsPerPage = useSelector(paginationCountSelector);
    const pastDateFilter = useSelector(formattedCompareDateSelector);
    const connectors = useSelector(connectorsSelector);

    const { finalData, currentFilteredData, pastFilteredData } = finalPercentagePerformanceData;
    const isGroupTabActive = useMemo(
        () => activePerformanceTab === PerformanceTab.Channels || activePerformanceTab === PerformanceTab.Tactics,
        [activePerformanceTab],
    );

    // take campaign data from channels for calculate totals
    const currentTotalAmount = useMemo(
        () =>
            getTotalAmountForPerformanceTable(
                isGroupTabActive ? currentFilteredData.flatMap((c) => c.campaigns || []) : currentFilteredData,
                true,
            ),
        [currentFilteredData, isGroupTabActive],
    );

    const pastTotalAmount = useMemo(
        () =>
            getTotalAmountForPerformanceTable(
                isGroupTabActive ? pastFilteredData.flatMap((c) => c.campaigns || []) : pastFilteredData,
                true,
            ),
        [pastFilteredData, isGroupTabActive],
    );

    const totalPercentageAmount = useMemo(
        () => percentageCalculation({ currentRow: currentTotalAmount, pastRow: pastTotalAmount }),
        [currentTotalAmount, pastTotalAmount],
    );

    const isNumericColumn = useMemo(
        () => getAllPerformanceTableHeader(connectors || []).some((h) => h.id === orderBy && h.numeric),
        [connectors, orderBy],
    );

    useEffect(() => {
        window.addEventListener("scroll", fixedPerformanceHeader);
        return () => {
            window.removeEventListener("scroll", fixedPerformanceHeader);
        };
    }, []);

    const handleChangePage = useCallback(
        (event: unknown, newPage: number) => {
            setPage(newPage);
        },
        [setPage],
    );

    const handleChangeRowsPerPage = useCallback(() => {
        setPage(0);
    }, [setPage]);

    const handleRequestSort = useCallback(
        (event: React.MouseEvent<unknown>, property: string) => {
            const isAsc = orderBy === property && order === "asc";
            setOrder(isAsc ? "desc" : "asc");
            setOrderBy(property);
        },
        [orderBy, order, setOrder, setOrderBy],
    );

    const sortedData = useMemo(
        () =>
            sortNegativeAtLast(
                stableSort(
                    Object.values(finalData).map((c) => c.current),
                    getComparator(order, orderBy),
                ),
                order,
                orderBy,
                isNumericColumn,
            ).slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage),
        [finalData, order, orderBy, isNumericColumn, page, rowsPerPage],
    );

    return (
        <div style={{ position: "relative" }}>
            <div className={classes.tableBorderClass}></div>
            <CustomTableStructure
                paginationProps={{
                    count: Object.values(finalData).length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    extraInfo: currentCampaignsDate
                        ? `Last model run date: ${format(new Date(currentCampaignsDate), "MMM d, yyyy")}`
                        : "",
                    className: classes.paginationClass,
                }}
                tableProps={{ id: "performance-table", size: "small" }}
                className={classes.noBorderClass}
            >
                <CampaignTableHeader
                    selectedTableColumns={selectedTableColumns}
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                />
                <TableBody>
                    <CampaignPercentageTableRow
                        totalAmount={totalPercentageAmount}
                        row={TOTAL_IN_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle="Total"
                        isPercentRow={true}
                        activePerformanceTab={activePerformanceTab}
                    />
                    <CampaignPercentageTableRow
                        totalAmount={pastTotalAmount}
                        row={TOTAL_IN_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle={getRangeFormattedTitle(pastDateFilter)}
                        activePerformanceTab={activePerformanceTab}
                    />
                    <CampaignPercentageTableRow
                        totalAmount={currentTotalAmount}
                        row={TOTAL_IN_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                        firstColTitle={getRangeFormattedTitle(currentDateFilter)}
                        activePerformanceTab={activePerformanceTab}
                    />
                    {sortedData.map((currentRow: any) => {
                        const key = PerformanceTabKeyMapping[activePerformanceTab] || PerformanceTabKeyMapping.default;
                        const pastRow: any = finalData[currentRow[key]]?.past;

                        const campaignName =
                            activePerformanceTab === PerformanceTab.Channels
                                ? currentRow?.channelName ?? pastRow?.channelName
                                : activePerformanceTab === PerformanceTab.Tactics
                                ? currentRow?.tacticName ?? pastRow?.tacticName
                                : currentRow?.campaignName ?? pastRow?.campaignName;

                        const campaignId = currentRow?.[key]?.[key] ?? pastRow?.[key];

                        return (
                            <Fragment key={campaignId}>
                                <CampaignPercentageTableRow
                                    row={percentageCalculation({
                                        currentRow,
                                        pastRow,
                                    })}
                                    isBothRowAvail={currentRow && pastRow}
                                    selectedTableColumns={selectedTableColumns}
                                    isImagesInTable={isImagesInTable}
                                    isPercentRow={true}
                                    firstColTitle={campaignName}
                                    allData={{ pastRow, currentRow }}
                                    activePerformanceTab={activePerformanceTab}
                                    handleChannelClick={handleChannelClick}
                                />
                                <CampaignPercentageTableRow
                                    row={pastRow}
                                    selectedTableColumns={selectedTableColumns}
                                    firstColTitle={getRangeFormattedTitle(pastDateFilter)}
                                    activePerformanceTab={activePerformanceTab}
                                />
                                <CampaignPercentageTableRow
                                    row={currentRow}
                                    selectedTableColumns={selectedTableColumns}
                                    firstColTitle={getRangeFormattedTitle(currentDateFilter)}
                                    activePerformanceTab={activePerformanceTab}
                                />
                            </Fragment>
                        );
                    })}
                </TableBody>
            </CustomTableStructure>
        </div>
    );
};
