import { GOOGLE_SHEETS } from "src/consts/connectors";
import { IDataSource, IDataSourceForm } from "src/interfaces/IData";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { IDataSourceInApiResponse } from "src/interfaces/api/IDataSourceResponse";
import { keysToCamelCase } from "src/utils/format";
import { backendUrl } from "../../utils/env";

export const transformDataSource = (data: IDataSourceInApiResponse[]) => {
    return data.map((item) => {
        const apiKeys = item.config_schema || {};

        return {
            ...keysToCamelCase(item),
            apiKeys:
                apiKeys.properties && Object.keys(apiKeys.properties).length
                    ? Object.entries(apiKeys.properties).map(([key, value]: any) => {
                          return {
                              fieldName: key,
                              dataType: value.type,
                              isRequired: apiKeys.required.includes(key),
                          };
                      })
                    : [],
        };
    });
};

const defaultAvatar = () => {
    return require(`../../assets/initialAvatar.png`);
};

export const getDataSourceIconImage = (dataSource: any) => {
    return dataSource?.iconUrl ? backendUrl.replace(/api\/$/, "") + dataSource.iconUrl : defaultAvatar();
};

export const getDataSourceImage = (dataSourcesByProgrammaticName: any, programmaticName?: string) => {
    if (!programmaticName) {
        return defaultAvatar();
    }

    const dataSource = dataSourcesByProgrammaticName[programmaticName.toLowerCase()];
    return getDataSourceIconImage(dataSource);
};

export const getSearchedAndFilteredDataSources = (dataSources: IDataSource[], search: string) => {
    if (dataSources && dataSources.length) {
        let resultdataSources = dataSources;
        if (search) {
            resultdataSources = resultdataSources.filter((dataSource: IDataSource) =>
                `${dataSource.name?.toLowerCase()} ${dataSource.programmaticName?.toLowerCase()}`.includes(
                    search.toLowerCase(),
                ),
            );
        }

        return resultdataSources;
    }

    return [];
};

export const getSearchedAndFilteredDataServices = (dataServices: IDataService[], search: string) => {
    if (dataServices && dataServices.length) {
        let resultDataServices = dataServices;
        if (search) {
            resultDataServices = resultDataServices.filter((dataSource: IDataService) =>
                `${dataSource.name?.toLowerCase()} ${dataSource.schema?.toLowerCase()}`.includes(search.toLowerCase()),
            );
        }

        return resultDataServices;
    }

    return [];
};

export const emptyDataSource = {
    name: "",
    programmaticName: "",
    dataSourceServiceId: "",
    dataSourceCategoryIds: [],
    description: "",
    beta: false,
    deprecated: false,
    pauseByDefault: false,
    colorCode: "",
    apiKeys: [],
    icon: "",
    dataSourceProviderId: 1,
    displayPerformance: false,
};

export const emptyDataService = {
    name: "",
    schema: "",
    dataSourceProviderId: "",
};

export const dataSourceServiceIsGoogleSheets = (dataSource: IDataSource | IDataSourceForm): boolean => {
    return dataSource.service === GOOGLE_SHEETS;
};

export const updateBodyDataSource = (values: IDataSourceForm) => {
    const configSchema = values.apiKeys.length
        ? {
              type: "object",
              required: values.apiKeys.filter((apiKey) => apiKey.isRequired).map((apiKey) => apiKey.fieldName),
              properties: values.apiKeys.reduce(
                  (properties, apiKey) => ({
                      ...properties,
                      [apiKey.fieldName]: { type: apiKey.dataType },
                  }),
                  {},
              ),
          }
        : {};

    return {
        name: values.name,
        programmatic_name: values.programmaticName,
        data_source_service_id: values.dataSourceServiceId,
        data_source_category_ids: values.dataSourceCategoryIds,
        description: values.description,
        beta: values.beta,
        deprecated: values.deprecated,
        pause_by_default: values.pauseByDefault,
        color_code: values.colorCode,
        icon: values.icon,
        config_schema: configSchema,
        display_performance: values.displayPerformance,
    };
};
