import { useSelector } from "react-redux";
import { userStateSelector } from "src/reduxState/slices/userSlice";
import hexcladImage from "src/assets/hexclad-case-study.png";
import { EmptyViewerWrapper } from "./EmptyViewerWrapper";

export const OnboardingViewerHomePage = ({ showPageHeader = false }: { showPageHeader?: boolean }) => {
    const currentUser = useSelector(userStateSelector);

    return (
        <EmptyViewerWrapper
            title={
                <>
                    Welcome, {currentUser.firstName}!<br /> Once we have data sources connected, we can begin!
                </>
            }
            description={
                <>
                    We’ve reached out to your administrator to set up the necessary data sources. <br /> In the
                    meantime, here is a sneak peek into what’s possible.
                </>
            }
            blog={{
                link: "https://www.prescient-ai.io/blog/hexclad-increased-revenue-roas",
                image: hexcladImage,
                writter: "Michael True • 21 Dec 2023",
                heading: "HexClad Gained 85% More Revenue and Increased ROAS 30% with Prescient AI",
                description:
                    "HexClad saw impressive returns following suggestions from our Optimizer, but they were also able to better align with their media buyers.",
            }}
            showPageHeader={showPageHeader}
        />
    );
};
