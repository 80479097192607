import React from "react";
import { TableRow, TableCell } from "@mui/material";
import { useSelector } from "react-redux";

import { useStylesForRowInTable } from "src/consts/StylesForTables";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { DATA_SOURCE_PROVIDERS } from "src/consts/dataSourcePage/dataSourcePage";
import { demoModeSelector } from "src/reduxState/slices/demoModeSlice";
import { TableActionCell } from "src/components/core/TableActionCell/TableActionCell";
import { stringToCapitalize } from "src/services/utils";

interface IDataServicesTableRow {
    dataService: IDataService;
    deleteClick: (e: React.MouseEvent<HTMLButtonElement>, dataSource: IDataService) => void;
    editClick: (e: React.MouseEvent<HTMLButtonElement>, dataSource: IDataService) => void;
}

export const DataServicesTableRow: React.FC<IDataServicesTableRow> = ({ dataService, deleteClick, editClick }) => {
    const classes = useStylesForRowInTable();
    const isDemoMode = useSelector(demoModeSelector);
    const provider = DATA_SOURCE_PROVIDERS[(dataService.dataSourceProviderId || "").toString()];

    return (
        <TableRow key={dataService.id} className={classes.bodyRow}>
            <TableCell>{stringToCapitalize(dataService.name)}</TableCell>
            <TableCell>{dataService.schema}</TableCell>
            <TableCell>{provider}</TableCell>
            {!isDemoMode && (
                <TableActionCell
                    actionIcons={[
                        { title: "Edit", onClick: editClick, data: dataService, icon: "editIcon2" },
                        { title: "Delete", onClick: deleteClick, data: dataService, icon: "trashIcon" },
                    ]}
                />
            )}
        </TableRow>
    );
};
