export enum ActivePerformanceTabs {
    Performance = "performance",
    Attribution = "attribution",
    Forecast = "forecast",
}

export enum ActivePerformanceTabsForEvent {
    performance = "Performance",
    attribution = "Attribution",
    forecast = "Forecasting",
}
