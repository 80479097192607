import { createSelector, createSlice } from "@reduxjs/toolkit";
import { IDataSource } from "../../interfaces/IData";
import { RootState } from "../stores/store";
import { IDictionary } from "src/interfaces/IDictionary";
import { CHART_COLORS, PRIMARY_COLOR } from "src/consts/colors";

const initialState: IDataSource[] = [];

export const getDataSourcesByProgrammaticNameList = ({ payload }: { payload: IDataSource[] }) => {
    return payload.reduce(
        (acc: any, item: IDataSource) => Object.assign(acc, { [item.programmaticName?.toLowerCase()]: item }),
        {},
    );
};

const supportedDataSourcesSlice = createSlice({
    name: "supportedDataSources",
    initialState,
    reducers: {
        setSupportedDataSources: (state, { payload }) => payload,
        addSupportedDataSources: (state, { payload }) => {
            state.push(payload);
        },
        editSupportedDataSources: (state, { payload }) => {
            return state.map((c: any) => {
                if (c.id === payload.id) {
                    return payload;
                } else {
                    return c;
                }
            });
        },
        deleteSupportedDataSourcesById: (state, { payload }) => {
            return state.filter((c: any) => c.id !== payload);
        },
    },
});

export const {
    setSupportedDataSources,
    addSupportedDataSources,
    deleteSupportedDataSourcesById,
    editSupportedDataSources,
} = supportedDataSourcesSlice.actions;

export default supportedDataSourcesSlice.reducer;

// Selectors
export const supportedDataSourcesSelector = (state: RootState) => state.supportedDataSources;

export const dataSourcesSelector = createSelector(supportedDataSourcesSelector, (sources: IDataSource[]) =>
    getDataSourcesByProgrammaticNameList({ payload: sources }),
);

export const availableDataSourcesSelector = createSelector([supportedDataSourcesSelector], (sources) =>
    sources.filter((c) => c.deprecated === false),
);

export const betaSupportedDataSourcesSelector = createSelector(
    supportedDataSourcesSelector,
    (sources: IDataSource[]) => {
        return sources.filter((c) => c.beta === true).map((c) => c.programmaticName);
    },
);

export const selectDataSourceByProgrammaticName = createSelector(
    [dataSourcesSelector, (state, programmaticName) => programmaticName],
    (dataSourcesSelector, programmaticName) => {
        const lowerCaseName = (programmaticName || "").toLowerCase();
        return dataSourcesSelector[lowerCaseName];
    },
);

export const selectDataSourceNameByProgrammaticName = createSelector(
    [dataSourcesSelector, (state, programmaticName) => programmaticName],
    (dataSourcesSelector, programmaticName) => {
        const lowerCaseName = (programmaticName || "").toLowerCase();
        return dataSourcesSelector[lowerCaseName]?.name
            ? dataSourcesSelector[lowerCaseName].name
            : lowerCaseName.replace(/[.,\/#!$%\^&\*;:{}=\-_`~()]/g, " ");
    },
);

export const dataSourcesByProgrammaticNameSelector = createSelector([supportedDataSourcesSelector], (state) =>
    state.reduce((acc: IDictionary, connector: IDataSource) => {
        acc[connector.programmaticName] = connector;
        return acc;
    }, {}),
);

export const dataSourcesByIdSelector = createSelector([supportedDataSourcesSelector], (state) =>
    state.reduce((acc: IDictionary, connector: IDataSource) => {
        acc[connector.id] = connector;
        return acc;
    }, {}),
);

export const selectDataSourceColor = createSelector(
    [dataSourcesByProgrammaticNameSelector, (state, connectorName) => connectorName],
    (dataSourcesByProgrammaticNameSelector, connectorName) => {
        if (!dataSourcesByProgrammaticNameSelector || !connectorName) {
            return PRIMARY_COLOR;
        }

        const connector = dataSourcesByProgrammaticNameSelector[connectorName.toLowerCase()];
        return connector?.colorCode || CHART_COLORS[connectorName.toLowerCase()] || PRIMARY_COLOR;
    },
);
