import React from "react";
import { CustomModal } from "../../CustomModal/CustomModal";
import { IConnector } from "../../../interfaces/IConnector";

interface IResyncConnectorModalProps {
    connector: IConnector;
    resyncLoading: boolean;
    closeModal: () => void;
    resyncConnector: (connector: IConnector) => Promise<void>;
}

export const ResyncConnectorModal: React.FC<IResyncConnectorModalProps> = ({
    closeModal,
    connector,
    resyncConnector,
    resyncLoading,
}) => {
    return (
        <CustomModal
            title="Historical Resync Connector?"
            subTitle={`Historical Resync of ${connector.connectorName} is time consuming.`}
            details={<></>}
            buttonName="Historical resync"
            handleAction={resyncConnector}
            selectedConnector={connector}
            closeModal={closeModal}
            disableBtn={resyncLoading}
            type="error"
        />
    );
};
