import { useEffect, useState } from "react";

const useAvatar = (avatarMediumUrl: string) => {
    const [avatarUrl, setAvatarUrl] = useState("");

    useEffect(() => {
        if (!avatarMediumUrl || avatarMediumUrl.match(/missing_avatar/)) {
            setAvatarUrl("");
            return;
        }

        const image = new Image();

        const handleLoad = () => setAvatarUrl(image.src);
        const handleError = () => setAvatarUrl("");

        image.addEventListener("load", handleLoad, { once: true });
        image.addEventListener("error", handleError, { once: true });
        image.src = avatarMediumUrl;

        // Cleanup
        return () => {
            image.removeEventListener("load", handleLoad);
            image.removeEventListener("error", handleError);
        };
    }, [avatarMediumUrl]);

    return avatarUrl;
};

export default useAvatar;
