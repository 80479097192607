import { Paper, SxProps, Theme } from "@mui/material";

import { DialogElements } from "./DialogElement";

export const CustomDialog = ({
    title,
    closeIcon,
    instanceSlot,
    actions,
    sx,
}: {
    title: JSX.Element | string;
    closeIcon: boolean;
    instanceSlot: JSX.Element;
    actions: {
        [key: number]: {
            label: string;
            onClick: () => void;
            style?: any;
            disabled?: boolean;
        };
    };
    sx?: SxProps<Theme>;
}) => {
    return (
        <Paper elevation={0} role="dialog" sx={{ width: "400px", ...sx }}>
            <DialogElements title={title} closeIcon={closeIcon} instanceSlot={instanceSlot} actions={actions} />
        </Paper>
    );
};
