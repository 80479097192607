import React, { Component } from "react";
import { ReactReduxContext } from "react-redux";
import { subscribe } from "../reduxState/subscribe";
import { IStateObserver } from "../interfaces/analytics/IStateObserver";
import { IActionListeners } from "../interfaces/analytics/IActionListeners";

interface IWithAnalyticsPassThroughProps {}

const withAnalytics =
    (valueObservers: IStateObserver, actionListeners: IActionListeners, invokeImmediately = true) =>
    (BaseComponent: React.ComponentType<IWithAnalyticsPassThroughProps>) => {
        class WithAnalytics extends Component {
            public static contextType = ReactReduxContext;
            public unsubscribe: () => void;
            public removeActionListenerList: Array<() => void> = [];
            public displayName: string = `WithAnalytics[${
                BaseComponent.displayName || BaseComponent.name || "Component"
            }]`;

            constructor(props: any, context: React.ContextType<typeof ReactReduxContext>) {
                super(props);
                const { store } = context;
                this.unsubscribe = subscribe(valueObservers, invokeImmediately)(store);
                this.removeActionListenerList = Object.keys(actionListeners).map((actionType) =>
                    // @ts-ignore
                    store.addActionListener(actionType, actionListeners[actionType]),
                );
            }

            public componentWillUnmount() {
                if (this.unsubscribe) {
                    this.unsubscribe();
                    this.removeActionListenerList.forEach((removeListener) => removeListener());
                }
            }

            public render() {
                return React.createElement(BaseComponent, this.props);
            }
        }

        return WithAnalytics;
    };

export default withAnalytics;
