import { generateGoalBody, transformGoal } from "../../services/goal/goal";
import baseCreateApi from "./baseCreateApi";
import { IGoal } from "../../interfaces/entities/IGoal";
import { IGoalDTO } from "../../interfaces/DTO/IGoalDTO";

export const goals = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getGoals: builder.query({
            query: (args: { orgId: string }) => ({
                url: `organizations/${args.orgId}/goals`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IGoalDTO[] }): IGoal[] => {
                return transformGoal(response.data);
            },
        }),
        createGoal: builder.mutation({
            query: (args: { body: IGoal; orgId: string }) => {
                return {
                    url: `organizations/${args.orgId}/goals`,
                    method: "POST",
                    body: JSON.stringify(generateGoalBody(args.body)),
                };
            },
            transformResponse: (response: { data: IGoalDTO }): IGoal[] => {
                return transformGoal([response.data]);
            },
        }),
        editGoal: builder.mutation({
            query: (args: { orgId: string; body: IGoal; goalId: string }) => {
                return {
                    url: `organizations/${args.orgId}/goals/${args.goalId}`,
                    method: "PATCH",
                    body: JSON.stringify(generateGoalBody(args.body)),
                };
            },
            transformResponse: (response: { data: IGoalDTO }): IGoal[] => {
                return transformGoal([response.data]);
            },
        }),
        deleteGoal: builder.mutation({
            query: (args: { goalId: string; orgId: string }) => ({
                url: `organizations/${args.orgId}/goals/${args.goalId}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useLazyGetGoalsQuery, useCreateGoalMutation, useDeleteGoalMutation, useEditGoalMutation } = goals;
