import React, { Suspense, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import { ThemeProvider, StyledEngineProvider } from "@mui/material/styles";
import { differenceInMinutes } from "date-fns";

import { IPageProps } from "../interfaces/IPageProps";
import { LoggedInRoute } from "./RouteValidation/LoggedInRoute";
import { AutoLoginRoute } from "./RouteValidation/AutoLoginRoute";
import { prescientLight } from "../theme/prescient";
import { NotistackProvider } from "../services/toaster";
import { useGetLoggedInUserQuery } from "../reduxState/apis/authApi";
import Loader from "../components/core/Loader/Loader";
import { Home } from "./Home/Home";
import UnsubscribeEmail from "./auth/UnsubscribeEmail";
import {
    ACCEPT_INIVITATION_PATH,
    CONFITM_MAIL_PATH,
    GETTING_STARTED_PATH,
    HELP_PAGE_PATH,
    LOGIN_PATH,
    NO_ORGANIZATION_PATH,
    RESET_PASSWORD_PATH,
    UNSUBSCRIBE_PATH,
} from "../consts/path/path";
import NoOrganizations from "./NoOrganizations/NoOrganizations";
import { AcceptInvitation } from "./auth/AcceptInvitation";
import { HelpPage } from "./Help/HelpPage";
import { ResetPassword } from "./auth/ResetPassword";
import { ConfirmEmail } from "./auth/ConfirmEmail";
import { GettingStartedPage } from "./GettingStartedPage/GettingStartedPage";
import { SimpleForm } from "../layouts/SimpleForm";
import { SimpleText } from "../layouts/SimpleText";
import { Login } from "./Login/Login";
import { CssBaseline } from "@mui/material";

/**
 * App container
 */

export const App: React.FC<IPageProps> = () => {
    // Check logged in user on load once.
    const { isError } = useGetLoggedInUserQuery({});
    if (isError) {
        console.log(isError);
    }

    useEffect(() => {
        // refresh if coming back to a stale tab.
        let blurredTime: Date = new Date();
        document.addEventListener("visibilitychange", () => {
            if (document.visibilityState === "visible") {
                const diffMinuteTime = differenceInMinutes(new Date(), blurredTime);
                if (diffMinuteTime > 10) {
                    location.reload();
                }
            } else {
                blurredTime = new Date();
            }
        });
    }, []);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={prescientLight}>
                <Suspense fallback={<Loader />}>
                    <NotistackProvider>
                        <Routes>
                            <Route
                                path="/*"
                                element={
                                    <LoggedInRoute>
                                        <Home />
                                    </LoggedInRoute>
                                }
                            />
                            <Route
                                path={LOGIN_PATH}
                                element={
                                    <SimpleForm>
                                        <Login />
                                    </SimpleForm>
                                }
                            />
                            <Route
                                path={ACCEPT_INIVITATION_PATH}
                                element={
                                    <AutoLoginRoute>
                                        <SimpleForm isUpgraded={true}>
                                            <AcceptInvitation />
                                        </SimpleForm>
                                    </AutoLoginRoute>
                                }
                            />
                            <Route
                                path={HELP_PAGE_PATH}
                                element={
                                    <SimpleForm>
                                        <HelpPage />
                                    </SimpleForm>
                                }
                            />
                            <Route
                                path={RESET_PASSWORD_PATH}
                                element={
                                    <AutoLoginRoute>
                                        <SimpleForm isUpgraded={true}>
                                            <ResetPassword />
                                        </SimpleForm>
                                    </AutoLoginRoute>
                                }
                            />
                            <Route
                                path={CONFITM_MAIL_PATH}
                                element={
                                    <SimpleForm>
                                        <ConfirmEmail />
                                    </SimpleForm>
                                }
                            />
                            <Route
                                path={GETTING_STARTED_PATH}
                                element={
                                    <SimpleText>
                                        <GettingStartedPage />
                                    </SimpleText>
                                }
                            />
                            <Route
                                path={UNSUBSCRIBE_PATH}
                                element={
                                    <SimpleForm>
                                        <UnsubscribeEmail />
                                    </SimpleForm>
                                }
                            />
                            <Route
                                path={NO_ORGANIZATION_PATH}
                                element={
                                    <SimpleForm>
                                        <NoOrganizations />
                                    </SimpleForm>
                                }
                            />
                        </Routes>
                    </NotistackProvider>
                </Suspense>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};
