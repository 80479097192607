// Theme colors
export const BACKGROUND_GRAY = "#616161";

export const WHITE = "#FFFFFF";
export const WHITE_OPACITY_2 = "#FFFFFF05";
export const WHITE_OPACITY_4 = "#FFFFFF0A";
export const WHITE_OPACITY_6 = "#FFFFFF0F";
export const WHITE_OPACITY_8 = "#FFFFFF14";
export const WHITE_OPACITY_10 = "#FFFFFF1A";
export const WHITE_OPACITY_12 = "#FFFFFF1F";
export const WHITE_OPACITY_20 = "#FFFFFF33";
export const WHITE_OPACITY_26 = "#00000042";
export const WHITE_OPACITY_70 = "#FFFFFFB2";
export const WHITE_OPACITY_75 = "#FFFFFFBF";
export const WHITE_OPACITY_40 = "#FFFFFF66";
export const WHITE_OPACITY_50 = "#FFFFFF80";
export const WHITE_OPACITY_88 = "#FFFFFFE0";

export const DARK_YELLOW = "#FFD942";
export const YELLOW = "#FFF174";
export const LIGHT_YELLOW = "#FFF59C";
export const YELLOW_OPACITY_8 = "#FFF17414";
export const YELLOW_OPACITY_12 = "#FFF1741F";
export const YELLOW_OPACITY_15 = "#FFF17426";
export const YELLOW_OPACITY_10 = "#FFF1741A";
export const YELLOW_OPACITY_50 = "#FFF9C8";

export const DARK_BLUE = "#0082FB";
export const BLUE = "#72DEFF";

export const DARK_ORANGE = "#D8640F";
export const ORANGE = "#F78B3D";
export const LIGHT_ORANGE = "#FB9B56";

export const DARK_PURPLE = "#6E17EC";
export const PURPLE = "#985EFF";
export const LIGHT_PURPLE = "#B073FB";

export const DARK_PINK = "#EF5182";
export const PINK = "#FA6896";

export const GREEN = "#38AE0C";
export const LIGHT_GREEN = "#99FF73";
export const LIGHT_GREEN_100 = "#ACFF8D";
export const LIGHT_GREEN_50 = "#D5FFC6";
export const GREEN_25 = "#48CF1540";

export const TURQUOISE = "#1EB980";
export const LIGHT_TURQUOISE = "#5DF7D2";

export const RED = "#F44336";
export const RED_20 = "#FF5E5033";
export const LIGHT_RED = "#FF796C";
export const LIGHT_RED_2 = "#E19D97";

// Base colors
export const DARK = "#212731";
export const TURQUOISE_BLUE = "#00ACB4";
export const TURQUOISE_BLUE_HOVER = "#00ACB41A";
export const TURQUOISE_TWO_BLUE = "#06C9D2";
export const GREYISH = "#A3A3A3";
export const CHARCOAL_GREY = "#303E48";
export const WHITE_COLOR = "#FDFDFD";
export const WHITE_COLOR_CLEAR = "#FFFFFF";
export const WHITE_TWO_COLOR = "#EEE";
export const RASPBERRY = "#B40047";
export const RASPBERRY_LIGHT = "#CA4C7E";
export const GREEN_COLOR = "#5EE29F";
export const RED_COLOR = "#FF6565";
export const BLACK_COLOR = "#000000";
export const BLACK_COLOR_OPACITY_87 = "#000000DE";
export const BLACK_COLOR_OPACITY_80 = "#000000CB";
export const BLACK_COLOR_OPACITY_50 = "#00000080";
export const BLACK_COLOR_OPACITY_60 = "#00000099";
export const BLACK_COLOR_OPACITY_40 = "#00000066";
export const BLACK_COLOR_OPACITY_30 = "#0000004D";
export const BLACK_COLOR_OPACITY_15 = "#00000026";
export const DARK_GREY = "#191919";
export const GRAY = "#434C55";
export const BACKGROUND_FOR_DEFAULT_CHIPS = "#FFFFFF";

// specific constant colors
export const PRIMARY_COLOR = "#009985";
export const PRIMARY_DARK_COLOR = "#195352";
export const PRIMARY_DISABLED_COLOR = "#e0e0e0";
export const PRIMARY_HOVER_COLOR = "#00695F";
export const SECONDARY = "#3998A0";
export const SUCCESS = "#008F57";
export const BACKGROUND_LIGHT = WHITE_TWO_COLOR;
export const BACKGROUND_DARK = DARK_GREY;

export const FIELD_LABEL_DISABLED_COLOR = GREYISH;
export const TEXT_COLOR = CHARCOAL_GREY;
export const TEXT_LABEL_COLOR = GREYISH;
export const TEXT_DISABLED_COLOR = CHARCOAL_GREY;
export const TEXT_PLACEHOLDER_COLOR = GREYISH;
export const TEXT_SUCCESS_COLOR = "#0f9960";
export const TEXT_ERROR_COLOR = RASPBERRY;
export const USERNAME_TEXT_COLOR = "#FFFFFFE0";
export const SUBTITLE_COLOR = "#FFFFFFB2";

export const NAVBAR_BACKGROUND = DARK;
export const NAVBAR_TEXT_COLOR = WHITE_COLOR;
export const BUTTON_TEXT_COLOR = WHITE_COLOR;
export const HOME_PAGE_TEXT_COLOR = WHITE_COLOR;
export const HOME_PAGE_MAIN_COLOR = BLACK_COLOR;
export const HOME_PAGE_DATA_CARD_COLOR = BLACK_COLOR;

export const SIDEBAR_BACKGROUND = GRAY;
export const SIDEBAR_ITEM_TEXT_COLOR = SUBTITLE_COLOR;
export const SIDEBAR_ITEM_ACTIVE_TEXT_COLOR = YELLOW;
export const SIDEBAR_ACTIVE_BACKGROUND = "#FFF17414";
export const SIDEBAR_HOVER_BACKGROUND = "#FFFFFF14";

export const SIDEBAR_HOVER_TEXT = "#FFFFFFB2";
export const SIDEBAR_FOCUS_BACKGROUND = "#FFFFFF1F";
export const SIDEBAR_FOCUS_TEXT = "#FFFFFFE0";

export const FIRST_KPI_CHART_COLOR = "#FA1232";
export const SECOND_KPI_CHART_COLOR = "#4A79F0";
export const OVERVIEW_CHART_COLOR = "#A29EFA";
export const OVERVIEW_CHART_BACKGROUND_COLOR = "#eeeeee";

export const FORECASTING_CHART_MARKLINE_COLOR = "#FFF175";
export const FORECASTING_CHART_SPLITLINE_COLOR = "#4b4b4b";
export const BASE_BACKGROUND_COLOR = "#373942";

export const SEARCH_RESULTS_BACKGROUND_COLOR = "#363636";

export const METRIC_CARDS_AVATAR_BACKGROUND = "#6FC9D1";

export const TRUE_FUNNEL_CHART_X_AXIS_COLOR = "#333333";

export const TRUE_FUNNEL_CHART_ITEM_FIRST = "#73C2C7";
export const TRUE_FUNNEL_CHART_ITEM_SECOND = "#88A683";
export const TRUE_FUNNEL_CHART_ITEM_THIRD = "#74C7B5";
export const TRUE_FUNNEL_CHART_ITEM_FOURTH = "#295251";
export const TRUE_FUNNEL_CHART_ITEM_FIFTH = "#6FC9D1";
export const TRUE_FUNNEL_CHART_ITEM_SIXTH = "#71DCB4";
export const TRUE_FUNNEL_CHART_ITEM_SEVENTH = "#567779";
export const TRUE_FUNNEL_CHART_ITEM_EIGHTH = "#3998A0";
export const TRUE_FUNNEL_CHART_ITEM_NINTH = "#D5EEF0";
export const TRUE_FUNNEL_CHART_ITEM_TENTH = "#1B313B";

export const HEADER_FONT_COLOR = "#1B313B";
export const HEADER_BG_COLOR = "#1B313B";
export const HEADER_ACTIVE_LINK_COLOR = "#73DDB4";
export const AVATAR_TEXT_COLOR = "#0B9786";
export const AVATAR_BG_COLOR = "#D1F8E9";

export const PRIMARY_MAIN_COLOR = "#429685";

export const CHART_COLORS: { [key: string]: string } = {
    total: "#0194FF",
    [`shopify-transactional`]: "#008060",
    [`shopify-wholesale`]: "#1877F2",
    [`shopify-subscription`]: "#ED750A",
    [`halo - amazon selling partner`]: "#ee6666",
};
