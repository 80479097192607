import { RootState } from "../../reduxState/stores/store";
import { IAnalyticsManager } from "./IAnalyticsManager";
import { IMember } from "src/interfaces/entities/IMember";
import { selectCurrentlyViewingCompanyById } from "../../reduxState/slices/organizationSlice";
import {
    isSuperAdminSelector,
    currentUserRoleByCompanyIdSelector,
    userIdSelector,
} from "../../reduxState/slices/userSlice";
import {
    IStateObserver,
    IStateObserverSelector,
    IStateObserverFunction,
} from "../../interfaces/analytics/IStateObserver";

// Selectors - pieces of state that you want to listen to on change.
const currentOrgViewingIdSelector: IStateObserverSelector = (state: RootState): any =>
    state.organizations.currentlyViewingId;

const currentUserSelector: IStateObserverSelector = (state: RootState): any => state.currentUser;

// Callbacks - the function you want to fire when state change occurs.
const orgChangeCallback: IStateObserverFunction =
    (analytics: IAnalyticsManager) =>
    (currValue: string | undefined, previousValue: string | undefined, state: RootState) => {
        const user = userIdSelector(state);
        const company = selectCurrentlyViewingCompanyById(state);
        const subStatus = company?.subscription?.status
            .split("_")
            .map((w) => w[0].toUpperCase() + w.substring(1).toLowerCase())
            .join(" ");
        const role = isSuperAdminSelector(state) ? "Superadmin" : currentUserRoleByCompanyIdSelector(state);

        if (!user) {
            return;
        }

        if (company?.id) {
            analytics.setCurrentOrganization({
                ...company,
                subscriptionStatus: subStatus || "",
                role: role ? role : "",
            });
        }
    };

const userChangeCallback: IStateObserverFunction =
    (analytics: IAnalyticsManager) =>
    (currUser: IMember | undefined, previousUser: IMember | undefined, state: RootState) => {
        if (!currUser) {
            return;
        }

        analytics.setUser(currUser);
    };

export default (analytics: IAnalyticsManager): IStateObserver => ({
    orgViewChange: [currentOrgViewingIdSelector, orgChangeCallback(analytics)],
    userChange: [currentUserSelector, userChangeCallback(analytics)],
});
