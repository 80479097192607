import { CAMPAIGN_INCLUSION_CHIPS_VALUE } from "src/consts/campaignInclusionsPage/campaignInclusions";
import { IChips } from "src/interfaces/IChips/IChips";
import { ICampaignInclusionResponse } from "src/interfaces/campaignsForInclusions/ICampaignInclusionResponse";
import { IDimCampaignResponse } from "src/interfaces/dimCampaignResponse/IDimCampaignResponse";
import { ICampaignForInclusion, ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { keysToCamelCase } from "src/utils/format";
import { getComparator, stableSort } from "src/utils/sort";

export const generateCampaignInclusionBody = (data: any) => {
    return {
        data: {
            attributes: {
                campaign_ids: data,
            },
            type: "company_campaign",
        },
    };
};

export const formatCampaignsForInclusions = (response: { data: IDimCampaignResponse[] }): IDimCampaign[] => {
    return response?.data?.length
        ? response.data.map((res) => {
              return keysToCamelCase(res.attributes);
          })
        : [];
};

export const getFilteredCampaignInclusionData = (
    campaigns: ICampaignForInclusionWithMap[],
    search: string,
    filter: number,
) => {
    if (campaigns && campaigns.length) {
        let updatedCampaigns = campaigns;
        if (filter) {
            const filterLabel = CAMPAIGN_INCLUSION_CHIPS_VALUE.find((chip) => chip.key === filter)?.label;
            updatedCampaigns = updatedCampaigns.filter((campaign: ICampaignForInclusionWithMap) =>
                filterLabel === "Inclusions" ? campaign.manuallySet : !campaign.manuallySet,
            );
        }

        if (search) {
            updatedCampaigns = search
                ? updatedCampaigns.filter((campaign: any) =>
                      `${campaign.campaignId} ${campaign.channel} ${campaign.campaignName}`
                          .toLowerCase()
                          .includes(search.toLowerCase()),
                  )
                : updatedCampaigns;
        }
        return updatedCampaigns;
    }
    return [];
};

export const getChipsValues = (campaignInclusions: ICampaignForInclusionWithMap[]) => {
    const newChipsValue: IChips[] = [];
    CAMPAIGN_INCLUSION_CHIPS_VALUE.map((filter) => {
        const items = getFilteredCampaignInclusionData(campaignInclusions, "", filter.key);
        if (filter.isAll || items.length) {
            newChipsValue.push({
                ...filter,
                disabled: false,
            });
        } else {
            newChipsValue.push({
                ...filter,
                disabled: true,
            });
        }
    });
    return newChipsValue;
};

export const getSelectedRows = (selected: readonly string[], campaignId: string) => {
    const selectedIndex = selected.indexOf(campaignId);
    let newSelected: readonly string[] = [];

    if (selectedIndex === -1) {
        newSelected = newSelected.concat(selected, campaignId);
    } else if (selectedIndex === 0) {
        newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
        newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
        newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
    }
    return newSelected;
};

export const sortedCampaigns = (allCampaigns: ICampaignForInclusionWithMap[]): ICampaignForInclusionWithMap[] => {
    const inclusions = allCampaigns.filter((c) => c.manuallySet);
    const exclusions = allCampaigns.filter((c) => !c.manuallySet);

    const sortedCompanyCampaignsByCreatedDate = stableSort(
        inclusions,
        getComparator("desc", "createdAt"),
    ) as ICampaignForInclusionWithMap[];

    const sortedCampaignsByName = stableSort(
        exclusions,
        getComparator("asc", "campaignName"),
    ) as ICampaignForInclusionWithMap[];

    return [...sortedCompanyCampaignsByCreatedDate, ...sortedCampaignsByName];
};
