import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { format } from "date-fns";
import { Stack } from "@mui/system";

import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingCompanyById,
} from "../../reduxState/slices/organizationSlice";
import { IScenario } from "../../interfaces/entities/IScenario";
import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";
import { isCampaignAvailableSelector } from "../../reduxState/slices/settingsSlice";
import { getPathForAnalytics } from "../../services/utils";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { OPTIIMIZATION_CREATE_PATH, OPTIIMIZATION_PATH } from "../../consts/path/path";
import {
    scenarioCurrentTabSelector,
    setCurrentlyViewingScenario,
    setScenarioTab,
} from "../../reduxState/slices/scenarioSlice";
import {
    OptimizationTab,
    SCENARIO_BASIS,
    SCENARIO_TRACKING_TABS,
    TIMEFRAME_OPTIONS,
} from "../../consts/optimizationPage/optimizationPage";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userStateSelector,
} from "../../reduxState/slices/userSlice";
import { ScenarioTrackingModal } from "../../components/Optimization/ScenarioTracking/ScenarioTrackingModal";
import { scenatioTableViewTabs } from "src/services/optimizationPage/optimization";
import { PageHeaderTabContext } from "src/components/CustomTabContext/PageHeaderTabContext";
import { ScenarioTab } from "src/components/Optimization/OptimizationTabs/ScenarioTab";
import { RecommendedTab } from "src/components/Optimization/OptimizationTabs/RecommendedTab";
import { ArchivedTab } from "src/components/Optimization/OptimizationTabs/ArchivedTab";
import { IOrganization } from "src/interfaces/entities/IOrganization";
import { checkIsIncompletePagesExist } from "src/services/onboardingPage/onboardingPage";
import { OnboardingViewerOptimizationPage } from "src/components/OnboardingPages/ViewerOnboardingPages/OnboardingViewerOptimizationPage";
import { UserRole } from "src/enums/UserRole";
import { OnboardingAdminHomePage } from "src/components/OnboardingPages/AdminOnboardingPages/OnboardingAdminHomePage";
import { listFeaturesSelector, optimizationPageSelector } from "src/reduxState/slices/featuresSlice";

export const Optimization = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const analyticsService = useAnalyticsService();
    const isOptimizationPageVisible = useSelector(optimizationPageSelector);
    const featureList = useSelector(listFeaturesSelector);

    const isCampaignAvailable = useSelector(isCampaignAvailableSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const currentUser = useSelector(userStateSelector);
    const scenarioCurrentTab = useSelector(scenarioCurrentTabSelector);
    const currrentOrg: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);

    const isIncompletePagesExist = useMemo(
        () => checkIsIncompletePagesExist(currrentOrg?.onboardingState),
        [currrentOrg],
    );

    const [scenarioForTracking, setScenarioForTracking] = useState<IScenario | null>(null);
    const [isScenarioTrackingModalOpen, setScenarioTrackingModalOpen] = useState<boolean>(false);
    const [tabValue, setTabValue] = useState("revenue");

    useEffect(() => {
        analyticsService.logEvent("Optimization Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    useEffect(() => {
        if (featureList.length && !isOptimizationPageVisible) {
            navigate(`/404`);
            return;
        }
    }, [featureList]);

    const openCreateModal = useCallback(() => {
        analyticsService.logEvent("Optimization New Scenario Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        dispatch(
            setCurrentlyViewingScenario({
                id: "",
                name: `${currentUser ? currentUser.firstName : ""}’s Scenario ${format(new Date(), "MMM d, yyyy")}`,
                description: "",
                scenarioType: SCENARIO_BASIS.SCALE,
                forecastTimeframe: TIMEFRAME_OPTIONS.LAST28DAYS,
                budget: 0,
                revenue: 0,
                roas: 1.0,
                campaignData: [],
            }),
        );
        navigate(`/org/${orgCode}${OPTIIMIZATION_CREATE_PATH}`);
    }, []);

    const openEditModal = (optimization: IScenario) => {
        analyticsService.logEvent("Optimization Previous Scenario Clicked", {
            Page: getPathForAnalytics(location.pathname),
        });
        navigate(`/org/${orgCode}${OPTIIMIZATION_PATH}/${optimization.id}`);
    };

    const handleCloseModal = () => {
        setScenarioTrackingModalOpen(false);
        setTabValue("revenue");
    };

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabValue(newValue);
        if (scenarioForTracking) {
            analyticsService.logEvent(
                `Metric Tab Changed ${SCENARIO_TRACKING_TABS.filter((t) => t.value === newValue)[0].label}`,
                {},
            );
        }
    };

    const handleTracking = (scenario: IScenario) => {
        setTabValue("revenue");
        analyticsService.logEvent(
            `See Tracking Clicked ${scenario.scenarioType === "forecast" ? "Forecast" : "Optimization"}`,
            {},
        );
        setScenarioTrackingModalOpen(true);
        setScenarioForTracking(scenario);
    };

    const handleScenarioViewChange = (event: React.SyntheticEvent<Element, Event>, newValue: OptimizationTab) => {
        dispatch(setScenarioTab(newValue as OptimizationTab));
    };

    const getCurrantTab = () => {
        const props = {
            handleTracking,
            openEditModal,
        };
        switch (scenarioCurrentTab) {
            case OptimizationTab.Scenarios:
                return <ScenarioTab {...props} />;
            case OptimizationTab.Recommended:
                return <RecommendedTab {...props} />;
            case OptimizationTab.Archived:
                return <ArchivedTab {...props} />;
            default:
                return <ScenarioTab {...props} />;
        }
    };

    const filteredTabs = scenatioTableViewTabs
        .map((tab) => {
            return {
                ...tab,
                isHidden: tab.value === OptimizationTab.Recommended,
            };
        })
        .filter((tab) => !tab.isHidden);

    const getEmptyComponent = () =>
        isSuperAdmin || userRole === UserRole.ADMIN ? (
            <OnboardingAdminHomePage />
        ) : (
            <OnboardingViewerOptimizationPage />
        );

    return (
        <>
            {!isCampaignAvailable && !isIncompletePagesExist ? (
                <NoCampaignPage
                    title="We're building your dashboard!"
                    subTitle="We're in the process of syncing your data and using it to build your custom attribution model. This
can take some time, so please check back later."
                />
            ) : (
                <div>
                    <PageHeader
                        pageHeading={"Optimization"}
                        buttonName="Create New Optimization"
                        onAdd={openCreateModal}
                        customStyle={{ padding: "30px 30px 0" }}
                        tabs={
                            <PageHeaderTabContext
                                value={scenarioCurrentTab}
                                handleChange={handleScenarioViewChange}
                                tabs={filteredTabs}
                            />
                        }
                        disabled={isIncompletePagesExist}
                        showBreadcrumbs={!isIncompletePagesExist}
                    />

                    {isIncompletePagesExist ? (
                        <PageContainer style={{ backgroundColor: "#fff" }}>{getEmptyComponent()}</PageContainer>
                    ) : (
                        <PageContainer>
                            <Stack gap="30px">{getCurrantTab()}</Stack>{" "}
                        </PageContainer>
                    )}
                    {isScenarioTrackingModalOpen && scenarioForTracking && (
                        <ScenarioTrackingModal
                            handleCloseModal={handleCloseModal}
                            scenarioForTracking={scenarioForTracking}
                            tabValue={tabValue}
                            handleTabChange={handleTabChange}
                        />
                    )}
                </div>
            )}
        </>
    );
};
