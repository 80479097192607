import React from "react";

const SVGLIST: { [key: string]: React.FC<{ onClick?: any; className?: any }> } = {};

import { ReactComponent as AlertCircleIcon } from "../../assets/svg/ico-Alert-Circle.svg";
import { ReactComponent as AlertTriangleIcon } from "../../assets/svg/ico-Alert-Triangle.svg";
import { ReactComponent as ArrowLeftIcon } from "../../assets/svg/ico-Arrow-Left.svg";
import { ReactComponent as ArrowRight } from "../../assets/svg/arrow-right.svg";
import { ReactComponent as BuildingIcon } from "../../assets/svg/ico-Building.svg";
import { ReactComponent as CalendarIcon } from "../../assets/svg/ico-Calendar.svg";
import { ReactComponent as CloseCircleIcon } from "../../assets/svg/ico-X-Circle.svg";
import { ReactComponent as CheckCircleIcon } from "../../assets/svg/ico-Check-Circle-2.svg";
import { ReactComponent as CheckIcon } from "../../assets/svg/ico-Check.svg";
import { ReactComponent as ChevronDownIcon } from "../../assets/svg/ico-Chevron-Down.svg";
import { ReactComponent as ChevronRightIcon } from "../../assets/svg/ico-Chevron-Right.svg";
import { ReactComponent as ChevronUpIcon } from "../../assets/svg/ico-Chevron-Up.svg";
import { ReactComponent as CloseIcon } from "../../assets/svg/ico-X.svg";
import { ReactComponent as CopyIcon } from "../../assets/svg/copy.svg";
import { ReactComponent as DownloadIcon } from "../../assets/svg/download.svg";
import { ReactComponent as EditIcon } from "../../assets/svg/ico-Edit-1.svg";
import { ReactComponent as EditIcon2 } from "../../assets/svg/ico-Edit-2.svg";
import { ReactComponent as ExportIcon } from "../../assets/svg/ico-Export.svg";
import { ReactComponent as ExternalLinkIcon } from "../../assets/svg/ico-External-Link.svg";
import { ReactComponent as EyeIcon } from "../../assets/svg/eye.svg";
import { ReactComponent as EyeOffIcon } from "../../assets/svg/eye-off.svg";
import { ReactComponent as FilterIcon } from "../../assets/svg/ico-Filter.svg";
import { ReactComponent as HelpIcon } from "../../assets/svg/ico-Help.svg";
import { ReactComponent as InfoIcon } from "../../assets/svg/ico-Info.svg";
import { ReactComponent as LinkIcon } from "../../assets/svg/link.svg";
import { ReactComponent as LogoutIcon } from "../../assets/svg/ico-Logout.svg";
import { ReactComponent as Mail } from "../../assets/svg/mail.svg";
import { ReactComponent as MaximizeIcon } from "../../assets/svg/ico-Maximize-2.svg";
import { ReactComponent as MinimizeIcon } from "../../assets/svg/ico-Minimize-2.svg";
import { ReactComponent as NetworkIcon } from "../../assets/svg/ico-Network.svg";
import { ReactComponent as OverflowIcon } from "../../assets/svg/ico-Overflow.svg";
import { ReactComponent as PlusIcon } from "../../assets/svg/ico-Plus.svg";
import { ReactComponent as RefreshIcon } from "../../assets/svg/refresh-ccw.svg";
import { ReactComponent as Search } from "../../assets/svg/search.svg";
import { ReactComponent as SettingsIcon } from "../../assets/svg/ico-settings.svg";
import { ReactComponent as TrashIcon } from "../../assets/svg/ico-Trash.svg";
import { ReactComponent as UserIcon } from "../../assets/svg/ico-User.svg";
import { ReactComponent as MarkPerscientLogo } from "../../assets/svg/markBackground.svg";
import { ReactComponent as Card } from "../../assets/svg/card.svg";
import { ReactComponent as Rocket } from "../../assets/svg/rocket.svg";
import { ReactComponent as Eraser } from "../../assets/svg/eraser.svg";
import { ReactComponent as SendIcon } from "../../assets/svg/send.svg";
import { ReactComponent as AirIcon } from "../../assets/svg/air.svg";
import { ReactComponent as Bell } from "../../assets/svg/bell.svg";
import { ReactComponent as MinusIcon } from "../../assets/svg/ico-minus.svg";
import { ReactComponent as ChevronsLeft } from "../../assets/svg/chevrons-left.svg";
import { ReactComponent as ChevronsRight } from "../../assets/svg/chevrons-right.svg";
import { ReactComponent as Trophy } from "../../assets/svg/trophy.svg";
import { ReactComponent as Lock } from "../../assets/svg/lock.svg";
import { ReactComponent as Unlock } from "../../assets/svg/unlock.svg";
import { ReactComponent as Undo } from "../../assets/svg/undo.svg";
import { ReactComponent as History } from "../../assets/svg/history.svg";
import { ReactComponent as Channel } from "../../assets/svg/channel.svg";
import { ReactComponent as Campaign } from "../../assets/svg/campaign.svg";
import { ReactComponent as RedDownArrow } from "../../assets/svg/redDownArrow.svg";
import { ReactComponent as GreenUpArrow } from "../../assets/svg/greenUpArrow.svg";
import { ReactComponent as ArchiveRestore } from "../../assets/svg/archive-restore.svg";
import { ReactComponent as Archive } from "../../assets/svg/archive.svg";
import { Delete, Add, EditOutlined } from '@mui/icons-material';

SVGLIST.alertCircleIcon = AlertCircleIcon;
SVGLIST.alertTriangleIcon = AlertTriangleIcon;
SVGLIST.arrowLeftIcon = ArrowLeftIcon;
SVGLIST.arrowRightIcon = ArrowRight;
SVGLIST.buildingIcon = BuildingIcon;
SVGLIST.calendarIcon = CalendarIcon;
SVGLIST.checkIcon = CheckIcon;
SVGLIST.chevronDownIcon = ChevronDownIcon;
SVGLIST.chevronRightIcon = ChevronRightIcon;
SVGLIST.chevronUpIcon = ChevronUpIcon;
SVGLIST.checkCircleIcon = CheckCircleIcon;
SVGLIST.closeCircleIcon = CloseCircleIcon;
SVGLIST.closeIcon = CloseIcon;
SVGLIST.copyIcon = CopyIcon;
SVGLIST.downloadIcon = DownloadIcon;
SVGLIST.editIcon = EditIcon;
SVGLIST.editIcon2 = EditIcon2;
SVGLIST.exportIcon = ExportIcon;
SVGLIST.externalLinkIcon = ExternalLinkIcon;
SVGLIST.eyeIcon = EyeIcon;
SVGLIST.eyeOffIcon = EyeOffIcon;
SVGLIST.filterIcon = FilterIcon;
SVGLIST.helpIcon = HelpIcon;
SVGLIST.infoIcon = InfoIcon;
SVGLIST.linkIcon = LinkIcon;
SVGLIST.logoutIcon = LogoutIcon;
SVGLIST.maximizeIcon = MaximizeIcon;
SVGLIST.minimizeIcon = MinimizeIcon;
SVGLIST.mailIcon = Mail;
SVGLIST.networkIcon = NetworkIcon;
SVGLIST.overflowIcon = OverflowIcon;
SVGLIST.plusIcon = PlusIcon;
SVGLIST.refreshIcon = RefreshIcon;
SVGLIST.searchIcon = Search;
SVGLIST.settingsIcon = SettingsIcon;
SVGLIST.trashIcon = TrashIcon;
SVGLIST.userIcon = UserIcon;
SVGLIST.markPerscientLogo = MarkPerscientLogo;
SVGLIST.card = Card;
SVGLIST.rocket = Rocket;
SVGLIST.eraser = Eraser;
SVGLIST.sendIcon = SendIcon;
SVGLIST.airIcon = AirIcon;
SVGLIST.bellIcon = Bell;
SVGLIST.minusIcon = MinusIcon;
SVGLIST.chevronsLeftIcon = ChevronsLeft;
SVGLIST.chevronsRightIcon = ChevronsRight;
SVGLIST.trophyIcon = Trophy;
SVGLIST.lockIcon = Lock;
SVGLIST.unlockIcon = Unlock;
SVGLIST.undoIcon = Undo;
SVGLIST.historyIcon = History;
SVGLIST.campaignIcon = Campaign;
SVGLIST.channelIcon = Channel;
SVGLIST.redDownArrowIcon = RedDownArrow;
SVGLIST.greenUpArrowIcon = GreenUpArrow;
SVGLIST.archiveRestoreIcon = ArchiveRestore;
SVGLIST.archiveIcon = Archive;
SVGLIST.deleteIcon = Delete;
SVGLIST.addIcon = Add;
SVGLIST.editOutlinedIcon = EditOutlined;

export default SVGLIST;
