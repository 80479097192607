import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, Select } from "@mui/material";
import { Formik, Form, FormikConfig, FormikProps } from "formik";
import { object, string } from "yup";
import Spinner from "@mui/material/CircularProgress";

import { fullWidthClass } from "src/assets/styles/commonStyle";
import { Flex, FlexDirection } from "src/components/core/Flex/Flex";
import { FlexItem } from "src/components/core/FlexItem/FlexItem";
import { Field } from "src/components/core/Field/Field";
import { Button } from "src/components/core/Button/Button";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { capitalizeFirstLetter } from "src/services/utils";
import { IDataProvider } from "src/interfaces/IDataProvider";
import { IDataCategory } from "src/interfaces/IDataCategory/IDataCategory";

interface IDataServiceModalProps extends FormikConfig<IDataService> {
    isEditMode?: boolean;
    dataServicesOptions: IDataService[];
    dataSourceCategoriesOptions: IDataCategory[];
    dataSourceProviders: IDataProvider[];
    onChangeMode?: (...args: any[]) => any;
}

const validationSchema = object().shape({
    name: string()
        .matches(/^[a-z0-9_]+$/, "Use only lowercase letters, numbers, underscores")
        .required("Name is required"),
    dataSourceProviderId: string().required("Data source provider is required"),
});

export const DataServiceForm: React.FC<IDataServiceModalProps> = ({
    isEditMode,
    dataServicesOptions,
    dataSourceCategoriesOptions,
    dataSourceProviders,
    ...rest
}) => {
    return (
        <Formik validationSchema={validationSchema} {...rest}>
            {({ touched, errors, handleChange, values, isSubmitting }: FormikProps<IDataService>) => {
                const disabledInput = isSubmitting;
                const hasNameError = Boolean(touched.name && errors.name);
                return (
                    <Form>
                        <Flex direction={FlexDirection.Column} spacing="24px">
                            <FlexItem className={fullWidthClass}>
                                <Field
                                    id="name"
                                    name="name"
                                    disabled={disabledInput}
                                    label="name"
                                    value={values.name || ""}
                                    onChange={handleChange}
                                    inputProps={{ maxLength: 30 }}
                                    placeholder="name"
                                    className={fullWidthClass}
                                    error={hasNameError}
                                    helperText={
                                        hasNameError
                                            ? errors.name
                                            : ["Use only lowercase letters, numbers, underscores"]
                                    }
                                />
                            </FlexItem>
                            <FlexItem className={fullWidthClass}>
                                <FormControl fullWidth>
                                    <InputLabel
                                        error={Boolean(touched.dataSourceProviderId && errors.dataSourceProviderId)}
                                    >
                                        Data source provider
                                    </InputLabel>
                                    <Select
                                        onChange={handleChange}
                                        value={values.dataSourceProviderId || ""}
                                        label="Data source provider"
                                        name="dataSourceProviderId"
                                        error={Boolean(touched.dataSourceProviderId && errors.dataSourceProviderId)}
                                        disabled={disabledInput}
                                    >
                                        {dataSourceProviders.map((item) => (
                                            <MenuItem key={item.id} value={item.id}>
                                                {capitalizeFirstLetter(item.name)}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {touched.dataSourceProviderId && errors.dataSourceProviderId ? (
                                        <FormHelperText sx={{ color: "#F44336" }}>
                                            {errors.dataSourceProviderId}
                                        </FormHelperText>
                                    ) : (
                                        <></>
                                    )}
                                </FormControl>
                            </FlexItem>
                        </Flex>
                        <Button
                            type="submit"
                            disabled={isSubmitting}
                            className={fullWidthClass}
                            fullWidth
                            sx={{ marginTop: "15px" }}
                        >
                            {isSubmitting ? <Spinner size={25} /> : isEditMode ? "Save Changes" : "Create Data Service"}
                        </Button>
                    </Form>
                );
            }}
        </Formik>
    );
};
