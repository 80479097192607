import { InputAdornment, TextField } from "@mui/material";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";

export const SearchField = ({
    value,
    onChange,
    placeholder,
}: {
    value?: string;
    onChange: any;
    placeholder: string;
}) => {
    return (
        <TextField
            variant="outlined"
            value={value}
            placeholder={placeholder}
            onChange={(e) => onChange(e)}
            sx={{
                width: "300px",
                backgroundColor: "white",
                "& .MuiInputBase-root": {
                    fontSize: "15px",
                    padding: "10px",
                    "& input": {
                        padding: 0,
                    },
                },
            }}
            InputProps={{
                startAdornment: (
                    <InputAdornment position="start">
                        <SVGIconRenderer
                            width="20px"
                            height="20px"
                            strokeColor="rgba(0, 0, 0, 0.26)"
                            icon="searchIcon"
                        />
                    </InputAdornment>
                ),
            }}
        />
    );
};
