import * as React from "react";
import { css } from "@emotion/css";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { object, ref, string } from "yup";
import { Stack } from "@mui/system";
import { Typography } from "@mui/material";

import { IResetPasswordValues } from "../../interfaces/IResetPasswordValues";
import { PRIMARY_DARK_COLOR, RED, BLACK_COLOR_OPACITY_50 } from "../../consts/colors";
import { Field } from "../core/Field/Field";
import { withRouter, IWithRouterProps } from "../ComponentWithRouterProp/ComponentWithRouterProp";
import { FooterForm } from "../core/Form/FooterForm";
import { Password } from "../core/Field/PasswordV1";

export const iconClass = (touched: boolean, mode?: string) => css`
    margin-right: 8px;
    opacity: ${touched || mode ? "1" : "0"};
`;

export const hiddenField = css`
    visibility: hidden;
    position: absolute !important;
    left: -999999%;
    height: 0;
`;

export const validationClass = (error: boolean) => css`
    margin-top: ${error ? "8px" : "0"};
`;

export const validationText = (touched: boolean | undefined, error: boolean, mode?: string) => css`
    font-family: "Inter", "Roboto", sans-serif !important;
    font-size: 14px !important;
    line-height: 18px !important;
    letter-spacing: 0.1px !important;
    color: ${!touched && !mode ? BLACK_COLOR_OPACITY_50 : error ? RED : PRIMARY_DARK_COLOR};
`;

export interface IResetPasswordFormProps extends FormikConfig<IResetPasswordValues>, IWithRouterProps {
    email?: string;
    firstName?: string;
    onResendEmailPage?(): void;
}

const ResetPasswordForm: React.FC<IResetPasswordFormProps> = (props) => {
    const { onResendEmailPage, ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                password: string()
                    .required("Password is required")
                    .min(8, "8 total characters as minimum")
                    .matches(/(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])/),
                passwordConfirmation: string()
                    .oneOf([ref("password"), null], "Passwords don’t match. Please check it.")
                    .required("Confirm Password is required"),
            })}
            {...rest}
        >
            {({
                errors,
                handleChange,
                touched,
                values,
                isSubmitting,
                setFieldTouched,
            }: FormikProps<IResetPasswordValues>) => {
                const hasPasswordConfirmationError = Boolean(
                    touched.passwordConfirmation && errors.passwordConfirmation,
                );
                const hasPasswordError = Boolean(touched.password && errors.password);
                const minLengthError = values.password.length < 8;
                const uppercaseError = Boolean(!values.password.match(/(?=.*?[A-Z])/));
                const lowercaseError = Boolean(!values.password.match(/(?=.*?[a-z])/));
                const numberError = Boolean(!values.password.match(/(?=.*?[0-9])/));

                const isPasswordError =
                    hasPasswordError || minLengthError || uppercaseError || lowercaseError || numberError;

                if (errors.resetPasswordToken && onResendEmailPage) {
                    onResendEmailPage();
                }

                return (
                    <Form>
                        <Stack gap={1}>
                            <Typography data-cy="heading" variant="h2">Create Your Password</Typography>
                            <Typography data-cy="userInfo">
                                Welcome, {props.firstName}! Let's set up your password to get you started.
                            </Typography>
                        </Stack>
                        <Field
                            size="small"
                            autoComplete="username"
                            value={props.email}
                            disabled
                            label="Email"
                            data-cy="email"
                        />
                        <Password
                            id="password"
                            name="password"
                            label="New password"
                            error={hasPasswordError}
                            value={values.password}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("password")}
                            autoComplete="new-password"
                            size="small"
                            data-cy="password"
                        />
                        <Password
                            id="passwordConfirmation"
                            name="passwordConfirmation"
                            label="Confirm new password"
                            error={hasPasswordConfirmationError}
                            helperText={hasPasswordConfirmationError ? errors.passwordConfirmation : ""}
                            value={values.passwordConfirmation}
                            onChange={handleChange}
                            onBlur={() => setFieldTouched("passwordConfirmation")}
                            autoComplete="new-password"
                            size="small"
                            data-cy="passwordConfirmation"
                        />

                        <Typography
                            data-cy="errorMsg"
                            color={`${
                                !Object.keys(touched).length
                                    ? "text.secondary"
                                    : isPasswordError
                                    ? "error.main"
                                    : PRIMARY_DARK_COLOR
                            }`}
                            variant="caption"
                        >
                            Make sure it’s at least 8 characters, 1 uppercase, 1 lowercase, and 1 number.
                        </Typography>
                        <FooterForm
                            submitBtnProps={{
                                disabled:
                                    Boolean(hasPasswordConfirmationError || hasPasswordError) ||
                                    Boolean(!touched.password || !touched.passwordConfirmation),
                                isLoading: isSubmitting,
                                text: "Confirm",
                                testId: "confirmButton",
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};

export default withRouter(ResetPasswordForm);
