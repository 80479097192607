import React, { useState } from "react";
import { TableBody } from "@mui/material";
import { useSelector } from "react-redux";

import { IDataSource, IDataSourceForm } from "src/interfaces/IData";
import { DataSourceTableHeader } from "./DataSourceTableHeader";
import { Choice, ICells } from "src/consts/dataSourcePage/dataSourcePage";
import { useStylesTable } from "src/consts/StylesForTables";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { CustomTableStructure } from "src/components/core/CustomTableStructure/CustomTableStructure";
import { getComparator, stableSort } from "src/utils/sort";
import { DataSourceTableRow } from "./DataSourceTableRow";

interface IDataSourceTable {
    data: IDataSource[];
    editClick: (organization: IDataSourceForm) => void;
    deleteClick: (organization: IDataSourceForm) => void;
}

export const DataSourceTable: React.FC<IDataSourceTable> = ({ data, editClick, deleteClick }) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("name");
    const [page, setPage] = useState<number>(0);

    const handleChangePage = (_: unknown, newPage: number) => setPage(newPage);
    const handleChangeRowsPerPage = () => setPage(0);

    const handleRequestSort = (_: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
        setPage(0);
    };

    const handleEdit = (e: React.MouseEvent<any>, dataSource: IDataSourceForm) => {
        e.stopPropagation();
        editClick(dataSource);
    };

    const handleDelete = (e: React.MouseEvent<any>, dataSource: IDataSourceForm) => {
        e.stopPropagation();
        deleteClick(dataSource);
    };

    return (
        <>
            <CustomTableStructure
                paginationProps={{
                    count: data.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                }}
            >
                <DataSourceTableHeader choiceBy={orderBy} choice={order} handleRequestSort={handleRequestSort} />
                <TableBody className={classes.tableBody}>
                    {stableSort(data, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((dataSource: any, index: number) => {
                            return (
                                <DataSourceTableRow
                                    key={dataSource.id}
                                    dataSource={dataSource}
                                    editClick={handleEdit}
                                    deleteClick={handleDelete}
                                />
                            );
                        })}
                </TableBody>
            </CustomTableStructure>
        </>
    );
};
