import React from "react";
import { Button, ButtonColor, ButtonVariant } from "../Button/Button";

interface ILoadingButton {
    loading?: boolean;
    text: string;
    variant?: ButtonVariant;
    color?: ButtonColor;
    className?: any;
    disabled?: boolean;
    loadingIndicator?: any;
    onClick(...args: any): any;
}

export const LoadingButton: React.FC<ILoadingButton> = ({
    variant = ButtonVariant.Container,
    color = ButtonColor.Primary,
    loading = false,
    text,
    onClick,
    className,
    disabled = false,
    loadingIndicator,
}) => {
    return (
        <Button
            variant={variant}
            color={color}
            onClick={onClick}
            className={`${className}`}
            sx={{ pointerEvents: loading ? "none" : "unset" }}
            disabled={disabled}
        >
            {loading && loadingIndicator}
            {!loading && text}
        </Button>
    );
};
