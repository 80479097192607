import optimizerIamge from "src/assets/marketing-mix-optimizer-launch-main-image.png";
import { EmptyViewerWrapper } from "./EmptyViewerWrapper";

export const OnboardingViewerOptimizationPage = () => {
    return (
        <EmptyViewerWrapper
            title={<>We're Busy Building Your Optimizer Dashboard!</>}
            description={
                <>
                    We’ve reached out to your administrator to set up the necessary data channels.
                    <br /> In the meantime, here is a sneak peek to what is possible.
                </>
            }
            blog={{
                link: "https://www.prescient-ai.io/blog/introducing-marketing-mix-optimizer",
                image: optimizerIamge,
                writter: "Michael True • 26 Sep 2023",
                heading: "Introducing Our Newest Feature: Marketing Mix Optimizer",
                description:
                    "We’re thrilled to announce the public launch of our newest feature, which helps our clients shape their media plan to maximize revenue and ROAS outcomes.",
            }}
        />
    );
};
