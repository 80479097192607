import { FC } from "react";
import { FormControl, MenuItem, Select, SelectChangeEvent } from "@mui/material";
import { useSelector } from "react-redux";

import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { defaultRevenueSourceSelector, isAmazonConnectedSelector } from "src/reduxState/slices/connectorsSlice";
import { getTargetOptions } from "src/services/homeDashboard/homePageTab";

interface ITargetSelect {
    value: string;
    handleChannelGrpByChange: (grp: string) => void;
    chartTitle: string;
    isDisabled?: boolean;
    isUpgraded?: boolean;
}

export const TargetSelect: FC<ITargetSelect> = ({
    value,
    handleChannelGrpByChange,
    chartTitle,
    isDisabled = false,
    isUpgraded = false,
}) => {
    const analytics = useAnalyticsService();
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const isAmazonConnected = useSelector(isAmazonConnectedSelector);
    const options = getTargetOptions(defaultRevenueSource, isAmazonConnected);

    const changeHanlder = (e: SelectChangeEvent<string>) => {
        const { value } = e.target;
        handleChannelGrpByChange(value);

        const getLabel = options.find((op) => op.id === value)?.label || "";
        analytics.logEvent("Dashboard Chart Filter Applied ", {
            Chart: chartTitle,
            "Revenue source": getLabel,
        });
    };

    return (
        <FormControl sx={{ minWidth: 120, backgroundColor: "white", textTransform: "capitalize" }} size="small">
            <Select
                labelId="demo-select-small-label"
                id="demo-select-small"
                value={value}
                onChange={changeHanlder}
                sx={{
                    height: isUpgraded ? "56px" : "50px",
                    "& .MuiSvgIcon-root": {
                        color: "#000000",
                    },
                }}
                variant="outlined"
                disabled={isDisabled}
            >
                {options.map((grp) => {
                    return (
                        <MenuItem
                            sx={{ textTransform: "capitalize" }}
                            value={grp.id}
                            key={grp.id}
                            disabled={grp.isDisabled}
                        >
                            {grp.label.replace(/_/g, " ")}
                        </MenuItem>
                    );
                })}
            </Select>
        </FormControl>
    );
};
