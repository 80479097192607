import React from "react";
import { IconButton, Paper, Popper, Tooltip, Typography } from "@mui/material";
import { useLocation } from "react-router-dom";
import { Theme } from "@mui/material/styles";
import { css } from "@emotion/css";
import { Stack, useTheme } from "@mui/system";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import { getTimeDelta } from "../../../../services/homeDashboard/homeDashboard";
import { useAnalyticsService } from "../../../../services/analytics/useAnalyticsService";
import { BACKGROUND_GRAY } from "../../../../consts/colors";
import { getPathForAnalytics } from "../../../../services/utils";
import { SVGIconRenderer } from "../../../SVGIconRenderer/SVGIconRenderer";
import { BetaTooltip } from "../../../ConnectorsComponent/NewConnectorComponents/BetaTooltip";

const useStyles = (theme: Theme, isMarginBottom?: boolean) => ({
    displayRow: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        marginBottom: isMarginBottom ? "19px" : "0",
    }),
    popperRow: css({
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    }),
    popper: css({
        borderRadius: theme.spacing(0.5),
        padding: `${theme.spacing(0.5)} ${theme.spacing(1)}`,
        marginTop: theme.spacing(1),
        backgroundColor: BACKGROUND_GRAY,
        display: "flex",
        flexDirection: "column",
    }),
    channelName: css({
        color: "white",
        fontStyle: "normal",
        marginRight: theme.spacing(1),
    }),
    time: css({
        color: "white",
        fontStyle: "italic",
    }),
    arrow: css({
        overflow: "hidden",
        position: "absolute",
        width: "1em",
        height: "0.71em",
        boxSizing: "border-box",
        marginTop: "-10px",
        color: BACKGROUND_GRAY,
        "&::before": {
            content: '""',
            margin: "auto",
            display: "block",
            width: "100%",
            height: "100%",
            backgroundColor: "currentColor",
            transform: "rotate(45deg)",
            transformOrigin: "0 100%",
        },
    }),
});

interface IDashboardHeader {
    text: React.ReactNode;
    dataForPopper?: IDataForPopper[];
    isMarginBottom?: boolean;
    isBetaCard?: boolean;
    tooltipRef?: React.RefObject<any>;
    tooltip?: React.ReactNode;
    tooltipPlacement?:
        | "bottom"
        | "left"
        | "right"
        | "top"
        | "bottom-end"
        | "bottom-start"
        | "left-end"
        | "left-start"
        | "right-end"
        | "right-start"
        | "top-end"
        | "top-start"
        | undefined;
}

export interface IDataForPopper {
    label: string;
    date: Date;
}

export const DashboardHeader: React.FC<IDashboardHeader> = ({
    text,
    isMarginBottom = true,
    dataForPopper,
    isBetaCard = false,
    tooltipRef,
    tooltip = "",
    tooltipPlacement,
}) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const [arrowRef, setArrowRef] = React.useState<HTMLElement | null>(null);
    const analyticsService = useAnalyticsService();
    const location = useLocation();
    const classes = useStyles(useTheme(), isMarginBottom);

    const open = Boolean(anchorEl);
    const id = open ? "simple-popper" : undefined;

    const handlePopperOpen = (e: React.MouseEvent<HTMLDivElement>) => {
        setAnchorEl(e.currentTarget);
        analyticsService.logEvent("Source Hovered", {
            Page: getPathForAnalytics(location.pathname),
            "Chart name": text,
        });
    };

    return (
        <div className={classes.displayRow}>
            <Stack direction="row" gap={1} alignItems="center">
                <Typography variant="h2">{text}</Typography>
                {tooltip && (
                    <Tooltip placement={tooltipPlacement} title={tooltip} ref={tooltipRef || null}>
                        <InfoOutlinedIcon fontSize="large" color="primary" />
                    </Tooltip>
                )}
                {isBetaCard && <BetaTooltip isTooltip={false} />}
            </Stack>
            {dataForPopper ? (
                <>
                    <div
                        style={{ display: "flex" }}
                        onMouseLeave={() => setAnchorEl(null)}
                        onMouseEnter={handlePopperOpen}
                        aria-describedby={id}
                    >
                        <SVGIconRenderer icon="infoIcon" width="20px" height="20px" />
                    </div>
                    <Popper
                        id={id}
                        open={open}
                        anchorEl={anchorEl}
                        modifiers={[
                            {
                                name: "preventOverflow",
                                enabled: true,
                                options: {
                                    rootBoundary: "window",
                                },
                            },
                            {
                                name: "arrow",
                                enabled: true,
                                options: {
                                    element: arrowRef,
                                },
                            },
                        ]}
                    >
                        <Paper>
                            <span className={classes.arrow} ref={setArrowRef} />
                            <div className={classes.popper}>
                                {dataForPopper && dataForPopper.length
                                    ? dataForPopper.map((data) => (
                                          <div className={classes.popperRow} key={data.label}>
                                              <Typography variant="body1" className={classes.channelName}>
                                                  {data.label}
                                              </Typography>
                                              <Typography variant="body1" className={classes.time}>
                                                  {getTimeDelta(data.date)}
                                              </Typography>
                                          </div>
                                      ))
                                    : null}
                            </div>
                        </Paper>
                    </Popper>
                </>
            ) : null}
        </div>
    );
};
