import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

export interface IState {
    connectLaterCountOrg: { [key: string]: number };
}

const initialState: IState = {
    connectLaterCountOrg: {
        eCommerce: 0,
        paidMarketing: 0,
        webAnalytics: 0,
    },
};

const connectLaterCountSlice = createSlice({
    name: "connectLaterCount",
    initialState,
    reducers: {
        setConnectLaterCount: (state, { payload }) => {
            state.connectLaterCountOrg[payload] = state.connectLaterCountOrg[payload] + 1;
        },
    },
});

export const { setConnectLaterCount } = connectLaterCountSlice.actions;

export default connectLaterCountSlice.reducer;

// Selectors
export const connectLaterCountSelector = (state: RootState) => state.connectLaterCount;
