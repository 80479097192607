import { Stack } from "@mui/system";
import { format } from "date-fns";
import queryString from "query-string";
import React, { useEffect, useState } from "react";
import { Range } from "react-date-range";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router";

import { NEW_CONNECTOR_PATH } from "src/consts/path/path";
import wallet from "../../assets/wallet.png";
import ReactDateRangeSelect from "../../components/DateRangeFilter/ReactDateRange";
import { getPastPeriod } from "../../components/DateRangeFilter/reactDateRangeUtils";
import { EmailAggregate } from "../../components/EmailComponents/EmailAggregate";
import { EmailCampaignPercentageTable } from "../../components/EmailComponents/EmailCampaignPercentageTable/EmailCampaignPercentageTable";
import { EmailCampaignTable } from "../../components/EmailComponents/EmailCampaignTable/EmailCampaignTable";
import { EmailSubHeader } from "../../components/EmailComponents/EmailSubHeader/EmailSubHeader";
import { InsightsModal } from "../../components/EmailComponents/InsightsModal/InsightsModal";
import { Message } from "../../components/Message/Message";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import Loader from "../../components/core/Loader/Loader";
import { Choice, EMAIL_ATTRIBUTION_HEADERS, IHeadCell } from "../../consts/emailPage/emailPage";
import { useLazyGetEmailPageQuery } from "../../reduxState/apis/emailPageApi";
import {
    formattedCompareDateSelector,
    formattedDateSelector,
    isCompareSelector,
    minDateSelector,
    setCompare,
    setCompareDateFilter,
} from "../../reduxState/slices/dateFilterSlice";
import { emailInsightsModalSelector } from "../../reduxState/slices/emailInsightsModalSlice";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import {
    getComparisionEmailPerformanceData,
    getFilteredEmailPeformance,
    getTotalAmountForEmail,
} from "../../services/emailPage/emailPage";
import { getDefaultRange } from "../../services/performancePage/performancePage";
import { convertObjectToQueryParam, handleFilteredData } from "../../services/utils";
import { useStylesForCampaingPage } from "../CampaignPageV2/CampaignPage";

interface ILocationState {
    state: {
        showInsightsTab?: boolean;
    };
    search: string;
}

export const handleSelectedEmailColsHidden = (performanceCols: IHeadCell[]) => {
    const hiddenCols = performanceCols.filter((c) => c.hidden);
    const latestQueryParamsObj = queryString.parse(window.location.search);
    const selectedCols: IHeadCell[] = performanceCols.map((h) => {
        if (latestQueryParamsObj?.excluded_columns?.includes(h.id)) {
            return { ...h, hidden: true };
        }
        if (
            latestQueryParamsObj.campaign_id ||
            (hiddenCols.map((c) => c.id).includes(h.id) && latestQueryParamsObj?.excluded_columns)
        ) {
            return { ...h, hidden: false };
        }
        return h;
    });
    return selectedCols;
};

export const Email = () => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const classes = useStylesForCampaingPage();
    const { search: locationSearch } = useLocation() as ILocationState;

    const [selectedTableColumns, setselectedTableColumns] = useState<IHeadCell[]>([...EMAIL_ATTRIBUTION_HEADERS]);
    const [page, setPage] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("emailSends");

    const minDate = useSelector(minDateSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const compareToPast = useSelector(isCompareSelector);
    const selectFormattedDates = useSelector(formattedDateSelector);
    const companyId = useSelector(selectCurrentlyViewingId);
    const insightsModalList = useSelector(emailInsightsModalSelector);
    const pastDateFilter = useSelector(formattedCompareDateSelector);

    const [
        getEmailPage,
        {
            data: allCurrentData = {
                data: [],
            },
            isFetching,
            isError,
            error,
        },
    ] = useLazyGetEmailPageQuery();

    const [
        getPastEmailPage,
        {
            data: allPastData = {
                data: [],
            },
            isFetching: isPastDataFetching,
        },
    ] = useLazyGetEmailPageQuery();

    const { data: currentData } = allCurrentData;
    const { data: pastData } = allPastData;

    const filterQueryParamsObj = queryString.parse(window.location.search);

    const totalAmountForSelectedRange = getTotalAmountForEmail(currentData || []);
    const performanceData = getFilteredEmailPeformance(currentData, search, filterQueryParamsObj);
    const totalAmountWithFilter = getTotalAmountForEmail(performanceData || []);

    const percentagePerformanceDataForSelectedRange = getComparisionEmailPerformanceData(
        currentData,
        pastData,
        "",
        {},
        true,
    );
    const finalPercentagePerformanceData = getComparisionEmailPerformanceData(
        currentData,
        pastData,
        search,
        filterQueryParamsObj,
        false,
    );

    const getPastPerformanceData = (pastDateRange: Range[]) => {
        const { startDate, endDate } = pastDateRange[0];
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            getPastEmailPage({
                orgId: companyId,
                data: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                },
            })
                .unwrap()
                .then(() => {
                    const filterQueryParamsObj = queryString.parse(window.location.search);
                    const updateDateFilter = {
                        ...filterQueryParamsObj,
                        comparison_start_date: formattedStartDate,
                        comparison_end_date: formattedEndDate,
                    };
                    navigate({
                        search: convertObjectToQueryParam(updateDateFilter),
                    });
                });
        }
    };

    const getCurrentPerformanceData = (dateRange: Range[]) => {
        const { startDate, endDate } = dateRange[0];
        if (startDate && endDate) {
            const formattedStartDate = format(startDate, "yyyy-MM-dd");
            const formattedEndDate = format(endDate, "yyyy-MM-dd");
            getEmailPage({
                orgId: companyId,
                data: {
                    start_date: formattedStartDate,
                    end_date: formattedEndDate,
                },
            })
                .unwrap()
                .then(() => {
                    const filterQueryParamsObj = queryString.parse(window.location.search);
                    const updateDateFilter: any = {
                        ...filterQueryParamsObj,
                        start_date: formattedStartDate,
                        end_date: formattedEndDate,
                    };

                    if (location.pathname.includes("email")) {
                        navigate({
                            search: convertObjectToQueryParam(updateDateFilter),
                            hash: location.hash,
                        });
                    }
                });
        }
    };

    useEffect(() => {
        const defaultRange = getDefaultRange(selectFormattedDates);
        getCurrentPerformanceData(defaultRange);
        if (locationSearch.includes("comparison_start_date")) {
            const defaultPastDate = getPastPeriod(defaultRange, true);
            dispatch(setCompare({ isCompareOn: true }));
            dispatch(setCompareDateFilter({ compareDate: JSON.stringify(defaultPastDate) }));
        }
        setPage(0);
        setSearch("");
        setOrder("desc");
        setOrderBy("emailSends");
    }, []);

    useEffect(() => {
        if (compareToPast) {
            getPastPerformanceData(pastDateFilter);
        }
    }, [pastDateFilter, compareToPast]);

    useEffect(() => {
        getCurrentPerformanceData(getDefaultRange(selectFormattedDates));
    }, [JSON.stringify(selectFormattedDates), companyId]);

    useEffect(() => {
        const selectedCols = handleSelectedEmailColsHidden([...EMAIL_ATTRIBUTION_HEADERS]);
        setselectedTableColumns(selectedCols);
    }, [filterQueryParamsObj.excluded_columns]);

    const goToCreateConnPage = () => {
        navigate(`/org/${orgCode}${NEW_CONNECTOR_PATH}`);
    };

    const onSearchChange = (e: React.ChangeEvent<any>) => {
        setPage(0);
        setSearch(e.target.value);
    };

    const handleTableColSelection = (column: IHeadCell) => {
        const columns = selectedTableColumns.map((c) => {
            if (c.id === column.id) {
                if (!c.hidden && c.id === "campaignId") {
                    const queryParamsObj = { ...filterQueryParamsObj };
                    delete queryParamsObj.campaign_id;
                    navigate({
                        search: convertObjectToQueryParam(queryParamsObj),
                    });
                }
                handleFilteredData("excluded_columns", { id: c.id }, navigate);
                return { ...c, hidden: !c.hidden };
            } else {
                return c;
            }
        });
        setselectedTableColumns(columns);
    };

    const commonPropsForTables = {
        selectedTableColumns,
        page,
        setPage,
        setOrder,
        order,
        orderBy,
        setOrderBy,
    };

    return (
        <div>
            <PageHeader
                pageHeading="Email"
                dateFilterComponent={<ReactDateRangeSelect minDate={minDate} compareView={true} />}
                tabs={
                    currentData.length ? (
                        <EmailAggregate
                            totalAmount={totalAmountForSelectedRange}
                            finalPercentagePerformanceData={percentagePerformanceDataForSelectedRange}
                            isLoading={false}
                        />
                    ) : (
                        <></>
                    )
                }
            />
            <PageContainer className={classes.pageContainer}>
                <EmailSubHeader
                    currentDateFilter={selectFormattedDates}
                    performanceData={performanceData}
                    selectedTableColumns={selectedTableColumns}
                    onSearchChange={onSearchChange}
                    handleTableCol={handleTableColSelection}
                    originalPerformanceData={
                        compareToPast && pastData && currentData
                            ? [...new Set([...pastData, ...currentData])]
                            : currentData || []
                    }
                    compareToPast={compareToPast}
                    finalPercentagePerformanceData={finalPercentagePerformanceData}
                    order={order}
                    orderBy={orderBy}
                    searchCampaign={search}
                    setselectedTableColumns={setselectedTableColumns}
                />
                {isPastDataFetching || isFetching ? (
                    <Loader />
                ) : currentData.length ? (
                    <div className="performanceTable">
                        {compareToPast && pastData ? (
                            <EmailCampaignPercentageTable
                                currentDateFilter={selectFormattedDates}
                                finalPercentagePerformanceData={finalPercentagePerformanceData}
                                {...commonPropsForTables}
                            />
                        ) : (
                            <EmailCampaignTable
                                isImagesInTable={true}
                                tableData={performanceData}
                                totalAmount={totalAmountWithFilter}
                                {...commonPropsForTables}
                            />
                        )}
                    </div>
                ) : (
                    <Message
                        title="You don’t have a Klaviyo connector yet!"
                        subtitle="Click the picture above to connect your first source!"
                        icon={wallet}
                        filters={undefined}
                        onIconClick={goToCreateConnPage}
                    />
                )}
            </PageContainer>
            {insightsModalList?.length ? (
                <Stack
                    flexDirection="row-reverse"
                    gap="10px"
                    sx={({ zIndex }: any) => ({
                        position: "fixed",
                        bottom: 0,
                        right: "30px",
                        zIndex: zIndex?.appBar + 1,
                    })}
                >
                    {insightsModalList.map((modalData) => (
                        <React.Fragment key={modalData.data.campaignId}>
                            <InsightsModal modalInfo={modalData} />
                        </React.Fragment>
                    ))}
                </Stack>
            ) : null}
        </div>
    );
};
