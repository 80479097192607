import { isAfter } from "date-fns";
import { toDate } from "date-fns-tz";
import { Alert, LinearProgress, Typography, linearProgressClasses } from "@mui/material";
import { Stack } from "@mui/system";
import styled from "styled-components";

import { IGoal } from "../../../interfaces/entities/IGoal";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { ContextType } from "../../../consts/HomePage.ts/HomePage";
import { IGoalItem } from "../../../interfaces/IGoal/IGoalItem";
import { GoalProcess } from "./GoalProcess";

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    [`& .${linearProgressClasses.bar}`]: {
        backgroundImage: "linear-gradient(to right, rgba(1, 148, 255, 1) , rgba(11, 151, 134, 1))",
    },
}));

const GoalAchievedBanner = () => {
    return (
        <Alert
            sx={{
                backgroundColor: "#9747FF",
                ".MuiAlert-message": {
                    padding: "4px 0",
                },
            }}
            variant="filled"
            icon={false}
        >
            <Stack direction="row" gap="10px" alignItems="center">
                <SVGIconRenderer icon="trophyIcon" strokeColor="white" />
                <Typography color="white" fontWeight="bold">
                    Goal achieved!
                </Typography>
            </Stack>
        </Alert>
    );
};

export const GoalItem = ({
    goal,
    openEditGoalModal,
}: {
    goal: IGoalItem;
    openEditGoalModal: (goal: IGoal) => void;
}) => {
    const campaignCount = goal.selectedCampaignsOrChannels.length;
    const isReportingDataAvailable = goal.reportingData !== null;

    const handleGoalClick = () => {
        openEditGoalModal(goal);
    };

    return (
        <Stack
            onClick={handleGoalClick}
            gap="7px"
            sx={{
                width: "100%",
                borderBottom: "1px solid rgba(217, 217, 217, 1)",
                padding: "5px",
                paddingBottom: "20px",
                "&:hover": {
                    cursor: "pointer",
                    backgroundColor: "rgba(0, 0, 0, 0.1)",
                },
            }}
        >
            {goal.goalPercentage >= 100 &&
                (["roas", "cac"].includes(goal.goalType) && isAfter(new Date(), toDate(goal.endDate))
                    ? GoalAchievedBanner()
                    : !["roas", "cac"].includes(goal.goalType) && GoalAchievedBanner())}
            <Typography
                fontWeight="bold"
                sx={{
                    display: "-webkit-box",
                    WebkitLineClamp: "3",
                    WebkitBoxOrient: "vertical",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                }}
            >
                {`${goal.title} ${
                    goal.context !== ContextType.PORTFOLIO
                        ? `${
                              goal.context === ContextType.CAMPAIGN
                                  ? `with ${campaignCount} campaign${campaignCount > 1 ? "s" : ""}`
                                  : `in ${goal.selectedCampaignsOrChannels.join(", ")}`
                          }`
                        : ""
                }`}
            </Typography>
            {isReportingDataAvailable ? (
                <>
                    <Typography>{goal.currentReportedTitle}</Typography>{" "}
                    <BorderLinearProgress
                        variant="determinate"
                        value={goal.goalPercentage > 100 ? 100 : goal.goalPercentage}
                    />
                </>
            ) : (
                <GoalProcess />
            )}
        </Stack>
    );
};
