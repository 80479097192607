import { Button, DialogActions, DialogContent, DialogTitle, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { IAction } from "./CustomModal";

export const DialogElements = ({
    title,
    closeIcon,
    instanceSlot,
    actions,
    closeModal,
}: {
    title: JSX.Element | string;
    closeIcon: boolean;
    instanceSlot: JSX.Element;
    actions: {
        [key: number]: IAction;
    };
    closeModal?: () => void;
}) => {
    const getActionKeys = Object.keys(actions).map((key) => +key);

    return (
        <>
            <DialogTitle component="div" sx={{ padding: "24px 24px 0 24px" }}>
                <Stack direction="row" justifyContent="space-between" gap={1}>
                    <Typography variant="h3">{title}</Typography>
                    {closeIcon && closeModal && (
                        <SVGIconRenderer
                            onClick={closeModal}
                            aria-label="close"
                            icon="closeIcon"
                            width="24px"
                            height="24px"
                        />
                    )}
                </Stack>
            </DialogTitle>
            <DialogContent sx={{ padding: "24px", paddingTop: "24px !important" }}>
                <Stack spacing={2}>{instanceSlot}</Stack>
            </DialogContent>
            <DialogActions sx={{ padding: "0 24px 24px 24px" }}>
                <Stack direction="row" justifyContent="space-between" width="100%" gap={1}>
                    <Stack direction="row" gap={1}>
                        {getActionKeys.includes(3) && (
                            <Button
                                variant={actions[3].variant || "text"}
                                color="primary"
                                size="medium"
                                sx={actions[3].style}
                                onClick={actions[3].onClick}
                                disabled={actions[3].disabled}
                                startIcon={actions[3].startIcon}
                            >
                                {actions[3].label}
                            </Button>
                        )}
                        {getActionKeys.includes(2) && (
                            <Button
                                variant={actions[2].variant || "outlined"}
                                color="primary"
                                size="medium"
                                sx={actions[2].style}
                                onClick={actions[2].onClick}
                                disabled={actions[2].disabled}
                                startIcon={actions[2].startIcon}
                            >
                                {actions[2].label}
                            </Button>
                        )}
                    </Stack>
                    <Button
                        color="primary"
                        size="medium"
                        sx={actions[1].style}
                        onClick={actions[1].onClick}
                        disabled={actions[1].disabled}
                        endIcon={actions[1].endIcon}
                        startIcon={actions[1].startIcon}
                        variant={actions[1].variant || "contained"}
                    >
                        {actions[1].label}
                    </Button>
                </Stack>
            </DialogActions>
        </>
    );
};
