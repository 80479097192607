import React, { Fragment } from "react";
import { Checkbox, FormControlLabel, FormGroup, ListItem, List, Typography, Stack, ListSubheader } from "@mui/material";
import { css } from "@emotion/css";
import groupBy from "lodash/groupBy";

import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { ICard } from "src/interfaces/entities/IScenario";
import { IDictionary } from "src/interfaces/IDictionary";

interface IInitialDnDState {
    draggedFrom: number;
    draggedTo: number;
    isDragging: boolean;
    originalOrder: ICard[];
    updatedOrder: ICard[];
}

interface ICustomColumnFilter {
    cards: ICard[];
    isDraggable: boolean;
    groupByName?: string;
    onClickHandleOpen?: () => void;
    dragAndDrop?: IInitialDnDState;
    columnHeader?: IDictionary;

    handleTableCol: (column: any) => void;
    onDragStart?: (e: React.DragEvent<HTMLLIElement>) => void;
    onDragOver?: (e: React.DragEvent<HTMLLIElement>) => void;
    onDrop?: () => void;
    onDragLeave?: () => void;
    updateColumnsInSettingsApi?: (selectedCols: any[]) => void;
    buttonStyleClass?: string;
    icon?: JSX.Element;
}

const useStyles = () => ({
    buttonClass: css({
        "& button": {
            border: 0,
            fontSize: "15px",
            padding: "6px 8px",
        },
    }),
    menuClass: css({
        "& .MuiPaper-root": {
            maxHeight: "calc(100vh - 500px)!important",
        },
    }),
    iconClass: css({ position: "absolute", right: "-20px", cursor: "move", width: "16px" }),
    filterHeading: css({ padding: "6px 16px", color: "rgba(0, 0, 0, 0.6)", fontSize: "14px" }),
});

export const CustomColumnFilter: React.FC<ICustomColumnFilter> = ({
    onClickHandleOpen,
    cards,
    groupByName = "",
    isDraggable,
    updateColumnsInSettingsApi,
    dragAndDrop,
    handleTableCol,
    onDragStart,
    onDragOver,
    onDrop,
    onDragLeave,
    columnHeader,
    buttonStyleClass,
    icon,
}) => {
    const classes = useStyles();

    const lightMenuConditionalProps =
        isDraggable && onClickHandleOpen && updateColumnsInSettingsApi
            ? {
                  menuCloseHandle: () => updateColumnsInSettingsApi(cards),
                  onClickHandleOpen: () => onClickHandleOpen(),
              }
            : {};

    const listItemConditionalProps =
        isDraggable && onDragStart && onDragOver && onDrop && onDragLeave
            ? {
                  draggable: true,
                  onDragStart: (e: React.DragEvent<HTMLLIElement>) => onDragStart(e),
                  onDragOver: (e: React.DragEvent<HTMLLIElement>) => onDragOver(e),
                  onDrop: () => onDrop(),
                  onDragLeave: () => onDragLeave(),
              }
            : {};

    const filterRows = Object.entries(groupBy(cards, groupByName));

    return (
        <LightMenu
            extraButtonClass={buttonStyleClass || classes.buttonClass}
            menuClass={classes.menuClass}
            title="Columns"
            isList={false}
            overFlow={true}
            placement="bottom-end"
            transformOrigin="top right"
            startIcon={icon || <SVGIconRenderer icon="editIcon" />}
            id="column-filter"
            dataCy="columnFilter"
            {...lightMenuConditionalProps}
        >
            <List style={{ padding: "8px 0" }}>
                <Typography className={classes.filterHeading}>Columns</Typography>

                {filterRows.map(([key, element]) => {
                    const columns = element.map((card, index) => {
                        if (card.id === "campaignName") {
                            return;
                        }
                        return (
                            <ListItem
                                style={{
                                    padding: "6px 16px",
                                    minHeight: "54px",
                                    opacity:
                                        isDraggable && dragAndDrop && dragAndDrop.draggedTo === Number(index) ? 0 : 1,
                                }}
                                key={card.id}
                                data-position={index}
                                {...listItemConditionalProps}
                            >
                                <FormGroup sx={{ width: "100%" }}>
                                    <FormControlLabel
                                        control={
                                            <Checkbox
                                                checked={!card.hidden}
                                                onChange={() => handleTableCol(card)}
                                                onKeyDown={(e) => e.key === "Enter" && handleTableCol(card)}
                                                inputProps={{ "aria-label": "controlled" }}
                                                value={card.label}
                                            />
                                        }
                                        id={card.id}
                                        data-cy={card.id}
                                        sx={{
                                            width: "100%",
                                            position: "relative",
                                            cursor: isDraggable ? "move" : "pointer",
                                        }}
                                        label={
                                            <Stack flexDirection="row" justifyContent="space-between">
                                                <Typography> {card.label}</Typography>
                                                {isDraggable && (
                                                    <>
                                                        <SVGIconRenderer
                                                            icon="overflowIcon"
                                                            strokeColor="#bababa"
                                                            className={classes.iconClass}
                                                        />
                                                        <SVGIconRenderer
                                                            icon="overflowIcon"
                                                            strokeColor="#bababa"
                                                            className={classes.iconClass}
                                                            style={{
                                                                right: "-15px",
                                                            }}
                                                        />
                                                    </>
                                                )}
                                            </Stack>
                                        }
                                    />
                                </FormGroup>
                            </ListItem>
                        );
                    });
                    if (key !== "other" && key !== "undefined") {
                        return (
                            columnHeader && (
                                <Fragment key={key}>
                                    <ListSubheader key={key}>{columnHeader[key]}</ListSubheader>
                                    {columns}
                                </Fragment>
                            )
                        );
                    }
                    return <Fragment key={key}>{columns}</Fragment>;
                })}
            </List>
        </LightMenu>
    );
};
