import baseCreateApi from "./baseCreateApi";
import { IEmailDTO } from "../../interfaces/DTO/IEmailDTO";
import { IEmail } from "../../interfaces/entities/IEmail";
import { formatEmailResponse } from "../../services/emailPage/emailPage";

interface IEmailPageBody {
    orgId: string;
    data: {
        end_date: string;
        start_date: string;
    };
}

export const emailPage = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getEmailPage: builder.query({
            query: (body: IEmailPageBody) => ({
                url: `organizations/${body.orgId}/performance/metric_attribution_email?start_date=${body.data.start_date}&end_date=${body.data.end_date}&connectors=klaviyo`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IEmailDTO[] }): { data: IEmail[] } => {
                const updateResponse: IEmail[] = response.data?.map((d: any) => ({
                    ...d,
                    type: d.campaign_id.length <= 8 ? "Flow" : "Campaign",
                }));
                return {
                    data: formatEmailResponse(updateResponse),
                };
            },
        }),
    }),
});

export const { useLazyGetEmailPageQuery } = emailPage;
