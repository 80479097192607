import * as React from "react";
import {
    Switch,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography,
} from "@mui/material";
import Spinner from "@mui/material/CircularProgress";
import { Stack } from "@mui/system";

import { Button } from "../core/Button/Button";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import {
    useStylesForContainedBtn,
    useStylesForHeaderInTable,
    useStylesForOutlinedBtn,
    useStylesTable,
} from "../../consts/StylesForTables";
import { IRevenueTag } from "../../reduxState/apis/revenueTagAPi";

export interface ITagModal {
    isApiLoading: boolean;
    data: IRevenueTag[];
    onTagSave: (values: IRevenueTagForTable[]) => void;
    toggleTagModal: () => void;
}

export interface IRevenueTagForTable {
    title: string;
    info: string;
    name: string;
    checked: boolean;
    id: string | undefined;
}

export const TagModal: React.FC<ITagModal> = ({ isApiLoading, data, onTagSave, toggleTagModal }) => {
    const classes = { ...useStylesForHeaderInTable(), ...useStylesTable() };
    const tagNames = data.map((d) => d.tagName);

    const getId = (tagName: string) => {
        return data.find((d) => d.tagName === tagName)?.id;
    };

    const [tagTableRows, setTagTableRows] = React.useState<IRevenueTagForTable[]>([
        {
            title: "Wholesale",
            info: "Prescient identifies wholesale tags by looking for the phrase ‘whole’ within your Shopify order tags. This check is not case sensitive. This means that any tags containing ‘whole’ will be captured (e.g. ‘wholesale’, ‘whole sale', or ‘Whole Sale’). ",
            name: "whole",
            checked: tagNames.includes("whole"),
            id: getId("whole"),
        },
        {
            title: "Trade",
            info: "Prescient identifies trade tags by looking for the phrase ‘trade’ within your Shopify order tags. This check is not case sensitive. This means that any tags containing ‘trade’ will be captured (e.g. ‘Trade’, ‘TRADE', ' or ‘rTrade’).",
            name: "trade",
            checked: tagNames.includes("trade"),
            id: getId("trade"),
        },
    ]);

    const handleTagChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTag = tagTableRows.map((tr) => {
            if (tr.name === e.target.name) {
                return { ...tr, checked: !tr.checked };
            }
            return tr;
        });

        setTagTableRows(selectedTag);
    };

    return (
        <Stack gap="15px">
            <Typography>
                Choose Shopify order tags that you want excluded from your attribution results. Orders that shouldn't be
                attributed to marketing (such as wholesale, retail, or trade show orders) should be excluded to maximize
                attribution model accuracy.
            </Typography>
            <Typography fontWeight="bold">
                This will cause historical attribution results to change. Please note that changes won't be visible for
                up to 72 hours.
            </Typography>
            <TableContainer
                sx={{
                    border: "1px solid #E0E0E0",
                    borderBottom: 0,
                }}
                className={classes.tableWrapper}
            >
                <Table className={classes.table}>
                    <TableHead className={classes.tableHeader}>
                        <TableRow className={classes.headerRow}>
                            <TableCell className={classes.tableCell}>Tag Type</TableCell>
                            <TableCell className={classes.tableCell} align="right">
                                Exclude From Modal
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className={classes.tableBody}>
                        {tagTableRows.map((row) => {
                            return (
                                <TableRow key={row.title}>
                                    <TableCell>
                                        <Stack direction="row" gap="10px" alignItems="center">
                                            <Typography>{row.title}</Typography>
                                            <Tooltip arrow title={row.info}>
                                                <Typography>
                                                    <SVGIconRenderer
                                                        icon="infoIcon"
                                                        height="14px"
                                                        width="14px"
                                                        style={{ margintTop: "5px" }}
                                                    />
                                                </Typography>
                                            </Tooltip>
                                        </Stack>
                                    </TableCell>
                                    <TableCell
                                        sx={{
                                            display: "flex",
                                            justifyContent: "end",
                                            flexDirection: "row",
                                        }}
                                        align={"right"}
                                    >
                                        <Switch
                                            name={row.name}
                                            checked={row.checked}
                                            onChange={(e) => handleTagChange(e)}
                                            inputProps={{ "aria-label": "controlled" }}
                                            disabled={isApiLoading}
                                        />
                                    </TableCell>
                                </TableRow>
                            );
                        })}
                    </TableBody>
                </Table>
            </TableContainer>
            <Stack direction="row" justifyContent="end" alignItems="center" gap="15px" sx={{ marginTop: "30px" }}>
                <Button
                    onClick={toggleTagModal}
                    variant="outlined"
                    className={useStylesForOutlinedBtn}
                    disabled={isApiLoading}
                >
                    Cancel
                </Button>
                <Button
                    onClick={() => onTagSave(tagTableRows)}
                    variant="contained"
                    className={useStylesForContainedBtn}
                    disabled={isApiLoading}
                    sx={{ width: "120px!important" }}
                >
                    {isApiLoading ? <Spinner size={25} /> : "Save changes"}
                </Button>
            </Stack>
        </Stack>
    );
};
