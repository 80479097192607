import React from "react";
import { FormikHelpers } from "formik";
import { useSelector } from "react-redux";
import { HeaderSize, Modal } from "../core/Modal/Modal";
import { MemberForm } from "../MemberForm/MemberForm";
import { userIdSelector } from "../../reduxState/slices/userSlice";
import { IMember } from "../../interfaces/entities/IMember";
import { selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";

interface IMembersModalForm {
    isOpen: boolean;
    member: IMember | null;
    isFormEditMode: boolean;
    currentCompany: IOrganization;
    deleteDisableBtn: boolean;
    onCloseModal(): void;
    onSubmit(members: IMember, formikHelpers: FormikHelpers<IMember>): void;
    onDelete(items: IMember): void;
}

export const MembersModalForm: React.FC<IMembersModalForm> = ({
    isOpen,
    member,
    onCloseModal,
    isFormEditMode,
    onSubmit,
    onDelete,
    currentCompany,
    deleteDisableBtn,
}) => {
    const currentUser = useSelector(userIdSelector);
    const currentOrgId = useSelector(selectCurrentlyViewingId);

    const role =
        member &&
        member.rolesByCompanyId &&
        member.rolesByCompanyId[currentOrgId] &&
        member.rolesByCompanyId[currentOrgId].role;

    return (
        <Modal
            isOpen={isOpen}
            headerSize={HeaderSize.SMALL}
            title={isFormEditMode ? "Edit member" : "New Member"}
            canOutsideClickClose={true}
            onClose={onCloseModal}
            content={
                <MemberForm
                    initialValues={member as IMember}
                    isEditMode={isFormEditMode}
                    isCurrentUser={Boolean(member && member.id === currentUser)}
                    onSubmit={onSubmit}
                    onCancel={onCloseModal}
                    onDelete={onDelete}
                    role={role || "member"}
                    currentCompany={currentCompany}
                    deleteDisableBtn={deleteDisableBtn}
                />
            }
        />
    );
};
