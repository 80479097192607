import React from "react";
import { Avatar, Box, Button, Typography } from "@mui/material";
import { css } from "@emotion/css";

import { BLACK_COLOR, RED, WHITE, GREYISH } from "../../consts/colors";
import { AlignItems, Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";

export const useStyles = () => ({
    modalHeader: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    }),
    container: css({
        display: "flex",
        position: "fixed",
        justifyContent: "center",
        alignContent: "center",
        zIndex: 1111,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.38)",
        backdropFilter: "blur(3px)",
    }),
    modalContent: css({
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        width: "408px",
        height: "auto",
        justifySelf: "center",
        alignSelf: "center",
        backgroundColor: `${WHITE}`,
        border: "0.5px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        boxShadow: "0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    }),
    headline: css({
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        fontSize: "22px",
        display: "flex",
        alignItems: "center",
        color: `${BLACK_COLOR}`,
        margin: 0,
    }),
    additionalInfo: css({
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        display: "flex",
        color: `${BLACK_COLOR}`,
        marginTop: "28px",
    }),
    imageContainerClass: css({
        height: "32px !important",
        width: "32px !important",
        marginRight: "8px !important",
    }),
    customAvatarClass: css({
        fontSize: "14px",
        backgroundColor: "rgb(96, 172, 179)",
    }),
    deleteIcon: css({
        marginLeft: "2px",
    }),
    organizationName: css({
        fontStyle: "normal !important",
        fontSize: "16px !important",
        lineHeight: "24px !important",
        letterSpacing: "0.1px !important",
    }),
    caption: css({
        fontSize: "12px !important",
        lineHeight: "16px !important",
        letterSpacing: "0.4px !important",
        color: `${RED} !important`,
        marginLeft: "4px !important",
    }),
    deleteButton: css({
        width: "100% !important",
        marginTop: "24px !important",
        "& button": {
            color: RED,
        },
    }),
    italicText: css({
        fontStyle: "italic",
        color: `${GREYISH}`,
    }),
});

interface IDeleteOrganizationModalProps {
    handleDelete: any;
    organization: IOrganization;
    isDeletingOrg: boolean;
    closeModal(): void;
}

export const DeleteOrganizationModal: React.FC<IDeleteOrganizationModalProps> = ({
    handleDelete,
    closeModal,
    organization,
    isDeletingOrg,
}) => {
    const classes = useStyles();

    return (
        <div className={classes.container}>
            <div className={classes.modalContent}>
                <div className={classes.modalHeader}>
                    <h3 className={classes.headline}>Delete Organization</h3>
                    <Box display="flex" flexDirection="row-reverse">
                        <SVGIconRenderer icon="closeIcon" onClick={closeModal} />
                    </Box>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyItems: "flex-start" }}>
                    <Typography className={classes.additionalInfo}>
                        Are you sure you want to delete this organization?
                    </Typography>
                </div>
                <div style={{ margin: "36px 16px" }}>
                    <Flex direction={FlexDirection.Row} alignItems={AlignItems.Center}>
                        {organization.avatarMediumUrl && !organization.avatarMediumUrl.match(/missing_avatar/) ? (
                            <Avatar className={classes.imageContainerClass} src={organization.avatarMediumUrl} />
                        ) : (
                            <Avatar className={`${classes.imageContainerClass} ${classes.customAvatarClass}`}>
                                {organization.displayName ? organization.displayName[0].toUpperCase() : ""}
                            </Avatar>
                        )}
                        <span className={classes.organizationName}>{organization.displayName}</span>
                    </Flex>
                </div>
                <Flex direction={FlexDirection.Row} alignItems={AlignItems.Start}>
                    <SVGIconRenderer strokeColor="error" icon="alertCircleIcon" className={classes.deleteIcon} />
                    <Typography className={classes.caption}>
                        By deleting this organization you’ll delete all Connectors, not users
                    </Typography>
                </Flex>
                <FlexItem className={classes.deleteButton}>
                    <Button
                        onClick={() => handleDelete(1)}
                        startIcon={<SVGIconRenderer icon="trashIcon" strokeColor="error" height="20px" width="20px" />}
                        disabled={isDeletingOrg}
                    >
                        Delete organization
                    </Button>
                </FlexItem>
            </div>
        </div>
    );
};
