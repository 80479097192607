import { FC } from "react";
import { Card, CardContent, CardMedia, Link, Paper, Typography } from "@mui/material";
import { Container, Stack } from "@mui/system";

import welcomeImage from "src/assets/viewerOnboarding.svg";
import { PageHeader } from "src/components/PageHeader/PageHeader.v2";

interface IEmptyViewerWrapper {
    title: JSX.Element;
    description: JSX.Element;
    blog: {
        link: string;
        image: string;
        writter: string;
        heading: string;
        description: string;
    };
    showPageHeader?: boolean;
}

export const EmptyViewerWrapper: FC<IEmptyViewerWrapper> = ({ title, description, blog, showPageHeader = false }) => {
    return (
        <Stack sx={{ height: "100%", background: "#FFFFFF" }}>
            {showPageHeader && <PageHeader pageHeading="Home" showBreadcrumbs={false} />}
            <Stack sx={{ padding: "24px 48px 0px 48px" }} gap={4} alignItems="center">
                <Container
                    sx={(theme) => ({
                        width: "740px",
                        display: "flex",
                        flexDirection: "column",
                        gap: theme.spacing(4),
                        padding: theme.spacing(4),
                    })}
                    fixed
                >
                    <Stack gap={1} textAlign="center">
                        <img
                            style={{ alignSelf: "center" }}
                            src={welcomeImage}
                            alt="Welcome to Prescient"
                            width={"150px"}
                            height={"150px"}
                        />
                        <Stack gap={1}>
                            <Typography variant="h2" color="text.secondary">
                                {title}
                            </Typography>
                            <Typography variant="body1">{description}</Typography>
                        </Stack>
                    </Stack>
                    <Paper variant="outlined" sx={{}}>
                        <Link underline="none" target="_blank" href={blog.link}>
                            <Card
                                sx={(theme) => ({
                                    display: "flex",
                                    [theme.breakpoints.down("sm")]: {
                                        flexDirection: "column",
                                    },
                                    boxShadow: "none",
                                })}
                            >
                                <CardMedia
                                    component="img"
                                    sx={(theme) => ({
                                        width: 200,
                                        height: 200,
                                        [theme.breakpoints.down("sm")]: {
                                            width: "100%",
                                        },
                                    })}
                                    image={blog.image}
                                    alt="Live from space album cover"
                                />
                                <CardContent>
                                    <Stack spacing={1}>
                                        <Typography variant="subtitle2" color="primary.main">
                                            {blog.writter}
                                        </Typography>
                                        <Typography variant="h6" fontSize="16px" fontWeight={700}>
                                            {blog.heading}
                                        </Typography>
                                        <Typography color="text.secondary">{blog.description}</Typography>
                                    </Stack>
                                </CardContent>
                            </Card>
                        </Link>
                    </Paper>
                </Container>
            </Stack>
        </Stack>
    );
};
