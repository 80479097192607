import { FC } from "react";
import { Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { CustomModal } from "../../core/CustomDialog/CustomModal";

interface IAmazonAdsModal {
    onContinueClicked: any;
    isLoading: boolean;
    closeModal: () => void;
}

export const AmazonAdsModal: FC<IAmazonAdsModal> = ({
    closeModal,
    onContinueClicked,
    isLoading,
}) => {

    return (
        <CustomModal
            title="Important Notice: Amazon Ads Historical Data Limitations"
            instanceSlot={
                <Stack gap={1}>
                    <Typography variant="body1">
                        Amazon Ads connectors have a maximum historical sync limit set by Amazon.
                        <br />
                        This limit varies by ad type, but Prescient will only be able to access data up to the maximum
                        limit upon the creation of the connector. After connecting, Prescient will store that data for
                        you and persist it over time.
                    </Typography>
                    <Typography variant="body1">Here are the limitations for each ad type: </Typography>

                    <ul style={{ margin: 0 }}>
                        <li>Sponsored Products - 95 days</li>
                        <li>Sponsored Display - 60 days</li>
                        <li>Sponsored Brands Version 2 - 60 days</li>
                        <li>Sponsored Brands Version 3 - 95 days</li>
                        <li>SB_BENCHMARK_BRAND_AND_CATEGORY_REPORT - 90 days</li>
                    </ul>
                </Stack>
            }
            actions={{
                2: {
                    label: "Cancel",
                    onClick: closeModal,
                    disabled: isLoading,
                },
                1: {
                    label: "Proceed with Connector Setup",
                    onClick: onContinueClicked,
                    disabled: isLoading,
                },
            }}
            closeIcon
            closeModal={closeModal}
            sx={{ ".MuiPaper-root": { maxWidth: "700px" } }}
        />
    );
};
