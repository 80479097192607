export type Choice = "asc" | "desc";

export interface ICells {
    firstName: string;
    email: string;
    isSuperadmin: boolean;
    createdAt: Date;
    companyCount: number;
    invitationState: string;
}

interface IHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
}

export const SUPER_ADMIN_MEMBERS_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "firstName",
        disablePadding: false,
        label: "Name",
    },
    {
        id: "email",
        disablePadding: false,
        label: "Email Address",
    },
    {
        id: "isSuperadmin",
        disablePadding: false,
        label: "Role",
    },
    {
        id: "createdAt",
        disablePadding: false,
        label: "Created",
    },
    {
        id: "invitationState",
        disablePadding: false,
        label: "Invitation Status",
    },
    {
        id: "companyCount",
        disablePadding: false,
        label: "Organizations",
    },
];
