import { Collapse, TableCell, TableRow, Typography } from "@mui/material";
import { Box, Stack } from "@mui/system";
import { FC, Fragment } from "react";

import { cursor } from "src/assets/styles/commonStyle";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import { Flex } from "src/components/core/Flex/Flex";
import { FORECASTED_CAMPAIGNS_HEADERS } from "src/consts/optimizationPage/optimizationPage";
import { FORMATS } from "src/enums/Formats";
import { getConfidenceLevel } from "src/services/optimizationPage/optimization";
import { formatValue, handleClickWithTextSelectionCheck } from "src/services/utils";
import { ScenarioForecastChart } from "../ScenarioForecastChart";
import {
    ICard,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
} from "src/interfaces/entities/IScenario";
import { FormattedValue } from "./FormattedValue";
import { IDictionary } from "src/interfaces/IDictionary";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";

interface IOutcomeTableRow {
    cards?: ICard[];
    rowClick: () => void;
    campaignRow: IMetricAttributionTableValuesTransformedWithSpendAllocation;
    classes: any;
    campaignOpenId: string;
    scenario: IScenario;
    campaignData: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    isChannelTab?: boolean;
    compareToLabel: string;
}

export const OutcomeTableRow: FC<IOutcomeTableRow> = ({
    cards,
    rowClick,
    campaignRow,
    classes,
    campaignOpenId,
    scenario,
    campaignData,
    isChannelTab = false,
    compareToLabel,
}) => {
    let filteredHeader = cards ? cards.filter((h: any) => h.hidden === false) : [];

    if (!filteredHeader) {
        filteredHeader = [...FORECASTED_CAMPAIGNS_HEADERS];
    }

    const getTableRowData = (): IDictionary => {
        return {
            campaignName: (
                <Stack
                    direction="row"
                    alignItems="center"
                    gap="20px"
                    sx={{ position: "relative", paddingLeft: isChannelTab ? "50px" : 0 }}
                >
                    <SVGIconRenderer
                        icon={campaignOpenId === campaignRow.campaignId ? "chevronDownIcon" : "chevronRightIcon"}
                        strokeColor="black"
                        style={isChannelTab ? { position: "absolute", left: "15px", top: "5px", zIndex: 2 } : {}}
                    />
                    <DataSourceAvatar
                        programmaticName={campaignRow.connectorName}
                        className={classes.avatarImage}
                    />
                    <Flex>
                        <Typography className={classes.campaignNameOverflow} title={campaignRow.campaignName || ""}>
                            {campaignRow.campaignName}
                        </Typography>
                    </Flex>
                </Stack>
            ),
            confidenceScore: campaignRow.confidenceScore
                ? `${(campaignRow.confidenceScore * 100).toFixed(0)}% ${getConfidenceLevel(
                      campaignRow.confidenceScore,
                  )}`
                : "",
            spend: formatValue(campaignRow.spend, FORMATS.DOLLAR, 0),
            trueRevenue: formatValue(campaignRow.trueRevenue, FORMATS.DOLLAR, 0),
            trueRoas: formatValue(campaignRow.trueRoas, FORMATS.NUMERIC, 2),
            allocationSpend: (
                <Typography>
                    {formatValue(campaignRow.allocationSpend, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={campaignRow.totalSpendDiff} format={FORMATS.DOLLAR} precision={0} />
                </Typography>
            ),
            forecastedRevenue: (
                <Typography>
                    {formatValue(campaignRow.forecastedRevenue, FORMATS.DOLLAR, 0)}
                    <br />
                    <FormattedValue value={campaignRow.totalRevenueDiff} format={FORMATS.DOLLAR} precision={0} />
                </Typography>
            ),
            forecastedRoas: (
                <Typography>
                    {formatValue(campaignRow.forecastedRoas, FORMATS.NUMERIC, 2)}
                    <br />
                    <FormattedValue value={campaignRow.totalRoasDiff} format={FORMATS.NUMERIC} precision={2} />
                </Typography>
            ),
        };
    };

    return (
        <Fragment>
            <TableRow onClick={handleClickWithTextSelectionCheck(rowClick)} hover className={cursor("pointer")}>
                {filteredHeader.map((element) => {
                    return (
                        <TableCell key={element.id} className={element.className} align={element.align || "right"}>
                            {getTableRowData()[element.id]}
                        </TableCell>
                    );
                })}
            </TableRow>

            <TableRow>
                <TableCell style={{ padding: 0, borderBottom: 0 }} colSpan={FORECASTED_CAMPAIGNS_HEADERS.length}>
                    <Collapse in={campaignOpenId === campaignRow.campaignId} timeout="auto" unmountOnExit>
                        <Box
                            sx={{
                                margin: 0,
                                padding: 2,
                                border: "1px solid rgba(0,0,0,0.06)",
                            }}
                        >
                            <ScenarioForecastChart
                                performanceCampaignData={campaignData.find(
                                    (d: any) => d.campaignId === campaignRow.campaignId,
                                )}
                                scenario={scenario}
                                compareToLabel={compareToLabel}
                            />
                        </Box>
                    </Collapse>
                </TableCell>
            </TableRow>
        </Fragment>
    );
};
