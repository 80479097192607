import baseCreateApi from "./baseCreateApi";
import {
    formatCampaignsForInclusions,
    generateCampaignInclusionBody,
} from "src/services/campaignInclusion/campaignInclusion";
import { ICampaignInclusionResponse } from "src/interfaces/campaignsForInclusions/ICampaignInclusionResponse";
import { ICampaignForInclusion } from "src/interfaces/entities/ICampaignForInclusion";
import { ICompanyCampaignsReponse } from "src/interfaces/ICompanyCampaignsReponse/ICompanyCampaignsReponse";
import { keysToCamelCase } from "src/utils/format";
import { ICompanyCampaign } from "src/interfaces/entities/ICompanyCampaign";

export const campaignInclusion = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getCampaignsForInclusions: builder.query({
            query: (args: { orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/campaigns_for_inclusions`,
                    method: "GET",
                };
            },
            transformResponse: (response: { data: ICampaignInclusionResponse[] }): ICampaignForInclusion[] =>
                formatCampaignsForInclusions(response),
        }),
        createCompanyCampaigns: builder.mutation({
            query: (args: { body: any; orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/company_campaigns`,
                    method: "POST",
                    body: JSON.stringify(generateCampaignInclusionBody(args.body)),
                };
            },
        }),
        deleteCompanyCampaigns: builder.mutation({
            query: (args: { companyCampaignId: string; orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/company_campaigns/${args.companyCampaignId}`,
                    method: "DELETE",
                };
            },
        }),
        getCompanyCampaigns: builder.query({
            query: (args: { orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/company_campaigns`,
                    method: "GET",
                };
            },
            transformResponse: (response: { data: ICompanyCampaignsReponse[] }): ICompanyCampaign[] => {
                return response?.data?.length
                    ? response.data.map((res) => {
                          return keysToCamelCase({ ...res.attributes, companyCampaignId: res.id });
                      })
                    : [];
            },
        }),
    }),
});

export const {
    useLazyGetCampaignsForInclusionsQuery,
    useCreateCompanyCampaignsMutation,
    useDeleteCompanyCampaignsMutation,
    useLazyGetCompanyCampaignsQuery,
} = campaignInclusion;
