import React from "react";
import { Message } from "../../components/Message/Message";
import underConstruction from "../../assets/underConstruction.png";

export const NotFound: React.FC = () => {
    return (
        <div>
            <Message
                title="Page Not Found"
                subtitle="The page you are looking for doesn't exist."
                icon={underConstruction}
            />
        </div>
    );
};
