import { formatValue } from "./utils";
import { FORMATS } from "../enums/Formats";
import { IHeadCell, IPerformanceAttributionTableCells } from "../consts/performancePaidPage/performancePaidPage";
import { IEmailAttributionTableCells } from "../consts/emailPage/emailPage";
import { ITrendTableRows } from "src/interfaces/dashboard/trend";
import { IBaseHaloColumn } from "src/components/Dashboard/BaseVsHaloCard/BaseVsHaloCard";

export interface IExportHead extends Omit<IHeadCell, "id"> {
    id:
        | keyof IPerformanceAttributionTableCells
        | "channelName"
        | keyof IEmailAttributionTableCells
        | keyof ITrendTableRows
        | keyof IBaseHaloColumn;
    overridePercentage?: boolean;
}

export const prepareDataForCSV = (data: any, columns: IExportHead[]): string => {
    const NAColumns = [
        "channelReportedRevenue",
        "channelReportedRoas",
        "trueRevenue",
        "trueRoas",
        "cac",
        "newCustomers",
    ];
    const header = columns.reduce((p: string, column: IExportHead) => {
        if (column.hidden) {
            return p;
        }

        if (p.length === 0) {
            return column.label;
        }

        return `${p},${column.label}`;
    }, "");
    const body = data
        .map((row: any, index: number) => {
            const r: string[] = [];
            columns.forEach((c: IExportHead) => {
                if (c.hidden) {
                    return;
                }
                const item =
                    index !== 0 && row[c.id] < 0 && !row.isPercentage && NAColumns.includes(c.id.toString())
                        ? "n/a"
                        : row.isPercentage || c.overridePercentage
                        ? formatValue(row[c.id], FORMATS.PERCENT, 2)
                        : row.isDollars
                        ? formatValue(row[c.id], FORMATS.DOLLAR, 2)
                        : formatValue(row[c.id], c.sign, c.exportFixed);
                r.push(`"${item}"`);
            });

            return r.join(",");
        })
        .join("\r\n");
    const file = `${header}\r\n${body}`;
    return file;
};

export const exportCSV = (filename: string, csvContent: string) => {
    const blob = new Blob([csvContent], { type: "text/csv" });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement("a");
    link.setAttribute("href", url);
    link.setAttribute("download", `${filename}.csv`);
    document.body.appendChild(link); // Required for FF
    link.click();
};
