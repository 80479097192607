import baseCreateApi from "./baseCreateApi";
import { keysToCamelCase } from "src/utils/format";
import { ICampaignTacticTypes } from "src/interfaces/entities/ICampaignTacticTypes";
import { ICampaignTacticTypesResponse } from "src/interfaces/ICampaignTacticTypesResponse/ICampaignTacticTypesResponse";
import { generateCampaignTacticBody } from "src/services/campaignTactics/campaignTactics";
import { setTactics } from "src/reduxState/slices/tacticsSlice";

export interface IAddCampaignTactic {
    campaignId: string;
    tacticId: string;
}

const setTacticsAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setTactics(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

export const campaignTacticsApi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getTacticsType: builder.query({
            query: (args: { orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/campaign_tactic_types`,
                    method: "GET",
                };
            },
            onQueryStarted(queryArgs, { dispatch, queryFulfilled }) {
                setTacticsAsyncHandler({ dispatch, queryFulfilled });
            },
            transformResponse: (response: { data: ICampaignTacticTypesResponse[] }): ICampaignTacticTypes[] => {
                return response.data.map((tactic) => keysToCamelCase(tactic.attributes));
            },
        }),
        addCampaignTactic: builder.mutation({
            query: (args: { orgId: string; campaignTacticData: IAddCampaignTactic[] }) => {
                return {
                    url: `/organizations/${args.orgId}/campaign_tactics`,
                    method: "POST",
                    body: JSON.stringify(generateCampaignTacticBody(args.campaignTacticData)),
                };
            },
        }),
    }),
});

export const { useLazyGetTacticsTypeQuery, useAddCampaignTacticMutation } = campaignTacticsApi;
