import { ChevronLeft } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, useState } from "react";
import { CustomModal, IAction } from "src/components/core/CustomDialog/CustomModal";
import Step1Image from "../../../assets/googleSheetsConnectorFlow/step1placeholder.png";
import Step2Image from "../../../assets/googleSheetsConnectorFlow/step2placeholder.png";
import Step3Image from "../../../assets/googleSheetsConnectorFlow/step3placeholder.png";
import { EMAIL_CODES, useSendEmailMutation } from "src/reduxState/apis/emailSender";
import { useSelector } from "react-redux";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";

interface IGoogleSheetsStep {
    subtitle: string;
    body: FC;
    image: FC;
}

interface IConnectGoogleSheetsModal {
    action: () => void;
    closeModal: () => void;
    shouldSendEmail?: boolean;
}

const steps = [
    {
        subtitle: "You need to update your data every day.",
        body: (
            <Typography>
                Prescient builds new models every morning. To ensure your models are as accurate and reliable as
                possible, <strong>you must update the data daily, including weekends.</strong>
            </Typography>
        ),
        image: <img src={Step1Image} style={{ borderRadius: "4px" }} />,
    },
    {
        subtitle: "You are responsible for the input data",
        body: (
            <Typography>
                Input data must be at the campaign level. It needs daily values for spend, reported revenue, reported
                conversions, and optionally, impressions and clicks. Don’t worry;
                <strong> we will provide you with a template sheet to help you format your data correctly.</strong>
            </Typography>
        ),
        image: <img src={Step2Image} style={{ borderRadius: "4px" }} />,
    },
    {
        subtitle: "You've got mail!",
        body: (
            <Typography>
                Please check your email for the Data Template. You will use that sheet when you navigate to your data
                connector on the next step.
            </Typography>
        ),
        image: <img src={Step3Image} style={{ borderRadius: "4px" }} />,
    },
];

export const ConnectGoogleSheetsModal: FC<IConnectGoogleSheetsModal> = ({
    action,
    closeModal,
    shouldSendEmail = false,
}) => {
    const [currentStep, setCurrentStep] = useState(0);
    const [isChecked, setIsChecked] = useState(false);

    const orgId = useSelector(selectCurrentlyViewingId);
    const [sendEmail] = useSendEmailMutation();

    const isLastStep = currentStep === steps.length - 1;

    const onClose = () => {
        if (isChecked && isLastStep) {
            action();
        }
        closeModal();
    };

    const incrementStep = () => {
        if (isLastStep) {
            action();
        } else {
            if (currentStep === 1 && shouldSendEmail) {
                sendEmail({ code: EMAIL_CODES.googleSheetsReady, orgId });
            }
            setCurrentStep(currentStep + 1);
        }
    };

    return (
        <CustomModal
            title="Connecting Google Sheets Data"
            instanceSlot={
                <Stack gap={2}>
                    {steps[currentStep].image}
                    <Typography variant="h6">{steps[currentStep].subtitle}</Typography>
                    <Typography>{steps[currentStep].body}</Typography>
                    {currentStep === 1 && (
                        <FormControlLabel
                            control={<Checkbox checked={isChecked} onChange={() => setIsChecked(!isChecked)} />}
                            label="I acknowledge all the requirements, and wish to proceed."
                        />
                    )}
                </Stack>
            }
            actions={{
                1: {
                    label: !isLastStep ? "Next" : "Connect",
                    onClick: () => incrementStep(),
                    disabled: currentStep === 1 && !isChecked,
                    variant: isLastStep ? "contained" : "text",
                },
                ...(currentStep !== 0 &&
                    ({
                        2: {
                            label: "Back",
                            onClick: () => setCurrentStep(currentStep - 1),
                            startIcon: <ChevronLeft />,
                            variant: "text",
                        },
                    } as { [key: number]: IAction })),
            }}
            closeIcon
            closeModal={onClose}
        />
    );
};
