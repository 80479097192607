import { SendOutlined } from "@mui/icons-material";
import { FormControl, FormHelperText, MenuItem, Select, SelectChangeEvent, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useState } from "react";
import { CustomModal } from "src/components/core/CustomDialog/CustomModal";
import { IConnector } from "src/interfaces/IConnector";

export const ShareConnectorModal = ({
    adminMemberOptions,
    selectedConnector,
    closeModal,
    shareConnectorHandler,
}: {
    adminMemberOptions: Array<{ id: string; name: string }>;
    selectedConnector: IConnector;
    closeModal: () => void;
    shareConnectorHandler: (
        connector: IConnector,
        formState: {
            userId: string;
            message: string;
        },
    ) => void;
}) => {
    const sampleMessage = `We are setting up our channels in Prescient. Please help me complete our connectors by setting up this channel.`;

    const [formState, setFormState] = useState({
        userId: "",
        message: `Hi <First>,\n${sampleMessage}`,
    });

    const getName = (id: string) => {
        return adminMemberOptions.find((member) => member.id === id)?.name.split(" - ")[0] || "";
    };

    const updateUserId = (event: SelectChangeEvent<string>) => {
        const id = event.target.value;
        setFormState({
            message: `Hi ${getName(id)},\n${sampleMessage}`,
            userId: id,
        });
    };

    return (
        <CustomModal
            title="Don’t Have Access? Share It to a Colleague!"
            instanceSlot={
                <Stack gap={2}>
                    <FormControl>
                        <Select
                            labelId="demo-simple-select-helper-label"
                            id="demo-simple-select-helper"
                            onChange={updateUserId}
                            value={formState.userId}
                            displayEmpty={true}
                            renderValue={(value) =>
                                value ? (
                                    getName(formState.userId)
                                ) : (
                                    <Typography color="text.secondary">Team Member</Typography>
                                )
                            }
                        >
                            {adminMemberOptions.length ? (
                                adminMemberOptions.map((member) => (
                                    <MenuItem value={member.id} key={member.id}>
                                        {member.name}
                                    </MenuItem>
                                ))
                            ) : (
                                <MenuItem>
                                    <Typography color="text.secondary">
                                        No admin member added in this organization
                                    </Typography>
                                </MenuItem>
                            )}
                        </Select>
                        <FormHelperText>Current admin members in your organization</FormHelperText>
                    </FormControl>
                    <TextField variant="outlined" size="medium" disabled value={formState.message} multiline rows={4} />
                </Stack>
            }
            actions={{
                2: {
                    label: "Cancel",
                    onClick: closeModal,
                },
                1: {
                    label: "Send",
                    onClick: () => shareConnectorHandler(selectedConnector, formState),
                    endIcon: <SendOutlined />,
                },
            }}
            closeIcon={false}
            closeModal={closeModal}
        />
    );
};
