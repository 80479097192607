export type Choice = "asc" | "desc";

export interface ICells {
    name: string;
    schema: string;
    dataSourceProviderId: string;
}

interface IHeadCell {
    id: keyof ICells;
    label: string;
    numeric: boolean;
    align?: "inherit" | "left" | "center" | "right" | "justify";
}

export const DATA_SERVICES_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "name",
        numeric: false,
        label: "Name",
    },
    {
        id: "schema",
        numeric: false,
        label: "Schema",
    },
    {
        id: "dataSourceProviderId",
        numeric: false,
        label: "Provider",
    },
];
