import React, { useEffect } from "react";
import { TableBody} from "@mui/material";
import { useSelector } from "react-redux";
import { useTheme } from "@mui/system";

import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { fixedPerformanceHeader } from "../../../services/performancePage/performancePage";
import { getComparator, stableSort } from "../../../utils/sort";
import { EmailCampaignTableHeader } from "./EmailCampaignTableHeader";
import { EmailCampaignTableRow } from "./EmailCampaignTableRow";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { Choice, IHeadCell, TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE } from "../../../consts/emailPage/emailPage";
import { useStylesForCampaignTable } from "../../../components/CampaignComponents/CampaignTable/CampaignTable";
import { IEmail } from "../../../interfaces/entities/IEmail";

interface IEmailCampaignTable {
    isLoading?: boolean;
    isImagesInTable?: boolean;
    tableData: IMetricAttributionTableValuesTransformed[];
    selectedTableColumns: IHeadCell[];
    page: number;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    setOrder: React.Dispatch<React.SetStateAction<Choice>>;
    order: Choice;
    orderBy: string;
    setOrderBy: React.Dispatch<React.SetStateAction<string>>;
    totalAmount: IEmail;
}

export const EmailCampaignTable: React.FC<IEmailCampaignTable> = ({
    isImagesInTable,
    tableData,
    selectedTableColumns,
    page,
    setPage,
    order,
    orderBy,
    setOrder,
    setOrderBy,
    totalAmount,
}) => {
    const classes = useStylesForCampaignTable(useTheme());
    const rowsPerPage = useSelector(paginationCountSelector);

    useEffect(() => {
        window.addEventListener("scroll", fixedPerformanceHeader);
        return () => {
            window.removeEventListener("scroll", fixedPerformanceHeader);
        };
    }, []);

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: string) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <div style={{ position: "relative" }}>
            <div className={classes.tableBorderClass}></div>
            <CustomTableStructure
                paginationProps={{
                    count: tableData.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                    className: classes.paginationClass,
                }}
                tableProps={{ id: "performance-table", size: "small" }}
                className={classes.noBorderClass}
            >
                <EmailCampaignTableHeader
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                    selectedTableColumns={selectedTableColumns}
                />
                <TableBody>
                    <EmailCampaignTableRow
                        totalAmount={totalAmount}
                        row={TOTAL_IN_EMAIL_METRIC_ATTRIBUTION_TABLE}
                        selectedTableColumns={selectedTableColumns}
                    />
                    {stableSort(tableData, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any, index: number) => {
                            return (
                                <EmailCampaignTableRow
                                    isImagesInTable={isImagesInTable}
                                    key={index}
                                    row={row}
                                    selectedTableColumns={selectedTableColumns}
                                />
                            );
                        })}
                </TableBody>
            </CustomTableStructure>
        </div>
    );
};
