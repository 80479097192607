import React from "react";
import MUIButton, { ButtonProps } from "@mui/material/Button";

export enum ButtonVariant {
    Text = "text",
    Container = "contained",
    Outlined = "outlined",
}

export enum ButtonColor {
    Primary = "primary",
    Secondary = "secondary",
    Tertiary = "tertiary",
    success = "success",
    Error = "error",
    Info = "info",
    Warning = "warning",
}

export const Button: React.FC<ButtonProps> = ({ variant = "contained", color = "primary", ...props }) => {
    return <MUIButton variant={variant} color={color} {...props} />;
};
