import { css } from "@emotion/css";
import { BLACK_COLOR_OPACITY_60 } from "../../consts/colors";

export const brokenStatus = css`
    background-color: #f443362b;
    color: #d14838;
    border-color: #d14838;
`;

export const activeStatus = css`
    background-color: #e1ffda;
    color: #55bd4f;
    border-color: #55bd4f;
    cursor: default;
`;

export const syncStatus = css({
    backgroundColor: "#fbf8b2",
    color: "#a19923",
    borderColor: "#f3e312",
    cursor: "default",
    "& .syncClass": {
        display: "flex",
        animation: "$spin 1s 1",
        alignItems: "center",
    },
    "@keyframes spin": {
        "100%": {
            transform: "rotate(0deg)",
        },
        "0%": {
            transform: "rotate(360deg)",
        },
    },
});

export const pausedStatus = css({
    backgroundColor: "rgba(0, 0, 0, 0.08)",
    color: BLACK_COLOR_OPACITY_60,
    borderColor: BLACK_COLOR_OPACITY_60,
    cursor: "default",
});

export const cursor = (cursorType: string) => css`
    cursor: ${cursorType};
`;

export const fullWidthClass = css`
    width: 100%;
`;

export const imageInputClass = css`
    display: flex;
    justify-content: center;
    margin-bottom: 20px;
`;

export const caption = css`
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
`;

export const imageLabel = css`
    ${caption};
    margin-bottom: 8px !important;
    margin-top: -8px !important;
`;
