import { Box } from "@mui/system";
import React, { FC } from "react";
import { prescientLight } from "src/theme/prescient";
import BillingAlert from "../BillingAlert/BillingAlert";

interface IPageContainer {
    children?: React.ReactNode;
    className?: any;
    style?: any;
}

export const PageContainer: FC<IPageContainer> = ({ children, className, style = {} }) => {
    return (
        <Box
            className={className}
            p="30px"
            sx={{
                minHeight: "calc(100vh - 234px)",
                backgroundColor: prescientLight.palette.background.default,
                ...style,
            }}
        >
            <BillingAlert />
            {children}
        </Box>
    );
};
