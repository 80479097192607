import { IFeatureDTO } from "../../interfaces/DTO/IFeatureDTO";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { generateFeaturesBody, transformFeature } from "../../services/features/features";
import { setFeatures, removeFeatureById } from "../slices/featuresSlice";
import baseCreateApi from "./baseCreateApi";

interface IFeatureBody {
    id: string;
    displayName: string;
    cleanName: string;
    description: string;
    companies: string[];
    companyInfo: IOrganization[];
}

const setFeaturesAsyncHandler = async ({ dispatch, queryFulfilled }: any) => {
    try {
        const res = await queryFulfilled;
        if (res) {
            dispatch(setFeatures(res.data));
        }
    } catch (error) {
        console.error(error);
    }
};

export const features = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getFeatures: builder.query({
            query: () => ({
                url: `features?include=companies`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }) {
                setFeaturesAsyncHandler({ dispatch, queryFulfilled, getState });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IFeatureDTO[] }) => {
                return transformFeature(response.data);
            },
        }),
        createFeature: builder.mutation({
            query: (args: { body: IFeatureBody }) => {
                return {
                    url: "features",
                    method: "POST",
                    body: JSON.stringify(generateFeaturesBody(args.body, false)),
                };
            },
        }),
        editFeature: builder.mutation({
            query: (args: { params: { id: number }; body: IFeatureBody }) => {
                return {
                    url: `features/${args.params.id}`,
                    method: "PATCH",
                    body: JSON.stringify(generateFeaturesBody(args.body, true)),
                };
            },
        }),
        deleteFeature: builder.mutation({
            query: (id: string) => ({
                url: `features/${id}`,
                method: "DELETE",
            }),
            onQueryStarted(id: string, { dispatch }) {
                dispatch(removeFeatureById(id));
            },
        }),
    }),
});

export const { useLazyGetFeaturesQuery, useCreateFeatureMutation, useEditFeatureMutation, useDeleteFeatureMutation } =
    features;
