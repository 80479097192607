import { IDictionary } from "../../../interfaces/IDictionary";
import { IAnalytics, IOrganizationWithRole } from "../IAnalytics";

export class CypressAnalytics implements IAnalytics {
    public instance: any = {}

    constructor() {
        this.init();
    }
    public init() {}

    public login(user: any) {}

    public logout() {}

    public logEvent(eventType: string, eventProperties?: any) {
        const simpleEvent = new CustomEvent(`cypressAnalyticsEventLog.${eventType}`, {})
        document.dispatchEvent(simpleEvent);

        if (!eventProperties) {
            return;
        }
        const propertiesString = JSON.stringify(eventProperties);
        const event = new CustomEvent(`cypressAnalyticsEventLog.${eventType}.${propertiesString}`, {})
        document.dispatchEvent(event);
    }

    public setCurrentOrganization(organization: IOrganizationWithRole): void {}

    public setUserProperties(properties: IDictionary<any>): void {}

    public setUser(user: any): void {}

    public getEventLog() {
        return this.instance.eventLog;
    }
}
