import { useContext } from "react";
import { AnalyticsContext } from "./analyticsContext";
import { IAnalyticsManager } from "./IAnalyticsManager";

export const useAnalyticsService = () => {
    const context = useContext<IAnalyticsManager | undefined>(AnalyticsContext);
    if (context === undefined) {
        throw new Error("Analytics service was not provided");
    }

    return context;
};
