import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Navigate, PathRouteProps, useLocation, useNavigate } from "react-router-dom";

import Loader from "src/components/core/Loader/Loader";
import { getEntitiesFromIncludes, useGetLoggedInUserQuery } from "src/reduxState/apis/authApi";
import { store } from "src/reduxState/stores/store";
import { ACCESS_DENIED_PATH, TRIAL_END_PATH } from "../../consts/path/path";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IOrganizationMetaData } from "../../interfaces/entities/IOrganizationMetaData";
import { useLazyGetConnectorsQuery } from "../../reduxState/apis/connectorsApi";
import { useLazyGetOrganizationMetaDataQuery } from "../../reduxState/apis/organizationApi";
import { continueAsSuperAdminSelector } from "../../reduxState/slices/continueAsSuperAdminSlice";
import { setMinDate } from "../../reduxState/slices/dateFilterSlice";
import {
    selectCompanyIdByCode,
    selectCurrentlyViewingCompanyById,
    selectCurrentlyViewingId,
    selectHasAccessToDashboard,
    selectIsActiveSubscription,
    selectIsExpiredTrial,
    setCurrentlyViewing,
} from "../../reduxState/slices/organizationSlice";
import { setUserSettings } from "../../reduxState/slices/settingsSlice";
import { currentUserRoleByCompanyIdSelector, isSuperAdminSelector } from "../../reduxState/slices/userSlice";

interface IProtectedOrgRouteProps extends PathRouteProps {
    accessRoles?: string[];
    children: any;
}

export const ProtectedOrgRoute: React.FC<IProtectedOrgRouteProps> = ({ accessRoles, children, ...rest }) => {
    const loggedInUser = useGetLoggedInUserQuery({});

    const location = useLocation();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const continueAsSuperAdmin = useSelector(continueAsSuperAdminSelector);
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const orgIdSelector = useSelector(selectCurrentlyViewingId);
    const [getConnectors] = useLazyGetConnectorsQuery();
    const isTrialExpired = useSelector(selectIsExpiredTrial);
    const isActiveSubscription = useSelector(selectIsActiveSubscription);
    const hasAccessToDashboard = useSelector(selectHasAccessToDashboard);
    const currentCompany: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const [getOrganizationMetaData] = useLazyGetOrganizationMetaDataQuery();
    const restrictedPath = ["performance", "campaigns", "e-commerce", "onboarding", "optimization", "home"];
    const locationPathArr = location.pathname.split("/");
    const isValidPath =
        restrictedPath.includes(locationPathArr[locationPathArr.length - 1]) ||
        restrictedPath.includes(locationPathArr[locationPathArr.length - 2]);
    const redirectToExpiredPath =
        currentCompany && currentCompany.subscription && !isActiveSubscription && !hasAccessToDashboard && isValidPath;
    const orgCodeFromPath = location.pathname.split("/")[2];

    const currentRouteId = useSelector(() => selectCompanyIdByCode(store.getState(), orgCodeFromPath));

    useEffect(() => {
        if (!orgIdSelector) {
            return;
        }

        getConnectors(orgIdSelector);
        getOrganizationMetaData(orgIdSelector)
            .unwrap()
            .then((res: IOrganizationMetaData) => {
                dispatch(setMinDate({ minDate: res?.data?.minimumReportingDate || null }));
                dispatch(
                    setUserSettings({
                        settings: res?.data?.minimumReportingDate ? true : false,
                        fromApi: false,
                        key: "isCampaignAvailable",
                    }),
                );
                dispatch(
                    setUserSettings({
                        settings: res?.data?.hasModeledData ? true : false,
                        fromApi: false,
                        key: "isModelingAvailable",
                    }),
                );
            });
    }, [orgIdSelector]);

    useEffect(() => {
        // Check if user data is not available
        if (!loggedInUser.data) {
            // If current route ID doesn't match organization ID, update the view
            if (currentRouteId !== orgIdSelector) {
                dispatch(setCurrentlyViewing(orgCodeFromPath));
            }
            return;
        }

        if (isSuperAdmin) {
            if (!currentRouteId) {
                navigate("/404");
                return;
            }
            return;
        }

        // If current route ID matches organization ID, no action is needed
        if (currentRouteId === orgIdSelector) {
            return;
        }

        const {
            data: { data: data = {}, included: included = [] },
        } = loggedInUser;

        // If current route ID doesn't match organization ID, update the view
        if (currentRouteId && currentRouteId !== orgIdSelector) {
            dispatch(setCurrentlyViewing(orgCodeFromPath));
            return;
        }

        const entities = getEntitiesFromIncludes([...included, data], {
            users: [],
            users_roles: [],
            companies: [],
            settings: [],
            features: [],
        });

        const companyExists = entities.companies.some((c) => c.code === orgCodeFromPath);

        // Update the view if the company exists, otherwise navigate to 404
        if (companyExists) {
            dispatch(setCurrentlyViewing(orgCodeFromPath));
        } else {
            navigate("/404");
        }
    }, [loggedInUser, currentRouteId, orgIdSelector]);

    if (loggedInUser.isLoading) {
        return <Loader />;
    }

    if (!continueAsSuperAdmin && redirectToExpiredPath) {
        if (isTrialExpired) {
            return <Navigate to={TRIAL_END_PATH} />;
        }
        return <Navigate to={`/org/${currentCompany.code}`} />;
    }

    if (!isSuperAdmin) {
        // check if userRole is matched and if not 403
        if (accessRoles && userRole && !accessRoles.includes(userRole)) {
            return <Navigate to={ACCESS_DENIED_PATH} />;
        }
    }

    return <ErrorBoundary>{children}</ErrorBoundary>;
};
