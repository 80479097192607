import React, { FC } from "react";
import { Box } from "@mui/system";
import { css } from "@emotion/css";

import { CustomChart } from "./CustomChart";
import { ReturnChart } from "./ReturnChart";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";

export const useStylesForMetricCharts = () => ({
    boldText: css({
        fontWeight: "bold",
        letterSpacing: "1px",
    }),
    chartContainer: css({
        display: "flex",
        flexWrap: "wrap",
        gap: "1rem",
    }),
});

interface IPerformanceTab {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
}

export const PerformanceTab: FC<IPerformanceTab> = ({ performanceCampaignData }) => {
    const classes = useStylesForMetricCharts();

    return (
        <Box mt={3} mb={5} className={classes.chartContainer} id="performance-chart-details">
            <ReturnChart performanceCampaignData={performanceCampaignData} classes={classes} />
            <CustomChart performanceCampaignData={performanceCampaignData} classes={classes} />
        </Box>
    );
};
