import { Fragment, useMemo, useState } from "react";
import { SubHeader } from "src/components/SubHeader/SubHeader.v2";
import { DataServicesTable } from "src/components/dataServices/DataServiceTable/DataServicesTable";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { getSearchedAndFilteredDataServices } from "src/services/dataSources/dataSources";

export const DataServices = ({
    dataServices,
    openEditDataSourceModal,
    openDeleteModal,
}: {
    dataServices: IDataService[];
    openEditDataSourceModal: (dataService: IDataService) => void;
    openDeleteModal: (dataService: IDataService) => void;
}) => {
    const [search, setSearch] = useState<string>("");

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const filteredData = useMemo(
        () => getSearchedAndFilteredDataServices(dataServices, search),
        [dataServices, search],
    );

    return (
        <Fragment>
            <SubHeader itemsLength={filteredData.length} title="Data Service(s)" onSearchChange={handleSearchChange} />
            <DataServicesTable data={filteredData} editClick={openEditDataSourceModal} deleteClick={openDeleteModal} />
        </Fragment>
    );
};
