import baseCreateApi from "./baseCreateApi";
import { IDimCampaignResponse } from "src/interfaces/dimCampaignResponse/IDimCampaignResponse";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { formatCampaignsForInclusions } from "src/services/campaignInclusion/campaignInclusion";

export const campaigns = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        // this needs to be change to get dim campaign api route when api available
        getDimCampaign: builder.query({
            query: (args: { orgId: string }) => {
                return {
                    url: `/organizations/${args.orgId}/campaigns_for_inclusions`,
                    method: "GET",
                };
            },
            transformResponse: (response: { data: IDimCampaignResponse[] }): IDimCampaign[] =>
                formatCampaignsForInclusions(response),
        }),
    }),
});

export const { useLazyGetDimCampaignQuery } = campaigns;
