import React from "react";
import Modal from "@mui/material/Modal";
import { css } from "@emotion/css";
import { Button, IconButton, Typography } from "@mui/material";

import { modalTitle } from "../../fontStyles/fontStyles";
import { IMember } from "../../interfaces/entities/IMember";
import { SVGIconRenderer } from "../../components/SVGIconRenderer/SVGIconRenderer";

interface IReInviteMemberModal {
    isOpen: boolean;
    member: IMember;
    reinviteBtnDisable: boolean;
    onClose?(): void;
    handleCancelButtonClick?(): void;
    onSubmit?(): void;
}

const useStyles = () => ({
    modalWrapper: css({
        margin: "15% auto 30%",
        display: "flex",
        flexDirection: "column",
        height: "fit-content",
        width: "375px",
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "center",
        padding: "30px",
        borderRadius: "5px",
    }),
    bodyClass: css({
        width: "100%",
        fontSize: "12px",
    }),
    heading: css({
        alignSelf: "baseline",
        marginBottom: "20px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
        "& button": {
            padding: "0",
            color: "rgba(0,0,0,0.87) !important",
        },
    }),
    textClass: css({
        fontSize: "12px",
    }),
    actionWrapper: css({
        width: "100%",
        display: "flex",
        justifyContent: "space-between",
    }),
    cancelBtnClass: css({ marginRight: "8px", color: "black", border: "1px solid black", width: "50%" }),
    submitBtnClass: css({ width: "50%" }),
});

export const ReInviteMemberModal: React.FC<IReInviteMemberModal> = ({
    isOpen,
    onClose,
    onSubmit = () => {},
    handleCancelButtonClick = () => {},
    member,
    reinviteBtnDisable,
}) => {
    const classes = useStyles();
    return (
        <Modal
            open={isOpen}
            onClose={onClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            disableAutoFocus={true}
            disableEnforceFocus={true}
        >
            <div className={classes.modalWrapper}>
                <div className={classes.heading}>
                    <Typography variant="h4" className={modalTitle(true)}>
                        Re-Invite Member?
                    </Typography>
                    <IconButton onClick={onClose} size="small">
                        <SVGIconRenderer icon="closeIcon" />
                    </IconButton>
                </div>
                <div className={classes.bodyClass}>
                    <Typography className={classes.textClass}>
                        Re-invitation will invalidate any previous invitations. Are you sure you want to send another
                        invite to this member?
                    </Typography>
                    <Typography className={classes.textClass} style={{ margin: "16px 0", color: "#36ae9e" }}>
                        {member.firstName} {member.lastName}
                    </Typography>
                </div>
                <div className={classes.actionWrapper}>
                    <Button onClick={handleCancelButtonClick} className={classes.cancelBtnClass}>
                        Never mind
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onSubmit}
                        className={classes.submitBtnClass}
                        disabled={reinviteBtnDisable}
                    >
                        Re-invite
                    </Button>
                </div>
            </div>
        </Modal>
    );
};
