import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import cloneDeep from "lodash/cloneDeep";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import { useParams } from "react-router";

import { PageContainer } from "src/components/PageContainer/PageContainer";
import { PageHeader } from "src/components/PageHeader/PageHeader.v2";
import {
    useCreateDataSourceMutation,
    useDeleteDataSourceMutation,
    useEditDataSourceMutation,
    useGetDataSourceCategoriesQuery,
    useGetDataSourceProvidersQuery,
} from "src/reduxState/apis/dataSourcesApi";
import { demoModeSelector } from "src/reduxState/slices/demoModeSlice";
import { emptyDataService, emptyDataSource } from "src/services/dataSources/dataSources";
import { Modal } from "src/components/core/Modal/Modal";
import { IDataSourceForm } from "src/interfaces/IData";
import { DataSourceForm } from "src/components/DataSource/DataSourceModals/DataSourceForm";
import { CustomModal } from "src/components/CustomModal/CustomModal";
import {
    addSupportedDataSources,
    deleteSupportedDataSourcesById,
    editSupportedDataSources,
} from "src/reduxState/slices/supportedDataSourcesSlice";
import { PageHeaderTabContext } from "src/components/CustomTabContext/PageHeaderTabContext";
import { DATA_INTEGRATION_TABS, DataSourceIntegrationTabs } from "src/consts/dataSourcePage/dataSourcePage";
import { DataSources } from "./DataSources";
import { IDictionary } from "src/interfaces/IDictionary";
import { DataServices } from "./DataServices";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";
import { DataServiceForm } from "src/components/dataServices/DataServiceModal/DataServiceForm";
import {
    useCreateDataSourceServiceMutation,
    useDeleteDataSourceServiceMutation,
    useEditDataSourceServiceMutation,
    useGetDataSourceServicesQuery,
} from "src/reduxState/apis/dataServicesApi";
import { DataProviders } from "./DataProviders";
import Loader from "src/components/core/Loader/Loader";

export const DataIntegration = () => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const isDemoMode = useSelector(demoModeSelector);
    const { dataIntegrationTab } = useParams();

    const [dataSourceTab, setDataSourceTab] = useState(
        (dataIntegrationTab as DataSourceIntegrationTabs) || DataSourceIntegrationTabs.DataSources,
    );

    const { data: dataServices = [], isLoading: isServicesLoading } = useGetDataSourceServicesQuery({});
    const { data: dataSourceCategories = [] } = useGetDataSourceCategoriesQuery({});
    const { data: dataSourceProviders = [], isLoading: isDataProviderLoading } = useGetDataSourceProvidersQuery({});

    // data sources
    const [createDataSource] = useCreateDataSourceMutation();
    const [editDataSource] = useEditDataSourceMutation();
    const [deleteDataSource, { isLoading: isDeleting }] = useDeleteDataSourceMutation();

    const [isEditMode, setIsEditMode] = useState<boolean>(false);
    const [isDataSourceModalOpen, setIsDataSourceModalOpen] = useState<boolean>(false);
    const [selectedDataSource, setSelectedDataSource] = useState<IDataSourceForm | null>(null);
    const [isDeleteModalOpen, setDeleteModalOpen] = useState<boolean>(false);

    // data services
    const [createDataService] = useCreateDataSourceServiceMutation();
    const [editDataService] = useEditDataSourceServiceMutation();
    const [deleteDataService, { isLoading: isServiceDeleting }] = useDeleteDataSourceServiceMutation();

    const [dataServicesState, setDataServicesState] = useState(dataServices);
    const [isDataServiceModalOpen, setIsDataServiceModalOpen] = useState<boolean>(false);
    const [selectedDataService, setSelectedDataService] = useState<IDataService | null>(null);
    const [isDeleteServiceModalOpen, setIsDeleteServiceModalOpen] = useState<boolean>(false);

    useEffect(() => {
        setDataServicesState(dataServices);
    }, [dataServices]);

    const closeModal = () => {
        setIsEditMode(false);
        setIsDataSourceModalOpen(false);
        setDeleteModalOpen(false);
        setSelectedDataSource(null);

        setIsDataServiceModalOpen(false);
        setSelectedDataService(null);
        setIsDeleteServiceModalOpen(false);
    };

    const openAddDataSourceModal = () => {
        setSelectedDataSource(emptyDataSource);
        setIsDataSourceModalOpen(true);
    };

    const openEditDataSourceModal = (dataSource: IDataSourceForm) => {
        setIsDataSourceModalOpen(true);
        setIsEditMode(true);
        setSelectedDataSource(cloneDeep(dataSource));
    };

    const openDeleteDataSourceModal = (dataSource: IDataSourceForm) => {
        setSelectedDataSource(cloneDeep(dataSource));
        setDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleSubmit = async (values: IDataSourceForm) => {
        try {
            const action = values.id ? editDataSourceAction : createDataSourceAction;
            await action(values);
        } catch (error) {
            enqueueSnackbar("Something went wrong!", {
                id: uniqueId(),
                variant: "error",
            });
        } finally {
            closeModal();
        }
    };

    const editDataSourceAction = async (values: IDataSourceForm) => {
        if (values.id) {
            const response = await editDataSource({
                params: { id: +values.id },
                body: values,
            }).unwrap();
            dispatch(editSupportedDataSources(response[0]));
        }
    };

    const createDataSourceAction = async (values: IDataSourceForm) => {
        const response = await createDataSource({ ...values }).unwrap();
        dispatch(addSupportedDataSources(response[0]));
    };

    const deleteDataSourceAction = async (dataSource: IDataSourceForm) => {
        if (!dataSource.id) {
            return;
        }

        try {
            await deleteDataSource(dataSource.id).unwrap();
            dispatch(deleteSupportedDataSourcesById(dataSource.id));
        } catch (error) {
            enqueueSnackbar("Something went wrong!", {
                id: uniqueId(),
                variant: "error",
            });
        } finally {
            closeModal();
        }
    };

    const openAddDataServiceModal = () => {
        setSelectedDataService(emptyDataService);
        setIsDataServiceModalOpen(true);
    };

    const openEditDataServiceModal = (dataService: IDataService) => {
        setIsDataServiceModalOpen(true);
        setSelectedDataService(cloneDeep(dataService));
    };

    const openDeleteDataServiceModal = (dataService: IDataService) => {
        setIsDeleteServiceModalOpen(true);
        setSelectedDataService(cloneDeep(dataService));
    };

    const handleDataServiceSubmit = async (values: IDataService) => {
        try {
            const action = values.id ? editDataServiceAction : createDataServiceAction;
            await action({
                ...values,
                schema: values.name,
            });
        } catch (error) {
            enqueueSnackbar("Something went wrong!", {
                id: uniqueId(),
                variant: "error",
            });
        } finally {
            closeModal();
        }
    };

    const editDataServiceAction = async (values: IDataService) => {
        if (values.id) {
            const response = await editDataService({
                params: { id: +values.id },
                body: values,
            }).unwrap();
            setDataServicesState((dataServices: IDataService[]) =>
                dataServices.map((d) => (d.id === response[0].attributes.id ? response[0].attributes : d)),
            );
        }
    };

    const createDataServiceAction = async (values: IDataService) => {
        const response = await createDataService({ ...values }).unwrap();
        setDataServicesState((dataServices: IDataService[]) => {
            return [response[0].attributes, ...dataServices];
        });
    };

    const deleteDataServiceAction = async (dataService: Required<IDataService>) => {
        try {
            await deleteDataService(dataService.id).unwrap();
            setDataServicesState((dataServices: IDataService[]) => dataServices.filter((d) => d.id !== dataService.id));
        } catch (error) {
            enqueueSnackbar("Something went wrong!", {
                id: uniqueId(),
                variant: "error",
            });
        } finally {
            closeModal();
        }
    };

    const handleScenarioViewChange = (
        event: React.SyntheticEvent<Element, Event>,
        newValue: DataSourceIntegrationTabs,
    ) => {
        setDataSourceTab(newValue);
    };

    const tabs: IDictionary = {
        [DataSourceIntegrationTabs.DataSources]: (
            <DataSources
                openEditDataSourceModal={openEditDataSourceModal}
                openDeleteModal={openDeleteDataSourceModal}
            />
        ),
        [DataSourceIntegrationTabs.DataService]: isServicesLoading ? (
            <Loader />
        ) : (
            <DataServices
                dataServices={dataServicesState}
                openEditDataSourceModal={openEditDataServiceModal}
                openDeleteModal={openDeleteDataServiceModal}
            />
        ),
        [DataSourceIntegrationTabs.DataProvider]: isDataProviderLoading ? (
            <Loader />
        ) : (
            <DataProviders data={dataSourceProviders} />
        ),
    };

    const addButtons: IDictionary = {
        [DataSourceIntegrationTabs.DataSources]: {
            buttonName: "Add Data Source",
            onAdd: openAddDataSourceModal,
        },
        [DataSourceIntegrationTabs.DataService]: {
            buttonName: "Add Data Service",
            onAdd: openAddDataServiceModal,
        },
    };

    return (
        <div>
            <PageHeader
                pageHeading="Data Integrations"
                disabled={isDemoMode}
                {...addButtons[dataSourceTab]}
                tabs={
                    <PageHeaderTabContext
                        value={dataSourceTab}
                        handleChange={handleScenarioViewChange}
                        tabs={DATA_INTEGRATION_TABS}
                    />
                }
            />
            <PageContainer>{tabs[dataSourceTab]}</PageContainer>
            {isDataSourceModalOpen && selectedDataSource && (
                <Modal
                    isOpen={isDataSourceModalOpen}
                    title={isEditMode ? "Edit Data Source" : "New Data Source"}
                    canOutsideClickClose={false}
                    onClose={closeModal}
                    width="480px"
                    content={
                        <DataSourceForm
                            isEditMode={isEditMode}
                            initialValues={selectedDataSource}
                            onSubmit={handleSubmit}
                            dataServicesOptions={dataServicesState}
                            dataSourceCategoriesOptions={dataSourceCategories}
                            dataSourceProviders={dataSourceProviders}
                        />
                    }
                />
            )}
            {isDeleteModalOpen && selectedDataSource && (
                <CustomModal
                    title="Remove Data Source"
                    subTitle={`Are you sure you want to delete ${selectedDataSource.name} data source?`}
                    details={<></>}
                    buttonName="Delete"
                    handleAction={deleteDataSourceAction}
                    selectedConnector={selectedDataSource}
                    closeModal={closeModal}
                    type="error"
                    disableBtn={isDeleting}
                />
            )}
            {isDataServiceModalOpen && selectedDataService && (
                <Modal
                    isOpen={isDataServiceModalOpen}
                    title={!!selectedDataService.id ? "Edit Data Service" : "New Data Service"}
                    canOutsideClickClose={false}
                    onClose={closeModal}
                    width="480px"
                    content={
                        <DataServiceForm
                            isEditMode={!!selectedDataService.id}
                            initialValues={selectedDataService}
                            onSubmit={handleDataServiceSubmit}
                            dataServicesOptions={dataServices}
                            dataSourceCategoriesOptions={dataSourceCategories}
                            dataSourceProviders={dataSourceProviders}
                        />
                    }
                />
            )}
            {isDeleteServiceModalOpen && selectedDataService && (
                <CustomModal
                    title="Remove Data Service"
                    subTitle={`Are you sure you want to delete ${selectedDataService.name} data Service?`}
                    details={<></>}
                    buttonName="Delete"
                    handleAction={deleteDataServiceAction}
                    selectedConnector={selectedDataService}
                    closeModal={closeModal}
                    type="error"
                    disableBtn={isServiceDeleting}
                />
            )}
        </div>
    );
};
