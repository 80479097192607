import React from "react";
import { useSelector } from "react-redux";
import { Navigate, PathRouteProps, useLocation } from "react-router-dom";

import { userIdSelector } from "../../reduxState/slices/userSlice";
import { LOGIN_PATH } from "../../consts/path/path";

interface ILoggedInRouteProps extends PathRouteProps {
    children?: any;
}

export const LoggedInRoute: React.FC<ILoggedInRouteProps> = ({ children }) => {
    const isLoggedIn = useSelector(userIdSelector);
    const location = useLocation();

    if (!isLoggedIn) {
        return (
            <Navigate
                to={`${LOGIN_PATH}${
                    location.pathname !== "/" ? `?redirect=${location.pathname.substring(1)}${location.search}` : ""
                }`}
            />
        );
    }

    return children;
};
