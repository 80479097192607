import React from "react";
import Spinner from "@mui/material/CircularProgress";
import { Stack, Typography } from "@mui/material";

const LoaderWithMessage = ({ message }: { message: string }) => {
    return (
        <Stack alignItems="center" gap="20px" justifyContent="center" height="calc(100vh - 350px)">
            <Typography variant="h5">{message}</Typography>
            <Spinner disableShrink />
        </Stack>
    );
};

export default LoaderWithMessage;
