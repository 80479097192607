import { IDictionary } from "../../../interfaces/IDictionary";
import { intercomAppId } from "../../../utils/env";
import { getDateInYYYYMMDDFormat } from "../../utils";
import { IAnalytics, IOrganizationWithRole } from "../IAnalytics";

/* tslint:disable */
export class IntercomAnalytics implements IAnalytics {
    // @ts-ignore
    public readonly instance = window.Intercom;
    private settings = {
        api_base: "https://api-iam.intercom.io",
        app_id: intercomAppId,
        alignment: "left"
    };

    constructor() {
        this.init();
    }

    public init() {
        // @ts-ignore
        window.intercomSettings = this.settings;
        (function () {
            var w = window;
            // @ts-ignore
            var ic = w.Intercom;
            if (typeof ic === "function") {
                ic("reattach_activator");
                // @ts-ignore
                ic("update", w.intercomSettings);
            } else {
                var d = document;
                var i = function () {
                    // @ts-ignore
                    i.c(arguments);
                };
                // @ts-ignore
                i.q = [];
                // @ts-ignore
                i.c = function (args) {
                    // @ts-ignore
                    i.q.push(args);
                };
                // @ts-ignore
                w.Intercom = i;
                var l = function () {
                    var s = d.createElement("script");
                    s.id = "intercom-script";
                    s.type = "text/javascript";
                    s.async = true;
                    s.src = `https://widget.intercom.io/widget/${intercomAppId}`;
                    var x = d.getElementsByTagName("script")[0];
                    // @ts-ignore
                    x.parentNode.insertBefore(s, x);
                };
                if (document.readyState === "complete") {
                    l();
                    // @ts-ignore
                } else if (w.attachEvent) {
                    // @ts-ignore
                    w.attachEvent("onload", l);
                } else {
                    w.addEventListener("load", l, false);
                }
            }
        })();
    }

    public login(user: any) {
        this.setUser(user);
    }

    public logout() {
        // @ts-ignore
        window.Intercom("shutdown");
    }

    public logEvent(eventType: string, eventProperties?: any) {}

    public setUserProperties(properties: IDictionary<any>): void {}

    public setUser(user: any): void {
        const createdAt = getDateInYYYYMMDDFormat(new Date(user.createdAt));

        // @ts-ignore
        window.Intercom(
            "boot",
            Object.assign(
                {
                    name: `${user.firstName} ${user.lastName}`,
                    email: user.email,
                    user_id: +user.id,
                    created_at: createdAt,
                    user_hash: user.intercomUserHash,
                },
                this.settings,
            ),
        );
    }

    public setCurrentOrganization(organization: IOrganizationWithRole): void {}
}
