import React, { useState } from "react";
import cloneDeep from "lodash/cloneDeep";
import { Box, Button, Typography } from "@mui/material";
import { css } from "@emotion/css";

import { AlignItems, Flex, FlexDirection, JustifyContent } from "../Flex/Flex";
import { FlexItem } from "../FlexItem/FlexItem";
import { WHITE, BLACK_COLOR, PRIMARY_COLOR } from "../../../consts/colors";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const classes = {
    modalHeader: css({
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
    }),
    container: css({
        display: "flex",
        position: "fixed",
        justifyContent: "center",
        alignContent: "center",
        zIndex: 1111,
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.38)",
        backdropFilter: "blur(3px)",
    }),
    modalContent: css({
        display: "flex",
        flexDirection: "column",
        padding: "40px",
        width: "408px",
        height: "auto",
        justifySelf: "center",
        alignSelf: "center",
        backgroundColor: `${WHITE}`,
        border: "0.5px solid rgba(255, 255, 255, 0.12)",
        boxSizing: "border-box",
        boxShadow: "0 16px 24px rgba(0, 0, 0, 0.14), 0 6px 30px rgba(0, 0, 0, 0.12), 0 8px 10px rgba(0, 0, 0, 0.2)",
        borderRadius: "8px",
    }),
    closeBtn: css({
        height: "24px",
        width: "24px",
        margin: "0 8px 0 0",
        cursor: "pointer",
    }),
    headline: css({
        fontStyle: "normal",
        fontWeight: 500,
        lineHeight: "24px",
        fontSize: "22px",
        display: "flex",
        alignItems: "center",
        color: `${BLACK_COLOR}`,
        margin: 0,
    }),
    additionalInfo: css({
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "12px",
        lineHeight: "16px",
        display: "flex",
        color: `${BLACK_COLOR}`,
        marginTop: "28px",
    }),
    name: css({
        fontStyle: "normal",
        fontWeight: "normal",
        fontSize: "14px",
        lineHeight: "16px",
        display: "flex",
        alignItems: "center",
        letterSpacing: "0.1px",
        color: `${PRIMARY_COLOR}`,
    }),
    item: css({
        display: "flex",
        margin: "0 0 8px 0",
        width: "100%",
    }),
    buttons: css({
        width: "156px",
    }),
    iconClass: css({
        marginLeft: "8px",
    }),
    bodyContainer: css({
        overflowY: "auto",
        maxHeight: "350px",
    }),
};

interface IDeleteModalProps {
    handleDelete: any;
    closeModal: any;
    items: any;
    title: string;
    header: string;
    actionName?: string;
}

export const DeleteModal: React.FC<IDeleteModalProps> = ({
    handleDelete,
    closeModal,
    items,
    title,
    header,
    actionName = "delete",
}) => {
    const [chosenItems, setChosenItems] = useState<any>(items);

    const removeItemFromList = (index: number) => {
        const items = cloneDeep(chosenItems);
        items.splice(index, 1);
        setChosenItems(items);

        if (items.length === 0) {
            closeModal();
        }
    };

    return (
        <div className={classes.container}>
            <div className={classes.modalContent}>
                <div className={classes.modalHeader}>
                    <h3 className={classes.headline}>
                        {actionName[0].toUpperCase() + actionName.slice(1)} {header}(s)
                        <SVGIconRenderer icon="alertCircleIcon" className={classes.iconClass} />
                    </h3>
                    <Box display="flex" flexDirection="row-reverse">
                        <SVGIconRenderer icon="closeIcon" className={classes.closeBtn} onClick={closeModal} />
                    </Box>
                </div>
                <div style={{ display: "flex", flexDirection: "row", justifyItems: "flex-start" }}>
                    <Typography className={classes.additionalInfo}>
                        {items.length === 1
                            ? `Are you sure you want to ${actionName} this ${header}?`
                            : `Are you sure you want to ${actionName} (${items.length}) ${title}?`}
                    </Typography>
                </div>
                <div className={classes.bodyContainer} style={{ margin: "16px 0" }}>
                    {chosenItems.map((item: any, index: number) => (
                        <div className={classes.item} key={item.displayName || item.schema || item.lastName}>
                            <div className={classes.name}>
                                {title === "feature"
                                    ? item.displayName
                                    : title === "connectors"
                                    ? item.connectorName
                                    : `${item.firstName} ${item.lastName}`}
                            </div>
                            <Box display="flex" flexDirection="row-reverse" onClick={() => removeItemFromList(index)}>
                                <SVGIconRenderer icon="closeIcon" />
                            </Box>
                        </div>
                    ))}
                </div>
                <Flex
                    direction={FlexDirection.Row}
                    alignItems={AlignItems.Center}
                    justifyContent={JustifyContent.Center}
                    spacing="16px"
                >
                    <FlexItem>
                        <Button color="inherit" onClick={closeModal} className={classes.buttons}>
                            Cancel
                        </Button>
                    </FlexItem>
                    <FlexItem>
                        <Button color="inherit" onClick={() => handleDelete(chosenItems)} className={classes.buttons}>
                            {actionName}
                        </Button>
                    </FlexItem>
                </Flex>
            </div>
        </div>
    );
};
