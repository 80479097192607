import { Checkbox, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { Box } from "@mui/system";

import { useStylesForHeaderInTable } from "src/consts/StylesForTables";
import { CAMPAIGN_TACTICS_HEADER, Choice } from "src/consts/campaignTacticsPage/campaignTactics";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";

interface ICampaignTacticsTableHeader {
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof IDimCampaign) => void;
    choice: Choice;
    choiceBy: string;
    selectedRowCount: number;
    totalRowCount: number;
    onSelectAllClick: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export const CampaignTacticsTableHeader = ({
    onRequestSort,
    choice,
    choiceBy,
    selectedRowCount,
    totalRowCount,
    onSelectAllClick,
}: ICampaignTacticsTableHeader) => {
    const tableHeaderStyle = useStylesForHeaderInTable();

    const createSortHandler = (property: keyof IDimCampaign) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead className={tableHeaderStyle.tableHeader}>
            <TableRow className={tableHeaderStyle.headerRow}>
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={selectedRowCount > 0 && selectedRowCount < totalRowCount}
                        checked={totalRowCount > 0 && selectedRowCount === totalRowCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            "aria-label": "select all campaigns",
                        }}
                    />
                </TableCell>
                {CAMPAIGN_TACTICS_HEADER.map((header) => {
                    return (
                        <TableCell className={tableHeaderStyle.tableCell} key={header.id}>
                            <TableSortLabel
                                active={choiceBy === header.id}
                                direction={choiceBy === header.id ? choice : "asc"}
                                onClick={createSortHandler(header.id)}
                            >
                                {header.label}
                                {choiceBy === header.id ? (
                                    <Box component="span" style={{ display: "none" }}>
                                        {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </TableCell>
                    );
                })}
                <TableCell align="center" className={tableHeaderStyle.tableCell}>
                    Tactic Selection
                </TableCell>
            </TableRow>
        </TableHead>
    );
};
