import React, { FC } from "react";
import { Skeleton, TableBody, TableCell, TableRow } from "@mui/material";

interface ITableRowsLoader {
    rowsNum: number;
    colsNum: number;
    style?: any;
}

export const TableRowsLoader: FC<ITableRowsLoader> = ({ rowsNum, colsNum, style }) => {
    return (
        <TableBody>
            {[...Array(rowsNum)].map((row, index) => (
                <TableRow key={index}>
                    {[...Array(colsNum)].map((c, i) => (
                        <TableCell key={i} style={style || {}} component="th" scope="row">
                            <Skeleton animation="wave" variant="text" />
                        </TableCell>
                    ))}
                </TableRow>
            ))}
        </TableBody>
    );
};
