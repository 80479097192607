import { IMember } from "../../interfaces/entities/IMember";
import { getDiffObjectKeys, getPathForAnalytics } from "../../services/utils";

const getObjectKeys = (obj: any) => Object.keys(obj);

const eventKeys = {
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    role: "Role",
    avatar: "Profile Picture",
};

export const updateMemberEvent = (updatedValues: IMember, member: any, currentOrgId: string) => {
    if (member) {
        let updatedMember = {};
        let memberWithRole = {};
        getObjectKeys(member).forEach((key) => {
            if (getObjectKeys(eventKeys).includes(key)) {
                memberWithRole = {
                    ...memberWithRole,
                    avatar: "",
                    role: member.rolesByCompanyId && member.rolesByCompanyId[currentOrgId].role,
                    [key]: member[key as keyof IMember],
                };
            }
        });

        getObjectKeys(updatedValues).forEach((key) => {
            if (getObjectKeys(eventKeys).includes(key)) {
                updatedMember = {
                    avatar: "",
                    ...updatedMember,
                    role: updatedValues.rolesByCompanyId && updatedValues.rolesByCompanyId[currentOrgId].role,
                    [key]: updatedValues[key as keyof IMember],
                };
            }
        });

        const diffDataKeys = getDiffObjectKeys(memberWithRole, updatedMember);
        const finalKeys = Object.entries(eventKeys)
            .map(([key, value]) => diffDataKeys.includes(key) && value)
            .filter((d) => d !== false);

        return {
            "Fields changed": finalKeys,
        };
    }
};

export const addMemberEvent = (values: IMember) => {
    return {
        "New member organization role": values.role,
        "New member picture uploaded": values.avatar ? true : false,
    };
};

export const deleteMemberEvent = (role: string) => {
    if (role) {
        return {
            Page: getPathForAnalytics(location.pathname),
            "Member organization role": role,
        };
    }
};
