import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

interface IGrinConnectorModalContent {
    classes: any;
    displayName: string;
}

export const GrinConnectorModalContent: FC<IGrinConnectorModalContent> = ({ classes, displayName }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Find API Key
                </Typography>
            </Stack>
            <Typography className={classes.info}>
                Log in to your{" "}
                <a href="https://app.grin.co/login" target="_blank" className={classes.link}>
                    {displayName} account
                </a>
                .
            </Typography>
            <Typography className={classes.info}>
                In your{" "}
                <a
                    href="https://app.grin.co/settings/account/integrations/api"
                    target="_blank"
                    className={classes.link}
                >
                    {`${displayName} Account Settings`}
                </a>
                , go to API integration.
            </Typography>
            <Typography className={classes.info}>
                Make a note of the API key. You will need it in the next step.
            </Typography>

            {/* Step 2 */}
            <Stack mt={1} direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>Enter the API Key you found in Step 1.</Typography>
            <Typography className={classes.info}>Click Save & Test.</Typography>
        </Fragment>
    );
};
