import React, { FC } from "react";
import { Box } from "@mui/system";

import { CustomChart } from "./CustomChart";
import { useStylesForMetricCharts } from "../../../components/CampaignComponents/InsightsModal/PerformanceTab/PerformanceTab";
import { IEmail } from "../../../interfaces/entities/IEmail";

interface IPerformanceTab {
    performanceCampaignData: IEmail;
}

export const PerformanceTab: FC<IPerformanceTab> = ({ performanceCampaignData }) => {
    const classes = useStylesForMetricCharts();

    return (
        <Box mt={3} mb={5} className={classes.chartContainer} id="email-performance-chart-details">
            <CustomChart performanceCampaignData={performanceCampaignData} classes={classes} />
        </Box>
    );
};
