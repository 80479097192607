import React from "react";
import { Button, Theme, Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import {
    CampaignStatus,
    IHeadCell,
    PerformanceTab,
    TRUE_METRICS,
} from "../../../consts/performancePaidPage/performancePaidPage";
import { ActivePerformanceTabs, ActivePerformanceTabsForEvent } from "../../../enums/ActivePerfomanceTabs";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import {
    insightsModalSelector,
    openedInsightsModalIdsSelector,
    setInsightsModal,
} from "../../../reduxState/slices/insightsModalSlice";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import {
    formatValue,
    getPathForAnalytics,
    capitalizeFirstLetter,
    handleClickWithTextSelectionCheck,
} from "../../../services/utils";
import { getManageCampaignLink, SUPPORTED_EXTERNAL_PLATFORM_LINKS } from "../../../services/campaigns/management";
import { FORMATS } from "../../../enums/Formats";
import { modelEligibilityByIdSelector } from "../../../reduxState/slices/modelEligibilitySlice";
import { LightMenu } from "../../LightMenu/LightMenu";
import { cursor } from "../../../assets/styles/commonStyle";
import { SVGIconRenderer } from "../../SVGIconRenderer/SVGIconRenderer";
import { betaSupportedDataSourcesSelector } from "../../../reduxState/slices/supportedDataSourcesSlice";
import { BetaTooltip } from "../../../components/ConnectorsComponent/NewConnectorComponents/BetaTooltip";
import { NOT_SET_TACTIC, getNAColumnsForTable } from "src/services/performancePage/performancePage";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";

export const useStylesForCampaignTableRow = (theme: Theme, isPerformanceRowSelected?: boolean) => ({
    selectedRow: css({
        backgroundColor: isPerformanceRowSelected ? "rgb(245, 245, 245)" : "#FFFFFF",
    }),
    totalAmountRow: css({
        position: "relative",
        zIndex: 1,
        "& td": {
            backgroundColor: "white",
        },
    }),
    title: css({
        fontSize: "20px",
        lineHeight: "133.4%",
        marginRight: "8px",
    }),
    campaignName: css({
        display: "flex",
        alignItems: "center",
        padding: "8px 16px",
        "& p": {
            fontSize: "0.8rem",
            lineHeight: "18px",
        },
    }),
    boldText: css({
        fontWeight: "bold",
    }),
    usualText: css({
        fontWeight: "normal",
    }),
    tooltip: css({
        fontSize: "14px",
    }),
    underLine: css({
        textDecoration: "underline",
        cursor: "pointer",
    }),
    channelReportedClass: css({
        flexDirection: "row-reverse",
        gap: theme.spacing(1),
    }),
    actionCell: css({
        "& .itemsContainer": {
            textAlign: "left",
        },
    }),
});

interface ICampaignTableRow {
    row: IMetricAttributionTableValuesTransformed;
    isImagesInTable?: boolean;
    totalAmount?: any;
    selectedTableColumns: IHeadCell[];
    isChannelRow?: boolean;
    handleChannelClick?: (row: any) => void;
    activePerformanceTab: PerformanceTab;
    isModelingAvailable: boolean;
    gotoTacticSettingsPage?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const CampaignTableRow: React.FC<ICampaignTableRow> = ({
    row,
    isImagesInTable,
    totalAmount,
    selectedTableColumns,
    isChannelRow,
    handleChannelClick,
    activePerformanceTab,
    isModelingAvailable,
    gotoTacticSettingsPage,
}) => {
    const analyticsService = useAnalyticsService();
    const insightsModal = useSelector(insightsModalSelector);
    const openedInsightsModalIds = useSelector(openedInsightsModalIdsSelector);
    const betaSupportedConnectors = useSelector(betaSupportedDataSourcesSelector);

    const isPerformanceRowSelected = openedInsightsModalIds?.length
        ? openedInsightsModalIds.includes(row.campaignId)
        : false;
    // @ts-ignore
    const eligibilityData = useSelector((state) => modelEligibilityByIdSelector(state, row.campaignId));
    const isTacticTabActive = activePerformanceTab === PerformanceTab.Tactics;

    const classes = {
        ...useStylesForRowInTable(),
        ...useStylesForCampaignTableRow(useTheme(), isPerformanceRowSelected),
    };
    const dispatch = useDispatch();

    const handleLinkClick = () => {
        const link = getManageCampaignLink(row.connectorName || "", row.campaignId || "", row.accountId || "");
        if (link) {
            analyticsService.logEvent("Performance Deep Link Clicked", {
                Page: getPathForAnalytics(location.pathname, location.search),
                Channel: row.channelName,
            });
            window.open(link);
        }
    };

    const actionList = [
        {
            label: `Manage Campaign on ${row.channelName}`,
            key: row.campaignId || row.campaignName || "12",
            onClick: handleLinkClick,
        },
    ];

    const supportActions = SUPPORTED_EXTERNAL_PLATFORM_LINKS.includes(row.connectorName || "");

    const openModalHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        const parent = document.getElementById(`${row.campaignId}-action`) as HTMLElement;
        const child = e.target as HTMLElement;
        if (parent.contains(child)) {
            return false;
        } else {
            if (!openedInsightsModalIds.includes(row.campaignId)) {
                const activeTab: ActivePerformanceTabs =
                    row.hasSecondOrderEffect && insightsModal.length
                        ? insightsModal[0].activeTab
                        : ActivePerformanceTabs.Performance;

                const activeMetric =
                    row.hasSecondOrderEffect && insightsModal.length ? insightsModal[0].activeMetric : "revenue";
                dispatch(
                    setInsightsModal({
                        data: row,
                        isMinimize: false,
                        activeTab,
                        activeMetric,
                    }),
                );

                analyticsService.logEvent("Performance Detail Clicked", {
                    Page: getPathForAnalytics(location.pathname, location.search),
                    "Campaign name": row.campaignName,
                    "Halo available": row.hasSecondOrderEffect ? "True" : "False",
                    "Drawer status": !openedInsightsModalIds.length ? "None" : "Opened",
                });

                analyticsService.logEvent("Performance Detail Page Viewed", {
                    Page: getPathForAnalytics(window.location.pathname, window.location.search),
                    "Detail page": ActivePerformanceTabsForEvent[activeTab],
                });
            }
        }
    };

    const getTacticCellData = () => {
        return row.tacticName === NOT_SET_TACTIC.displayName ? (
            <Button
                variant="contained"
                color="primary"
                size="medium"
                onClick={gotoTacticSettingsPage}
                data-cy="label-tactics-btn"
            >
                Label Tactics
            </Button>
        ) : (
            row.tacticName
        );
    };

    const handleTableRowClick = (e: React.MouseEvent<HTMLDivElement>) => {
        if (!isChannelRow && !totalAmount && activePerformanceTab !== PerformanceTab.Tactics) {
            handleClickWithTextSelectionCheck(openModalHandler)(e);
        } else {
            handleChannelClick && handleClickWithTextSelectionCheck(handleChannelClick)(row);
        }
    };

    const title = isChannelRow
        ? row.connectorName || ""
        : activePerformanceTab === PerformanceTab.Tactics
        ? row.tactic
        : row.campaignName || row.campaignId || "";

    return (
        <TableRow
            id={`${totalAmount ? "sticky-total-amount" : `performance-row-${row.campaignId}`}`}
            className={`${classes.bodyRow} ${classes.selectedRow}  ${
                totalAmount ? classes.totalAmountRow : cursor("pointer")
            }`}
            onClick={handleTableRowClick}
            data-cy={`${activePerformanceTab}_${title}`}
        >
            {selectedTableColumns.map((element, index) => {
                const isCampaignNameCell = element.id === "campaignName";
                const isTotalRow = totalAmount && totalAmount.campaignName === "Total";
                const negativeCols = getNAColumnsForTable(row.connectorName);
                const isImagesInCell = isImagesInTable && isCampaignNameCell;

                const title = totalAmount
                    ? formatValue(totalAmount[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0)
                    : isCampaignNameCell
                    ? isChannelRow
                        ? row.channelName || ""
                        : activePerformanceTab === PerformanceTab.Tactics
                        ? getTacticCellData()
                        : row.campaignName || row.campaignId || ""
                    : formatValue(row[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0);

                const currentCellValue = row[element.id] ? row[element.id] : row[element.id] === 0 ? 0 : -1;
                const isTrueMetricCell = TRUE_METRICS.includes(element.id);
                const isNotValidData =
                    !isTotalRow && negativeCols.includes(element.id) && currentCellValue && +currentCellValue < 0;

                let rowText = title;
                if (isTrueMetricCell && !isModelingAvailable) {
                    rowText = "Pending";
                } else if (element.id === "status" && !totalAmount) {
                    rowText = capitalizeFirstLetter(
                        row.status && row.status !== CampaignStatus.UNKNOWN ? row.status.toLocaleLowerCase() : "",
                    );
                } else if (isNotValidData) {
                    rowText = "N/A";
                }

                return (
                    !element.hidden && (
                        <TableCell
                            component="td"
                            scope="row"
                            key={index}
                            title={isCampaignNameCell && typeof title === "string" ? title : ""}
                            sx={{
                                borderBottom: "1px solid #E0E0E0",
                                color: "#000000!important",
                                backgroundColor: isTrueMetricCell ? "rgba(66, 150, 133, 0.30)" : "inherit",
                                minHeight: "52px",
                                padding: "1px 16px",
                                position: "relative",
                            }}
                        >
                            <span
                                className={
                                    isCampaignNameCell
                                        ? classes.campaignName
                                        : isImagesInCell
                                        ? `${classes.campaignName} ${classes.channelReportedClass}`
                                        : ""
                                }
                            >
                                {isImagesInCell && (
                                    <DataSourceAvatar
                                        programmaticName={row.connectorName}
                                        sx={{ width: "32px!important", height: "32px!important", marginRight: "10px" }}
                                    />
                                )}

                                <Typography
                                    className={`${totalAmount && isTotalRow ? classes.boldText : classes.usualText} ${
                                        isCampaignNameCell && !isTotalRow && classes.underLine
                                    }`}
                                    variant="body2"
                                    sx={{
                                        flexGrow: "1",
                                    }}
                                    component="div"
                                >
                                    {rowText}

                                    {!isTacticTabActive &&
                                        isCampaignNameCell &&
                                        row.connectorName &&
                                        betaSupportedConnectors.includes(row.connectorName) && (
                                            <Typography component="span" sx={{ marginLeft: "8px" }}>
                                                <BetaTooltip />
                                            </Typography>
                                        )}
                                </Typography>
                            </span>
                        </TableCell>
                    )
                );
            })}
            <TableCell
                key="campaign-row-actions"
                component="td"
                scope="row"
                className={classes.tableCellAction}
                align="right"
                id={`${row.campaignId}-action`}
            >
                {!isChannelRow && !totalAmount && supportActions && (
                    <LightMenu
                        items={actionList}
                        title={<SVGIconRenderer icon="overflowIcon" />}
                        header={"Actions"}
                        extraButtonClass={classes.actionCell}
                    />
                )}
            </TableCell>
        </TableRow>
    );
};
