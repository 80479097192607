import { FC, useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";
import { Stack } from "@mui/system";

import { CampaignInclusionTableHeader } from "./CampaignInclusionTableHeader";
import { CustomTableStructure } from "src/components/core/CustomTableStructure/CustomTableStructure";
import { CampaignInclusionsRow } from "./CampaignInclusionTableRow";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { ICampaignForInclusionWithMap } from "src/interfaces/entities/ICampaignForInclusion";
import { CAMPAIGN_INCLUSIONS_HEADER, Choice } from "src/consts/campaignInclusionsPage/campaignInclusions";
import { getComparator, stableSort } from "src/utils/sort";
import { CampaignInclusionTableToolbar } from "./CampaignInclusionTableToolbar";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import { getSelectedRows } from "src/services/campaignInclusion/campaignInclusion";

interface ICampaignInclusionTable {
    data: ICampaignForInclusionWithMap[];
    isLoading: boolean;
    campaignInclusionsBtnAction: (selectedRows: ICampaignForInclusionWithMap) => void;
    bulkCampaignSaveBtnAction: (
        addCampaigns: ICampaignForInclusionWithMap[],
        removeCampaigns: ICampaignForInclusionWithMap[],
    ) => Promise<void>;
    setSelectedRows: React.Dispatch<React.SetStateAction<readonly string[]>>;
    selectedRows: readonly string[];
}

export const CampaignInclusionTable: FC<ICampaignInclusionTable> = ({
    data,
    campaignInclusionsBtnAction,
    isLoading,
    bulkCampaignSaveBtnAction,
    setSelectedRows,
    selectedRows,
}) => {
    const rowsPerPage = useSelector(paginationCountSelector);
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);

    const [page, setPage] = useState<number>(0);
    const [order, setOrder] = useState<Choice>("asc");
    const [orderBy, setOrderBy] = useState("");

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICampaignForInclusionWithMap) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = data.map((n) => n.campaignId);
            setSelectedRows(newSelected);
            return;
        }
        setSelectedRows([]);
    };

    const handleRowSelect = (event: React.MouseEvent<unknown>, campaignId: string) => {
        setSelectedRows(getSelectedRows(selectedRows, campaignId));
    };

    const isSelected = (campaignId: string) => selectedRows.indexOf(campaignId) !== -1;

    // get campaigns from data based on selected campaign ids
    const { inclusionCampaigns, exclusionCampaigns } = data.reduce(
        (result, d) => {
            if (selectedRows.includes(d.campaignId)) {
                if (d.manuallySet) {
                    result.inclusionCampaigns.push(d);
                } else {
                    result.exclusionCampaigns.push(d);
                }
            }
            return result;
        },
        { inclusionCampaigns: [], exclusionCampaigns: [] } as {
            inclusionCampaigns: ICampaignForInclusionWithMap[];
            exclusionCampaigns: ICampaignForInclusionWithMap[];
        },
    );

    const inclusionLength = exclusionCampaigns.length;
    const exclusionLength = inclusionCampaigns.length;

    const addCampaigns = inclusionLength ? `adding ${inclusionLength} campaign${inclusionLength > 1 ? "s" : ""}` : "";
    const removeCampaigns = exclusionLength
        ? `${inclusionLength ? " and" : ""} removing ${exclusionLength} campaign${exclusionLength > 1 ? "s" : ""} from`
        : "";
    const companyDisplayName = currentCompany?.displayName;

    const message = `Save changes to ${addCampaigns}${removeCampaigns} ${
        exclusionLength ? "" : "to"
    } ${companyDisplayName}`;

    const saveClick = () => {
        bulkCampaignSaveBtnAction(exclusionCampaigns, inclusionCampaigns);
    };

    return (
        <Stack gap="20px">
            {selectedRows.length > 0 && (
                <CampaignInclusionTableToolbar displayMsg={message} bulkCampaignSaveBtnAction={saveClick} />
            )}
            <CustomTableStructure
                paginationProps={{
                    count: data.length,
                    page,
                    handleChangePage,
                    handleChangeRowsPerPage,
                }}
            >
                <CampaignInclusionTableHeader
                    onRequestSort={handleRequestSort}
                    choice={order}
                    choiceBy={orderBy}
                    selectedRowCount={selectedRows.length}
                    onSelectAllClick={handleSelectAllClick}
                    totalRowCount={data.length}
                />
                <TableBody>
                    {data.length ? (
                        stableSort(data, getComparator(order, orderBy))
                            .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                            .map((d: any, index: number) => {
                                const isRowSelected = isSelected(d.campaignId);
                                const labelId = `enhanced-table-checkbox-${index}`;

                                return (
                                    <CampaignInclusionsRow
                                        key={d.campaignId}
                                        row={d}
                                        campaignInclusionsBtnAction={campaignInclusionsBtnAction}
                                        isLoading={isLoading}
                                        isRowSelected={isRowSelected}
                                        labelId={labelId}
                                        onRowClick={handleRowSelect}
                                    />
                                );
                            })
                    ) : (
                        <TableRow>
                            <TableCell align="center" colSpan={CAMPAIGN_INCLUSIONS_HEADER.length + 2}>
                                No data found
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </CustomTableStructure>
        </Stack>
    );
};
