import { createSlice, createSelector } from "@reduxjs/toolkit";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { setCurrentlyViewing } from "./organizationSlice";

const initialState: {
    list: any[];
    currentView: { isSavedViewOn: boolean; data: { name: string; filter: string; id: string } };
} = {
    list: [],
    currentView: {
        isSavedViewOn: false,
        data: { name: "Paid Performance", filter: "", id: "" },
    },
};

const filterViewSlice = createSlice({
    name: "filterView",
    initialState,
    reducers: {
        setFilterView: (state, { payload }) => {
            state.currentView = payload;
        },
        setFilterList: (state, { payload }) => {
            state.list = payload;
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return initialState;
        });
        builder.addCase(setCurrentlyViewing, () => {
            return initialState;
        });
    },
});

export const { setFilterView, setFilterList } = filterViewSlice.actions;

export default filterViewSlice.reducer;

// Selectors
export const filterViewSelector = (state: RootState) => state.filterView;

export const selectCurentFilterView = createSelector(filterViewSelector, (data) => data.currentView);
export const selectFilterViewList = createSelector(filterViewSelector, (data) => data.list);
export const selectIsSavedViewOn = createSelector(filterViewSelector, (data) => data.currentView.isSavedViewOn);
