import React, { useEffect, useState } from "react";
import { Button, Paper, Stack, Switch, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import Loader from "../../components/core/Loader/Loader";
import { useLazyGetSettingsQuery, useEditSettingsMutation } from "../../reduxState/apis/settingsApi";
import { userIdSelector } from "../../reduxState/slices/userSlice";
import { capitalizeFirstLetter, getPathForAnalytics } from "../../services/utils";
import { ISettingsList } from "../../consts/settingsPage/settingsPage";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";

export const Settings = () => {
    const analytics = useAnalyticsService();
    const [getSettings, { isLoading }] = useLazyGetSettingsQuery();
    const [editSettings] = useEditSettingsMutation();
    const currentUserId = useSelector(userIdSelector);

    const [settingsList, setSettingsList] = useState<ISettingsList[]>([]);

    useEffect(() => {
        analytics.logEvent("Account Settings Page Viewed", {
            Page: getPathForAnalytics(window.location.pathname),
        });
        getSettings({})
            .unwrap()
            .then((settingsResponse) => {
                setSettingsList(settingsResponse);
            });
    }, []);

    const settingsToggle = (settingsData: ISettingsList) => {
        console.log(settingsData);
        analytics.logEvent("Communication Preference Updated", {
            Page: getPathForAnalytics(window.location.pathname),
            "Communication status": settingsData.enable ? "Enabled" : "Disabled",
            "Communication name": settingsData.name,
            "Communication type": capitalizeFirstLetter(settingsData.notificationType),
        });

        editSettings({
            body: {
                notificationId: settingsData.id,
                userId: currentUserId,
                enable: settingsData.enable,
            },
        }).then(() => {
            const updatedList = settingsList.map((d) => (d.id === settingsData.id ? settingsData : d));
            setSettingsList(updatedList);
        });
    };

    const handleToggle = (e: React.ChangeEvent<HTMLInputElement>, row: ISettingsList) => {
        settingsToggle({ ...row, enable: e.target.checked });
    };

    const openCookieSettingsModal = () => {
        analytics.logEvent("Cookie Settings Viewed", {
            Page: getPathForAnalytics(window.location.pathname),
        });
        window.CC.showSettings();
    };

    return (
        <div>
            <PageHeader pageHeading="User Settings" />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    <div>
                        <Stack gap={2} direction="row" flexWrap="wrap">
                            {settingsList.map((setting) => (
                                <Paper
                                    key={setting.name}
                                    variant="outlined"
                                    sx={{ width: "400px", padding: "10px", background: "white" }}
                                >
                                    <Stack direction="row" justifyContent="space-between" alignItems="center">
                                        <div>
                                            <Typography>{setting.name}</Typography>
                                            <Typography component="span" color="GrayText" variant="body2">
                                                (Type: {capitalizeFirstLetter(setting.notificationType)})
                                            </Typography>
                                        </div>
                                        <div>
                                            <Switch
                                                onChange={(e) => handleToggle(e, setting)}
                                                checked={setting.enable}
                                            />
                                        </div>
                                    </Stack>
                                </Paper>
                            ))}
                            {
                                <Paper variant="outlined" sx={{ width: "400px", padding: "10px", background: "white" }}>
                                    <Stack
                                        direction="row"
                                        justifyContent="space-between"
                                        alignItems="center"
                                        sx={{ padding: "10px", background: "white" }}
                                    >
                                        <Typography>Cookie Settings</Typography>
                                        <Button
                                            variant="outlined"
                                            data-cc="c-settings"
                                            onClick={openCookieSettingsModal}
                                            sx={{
                                                textTransform: "initial",
                                            }}
                                            size="small"
                                        >
                                            Change
                                        </Button>
                                    </Stack>
                                </Paper>
                            }
                        </Stack>
                        <Stack></Stack>
                    </div>
                )}
            </PageContainer>
        </div>
    );
};
