export type Choice = "asc" | "desc";

export interface ICells {
    title: string;
    releaseDate: string;
    description: string;
    link: number;
}

interface IHeadCell {
    id: keyof ICells;
    disablePadding: boolean;
    label: string;
    numeric: boolean;
}

export const ANNOUNCEMENT_PAGE_TABLE_HEADERS: readonly IHeadCell[] = [
    {
        id: "title",
        numeric: false,
        disablePadding: false,
        label: "Title",
    },
    {
        id: "description",
        numeric: false,
        disablePadding: false,
        label: "Description",
    },
    {
        id: "releaseDate",
        numeric: false,
        disablePadding: false,
        label: "Release Date",
    },
    {
        id: "link",
        numeric: false,
        disablePadding: false,
        label: "Link",
    },
];
