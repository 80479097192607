import baseCreateApi from "./baseCreateApi";

export interface ISubscriptionRequestBody {
    externalSubscriptionId: string;
}

export const subscription = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        createSubscription: builder.mutation({
            query: (queryArgs: { params: { orgId: number }; body: ISubscriptionRequestBody }) => ({
                url: `organizations/${queryArgs.params.orgId}/subscriptions`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            external_subscription_id: queryArgs.body.externalSubscriptionId,
                        },
                        type: "subscriptions",
                    },
                }),
            }),
        }),
        editSubscription: builder.mutation({
            query: (queryArgs: { params: { orgId: number; subId: number }; body: ISubscriptionRequestBody }) => ({
                url: `organizations/${queryArgs.params.orgId}/subscriptions/${queryArgs.params.subId}`,
                method: "PATCH",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            external_subscription_id: queryArgs.body.externalSubscriptionId,
                        },
                        type: "subscriptions",
                    },
                }),
            }),
        }),
        deleteSubscription: builder.mutation({
            query: (args: { id: number; orgId: number }) => ({
                url: `organizations/${args.orgId}/subscriptions/${args.id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const { useCreateSubscriptionMutation, useEditSubscriptionMutation, useDeleteSubscriptionMutation } =
    subscription;
