import React from "react";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import { object, string } from "yup";
import { Typography } from "@mui/material";

import { Field } from "../core/Field/Field";
import { HeaderForm } from "../core/Form/HeaderForm";
import { IEmailValues } from "../../interfaces/IEmailValues";
import { fieldClass } from "../../layouts/SimpleForm";
import { FooterForm } from "../core/Form/FooterForm";

interface IForgotPasswordFormProps extends FormikConfig<IEmailValues> {
    onBackToLogin(): void;
}

export const ForgotPasswordForm: React.FC<IForgotPasswordFormProps> = (props) => {
    const { onBackToLogin, ...rest } = props;

    return (
        <Formik
            validationSchema={object().shape({
                email: string().email("Invalid email format").required("E-mail is required"),
            })}
            {...rest}
        >
            {({ errors, handleChange, touched, values, isSubmitting }: FormikProps<IEmailValues>) => {
                const hasEmailError = Boolean((touched.email && errors.email) || errors.base);

                return (
                    <Form>
                        <HeaderForm title="Reset Password" small close={false} />
                        <Field
                            id="email"
                            name="email"
                            label="E-mail"
                            error={hasEmailError}
                            helperText={hasEmailError ? "Oops! This email address is incorrect." : null}
                            value={values.email}
                            onChange={handleChange}
                            placeholder="Email"
                            data-cy="forgotPasswordEmail"
                            fullWidth
                            classes={{
                                root: fieldClass,
                            }}
                        />
                        <Typography component="p" variant="body2">
                            If you’re registered in Prescient, we’ll send you a link to this address that you can use to
                            reset your password.
                        </Typography>
                        <FooterForm
                            submitBtnProps={{
                                disabled: isSubmitting || values.email === "",
                                isLoading: isSubmitting,
                                text: "Reset Password",
                                testId: "forgotPasswordSubmit",
                            }}
                            cancelBtnProps={{
                                text: "Back to Login",
                                onCancelBtnClick: onBackToLogin,
                                startIcon: true,
                            }}
                        />
                    </Form>
                );
            }}
        </Formik>
    );
};
