import { FC } from "react";
import { CircularProgress, Divider, ListItem, ListItemAvatar, ListItemText } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

import { IDataSourceWithConnected } from "src/interfaces/IData";
import { LoadingButton } from "@mui/lab";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";

interface IConnectorCard {
    dataSource: IDataSourceWithConnected;
    currentService: Array<IDataSourceWithConnected["service"]>;
    addChannelHandler: (dataSource: IDataSourceWithConnected) => void;
    removeChannelHandler: (dataSource: IDataSourceWithConnected) => void;
}

export const ConnectorCard: FC<IConnectorCard> = ({
    dataSource,
    currentService,
    addChannelHandler,
    removeChannelHandler,
}) => {
    const isLoading = currentService.includes(dataSource.programmaticName);

    return (
        <>
            <ListItem sx={{ padding: "8px 0" }}>
                <ListItemAvatar>
                    <DataSourceAvatar
                        className={{ width: "40px", height: "40px" }}
                        programmaticName={dataSource.programmaticName}
                    />
                </ListItemAvatar>
                <ListItemText primary={dataSource.name} secondary={dataSource.categories.join(", ")} />
                {dataSource.isConnected ? (
                    <LoadingButton
                        sx={{ width: "120px", height: "40px", fontSize: "14px" }}
                        variant="outlined"
                        color="error"
                        onClick={() => removeChannelHandler(dataSource)}
                        disabled={isLoading}
                        startIcon={!isLoading && <CloseIcon />}
                    >
                        {isLoading ? <CircularProgress color={"inherit"} size={16} /> : "Remove"}
                    </LoadingButton>
                ) : (
                    <LoadingButton
                        sx={{ width: "120px", height: "40px", fontSize: "14px" }}
                        variant="outlined"
                        onClick={() => addChannelHandler(dataSource)}
                        disabled={isLoading}
                        data-cy="connectorCardBtn"
                    >
                        {isLoading ? <CircularProgress color={"inherit"} size={16} /> : "Add Channel"}
                    </LoadingButton>
                )}
            </ListItem>
            <Divider />
        </>
    );
};
