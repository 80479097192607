import React from "react";
import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from "@mui/material";
import queryString from "query-string";
import { toDate } from "date-fns-tz";
import { css } from "@emotion/css";

import { NEGATIVE_TREND_GOOD } from "../../../../consts/performancePaidPage/performancePaidPage";
import { FORMATS } from "../../../../enums/Formats";
import {
    getCurrentLegendName,
    getLegendLabel,
    getMetricNameForLegend,
} from "../../../../services/performancePage/performancePage";
import { formatValue } from "../../../../services/utils";
import { PercentageTrendChip } from "../../../core/Chips/PercentageTrendChip";
import { getRangeFormattedTitle } from "../../../DateRangeFilter/reactDateRangeUtils";

interface IPercentageTable {
    keys: string[];
    pastLegendValues: any;
    legendValues: any;
    metrics: any;
    id: string;
}

export const useStyles = () => ({
    tableWrapper: css({
        boxShadow: "inset 0px 1px 0px rgba(0, 0, 0, 0.12)",
    }),
    tableHead: css({
        backgroundColor: "rgb(240 247 245)",
        fontWeight: "bold",
    }),
    tableCell: css({
        padding: "8px 16px",
    }),
});

const calculateCellValue = (metrics: any, id: string, value: any, keys: any, classes: any) => {
    return keys.map((key: string) => {
        const currentLegend = key.split(" - ")[0].toLowerCase();
        const k = getMetricNameForLegend(currentLegend, id);

        const selectedM = metrics.find((h: { id: any }) => h.id === k);
        let pastValue = formatValue(
            value[key] || 0,
            selectedM ? selectedM.sign : FORMATS.DOLLAR,
            selectedM ? selectedM.fixed : 0,
        );
        if (["trueRoas", "trueRevenue"].includes(k) && (!isFinite(value[key]) || value[key] < 0)) {
            pastValue = "-";
        }
        return (
            <TableCell key={key} className={classes.tableCell}>
                {pastValue}
            </TableCell>
        );
    });
};

export const PercentageTable: React.FC<IPercentageTable> = ({ keys, pastLegendValues, legendValues, metrics, id }) => {
    const filterQueryParamsObj = queryString.parse(window.location.search);

    const classes = useStyles();

    let currentDate;
    let pastDate;
    if (
        typeof filterQueryParamsObj.comparison_start_date === "string" &&
        typeof filterQueryParamsObj.comparison_end_date === "string"
    ) {
        pastDate = getRangeFormattedTitle([
            {
                startDate: toDate(filterQueryParamsObj.comparison_start_date),
                endDate: toDate(filterQueryParamsObj.comparison_end_date),
            },
        ]);
    }

    if (typeof filterQueryParamsObj.start_date === "string" && typeof filterQueryParamsObj.end_date === "string") {
        currentDate = getRangeFormattedTitle([
            {
                startDate: toDate(filterQueryParamsObj.start_date),
                endDate: toDate(filterQueryParamsObj.end_date),
            },
        ]);
    }

    return (
        <TableContainer component={Paper} className={classes.tableWrapper}>
            <Table size="small">
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.tableHead}>Date Range</TableCell>
                        {keys.map((k) => (
                            <TableCell key={k} className={classes.tableHead}>
                                {getLegendLabel(k)}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    <TableRow hover>
                        <TableCell className={classes.tableCell}>{pastDate}</TableCell>
                        {calculateCellValue(metrics, id, pastLegendValues, keys, classes)}
                    </TableRow>
                    <TableRow hover>
                        <TableCell className={classes.tableCell}>{currentDate}</TableCell>
                        {calculateCellValue(metrics, id, legendValues, keys, classes)}
                    </TableRow>
                    <TableRow hover>
                        <TableCell className={classes.tableCell}>
                            <b>% Change</b>
                        </TableCell>
                        {keys.map((key: string) => {
                            const currentLegend = key.split(" - ")[0].toLowerCase();
                            const k = getCurrentLegendName(currentLegend);
                            const per = +(((legendValues[key] - pastLegendValues[key]) / pastLegendValues[key]) * 100);
                            if (
                                ["trueRoas", "trueRevenue"].includes(k) &&
                                (legendValues[key] < 0 || pastLegendValues[key] < 0)
                            ) {
                                return (
                                    <TableCell key={key} className={classes.tableCell}>
                                        -
                                    </TableCell>
                                );
                            }

                            return (
                                <TableCell key={key} className={classes.tableCell}>
                                    {
                                        <PercentageTrendChip
                                            value={isFinite(per) ? per : 100}
                                            isPositiveTrendGood={NEGATIVE_TREND_GOOD.indexOf(k.toUpperCase()) === -1}
                                        />
                                    }
                                </TableCell>
                            );
                        })}
                    </TableRow>
                </TableBody>
            </Table>
        </TableContainer>
    );
};
