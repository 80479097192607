import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

const initialState: number = 0;

const addBlockSkip = createSlice({
    name: "addBlockSkpiCount",
    initialState,
    reducers: {
        setAdBlockSkipCount: (state, { payload }) => {
            return state + 1;
        },
    },
});

export const { setAdBlockSkipCount } = addBlockSkip.actions;

export default addBlockSkip.reducer;

// Selectors
export const adBlockSkipCountSelector = (state: RootState) => state.adBlockSkipCount;
