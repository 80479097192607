import { createSelector, createSlice } from "@reduxjs/toolkit";
import { selectCurrentlyViewingId } from "./organizationSlice";
import { logoutUser } from "../actions/auth";
import { RootState } from "../stores/store";
import { IMember } from "../../interfaces/entities/IMember";
import { UserRole } from "../../enums/UserRole";

const getInitialValues = () => {
    return {} as unknown as IMember;
};

const userDataSlice = createSlice({
    name: "user",
    initialState: getInitialValues(),
    reducers: {
        setUser: (state, { payload }) => payload,
        setUserAfterLoginCheck: (state, { payload }) => payload,
        setUserLastSeenAnnouncement: (state, {}) => {
            state.lastSeenAnnouncement = new Date().toISOString().split("T").join(" ");
        },
    },
    extraReducers: (builder) => {
        builder.addCase(logoutUser, () => {
            return {} as unknown as IMember;
        });
    },
});

export const { setUser, setUserAfterLoginCheck, setUserLastSeenAnnouncement } = userDataSlice.actions;
export default userDataSlice.reducer;

export const userStateSelector = (state: RootState) => state.currentUser;

export const userIdSelector = createSelector(userStateSelector, (data) => data.id);
export const userEmailSelector = createSelector(userStateSelector, (data) => data.email);

// User Company Selectors
export const userCompanyListSelector = createSelector(userStateSelector, (data) => data.companies);

// User Role selectors
// userRoleSelector is deprectated
export const userRoleSelector = createSelector(userStateSelector, (data) => data.role);
export const userSuperAdminRoleSelector = createSelector(userStateSelector, (data) => data.isSuperadmin);
export const userRolesSelector = createSelector(userStateSelector, (data) => data.rolesByCompanyId);
export const currentUserRoleByCompanyIdSelector = createSelector(
    [userRolesSelector, selectCurrentlyViewingId],
    (roles, companyId) => roles && roles[companyId] && roles[companyId].role,
);

export const isSuperAdminSelector = createSelector(
    [userRoleSelector, userSuperAdminRoleSelector],
    (role, isSuperadmin) => isSuperadmin || role === UserRole.SUPERADMIN,
);

export const lastSeenAnnouncementSelector = createSelector(
    userStateSelector,
    (data: IMember) => data.lastSeenAnnouncement,
);
