import baseCreateApi from "./baseCreateApi";

import { keysToCamelCase } from "../../utils/format";
import { transformDataSource } from "src/services/dataSources/dataSources";
import { IDataSourceServiceResponse } from "src/interfaces/api/IDataSourceServiceResponse";
import { IDataSourceInApiResponse } from "src/interfaces/api/IDataSourceResponse";
import { IDataService } from "src/interfaces/IDataServices/IDataServices";

export const dataSourceServices = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getDataSourceServices: builder.query({
            query: () => ({
                url: `data_source_services`,
                method: "GET",
            }),
            transformResponse: (response: IDataSourceServiceResponse): IDataService[] => {
                return (
                    response?.data?.map((d) => ({
                        ...keysToCamelCase(d.attributes),
                    })) || []
                );
            },
        }),
        createDataSourceService: builder.mutation({
            query: (body: IDataService) => ({
                url: "data_source_services",
                method: "POST",
                body: JSON.stringify({
                    data: {
                        type: "data_source_services",
                        attributes: {
                            name: body.name,
                            schema: body.schema,
                            data_source_provider_id: body.dataSourceProviderId,
                        },
                    },
                }),
            }),
            transformResponse: (response: { data: IDataSourceInApiResponse }) => {
                return transformDataSource([response.data]);
            },
        }),
        editDataSourceService: builder.mutation({
            query: (queryArgs: { params: { id: number }; body: IDataService }) => ({
                url: `data_source_services/${queryArgs.params.id}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        type: "data_source_services",
                        attributes: {
                            name: queryArgs.body.name,
                            schema: queryArgs.body.schema,
                            data_source_provider_id: queryArgs.body.dataSourceProviderId,
                        },
                    },
                }),
            }),
            transformResponse: (response: { data: IDataSourceInApiResponse }) => {
                return transformDataSource([response.data]);
            },
        }),
        deleteDataSourceService: builder.mutation({
            query: (id: number) => ({
                url: `data_source_services/${id}`,
                method: "DELETE",
            }),
        }),
    }),
});

export const {
    useGetDataSourceServicesQuery,
    useCreateDataSourceServiceMutation,
    useEditDataSourceServiceMutation,
    useDeleteDataSourceServiceMutation,
} = dataSourceServices;
