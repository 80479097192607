import { Button, Snackbar, Typography } from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router";
import Bugsnag from "@bugsnag/js";
import queryString from "query-string";
import { css } from "@emotion/css";
import { Stack } from "@mui/system";

import {  Flex } from "../../components/core/Flex/Flex";
import { useLazySubscribeQuery, useLazyUnsubscribeQuery } from "../../reduxState/apis/unsubscribeApi";
import { withRouter } from "../../components/ComponentWithRouterProp/ComponentWithRouterProp";
import { SVGIconRenderer } from "../../components/SVGIconRenderer/SVGIconRenderer";
import { FooterForm } from "../../components/core/Form/FooterForm";
import { LOGIN_PATH } from "../../consts/path/path";

const useStyles = () => ({
    unsubscribeEmailClass: css({
        "& h2": {
            marginTop: "0px",
        },
    }),
    confirmBtnClass: css({
        width: "100%",
        marginBottom: "10px",
    }),
    subscribeClass: css({
        textTransform: "initial",
        fontSize: "12px",
        textDecoration: "underline",
    }),
});

interface IUnsubscribeEmail {
    location: {
        search: string;
    };
}

const UnsubscribeEmail = (props: IUnsubscribeEmail) => {
    const [isErrorState, setIsErrorState] = useState(false);
    const params = queryString.parse(props.location.search);
    const { email, job_name, notification_id, user_id } = params;
    const classes = useStyles();
    const navigate = useNavigate();
    const [unsubscribe, { isSuccess, isLoading }] = useLazyUnsubscribeQuery();
    const [subscribe] = useLazySubscribeQuery();
    const [isSubscribeScreen, setIsSubscribeScreen] = useState(false);
    const emailType = job_name && typeof job_name === "string" && job_name.split("_").join(" ");

    const redirectToLogin = () => {
        navigate(LOGIN_PATH);
    };

    const handleUnsubscribe = () => {
        unsubscribe({ user_id: user_id as string, notification_id: notification_id as string })
            .unwrap()
            .then(() => {
                setIsSubscribeScreen(false);
            })
            .catch((error) => {
                setIsErrorState(true);
                Bugsnag.notify(error);
            });
    };

    const handleSubscribe = () => {
        if (isSubscribeScreen) {
            handleUnsubscribe();
        } else {
            subscribe({ user_id: user_id as string, notification_id: notification_id as string })
                .unwrap()
                .then(() => {
                    setIsSubscribeScreen(true);
                })
                .catch((error) => {
                    setIsErrorState(true);
                    Bugsnag.notify(error);
                });
        }
    };

    const hanldeCloseError = () => {
        setIsErrorState(false);
    };

    return (
        <Stack gap="30px" className={classes.unsubscribeEmailClass}>
            <Typography fontSize="1.5rem" color="#000000" fontWeight="700">
                {email}
            </Typography>
            {isSuccess ? (
                <>
                    <Typography>
                        You've successfully been {isSubscribeScreen ? "subscribed" : "unsubscribed"} to
                        <b> {emailType}</b>.
                    </Typography>

                    <span style={{ fontSize: "12px", color: "#00000099" }}>
                        <hr />
                        {isSubscribeScreen ? "Subscribed" : "Unsubscribed"} by accident?{" "}
                        <Button
                            className={classes.subscribeClass}
                            color="primary"
                            size="small"
                            onClick={handleSubscribe}
                        >
                            {!isSubscribeScreen ? "Subscribe" : "Unsubscribe"} again
                        </Button>
                    </span>
                </>
            ) : (
                <>
                    <Typography>
                        Are you sure you want to unsubscribe from <b>{emailType}</b>?
                    </Typography>
                    <FooterForm
                        submitBtnProps={{
                            disabled: isLoading,
                            text: "Confirm",
                            onActionBtnClick: handleUnsubscribe,
                        }}
                        cancelBtnProps={{
                            text: "Cancel",
                            onCancelBtnClick: redirectToLogin,
                            cancelBtnStyle: { justifyContent: "center" },
                        }}
                    />
                </>
            )}
            <Snackbar
                open={isErrorState}
                autoHideDuration={2000}
                onClose={hanldeCloseError}
                message={
                    <Flex>
                        <SVGIconRenderer strokeColor="white" icon="alertTriangleIcon" style={{ marginRight: "8px" }} />
                        <span>Something went wrong</span>
                    </Flex>
                }
            />
        </Stack>
    );
};

export default withRouter(UnsubscribeEmail);
