import { FC, useEffect, useState } from "react";
import { array, date, object, string } from "yup";
import {
    Autocomplete,
    Avatar,
    Button,
    Chip,
    FormControl,
    FormControlLabel,
    FormHelperText,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    SelectChangeEvent,
    Stack,
    TextField,
} from "@mui/material";
import { Box } from "@mui/system";
import { Form, Formik, FormikProps } from "formik";

import { Field } from "../../../core/Field/Field";
import { IGoal } from "../../../../interfaces/entities/IGoal";
import { CONTEXT_OPTION, ContextType, GOAL_TYPE_OPTION, GoalType } from "../../../../consts/HomePage.ts/HomePage";
import { getCampaignChannelOption } from "../../../../services/goal/goal";
import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { inputSpendFormat } from "../../../CampaignComponents/InsightsModal/ForecastTab/forecastUtils";
import { DataSourceAvatar } from "../../../DataSourceAvatar/DataSourceAvatar";

interface ICreateGoalForm {
    goal: IGoal;
    onSubmit: (values: IGoal, setSubmitting: (isSubmitting: boolean) => void) => Promise<void>;
    closeGoalModal: () => void;
    deleteGoalAction: (goal: IGoal, setSubmitting: (isSubmitting: boolean) => void) => Promise<void>;
    classes: any;
    performanceData: IMetricAttributionTableValuesTransformed[];
}

export const CreateGoalForm: FC<ICreateGoalForm> = (props) => {
    const { onSubmit, goal, closeGoalModal, deleteGoalAction, classes, performanceData } = props;
    const [campaignOptions, setCamapignOptions] = useState<any[]>([]);

    const updateChannelOptions = (context: ContextType) => {
        if (context === "channel") {
            const channelOp = getCampaignChannelOption(performanceData, "channel");
            setCamapignOptions(channelOp);
        } else if (context === "campaign") {
            const campaignOp = getCampaignChannelOption(performanceData, "campaign");
            setCamapignOptions(campaignOp);
        }
    };

    useEffect(() => {
        if (goal.context) {
            updateChannelOptions(goal.context);
        }
    }, []);

    return (
        <Formik
            initialValues={goal}
            validationSchema={object().shape({
                endDate: date()
                    .required("End date is required")
                    .when("startDate", (startDate, schema) =>
                        startDate ? schema.min(startDate, "End date must be later than start date") : schema,
                    ),
                goalType: string().required("Type is required"),
                goalValue: string().required("Goal is required"),
                context: string().required("Context is required"),
                visibility: string().required("Visibility is required"),
                campaignsOrChannels: array().when(["context"], {
                    is: (context: ContextType) => context === "campaign" || context === "channel",
                    then: array().min(1, "Please select at least one"),
                }),
            })}
            onSubmit={(values, { setSubmitting }) => {
                onSubmit(values, setSubmitting);
            }}
        >
            {({
                errors,
                handleChange,
                touched,
                values,
                isSubmitting,
                setSubmitting,
                setFieldValue,
            }: FormikProps<IGoal>) => {
                const hasEndDateErr = touched.endDate && (errors.endDate || errors.base);
                const hasGoalTypeErr = touched.goalType && (errors.goalType || errors.base);
                const hasGoalValueErr = touched.goalValue && (errors.goalValue || errors.base);
                const hasContextErr = touched.context && (errors.context || errors.base);
                const hasVisibilityErr = touched.visibility && (errors.visibility || errors.base);
                const campaignsOrChannels = touched.campaignsOrChannels && (errors.campaignsOrChannels || errors.base);

                const slectedGoalType = GOAL_TYPE_OPTION.filter((t) => t.value === values.goalType)[0]?.label;
                const slectedContext = CONTEXT_OPTION.filter(
                    (t) => values.context !== ContextType.PORTFOLIO && t.value === values.context,
                )[0]?.label;

                const handleContext = (event: SelectChangeEvent<ContextType>) => {
                    const { value } = event.target;
                    if (value) {
                        handleChange(event);
                        setFieldValue("campaignsOrChannels", []);
                        if (value) {
                            updateChannelOptions(value as ContextType);
                        }
                    }
                };

                return (
                    <Form>
                        <Stack gap="30px">
                            <Stack direction="row" gap="30px" flexWrap="wrap">
                                <FormControl className={classes.formInput}>
                                    <label>Type</label>
                                    <Select
                                        className={classes.formInput}
                                        displayEmpty={true}
                                        name="goalType"
                                        onChange={handleChange}
                                        value={values.goalType}
                                        error={!!hasGoalTypeErr}
                                    >
                                        {GOAL_TYPE_OPTION.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option?.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {hasGoalTypeErr && (
                                        <FormHelperText
                                            style={{
                                                color: "#F44336",
                                                margin: "3px 14px 0",
                                            }}
                                        >
                                            {errors.goalType}
                                        </FormHelperText>
                                    )}
                                </FormControl>
                                <FormControl className={classes.formInput}>
                                    <label className={classes.formSimpleLabel}>Goal {slectedGoalType}</label>
                                    <Field
                                        placeholder={`${slectedGoalType ? `Enter ${slectedGoalType}...` : `Enter...`}`}
                                        name="goalValue"
                                        value={
                                            values.goalType === GoalType.ROAS
                                                ? values.goalValue
                                                : inputSpendFormat(+values.goalValue, true)
                                        }
                                        onChange={(e) => {
                                            values.goalType === GoalType.ROAS
                                                ? setFieldValue("goalValue", e.target.value)
                                                : setFieldValue(
                                                      "goalValue",
                                                      +e.target.value.toString().replace(/,/g, ""),
                                                  );
                                        }}
                                        error={!!hasGoalValueErr}
                                        helperText={hasGoalValueErr ? errors.goalValue : ""}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack direction="row" gap="30px" flexWrap="wrap">
                                <FormControl className={classes.formInput}>
                                    <label className={classes.formSimpleLabel}>Start Date</label>
                                    <Field
                                        type="date"
                                        name="startDate"
                                        onChange={handleChange}
                                        defaultValue={values.startDate || null}
                                    />
                                </FormControl>
                                <FormControl className={classes.formInput}>
                                    <label className={classes.formSimpleLabel}>End Date</label>
                                    <Field
                                        type="date"
                                        name="endDate"
                                        onChange={handleChange}
                                        error={!!hasEndDateErr}
                                        helperText={hasEndDateErr ? errors.endDate : ""}
                                        defaultValue={values.endDate || null}
                                    />
                                </FormControl>
                            </Stack>
                            <Stack direction="row" gap="30px" flexWrap="wrap">
                                <FormControl sx={{ width: "480px" }}>
                                    <label>Context</label>
                                    <Select
                                        displayEmpty={true}
                                        name="context"
                                        onChange={handleContext}
                                        value={values.context}
                                        error={!!hasContextErr}
                                    >
                                        {CONTEXT_OPTION.map((option) => (
                                            <MenuItem key={option.value} value={option.value}>
                                                {option?.label}
                                            </MenuItem>
                                        ))}
                                    </Select>
                                    {hasContextErr && (
                                        <FormHelperText className={classes.error}>{errors.context}</FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                            {!values.context ||
                                (values.context !== ContextType.PORTFOLIO && (
                                    <Stack>
                                        <FormControl sx={{ maxWidth: "480px" }}>
                                            <label className={classes.formSimpleLabel}>Choose {slectedContext}</label>
                                            <Autocomplete
                                                multiple
                                                id="tags-outlined"
                                                options={campaignOptions}
                                                getOptionLabel={(option) => option?.label}
                                                value={values.campaignsOrChannels}
                                                filterSelectedOptions
                                                isOptionEqualToValue={(option, value) => option.value === value.value}
                                                renderOption={(props, option) => (
                                                    <Box
                                                        component="li"
                                                        sx={{ "& > img": { mr: 2, flexShrink: 0 } }}
                                                        {...props}
                                                    >
                                                        <DataSourceAvatar
                                                            programmaticName={option?.connectorName}
                                                            className={classes.connectorImage}
                                                        />
                                                        {option?.label}
                                                    </Box>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        name="campaignsOrChannels"
                                                        error={!!campaignsOrChannels}
                                                    />
                                                )}
                                                onChange={(e, newValue) => {
                                                    setFieldValue("campaignsOrChannels", newValue);
                                                }}
                                                renderTags={(value, getTagProps) =>
                                                    value.map((option, index) => {
                                                        return (
                                                            <Chip
                                                                avatar={
                                                                    <DataSourceAvatar
                                                                        programmaticName={option?.connectorName}
                                                                    />
                                                                }
                                                                variant="outlined"
                                                                label={option?.label}
                                                                {...getTagProps({ index })}
                                                            />
                                                        );
                                                    })
                                                }
                                                disabled={!values.context}
                                            />
                                            {campaignsOrChannels && (
                                                <FormHelperText className={classes.error}>
                                                    <>
                                                        {errors.campaignsOrChannels} {values.context}
                                                    </>
                                                </FormHelperText>
                                            )}
                                        </FormControl>
                                    </Stack>
                                ))}
                            <Stack>
                                <FormControl>
                                    <label>Goal Visibility</label>
                                    <RadioGroup
                                        aria-labelledby="demo-radio-buttons-group-label"
                                        name="visibility"
                                        onChange={handleChange}
                                        value={values.visibility}
                                    >
                                        <FormControlLabel
                                            value="common"
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <b>Public •</b> Viewable by anyone in your organization
                                                </>
                                            }
                                        />
                                        <FormControlLabel
                                            value="personal"
                                            control={<Radio />}
                                            label={
                                                <>
                                                    <b>Private • </b> Only viewable by you
                                                </>
                                            }
                                        />
                                    </RadioGroup>
                                    {hasVisibilityErr && (
                                        <FormHelperText className={classes.error}>{errors.visibility}</FormHelperText>
                                    )}
                                </FormControl>
                            </Stack>
                            <Stack direction="row" gap="10px" justifyContent={goal.id ? "space-between" : "end"}>
                                {goal.id && (
                                    <Button
                                        variant="contained"
                                        color="error"
                                        className={classes.actionBtn}
                                        onClick={() => {
                                            setSubmitting(true);
                                            deleteGoalAction(goal, setSubmitting);
                                        }}
                                        disabled={isSubmitting}
                                    >
                                        Delete
                                    </Button>
                                )}
                                <Stack direction="row" gap="10px">
                                    <Button
                                        sx={{
                                            color: "black",
                                        }}
                                        className={classes.actionBtn}
                                        variant="outlined"
                                        onClick={closeGoalModal}
                                        disabled={isSubmitting}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        className={classes.actionBtn}
                                        variant="contained"
                                        type="submit"
                                        disabled={isSubmitting}
                                    >
                                        {goal.id ? "Save Changes" : "Create Goal"}
                                    </Button>
                                </Stack>
                            </Stack>
                        </Stack>
                    </Form>
                );
            }}
        </Formik>
    );
};
