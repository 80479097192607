import amplitude, { Config } from "amplitude-js";
import { IDictionary } from "../../../interfaces/IDictionary";
import { getDateInYYYYMMDDFormat } from "../../utils";
import { IAnalytics, IOrganizationWithRole } from "../IAnalytics";

const amplitudeConfig: Config = {
    includeUtm: true,
    includeReferrer: true,
    includeGclid: true,
    includeFbclid: true,
    unsetParamsReferrerOnNewSession: true,
};

export class AmplitudeAnalytics implements IAnalytics {
    public readonly instance = amplitude.getInstance();
    constructor(token?: string) {
        token && this.instance.init(token, undefined, amplitudeConfig);
    }
    public init(token: string) {
        this.instance.init(token, undefined, amplitudeConfig);
    }

    public login(user: any) {
        const currentDate = getDateInYYYYMMDDFormat(new Date());
        const createdAt = getDateInYYYYMMDDFormat(new Date(user.createdAt));

        const userProperties: any = {
            "User ID": +user.id,
            Email: user.email,
            "Last login date": currentDate,
            "Account creation date": createdAt,
        };

        const identify = new amplitude.Identify().setOnce("First login date", currentDate);

        this.instance.setUserId(user.id.toString());
        this.instance.identify(identify);
        this.instance.setUserProperties(userProperties);
        this.instance.logEvent("Logged In");
    }

    public logout() {
        this.instance.setUserId(null);
    }

    public logEvent(eventType: string, eventProperties?: any) {
        this.instance.logEvent(eventType, eventProperties);
    }

    public setCurrentOrganization(organization: IOrganizationWithRole): void {
        this.instance.setUserProperties({
            "Current organization role": organization.role,
            "Current organization": organization.name,
            "Current organization id": organization.id,
            "Current organization subscription status": organization.subscriptionStatus,
        });
    }

    public setUserProperties(properties: IDictionary<any>): void {
        this.instance.setUserProperties(properties);
    }

    public setUser(user: any): void {
        this.instance.setUserId(user.id);
    }
}
