import { css } from "@emotion/css";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { object, string } from "yup";
import { Button, TextField, Typography } from "@mui/material";
import { bind } from "decko";
import Spinner from "@mui/material/CircularProgress";

import { IMember } from "../../interfaces/entities/IMember";
import { AlignItems, Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { ImageInput } from "../core/ImageInput/ImageInput";
import { BLACK_COLOR } from "../../consts/colors";
import Chips from "../core/Chips/Chips";
import { changeDate } from "../../services/utils";
import useAvatar from "../MemberCard/useAvatar";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { MemberDeleteModal } from "../MemberComponents/MemberDeleteModal/MemberDeleteModal";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { imageInputClass } from "../../assets/styles/commonStyle";

const buttonClass = css`
    margin-top: 40px !important;
`;

export const editButtons = css`
    width: 156px !important;
`;

export const dateWrapper = css`
    flex-grow: 1;
`;

const formButtonClass = css`
    width: 100%;
    margin-top: 10px;
`;

export const labelClass = css`
    font-family: "Inter", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    color: ${BLACK_COLOR}!important;
    margin-bottom: 8px !important;
`;

const TextFields = css`
    margin-top: 15px !important;
`;

export interface IMemberFormProps extends FormikConfig<IMember> {
    isEditMode?: boolean;
    isCurrentUser?: boolean;
    role: string;
    currentCompany: IOrganization;
    deleteDisableBtn: boolean;
    onDelete(items: IMember): void;

    onCancel?(...args: any[]): any;

    onRemove?(...args: any[]): any;

    onChangeMode?(...args: any[]): any;
}

export class MemberForm extends React.Component<IMemberFormProps, {}> {
    public state = {
        image: "",
        imageName: "",
        isDeleteModal: false,
    };

    @bind()
    public closeModal() {
        this.setState({ isDeleteModal: false });
    }

    public render() {
        const toBase64 = (file: Blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        const getFile = async (url: Blob) => {
            return toBase64(url);
        };
        const {
            isEditMode,
            isCurrentUser,
            onCancel,
            onRemove,
            onChangeMode,
            onDelete,
            currentCompany,
            deleteDisableBtn,
            role,
            ...rest
        } = this.props;
        const imageFunction = async (getImage: any) => {
            return getImage(this.state.image);
        };

        return (
            <Formik
                validationSchema={object().shape({
                    email: string().email("Invalid email format").required("E-mail is required"),
                    firstName: string().required("First name is required"),
                    lastName: string().required("Last name is required"),
                })}
                {...rest}
            >
                {({ errors, handleChange, touched, values, isSubmitting, setFieldValue }: FormikProps<IMember>) => {
                    const avatarUrl = useAvatar(values.avatarMediumUrl || "");

                    const hasEmailError = touched.email && (errors.email || errors.base);
                    const hasFirstNameError = touched.firstName && (errors.firstName || errors.base);
                    const hasLastNameError = touched.lastName && (errors.lastName || errors.base);

                    const isExistMember = Boolean(values.id);
                    const disabledInput = isSubmitting || (Boolean(values.id) && !isEditMode);

                    return (
                        <Form>
                            <div className={imageInputClass}>
                                <ImageInput
                                    onChange={handleChange}
                                    initial={avatarUrl}
                                    onSelectImage={(file, name) => {
                                        this.setState(
                                            {
                                                image: file,
                                                imageName: name,
                                            },
                                            async () => {
                                                setFieldValue("avatar", await imageFunction(getFile));
                                                setFieldValue("avatarName", this.state.imageName);
                                            },
                                        );
                                    }}
                                    isAvatar
                                    name="avatar"
                                />
                            </div>
                            <TextField
                                fullWidth={true}
                                id="firstName"
                                name="firstName"
                                variant="outlined"
                                disabled={disabledInput}
                                label="First name"
                                value={values.firstName}
                                onChange={handleChange}
                                // maxLength={30}
                                // max="328px"
                                placeholder="First name"
                                className={TextFields}
                            />
                            <TextField
                                fullWidth={true}
                                id="lastName"
                                variant="outlined"
                                name="lastName"
                                label="Last name"
                                disabled={disabledInput}
                                // error={hasLastNameError ? errors.lastName : null}
                                value={values.lastName}
                                onChange={handleChange}
                                // maxLength={30}
                                placeholder="Last name"
                                className={TextFields}
                            />
                            <TextField
                                fullWidth={true}
                                id="email"
                                variant="outlined"
                                name="email"
                                label="E-mail"
                                disabled={isEditMode}
                                error={!!hasEmailError}
                                value={values.email}
                                onChange={handleChange}
                                placeholder="E-mail"
                                className={TextFields}
                                helperText={hasEmailError ? errors.email : ""}
                            />
                            <div style={{ marginTop: "15px" }}>
                                {/*<RadioGroup*/}
                                {/*  items={[*/}
                                {/*    { label: 'User', value: 'member' },*/}
                                {/*    { label: 'Admin', value: 'admin' },*/}
                                {/*  ]}*/}
                                {/*  title='Role'*/}
                                {/*  onClick={(role: string) =>*/}
                                {/*    setFieldValue('role', role.toLowerCase())*/}
                                {/*  }*/}
                                {/*  defaultValue={values.role}*/}
                                {/*/>*/}
                                <Typography className={labelClass}>Role</Typography>
                                <Chips
                                    items={[
                                        { label: "Viewer", key: 0, isAll: true },
                                        {
                                            label: "Admin",
                                            key: 1,
                                            isAll: false,
                                        },
                                    ]}
                                    callback={(role: number) => setFieldValue("role", role === 0 ? "member" : "admin")}
                                    multiple={false}
                                    checkedItem={role === "member" ? 0 : 1}
                                    isMargin={false}
                                    isEventPerform={false}
                                />
                            </div>
                            {isEditMode && isExistMember ? (
                                <div style={{ margin: "20px 0 40px 0" }}>
                                    <Typography className={labelClass}>Created</Typography>
                                    <Flex direction={FlexDirection.Row} alignItems={AlignItems.Center}>
                                        <FlexItem className={dateWrapper}>
                                            <div style={{ width: "112px" }}>
                                                <TextField
                                                    variant="outlined"
                                                    disabled
                                                    value={changeDate(values.createdAt)}
                                                />
                                            </div>
                                        </FlexItem>
                                        <FlexItem alignSelf={AlignItems.Center}>
                                            <Button
                                                variant="text"
                                                style={{ color: "red" }}
                                                onClick={() => this.setState({ isDeleteModal: true })}
                                                startIcon={
                                                    <SVGIconRenderer
                                                        icon="trashIcon"
                                                        height="20px"
                                                        width="20px"
                                                        strokeColor="error"
                                                    />
                                                }
                                            >
                                                Delete member
                                            </Button>
                                        </FlexItem>
                                    </Flex>
                                </div>
                            ) : null}
                            <Flex direction={FlexDirection.Column}>
                                <FlexItem className={formButtonClass}>
                                    {!isExistMember && (
                                        <Button
                                            fullWidth={true}
                                            variant="contained"
                                            // loading={isSubmitting}
                                            type="submit"
                                            color="secondary"
                                            disabled={
                                                isSubmitting ||
                                                !values.email ||
                                                !values.firstName ||
                                                !values.lastName ||
                                                !values.role ||
                                                !!hasEmailError
                                            }
                                            className={buttonClass}
                                        >
                                            {isSubmitting ? <Spinner size={25} /> : "Create member"}
                                        </Button>
                                    )}
                                    {isEditMode && isExistMember && (
                                        <Flex direction={FlexDirection.Row} spacing="16px">
                                            <FlexItem>
                                                <Button
                                                    variant="contained"
                                                    onClick={this.props.onCancel}
                                                    className={editButtons}
                                                >
                                                    Cancel
                                                </Button>
                                            </FlexItem>
                                            <FlexItem>
                                                <Button
                                                    // size="large"
                                                    variant="contained"
                                                    // loading={isSubmitting}
                                                    type="submit"
                                                    color="primary"
                                                    style={{ color: "#ffffff" }}
                                                    className={editButtons}
                                                    disabled={
                                                        isSubmitting ||
                                                        !values.email ||
                                                        !values.firstName ||
                                                        !values.lastName ||
                                                        !role
                                                    }
                                                >
                                                    {isSubmitting ? <Spinner size={25} /> : "Save changes"}
                                                </Button>
                                            </FlexItem>
                                        </Flex>
                                    )}
                                </FlexItem>
                            </Flex>
                            {this.state.isDeleteModal && values && (
                                <MemberDeleteModal
                                    member={values}
                                    onDelete={onDelete}
                                    closeModal={this.closeModal}
                                    currentCompany={currentCompany}
                                    disableBtn={deleteDisableBtn}
                                />
                            )}
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
