import { FC, useEffect } from "react";
import { useSelector } from "react-redux";
import {
    Paper,
    Skeleton,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Typography,
} from "@mui/material";
import { format, startOfToday } from "date-fns";
import { Stack } from "@mui/system";
import cloneDeep from "lodash/cloneDeep";

import { FORMATS } from "src/enums/Formats";
import {
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    ISummary,
} from "src/interfaces/entities/IScenario";
import { useLazyCompanyForecastQuery } from "src/reduxState/apis/optimizationApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { formatValue } from "src/services/utils";
import { PredictedLiftChart } from "./PredictedLiftChart";
import {
    NO_CHANGE_FORECAST_HEADING,
    OPTIMIZED_FORECAST_HEADING,
    PREDICTED_LIFT_PER_HEADING,
    getPredictedLiftTableBody,
} from "src/services/optimizationPage/optimization";

export enum PredictedLiftTabs {
    "EcommerceForecast" = "Ecommerce Forecast",
    "OptimizationPredictedLift" = "Optimization Predicted Lift",
    "Media" = "Media",
    "Trend" = "Trend",
    "Holidays" = "Holidays",
}

interface IPerdictedLiftTab {
    scenario: IScenario;
    summaryArray: ISummary[];
    campaignDataBasedOnCompareTo: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
}

export const PerdictedLiftTab: FC<IPerdictedLiftTab> = ({ scenario, summaryArray, campaignDataBasedOnCompareTo }) => {
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const [
        companyForecast,
        {
            data: chartData = {
                backtestTrue: [],
                forecast: { trendForecast: [], totalForecast: [], dates: [] },
                backtest: { trendForecast: [], totalForecast: [], dates: [] },
            },
            isLoading,
            isFetching,
        },
    ] = useLazyCompanyForecastQuery();

    useEffect(() => {
        companyForecast({
            orgId: currentOrgId,
            timeframe: scenario.forecastTimeframe,
            startDate: format(startOfToday(), "yyyy-MM-dd"),
        });
    }, []);

    const tableHeader = [
        {
            label: `Next ${scenario.forecastTimeframe} Days Predicted Lift Decomposition`,
            id: "decomposition",
            align: "left",
        },
        { label: NO_CHANGE_FORECAST_HEADING, id: "actualPast", sign: FORMATS.DOLLAR, fixed: 0, align: "right" },
        { label: OPTIMIZED_FORECAST_HEADING, id: "predictedFuture", sign: FORMATS.DOLLAR, fixed: 0, align: "right" },
        { label: PREDICTED_LIFT_PER_HEADING, id: "predictedLiftPer", sign: FORMATS.PERCENT, fixed: 2, align: "right" },
    ];

    const companyForecastResponse = cloneDeep(chartData);
    const optimizationRevenue = summaryArray.find((s) => s.title === "Total Revenue")?.change || 0;

    const isOptimizationRevenueNegative = optimizationRevenue < 0;
    const tableBody = getPredictedLiftTableBody(companyForecastResponse, optimizationRevenue);

    return (
        <Stack gap="40px">
            <Stack gap="40px">
                <Stack
                    sx={{ boxShadow: "0px -1px 0px 0px #D9D9D9 inset", paddingBottom: "5px" }}
                    direction="row"
                    justifyContent="space-between"
                >
                    <Typography color="#000" variant="h5" fontWeight="bold">
                        Predicted Lift Chart
                    </Typography>
                </Stack>
                {chartData && (
                    <Paper
                        elevation={0}
                        sx={{ padding: 1, border: "1px solid #D9D9D9", height: "400px", paddingBottom: "20px" }}
                    >
                        <PredictedLiftChart isLoading={isLoading || isFetching} tableBody={tableBody} />
                    </Paper>
                )}
            </Stack>
            <Paper elevation={0} sx={{ backgroundColor: "white", border: "1px solid #D9D9D9", borderRadius: "5px" }}>
                <TableContainer>
                    <Table>
                        <TableHead>
                            <TableRow>
                                {tableHeader.map((h) => (
                                    <TableCell sx={{ textAlign: h.align, fontWeight: "bold" }} key={h.label}>
                                        {h.label}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {tableBody.map((row: any) => (
                                <TableRow key={row.decomposition}>
                                    {tableHeader.map((header) => (
                                        <TableCell key={header.id} sx={{ textAlign: header.align }}>
                                            {isLoading || isFetching ? (
                                                <Skeleton />
                                            ) : (
                                                formatValue(row[header.id], header.sign, header.fixed)
                                            )}
                                        </TableCell>
                                    ))}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Paper>
        </Stack>
    );
};
