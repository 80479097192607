import * as React from "react";
import { Link } from "react-router-dom";
import { css } from "@emotion/css";
import { Typography } from "@mui/material";

import { Message } from "../../components/Message/Message";
import { Button } from "../../components/core/Button/Button";
import { AlignItems, Flex, FlexDirection, JustifyContent } from "../../components/core/Flex/Flex";
import { FlexItem } from "../../components/core/FlexItem/FlexItem";

import { useForgotPasswordMutation } from "../../reduxState/apis/authApi";
import icon from "./../../assets/Illustrations.png";
import { HELP_PAGE_PATH } from "../../consts/path/path";

const buttonClass = css`
    margin-top: 16px !important;
`;

interface IResendEmailProps {
    email: string;
}

const textWrapper = css`
    margin-top: 24px;
`;

const subTitleWrapper = css`
    font-family: "Inter", "Roboto", sans-serif !important;
    font-style: normal !important;
    font-weight: normal !important;
    text-align: center !important;
    letter-spacing: 0.1px !important;
`;

const titleWrapper = css`
    cursor: pointer;
    ${subTitleWrapper};
`;

const ResendEmail: React.FC<IResendEmailProps> = ({ email }) => {
    const [forgetPassword] = useForgotPasswordMutation();
    const handleResendEmail = async () => {
        await forgetPassword(email)
            .unwrap()
            .catch((error) => console.log(error));
    };

    return (
        <Flex direction={FlexDirection.Column} alignItems={AlignItems.Center} justifyContent={JustifyContent.Center}>
            <FlexItem>
                <Message
                    icon={icon}
                    title="Your reset password link has expired, because you either already used it or did not use it within 6 hours. 😟 "
                    subtitle="Create the new one by clicking on the button down."
                />
            </FlexItem>
            <FlexItem alignSelf={AlignItems.Center} justifySelf={JustifyContent.Center}>
                <Button className={buttonClass} onClick={handleResendEmail}>
                    Request another link
                </Button>
            </FlexItem>
            <FlexItem alignSelf={AlignItems.Center} justifySelf={JustifyContent.Center} className={textWrapper}>
                <Flex direction={FlexDirection.Row} spacing="8px">
                    <FlexItem>
                        <Typography variant="body2" className={subTitleWrapper}>
                            If the issue continues to persist,
                        </Typography>
                    </FlexItem>
                    <FlexItem>
                        <Link to={HELP_PAGE_PATH}>
                            <Typography variant="body2" className={titleWrapper}>
                                let us know 😊
                            </Typography>
                        </Link>
                    </FlexItem>
                </Flex>
            </FlexItem>
        </Flex>
    );
};
export default ResendEmail;
