import camelCase from "lodash/camelCase";
import { IErrorValues } from "../interfaces/IErrorValues";

export const transformApiErrors = (errors: any[] | { message: string }): IErrorValues => {
  if (!Array.isArray(errors)) {
    return { base: errors ? errors.message : "" };
  }

  return errors && errors.reduce((acc, curent) => {
    if (curent.source) {
      acc[camelCase(curent.source.parameter)] = curent.detail;
    } else {
      acc.base = curent.detail;
    }

    return acc;
  }, {});
};
