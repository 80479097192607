import { format, isAfter } from "date-fns";
import { toDate } from "date-fns-tz";

import { ContextType, GoalType, IChannelCampaignOption } from "../../consts/HomePage.ts/HomePage";
import { IGoalDTO } from "../../interfaces/DTO/IGoalDTO";
import { IGoal } from "../../interfaces/entities/IGoal";
import { keysToCamelCase } from "../../utils/format";
import { formatValue, getPreparedValue } from "../utils";
import { FORMATS } from "../../enums/Formats";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { calculatePercentage } from "../performancePage/performancePage";

export const generateGoalBody = (body: IGoal) => {
    return {
        data: {
            attributes: {
                goal_type: body.goalType,
                goal_value: +body.goalValue,
                start_date: body.startDate,
                end_date: body.endDate,
                context: body.context,
                channels: JSON.stringify(
                    body.context === "channel" ? body.campaignsOrChannels.map((c) => c.connectorName) : [],
                ),
                campaigns: JSON.stringify(
                    body.context === "campaign" ? body.campaignsOrChannels.map((c) => c.campaignId) : [],
                ),
                visibility: body.visibility,
                creator_id: body.creatorId,
            },
            type: "goals",
        },
    };
};

export const transformGoal = (data: IGoalDTO[] = []): IGoal[] => {
    return data.map((goal) => {
        const { attributes } = goal;
        const transformAtt = keysToCamelCase(attributes);
        return {
            ...transformAtt,
            campaignsOrChannels:
                transformAtt.context === "campaign" ? attributes.campaigns || [] : attributes.channels || [],
            id: goal.id,
            goalPercentage: calculatePercentage(transformAtt.reportingData, +transformAtt.goalValue),
        };
    });
};

export const getDateSentence = (startDate: string, endDate: string) => {
    if (startDate && endDate) {
        return `between ${format(toDate(startDate), "MM/dd/yyy")} and ${format(toDate(endDate), "MM/dd/yyy")}`;
    } else if (startDate === null && endDate) {
        return `by ${format(toDate(endDate), "MM/dd/yyy")}`;
    } else {
        return "";
    }
};

export const getCampaignChannelOption = (
    performanceData: IMetricAttributionTableValuesTransformed[],
    contextValue: ContextType | string,
) => {
    if (!performanceData) {
        return [];
    }
    if (contextValue === "campaign") {
        let data: IChannelCampaignOption[] = [];
        performanceData.forEach((campaign) => {
            if (campaign.campaignId && campaign.campaignName) {
                data = [...data, { value: campaign.campaignId, label: campaign.campaignName, ...campaign }];
            }
        });
        return data;
    }
    if (contextValue === "channel") {
        let data: IChannelCampaignOption[] = [];
        performanceData.forEach((campaign) => {
            if (data.findIndex((item) => item.value === campaign.connectorName) === -1) {
                if (campaign.channelName && campaign.connectorName) {
                    data = [...data, { value: campaign.connectorName, label: campaign.channelName, ...campaign }];
                }
            }
        });
        return data;
    }
    return [];
};

export const goalMetaData = {
    revenue: { lable: "Revenue", unit: FORMATS.DOLLAR, decimal: 0 },
    roas: { lable: "ROAS", unit: FORMATS.NUMERIC, decimal: 2 },
    budget: { lable: "Spend", unit: FORMATS.DOLLAR, decimal: 0 },
    new_customers: { lable: "New Customers", unit: FORMATS.NUMERIC, decimal: 0 },
    cac: { lable: "CAC", unit: FORMATS.DOLLAR, decimal: 0 },
};

export const getStatus = (goal: IGoal) => {
    if (goal.goalType === GoalType.ROAS) {
        if (goal.goalPercentage <= 95) {
            return "Reach";
        } else {
            return "Maintain";
        }
    }

    if (goal.goalType === GoalType.CAC) {
        if (goal.goalPercentage <= 105) {
            return "Maintain";
        } else {
            return "Reduce";
        }
    }

    if (goal.goalType === GoalType.NEW_CUSTOMERS) {
        return "Acquire";
    }

    return "Reach";
};

export const getGoalListWithContext = (
    goalList: IGoal[],
    performanceData: IMetricAttributionTableValuesTransformed[],
) => {
    return goalList?.map((goal) => {
        const dateSentence = getDateSentence(goal.startDate, goal.endDate);
        let campaignsOrChannels: any[] = [];
        if (goal.context === ContextType.CHANNEL) {
            const channelOp = getCampaignChannelOption(performanceData, "channel");
            campaignsOrChannels = channelOp.filter((c) => goal.channels?.includes(c.connectorName));
        } else if (goal.context === ContextType.CAMPAIGN) {
            const campaignOp = getCampaignChannelOption(performanceData, "campaign");
            campaignsOrChannels = campaignOp.filter((c) => goal.campaigns?.includes(c.campaignId));
        }

        return {
            ...goal,
            title: `${getStatus(goal)} ${getPreparedValue(
                goalMetaData[goal.goalType].unit,
                +goal.goalValue,
                goalMetaData[goal.goalType].decimal,
            )}  in ${goalMetaData[goal.goalType].lable} ${dateSentence}`,
            currentReportedTitle: (
                <>
                    {isAfter(new Date(), toDate(goal.endDate)) ? "" : "Current"} {goalMetaData[goal.goalType].lable}:{" "}
                    <b>
                        {formatValue(
                            +goal.reportingData,
                            goalMetaData[goal.goalType].unit,
                            goalMetaData[goal.goalType].decimal,
                        )}
                    </b>{" "}
                    ({formatValue(+goal.goalPercentage, FORMATS.PERCENT, 0)} of goal)
                </>
            ),
            campaignsOrChannels,
            selectedCampaignsOrChannels:
                goal.context === ContextType.CAMPAIGN
                    ? campaignsOrChannels.map((c) => c.campaignName)
                    : goal.context === ContextType.CHANNEL
                    ? campaignsOrChannels.map((c) => c.channelName)
                    : [],
            reportingData: goal.reportingData,
        };
    });
};
