import React, { useState } from "react";
import { TableBody, TableCell, TableRow } from "@mui/material";
import { useSelector } from "react-redux";

import { stableSort, getComparator } from "../../../utils/sort";
import { useStylesTable } from "../../../consts/StylesForTables";
import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { IAnnouncement } from "../../../interfaces/entities/IAnnouncement";
import { paginationCountSelector } from "../../../reduxState/slices/settingsSlice";
import { AnnouncementTableRow } from "../AnnouncementTableRow/AnnouncementTableRow";
import { AnnouncementTableHeader } from "../AnnouncementTableHeader/AnnouncementTableHeader";
import { ANNOUNCEMENT_PAGE_TABLE_HEADERS, Choice, ICells } from "../../../consts/announcementPage/announcementPage";

interface IAnnouncementTable {
    announcements: IAnnouncement[];
    onEditAnnouncement: (announcement: IAnnouncement) => void;
    onDeleteAnnouncement: (announcement: IAnnouncement) => void;
}

export const AnnouncementTable: React.FC<IAnnouncementTable> = ({
    announcements,
    onEditAnnouncement,
    onDeleteAnnouncement,
}) => {
    const classes = useStylesTable();
    const rowsPerPage = useSelector(paginationCountSelector);

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("");
    const [page, setPage] = useState<number>(0);

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>, announcement: IAnnouncement) => {
        e.stopPropagation();
        onEditAnnouncement(announcement);
    };

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>, announcement: IAnnouncement) => {
        e.stopPropagation();
        onDeleteAnnouncement(announcement);
    };

    const handleChangePage = (event: unknown, newPage: number) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(0);
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: announcements.length,
                page,
                handleChangePage,
                handleChangeRowsPerPage,
            }}
        >
            <AnnouncementTableHeader onRequestSort={handleRequestSort} choice={order} choiceBy={orderBy} />
            <TableBody className={classes.tableBody}>
                {announcements.length === 0 ? (
                    <TableRow>
                        <TableCell align="center" colSpan={ANNOUNCEMENT_PAGE_TABLE_HEADERS.length + 1}>
                            No announcement found
                        </TableCell>
                    </TableRow>
                ) : (
                    stableSort(announcements, getComparator(order, orderBy))
                        .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                        .map((row: any) => {
                            return (
                                <AnnouncementTableRow
                                    key={row.id}
                                    announcement={row}
                                    onDeleteAnnouncement={handleDelete}
                                    onEditAnnouncement={handleEdit}
                                />
                            );
                        })
                )}
            </TableBody>
        </CustomTableStructure>
    );
};
