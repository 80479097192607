import { Button, Divider, Link, MenuItem, Select, Skeleton, Tooltip, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { Fragment, FC, useEffect, useState } from "react";
import { useSnackbar } from "notistack";
import { grey } from "@mui/material/colors";
import BatchPredictionOutlinedIcon from "@mui/icons-material/BatchPredictionOutlined";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import uniqueId from "lodash/uniqueId";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";

import {
    COMPANY_FORECAST_TAB,
    COMPANY_FORECAST_TAB_FUNC_DICTIONARY,
    PREDICTION_ACCURACY_CARD,
    COMPANY_BACKTEST_TAB_FUNC_DICTIONARY,
    forecastTabs,
    formatDateRangeForEcommerce,
} from "src/services/optimizationPage/optimization";
import { useLazyCompanyWideBacktestQuery, useLazyCompanyWideForecastQuery } from "src/reduxState/apis/optimizationApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { useSelector } from "react-redux";
import { IDictionary } from "src/interfaces/IDictionary";
import { formatValue } from "src/services/utils";
import { DashboardCardHeader } from "src/components/Dashboard/CardComponents/CardHeader/CardHeader";
import { HomepageCardContainer } from "../CardComponents/HomepageCardContainer";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";
import { HomePagePartialCard } from "../CardComponents/HomePagePartialCard";
import { getModeledMetricLabelBasedOnService } from "src/consts/performancePaidPage/performancePaidPage";
import { defaultRevenueSourceSelector } from "src/reduxState/slices/connectorsSlice";
import { IForecastCard } from "src/consts/HomePage.ts/HomePage";
import { ForecastTimeframe } from "src/interfaces/api/ICompanyForecastResponseV1";
import { CompanyForecastMetricMapping } from "src/interfaces/companyForecast/ICompanyBacktestFormattedResponse";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";

const INITIAL_CARD_DATA = {
    predictedRevenue: {
        [ForecastTimeframe.THIRTY]: {},
        [ForecastTimeframe.SIXTY]: {},
        [ForecastTimeframe.NINETY]: {},
    },
    predictionAccuracy: {
        [ForecastTimeframe.THIRTY]: {},
        [ForecastTimeframe.SIXTY]: {},
        [ForecastTimeframe.NINETY]: {},
    },
};

interface ICompanyCard {
    cards: IForecastCard[];
    title: string;
    isLoading: boolean;
    tooltip: string | JSX.Element;
    dateRange?: string;
}
[];

interface ICompanyForecastTab {
    tooltipRef: React.RefObject<HTMLDivElement>;
}

export const CompanyForecastTab: FC<ICompanyForecastTab> = ({ tooltipRef }) => {
    const { enqueueSnackbar } = useSnackbar();

    const [timeframe, setTimeframe] = useState<ForecastTimeframe>(ForecastTimeframe.THIRTY);
    const [totalTabValue, setTotalTabValue] = useState<IDictionary>(INITIAL_CARD_DATA);
    const defaultRevenueSource = useSelector(defaultRevenueSourceSelector);
    const orgId = useSelector(selectCurrentlyViewingId);
    const partialStateForHome = useSelector(partialStateSelectorForHome);
    const modeledMetricLabel = getModeledMetricLabelBasedOnService(defaultRevenueSource, false);
    const analyticsService = useAnalyticsService();

    const [companyForecast, { isLoading: isForecastLoading, isFetching: isForecastFetching }] =
        useLazyCompanyWideForecastQuery();
    const [companyBacktest, { isLoading: isBacktestLoading, isFetching: isBacktestFetching }] =
        useLazyCompanyWideBacktestQuery();

    useEffect(() => {
        setTotalTabValue(INITIAL_CARD_DATA);
        setTimeframe(ForecastTimeframe.THIRTY);
    }, [orgId]);

    useEffect(() => {
        const fetchForecastData = async () => {
            try {
                const forecastData = await companyForecast({ orgId }).unwrap();
                setTotalTabValue((prevValues) => ({
                    ...prevValues,
                    predictedRevenue: forecastData,
                }));
            } catch (error: any) {
                enqueueSnackbar(`Fetching forecast data error (Code: ${error.status})`, {
                    id: uniqueId(),
                    variant: "error",
                });
            }
        };

        const fetchBacktestData = async () => {
            try {
                const backtestData = await companyBacktest({ orgId }).unwrap();
                const predictionAccuracy = Object.entries(backtestData).reduce(
                    (acc, [key, { data }]) => ({
                        ...acc,
                        [key]: {
                            [CompanyForecastMetricMapping.trendHolidayMarketing]:
                                data.revenueModels.trendHolidayMarketing,
                            [CompanyForecastMetricMapping.actualRevenue]: data.actualRevenue,
                            [CompanyForecastMetricMapping.globalAccuracy]: data.metricDict.globalAccuracy * 100,
                            [CompanyForecastMetricMapping.smape]: data.metricDict.smape,
                            [CompanyForecastMetricMapping.minAndMaxDate]: data.minAndMaxDate,
                        },
                    }),
                    {},
                );

                setTotalTabValue((prevValues) => ({
                    ...prevValues,
                    predictionAccuracy,
                }));
            } catch (error: any) {
                enqueueSnackbar(`Fetching backtest data error (Code: ${error.status})`, {
                    id: uniqueId(),
                    variant: "error",
                });
            }
        };

        fetchForecastData();
        fetchBacktestData();
    }, [orgId]);

    const handleTimeframeChange = (timeframe: ForecastTimeframe) => {
        analyticsService.logEvent(`CWF ${timeframe} Day Clicked`, {});
        setTimeframe(timeframe);
    };

    const renderCard = ({ cards, title, tooltip, dateRange, isLoading }: ICompanyCard) => {
        return (
            <Stack sx={{ flex: "1 0 0" }} gap={3}>
                <Stack direction="row" justifyContent="space-between">
                    <Stack gap={2}>
                        <Stack direction="row" gap={1}>
                            <Typography variant="h3">{title}</Typography>
                            {tooltip && (
                                <Tooltip title={tooltip}>
                                    <InfoOutlinedIcon color="primary" />
                                </Tooltip>
                            )}
                        </Stack>
                        <Typography height="25px" variant="subtitle1">
                            {dateRange}
                        </Typography>
                    </Stack>
                </Stack>
                <Stack alignSelf="stretch" gap={1} direction="row">
                    {cards.map((c) => (
                        <Stack
                            key={c.name}
                            sx={{
                                padding: "0px 24px 0 16px",
                                borderLeft: "5px solid #E9E9E9",
                                minWidth: "167px",
                                maxWidth: "186px",
                            }}
                        >
                            <Stack>
                                {isLoading ? (
                                    <Skeleton variant="text" />
                                ) : (
                                    <Tooltip title={c.tooltipText || ""}>
                                        <Typography variant="h4" color={"#000"}>
                                            {formatValue(c.value, c.sign, c.precision)}
                                        </Typography>
                                    </Tooltip>
                                )}

                                <Stack direction="row" gap={1}>
                                    <Typography variant="subtitle1" color={grey[700]}>
                                        {c.name}
                                    </Typography>

                                    {c.titleAttribute && (
                                        <Tooltip
                                            title={
                                                <Stack gap={1}>
                                                    {c.titleAttribute.map((item, index) => (
                                                        <Typography key={index}>{item}</Typography>
                                                    ))}
                                                </Stack>
                                            }
                                        >
                                            <InfoOutlinedIcon color="primary" />
                                        </Tooltip>
                                    )}
                                </Stack>
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
            </Stack>
        );
    };

    const partialHomeElement = (
        <HomePagePartialCard
            icon={
                <BatchPredictionOutlinedIcon
                    color="primary"
                    sx={{
                        width: "56px",
                        height: "56px",
                        opacity: "100%",
                    }}
                />
            }
            heading="Constructing Forecasts"
            description="Check back later for our prediction of your e-commerce revenue for the next 3 months, and
 how accurate our models are in predicting that revenue."
            actionButton={
                <Button
                    variant="contained"
                    color="inherit"
                    size="small"
                    endIcon={<ChevronRightIcon />}
                    href="https://help.prescient-ai.io/en/articles/9293715-new-feature-tour-homepage"
                    target="_blank"
                >
                    Learn More About Forecasting
                </Button>
            }
            style={{ padding: "40px 345px" }}
        />
    );

    const forecastCards: IForecastCard[] = COMPANY_FORECAST_TAB.map((tab) =>
        COMPANY_FORECAST_TAB_FUNC_DICTIONARY[tab]?.(
            totalTabValue?.predictedRevenue[timeframe]?.[tab],
            totalTabValue?.predictedRevenue[timeframe],
        ),
    ).filter(Boolean);

    const backtestCards: IForecastCard[] = PREDICTION_ACCURACY_CARD.map((tab) =>
        COMPANY_BACKTEST_TAB_FUNC_DICTIONARY[tab]?.(
            modeledMetricLabel,
            totalTabValue?.predictionAccuracy[timeframe]?.[tab],
            totalTabValue?.predictionAccuracy[timeframe]?.smape,
        ),
    ).filter(Boolean);

    const finalCards: ICompanyCard[] = [
        {
            cards: forecastCards,
            title: "Forecasted Revenue",
            dateRange: formatDateRangeForEcommerce(totalTabValue?.predictedRevenue?.[timeframe]?.minAndMaxDate),
            isLoading: isForecastLoading || isForecastFetching,
            tooltip: (
                <>
                    The forecast is comprised of models that specialize in predicting different drivers of revenue such as Media, and external factors such as Trend and Holiday effects. &nbsp;
                    <Link target="_blank" href="https://help.prescient-ai.io/en/articles/9774028-predicted-ecommerce-revenue" color="#FFF">{`Learn More>>`}</Link>
                </>
            ),
        },
        {
            cards: backtestCards,
            title: "Backtesting Accuracy",
            dateRange: formatDateRangeForEcommerce(totalTabValue?.predictionAccuracy?.[timeframe]?.minAndMaxDate),
            isLoading: isBacktestLoading || isBacktestFetching,
            tooltip: (
                <>
                    Accuracy is measured using an industry standard technique called backtesting, In short, Prescient compares its modeled results to known values in the past (i.e., actuals) in order to calculate an accuracy score. &nbsp;
                    <Link target="_blank" href="https://help.prescient-ai.io/en/articles/8888433-backtested-accuracy-scores" color="#FFF">{`Learn More>>`}</Link>
                </>
            ),
        },
    ];

    return (
        <HomepageCardContainer>
            <Stack gap={partialStateForHome ? 0 : 3}>
                <DashboardCardHeader
                    title={`${modeledMetricLabel} Ecommerce Forecasting`}
                    tooltip={
                        <>
                            {`Prescient AI’s global view of predicted revenue for your ${
                            modeledMetricLabel === "Store" ? "store" : `${modeledMetricLabel} store`
                            } and model accuracy.`}
                        </>
                    }
                    isBetaCard={true}
                    tooltipRef={tooltipRef}
                    instances={[
                        <Select
                            labelId="demo-select-small-label"
                            id="demo-select-small"
                            value={timeframe}
                            onChange={(e) => handleTimeframeChange(e.target.value as ForecastTimeframe)}
                            variant="outlined"
                        >
                            {forecastTabs.map((grp) => {
                                return (
                                    <MenuItem value={grp.value} key={grp.value}>
                                        {grp.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>,
                    ]}
                />

                {partialStateForHome ? (
                    partialHomeElement
                ) : (
                    <Stack
                        direction="row"
                        gap={{ sm: 2, md: 2, lg: 5 }}
                        alignSelf="stretch"
                        sx={(theme) => ({
                            flexWrap: "noWrap",
                            [theme.breakpoints.down(1400)]: {
                                flexWrap: "wrap",
                            },
                        })}
                    >
                        {finalCards.map((card, index) => (
                            <Fragment key={card.title}>
                                {renderCard(card)}
                                {index === 0 && (
                                    <Stack
                                        sx={(theme) => ({
                                            [theme.breakpoints.down(1400)]: {
                                                visibility: "hidden",
                                            },
                                        })}
                                    >
                                        <Divider orientation="vertical" />
                                    </Stack>
                                )}
                            </Fragment>
                        ))}
                    </Stack>
                )}
            </Stack>
        </HomepageCardContainer>
    );
};
