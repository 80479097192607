import { IAnnouncement } from "../../interfaces/entities/IAnnouncement";

export const generateAnnouncementsBody = (body: IAnnouncement) => {
    const resultBody: { data: any } = {
        data: {
            attributes: {
                release_date: body.releaseDate,
                title: body.title,
                description: body.description,
                link: body.link,
            },
            type: "announcements",
        },
    };

    return resultBody;
};
