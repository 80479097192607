import React from "react";
import { Card, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { Stack } from "@mui/system";

import { TEXT_ERROR_COLOR, WHITE_COLOR } from "../../consts/colors";
import { SVGIconRenderer } from "../../components/SVGIconRenderer/SVGIconRenderer";

const useStyle = () => ({
    errorContainer: css({
        background: WHITE_COLOR,
        padding: "2rem",
        textAlign: "center",
        height: "100vh",
        width: "100%",
        "& p": {
            textAlign: "left",
            display: "inline-flex",
            fontSize: "1rem",
            "& svg": {
                color: TEXT_ERROR_COLOR,
                marginRight: "2px",
            },
        },
    }),
    cardStyle: css({
        width: "520px",
        padding: "1rem 2rem 2rem 2rem",
        height: "fit-content",
        marginTop: "8rem",
    }),
});

interface IApiError {
    errMsg: string;
}

export const ApiError: React.FC<IApiError> = ({ errMsg }) => {
    const classes = useStyle();
    return (
        <Stack className={classes.errorContainer} alignItems="center">
            <Card variant="outlined" className={classes.cardStyle}>
                <Stack gap="1rem">
                    <Typography variant="h6" alignSelf="center">
                        Error
                    </Typography>
                    <Stack direction="row" gap="1rem" alignItems="center">
                        <SVGIconRenderer
                            strokeColor={TEXT_ERROR_COLOR}
                            icon="alertCircleIcon"
                            width="20px"
                            height="20px"
                        />
                        <Typography>{errMsg}</Typography>
                    </Stack>
                </Stack>
            </Card>
        </Stack>
    );
};
