import { FORMATS } from "src/enums/Formats";
import { IGoal } from "../../interfaces/entities/IGoal";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";

export const LAST_DAYS_COUNT = 30;

export enum GoalType {
    REVENUE = "revenue",
    ROAS = "roas",
    NEW_CUSTOMERS = "new_customers",
    CAC = "cac",
    BUDGET = "budget",
}

export const INITAL_GOAL: IGoal = {
    goalType: GoalType.REVENUE,
    goalValue: "",
    startDate: "",
    endDate: "",
    context: "",
    campaignsOrChannels: [],
    visibility: "",
    creatorId: "",
    id: "",
    campaigns: [],
    channels: [],
    reportingData: 0,
    goalPercentage: 0,
};

export enum ContextType {
    PORTFOLIO = "portfolio",
    CHANNEL = "channel",
    CAMPAIGN = "campaign",
}

export const GOAL_TYPE_OPTION = [
    { label: "Revenue", value: GoalType.REVENUE },
    { label: "ROAS", value: GoalType.ROAS },
    { label: "Budget", value: GoalType.BUDGET },
    // { label: "New Customers", value: GoalType.NEW_CUSTOMERS },
    // { label: "CAC", value: GoalType.CAC },
];

export const CONTEXT_OPTION = [
    { label: "Portfolio", value: ContextType.PORTFOLIO },
    { label: "Channel", value: ContextType.CHANNEL },
    { label: "Campaign", value: ContextType.CAMPAIGN },
];

export interface IChannelCampaignOption extends IMetricAttributionTableValuesTransformed {
    value: string;
    label: string;
}

export interface IForecastCard {
    name: string;
    value: string;
    sign: FORMATS;
    titleAttribute: string[];
    precision: number;
    tooltipText: string;
}
