import baseCreateApi from "./baseCreateApi";

interface IUnsubscribe {
    user_id?: string;
    notification_id: string;
}

export const unsubscribeApi = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        unsubscribe: builder.query({
            query: (args: IUnsubscribe) => ({
                url: `users/subscription_unsubscribe?user_id=${args.user_id}&notification_id=${args.notification_id}`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
        }),
        subscribe: builder.query({
            query: (args: IUnsubscribe) => ({
                url: `users/subscription_subscribe?user_id=${args.user_id}&notification_id=${args.notification_id}`,
                method: "GET",
            }),
            keepUnusedDataFor: 0,
        }),
    }),
});

export const { useLazyUnsubscribeQuery, useLazySubscribeQuery } = unsubscribeApi;
