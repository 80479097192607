import { FC } from "react";
import { SnackbarKey, SnackbarProvider } from "notistack";
import CustomNotistack, { ICusomVariant } from "../components/core/CustomNotistack/CustomNotistack";

interface ICommonVariantType {
    Type: {
        customMsg?: any;
        variantType?: ICusomVariant["variantType"];
        actionHandle?: (params: SnackbarKey) => void;
        customCloseToast?: (params: SnackbarKey) => void;
        id: SnackbarKey;
    };
}

declare module "notistack" {
    /* tslint:disable */
    interface VariantOverrides {
        error: ICommonVariantType["Type"];
        success: ICommonVariantType["Type"];
        info: ICommonVariantType["Type"];
    }
}

export const NotistackProvider: FC<{ children: React.ReactNode }> = ({ children }) => {
    return (
        <SnackbarProvider
            Components={{
                error: CustomNotistack,
                success: CustomNotistack,
                info: CustomNotistack,
            }}
            dense
            anchorOrigin={{ horizontal: "right", vertical: "top" }}
            maxSnack={10}
            autoHideDuration={2000}
            hideIconVariant
            preventDuplicate
        >
            {children}
        </SnackbarProvider>
    );
};
