import { FC } from "react";
import styled from "styled-components";
import { Chip, Tooltip, TooltipProps, Typography, tooltipClasses } from "@mui/material";

const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
))({
    [`& .${tooltipClasses.tooltip}`]: {
        maxWidth: 400,
    },
});

interface IBetaTooltip {
    placement?:
        | "bottom-end"
        | "bottom-start"
        | "bottom"
        | "left-end"
        | "left-start"
        | "left"
        | "right-end"
        | "right-start"
        | "right"
        | "top-end"
        | "top-start"
        | "top";
    isTooltip?: boolean;
}

export const BetaTooltip: FC<IBetaTooltip> = ({ placement, isTooltip = true }) => {
    if (!isTooltip) {
        return <Chip size="small" label="Beta" sx={{ color: "#00000099" }} />;
    }
    return (
        <CustomWidthTooltip
            arrow
            placement={placement || "bottom"}
            title={
                <Typography>
                    Beta connectors are currently being researched and tested by Prescient. In most cases, these
                    connectors will have no impact on modeled results.
                    <br /> <br />
                    You can help us expand our modeled data sources and potentially improve the accuracy of your
                    attribution model by connecting them and granting us access to your data.
                    <br /> <br />
                    If you run into any issues, see data that seems incorrect, or want to report an issue with a beta
                    connector please let us know via the FEEDBACK button in the bottom right hand corner of your screen.
                    <br /> <br />
                    For more details on specific connectors, reach out to your account representative
                </Typography>
            }
        >
            <Chip size="small" label="Beta" sx={{ color: "#00000099" }} />
        </CustomWidthTooltip>
    );
};
