import { IMembersResponse } from "../../interfaces/IMembersResponse";
import { generateMemberBody, generateSuperAdminMemberBody, transformMembers } from "../../services/members/members";
import { setMembers } from "../slices/membersSlice";
import baseCreateApi from "./baseCreateApi";

export interface IMemberBody {
    firstName: string;
    lastName: string;
    email: string;
    role?: string;
    avatar?: string;
    avatarName?: string;
    isSuperadmin?: boolean;
    roles?: any[];
}

export interface ISuperAdminUserBody {
    firstName: string;
    lastName: string;
    email: string;
    calendarUrl?: string;
    role?: string;
    avatar?: string;
    avatarName?: string;
    isSuperadmin?: boolean;
    roles?: any[];
}

const setMembersAsyncHandler = async ({ dispatch, queryFulfilled, getState }: any) => {
    const isDemoMode = getState().isDemoMode;
    try {
        const res = await queryFulfilled;
        if (res) {
            if (isDemoMode) {
                const demoMemberData = res.data.map((mem: any, index: number) => ({
                    ...mem,
                    firstName: `User #${index + 1}`,
                    lastName: `User #${index + 1}`,
                    email: `email@email.com`,
                    avatarMediumUrl: "missing",
                }));
                dispatch(setMembers(demoMemberData));
            } else {
                dispatch(setMembers(res.data));
            }
        }
    } catch (error) {
        console.error(error);
    }
};

export const members = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        getMembers: builder.query({
            query: (args: { orgId: string; include?: string }) => ({
                url: `organizations/${args.orgId}/users${args.include ? `?include=${args.include}` : ""}`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }) {
                setMembersAsyncHandler({ dispatch, queryFulfilled, getState });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IMembersResponse[]; included: [] }) => {
                return transformMembers(response.data, response.included);
            },
        }),
        getAllMembers: builder.query({
            query: (include?: string) => ({
                url: `users${include ? `?include=${include}` : ""}`,
                method: "GET",
            }),
            onQueryStarted(queryArgs, { dispatch, queryFulfilled, getState }) {
                setMembersAsyncHandler({ dispatch, queryFulfilled, getState });
            },
            keepUnusedDataFor: 0,
            transformResponse: (response: { data: IMembersResponse[]; included: [] }) => {
                return transformMembers(response.data, response.included);
            },
        }),
        createMemberWithOrg: builder.mutation({
            query: (args: { body: IMemberBody; orgId: string }) => ({
                url: `organizations/${args.orgId}/users`,
                method: "POST",
                body: JSON.stringify(generateMemberBody(args.body)),
            }),
        }),
        editMember: builder.mutation({
            query: (queryArgs: { params: { id: number; orgId: string }; body: IMemberBody }) => ({
                url: `organizations/${queryArgs.params.orgId}/users/${queryArgs.params.id}`,
                method: "PATCH",
                body: JSON.stringify(generateMemberBody(queryArgs.body)),
            }),
        }),
        deleteMember: builder.mutation({
            query: (id: number) => ({
                url: `users/${id}`,
                method: "DELETE",
            }),
        }),
        deleteMemberRole: builder.mutation({
            query: (queryArgs: { id: string; orgId: string }) => ({
                url: `organizations/${queryArgs.orgId}/roles/${queryArgs.id}`,
                method: "DELETE",
            }),
        }),
        superadminCreateUser: builder.mutation({
            query: (queryArgs: { body: ISuperAdminUserBody }) => {
                return {
                    url: `users`,
                    method: "POST",
                    body: JSON.stringify(generateSuperAdminMemberBody(queryArgs.body)),
                };
            },
        }),
        editAdminMember: builder.mutation({
            query: (queryArgs: { params: { id: number }; body: IMemberBody }) => {
                return {
                    url: `users/${queryArgs.params.id}`,
                    method: "PATCH",
                    body: JSON.stringify(generateSuperAdminMemberBody(queryArgs.body)),
                };
            },
        }),
        reinviteMember: builder.mutation({
            query: (args: { orgId: string; userId: string }) => ({
                url: `organizations/${args.orgId}/users/${args.userId}/reinvite`,
                method: "POST",
            }),
        }),
        reinviteMemberWithoutOrg: builder.mutation({
            query: (args: { userId: string }) => ({
                url: `users/${args.userId}/reinvite`,
                method: "POST",
            }),
        }),
    }),
});

export const {
    useLazyGetAllMembersQuery,
    useLazyGetMembersQuery,
    useCreateMemberWithOrgMutation,
    useEditMemberMutation,
    useDeleteMemberMutation,
    useDeleteMemberRoleMutation,
    useSuperadminCreateUserMutation,
    useEditAdminMemberMutation,
    useReinviteMemberMutation,
    useReinviteMemberWithoutOrgMutation,
} = members;
