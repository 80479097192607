import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";
import { Box } from "@mui/system";
import { format } from "date-fns";

import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { selectCurrentlyViewingCode, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { ActivePerformanceTabs } from "../../enums/ActivePerfomanceTabs";
import { PerformanceTabHolderComponent } from "../../components/CampaignComponents/InsightsModal/PerformanceTab/PerformanceTabHolderComponent";
import { useLazyGetPerformancePageQuery } from "../../reduxState/apis/performancePageApi";
import { formattedDateSelector } from "../../reduxState/slices/dateFilterSlice";
import { getPaidDataSources } from "../../consts/performancePaidPage/performancePaidPage";
import Loader from "../../components/core/Loader/Loader";
import { Message } from "../../components/Message/Message";
import { PerformanceContext } from "../CampaignPageV2/CampaignPage";
import { defaultRevenueSourceSelector, isAmazonConnectedSelector } from "src/reduxState/slices/connectorsSlice";
import { supportedDataSourcesSelector } from "src/reduxState/slices/supportedDataSourcesSlice";

export const useStylesForDetailPage = () => ({
    performancePage: css({
        position: "absolute",
        left: 0,
        top: 0,
        width: "100%",
        minHeight: "100vh",
        backgroundColor: "#F8F9FA",
    }),
    mainContainer: css({
        width: "100%",
        display: "flex",
        flexDirection: "column",
        padding: "32px",
        backgroundColor: "white",
        boxShadow: "0px -1px 1px #e0e0e0",
    }),
});

export const CampaignDetailsPage = ({ ...rest }) => {
    const classes = useStylesForDetailPage();
    const navigate = useNavigate();
    const params = useParams();
    const campaignId = params.campaignId;

    const currentTab = window.location.pathname.split("/").pop();

    const [tabValue, setTabValue] = useState<string | ActivePerformanceTabs>(currentTab || "performance");
    const isAmazonConnected = useSelector(isAmazonConnectedSelector);
    const defaultRevenueConnector = useSelector(defaultRevenueSourceSelector);

    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const selectFormattedDates = useSelector(formattedDateSelector);
    const companyId = useSelector(selectCurrentlyViewingId);

    const [
        getPerformancePage,
        {
            data: allCurrentData = {
                data: [],
                meta: { campaignsDate: "", revenueData: [] },
            },
            isLoading,
        },
    ] = useLazyGetPerformancePageQuery();

    useEffect(() => {
        setTabValue(currentTab || "performance");
        if (supportedDataSources.length && selectFormattedDates[0].startDate && selectFormattedDates[0].endDate && campaignId) {
            getPerformancePage({
                orgId: companyId,
                data: {
                    start_date: format(selectFormattedDates[0].startDate, "yyyy-MM-dd"),
                    end_date: format(selectFormattedDates[0].endDate, "yyyy-MM-dd"),
                    connectors: getPaidDataSources(supportedDataSources),
                    campaign_id: [campaignId],
                },
            });
        }
    }, [location.pathname, supportedDataSources]);

    const handleTabChange = (event: React.SyntheticEvent, newValue: ActivePerformanceTabs) => {
        setTabValue(newValue);
        navigate(`/org/${orgCode}/campaigns/${campaignId}/${newValue}${window.location.search}`);
    };
    const performanceCampaignData = allCurrentData && allCurrentData.data && allCurrentData.data[0];

    return (
        <div className={classes.performancePage}>
            {performanceCampaignData && (
                <PageHeader title={performanceCampaignData.campaignName || ""} pageHeading="Campaign Detail Pages" />
            )}

            <div className={classes.mainContainer}>
                {isLoading ? (
                    <Loader />
                ) : performanceCampaignData ? (
                    <PerformanceContext.Provider
                        value={{
                            defaultRevenueConnectorSource: defaultRevenueConnector,
                            isAmazonCampaignAvailable: isAmazonConnected,
                        }}
                    >
                        <PerformanceTabHolderComponent
                            handleTabChange={handleTabChange}
                            tabValue={tabValue}
                            performanceCampaignData={performanceCampaignData}
                        />
                    </PerformanceContext.Provider>
                ) : (
                    <Box height="100vh">
                        <Message
                            title={`We don't have any campaign data with id ${campaignId}`}
                            subtitle={`Please make sure you entered right campaign id`}
                        />
                    </Box>
                )}
            </div>
        </div>
    );
};
