export enum SubscriptionStatusKeys {
    ACTIVE = "ACTIVE",
    INACTIVE = "INACTIVE",
    INCOMPLETE = "INCOMPLETE",
    INCOMPLETE_EXPIRED = "INCOMPLETE_EXPIRED",
    CANCELED = "CANCELED",
    UNPAID = "UNPAID",
    PAST_DUE = "PAST_DUE",
    TRIALING = "TRIALING",
}
