import { useEffect } from "react";
import Cookies from "js-cookie";
import "vanilla-cookieconsent";
import pluginConfig from "./CookieConsentConfig";
import { isConsentRequired } from "../../services/utils";

const CookieConsentComponent = () => {
    useEffect(() => {
        if (!document.getElementById("cc--main")) {
            window.CC = window.initCookieConsent();
            window.CC.run(pluginConfig);
            const cookieConsent = JSON.parse(Cookies.get("cc_cookie") || "{}");
            // accept all cookie when consent is not required and it is empty
            // empty check should make it so we honor the consent set by user in settings.
            if (!isConsentRequired() && Object.keys(cookieConsent).length === 0) {
                try {
                    window.CC.accept("all");
                } catch (error) {
                    console.log("error at accepting all cookies");
                }
            }
        }
    }, []);

    return null;
};

export default CookieConsentComponent;
