import React, { useContext, useEffect, useState } from "react";
import { Table, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import { useTheme } from "@mui/system";
import { css } from "@emotion/css";

import { FORECASTED_CAMPAIGNS_HEADERS } from "../../../../consts/optimizationPage/optimizationPage";
import {
    IScenario,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    ICard,
} from "../../../../interfaces/entities/IScenario";
import { getTotalAllocation } from "../../../../services/optimizationPage/optimization";
import { TableRowsLoader } from "../../../TableLoader/TableLoader";
import { OutcomeTableHeader } from "./OutcomeTableHeader";
import { OutcomeTableRow } from "./OutcomeTableRow";
import { optimizationStyle } from "../../../../containers/Optimization/optimizationStyle";
import { IDictionary } from "src/interfaces/IDictionary";
import { OptimizationOutcomeContext } from "src/containers/OptimizationOutcomePage/OptimizationOutcomePage";
import { getComparator, stableSort } from "src/utils/sort";

interface IOutcomeCampaignTable {
    cards: ICard[];
    campaignData: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    id: string;
    scenario: IScenario;
    allForecastedTotalAllocation: IDictionary;
    isTableLoading: boolean;
    compareToLabel: string;
}

const channelTabStyle = () => ({
    recommandarionTabel: css({
        border: "1px solid #D9D9D9",
        backgroundColor: "white",
        borderRadius: "5px",
        "& td, & th": {
            color: "#000",
        },
    }),
    tableBodyStyle: css({
        ".campaignNameCell": {
            width: "450px !important",
            minWidth: "450px !important",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
        },
        ".confidenceCell, .forecastedRevenueCell, .spendCell": {
            fontSize: "15px",
            border: "solid rgba(224, 224, 224, 1)",
            borderWidth: "0 0 1px 0",
            width: "150px !important",
            minWidth: "150px !important",
        },
        ".forecastedRoasCell": {
            width: "130px !important",
            minWidth: "130px !important",
        },
    }),
});

export const OutcomeCampaignTable: React.FC<IOutcomeCampaignTable> = ({
    cards,
    campaignData,
    id,
    scenario,
    allForecastedTotalAllocation,
    isTableLoading,
    compareToLabel,
}) => {
    const classes = { ...channelTabStyle(), ...optimizationStyle(useTheme()) };
    const optimizationOutcomeContext = useContext(OptimizationOutcomeContext);

    const totalAllocationCampaigns = getTotalAllocation(campaignData);
    const isForecasted = scenario.scenarioType === "forecast";

    const isIncreaseTable = id === "increase";
    const [campaignOpenId, setCampaignOpenId] = useState<string>("");
    let filteredHeader = (cards || []).filter((h: any) => h.hidden === false);

    if (isForecasted && filteredHeader) {
        filteredHeader = filteredHeader.filter((h: any) => h.id !== "spend");
    }

    useEffect(() => {
        setCampaignOpenId("");
    }, [compareToLabel, optimizationOutcomeContext?.metricRate]);

    const campaignRowClick = (campaignId: string) => {
        setCampaignOpenId((id) => (id === campaignId ? "" : campaignId));
    };

    if (isForecasted) {
        // filteredHeader = filteredHeader.filter((h) => h.id !== "spend");
    }

    return (
        <TableContainer className={classes.recommandarionTabel}>
            <Table>
                <OutcomeTableHeader scenario={scenario} compareToLabel={compareToLabel} cards={cards} />
                {isTableLoading ? (
                    <TableRowsLoader rowsNum={campaignData.length + 1} colsNum={FORECASTED_CAMPAIGNS_HEADERS.length} />
                ) : (
                    <TableBody className={classes.tableBodyStyle}>
                        {campaignData.length ? (
                            <>
                                {stableSort(campaignData, getComparator("asc", "campaignName")).map(
                                    (campaignRow: any) => {
                                        return (
                                            <OutcomeTableRow
                                                cards={cards}
                                                key={campaignRow.campaignId}
                                                rowClick={() => campaignRowClick(campaignRow.campaignId || "")}
                                                campaignRow={
                                                    campaignRow as IMetricAttributionTableValuesTransformedWithSpendAllocation
                                                }
                                                classes={classes}
                                                campaignOpenId={campaignOpenId}
                                                scenario={scenario}
                                                campaignData={campaignData}
                                                compareToLabel={compareToLabel}
                                            />
                                        );
                                    },
                                )}
                                <TableRow className={classes.totalRow}>
                                    {filteredHeader.map((element: any, index: number) => {
                                        return (
                                            <TableCell key={element.id} align={element.align}>
                                                {allForecastedTotalAllocation[element.id]}
                                            </TableCell>
                                        );
                                    })}
                                </TableRow>
                            </>
                        ) : (
                            <TableRow>
                                <TableCell
                                    className={classes.outcomeTableCell}
                                    colSpan={FORECASTED_CAMPAIGNS_HEADERS.length}
                                    align="center"
                                    sx={{ color: "rgba(0, 0, 0, 0.6)!important" }}
                                >
                                    No campaigns
                                </TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                )}
            </Table>
        </TableContainer>
    );
};
