import { FC, Fragment } from "react";
import { Chip, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import step1Image from "src/assets/partnerize-step1.png";
import step2Image from "src/assets/partnerize-step2.png";
import step3Image from "src/assets/partnerize-step3.png";

interface IPartnerizeAffiliateModalContent {
    classes: any;
}

export const PartnerizeAffiliateModalContent: FC<IPartnerizeAffiliateModalContent> = ({ classes }) => {
    return (
        <Fragment>
            <Typography className={classes.heading}>Setup instructions</Typography>

            {/* Step 1 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 1" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Retrieve your API key
                </Typography>
            </Stack>
            <Typography className={classes.info}>Log in to your Partnerize account</Typography>

            <Typography className={classes.info}>Navigate to your Settings</Typography>
            <img src={step1Image} />

            <Typography className={classes.info} mt={1}>
                Click “Your account”
            </Typography>
            <img src={step2Image} />

            <Typography className={classes.info} mt={1}>
                Your API Key and Application Key are available at the bottom of the account details section
            </Typography>
            <img src={step3Image} />

            {/* Step 2 */}
            <Stack direction="row" alignItems="center" gap="10px">
                <Chip color="primary" label="STEP 2" />
                <Typography component="div" fontSize="1.1rem" my="0.5rem" color="black">
                    Finish configuration
                </Typography>
            </Stack>
            <Typography className={classes.info}>
                Return to Prescient and enter your API Key and Application Key
            </Typography>
            <Typography className={classes.info}>Click Save & Test</Typography>
        </Fragment>
    );
};
