import { Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { FC, Fragment, useEffect } from "react";
import { ConnectorStepper } from "../ConnectorStepper";
import { ConnectorTable } from "../ConnectorsTable/ConnectorTable";
import { IConnector } from "src/interfaces/IConnector";

interface IConnectorTableWithStepperWrapper {
    title: string;
    subTitle: string;
    activeStep: number;
    connectors: IConnector[];
    editConnector: (connector: IConnector) => void;

    openRespectiveConfirmModal: (connector: IConnector, action: string) => void;
    handleNext: () => void;
    handleBack: () => void;
    handleStep: (step: number) => void;
}

export const ConnectorTableWithStepperWrapper: FC<IConnectorTableWithStepperWrapper> = ({
    title,
    subTitle,
    activeStep,
    handleNext,
    handleBack,
    connectors,
    editConnector,
    openRespectiveConfirmModal,
    handleStep,
}) => {
    useEffect(() => {
        if (connectors.length === 0) {
            handleStep(0);
        }
    }, [connectors.length]);

    return (
        <Fragment>
            <Stack textAlign="center" spacing={1}>
                <Typography variant="h2">{title}</Typography>
                <Typography variant="body1">{subTitle}</Typography>
            </Stack>
            <Stack gap={4}>
                <ConnectorStepper
                    activeStep={activeStep}
                    handleNext={handleNext}
                    handleBack={handleBack}
                    handleStep={handleStep}
                />
                <Stack gap={1}>
                    <ConnectorTable
                        connectors={connectors}
                        openRespectiveConfirmModal={openRespectiveConfirmModal}
                        editClick={editConnector}
                    />
                </Stack>
            </Stack>
        </Fragment>
    );
};
