import { IHeadCell } from "../../consts/performancePaidPage/performancePaidPage";
import { ISettings, IPerformanceCard } from "../slices/settingsSlice";
import baseCreateApi from "./baseCreateApi";

const formatSettings = (settings?: ISettings, performanceColumnOrders?: string[], paginationCount?: number) => {
    let finalSetting = {};

    if (performanceColumnOrders) {
        finalSetting = { ...finalSetting, performance_column_orders: performanceColumnOrders };
    }
    if (settings) {
        const overviewData = settings.performanceOverviewCards.map((card: IPerformanceCard) => ({
            connector_type: card.connectorName,
            kpi: card.metric,
        }));
        finalSetting = {
            ...finalSetting,
            performance_overview_cards: overviewData,
            performance_detail_cards: settings.performanceDetailCards,
        };
    }

    if (paginationCount) {
        finalSetting = {
            ...finalSetting,
            counts: [{ pagination_count: paginationCount }],
        };
    }

    return finalSetting;
};

export const kpiSettings = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        editUserSettings: builder.mutation({
            query: (data: {
                orgId: string;
                userId: string;
                performanceColumnOrders?: string[];
                KPISettings?: ISettings;
                paginationCount?: number;
            }) => ({
                url: `organizations/${data.orgId}/user_settings/update`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            user_id: data.userId,
                            settings: formatSettings(
                                data.KPISettings,
                                data.performanceColumnOrders,
                                data.paginationCount,
                            ),
                        },
                        type: "user_settings",
                    },
                }),
            }),
        }),
    }),
});

export const { useEditUserSettingsMutation } = kpiSettings;
