import { CircularProgress, Skeleton, Typography } from "@mui/material";
import { Stack } from "@mui/system";

export const GoalProcess = () => {
    return (
        <Stack gap={1}>
            <Stack alignItems="center" direction="row" gap={1}>
                <CircularProgress disableShrink color="primary" size={20} />
                <Typography>We are processing this goal. Please check back later.</Typography>
            </Stack>
            <Skeleton height="30px" />
        </Stack>
    );
};
