import React, { FC } from "react";
import { MenuItem, MenuList, Typography } from "@mui/material";
import { css } from "@emotion/css";
import CloseIcon from "@mui/icons-material/Close";
import { Stack } from "@mui/system";

import { LightMenu } from "../LightMenu/LightMenu";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import camelCase from "lodash/camelCase";

interface IDynamicSavedFilterList {
    isSavedViewOn: boolean;
    curentFilterView: {
        isSavedViewOn: boolean;
        data: {
            name: string;
            filter: string;
            id: string;
            isDefault?: boolean;
        };
    };
    childRef: React.RefObject<any>;
    id?: string;
    dataCy: string;
    defaultFilterList: any[];
    savedFilterList: any[];
    actionList: any[];
    handleMenuClick: (filter: { name: string; filters: string; id: string }) => void;
    exitViewHandler: (event: any) => void;
}

const useStyles = () => ({
    headerBtn: css({
        "& button": {
            backgroundColor: "inherit",
            border: "2px solid",
            borderColor: "rgba(66, 150, 133, 0.50)",
            width: "229px",
            height: "40px",
        },
    }),
    menuClass: css({
        minWidth: "229px",
    }),
});

export const DynamicSavedFilterList: FC<IDynamicSavedFilterList> = ({
    isSavedViewOn,
    curentFilterView,
    childRef,
    defaultFilterList,
    savedFilterList,
    actionList,
    handleMenuClick,
    exitViewHandler,
    id,
    dataCy,
}) => {
    const classes = useStyles();

    return (
        <LightMenu
            isList={false}
            title={
                isSavedViewOn && curentFilterView?.data ? (
                    <Stack direction="row" justifyContent="space-between" alignItems="center" width="100%">
                        {curentFilterView.data.name}
                        {curentFilterView.data.name !== "Default View" && (
                            <CloseIcon
                                sx={{ fontSize: "18px", color: "#000000" }}
                                onClick={(event) => exitViewHandler(event)}
                            />
                        )}
                    </Stack>
                ) : (
                    "Default View"
                )
            }
            id={id}
            closeCustomEndIcon={<SVGIconRenderer icon="chevronUpIcon" height="16px" width="16px" />}
            openCustomEndIcon={<SVGIconRenderer icon="chevronDownIcon" height="16px" width="16px" />}
            ref={childRef}
            extraButtonClass={classes.headerBtn}
            dataCy={dataCy}
            wideset={true}
            menuClass={classes.menuClass}
        >
            <MenuList style={{ padding: "8px 0", minWidth: "140px" }}>
                {defaultFilterList.map((filter) => {
                    return (
                        <MenuItem
                            key={filter.id}
                            id={filter.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px" }}
                            onClick={() => handleMenuClick(filter)}
                            selected={curentFilterView?.data?.name === filter.name}
                            data-cy={camelCase(filter.name).replace(/ /g, "")}
                        >
                            <Typography component="span" variant="body1">
                                {filter.name}
                            </Typography>
                            {isSavedViewOn &&
                                curentFilterView?.data?.name !== "Default View" &&
                                curentFilterView?.data?.id === filter.id && (
                                    <CloseIcon
                                        sx={{ fontSize: "18px", color: "#000000", marginLeft: "auto" }}
                                        onClick={(event) => exitViewHandler(event)}
                                    />
                                )}
                        </MenuItem>
                    );
                })}
                <MenuItem sx={{ padding: "0px", margin: "8px 0" }} divider />
                {savedFilterList.map((filter) => {
                    return (
                        <MenuItem
                            key={filter.id}
                            id={filter.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px" }}
                            onClick={() => handleMenuClick(filter)}
                            selected={curentFilterView?.data?.name === filter.name}
                            data-cy={camelCase(filter.name).replace(/ /g, "")}
                        >
                            <Typography component="span" variant="body1">
                                {filter.name}
                            </Typography>
                            {isSavedViewOn && curentFilterView?.data?.id === filter.id && (
                                <CloseIcon
                                    onClick={(event) => exitViewHandler(event)}
                                    style={{ marginLeft: "auto", fontSize: "18px", color: "#000000" }}
                                />
                            )}
                        </MenuItem>
                    );
                })}
                {savedFilterList.length > 0 && <MenuItem sx={{ padding: "0px", margin: "8px 0" }} divider />}
                {actionList.map((action) => {
                    return (
                        <MenuItem
                            key={action.name.replace(/ /g, "_")}
                            id={action.name.replace(/ /g, "_")}
                            sx={{ padding: "6px 16px", gap: "12px" }}
                            onClick={() => action.clickHandler()}
                            data-cy={camelCase(action.name).replace(/ /g, "")}
                            disabled={action.disabled}
                        >
                            {action.icon}
                            <Typography component="span" variant="body1">
                                {action.name}
                            </Typography>
                        </MenuItem>
                    );
                })}
            </MenuList>
        </LightMenu>
    );
};
