import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { IPerformancePageResponse } from "../../interfaces/IPerformancePage";

const initialState: IPerformancePageResponse[] = [];

const performancePageSlice = createSlice({
    name: "performancePage",
    initialState,
    reducers: {
        setPerformancePage: (state, { payload }) => payload,
    },
});

export const { setPerformancePage } = performancePageSlice.actions;

export default performancePageSlice.reducer;

// Selectors
export const performancePageSelector = (state: RootState) => state.performancePage;
