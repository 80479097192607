import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useSelector } from "react-redux";

import { useStylesForHeaderInTable } from "src/consts/StylesForTables";
import { demoModeSelector } from "src/reduxState/slices/demoModeSlice";
import { Choice, DATA_SERVICES_PAGE_TABLE_HEADERS, ICells } from "src/consts/dataServicesPage/dataServicesPage";

interface IDataServicesTableHeader {
    handleRequestSort: (event: React.MouseEvent<unknown>, property: keyof ICells) => void;
    choice: Choice;
    choiceBy: string;
}

export const DataServicesTableHeader = (props: IDataServicesTableHeader) => {
    const { choiceBy, choice, handleRequestSort } = props;
    const headerClasses = useStylesForHeaderInTable();
    const isDemoMode = useSelector(demoModeSelector);

    const createSortHandler = (property: keyof ICells) => (event: React.MouseEvent<unknown>) => {
        handleRequestSort(event, property);
    };

    return (
        <TableHead className={headerClasses.tableHeader}>
            <TableRow className={headerClasses.headerRow}>
                {DATA_SERVICES_PAGE_TABLE_HEADERS.map((column) => (
                    <TableCell
                        className={headerClasses.tableCell}
                        key={column.id}
                        sortDirection={choiceBy === column.id ? choice : false}
                        align={column.align || "left"}
                    >
                        <TableSortLabel
                            active={choiceBy === column.id}
                            direction={choiceBy === column.id ? choice : "asc"}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                            {choiceBy === column.id ? (
                                <Box component="span" style={{ display: "none" }}>
                                    {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
                {!isDemoMode && (
                    <TableCell
                        align={"right"}
                        className={headerClasses.tableCell}
                        key={"activation"}
                        style={{ width: "20%" }}
                    ></TableCell>
                )}
            </TableRow>
        </TableHead>
    );
};
