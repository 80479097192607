import { FC, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { OptimizationTable } from "../OptimizationTable/OptimizationTable";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { paginationCountSelector } from "src/reduxState/slices/settingsSlice";
import { useEditScenarioMutation, useLazyGetScenarioQuery } from "src/reduxState/apis/optimizationApi";
import Loader from "src/components/core/Loader/Loader";
import { OptimizationTab } from "src/consts/optimizationPage/optimizationPage";
import { IScenario } from "src/interfaces/entities/IScenario";
import { DeleteScenarioModal } from "../DeleteScenarioModal/DeleteScenarioModal";

interface IArchivedTab {
    handleTracking: (scenario: IScenario) => void;
    openEditModal: (optimization: IScenario) => void;
}

export const ArchivedTab: FC<IArchivedTab> = ({ handleTracking, openEditModal }) => {
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const rowsPerPage = useSelector(paginationCountSelector);

    const [page, setPage] = useState<number>(1);
    const [totalCount, setTotalCount] = useState<number>(1);
    const [scenariosState, setScenarios] = useState<IScenario[]>([]);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [scenarioForDelete, setScenarioForDelete] = useState<IScenario | null>(null);

    const [getScenario, { isLoading: isScenarioLoading, isFetching }] = useLazyGetScenarioQuery();
    const [editScenario, { isLoading: isEditLoading }] = useEditScenarioMutation();

    useEffect(() => {
        getScenario({
            orgId: currentOrgId,
            page,
            pageSize: rowsPerPage,
            filter: "filter[is_archived]=true",
        })
            .unwrap()
            .then((res) => {
                if (res?.meta) {
                    setScenarios(res.data);
                    setPage(res.meta.currentPage);
                    setTotalCount(res.meta.totalCount);
                }
            });
    }, [currentOrgId, page, rowsPerPage]);

    const onArchiveOptimization = (scenario: IScenario) => {
        if (scenario.id) {
            editScenario({
                body: { ...scenario, isArchived: !scenario.isArchived },
                orgId: currentOrgId,
                scenarioId: scenario.id.toString(),
            }).then(() => {
                setScenarios(scenariosState.filter((s) => s.id !== scenario.id));
            });
        }
    };

    const handleCloseModal = () => {
        setScenarioForDelete(null);
        setIsDeleteModalOpen(false);
    };

    const onDeleteOptimization = async (scenario: IScenario) => {
        setScenarioForDelete(scenario);
        setIsDeleteModalOpen(true);
    };

    return isScenarioLoading ? (
        <Loader />
    ) : (
        <>
            <OptimizationTable
                optimizations={scenariosState}
                onDeleteOptimization={onDeleteOptimization}
                onEditOptimization={openEditModal}
                handleTracking={handleTracking}
                page={page}
                setPage={setPage}
                totalCount={totalCount}
                isFetching={isEditLoading || isFetching}
                currentTab={OptimizationTab.Archived}
                onArchiveOptimization={onArchiveOptimization}
            />
            {isDeleteModalOpen && scenarioForDelete && (
                <DeleteScenarioModal
                    setScenarios={setScenarios}
                    scenarioForDelete={scenarioForDelete}
                    handleCloseModal={handleCloseModal}
                />
            )}
        </>
    );
};
