import { IDimCampaignResponse } from "src/interfaces/dimCampaignResponse/IDimCampaignResponse";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { keysToCamelCase } from "src/utils/format";

export const formatDimCampaigns = (response: { data: IDimCampaignResponse[] }): IDimCampaign[] => {
    return response?.data?.length
        ? response.data.map((res) => {
              return keysToCamelCase(res.attributes);
          })
        : [];
};
