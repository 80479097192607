import React from "react";
import { Box, TableCell, TableHead, TableRow, TableSortLabel } from "@mui/material";
import { useTheme } from "@mui/system";

import { useStylesForHeaderInTable } from "../../../consts/StylesForTables";
import { TRUE_METRICS } from "../../../consts/performancePaidPage/performancePaidPage";
import { AVATAR_TEXT_COLOR } from "../../../consts/colors";
import { Choice, IHeadCell } from "../../../consts/emailPage/emailPage";
import { useStylesForCampaignTableHeader } from "../../../components/CampaignComponents/CampaignTable/CampaignTableHeader";

interface IEmailCampaignTableHeader {
    onRequestSort?: (event: React.MouseEvent<unknown>, property: string) => void;
    choice?: Choice;
    choiceBy?: string;
    selectedTableColumns: IHeadCell[];
}

export const EmailCampaignTableHeader = (props: IEmailCampaignTableHeader) => {
    const { onRequestSort, choice, choiceBy, selectedTableColumns } = props;

    const classes = { ...useStylesForCampaignTableHeader(useTheme()), ...useStylesForHeaderInTable() };

    const createSortHandler = (property: string, name?: string) => (event: React.MouseEvent<unknown>) => {
        if (onRequestSort) {
            onRequestSort(event, property);
        }
    };

    return (
        <TableHead className={classes.tableHeader}>
            <TableRow className={`${classes.headerRow} ${classes.stickyHeader}`} id="sticky-performance-header">
                {selectedTableColumns.map(
                    (header) =>
                        !header.hidden && (
                            <TableCell
                                key={header.id}
                                align={header.numeric ? "right" : "left"}
                                sortDirection={choiceBy === header.id ? choice : false}
                                className={classes.modifiedCellClass}
                                sx={{
                                    borderBottom: "1px solid #E0E0E0",
                                    borderRight: TRUE_METRICS.includes(header.id)
                                        ? `1px solid ${AVATAR_TEXT_COLOR}`
                                        : "",
                                    borderLeft: TRUE_METRICS.includes(header.id)
                                        ? `1px solid ${AVATAR_TEXT_COLOR}`
                                        : "",
                                    width: header.width,
                                    minWidth: header.width,
                                    borderTop: 0,
                                }}
                            >
                                {onRequestSort ? (
                                    <TableSortLabel
                                        active={choiceBy === header.id}
                                        direction={choiceBy === header.id ? choice : "asc"}
                                        onClick={createSortHandler(header.id, header.label)}
                                        className={classes.headerLeftSectionTitle}
                                        color="green"
                                    >
                                        {header.label}
                                        {choiceBy === header.id ? (
                                            <Box component="span" style={{ display: "none" }}>
                                                {choice === "desc" ? "sorted descending" : "sorted ascending"}
                                            </Box>
                                        ) : null}
                                    </TableSortLabel>
                                ) : (
                                    <span className={classes.headerLeftSectionTitle}>{header.label}</span>
                                )}
                            </TableCell>
                        ),
                )}
            </TableRow>
        </TableHead>
    );
};
