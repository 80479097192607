import * as React from "react";
import { Tooltip, Chip } from "@mui/material";
import { IModelEligibility } from "../interfaces/entities/IModelEligibility";

interface IModelEligibilityTooltipProps {
    modelEligibilityData?: IModelEligibility;
    classes: {
        tooltip: string;
    };
}

const getProblemAndResolution = (modelEligibilityData: IModelEligibility) => {
    return (
        <React.Fragment>
            {modelEligibilityData.explanation}
            {modelEligibilityData.ruleExceptions.map((rule, index) => (
                <p key={index} style={{ marginBottom: 0 }}>
                    <b>Problem:</b>
                    <br />
                    {rule.current}
                    <br />
                    <br />
                    <b>To Resolve This:</b>
                    <br />
                    {rule.resolution}
                </p>
            ))}
        </React.Fragment>
    );
};

export const ModelEligibilityTooltip: React.FC<IModelEligibilityTooltipProps> = ({
    modelEligibilityData,
    classes = {
        tooltip: "",
    },
}) => {
    const message = modelEligibilityData ? (
        getProblemAndResolution(modelEligibilityData)
    ) : (
        <React.Fragment>
            True metrics are not yet available for this campaign. This is expected and is monitored internally. Any
            issues will be resolved by our data science team.
        </React.Fragment>
    );
    const label = modelEligibilityData ? modelEligibilityData.label : "Not Available";
    return (
        <Tooltip placement="top" arrow classes={{ tooltip: classes.tooltip }} title={message}>
            <Chip component="span" label={label} variant="outlined" sx={{ width: "100%" }} />
        </Tooltip>
    );
};
