import React, { FC } from "react";
import { Typography } from "@mui/material";
import { CustomModal } from "../core/CustomDialog/CustomModal";

interface ISaveChangesWarningModal {
    isOpen: boolean;
    message: string;
    onConfirm: () => void;
    onCancel: () => void;
    onSaveChanges: () => void;
}

export const SaveChangesWarningModal: FC<ISaveChangesWarningModal> = ({
    isOpen,
    message,
    onConfirm,
    onCancel,
    onSaveChanges,
}) => {
    if (!isOpen) {
        return null;
    }

    return (
        <CustomModal
            title="Unsaved Changes"
            instanceSlot={<Typography>{message}</Typography>}
            actions={{
                1: {
                    label: "Save Changes",
                    onClick: onSaveChanges,
                },
                2: {
                    label: "Continue",
                    onClick: onConfirm,
                },
                3: {
                    label: "Cancel",
                    onClick: onCancel,
                },
            }}
            closeIcon={false}
            closeModal={onCancel}
        />
    );
};
