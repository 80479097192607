import React from "react";
import { Typography } from "@mui/material";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useDispatch, useSelector } from "react-redux";
import { useTheme } from "@mui/system";

import { IMetricAttributionTableValuesTransformed } from "../../../interfaces/performanceDetails/IMetricAttributionTableResponse";

import { ActivePerformanceTabs } from "../../../enums/ActivePerfomanceTabs";
import { PercentageTrendChip } from "../../core/Chips/PercentageTrendChip";
import { capitalizeFirstLetter, formatValue, handleClickWithTextSelectionCheck } from "../../../services/utils";
import { FORMATS } from "../../../enums/Formats";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { cursor } from "../../../assets/styles/commonStyle";
import { AVATAR_TEXT_COLOR } from "../../../consts/colors";
import { NegativeReportedMetricTooltip } from "../../../components/CampaignComponents/NegativeReportedMetricTooltip.";
import { EMAIL_TRUE_METRICS, IHeadCell, NEGATIVE_TREND_GOOD } from "../../../consts/emailPage/emailPage";
import {
    openedEmailInsightsModalIdsSelector,
    setEmailInsightsModal,
} from "../../../reduxState/slices/emailInsightsModalSlice";
import { useStylesForCampaignTableRow } from "../../../components/CampaignComponents/CampaignTable/CampaignTableRow";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { IEmail } from "../../../interfaces/entities/IEmail";

interface IEmailCampaignPercentageTableRow {
    row: IEmail;
    totalAmount?: IEmail;
    selectedTableColumns: IHeadCell[];
    firstColTitle?: string;
    isPercentRow?: boolean;
    isBothRowAvail?: boolean;
    allData?: {
        pastRow: IEmail;
        currentRow: IEmail;
    };
}

export const EmailCampaignPercentageTableRow: React.FC<IEmailCampaignPercentageTableRow> = ({
    row,
    totalAmount,
    selectedTableColumns,
    firstColTitle,
    isPercentRow,
    isBothRowAvail,
    allData,
}) => {
    const dispatch = useDispatch();
    const openedInsightsModalIds = useSelector(openedEmailInsightsModalIdsSelector);
    const isPerformanceRowSelected = openedInsightsModalIds?.length
        ? openedInsightsModalIds.includes(row.campaignId)
        : false;

    const classes = {
        ...useStylesForRowInTable(),
        ...useStylesForCampaignTableRow(useTheme(), isPerformanceRowSelected),
    };

    const isReportedRevenueNegative = !totalAmount && row.channelReportedRevenue && row.channelReportedRevenue < 0;

    const getPercentageValue = (value: number) => {
        return typeof value === "number" ? <PercentageTrendChip value={value} isPositiveTrendGood={true} /> : value;
    };

    const getPreparedValue = (element: any, title: any) => {
        return element.id === "channelReportedRevenue" && isReportedRevenueNegative ? (
            <NegativeReportedMetricTooltip classes={classes} performanceRow={row} />
        ) : element.id === "status" ? (
            ""
        ) : (
            title
        );
    };

    const getCellValue = (element: any, title: any, isPercentRow: boolean) => {
        const formattedPercentageVal =
            element.id === "type"
                ? ""
                : totalAmount
                ? getPercentageValue(totalAmount[element.id])
                : !isBothRowAvail
                ? `--`
                : getPercentageValue(row[element.id]);

        return isPercentRow
            ? formattedPercentageVal
            : title === "All Campaigns"
            ? element.id === "campaignId"
                ? firstColTitle || ""
                : "Total"
            : getPreparedValue(element, title);
    };

    const openModalHandler = (e: React.MouseEvent<HTMLTableRowElement, MouseEvent>) => {
        if (allData?.currentRow && !openedInsightsModalIds.includes(row.campaignId)) {
            dispatch(
                setEmailInsightsModal({
                    data: allData.currentRow,
                    isMinimize: false,
                    activeTab: ActivePerformanceTabs.Performance,
                }),
            );
        }
    };

    return (
        <TableRow
            hover={isPercentRow}
            id={`performance-row-${row.campaignId}` || ""}
            className={`${classes.bodyRow} ${classes.selectedRow} ${isPercentRow && cursor("pointer")}`}
            onClick={handleClickWithTextSelectionCheck(openModalHandler)}
        >
            {selectedTableColumns.map((element, index) => {
                const isCampaignNameCell = element.id === "campaignName";
                const negativeCols = ["channelReportedRevenue"];

                const title = totalAmount
                    ? formatValue(totalAmount[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0)
                    : isCampaignNameCell
                    ? row.campaignName || row.campaignId || ""
                    : row[element.id] && row[element.id] !== 0
                    ? formatValue(row[element.id], element.sign || FORMATS.NUMERIC, element.fixed || 0)
                    : "N/A";

                const isTotalRow = totalAmount && totalAmount.campaignName === "Total";
                const currentCellValue = row[element.id] ? row[element.id] : row[element.id] === 0 ? 0 : -1;
                const isTrueMetricCell = EMAIL_TRUE_METRICS.includes(element.id);
                const isNotValidData =
                    !totalAmount &&
                    !isPercentRow &&
                    negativeCols.includes(element.id) &&
                    currentCellValue &&
                    currentCellValue < 0;

                return (
                    !element.hidden && (
                        <TableCell
                            component="td"
                            scope="row"
                            sx={{
                                borderBottom: "1px solid #E0E0E0",
                                borderLeft: isTrueMetricCell ? `1px solid ${AVATAR_TEXT_COLOR}` : "",
                                borderRight: isTrueMetricCell ? `1px solid ${AVATAR_TEXT_COLOR}` : "",
                                color: "#000000!important",
                                minHeight: "52px",
                                padding: "15px 20px",
                                position: "relative",
                            }}
                            key={index}
                            align={element.numeric ? "right" : "left"}
                            title={firstColTitle && isCampaignNameCell ? firstColTitle : ""}
                        >
                            <span className={isCampaignNameCell ? classes.campaignName : ""}>
                                {isCampaignNameCell && isPercentRow && !totalAmount ? (
                                    <SVGIconRenderer
                                        icon={row.type?.toLowerCase() === "flow" ? "airIcon" : "sendIcon"}
                                        style={{ marginRight: "10px" }}
                                    />
                                ) : null}
                                {isCampaignNameCell ? (
                                    <Typography
                                        component="div"
                                        className={`${
                                            totalAmount && isTotalRow ? classes.boldText : classes.usualText
                                        } ${isPercentRow && !totalAmount && classes.underLine}`}
                                        variant="body2"
                                        sx={{
                                            paddingLeft: (totalAmount && isTotalRow) || isPercentRow ? 0 : "32px",
                                        }}
                                    >
                                        {firstColTitle}
                                    </Typography>
                                ) : (
                                    <Typography
                                        component="div"
                                        className={`${
                                            totalAmount && isTotalRow ? classes.boldText : classes.usualText
                                        } ${isCampaignNameCell && !isTotalRow && classes.underLine}`}
                                        variant="body2"
                                    >
                                        {element.id === "status" && !totalAmount
                                            ? isPercentRow
                                                ? capitalizeFirstLetter(row.status?.toLocaleLowerCase() || "")
                                                : ""
                                            : isNotValidData
                                            ? "N/A"
                                            : getCellValue(element, title, isPercentRow || false)}
                                    </Typography>
                                )}
                            </span>
                        </TableCell>
                    )
                );
            })}
        </TableRow>
    );
};
