import Cookies from "js-cookie";

import { IDictionary } from "../../interfaces/IDictionary";
import { IAnalytics, IOrganizationWithRole } from "./IAnalytics";
import { IAnalyticsManager } from "./IAnalyticsManager";
import { AmplitudeAnalytics } from "./providers/amplitudeAnalytics";
import { IntercomAnalytics } from "./providers/intercomAnalytics";
import { CypressAnalytics } from "./providers/cypressAnalytics";
import { amplitudeAnalyticsKey, isCypressRunning } from "../../utils/env";
import { IMember } from "../../interfaces/entities/IMember";
import { VitallyAnalytics } from "./providers/vitallyAnalytics";

export class AnalyticsManager implements IAnalyticsManager {
    private providers: IAnalytics[];
    constructor(providers: IAnalytics[]) {
        this.providers = providers;
    }

    public logEvent(eventType: string, eventProperties?: IDictionary<any>): void {
        this.providers.forEach((p) => {
            p.logEvent(eventType, eventProperties);
        });
    }
    public setCurrentOrganization(properties: IOrganizationWithRole): void {
        this.providers.forEach((p) => {
            p.setCurrentOrganization(properties);
        });
    }
    public setUserProperties(properties: IDictionary<any>): void {
        this.providers.forEach((p) => {
            p.setUserProperties(properties);
        });
    }
    public setUser(user: IMember): void {
        this.providers.forEach((p) => {
            p.setUser(user);
        });
    }

    public login(user: any): void {
        this.providers.forEach((p) => {
            p.login(user);
        });
    }

    public logout(): void {
        this.providers.forEach((p) => {
            p.logout();
        });
    }
}

const getAnalyticsManager = () => {
    if (isCypressRunning) {
        console.log("Cypress detected, disabling standard analytics to avoid cost increases. Using CypressAnalytics");
        return [
            new CypressAnalytics(),
        ];
    }

    const allAnalytics = [
        new CypressAnalytics(),
        new AmplitudeAnalytics(amplitudeAnalyticsKey),
        new IntercomAnalytics(),
        new VitallyAnalytics(),
    ];

    const cookieConsent = JSON.parse(Cookies.get("cc_cookie") || "{}");
    return cookieConsent?.categories?.includes("analytics") ? allAnalytics : [];
};

export const analyticsManagerService = new AnalyticsManager(getAnalyticsManager());
