import { Typography } from "@mui/material";
import { capitalize } from "lodash";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

import { CustomModal } from "src/components/CustomModal/CustomModal";
import { IScenario } from "src/interfaces/entities/IScenario";
import { useDeleteScenarioMutation } from "src/reduxState/apis/optimizationApi";
import { selectCurrentlyViewingId } from "src/reduxState/slices/organizationSlice";
import { filterScenarioById } from "src/reduxState/slices/scenarioSlice";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "src/services/utils";

export const DeleteScenarioModal = ({
    scenarioForDelete,
    handleCloseModal,
    setScenarios,
}: {
    scenarioForDelete: IScenario;
    handleCloseModal: () => void;
    setScenarios: React.Dispatch<React.SetStateAction<IScenario[]>>;
}) => {
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();
    const analyticsService = useAnalyticsService();
    const currentOrgId = useSelector(selectCurrentlyViewingId);

    const [deleteScenario, { isLoading: isDeleteLoading }] = useDeleteScenarioMutation();

    const deleteScenarioAction = async (scenario: IScenario) => {
        if (scenario.id) {
            await deleteScenario({ orgId: currentOrgId, scenarioId: scenario.id.toString() })
                .then(() => {
                    analyticsService.logEvent("Optimization Deleted", {
                        Page: getPathForAnalytics(location.pathname),
                    });
                    setScenarios((prev) => prev.filter((s) => s.id !== scenario.id));
                    dispatch(filterScenarioById({ id: scenario.id }));
                    enqueueSnackbar(`${capitalize(scenario.name)} deleted successfully.`, {
                        id: uniqueId(),
                        variant: "success",
                        autoHideDuration: 3000,
                    });
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`Delete scenario error ${error}`);
                })
                .finally(() => {
                    handleCloseModal();
                });
        }
    };

    return (
        <CustomModal
            title="Delete Scenario?"
            subTitle=""
            details={
                <Typography component="li" variant="body2" sx={{ textIndent: "unset!important", marginTop: "-20px" }}>
                    Are you sure want to delete <b>{scenarioForDelete.name}</b> scenario?
                </Typography>
            }
            buttonName="Delete"
            handleAction={deleteScenarioAction}
            selectedConnector={scenarioForDelete}
            closeModal={handleCloseModal}
            disableBtn={isDeleteLoading}
            type="error"
        />
    );
};
