import { css } from "@emotion/css";
import { Form, Formik, FormikConfig, FormikProps } from "formik";
import React from "react";
import { bind } from "decko";
import { boolean, object, string } from "yup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Spinner from "@mui/material/CircularProgress";
import Typography from "@mui/material/Typography";

import { Button, ButtonVariant } from "../core/Button/Button";
import { Field } from "../core/Field/Field";
import { Password } from "../core/Field/Password";
import { CheckBox } from "../core/CheckBox/CheckBox";

import { IUserLoginValues } from "../../interfaces/IUserLoginValues";
import { fieldClass } from "../../layouts/SimpleForm";
import { FooterForm } from "../core/Form/FooterForm";

const checkbox = css`
    span {
        font-size: 16px;
        padding-top: 0;
        padding-bottom: 0;
    }
`;

interface ILoginFormProps extends FormikConfig<IUserLoginValues> {
    onForgotPassword?(...args: any[]): any;
    onKeepLoggedIn?(value: boolean): void;
}

export class LoginForm extends React.Component<ILoginFormProps, { keepLoggedIn: boolean }> {
    constructor(props: ILoginFormProps) {
        super(props);
        this.state = {
            keepLoggedIn: false,
        };
    }

    @bind
    public handleClick() {
        if (this.props.onKeepLoggedIn) {
            this.props.onKeepLoggedIn(!this.state.keepLoggedIn);
        }
        this.setState({ keepLoggedIn: !this.state.keepLoggedIn });
    }

    public render() {
        const { onForgotPassword, ...rest } = this.props;

        return (
            <Formik
                validationSchema={object().shape({
                    email: string().email("Invalid email format").required("E-mail is required"),
                    password: string().required("Password is required"),
                    isChecked: boolean(),
                })}
                {...rest}
            >
                {({ errors, handleChange, touched, values, isSubmitting }: FormikProps<IUserLoginValues>) => {
                    const hasEmailError = (touched.email && errors.email) || errors.base;
                    const hasPasswordError = (touched.password && errors.password) || errors.base;
                    const hasErrors = Boolean(hasEmailError || hasPasswordError);

                    return (
                        <Form data-cy="loginForm">
                            <Typography fontSize="1.75rem" color="#000000" fontWeight="700">
                                Log In
                            </Typography>
                            <Typography
                                fontSize="18px"
                                variant="body2"
                                color="#000000"
                                sx={(theme) => ({
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: "12px!important",
                                    },
                                })}
                            >
                                Log In to your PrescientAI account.
                            </Typography>
                            <Field
                                id="email"
                                name="email"
                                label="Email"
                                placeholder="Enter email"
                                value={values.email}
                                error={hasErrors}
                                onChange={handleChange}
                                classes={{
                                    root: fieldClass,
                                }}
                                autoComplete="username, email"
                                data-cy="loginEmail"
                                fullWidth
                            />
                            <Password
                                id="password"
                                name="password"
                                label={"Password"}
                                placeholder="Enter password"
                                autoComplete="current-password"
                                error={hasErrors}
                                value={values.password}
                                onChange={handleChange}
                                data-cy="loginPassword"
                                helperText={
                                    hasEmailError || hasPasswordError
                                        ? "Oops! That Email or password is incorrect. Please try again."
                                        : ""
                                }
                                classes={{
                                    root: fieldClass,
                                }}
                                fullWidth
                            />
                            <FormControlLabel
                                className={checkbox}
                                control={<CheckBox onChange={this.handleClick} checked={this.state.keepLoggedIn} />}
                                data-cy="keepMeloggedIn"
                                label="Keep me logged in"
                                sx={(theme) => ({
                                    [theme.breakpoints.down("sm")]: {
                                        fontSize: "12px!important",
                                    },
                                })}
                            />
                            <FooterForm
                                submitBtnProps={{
                                    disabled: isSubmitting || (values.email === "" && values.password === ""),
                                    isLoading: isSubmitting,
                                    text: "Log In",
                                    testId:"loginButton"
                                }}
                                cancelBtnProps={{
                                    text: "Forgot your password?",
                                    onCancelBtnClick: onForgotPassword,
                                    testId: "forgotPasswordButton",
                                }}
                            />
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
