import React, { FC } from "react";
import { EMAIL_ATTRIBUTION_HEADERS } from "../../consts/emailPage/emailPage";
import { getTotalAmountForEmail, percentageCalculationForEmail } from "../../services/emailPage/emailPage";
import { AggregateMetricHeader } from "../AggregateMetricHeader/AggregateMetricHeader";
import { IEmail } from "../../interfaces/entities/IEmail";
import { IEmailPercentageTableData } from "../../interfaces/emailDetails/IEmailPercentage";

interface IEmailAggregate {
    totalAmount: IEmail;
    finalPercentagePerformanceData: IEmailPercentageTableData;
    isLoading: boolean;
}

export const EmailAggregate: FC<IEmailAggregate> = ({ totalAmount, finalPercentagePerformanceData, isLoading }) => {
    const { finalData, currentFilteredData, pastFilteredData } = finalPercentagePerformanceData;

    const totalKeyMap: {
        [key: string]: {
            title: string;
            info: string;
        };
    } = {
        channelReportedRevenue: {
            title: "Channel Revenue",
            info: "This refers to the revenue attribution model used by the associated platform to report revenue for a given campaign",
        },
        reportedOrders: {
            title: "Reported Orders",
            info: "",
        },
        emailSends: {
            title: "Sends",
            info: "Platform reported sends",
        },
        emailOpen: {
            title: "Total Opens",
            info: "",
        },
        emailOpenUnique: {
            title: "Unique Opens",
            info: "The unique count of opens (one open per recipient)",
        },
        openRate: {
            title: "Open Rate",
            info: "The rate at which your customers open your emails (the number of individuals opening your email divided by the number of recipients)",
        },
        emailClicks: {
            title: "Total Clicks",
            info: "",
        },
        emailClicksUnique: {
            title: "Unique Clicks",
            info: "The unique count of clicks (one click per recipient)",
        },
        clickRate: {
            title: "Click Rate",
            info: "The rate at which your customers click a link in your emails (the number of individuals clicking your email divided by the number of recipients)",
        },
        emailBounces: {
            title: "Bounces",
            info: "",
        },
        bounceRate: {
            title: "Bounce Rate",
            info: "The number of bounced addresses divided by the total number of recipients",
        },
        emailUnsubscribes: {
            title: "Unsubscribes",
            info: "",
        },
        unsubscribeRate: {
            title: "Unsubscribe Rate",
            info: "The number of recipients who unsubscribe divided by the total number of recipients",
        },
        rpc: {
            title: "RPC",
            info: "",
        },
        aov: {
            title: "AOV",
            info: "",
        },
    };

    const currentTotalAmount = getTotalAmountForEmail(currentFilteredData);
    const pastTotalAmount = getTotalAmountForEmail(pastFilteredData);
    const totalPercentageAmount = percentageCalculationForEmail({
        currentRow: currentTotalAmount,
        pastRow: pastTotalAmount,
    });

    return (
        <AggregateMetricHeader
            tableHeader={[...EMAIL_ATTRIBUTION_HEADERS]}
            metricTotalMapObj={totalKeyMap}
            isMetricLoading={isLoading}
            totalTableAmount={totalAmount}
            totalPercentageTableAmount={totalPercentageAmount}
        />
    );
};
