import React, { FC, useState, useMemo } from "react";
import { TabPanel } from "@mui/lab";

import { CustomTabContext } from "src/components/CustomTabContext/CustomTabContext";
import {
    ICard,
    IMetricAttributionTableValuesTransformedWithSpendAllocation,
    IScenario,
    ITacticGroup,
    RecommandationTab as RecommendationTabEnum,
} from "src/interfaces/entities/IScenario";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { getComparator, stableSort } from "src/utils/sort";
import { CampaignsRecommendationsTab } from "./CampaignsRecommendationsTab";
import { ChannelsRecommendationsTab } from "./ChannelsRecommendationsTab";
import { TacticsRecommendationsTab } from "./TacticsRecommendationsTab";
import {
    getChannelGroupData,
    getSearchedCampaigns,
    getTacticGroupData,
    getTotalAllocation,
    getTotalCampaignData,
} from "src/services/optimizationPage/optimization";
import { IDictionary } from "src/interfaces/IDictionary";
import { SearchField } from "src/components/SearchField/SearchField";

interface IRecommendationTab {
    selectedScenario: IScenario;
    forecastCampaign: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    isRecalculateScenarioDisabled: boolean;
    isPerformanceLoading: boolean;
    increaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    decreaseSpendCampaigns: IMetricAttributionTableValuesTransformedWithSpendAllocation[];
    compareToLabel: string;
    cards: ICard[];
}

export const RecommendationTab: FC<IRecommendationTab> = ({
    selectedScenario,
    forecastCampaign,
    isRecalculateScenarioDisabled,
    isPerformanceLoading,
    increaseSpendCampaigns,
    decreaseSpendCampaigns,
    compareToLabel,
    cards,
}) => {
    const analyticsService = useAnalyticsService();
    const tabs = [
        { label: "Channels", value: RecommendationTabEnum.Channels },
        { label: "Tactics", value: RecommendationTabEnum.Tactics },
        { label: "Campaigns", value: RecommendationTabEnum.Campaigns },
    ];
    const [searchCampaigns, setSearchCampaigns] = useState("");
    const [tabValue, setTabValue] = useState(RecommendationTabEnum.Channels);

    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        if (newValue === RecommendationTabEnum.Tactics) {
            analyticsService.logEvent(`Optimization Tactics Page Viewed`, {});
        }
        setTabValue(newValue as RecommendationTabEnum);
    };

    const handleSearchChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
        const { value } = e.target;
        setSearchCampaigns(value);
    };

    const searchedIncreasedCampaignsData = useMemo(() => {
        return getSearchedCampaigns(increaseSpendCampaigns, searchCampaigns);
    }, [increaseSpendCampaigns, searchCampaigns]);

    const searchedDecreasedCampaignsData = useMemo(() => {
        return getSearchedCampaigns(decreaseSpendCampaigns, searchCampaigns);
    }, [decreaseSpendCampaigns, searchCampaigns]);

    const searchedCampaigns = useMemo(() => {
        return [...searchedIncreasedCampaignsData, ...searchedDecreasedCampaignsData];
    }, [searchedIncreasedCampaignsData, searchedDecreasedCampaignsData]);

    const searchedChannelGroupData = useMemo(() => {
        return getChannelGroupData(searchedCampaigns).filter((channel) => channel.campaigns.length > 0);
    }, [searchedCampaigns]);

    const searchedTacticsGroupData = useMemo(() => {
        return getTacticGroupData(searchedCampaigns).filter((channel) => channel.campaigns.length > 0);
    }, [searchedCampaigns]);

    const totalAllocationBasedOnSearchedCampaigns: IDictionary = useMemo(() => {
        return getTotalCampaignData(getTotalAllocation(searchedCampaigns));
    }, [searchedCampaigns]);

    return (
        <CustomTabContext tabValue={tabValue} handleChange={handleChange} tabs={tabs}>
            <>
                <SearchField placeholder={`Search ${tabValue}`} onChange={handleSearchChange} value={searchCampaigns} />
                <TabPanel sx={{ padding: 0 }} value="campaigns">
                    <CampaignsRecommendationsTab
                        cards={cards}
                        selectedScenario={selectedScenario}
                        forecastCampaign={forecastCampaign}
                        allForecastedTotalAllocation={totalAllocationBasedOnSearchedCampaigns}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        increaseSpendCampaigns={searchedIncreasedCampaignsData}
                        decreaseSpendCampaigns={searchedDecreasedCampaignsData}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="channels">
                    <ChannelsRecommendationsTab
                        cards={cards}
                        channelGroupData={stableSort(
                            searchedChannelGroupData,
                            getComparator("asc", "cleanChannelName"),
                        )}
                        scenario={selectedScenario}
                        totalAllocationCampaigns={totalAllocationBasedOnSearchedCampaigns}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
                <TabPanel sx={{ padding: 0 }} value="tactics">
                    <TacticsRecommendationsTab
                        cards={cards}
                        tacticGroupData={searchedTacticsGroupData}
                        scenario={selectedScenario}
                        totalAllocationCampaigns={totalAllocationBasedOnSearchedCampaigns}
                        isTableLoading={isRecalculateScenarioDisabled || isPerformanceLoading}
                        compareToLabel={compareToLabel}
                    />
                </TabPanel>
            </>
        </CustomTabContext>
    );
};
