import * as React from "react";
import { Box, Typography } from "@mui/material";
import { css } from "@emotion/css";
import { Stack } from "@mui/system";

import { cursor } from "../../../assets/styles/commonStyle";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";

const additionalInfo = (small: boolean) => css`
    font-style: normal !important;
    font-weight: normal !important;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex !important;
    font-family: "Inter", sans-serif !important;
    align-items: center !important;
    letter-spacing: 0.1px !important;
    margin-top: ${small ? "0" : "40px"} !important;
`;

const emailClass = (small: boolean) => css`
    font-weight: bold;
    font-size: 14px !important;
    line-height: 18px !important;
    display: flex;
    align-items: center;
    margin-top: ${small ? "0" : "40px"} !important;
`;

export const modalHeader = css`
    justify-content: space-between;
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
`;

export interface ILoginFormProps {
    title: string;
    subTitle?: string;
    color?: string;
    icon?: React.ReactNode;
    email?: string;
    close?: boolean;
    small?: boolean;
    fontSize?: FontSize;
    onClose?(): void;
}

export enum FontSize {
    SMALL = "small",
    NORMAL = "normal",
}

export const HeaderForm: React.FC<ILoginFormProps> = ({
    title,
    subTitle,
    email,
    icon,
    close = true,
    small = false,
    onClose,
}) => {
    return (
        <>
            <Stack flexDirection="row" justifyContent="space-between" alignItems="center" sx={{ width: "100%" }}>
                <Stack flexDirection="row" alignItems="center" gap="12px">
                    <Typography fontSize="1.75rem" color="#000000" fontWeight="700">
                        {title}
                    </Typography>
                    {icon && icon}
                </Stack>
                {close && (
                    <Box
                        display="flex"
                        flexDirection="row-reverse"
                        style={{ marginRight: "-8px" }}
                        onClick={onClose}
                        className={`${cursor("pointer")}`}
                    >
                        <SVGIconRenderer icon="closeIcon" />
                    </Box>
                )}
            </Stack>
            {subTitle && <Typography className={additionalInfo(small)}>{subTitle}</Typography>}
            {email && <Typography className={emailClass(small)}>{email}</Typography>}
        </>
    );
};
