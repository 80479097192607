import React, { useState } from "react";
import { Backdrop, CircularProgress, TableBody, TableCell, TableRow, Typography } from "@mui/material";
import { useSelector } from "react-redux";
import { format } from "date-fns";

import { CustomTableStructure } from "../../../components/core/CustomTableStructure/CustomTableStructure";
import { useStylesTable } from "../../../consts/StylesForTables";
import {
    Choice,
    ICells,
    OPTIMIZATION_PAGE_TABLE_HEADERS,
    OptimizationTab,
} from "../../../consts/optimizationPage/optimizationPage";
import { IScenario } from "../../../interfaces/entities/IScenario";
import { OptimizationTableHeader } from "./OptimizationHeader";
import { getComparator, stableSort } from "../../../utils/sort";
import { OptimizationRow } from "./OptimizationRow";
import { scenariolastModelRunDateSelector } from "../../../reduxState/slices/scenarioSlice";

interface IOptimizationsTableView {
    optimizations: IScenario[];
    isFetching: boolean;
    page: number;
    totalCount: number;
    disableTracking?: boolean;
    setPage: React.Dispatch<React.SetStateAction<number>>;
    currentTab?: OptimizationTab;
    handleTracking: (scenario: IScenario) => void;
    onArchiveOptimization?: (scenario: IScenario) => void;
    onEditOptimization?(scenario: IScenario): void;
    onDeleteOptimization(scenario: IScenario): void;
}

export const OptimizationTable: React.FC<IOptimizationsTableView> = ({
    optimizations,
    onDeleteOptimization,
    onEditOptimization,
    handleTracking,
    onArchiveOptimization,
    page,
    setPage,
    totalCount,
    isFetching,
    currentTab,
    disableTracking = false,
}) => {
    const classes = useStylesTable();
    const scenariolastModelRunDate = useSelector(scenariolastModelRunDateSelector);

    const [order, setOrder] = useState<Choice>("desc");
    const [orderBy, setOrderBy] = useState("");

    const handleEdit = (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => {
        e.stopPropagation();
        if (onEditOptimization && scenario) {
            onEditOptimization(scenario);
        }
    };

    const handleDelete = (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => {
        e.stopPropagation();
        if (scenario) {
            onDeleteOptimization(scenario);
        }
    };

    const handleArchive = (e: React.MouseEvent<HTMLDivElement>, scenario: IScenario) => {
        e.stopPropagation();
        if (onArchiveOptimization && scenario) {
            onArchiveOptimization(scenario);
        }
    };

    const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof ICells) => {
        const isAsc = orderBy === property && order === "asc";
        setOrder(isAsc ? "desc" : "asc");
        setOrderBy(property);
    };

    const handleChangePage = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, newPage: number) => {
        setPage(newPage + 1);
    };

    const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setPage(1);
    };

    return (
        <CustomTableStructure
            paginationProps={{
                count: totalCount,
                page: page - 1,
                handleChangePage,
                handleChangeRowsPerPage,
                extraInfo: scenariolastModelRunDate
                    ? `Last model run date: ${format(new Date(scenariolastModelRunDate), "MMM d, yyyy")}`
                    : "",
            }}
        >
            <OptimizationTableHeader onRequestSort={handleRequestSort} choice={order} choiceBy={orderBy} />
            <TableBody className={classes.tableBody} sx={{ position: "relative" }}>
                {isFetching && (
                    <TableRow>
                        <TableCell
                            align="center"
                            sx={{ padding: 0, border: 0 }}
                            colSpan={OPTIMIZATION_PAGE_TABLE_HEADERS.length}
                        >
                            <Backdrop
                                sx={{
                                    position: "absolute",
                                    backgroundColor: "rgba(255,255,255,0.5)",
                                    color: "#fff",
                                    gap: "10px",
                                    zIndex: (theme) => theme.zIndex.drawer + 1,
                                }}
                                open={isFetching}
                            >
                                <CircularProgress size={30} disableShrink />
                            </Backdrop>
                        </TableCell>
                    </TableRow>
                )}

                {optimizations.length === 0 ? (
                    <TableRow>
                        <TableCell align="center" colSpan={OPTIMIZATION_PAGE_TABLE_HEADERS.length}>
                            <Typography>No scenario found</Typography>
                        </TableCell>
                    </TableRow>
                ) : (
                    stableSort(optimizations, getComparator(order, orderBy)).map((row: any) => {
                        return (
                            <OptimizationRow
                                optimization={row}
                                key={row.id}
                                onDeleteOptimization={handleDelete}
                                onEditOptimization={handleEdit}
                                handleTracking={handleTracking}
                                onArchiveScenario={handleArchive}
                                currentTab={currentTab}
                                disableTracking={disableTracking}
                            />
                        );
                    })
                )}
            </TableBody>
        </CustomTableStructure>
    );
};
