import { createSlice, createSelector } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";
import { IModelEligibility } from "../../interfaces/entities/IModelEligibility";

export interface IModelEligibilityState {
    byId: { [key: string]: IModelEligibility };
}

const initialState: IModelEligibilityState = {
    byId: {},
};

const modelEligibility = createSlice({
    name: "modelEligibility",
    initialState,
    reducers: {
        appendModelEligibilityData: (
            state: IModelEligibilityState,
            action: {
                payload: IModelEligibility[];
                type: string;
            },
        ) => {
            const { payload } = action;
            const { byId } = state;
            const newById = Object.assign({}, byId);

            payload.forEach((model) => {
                newById[model.campaignId] = model;
            });

            return {
                byId: newById,
            };
        },
    },
});

export const { appendModelEligibilityData } = modelEligibility.actions;

export default modelEligibility.reducer;

// Selectors
export const modelEligibilitySelector = (state: RootState) => state.modelEligibility;
export const modelEligibilityListByIdSelector = createSelector(modelEligibilitySelector, (state) => state.byId);
export const modelEligibilityListKeysSelector = createSelector(modelEligibilityListByIdSelector, (byId) =>
    Object.keys(byId),
);

export const modelEligibilityByIdSelector = createSelector(
    [modelEligibilityListByIdSelector, (_, campaignId) => campaignId],
    (byId, campaignId) => byId && byId[campaignId],
);
