import React, { useState } from "react";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { Stack } from "@mui/system";
import { useDispatch, useSelector } from "react-redux";

import { IMetricAttributionTableValuesTransformed } from "../../../../interfaces/performanceDetails/IMetricAttributionTableResponse";
import { RevenueMetric } from "./RevenueMetric/RevenueMetric";
import { NewCustomersMetric } from "./NewCustomerMetric/NewCustomersMetric";
import { setInsightsModalActiveMetric } from "../../../../reduxState/slices/insightsModalSlice";
import { ActivePerformanceTabs } from "../../../../enums/ActivePerfomanceTabs";
import { attributionMetricDropdownSelector } from "../../../../reduxState/slices/featuresSlice";

interface IAttributionTab {
    performanceCampaignData: IMetricAttributionTableValuesTransformed;
    modalInfo?: {
        data: IMetricAttributionTableValuesTransformed;
        isMinimize: boolean;
        activeTab: ActivePerformanceTabs;
        activeMetric: string;
    };
}

const metricOptions = [
    { label: "Revenue", id: "revenue" },
    { label: "New Customers", id: "newCustomers" },
];

export const AttributionTab: React.FC<IAttributionTab> = ({ performanceCampaignData, modalInfo }) => {
    const attributionMetricDropdown = useSelector(attributionMetricDropdownSelector);
    const dispatch = useDispatch();
    const [selectedMetric, setSelectedMetric] = useState(modalInfo?.activeMetric || "revenue");
    const isCustomersNotAvailable = !(performanceCampaignData.newCustomers && performanceCampaignData.newCustomers > 0);

    return (
        <Stack flexDirection="column" spacing={4} mt={3}>
            {attributionMetricDropdown && (
                <div style={{ position: "relative" }}>
                    <FormControl
                        sx={{
                            m: 1,
                            minWidth: 120,
                            backgroundColor: "white",
                            position: "absolute",
                            right: 20,
                        }}
                        size="small"
                    >
                        <InputLabel id="metric-select-small-label">Metric</InputLabel>
                        <Select
                            value={selectedMetric}
                            labelId="metric-select-small-label"
                            id="metric-select-small"
                            label="Metric"
                            onChange={(e) => {
                                dispatch(
                                    setInsightsModalActiveMetric({
                                        campaignId: performanceCampaignData.campaignId,
                                        activeMetric: e.target.value,
                                    }),
                                );
                                setSelectedMetric(e.target.value);
                            }}
                        >
                            {metricOptions.map((grp) => {
                                return (
                                    <MenuItem
                                        value={grp.id}
                                        key={grp.id}
                                        disabled={isCustomersNotAvailable && grp.id === "newCustomers"}
                                    >
                                        {grp.label}
                                    </MenuItem>
                                );
                            })}
                        </Select>
                    </FormControl>
                </div>
            )}
            {selectedMetric === "revenue" && <RevenueMetric performanceCampaignData={performanceCampaignData} />}
            {selectedMetric === "newCustomers" && (
                <NewCustomersMetric performanceCampaignData={performanceCampaignData} />
            )}
        </Stack>
    );
};
