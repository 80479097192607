export interface IBreakpoints<T = number> {
    [key: string]: T;
}

export type BreakpointKeys = keyof IBreakpoints;

// tslint:disable-next-line:no-unnecessary-class
export class MediaBlender {
    public static defaultBreakPoints: IBreakpoints = {
      lg: 1200,
      md: 992,
      sm: 768,
      xlg: 1440,
      xs: 480,
      xxs: 360,
    };

    public static init(breakPoints?: IBreakpoints) {
      if (breakPoints) {
        this.defaultBreakPoints = breakPoints;
      }
    }

    public static down(breakPointKey: BreakpointKeys) {
      const breakPoint: number = this.defaultBreakPoints[breakPointKey];
      if (!breakPoint) {
        throw new Error(`Breakpoint key '${breakPointKey}' is not defined!`);
      }

      return this._generateMediaQuery(`(max-width: ${breakPoint - 1}px)`);
    }

    public static up(breakPointKey: BreakpointKeys) {
      const breakPoint: number = this.defaultBreakPoints[breakPointKey];

      if (!breakPoint) {
        throw new Error(`Breakpoint key '${breakPointKey}' is not defined!`);
      }

      return this._generateMediaQuery(`(min-width: ${breakPoint}px)`);
    }

    private static _generateMediaQuery(mediaQuery: string) {
        return (content?: string) => `@media ${mediaQuery} {
        ${content}
      }`;
    }
}
