import React, { useEffect } from "react";

import { NoCampaignPage } from "../CampaignPageV2/NoCampaignPage";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../services/utils";

export const SubscriptionExpired = () => {
    const analyticsService = useAnalyticsService();

    useEffect(() => {
        analyticsService.logEvent("Subscription Expired Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, []);

    return (
        <div>
            <NoCampaignPage
                title="Your subscription has expired."
                subTitle={
                    <>
                        Your subscription has expired. To reactivate your subscription, go to your billing page to fix
                        the issue or reach out to your customer success manager.
                    </>
                }
                isContinueAsSuperAdminBtn={true}
            />
        </div>
    );
};
