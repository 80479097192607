import { FC, useCallback, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import { Box, Stack } from "@mui/system";
import * as echarts from "echarts";
import groupBy from "lodash/groupBy";
import { calculatePercentage } from "src/services/performancePage/performancePage";

import { IDictionary } from "src/interfaces/IDictionary";
import { capitalizeFirstLetter, formatValue } from "src/services/utils";
import { FORMATS } from "src/enums/Formats";
import { IChartItem } from "src/interfaces/chart/IChartItem";
import { baseVsHaloTooltip } from "src/services/homeDashboard/homePageTab";
import { CardLoader } from "../CardComponents/CardLoader";
import { getComparator, stableSort } from "src/utils/sort";
import {
    dataSourcesByProgrammaticNameSelector,
    selectDataSourceColor,
} from "src/reduxState/slices/supportedDataSourcesSlice";
import { store } from "src/reduxState/stores/store";
import { CHART_COLORS } from "src/consts/colors";

interface IBaseVsHaloChart {
    seriesData: IChartItem[];
    isFetching: boolean;
    grpBy: string;
}

export const getSeriesData = (seriesData: IChartItem[] = [], metrics: IDictionary, getColors?: IDictionary) => {
    const allRevenueTotal = seriesData?.reduce((a, c) => a + c.value, 0) || 0;
    const grpBeMetricName = groupBy(seriesData, "metricName");

    let targetObject: IDictionary = {};
    Object.keys(grpBeMetricName)
        .sort()
        .map((key) => {
            targetObject = { ...targetObject, [key]: groupBy(grpBeMetricName[key], "target") };
        });

    const chartData: any = Object.entries(targetObject).flatMap(([key, valueArr]) => {
        const targetData = Object.keys(valueArr).map((value) => {
            return valueArr[value].map((c: any) => ({
                ...c,
                seriesName: `${metrics[key].name} - ${capitalizeFirstLetter(
                    value.replace(/_/g, " ").replace("revenue", ""),
                ).trim()}`,
                color: metrics[key].color,
            }));
        });
        return stableSort(targetData, getComparator("asc", "seriesName"));
    });

    const grp = groupBy(chartData.flat(1), "seriesName");

    return Object.keys(grp).map((s) => {
        const grpByName = groupBy(Object.values(grp).flat(1), "channelName");
        const channelGrp = groupBy(grp[s], "channelName");
        let data = Object.keys(channelGrp)
            .map((channel) => {
                const total = grpByName[channel].reduce((a, c) => a + c.value, 0) || 0;
                const subTotal = channelGrp[channel].reduce((a, c) => a + c.value, 0) || 0;
                return [calculatePercentage(subTotal, total), channel, subTotal, channelGrp[channel]];
            })
            .filter((d) => d[2]);

        const totalGrpData = grp[s].reduce((a, c) => a + c.value, 0);
        data = [...data, [calculatePercentage(totalGrpData, allRevenueTotal), "Total", totalGrpData, grp[s]]];

        return {
            name: s,
            type: "bar",
            stack: "revenue",
            data,
            barWidth: 50,
            color: getColors?.[s.toLowerCase()],
        };
    });
};

export const BaseVsHaloChart: FC<IBaseVsHaloChart> = ({ seriesData, isFetching, grpBy }) => {
    if (isFetching) {
        return <CardLoader height="100%" width="99%" />;
    }

    const getChartColor = useCallback((seriesName: string = "") => {
        return selectDataSourceColor(store.getState(), seriesName);
    }, []);

    let chartElement: any = null;

    const getColors: IDictionary = {
        [`base - amazon selling partner`]: getChartColor("amazon_selling_partner"),
        [`halo - amazon selling partner`]: CHART_COLORS["halo - amazon selling partner"],
    };

    const metrics: IDictionary = useMemo(
        () => ({
            first_order_revenue: {
                name: "Base",
            },
            second_order_revenue: {
                name: "Halo",
            },
        }),
        [grpBy],
    );

    const grpData = groupBy(seriesData, "channelName");
    const removedAllZeroValues = Object.keys(grpData).filter((key) => {
        if (!grpData[key].every((v) => v.value === 0)) {
            return grpData[key];
        }
    });

    const yAxisLabel = [...[...new Set(removedAllZeroValues)].sort().reverse(), "Total"];

    const dataSourcesByProgrammaticName = useSelector(dataSourcesByProgrammaticNameSelector);

    const option = {
        responsive: true,
        maintainAspectRatio: false,
        tooltip: {
            trigger: "axis",
            confine: "true",
            axisPointer: {
                type: "shadow",
                label: {
                    show: false,
                },
            },
            formatter: (params: any) => baseVsHaloTooltip(dataSourcesByProgrammaticName, params),
        },
        legend: {
            show: true,
            selectedMode: false,
        },
        grid: {
            right: 20,
            left: 5,
            top: 40,
            containLabel: true,
        },
        xAxis: {
            name: "Revenue",
            type: "value",
            splitLine: {
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisLabel: {
                hideOverlap: true,
                formatter: (value: number) => formatValue(value, FORMATS.PERCENT, 0),
            },
            nameLocation: "middle",
            nameTextStyle: {
                fontSize: 12,
                align: "center",
                verticalAlign: "top",
                lineHeight: 50,
            },
            max: 100,
        },
        yAxis: {
            type: "category",
            splitLine: {
                show: false,
            },
            axisLine: {
                show: true,
            },
            axisTick: {
                show: false,
            },
            axisLabel: {
                fontSize: 15,
            },
            data: yAxisLabel,
        },
        series: getSeriesData(seriesData, metrics, getColors),
    };

    const initChart = (data: IChartItem[]) => {
        if (document.getElementById("baseVsHaloChart")) {
            chartElement = echarts.init(document.getElementById("baseVsHaloChart")!);
            if (chartElement) {
                chartElement.setOption({ ...option }, true, true);
                window.addEventListener("resize", chartResize);
            }
        }
    };

    const chartResize = () => {
        chartElement.resize();
    };

    useEffect(() => {
        initChart(seriesData);
        return () => {
            window.removeEventListener("resize", chartResize);
        };
    }, [JSON.stringify(seriesData)]);

    return (
        <Stack style={{ height: "100%" }}>
            <Box id="baseVsHaloChart" style={{ minWidth: "99%", width: "99%", height: "100%" }}></Box>
        </Stack>
    );
};
