export enum HelpCenter {
    QUICK_START = "quick_start",
    GLOSSARY = "glossary",
    FULL_GUIDE = "full_guide",
    RELEASES_NOTES = "releases_notes",
    FAQS = "faqs",
    ADVICES = "advice",
    SUPPORT = "support",
    ABOUT_THE_APP = "about_the_app",
    GUIDES = "guides",
}
