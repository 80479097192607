import { FC, Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Button } from "@mui/material";
import { Box, Stack, useTheme } from "@mui/system";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { CampaignTacticsTable } from "src/components/CampaignTactics/CampaignTacticsTable";
import { SubHeader, useStylesForSubHeader } from "src/components/SubHeader/SubHeader.v2";
import Loader from "src/components/core/Loader/Loader";
import { ICampaignTacticTypes } from "src/interfaces/entities/ICampaignTacticTypes";
import { ICompanyCampaign } from "src/interfaces/entities/ICompanyCampaign";
import {
    IAddCampaignTactic,
    useAddCampaignTacticMutation,
    useLazyGetTacticsTypeQuery,
} from "src/reduxState/apis/campaignTacticsApi";
import { useLazyGetDimCampaignsQuery } from "src/reduxState/apis/dimCampaignsApi";
import { selectCurrentlyViewingCompanyById } from "src/reduxState/slices/organizationSlice";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { filterTacticsCampaigns, getFilteredCampaignTacticsData } from "src/services/campaignTactics/campaignTactics";
import { capitalizeFirstLetter, toCamelCase } from "src/services/utils";
import { dataSourcesByProgrammaticNameSelector } from "src/reduxState/slices/supportedDataSourcesSlice";
import { getSelectedRows } from "src/services/campaignInclusion/campaignInclusion";
import { useCallbackPrompt } from "src/hooks/useCallbackPrompt";
import { SaveChangesWarningModal } from "src/components/SaveChangesWarningModal/SaveChangesWarningModal";
import { useAnalyticsService } from "src/services/analytics/useAnalyticsService";
import { useLazyGetCompanyCampaignsQuery } from "src/reduxState/apis/campaignInclusionApi";

interface ITactics {
    unsavedWarningDialog: boolean;
    setUnsavedWarningDialog: React.Dispatch<React.SetStateAction<boolean>>;
}

export const Tactics: FC<ITactics> = ({ unsavedWarningDialog, setUnsavedWarningDialog }) => {
    const { enqueueSnackbar } = useSnackbar();
    const analyticsService = useAnalyticsService();
    const currentCompany = useSelector(selectCurrentlyViewingCompanyById);
    const dataSourcesByProgrammaticName = useSelector(dataSourcesByProgrammaticNameSelector);

    const [getDimCampaigns, { isFetching }] = useLazyGetDimCampaignsQuery();
    const [getCompanyCampaigns, { isFetching: isCompanyCampaignFetching }] = useLazyGetCompanyCampaignsQuery();
    const [getTacticsType, { data: tacticsType = [] }] = useLazyGetTacticsTypeQuery();
    const [addCampaignTactic, { isLoading: isTacticSaving }] = useAddCampaignTacticMutation();

    const [campaignsTactics, setCampaignsTactics] = useState<IDimCampaign[] | null>(null);
    const [updatedCampaignsTactics, setUpdatedCampaignsTactics] = useState<IAddCampaignTactic[]>([]);
    const [selectedRows, setSelectedRows] = useState<readonly string[]>([]);
    const [search, setSearch] = useState<string>("");
    const [filter, setFilter] = useState<string[]>(["all"]);
    const [universalTactic, setUniversalTactic] = useState<string | null>(null);

    const [showPrompt, confirmNavigation, cancelNavigation] = useCallbackPrompt(unsavedWarningDialog || false);

    useEffect(() => {
        setUnsavedWarningDialog(selectedRows.length > 0 || updatedCampaignsTactics.length > 0);
    }, [updatedCampaignsTactics, selectedRows]);

    useEffect(() => {
        analyticsService.logEvent(`Tactic Settings Page Viewed`, {});
        if (!currentCompany?.id) {
            return;
        }
        Promise.all([
            getCompanyCampaigns({ orgId: currentCompany.id }).unwrap(),
            getDimCampaigns({
                orgId: currentCompany.id,
            }).unwrap(),
        ])
            .then((res: [ICompanyCampaign[], IDimCampaign[]]) => {
                setCampaignsTactics(filterTacticsCampaigns(res[1], res[0]));
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar("Something went wrong!", {
                    id: uniqueId(),
                    variant: "error",
                });
            });
        getTacticsType({ orgId: currentCompany.id });

        return () => {
            setUnsavedWarningDialog(false);
        };
    }, [currentCompany?.id]);

    const tacticChangeHandler = (value: ICampaignTacticTypes | null) => {
        setUniversalTactic(value ? value.id.toString() : null);
    };

    const onTacticRowClick = (campaignId: string) => {
        const updatedSelectedRows = getSelectedRows(selectedRows, campaignId);
        setSelectedRows(updatedSelectedRows);
        if (!updatedSelectedRows.length) {
            setUniversalTactic(null);
        }
    };

    const onTacticSelectionChange = (value: ICampaignTacticTypes | null, campaignId: string) => {
        const findCampaign = campaignsTactics?.find((c) => campaignId === c.campaignId);
        let updatedData = [...updatedCampaignsTactics];
        const upatedValue = value ? value.id : null;

        if (findCampaign && findCampaign.campaignTacticTypeId === upatedValue) {
            updatedData = updatedCampaignsTactics.filter((tactic) => tactic.campaignId !== campaignId);
        } else {
            const includedCampaignIndex = updatedCampaignsTactics.findIndex(
                (tactic) => tactic.campaignId === campaignId,
            );
            const updatedTactic = { campaignId, tacticId: (upatedValue || "").toString() };
            if (includedCampaignIndex > -1) {
                updatedData[includedCampaignIndex] = updatedTactic;
            } else {
                updatedData = [...updatedCampaignsTactics, updatedTactic];
            }
        }
        setUpdatedCampaignsTactics(updatedData);
    };

    const handleSaveCampaignTactics = (isuniversalTacticApply: boolean) => {
        if (!currentCompany?.id) {
            return;
        }
        analyticsService.logEvent(`Tactic Settings Page Saved`, {});

        let selectedCampaigns: IAddCampaignTactic[] = [...updatedCampaignsTactics];
        if (isuniversalTacticApply) {
            selectedCampaigns = selectedRows.map((campaignId) => ({
                campaignId,
                tacticId: universalTactic || "",
            }));
        }

        addCampaignTactic({
            orgId: currentCompany.id,
            campaignTacticData: selectedCampaigns,
        })
            .unwrap()
            .then(() => {
                enqueueSnackbar("Tactics saved successfully!", {
                    id: uniqueId(),
                    variant: "success",
                });
                const tactics: IDimCampaign[] =
                    campaignsTactics?.map((campaignsTactic) => {
                        const updatedTactic = selectedCampaigns.find(
                            (c) => c.campaignId === campaignsTactic.campaignId,
                        );
                        if (updatedTactic) {
                            return {
                                ...campaignsTactic,
                                campaignTacticTypeId: +updatedTactic.tacticId,
                            };
                        }
                        return campaignsTactic;
                    }) || [];

                setCampaignsTactics(tactics);
            })
            .catch((e) => {
                console.log(e);
                enqueueSnackbar("Something went wrong!", {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                setUpdatedCampaignsTactics([]);
                setSelectedRows([]);
                setUniversalTactic(null);
            });
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const handleChangeFilterValue = (filterValue: string[]) => {
        analyticsService.logEvent(`Tactic Settings Page Filtered`, {});
        setFilter(filterValue);
    };

    const dataForTable = campaignsTactics?.map((c) => {
        return {
            ...c,
            cleanChannelName: c.channel
                ? dataSourcesByProgrammaticName[c.channel.toLowerCase()]?.name ||
                  capitalizeFirstLetter(toCamelCase(c.channel))
                : "",
        };
    });

    const filterChipsValue = dataForTable?.length
        ? [
              ...new Map(
                  [
                      { key: "all", label: "All", isAll: true },
                      ...dataForTable.map((d) => ({
                          isAll: false,
                          key: d.channel,
                          label: d.cleanChannelName,
                      })),
                  ].map((item) => [item.key, item]),
              ).values(),
          ]
        : [];

    const finalCampaignsTactics: IDimCampaign[] = getFilteredCampaignTacticsData(
        dataForTable || [],
        search,
        filter,
        filterChipsValue,
    );

    const handleSelectAllClick = (event: React.ChangeEvent<HTMLInputElement>) => {
        if (event.target.checked) {
            const newSelected = finalCampaignsTactics?.map((n) => n.campaignId);
            if (newSelected) {
                setSelectedRows(newSelected);
            }
            return;
        }
        setSelectedRows([]);
        setUniversalTactic(null);
    };

    const saveChangesHandler = () => {
        handleSaveCampaignTactics(false);
        confirmNavigation();
    };

    return (
        <Stack gap="20px" sx={{ marginTop: 3 }}>
            {isFetching || isCompanyCampaignFetching || !campaignsTactics ? (
                <Loader />
            ) : (
                <Fragment>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <h2 style={{ margin: 0, color: "inherit", fontSize: "28px" }}>Tactics</h2>
                        <Button
                            variant="contained"
                            color="primary"
                            className={useStylesForSubHeader(useTheme()).actionButton}
                            onClick={() => handleSaveCampaignTactics(false)}
                            disabled={isTacticSaving || updatedCampaignsTactics.length === 0}
                        >
                            Save
                        </Button>
                    </Box>
                    <Stack>
                        <SubHeader
                            onSearchChange={handleSearchChange}
                            title="campaign(s)"
                            chosenItemsLength={0}
                            chipsValues={filterChipsValue}
                            itemsLength={finalCampaignsTactics?.length || 0}
                            isEventPerform={false}
                            multiple={true}
                            onChipsChange={handleChangeFilterValue}
                        />
                        <CampaignTacticsTable
                            campaignsTactics={finalCampaignsTactics}
                            tacticsType={tacticsType}
                            onTacticSelectionChange={onTacticSelectionChange}
                            handleSaveCampaignTactics={handleSaveCampaignTactics}
                            universalTactic={universalTactic}
                            tacticChangeHandler={tacticChangeHandler}
                            onTacticRowClick={onTacticRowClick}
                            selectedRows={selectedRows}
                            isTacticSaving={isTacticSaving}
                            handleSelectAllClick={handleSelectAllClick}
                        />
                    </Stack>
                </Fragment>
            )}
            <SaveChangesWarningModal
                isOpen={showPrompt}
                message="You have unsaved changes. Are you sure you want to navigate away from this page?"
                onConfirm={confirmNavigation}
                onCancel={cancelNavigation}
                onSaveChanges={saveChangesHandler}
            />
        </Stack>
    );
};
