import { createAction } from "@reduxjs/toolkit";

export const crudPerfomanceView = createAction(
    "crudPerfomanceView",
    (data: { page: string; eventName: string; viewName: string }) => ({
        payload: data,
    }),
);

export const exportPerformanceTable = createAction(
    "exportPerformanceTable",
    (data: { page: string; allColumns: string }) => ({
        payload: data,
    }),
);
