import React, { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { FormikHelpers } from "formik";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";

import { Message } from "../../components/Message/Message";
import { MembersTable } from "../../components/MemberComponents/MembersTable/MembersTable";
import { SubHeader, useStylesForSubHeader } from "../../components/SubHeader/SubHeader.v2";
import { MembersModalForm } from "../../components/MembersModalForm/MembersModalForm";
import { getChipsValues, getSearchedAndFilteredMembers, isSelect } from "../../services/members/members";
import { membersSelector } from "../../reduxState/slices/membersSlice";
import {
    useCreateMemberWithOrgMutation,
    useDeleteMemberRoleMutation,
    useEditMemberMutation,
    useLazyGetMembersQuery,
    useReinviteMemberMutation,
} from "../../reduxState/apis/memberApi";
import { selectCurrentlyViewingCompanyById, selectCurrentlyViewingId } from "../../reduxState/slices/organizationSlice";
import { UserRole } from "../../enums/UserRole";
import { IMember } from "../../interfaces/entities/IMember";
import noMembers from "../../assets/NoMembers.png";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import Loader from "../../components/core/Loader/Loader";
import {
    currentUserRoleByCompanyIdSelector,
    isSuperAdminSelector,
    userIdSelector,
} from "../../reduxState/slices/userSlice";
import { addMemberEvent, deleteMemberEvent, updateMemberEvent } from "./memberEvents";
import { capitalizeFirstLetter, getPathForAnalytics } from "../../services/utils";
import { demoModeSelector } from "../../reduxState/slices/demoModeSlice";
import { ReInviteMemberModal } from "./ReInviteMemberModal";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { MemberDeleteModal } from "../../components/MemberComponents/MemberDeleteModal/MemberDeleteModal";
import { Box, Button } from "@mui/material";
import { useTheme } from "@mui/system";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";

export const Members: React.FC = () => {
    const [filter, setFilter] = useState<number>(0);
    const [search, setSearch] = useState<string>("");
    const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
    const [isOpen, setIsOpen] = useState<boolean>(false);
    const [member, setMember] = useState<IMember | null>(null);
    const [selectedMembers, setSelectedMembers] = useState<Array<number | string>>([]);
    const members = useSelector(membersSelector);
    const [getMembers, { isLoading }] = useLazyGetMembersQuery();
    const currentOrgId = useSelector(selectCurrentlyViewingId);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [deleteMember, { error: deleteError, isLoading: deleteLoading }] = useDeleteMemberRoleMutation();
    const [createMember, { error: createError }] = useCreateMemberWithOrgMutation();
    const [editMember, { error: editError }] = useEditMemberMutation();
    const analyticsService = useAnalyticsService();
    const userRole = useSelector(currentUserRoleByCompanyIdSelector);
    const currentUserId = useSelector(userIdSelector);
    const { enqueueSnackbar } = useSnackbar();
    const isDemoMode = useSelector(demoModeSelector);
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    const [statusModalOpen, setStatusModalOpen] = useState(false);
    const [reinviteMember] = useReinviteMemberMutation();
    const [reinviteBtnDisable, setReinviteBtnDisable] = useState(false);
    const currentCompany: IOrganization = useSelector(selectCurrentlyViewingCompanyById);

    const isMember = userRole === UserRole.MEMBER && !isSuperAdmin;

    useEffect(() => {
        analyticsService.logEvent("Members Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    useEffect(() => {
        getMembers({ orgId: currentOrgId, include: "companies,users_roles" });
    }, [currentOrgId]);

    const handleChangeFilterValue = (filterValue: number) => {
        setFilter(filterValue);
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const handleCloseModal = () => {
        setIsOpen(false);
        setIsFormEditMode(false);
    };

    const handleTableRowClick = (member: IMember) => {
        handleChangeMode();

        if (members) {
            const foundedMember: IMember[] = members.filter((item: IMember) => item.id === member.id);

            if (foundedMember) {
                setMember(foundedMember[0]);
            }
        }
    };

    const handleChangeMode = () => {
        setIsFormEditMode(true);
        setIsOpen(true);
    };

    const handleSubmit = async (values: IMember, formikHelpers: FormikHelpers<IMember>) => {
        if (!values.id) {
            const addMember = addMemberEvent(values);
            analyticsService.logEvent("Member Invited", addMember);
            await createMemberAction(values, formikHelpers);
        } else {
            const finalKeys = updateMemberEvent(values, member, currentOrgId);
            analyticsService.logEvent("Member Edited", finalKeys);
            await editMemberAction(values, formikHelpers);
        }
    };

    const editMemberAction = async (values: any, formikHelpers: FormikHelpers<IMember>) => {
        await editMember({
            params: { id: +values.id, orgId: currentOrgId },
            body: {
                ...values,
            },
        })
            .unwrap()
            .then(() => getMembers({ orgId: currentOrgId, include: "companies,users_roles" }))
            .catch((error) => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
            })
            .finally(() => {
                setIsFormEditMode(false);
                setIsOpen(false);
                formikHelpers.setSubmitting(false);
            });
    };

    const createMemberAction = async (values: any, formikHelpers: FormikHelpers<IMember>) => {
        await createMember({
            body: {
                ...values,
            },
            orgId: currentOrgId,
        })
            .unwrap()
            .then(() => {
                getMembers({ orgId: currentOrgId, include: "companies,users_roles" });
                setIsOpen(false);
            })
            .catch((error) => {
                const errors = transformApiErrors({ message: "Error" });
                formikHelpers.setErrors(errors);
                if (error.status === 422) {
                    formikHelpers.setErrors({
                        ...errors,
                        email: `Oops! ${values.email} is already a member of your organization`,
                    });
                    setIsOpen(true);
                } else {
                    setIsOpen(false);
                }
            })
            .finally(() => {
                setIsFormEditMode(false);
                formikHelpers.setSubmitting(false);
            });
    };

    const handleAnalyticsClick = () => {
        if (currentOrgId) {
            analyticsService.logEvent("Member Invitation Began", {
                "Current organization ID": currentOrgId,
            });
        }
    };

    const handleOpenNewMemberModal = useCallback(() => {
        handleAnalyticsClick();
        setMember({
            avatar: "",
            avatarName: "",
            email: "",
            firstName: "",
            id: "",
            lastName: "",
            role: UserRole.MEMBER,
        });
        setIsOpen(true);
    }, []);

    const closeModal = () => {
        setIsOpen(false);
        setIsDeleteModalOpen(false);
        setMember(null);
    };

    const handleDeleteTableAction = (member: IMember) => {
        setMember(member);
        setIsDeleteModalOpen(true);
    };

    const handleStatusClick = (member: IMember) => {
        if (member && member.invitationState) {
            setMember(member);
            analyticsService.logEvent("Member Reinvitation Began", {
                Page: getPathForAnalytics(location.pathname),
                "Member status": capitalizeFirstLetter(member.invitationState),
            });
            setStatusModalOpen(true);
        }
    };

    const handleModalReInvite = () => {
        if (member) {
            setReinviteBtnDisable(true);
            reinviteMember({
                orgId: currentOrgId,
                userId: member.id,
            })
                .then(() => {
                    if (member.invitationState) {
                        analyticsService.logEvent("Member Reinvited", {
                            Page: getPathForAnalytics(location.pathname),
                            "Member status": capitalizeFirstLetter(member.invitationState),
                        });
                    }
                    getMembers({ orgId: currentOrgId, include: "companies,users_roles" });
                })
                .catch((error: any) => {
                    enqueueSnackbar("Error", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`reinvite member error ${error}`);
                })
                .finally(() => {
                    setStatusModalOpen(false);
                    setReinviteBtnDisable(false);
                });
        }
    };

    const handleModalClose = () => {
        if (member && member.invitationState) {
            analyticsService.logEvent("Member Reinvitation Cancelled", {
                Page: getPathForAnalytics(location.pathname),
                "Member status": capitalizeFirstLetter(member.invitationState),
            });
            setStatusModalOpen(false);
        }
    };

    const handleRemoveMember = async (id: string, role: string) => {
        const deleteMemberData = deleteMemberEvent(role);
        analyticsService.logEvent("Member Removed", deleteMemberData);
        await deleteMember({
            id,
            orgId: currentOrgId,
        })
            .unwrap()
            .then(() => {
                getMembers({ orgId: currentOrgId, include: "companies,users_roles" });
            })
            .catch((error) => {
                enqueueSnackbar("Error", {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                setIsOpen(false);
                setIsFormEditMode(false);
            });
    };

    const deleteSingleMember = async (member: IMember) => {
        const role = member.rolesByCompanyId && member.rolesByCompanyId[currentOrgId];
        if (!role) {
            return;
        }
        await handleRemoveMember(role.id, role.role);
        closeModal();
    };

    const getPreparedMembers = () => {
        const newMembers: IMember[] = getSearchedAndFilteredMembers(members, search, filter, currentOrgId);
        if (newMembers && newMembers.length) {
            return newMembers.map((member: IMember) => {
                return {
                    ...member,
                    isChecked: isSelect(+member.id, selectedMembers),
                };
            });
        }

        return [];
    };

    return (
        <div>
            {isLoading ? (
                <Loader />
            ) : (
                <div>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <h2>Members</h2>
                        {!isMember && (
                            <Button
                                variant="contained"
                                color="primary"
                                className={useStylesForSubHeader(useTheme()).actionButton}
                                startIcon={
                                    <SVGIconRenderer
                                        icon={"plusIcon"}
                                        strokeColor={isDemoMode ? "rgba(0, 0, 0, 0.26)" : "white"}
                                    />
                                }
                                onClick={handleOpenNewMemberModal}
                                disabled={isDemoMode}
                                data-cy="addMember"
                            >
                                {isSuperAdmin || userRole !== UserRole.MEMBER ? "Add Member" : ""}
                            </Button>
                        )}
                    </Box>
                    <SubHeader
                        chosenItemsLength={selectedMembers.length}
                        itemsLength={getSearchedAndFilteredMembers(members, search, filter, currentOrgId).length}
                        chipsValues={getChipsValues(members, currentOrgId)}
                        title="Member(s)"
                        onSearchChange={handleSearchChange}
                        onChipsChangeSingle={handleChangeFilterValue}
                        multiple={false}
                        isEventPerform={false}
                    />
                    {!getSearchedAndFilteredMembers(members, search, filter, currentOrgId).length ? (
                        isLoading ? (
                            <Loader />
                        ) : (
                            <Message
                                title="We didn’t find anybody with that name 😔"
                                subtitle="Please make sure you spelled their name correctly or try a different name."
                                icon={noMembers}
                            />
                        )
                    ) : (
                        <MembersTable
                            members={getPreparedMembers()}
                            member={member}
                            onDeleteMember={handleDeleteTableAction}
                            onEditMember={handleTableRowClick}
                            onStatusClick={handleStatusClick}
                        />
                    )}
                </div>
            )}
            {isOpen && (
                <MembersModalForm
                    currentCompany={currentCompany}
                    isOpen={isOpen}
                    member={member}
                    onCloseModal={handleCloseModal}
                    isFormEditMode={isFormEditMode}
                    onDelete={deleteSingleMember}
                    onSubmit={handleSubmit}
                    deleteDisableBtn={deleteLoading}
                />
            )}
            {isDeleteModalOpen && member && (
                <MemberDeleteModal
                    member={member}
                    onDelete={deleteSingleMember}
                    closeModal={closeModal}
                    currentCompany={currentCompany}
                    disableBtn={deleteLoading}
                />
            )}
            {statusModalOpen && member && (
                <ReInviteMemberModal
                    isOpen={statusModalOpen}
                    onClose={handleModalClose}
                    onSubmit={handleModalReInvite}
                    handleCancelButtonClick={handleModalClose}
                    member={member}
                    reinviteBtnDisable={reinviteBtnDisable}
                />
            )}
        </div>
    );
};
