import * as React from "react";
import { createRoot } from "react-dom/client";
import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Bugsnag from "@bugsnag/js";
import BugsnagPluginReact from "@bugsnag/plugin-react";
import "whatwg-fetch";
import { Provider as ReduxProvider } from "react-redux";
import CssBaseline from "@mui/material/CssBaseline";
import "vanilla-cookieconsent/dist/cookieconsent.css";

import { bugsnagApiKey, appVersion } from "./utils/env";

import { App } from "./containers/App";

import "./index.css";
import * as serviceWorker from "./serviceWorker";
import { store, persistor } from "./reduxState/stores/store";
import { PersistGate } from "redux-persist/integration/react";
import { analyticsManagerService } from "./services/analytics/analyticsManager";
import { AnalyticsContext } from "./services/analytics/analyticsContext";
import stateValueObservers from "./services/analytics/valueObservers";
import actionEventListeners from "./services/analytics/actionEventListeners";
import withAnalytics from "./components/withAnalytics";

// Add bug snag error boundary
Bugsnag.start({
    apiKey: bugsnagApiKey,
    appVersion,
    plugins: [new BugsnagPluginReact()],
    onError: (event) => {
        const currentUser = store.getState().currentUser;
        if (currentUser) {
            event.setUser(currentUser.id, currentUser.email, currentUser.firstName);
        }
    },
});

// Attach Analytic subscribers
const SubscribedApp = withAnalytics(
    stateValueObservers(analyticsManagerService),
    actionEventListeners(analyticsManagerService),
)(App);

const BugSnagErrorBoundary = Bugsnag.getPlugin("react")!.createErrorBoundary(React);

const container = document.getElementById("root");
const root = createRoot(container!);

const mainRouter = createBrowserRouter([
    {
        path: "/*",
        element: (
            <ReduxProvider store={store}>
                <AnalyticsContext.Provider value={analyticsManagerService}>
                    <PersistGate loading={null} persistor={persistor}>
                        <SubscribedApp />
                    </PersistGate>
                </AnalyticsContext.Provider>
            </ReduxProvider>
        ),
    },
]);

root.render(
    <BugSnagErrorBoundary>
        <CssBaseline />
        <RouterProvider router={mainRouter} />
    </BugSnagErrorBoundary>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
