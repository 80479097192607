import React, { FC } from "react";
import { css } from "@emotion/css";
import { Theme, useTheme } from "@mui/system";

import SVGLIST from "./svgIcons";
import { IDictionary } from "../../interfaces/IDictionary";

interface ISVGRenderer {
    icon: string;
    strokeColor?: string;
    className?: any;
    height?: string;
    width?: string;
    style?: any;
    title?: string;
    onClick?: (args?: any) => any;
}

const getStrokeColor = (theme: Theme, strokeColor: string) => {
    const defaultColors: IDictionary = {
        error: theme.palette.error.main,
        success: theme.palette.success.main,
        primary: theme.palette.primary.main,
        secondary: theme.palette.secondary.main,
    };
    return defaultColors[strokeColor] ? defaultColors[strokeColor] : strokeColor;
};

const useStyles = ({ strokeColor, theme }: { strokeColor: string; theme: Theme }) => ({
    icon: css({
        cursor: "pointer",
        "& path": {
            stroke: getStrokeColor(theme, strokeColor),
        },
    }),
});

export const SVGIconRenderer: FC<ISVGRenderer> = ({
    icon,
    strokeColor = "black",
    className = "",
    onClick,
    ...rest
}) => {
    const classes = useStyles({ strokeColor, theme: useTheme() });
    const IconToBeRender = SVGLIST[icon];

    return <IconToBeRender className={`${classes.icon} ${className}`} onClick={onClick} {...rest} />;
};
