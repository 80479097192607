export enum ConnectorStatus {
    INCOMPLETE = "incomplete",
    BROKEN = "broken",
    HISTORICAL_SYNCING = "historical_syncing",
    SYNCING = "syncing",
    CONNECTED = "active",
    PAUSED = "paused",
}

export interface IConnector {
    id: number | string;
    dataSource?: any;
    dataSourceId?: number;
    service: string;
    schema: string;
    table: string;
    fivetranId: number | string;
    fivetranToken: string;
    createdAt: string;
    status: ConnectorStatus;
    connectorName?: string;
    categories: string[];
}
