import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { IconButton, List, ListItem, ListItemButton } from "@mui/material";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";

import {
    OrganizationsListedByOrderSelector,
    selectCurrentlyViewingCompanyById,
    setCurrentlyViewing,
} from "../../../reduxState/slices/organizationSlice";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { IOrganization } from "../../../interfaces/entities/IOrganization";
import { getComparator, stableSort } from "../../../utils/sort";
import { getPathForAnalytics } from "../../../services/utils";
import { BLACK_COLOR, HEADER_ACTIVE_LINK_COLOR } from "../../../consts/colors";
import { SVGIconRenderer } from "../../../components/SVGIconRenderer/SVGIconRenderer";
import { getDefaultRedirectPath } from "src/consts/path/path";
import CustomAvatar from "src/components/CustomAvatar/CustomAvatar";

const useStyles = () => ({
    orgSelectionContent: css({
        padding: "30px 20px 30px 30px",
        marginRight: "30px",
        "&::-webkit-scrollbar": {
            webkitAppearance: "none",
            width: "8px",
        },
        "&::-webkit-scrollbar-track": {
            backgroundColor: "transparent",
        },
        "&::-webkit-scrollbar-thumb": {
            borderRadius: "4px",
            backgroundColor: "#C5C9CD",
        },
    }),
    dialogTitleClass: css({
        display: "flex",
        justifyContent: "space-between",
        "& p": { fontWeight: "bold", fontSize: "18px" },
        "& .MuiSvgIcon-root": { color: BLACK_COLOR, cursor: "pointer" },
    }),
});

const orgDropdownItem = ({
    company,
    currentOrganization,
}: {
    company: IOrganization;
    currentOrganization?: IOrganization;
}) => {
    if (!company) {
        return null;
    }
    const avatarText = company.displayName ? company.displayName[0] : company.name[0];
    const style =
        currentOrganization?.id === company.id
            ? {
                  border: `1px solid ${HEADER_ACTIVE_LINK_COLOR}`,
                  background: "#F8FFFC",
                  width: "100%",
                  borderRadius: "5px",
                  padding: "10px",
              }
            : {
                  padding: currentOrganization ? "10px" : 0,
              };

    return (
        <Stack direction="row" alignItems="center" gap={2} sx={style}>
            <CustomAvatar avatarUrl={company.avatarMediumUrl || ""} avatarText={avatarText} />
            <Typography fontSize="15px">{company.displayName || company.name}</Typography>
            {currentOrganization?.id === company.id && (
                <SVGIconRenderer icon="checkIcon" strokeColor="primary" style={{ marginLeft: "auto" }} />
            )}
        </Stack>
    );
};

const OrganizationSelection = () => {
    const [openOrgSelectionDialog, setOpenOrgSelectionDialog] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const orgClasses = useStyles();

    const analyticsService = useAnalyticsService();
    const currentOrganization: IOrganization = useSelector(selectCurrentlyViewingCompanyById);
    const organizations: IOrganization[] = useSelector(OrganizationsListedByOrderSelector);
    const sortedOrganization = stableSort(organizations, getComparator("asc", "name"));

    const handleOrgSwitchBegin = () => {
        analyticsService.logEvent("Organization Change Began", {
            Page: getPathForAnalytics(location.pathname),
            "Current organization ID": currentOrganization.id,
        });
    };

    const handleOrgSelectInDialog = () => {
        handleOrgSwitchBegin();
        setOpenOrgSelectionDialog(true);
    };

    const closeOrgSelectionDialog = () => {
        setOpenOrgSelectionDialog(false);
    };

    const handleOrgSwitch = (org: IOrganization) => {
        analyticsService.logEvent("Organization Changed", {
            Page: getPathForAnalytics(location.pathname),
            "Current organization ID": currentOrganization.id,
            "Destination organization ID": org.id,
        });
        const orgCode = org.code;
        dispatch(setCurrentlyViewing(orgCode));
        navigate(`/org/${orgCode}${getDefaultRedirectPath(org)}`);
    };

    const organizationItems = currentOrganization
        ? sortedOrganization.map((item: any) => {
              return {
                  key: item.id,
                  label: orgDropdownItem({ company: item, currentOrganization }),
                  onClick: () => {
                      handleOrgSwitch(item);
                      closeOrgSelectionDialog();
                  },
              };
          })
        : [];

    const getCurrentOrgAvatar = () => {
        if (!currentOrganization || !organizations.length) { return null; }

        const isOneOrg = organizations.length === 1;
        const handleClick = isOneOrg ? () => {} : handleOrgSelectInDialog;
        const displayName = currentOrganization.displayName || currentOrganization.name;
        const avatarText = displayName[0];

        return (
            <IconButton
                sx={{ padding: 0, cursor: isOneOrg ? "auto" : "pointer" }}
                title={displayName}
                disableRipple={isOneOrg}
                onClick={handleClick}
            >
                <CustomAvatar avatarUrl={currentOrganization.avatarMediumUrl} avatarText={avatarText} />
            </IconButton>
        );
    };

    return (
        <div>
            {getCurrentOrgAvatar()}
            <Dialog
                maxWidth="xs"
                fullWidth={true}
                onClose={closeOrgSelectionDialog}
                aria-labelledby="customized-dialog-title"
                open={openOrgSelectionDialog}
            >
                <DialogTitle className={orgClasses.dialogTitleClass}>
                    <Typography>Choose Company</Typography>
                    <SVGIconRenderer icon="closeIcon" onClick={closeOrgSelectionDialog} />{" "}
                </DialogTitle>
                <DialogContent className={orgClasses.orgSelectionContent}>
                    <List>
                        {organizationItems.map((item) => (
                            <ListItem key={item.key} disablePadding>
                                <ListItemButton sx={{ padding: 0 }} onClick={item.onClick}>
                                    {item.label}
                                </ListItemButton>
                            </ListItem>
                        ))}
                    </List>
                </DialogContent>
            </Dialog>
        </div>
    );
};

export default OrganizationSelection;
