import React, { FC, useCallback, useEffect, useState } from "react";
import { FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { useSelector } from "react-redux";
import uniqueId from "lodash/uniqueId";

import { DeleteModal } from "../../components/core/DeleteModal/DeleteModal";
import Loader from "../../components/core/Loader/Loader";
import { FeatureModalForm } from "../../components/FeaturesComponent/FeaturesModalFrom/FeaturesModalFrom";
import { FeaturesTable } from "../../components/FeaturesComponent/FeaturesTable/FeaturesTable";
import { IFeature } from "../../interfaces/entities/IFeature";
import {
    useCreateFeatureMutation,
    useDeleteFeatureMutation,
    useEditFeatureMutation,
    useLazyGetFeaturesQuery,
} from "../../reduxState/apis/featureApi";
import { listFeaturesSelector } from "../../reduxState/slices/featuresSlice";
import { transformApiErrors } from "../../utils/transformApiErrors";
import { getFilteredFeatureList } from "../../services/features/features";
import { selectListOfOrganizations } from "../../reduxState/slices/organizationSlice";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { getComparator, stableSort } from "../../utils/sort";
import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { SubHeader } from "../../components/SubHeader/SubHeader.v2";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { demoModeSelector } from "../../reduxState/slices/demoModeSlice";

export const Features: FC = () => {
    const { enqueueSnackbar } = useSnackbar();

    const [getFeatures, { data, isLoading }] = useLazyGetFeaturesQuery();
    const featuresList = useSelector(listFeaturesSelector);
    const isDemoMode = useSelector(demoModeSelector);

    const [isCreateOrEditModalOpen, setCreateOrEditModalOpen] = useState<boolean>(false);
    const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [feature, setFeature] = useState<IFeature | null>(null);
    const [search, setSearch] = useState<string>("");

    const [createFeature] = useCreateFeatureMutation();
    const [editFeature] = useEditFeatureMutation();
    const [deleteFeature] = useDeleteFeatureMutation();

    const companiesById: { [key: string]: IOrganization } = useSelector(selectListOfOrganizations);
    const companies: IOrganization[] = stableSort(
        Object.values(companiesById),
        getComparator("asc", "name"),
    ) as IOrganization[];

    useEffect(() => {
        getFeatures({});
    }, []);

    const openCreateModal = useCallback(() => {
        setFeature({
            id: "",
            displayName: "",
            cleanName: "",
            description: "",
            companyInfo: [],
            companies: [],
        });
        setCreateOrEditModalOpen(true);
    }, []);

    const openEditModal = (feature: IFeature) => {
        setFeature(feature);
        setIsFormEditMode(true);
        setCreateOrEditModalOpen(true);
    };

    const openDeleteModal = (feature: IFeature) => {
        setFeature(feature);
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleCloseModal = () => {
        setCreateOrEditModalOpen(false);
        setIsDeleteModalOpen(false);
        setIsFormEditMode(false);
        setFeature(null);
    };

    const createFeatureAction = (values: any) => {
        return createFeature({
            body: {
                ...values,
            },
        });
    };

    const editFeatureAction = (values: any) => {
        if (feature) {
            return editFeature({
                params: { id: +values.id },
                body: {
                    ...values,
                    cleanName: feature.cleanName,
                },
            });
        }
    };

    const handleCreateOrEditSubmit = async (values: IFeature, formikHelpers: FormikHelpers<IFeature>) => {
        let apiResponse: any;
        if (!values.id) {
            apiResponse = createFeatureAction(values);
        } else {
            apiResponse = editFeatureAction(values);
        }
        await apiResponse
            .unwrap()
            .then(() => {
                getFeatures({});
                handleCloseModal();
            })
            .catch((error: { status: number; data: any }) => {
                const errors = transformApiErrors({ message: "Error" });
                if (error.status === 422) {
                    const { errors: apiError } = error.data;
                    formikHelpers.setErrors({
                        ...errors,
                        displayName: `${apiError[0].detail}.`,
                    });
                    setCreateOrEditModalOpen(true);
                } else {
                    setCreateOrEditModalOpen(false);
                }
                throw new Error(`feature error ${error}`);
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
            });
    };

    const deleteFeatureAction = async (feature: IFeature[]) => {
        if (feature[0].id) {
            await deleteFeature(feature[0].id)
                .unwrap()
                .then(() => {
                    handleCloseModal();
                    getFeatures({});
                })
                .catch((error) => {
                    enqueueSnackbar("Error", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`delete feature error ${error}`);
                });
        }
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const updatedFilterList: IFeature[] = getFilteredFeatureList(featuresList, search, companies);

    return (
        <div>
            <PageHeader pageHeading="Features" buttonName="Add Feature" onAdd={openCreateModal} disabled={isDemoMode} />
            <PageContainer>
                {isLoading ? (
                    <Loader />
                ) : (
                    <>
                        <SubHeader
                            itemsLength={updatedFilterList.length}
                            title="Feature(s)"
                            onSearchChange={handleSearchChange}
                        />
                        <FeaturesTable
                            features={updatedFilterList}
                            onEditFeature={openEditModal}
                            onDeleteFeature={openDeleteModal}
                        />
                    </>
                )}
            </PageContainer>
            {isCreateOrEditModalOpen && (
                <FeatureModalForm
                    isOpen={isCreateOrEditModalOpen}
                    feature={feature}
                    onCloseModal={handleCloseModal}
                    isFormEditMode={isFormEditMode}
                    onSubmit={handleCreateOrEditSubmit}
                    companyOptions={companies}
                />
            )}

            {isDeleteModalOpen && (
                <DeleteModal
                    actionName="remove"
                    header="feature"
                    handleDelete={deleteFeatureAction}
                    items={[feature]}
                    closeModal={handleCloseModal}
                    title="feature"
                />
            )}
        </div>
    );
};
