import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "../stores/store";

const initialState: any = [];

const campaignsSlice = createSlice({
    name: "campaigns",
    initialState,
    reducers: {
        setCampaign: (state, { payload }) => payload,
    },
});

export const { setCampaign } = campaignsSlice.actions;

export default campaignsSlice.reducer;

// Selectors
export const campaignsSelector = (state: RootState) => state.campaigns;
