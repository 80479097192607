import { css } from "@emotion/css";
import {
    Autocomplete,
    FormControl,
    FormControlLabel,
    FormHelperText,
    IconButton,
    InputLabel,
    MenuItem,
    Select,
    Switch,
    TextField,
    Typography,
} from "@mui/material";
import { Stack } from "@mui/system";
import { FieldArray, Form, Formik, FormikConfig, FormikProps } from "formik";
import * as React from "react";
import { object, string } from "yup";

import { imageInputClass } from "../../assets/styles/commonStyle";
import { BLACK_COLOR } from "../../consts/colors";
import { MemberOrganization } from "../../containers/SuperAdminMembers/MemberOrganization/MemberOrganization";
import { UserRole } from "../../enums/UserRole";
import { IMember } from "../../interfaces/entities/IMember";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { changeDate } from "../../services/utils";
import useAvatar from "../MemberCard/useAvatar";
import { SVGIconRenderer } from "../SVGIconRenderer/SVGIconRenderer";
import { Button } from "../core/Button/Button";
import { Field } from "../core/Field/Field";
import { Flex, FlexDirection } from "../core/Flex/Flex";
import { FlexItem } from "../core/FlexItem/FlexItem";
import { ImageInput } from "../core/ImageInput/ImageInput";

const formFieldClass = css`
    margin-top: 15px !important;
`;

const avatarFieldClass = css`
    margin-top: 30px;
    a {
        text-decoration: none;
        border-radius: 50%;
        background: white;
    }
`;

export const labelClass = css`
    font-size: 12px !important;
    letter-spacing: 0.4px !important;
    color: ${BLACK_COLOR}!important;
    margin-bottom: 8px !important;
`;

const caption = css`
    font-size: 12px !important;
    line-height: 16px !important;
    letter-spacing: 0.4px !important;
    margin-top: 5px;
`;

export interface IOrgRoleFieldItem {
    company: IOrganization | null;
    role: string;
}

export interface IOrgRoleFieldItems {
    roles: IOrgRoleFieldItem[];
}

export interface IMemberFormProps extends FormikConfig<IMember> {
    isEditMode?: boolean;
    isCurrentUser?: boolean;
    allCompanies: any[];
    selectedCompanies?: any[];
    initialValues: IMember;
    isSuperAdminForm: boolean;
    showSelectedCompanies?: boolean;
    showBackButton?: boolean;

    onCancel?(...args: any[]): any;

    onRemove?(...args: any[]): any;

    onChangeMode?(...args: any[]): any;

    gotToBack?(...args: any[]): any;
}

export class UserForm extends React.Component<IMemberFormProps, {}> {
    public state = {
        image: "",
        imageName: "",
        organizationRole: [{ role: "", company: null }],
    };

    public handleRoleChange(setFieldValue: any) {
        return (role: any) => {
            setFieldValue("role", role);
        };
    }

    public handleCompanyChange(setFieldValue: any) {
        return (company: any) => {
            setFieldValue("company", company);
        };
    }

    public renderSelectItem(item: any, { handleClick }: any) {
        return (
            <MenuItem key={item} onClick={handleClick}>
                {item}
            </MenuItem>
        );
    }

    public renderCompanySelectItem(item: any, { handleClick }: any) {
        return (
            <MenuItem key={item} onClick={handleClick}>
                {item}
            </MenuItem>
        );
    }

    public render() {
        const {
            isEditMode,
            isCurrentUser,
            onCancel,
            onRemove,
            onChangeMode,
            gotToBack,
            isSuperAdminForm,
            selectedCompanies,
            showSelectedCompanies = true,
            showBackButton = false,
            initialValues,
            onSubmit,
            ...rest
        } = this.props;
        const userRoles = [
            { id: UserRole.MEMBER, label: "Member" },
            { id: UserRole.ADMIN, label: "Admin" },
        ];

        const allCompanies = this.props.allCompanies;
        const toBase64 = (file: Blob) =>
            new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.readAsDataURL(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = reject;
            });
        const getFile = async (url: Blob) => {
            return toBase64(url);
        };
        const imageFunction = async (getImage: any) => {
            return getImage(this.state.image);
        };

        const emailValidation = string().email("Invalid email format").required("E-mail is required");

        return (
            <Formik
                validationSchema={object().shape({
                    email: isSuperAdminForm
                        ? emailValidation.matches(
                              /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@(prescient-ai.io|prescientai.com)$/,
                              "Oops! Emails must end in '@prescient-ai.io' or '@prescientai.com' to be made a Super Admin",
                          )
                        : emailValidation,
                    calendarUrl: string().url("Invalid URL format"),
                    firstName: string().required("First name is required"),
                    lastName: string().required("Last name is required"),
                })}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={onSubmit}
                {...rest}
            >
                {({ errors, handleChange, touched, values, isSubmitting, setFieldValue }: FormikProps<IMember>) => {
                    const hasEmailError = Boolean(touched.email && errors.email);
                    const hasCalendarUrlError = Boolean(touched.calendarUrl && errors.calendarUrl);
                    const hasFirstNameError = Boolean(touched.firstName && errors.firstName);
                    const hasLastNameError = Boolean(touched.lastName && errors.lastName);

                    const isExistMember = Boolean(values.id);
                    const disabledInput = isSubmitting || (Boolean(values.id) && !isEditMode);
                    const avatarUrl = useAvatar(values.avatarMediumUrl || "");
                    const { roles } = values;
                    const initialRoles = roles?.length ? roles : [{ company: null, role: "" }];
                    const selectedAllCompanies = roles?.map((c) => c.company);

                    return (
                        <Form>
                            <div className={imageInputClass}>
                                <ImageInput
                                    onChange={() => !disabledInput && handleChange}
                                    initial={avatarUrl}
                                    isDisabled={disabledInput}
                                    onSelectImage={(file, name) => {
                                        this.setState(
                                            {
                                                image: file,
                                                imageName: name,
                                            },
                                            async () => {
                                                setFieldValue("avatar", await imageFunction(getFile));
                                                setFieldValue("avatarName", this.state.imageName);
                                            },
                                        );
                                    }}
                                    isAvatar
                                    name="avatar"
                                />
                            </div>
                            <Field
                                fullWidth={true}
                                id="firstName"
                                name="firstName"
                                label="First name"
                                disabled={disabledInput}
                                error={hasFirstNameError}
                                helperText={hasFirstNameError ? errors.firstName : null}
                                value={values.firstName || ""}
                                onChange={handleChange}
                                placeholder="Enter first name"
                                className={formFieldClass}
                            />
                            <Field
                                fullWidth={true}
                                id="lastName"
                                name="lastName"
                                label="Last name"
                                disabled={disabledInput}
                                error={hasLastNameError}
                                helperText={hasLastNameError ? errors.lastName : null}
                                value={values.lastName || ""}
                                onChange={handleChange}
                                placeholder="Enter last name"
                                className={formFieldClass}
                            />
                            <Field
                                fullWidth={true}
                                id="email"
                                name="email"
                                label="E-mail"
                                disabled={disabledInput}
                                error={hasEmailError}
                                helperText={hasEmailError ? errors.email : null}
                                value={values.email || ""}
                                onChange={handleChange}
                                placeholder="Enter e-mail"
                                className={formFieldClass}
                            />

                            {(isSuperAdminForm || values.isSuperadmin) && (
                                <Field
                                    fullWidth={true}
                                    id="calendarUrl"
                                    name="calendarUrl"
                                    label="Calendar URL"
                                    disabled={disabledInput}
                                    error={hasCalendarUrlError}
                                    helperText={hasCalendarUrlError ? errors.calendarUrl : null}
                                    value={values.calendarUrl || ""}
                                    onChange={handleChange}
                                    placeholder="Enter calendar url"
                                    className={formFieldClass}
                                />
                            )}

                            {(isEditMode || showBackButton) && !isSuperAdminForm && (
                                <FieldArray
                                    name="roles"
                                    render={({ remove, push }) => {
                                        const addEntry = (orgRole: IOrgRoleFieldItem) => {
                                            push({
                                                company: "",
                                                role: "",
                                            });
                                        };

                                        const removeEntry = (orgRole: IOrgRoleFieldItem, index: number) => {
                                            remove(index);
                                        };

                                        return (
                                            <div>
                                                {initialRoles?.map((orgRole: IOrgRoleFieldItem, index: number) => {
                                                    const isLastEntry = initialRoles.length - 1 === index;
                                                    const isDisabled = !orgRole.role || !orgRole.company;
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <Stack
                                                                flexDirection="row"
                                                                gap="10px"
                                                                alignItems="center"
                                                                sx={{ marginTop: "15px", width: "100%" }}
                                                            >
                                                                <Autocomplete
                                                                    options={allCompanies}
                                                                    renderInput={(params) => (
                                                                        <TextField {...params} label="Organizations" />
                                                                    )}
                                                                    getOptionLabel={(option) =>
                                                                        option.displayName || option.name || ""
                                                                    }
                                                                    filterSelectedOptions
                                                                    onChange={(e, newValue) => {
                                                                        if (newValue) {
                                                                            setFieldValue(
                                                                                `roles.${index}.company`,
                                                                                newValue,
                                                                            );
                                                                        }
                                                                    }}
                                                                    sx={{ flexGrow: "1" }}
                                                                    disableClearable
                                                                    value={orgRole.company || null}
                                                                />
                                                                <FormControl sx={{ width: "110px" }}>
                                                                    <InputLabel id="select-type-label">Role</InputLabel>
                                                                    <Select
                                                                        id={`roles.${index}.role`}
                                                                        value={orgRole.role}
                                                                        name={`roles.${index}.role`}
                                                                        onChange={handleChange}
                                                                        label="Role"
                                                                    >
                                                                        {userRoles.map((role) => (
                                                                            <MenuItem key={role.id} value={role.id}>
                                                                                {role.label}
                                                                            </MenuItem>
                                                                        ))}
                                                                    </Select>
                                                                </FormControl>
                                                                <div className="btn-box">
                                                                    <IconButton
                                                                        onClick={() => removeEntry(orgRole, index)}
                                                                    >
                                                                        <SVGIconRenderer
                                                                            icon="closeIcon"
                                                                            strokeColor="rgba(0, 0, 0, 0.54)"
                                                                        />
                                                                    </IconButton>
                                                                </div>
                                                            </Stack>
                                                            {isLastEntry && (
                                                                <Button
                                                                    onClick={() => addEntry(orgRole)}
                                                                    disabled={isDisabled}
                                                                    variant="text"
                                                                    sx={{
                                                                        textTransform: "inherit",
                                                                        paddingLeft: 0,
                                                                    }}
                                                                >
                                                                    Add new company role
                                                                </Button>
                                                            )}
                                                        </React.Fragment>
                                                    );
                                                })}
                                            </div>
                                        );
                                    }}
                                />
                            )}
                            {errors.roles && (
                                <FormHelperText
                                    sx={{
                                        color: "#F44336",
                                    }}
                                >
                                    Minimum 1 organization role is required
                                </FormHelperText>
                            )}
                            {isExistMember && (
                                <Typography className={caption}>Created: {changeDate(values.createdAt)}</Typography>
                            )}
                            {isExistMember && (
                                <>
                                    {initialValues.email.match(
                                        /^[a-zA-Z0-9.! #$%&'*+/=? ^_`{|}~-]+@prescient-ai.io$/,
                                    ) && (
                                        <FormControlLabel
                                            className={formFieldClass}
                                            control={
                                                <Switch
                                                    name="isSuperadmin"
                                                    checked={values.isSuperadmin}
                                                    disabled={disabledInput}
                                                    onChange={() => setFieldValue("isSuperadmin", !values.isSuperadmin)}
                                                />
                                            }
                                            label="Super Admin"
                                        />
                                    )}
                                    {!isEditMode && (
                                        <Flex direction={FlexDirection.Column} className={avatarFieldClass}>
                                            <FlexItem className={labelClass}>Organizations</FlexItem>
                                            <FlexItem>
                                                <MemberOrganization
                                                    member={values}
                                                    memberCompanies={selectedCompanies}
                                                    isForm={true}
                                                />
                                            </FlexItem>
                                        </Flex>
                                    )}
                                </>
                            )}
                            <Stack
                                direction={FlexDirection.Row}
                                justifyContent={showBackButton ? "space-between" : ""}
                                gap="15px"
                                sx={{ marginTop: "40px", width: "100%" }}
                            >
                                {showBackButton && (
                                    <Button
                                        variant="text"
                                        type="button"
                                        onClick={gotToBack}
                                        startIcon={<SVGIconRenderer icon="arrowLeftIcon" strokeColor="primary" />}
                                    >
                                        Back
                                    </Button>
                                )}
                                {!isExistMember && (
                                    <>
                                        <Button disabled={isSubmitting} type="submit">
                                            Add
                                        </Button>
                                    </>
                                )}
                                {!isEditMode && isExistMember && (
                                    <Button disabled={isSubmitting} onClick={onChangeMode} type="button">
                                        Edit
                                    </Button>
                                )}
                                {isEditMode && isExistMember && (
                                    <Button disabled={isSubmitting} type="submit">
                                        Save
                                    </Button>
                                )}
                                {isExistMember && !isSubmitting && isEditMode && (
                                    <FlexItem>
                                        <Button
                                            disabled={isSubmitting || isCurrentUser}
                                            onClick={onRemove}
                                            type="button"
                                        >
                                            Delete Member
                                        </Button>
                                    </FlexItem>
                                )}
                            </Stack>
                        </Form>
                    );
                }}
            </Formik>
        );
    }
}
