import { Stack } from "@mui/system";
import { useSelector } from "react-redux";
import { partialStateSelectorForHome } from "src/reduxState/slices/settingsSlice";

export const HomepageCardContainer = ({ children }: { children: React.ReactNode }) => {
    const partialStateForHome = useSelector(partialStateSelectorForHome);

    return (
        <Stack
            sx={{
                padding: "16px 24px 24px 24px",
                background: "#FFF",
                borderRadius: "4px",
                border: "1px solid #E0E0E0",
            }}
            gap={partialStateForHome ? 0 : 3}
        >
            {children}
        </Stack>
    );
};
