import React from "react";
import { Stack } from "@mui/system";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import { useNavigate } from "react-router-dom";
import { format, parseISO } from "date-fns";
import { css } from "@emotion/css";
import { useSelector } from "react-redux";

import { IOrganization } from "../../../interfaces/entities/IOrganization";
import { demoModeSelector } from "../../../reduxState/slices/demoModeSlice";
import { useStylesForRowInTable } from "../../../consts/StylesForTables";
import { cursor } from "../../../assets/styles/commonStyle";
import CustomAvatar from "src/components/CustomAvatar/CustomAvatar";
import { OrganizationState } from "../../../enums/OrganizationState";
import { SubscriptionStatus } from "../../../enums/SubscriptionStatus";
import { TableActionCell } from "../../../components/core/TableActionCell/TableActionCell";
import { getDefaultRedirectPath } from "src/consts/path/path";
import { handleClickWithTextSelectionCheck } from "src/services/utils";

interface IOrganizationRow {
    labelId: string;
    row: any;
    deleteClick: (e: React.MouseEvent<any>, organization: IOrganization) => void;
    editClick: (e: React.MouseEvent<any>, organization: IOrganization) => void;
    viewOrg: (organization: IOrganization) => void;
    subscriptionClick: (e: React.MouseEvent<any>, organization: IOrganization) => void;
}

const useStyles = () => ({
    downloadIcon: css({
        cursor: "pointer",
    }),
    blockWithFilenameAndIcon: css({
        display: "flex",
        alignItems: "center",
    }),
    iconClass: css({ height: "100%", display: "flex", alignItems: "center" }),
});

export const OrganizationTableRow: React.FC<IOrganizationRow> = ({
    labelId,
    row,
    viewOrg,
    editClick,
    deleteClick,
    subscriptionClick,
}) => {
    const rowClasses = { ...useStylesForRowInTable(), ...useStyles() };
    const isDemoMode = useSelector(demoModeSelector);
    const navigate = useNavigate();

    const viewOrganization = () => {
        viewOrg(row);
        navigate(`/org/${row.code}${getDefaultRedirectPath(row)}`);
    };

    return (
        <TableRow
            key={row.id}
            className={`${rowClasses.bodyRow} ${cursor("pointer")}`}
            hover
            tabIndex={-1}
            onClick={handleClickWithTextSelectionCheck(viewOrganization)}
        >
            <TableCell
                className={rowClasses.tableCell}
                component="th"
                scope="row"
                id={labelId}
                sx={{
                    fontSize: "15px",
                }}
            >
                <Stack direction="row" gap={1} alignItems="center">
                    <CustomAvatar
                        avatarUrl={row.avatarMediumUrl}
                        avatarText={row.displayName?.[0] || row.name?.[0]}
                        size="32px"
                        sx={{ fontWeight: 400, fontSize: "14px" }}
                    />
                    <span>{row.displayName ? row.displayName.split("_").join(" ") : ""}</span>
                </Stack>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="left">
                <span>{row.id}</span>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="left">
                <span>{OrganizationState[row.dataState as keyof typeof OrganizationState]}</span>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="left">
                <span>
                    {row.subscription?.status
                        ? SubscriptionStatus[
                              (row.subscription.status || "").toUpperCase() as keyof typeof SubscriptionStatus
                          ]
                        : ""}
                </span>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="left">
                <span>{row.customerSuccessManager?.email}</span>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="right">
                <span>{row.membersCount || 0}</span>
            </TableCell>
            <TableCell className={rowClasses.tableCell} align="right">
                <span>{row.connectorsCount || 0}</span>
            </TableCell>
            <TableCell align="right" className={rowClasses.tableCell}>
                {format(parseISO(row.createdAt), "MM/dd/yyyy")}
            </TableCell>
            {!isDemoMode && (
                <TableActionCell
                    actionIcons={[
                        { title: "Edit", onClick: editClick, data: row, icon: "editIcon2" },
                        {
                            title: row.subscription ? "Edit Subscription" : "Add Subscription",
                            onClick: subscriptionClick,
                            data: row,
                            icon: "card",
                            strokeColor: row.subscription ? "#0B9786" : "black",
                        },
                        { title: "Delete", onClick: deleteClick, data: row, icon: "trashIcon" },
                    ]}
                />
            )}
        </TableRow>
    );
};
