import { FC, useEffect, useState } from "react";
import { Autocomplete, Checkbox, TableCell, TableRow, TextField, Typography } from "@mui/material";
import { Stack } from "@mui/system";

import { imageContainerClass } from "src/consts/StylesForTables";
import { CAMPAIGN_TACTICS_HEADER } from "src/consts/campaignTacticsPage/campaignTactics";
import { IDimCampaign } from "src/interfaces/entities/IDimCampaign";
import { ICampaignTacticTypes } from "src/interfaces/entities/ICampaignTacticTypes";
import { DataSourceAvatar } from "src/components/DataSourceAvatar/DataSourceAvatar";
import { handleClickWithTextSelectionCheck } from "src/services/utils";

interface ICampaignTacticsTableRow {
    row: IDimCampaign & { cleanChannelName: string };
    getSelectedTactics: (tacticId?: string) => ICampaignTacticTypes | null;
    tacticsType: ICampaignTacticTypes[];
    handleTacticSelectionChange: (
        e: React.SyntheticEvent<Element, Event>,
        newValue: ICampaignTacticTypes | null,
        row: IDimCampaign,
    ) => void;
    onTacticRowClick: (campaignId: string) => void;
    isSelected: boolean;
    labelId: string;
}

export const CampaignTacticsTableRow: FC<ICampaignTacticsTableRow> = ({
    row,
    getSelectedTactics,
    tacticsType,
    handleTacticSelectionChange,
    isSelected,
    labelId,
    onTacticRowClick,
}) => {
    const [selectedTactic, setSelectedTactic] = useState<ICampaignTacticTypes | null>(
        getSelectedTactics(row.campaignTacticTypeId?.toString()),
    );

    useEffect(() => {
        setSelectedTactic(getSelectedTactics(row.campaignTacticTypeId?.toString()));
    }, [row.campaignTacticTypeId]);

    const handleAutocompleteChange = (
        e: React.SyntheticEvent<Element, Event>,
        newValue: ICampaignTacticTypes | null,
    ) => {
        e.stopPropagation();
        setSelectedTactic(newValue);
        handleTacticSelectionChange(e, newValue, row);
    };

    return (
        <TableRow
            onClick={(e) => handleClickWithTextSelectionCheck(onTacticRowClick)(row.campaignId)}
            selected={isSelected}
            aria-checked={isSelected}
            tabIndex={-1}
            sx={{ cursor: "pointer" }}
            hover
        >
            <TableCell padding="checkbox">
                <Checkbox
                    color="primary"
                    checked={isSelected}
                    inputProps={{
                        "aria-labelledby": labelId,
                    }}
                />
            </TableCell>
            {CAMPAIGN_TACTICS_HEADER.map((header, i) => {
                return (
                    <TableCell key={i}>
                        {header.id === "channel" && row.channel ? (
                            <Stack gap="8px" direction="row" alignItems="center" justifyContent="flex-start">
                                <DataSourceAvatar
                                    className={imageContainerClass}
                                    programmaticName={row.channel.toLowerCase()}
                                />

                                <Typography variant="body2">{row.cleanChannelName}</Typography>
                            </Stack>
                        ) : (
                            row[header.id]
                        )}
                    </TableCell>
                );
            })}
            <TableCell
                sx={{ display: "flex", justifyContent: "center" }}
                onClick={(e) => {
                    e.stopPropagation();
                }}
                onMouseDown={(e) => {
                    e.stopPropagation();
                }}
            >
                <Autocomplete
                    sx={{ width: 250 }}
                    size="small"
                    options={tacticsType}
                    getOptionLabel={(option: ICampaignTacticTypes) => option.displayName}
                    value={selectedTactic}
                    renderInput={(params) => <TextField {...params} />}
                    onChange={handleAutocompleteChange}
                />
            </TableCell>
        </TableRow>
    );
};
