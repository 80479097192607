import React, { useCallback, useEffect, useState } from "react";
import { FormikHelpers } from "formik";
import { useSnackbar } from "notistack";
import { Typography } from "@mui/material";
import uniqueId from "lodash/uniqueId";
import { useDispatch, useSelector } from "react-redux";

import { PageHeader } from "../../components/PageHeader/PageHeader.v2";
import { IAnnouncement } from "../../interfaces/entities/IAnnouncement";
import { AnnouncementModalForm } from "../../components/AnnouncementComponents/AnnouncementModalForm/AnnouncementModalForm";
import { useCreateAnnouncementsMutation, useLazyGetAnnouncementsQuery } from "../../reduxState/apis/announcementApi";
import { useEditAnnouncementsMutation } from "../../reduxState/apis/announcementApi";
import { useDeleteAnnouncementsMutation } from "../../reduxState/apis/announcementApi";
import { PageContainer } from "../../components/PageContainer/PageContainer";
import { AnnouncementTable } from "../../components/AnnouncementComponents/AnnouncementTable/AnnouncementTable";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import {
    announcementCountSelector,
    announcementsSelector,
    deleteAnnouncementById,
    editAnnouncement,
    setAnnouncementIds,
} from "../../reduxState/slices/announcementSlice";

const initialAnnouncement = {
    id: "",
    title: "",
    description: "",
    link: "",
    releaseDate: "",
};

export default function Announcement() {
    const { enqueueSnackbar } = useSnackbar();
    const dispatch = useDispatch();
    const announcements = useSelector(announcementsSelector);
    const announcementIds = useSelector(announcementCountSelector);
    const [announcementList, setAnnouncementList] = useState<IAnnouncement[]>(announcements);

    const [isCreateOrEditModalOpen, setCreateOrEditModalOpen] = useState<boolean>(false);
    const [announcement, setAnnouncement] = useState<IAnnouncement | null>(null);
    const [isFormEditMode, setIsFormEditMode] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

    const [createAnnouncements] = useCreateAnnouncementsMutation();
    const [editAnnouncements] = useEditAnnouncementsMutation();
    const [deleteAnnouncements, { isLoading: isDeleteLoading }] = useDeleteAnnouncementsMutation();
    const [getAnnouncements] = useLazyGetAnnouncementsQuery();

    useEffect(() => {
        setAnnouncementList(announcements);
    }, [announcements]);

    const openCreateModal = useCallback(() => {
        setAnnouncement(initialAnnouncement);
        setCreateOrEditModalOpen(true);
    }, []);

    const openEditModal = (announcement: IAnnouncement) => {
        setAnnouncement(announcement);
        setIsFormEditMode(true);
        setCreateOrEditModalOpen(true);
    };

    const openDeleteModal = (announcement: IAnnouncement) => {
        setAnnouncement(announcement);
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const handleCloseModal = () => {
        setCreateOrEditModalOpen(false);
        setIsDeleteModalOpen(false);
        setIsFormEditMode(false);
        setAnnouncement(null);
    };

    const handleCreateOrEditSubmit = async (values: IAnnouncement, formikHelpers: FormikHelpers<IAnnouncement>) => {
        let apiResponse: any;
        if (!values.id) {
            apiResponse = createAnnouncements({
                body: {
                    ...values,
                },
            });
        } else {
            apiResponse = editAnnouncements({
                params: { id: +values.id },
                body: {
                    ...values,
                },
            });
        }
        await apiResponse
            .then((response: any) => {
                if (values.id) {
                    dispatch(editAnnouncement(values));
                } else {
                    getAnnouncements({});
                    dispatch(setAnnouncementIds([...announcementIds, response.data.id]));
                }
            })
            .finally(() => {
                formikHelpers.setSubmitting(false);
                setCreateOrEditModalOpen(true);
                handleCloseModal();
            });
    };

    const deleteAnnouncementAction = async (announcement: IAnnouncement) => {
        if (announcement.id) {
            await deleteAnnouncements(announcement.id)
                .then(() => {
                    dispatch(deleteAnnouncementById(announcement.id));
                    dispatch(setAnnouncementIds(announcementIds.filter((id) => id !== announcement.id)));
                })
                .catch((error) => {
                    enqueueSnackbar("Something went wrong!", {
                        id: uniqueId(),
                        variant: "error",
                    });
                    throw new Error(`Delete scenario error ${error}`);
                })
                .finally(() => {
                    handleCloseModal();
                });
        }
    };

    return (
        <div>
            <PageHeader pageHeading="Announcement" buttonName="Add Announcement" onAdd={openCreateModal} />
            {isCreateOrEditModalOpen && (
                <AnnouncementModalForm
                    isOpen={isCreateOrEditModalOpen}
                    announcement={announcement}
                    onCloseModal={handleCloseModal}
                    isFormEditMode={isFormEditMode}
                    onSubmit={handleCreateOrEditSubmit}
                />
            )}
            <PageContainer>
                <AnnouncementTable
                    announcements={announcementList}
                    onEditAnnouncement={openEditModal}
                    onDeleteAnnouncement={openDeleteModal}
                />
            </PageContainer>
            {isDeleteModalOpen && announcement && (
                <CustomModal
                    title="Delete Announcement?"
                    subTitle=""
                    details={
                        <Typography
                            component="li"
                            variant="body2"
                            sx={{ textIndent: "unset!important", marginTop: "-20px" }}
                        >
                            Are you sure want to delete <b>{announcement.title}</b> announcement?
                        </Typography>
                    }
                    buttonName="Delete"
                    handleAction={deleteAnnouncementAction}
                    selectedConnector={announcement}
                    closeModal={handleCloseModal}
                    disableBtn={isDeleteLoading}
                    type="error"
                />
            )}
        </div>
    );
}
