import { keysToCamelCase } from "src/utils/format";
import baseCreateApi from "./baseCreateApi";

export const onboardingStates = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        editOnboardingStates: builder.mutation({
            query: (args: { onboardingStates: Record<string, string>; stateId: number; orgId: string }) => ({
                url: `organizations/${args.orgId}/onboarding_states/${args.stateId}`,
                method: "PUT",
                body: JSON.stringify({
                    data: {
                        type: "onboarding_states",
                        attributes: args.onboardingStates,
                    },
                }),
            }),
            transformResponse: (response: any) => {
                return keysToCamelCase({
                    id: response.data.id,
                    ...response.data.attributes,
                });
            },
        }),
    }),
});

export const { useEditOnboardingStatesMutation } = onboardingStates;
