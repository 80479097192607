import { IGetUserEventResponse, IUserEventData } from "src/interfaces/api/IGetUserEventResponse";
import baseCreateApi from "./baseCreateApi";

export const userEvent = baseCreateApi.injectEndpoints({
    endpoints: (builder) => ({
        addUserEvent: builder.mutation({
            query: (data: { userId: string; eventName: string }) => ({
                url: `user_events`,
                method: "POST",
                body: JSON.stringify({
                    data: {
                        attributes: {
                            user_id: data.userId,
                            event: data.eventName,
                        },
                        type: "user_events",
                    },
                }),
            }),
            invalidatesTags: ["UserEvents"],
        }),
        getUserEvents: builder.query({
            query: () => ({
                url: `user_events`,
                method: "GET",
            }),
            transformResponse: (response: IGetUserEventResponse): IUserEventData[] => {
                return response.data;
            },
            providesTags: ["UserEvents"],
        }),
    }),
});

export const { useAddUserEventMutation, useLazyGetUserEventsQuery, useGetUserEventsQuery } = userEvent;
