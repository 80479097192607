import { Stack, keyframes } from "@mui/system";
import { Typography } from "@mui/material";

export const HomePagePartialCard = ({
    icon,
    image,
    heading,
    description,
    actionButton,
    style = {},
}: {
    icon?: JSX.Element;
    image?: string;
    heading: string;
    description: string | JSX.Element;
    actionButton: JSX.Element;
    style?: any;
}) => {
    const iconKeyFrame = keyframes`
        0%, 100% {
            transform: scale(1);
            opacity: 100%;
        }
        50% {
            transform: scale(0.625); /* 35px / 56px = 0.625 */
            opacity: 70%;
        }
    `;

    const containerKeyFrames = keyframes`
        0%, 100%  {
            background: #F8F8F8;
        }
        50% {
            background: #EAEAEA;
        }
    `;

    const imageKeyframes = keyframes`
        0%, 100% {
            background-position: right;
        }
        50% {
            background-position: left;
        }
    `;

    const animation = image
        ? {
              animation: `${containerKeyFrames} 6s infinite`,
              animationPlayState: "paused",
              "& .image": {
                  animation: `${imageKeyframes} 6s ease-in-out infinite`,
                  animationPlayState: "paused",
              },
              "&:hover": {
                  animationPlayState: "running",
                  "& .image": {
                      animationPlayState: "running",
                  },
              },
          }
        : {
              animation: `${containerKeyFrames} 2s infinite`,
              animationPlayState: "paused",
              ".animationIcon svg": {
                  animation: `${iconKeyFrame} 2s infinite`,
                  flexShrink: 0,
                  animationPlayState: "paused",
              },
              "&:hover": {
                  animationPlayState: "running",
                  ".animationIcon svg": {
                      animationPlayState: "running",
                  },
              },
          };

    return (
        <Stack
            justifyContent="center"
            alignItems="center"
            gap={3}
            alignSelf="stretch"
            width="100%"
            sx={{
                ...style,
                background: "#F8F8F8",
                ...animation,
            }}
        >
            <Stack gap={2} alignItems="center">
                <Stack gap={image ? 3 : 1} justifyContent="center" alignItems="center">
                    {image ? (
                        <div
                            className="image"
                            style={{
                                width: "301px",
                                height: "301px",
                                border: "5px solid var(--primary-main, #429685)",
                                borderRadius: "422px",
                                background: `url(${image}) lightgray -234.587px 0px / 177.936% 100% no-repeat`,
                                boxShadow: "0px 0px 4px 7px rgba(0, 0, 0, 0.47) inset",
                                backgroundPosition: "100% 50%",
                            }}
                        ></div>
                    ) : (
                        <Stack
                            flexShrink={0}
                            alignItems="center"
                            justifyContent="center"
                            sx={{ width: "56px", height: "56px", animation: "none" }}
                            className="animationIcon"
                        >
                            {icon}
                        </Stack>
                    )}
                    <Typography variant="h2" color="text.secondary" textAlign="center">
                        {heading}
                    </Typography>
                </Stack>
                <Typography width="500px" variant="body2" color="text.secondary" textAlign="center">
                    {description}
                </Typography>
                {actionButton}
            </Stack>
        </Stack>
    );
};
