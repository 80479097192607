import { HelpCenter } from "../enums/HelpCenter";

export const HELP_CENTER_VALUE = [
    {
        title: "Quick start",
        notionPageId: "Quick-Start-dd2704d714d84a5b863a1c17dd1e5f12",
        description: "Want to get started ASAP?",
        icon: HelpCenter.QUICK_START,
    },
    {
        title: "Glossary",
        notionPageId: "Prescient-Glossary-78a96b8139a7480bbd112e700a2f4015",
        description: "Definitions of some of the language used throughout the platform.",
        icon: HelpCenter.GLOSSARY,
    },
    {
        title: "Full onboarding guide",
        notionPageId: "Full-Onboarding-Guide-96c4e8ea76df4accb2fcd865c224896f",
        description: "Step-bv-step videos for everything you need to start using Prescient.",
        icon: HelpCenter.FULL_GUIDE,
    },
    {
        title: "FAQs",
        notionPageId: "FAQs-409694f5a23b4f27ba40341d649cb866",
        description: "Questions? Check this out first.",
        icon: HelpCenter.FAQS,
    },
    {
        title: "Advice",
        notionPageId: "Advice-5d0b93a823fa434a8f007091b2c760b4",
        description: "Some advice from our team before you get started.",
        icon: HelpCenter.ADVICES,
    },
    // {
    //     title: "Guides",
    //     notionPageId: "Guides-48f92b9740f148e8bd297078a29ffe10",
    //     description: "How To's for Prescient's core features,",
    //     icon: HelpCenter.GUIDES,
    // },
];

export const GETTING_STARTED_URL = "Getting-Started-With-Prescient-9593a23a71244fe6b6644402dc220de9";
