import { Typography } from "@mui/material";
import React from "react";
import { LightModal } from "src/components/LightModal/LightModal";

interface IFixedConnectorModalProps {
    isFixedModalOpen: boolean;
    handleModalClose: () => void;
    connectorName: string | null;
}

export const FixedConnectorModal: React.FC<IFixedConnectorModalProps> = ({
    isFixedModalOpen,
    handleModalClose,
    connectorName,
}) => {
    return connectorName ? (
        <LightModal
            isOpen={isFixedModalOpen}
            onClose={handleModalClose}
            title={`${connectorName} Has Been Re-Connected`}
            buttonName="Okay"
            onSubmit={handleModalClose}
            content={
                <div style={{ maxWidth: "552px" }}>
                    <Typography variant="body1" mb={2}>
                        {`${connectorName} is now active. The data interruption has been resolved.`}
                    </Typography>
                </div>
            }
        />
    ) : null;
};
