import { createSelector, createSlice } from "@reduxjs/toolkit";
import { ICampaignTacticTypes } from "../../interfaces/entities/ICampaignTacticTypes";
import { RootState } from "../stores/store";

export interface ITacticState {
    all: ICampaignTacticTypes[];
    byId: { [key: string]: ICampaignTacticTypes };
}

const initialState: ITacticState = {
    all: [],
    byId: {},
};

const tacticsSlice = createSlice({
    name: "tactics",
    initialState,
    reducers: {
        setTactics: (state, { payload }) => {
            return {
                all: payload,
                byId: payload.reduce(
                    (acc: any, item: ICampaignTacticTypes) => Object.assign(acc, { [item.id]: item }),
                    {},
                ),
            };
        },
    },
});

export const { setTactics } = tacticsSlice.actions;

export default tacticsSlice.reducer;

// Selectors
export const connectorsByTacticSelector = (state: RootState) => state.tactics;

export const selectTacticById = createSelector(
    [connectorsByTacticSelector, (state, tacticId) => tacticId],
    (connectorsByTacticSelector, tacticId) => {
        return connectorsByTacticSelector.byId[tacticId];
    },
);

export const getAllTactics = createSelector([connectorsByTacticSelector], (connectorsByTacticSelector) => {
    return connectorsByTacticSelector.all;
});
