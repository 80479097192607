import { AnyAction } from "@reduxjs/toolkit";
import { IOrganization } from "../../interfaces/entities/IOrganization";
import { IAnalyticsManager } from "./IAnalyticsManager";
import { IActionListeners, IActionListenerFunction } from "../../interfaces/analytics/IActionListeners";
import { getPathForAnalytics } from "../utils";

const setUserProps: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { payload } = action;
    analytics.login(payload);
};

const setAllOrganizationsProps: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { payload } = action;
    const companyNames = payload.map((org: IOrganization) => org.name);

    if (companyNames.length === 0) {
        return;
    }

    analytics.setUserProperties({
        "All organizations": companyNames,
    });
};

const logoutUserEvent: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    analytics.logEvent("Logged Out");
    analytics.logout();
};

const loginErrorEvent: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { error } = action;
    analytics.logEvent("Log-in Attempt Failed", { "Error message": error.details });
};

const bypassEcommerceStepEvent: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { page, connectorCount } = action.payload;
    analytics.logEvent("Onboarding Google Analytics Bypass Clicked", {
        Page: getPathForAnalytics(page),
        "Connector count": connectorCount,
    });
};

const crudPerfomanceViewEvent: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { page, eventName, viewName } = action.payload;
    analytics.logEvent(eventName, {
        Page: page,
        "View name": viewName,
    });
};

const duplicateConnectorWarningEvent: IActionListenerFunction =
    (analytics: IAnalyticsManager) => (action: AnyAction) => {
        const { page, connectorType, connectorName } = action.payload;
        analytics.logEvent("Duplicate Connector Warning Displayed", {
            Page: getPathForAnalytics(page),
            "Connector type": connectorType,
            "Connector name": connectorName,
        });
    };

const exportPerformanceTableEvent: IActionListenerFunction = (analytics: IAnalyticsManager) => (action: AnyAction) => {
    const { page, allColumns } = action.payload;
    analytics.logEvent("Performance Data Exported", {
        Page: page,
        "All columns": allColumns,
    });
};

export default (analytics: IAnalyticsManager): IActionListeners => ({
    "user/setUser": setUserProps(analytics),
    "organizations/setOrganizationsOnSignIn": setAllOrganizationsProps(analytics),
    logoutUser: logoutUserEvent(analytics),
    loginError: loginErrorEvent(analytics),
    bypassEcommerceStep: bypassEcommerceStepEvent(analytics),
    crudPerfomanceView: crudPerfomanceViewEvent(analytics),
    duplicateConnectorWarning: duplicateConnectorWarningEvent(analytics),
    exportPerformanceTable: exportPerformanceTableEvent(analytics),
});
