import React, { useEffect, useState } from "react";
import { OverridableComponent } from "@mui/material/OverridableComponent";
import { Chip, SvgIconTypeMap, Theme, useTheme } from "@mui/material";
import { Stack } from "@mui/system";
import { css } from "@emotion/css";
import cloneDeep from "lodash/cloneDeep";

import { BACKGROUND_FOR_DEFAULT_CHIPS, BLACK_COLOR_OPACITY_60 } from "../../../consts/colors";
import doneIcon from "../../../assets/doneIcon.png";
import { useAnalyticsService } from "../../../services/analytics/useAnalyticsService";
import { getPathForAnalytics } from "../../../services/utils";

export interface IChipsProps {
    items: IItem[];
    itemsLength?: number;
    icon?: string;
    dismissible?: boolean;
    counter?: boolean;
    callback: any;
    multiple?: boolean;
    disabled?: boolean;
    checkedItem?: number;
    isMargin?: boolean;
    isEventPerform?: boolean;
    chipUpdated?: boolean;
}

export interface IItem {
    key: number | string;
    label: string;
    isAll: boolean;
    count?: number;
    disabled?: boolean;
    icon?: OverridableComponent<SvgIconTypeMap> & {
        muiName: string;
    };
}

const useStyles = (theme: Theme, isMargin: boolean, chipUpdated: boolean) => ({
    chipStyle: css(
        chipUpdated
            ? {
                  marginRight: "16px",
                  height: "32px",
              }
            : {
                  width: "fit-content",
                  height: "32px",
                  borderRadius: "18px",
                  fontSize: "13px",
                  lineHeight: "18px",
                  padding: "4px",
                  margin: isMargin ? "24px 8px 24px 0" : "0 8px 0 0",
                  [theme.breakpoints.down("md")]: {
                      margin: "0 0.8em 0.8em 0 !important",
                  },
              },
    ),
    chipDefaultStyle: css(
        chipUpdated
            ? {
                  marginRight: "16px",
              }
            : {
                  backgroundColor: BACKGROUND_FOR_DEFAULT_CHIPS,
                  color: BLACK_COLOR_OPACITY_60,
                  border: "none",
                  margin: isMargin ? "24px 8px 24px 0" : "0 8px 0 0",
                  [theme.breakpoints.down("md")]: {
                      margin: "0 0.8em 0.8em 0 !important",
                  },
              },
    ),
    chipSelectedStyle: css({
        backgroundColor: "black",
        color: "red",
    }),
    iconStyle: css({
        width: "18px",
        height: "18px",
        marginLeft: "10px !important",
    }),
});

const Chips: React.FC<IChipsProps> = ({
    items,
    icon,
    counter,
    dismissible,
    callback,
    itemsLength,
    multiple,
    disabled,
    checkedItem,
    isMargin = true,
    isEventPerform = true,
    chipUpdated = false,
}) => {
    const theme = useTheme();
    const { chipStyle, chipDefaultStyle, chipSelectedStyle, iconStyle } = useStyles(theme, isMargin, chipUpdated);

    const [activeChip, setActiveChip] = useState({
        items: [
            checkedItem
                ? checkedItem
                : items.filter((item) => item.isAll)[0] && items.filter((item) => item.isAll)[0].key,
        ],
    });
    const [activeSingleChip, setActiveSingleChip] = useState({
        items: checkedItem
            ? checkedItem
            : items.filter((item) => item.isAll)[0] && items.filter((item) => item.isAll)[0].key,
    });
    const analyticsService = useAnalyticsService();

    useEffect(() => {
        setVisibleItems({ items });
    }, [items]);

    useEffect(() => {
        setActiveChip({
            items: [
                checkedItem
                    ? checkedItem
                    : items.filter((item) => item.isAll)[0] && items.filter((item) => item.isAll)[0].key,
            ],
        });
        setActiveSingleChip({
            items: checkedItem
                ? checkedItem
                : items.filter((item) => item.isAll)[0] && items.filter((item) => item.isAll)[0].key,
        });
    }, [checkedItem]);

    const [visibleItems, setVisibleItems] = useState({ items });

    const handleMultipleClick = (item: IItem) => {
        if (isEventPerform) {
            analyticsService.logEvent("Performance Attribution Table Filter Applied", {
                Page: getPathForAnalytics(location.pathname),
                Filter: item.label,
            });
        }
        const copyActiveChip = cloneDeep(activeChip);
        if (copyActiveChip.items.indexOf(item.key) >= 0) {
            copyActiveChip.items.splice(copyActiveChip.items.indexOf(item.key), 1);
            const all = items.find((item) => item.isAll);

            if (copyActiveChip.items.length === 0 && all) {
                copyActiveChip.items.push(all.key);
            }

            setActiveChip(copyActiveChip);
            callback(copyActiveChip.items);
        } else {
            const all = items.find((item) => item.isAll);

            if (!item.isAll && all && activeChip.items.indexOf(all.key) >= 0) {
                copyActiveChip.items.splice(activeChip.items.indexOf(all.key), 1);
            }
            if (item.isAll) {
                copyActiveChip.items = [item.key];
            } else {
                copyActiveChip.items.push(item.key);
            }
            setActiveChip(copyActiveChip);
            callback(copyActiveChip.items);
        }
    };

    const handleSingleClick = (item: IItem) => {
        if (isEventPerform) {
            analyticsService.logEvent("Performance Attribution Table Filter Applied", {
                Page: getPathForAnalytics(location.pathname),
                Filter: item.label,
            });
        }
        if (activeSingleChip.items === item.key) {
            const all = items.find((item) => item.isAll);
            if (all) {
                setActiveSingleChip({ items: all.key });
                callback(all.key);
            }
        } else {
            setActiveSingleChip({ items: item.key });
            callback(item.key);
        }
    };

    const handleDelete = (item: IItem) => {
        const copyVisibleItems = cloneDeep(visibleItems);
        copyVisibleItems.items.splice(copyVisibleItems.items.indexOf(item), 1);
        setVisibleItems(copyVisibleItems);
    };

    return (
        <Stack flexDirection="row" flexWrap="wrap" gap={1}>
            {visibleItems.items.length > 0 &&
                visibleItems.items.map((item) => (
                    <React.Fragment key={item.key}>
                        <Chip
                            disabled={itemsLength === 0 ? true : (!item.isAll && disabled) || item.disabled}
                            className={
                                (
                                    multiple
                                        ? activeChip.items.indexOf(item.key) >= 0
                                        : activeSingleChip.items === item.key
                                )
                                    ? chipSelectedStyle && chipStyle
                                    : chipStyle && chipDefaultStyle
                            }
                            label={
                                counter
                                    ? `${item.label} ${items.indexOf(item) + 1}`
                                    : item.count
                                    ? `${item.label} (${item.count})`
                                    : item.label
                            }
                            key={item.key}
                            clickable
                            onClick={multiple ? () => handleMultipleClick(item) : () => handleSingleClick(item)}
                            onDelete={() => handleDelete(item)}
                            deleteIcon={
                                dismissible ? (
                                    <img
                                        alt="doneIcon"
                                        src={doneIcon}
                                        style={{ filter: "invert(10%) sepia(85%) saturate(3)" }}
                                    />
                                ) : (
                                    <></>
                                )
                            }
                            variant="outlined"
                            color={
                                chipUpdated
                                    ? multiple && activeChip.items.indexOf(item.key) >= 0
                                        ? "secondary"
                                        : "default"
                                    : "secondary"
                            }
                            icon={item.icon ? <item.icon className={iconStyle} /> : <></>}
                        />
                    </React.Fragment>
                ))}
        </Stack>
    );
};

export default Chips;
