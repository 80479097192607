import { css } from "@emotion/css";
import { Box, Button, Typography } from "@mui/material";
import { useTheme } from "@mui/system";
import uniqueId from "lodash/uniqueId";
import { useSnackbar } from "notistack";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { FixedConnectorModal } from "src/components/ConnectorsComponent/Modals/FixedConnectorModal";
import { SVGIconRenderer } from "src/components/SVGIconRenderer/SVGIconRenderer";
import noConnectors from "../../assets/noConnectors.png";
import wallet from "../../assets/wallet.png";
import { ConnectorsTable } from "../../components/ConnectorsComponent/ConnectorsTable/ConnectorsTable";
import { PurgeConnectorModal } from "../../components/ConnectorsComponent/Modals/PurgeConnectorModal";
import { ResyncConnectorModal } from "../../components/ConnectorsComponent/Modals/ResyncConnectorModal";
import { CustomModal } from "../../components/CustomModal/CustomModal";
import { LightModal } from "../../components/LightModal/LightModal";
import { Message } from "../../components/Message/Message";
import ProcessFailed from "../../components/ProcessFailed/ProcessFailed";
import { SubHeader, useStylesForSubHeader } from "../../components/SubHeader/SubHeader.v2";
import Loader from "../../components/core/Loader/Loader";
import { CONNECTORS_PATH, CUSTOM_CREATE_CONNECTOR_PATH } from "../../consts/path/path";
import { ConnectorStatus, IConnector } from "../../interfaces/IConnector";
import {
    STATUS_MAPPING,
    useDeleteConnectorMutation,
    useHistoricalResyncConnectorMutation,
    useLazyGetConnectorsQuery,
    usePurgeConnectorMutation,
    useReconnectConnectorMutation,
} from "../../reduxState/apis/connectorsApi";
import { connectorsSelector, setConnector } from "../../reduxState/slices/connectorsSlice";
import {
    selectCurrentlyViewingCode,
    selectCurrentlyViewingId,
    selectCurrentlyViewingOrgIsPaused,
} from "../../reduxState/slices/organizationSlice";
import { supportedDataSourcesSelector } from "../../reduxState/slices/supportedDataSourcesSlice";
import { useAnalyticsService } from "../../services/analytics/useAnalyticsService";
import {
    getConnectorsChipsValues,
    getFilters,
    getPreparedConnectors,
    isCustomConnector,
} from "../../services/connectors/connectors";
import { getPathForAnalytics } from "../../services/utils";

const useStyles = () => ({
    container: css({
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        gap: 0,
    }),
});

export enum ConnectorView {
    YOUR_CONNECTORS = "your_connectors",
    AVAILABLE_CONNECTORS = "available_connectors",
    ALL = "all",
}

const Connectors: React.FC = () => {
    const classes = useStyles();
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const { enqueueSnackbar } = useSnackbar();

    const [search, setSearch] = useState<string>("");
    const [selectedConnector, setSelectedConnector] = useState<IConnector | null>(null);

    const [isResyncConnectorModalOpen, setIsResyncConnectorModalOpen] = useState(false);
    const [isPurgeConnectorModalOpen, setIsPurgeConnectorModalOpen] = useState<boolean>(false);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
    const [filter, setFilter] = useState<number[]>([0]);
    const [errorModal, setErrorModal] = useState<boolean>(false);
    const [disableDeleteBtn, setDisableDeleteBtn] = useState<boolean>(false);

    const [getConnectors, { isLoading }] = useLazyGetConnectorsQuery();
    const [deleteConnectors, { error: deleteError }] = useDeleteConnectorMutation();
    const [purgeConnector, { isLoading: purgeLoading }] = usePurgeConnectorMutation();
    const [historicalResyncConnector, { isLoading: resyncLoading }] = useHistoricalResyncConnectorMutation();
    const [reconnectConnector] = useReconnectConnectorMutation();
    const [fixedConnector, setFixedConnector] = useState<string | null>(null);

    const connectors = useSelector(connectorsSelector);
    const orgId = useSelector(selectCurrentlyViewingId);
    const orgCode = useSelector(selectCurrentlyViewingCode);
    const analyticsService = useAnalyticsService();
    const searchParam = useLocation().search;
    const supportedDataSources = useSelector(supportedDataSourcesSelector);
    const isDataFlowPaused = useSelector(selectCurrentlyViewingOrgIsPaused);
    const [dataFlowPausedModal, setDataFlowPausedModal] = useState(isDataFlowPaused);

    useEffect(() => {
        setDataFlowPausedModal(isDataFlowPaused);
        getConnectors(orgId).then((res) => {
            const connectors = res.data;
            if (searchParam && connectors?.length) {
                const params = new URLSearchParams(searchParam);
                const id = params.get("id");
                const newConnector = connectors.filter((connector: any) => connector.fivetranId === id);

                if (newConnector.length) {
                    const reconnectConnector = localStorage.getItem("reconnectConnector");
                    analyticsService.logEvent(`Connector ${reconnectConnector ? "Reconnected" : "Created"} `, {
                        Page: getPathForAnalytics(window.location.pathname),
                        "Connector categories": newConnector[0].dataSource.categories,
                        "Connector name": newConnector[0].connectorName,
                    });
                }
                localStorage.removeItem("reconnectConnector");
                navigate(`/org/${orgCode}${CONNECTORS_PATH}`, { replace: true });

                const sentFromEmail =
                    params.get("utm_medium") === "email" && params.get("utm_source") === "broken_connector_email";
                const brokenConnectorId = params.get("connector_id");
                const fixed = connectors.find(
                    (connector) =>
                        connector.id.toString() === brokenConnectorId && connector.status !== STATUS_MAPPING.broken,
                );
                if (sentFromEmail && brokenConnectorId && fixed) {
                    setFixedConnector(fixed.connectorName);
                }
            }
        });
    }, [orgId]);

    useEffect(() => {
        analyticsService.logEvent("Connectors Page Viewed", {
            Page: getPathForAnalytics(location.pathname),
        });
    }, [location.pathname]);

    const closeModal = () => {
        setIsResyncConnectorModalOpen(false);
        setIsPurgeConnectorModalOpen(false);
        setIsDeleteModalOpen(false);
        setSelectedConnector(null);
    };

    const openResyncModal = (connector: IConnector) => {
        setIsResyncConnectorModalOpen(!isResyncConnectorModalOpen);
        setSelectedConnector(connector);
    };

    const openPurgeConnectorModal = (connector: IConnector) => {
        setIsPurgeConnectorModalOpen(!isPurgeConnectorModalOpen);
        setSelectedConnector(connector);
    };

    const openDeleteModal = (connector?: IConnector) => {
        if (connector) {
            setSelectedConnector(connector);
        }
        setIsDeleteModalOpen(!isDeleteModalOpen);
    };

    const deleteConnector = async (connector: IConnector) => {
        analyticsService.logEvent("Connector Deleted", {
            Page: getPathForAnalytics(location.pathname),
            "Connector categories": connector.categories,
            "Connector name": connector.connectorName,
        });
        setDisableDeleteBtn(true);
        deleteConnectors({
            id: connector.id.toString(),
            orgId,
        })
            .then(() => {
                enqueueSnackbar(`${connector.connectorName} deleted successfully.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to delete ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
                setErrorModal(true);
            })
            .finally(() => {
                setDisableDeleteBtn(false);
                closeModal();
            });
    };

    const purgeConnectorHandler = async (connector: IConnector) => {
        await purgeConnector({
            connectorId: connector.id.toString(),
            orgId,
        })
            .then(() => {
                enqueueSnackbar(`${connector.connectorName} purged successfully.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to purge ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    const resyncConnectorHandler = async (connector: IConnector) => {
        await historicalResyncConnector({
            connectorId: connector.id.toString(),
            orgId,
        })
            .unwrap()
            .then(() => {
                dispatch(
                    setConnector(
                        connectors.map((c: IConnector) => {
                            return connector.id === c.id
                                ? {
                                      ...c,
                                      status: STATUS_MAPPING.historical_syncing,
                                  }
                                : c;
                        }),
                    ),
                );
                enqueueSnackbar(`Historical resync started for ${connector.connectorName}.`, {
                    id: uniqueId(),
                    variant: "success",
                });
            })
            .catch(() => {
                enqueueSnackbar(`Failed to historical resync ${connector.connectorName}. Please try again later.`, {
                    id: uniqueId(),
                    variant: "error",
                });
            })
            .finally(() => {
                closeModal();
            });
    };

    const handleSearchChange = (item: string) => {
        setSearch(item);
    };

    const handleChangeFilterValue = (filterValue: number[]) => {
        setFilter(filterValue);
    };

    const handleErrorModalChange = (item: boolean) => {
        setErrorModal(item);
    };

    const closePausedDataFlowModal = () => {
        setDataFlowPausedModal(false);
    };

    const filteredConnectors = getPreparedConnectors(connectors, search, filter, supportedDataSources);

    const createNewConnHandler = useCallback(() => {
        navigate(`/org/${orgCode}${CONNECTORS_PATH}`);
    }, []);

    const handleEditConnector = (connector: IConnector) => {
        if (isCustomConnector(connector.service)) {
            navigate(
                `/org/${orgCode}${CUSTOM_CREATE_CONNECTOR_PATH}?connectorName=${connector.service}&connectorId=${
                    connector.id
                }&isConfigSet=${connector.status !== STATUS_MAPPING.incomplete}`,
                {
                    state: {
                        isConnected: connector.status === ConnectorStatus.CONNECTED,
                    },
                },
            );
        } else {
            reconnectConnector({
                orgId,
                connectorId: connector.id.toString(),
            })
                .unwrap()
                .then((response: { data: { fivetran_connect_url: string } }) => {
                    const path = window.location.origin + `/org/${orgCode}${CONNECTORS_PATH}`;
                    const redirectURL = `${response.data.fivetran_connect_url}${path}`;
                    window.open(redirectURL, "_self");
                })
                .catch((error) => {
                    throw new Error(`reconnect connector error ${error}`);
                });
        }
    };

    return (
        <div className={classes.container}>
            {isResyncConnectorModalOpen && selectedConnector && (
                <ResyncConnectorModal
                    connector={selectedConnector}
                    closeModal={closeModal}
                    resyncConnector={resyncConnectorHandler}
                    resyncLoading={resyncLoading}
                />
            )}
            {isPurgeConnectorModalOpen && selectedConnector && (
                <PurgeConnectorModal
                    connector={selectedConnector}
                    closeModal={closeModal}
                    purgeConnector={purgeConnectorHandler}
                    purgeLoading={purgeLoading}
                />
            )}
            {errorModal ? <ProcessFailed isOpen={errorModal} onClose={handleErrorModalChange} /> : null}
            {isDeleteModalOpen && selectedConnector && (
                <CustomModal
                    title="Delete Connector?"
                    subTitle={`Deleting your ${selectedConnector.connectorName} connector will:`}
                    details={
                        <>
                            <Typography component="li" variant="body2">
                                - Remove it from your dashboards
                            </Typography>
                            <Typography component="li" variant="body2">
                                - Prevent its data from being used in models
                            </Typography>
                            <Typography component="li" variant="body2">
                                - Require you to reconnect and re-sync if you want to access its data in Prescient again
                            </Typography>
                        </>
                    }
                    buttonName="Delete"
                    handleAction={deleteConnector}
                    selectedConnector={selectedConnector}
                    closeModal={closeModal}
                    disableBtn={disableDeleteBtn}
                    type="error"
                />
            )}
            <LightModal
                isOpen={dataFlowPausedModal}
                onClose={closePausedDataFlowModal}
                title="Paused data flow"
                buttonName="Ok"
                onSubmit={closePausedDataFlowModal}
                handleCancelButtonClick={closePausedDataFlowModal}
                content={
                    <Typography component="div">
                        Your data pipeline is paused. <br />
                        Please contact <a href="mailto:mike@prescient-ai.io">mike@prescient-ai.io</a> to rectify the
                        issue.
                    </Typography>
                }
            />
            <FixedConnectorModal
                connectorName={fixedConnector}
                isFixedModalOpen={!!fixedConnector}
                handleModalClose={() => setFixedConnector(null)}
            />
            {isLoading ? (
                <Loader />
            ) : (
                <>
                    <Box sx={{ display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center" }}>
                        <h2>Connectors</h2>
                        <Button
                            variant="contained"
                            color="primary"
                            className={useStylesForSubHeader(useTheme()).actionButton}
                            startIcon={
                                <SVGIconRenderer
                                    icon={"plusIcon"}
                                    strokeColor={isDataFlowPaused ? "rgba(0, 0, 0, 0.26)" : "white"}
                                />
                            }
                            onClick={createNewConnHandler}
                            disabled={isDataFlowPaused}
                            data-cy="addConnector"
                        >
                            Add Connector
                        </Button>
                    </Box>
                    <SubHeader
                        onSearchChange={handleSearchChange}
                        onChipsChange={handleChangeFilterValue}
                        title="item(s)"
                        chosenItemsLength={0}
                        chipsValues={getConnectorsChipsValues(connectors, supportedDataSources)}
                        itemsLength={filteredConnectors && filteredConnectors.length ? filteredConnectors.length : 0}
                        isEventPerform={false}
                    />
                    {!filteredConnectors.length ? (
                        isLoading ? (
                            <Loader />
                        ) : (
                            <Message
                                title={
                                    search
                                        ? "We didn’t find anything "
                                        : "You don’t have any data sources connected yet  "
                                }
                                subtitle={
                                    search
                                        ? "Please make sure you spelled everything correctly or try a different search term."
                                        : "Click the picture above to connect your first source!"
                                }
                                icon={search ? noConnectors : wallet}
                                filters={search ? getFilters(filter) : undefined}
                                onIconClick={!search ? createNewConnHandler : undefined}
                            />
                        )
                    ) : (
                        <ConnectorsTable
                            connectors={filteredConnectors}
                            purgeClick={openPurgeConnectorModal}
                            deleteClick={openDeleteModal}
                            resyncClick={openResyncModal}
                            editClick={handleEditConnector}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default Connectors;
