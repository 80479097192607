import { createSelector, createSlice } from "@reduxjs/toolkit";
import { IDataSource } from "../../interfaces/IData";
import { RootState } from "../stores/store";

const initialState: IDataSource[] = [];

const supportedDataSourcesByOrgSlice = createSlice({
    name: "supportedDataSourcesByOrg",
    initialState,
    reducers: {
        setSupportedDataSourcesByOrg: (state, { payload }) => payload,
    },
});

export const { setSupportedDataSourcesByOrg } = supportedDataSourcesByOrgSlice.actions;

export default supportedDataSourcesByOrgSlice.reducer;

// Selectors
export const orgSpecificDataSourcesSelector = (state: RootState) => state.supportedDataSourcesByOrg;

export const orgSpecificCleanDataSourcesSelector = createSelector([orgSpecificDataSourcesSelector], (state) =>
    state.filter((c) => c.deprecated === false),
);
