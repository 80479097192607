import React from "react";
import { Chip, Tooltip } from "@mui/material";
import { IMetricAttributionTableValuesTransformed } from "../../interfaces/performanceDetails/IMetricAttributionTableResponse";

interface INegativeReportedMetricTooltip {
    classes: any;
    performanceRow: IMetricAttributionTableValuesTransformed;
}

export const NegativeReportedMetricTooltip: React.FC<INegativeReportedMetricTooltip> = ({
    classes,
    performanceRow,
}) => {
    return (
        <Tooltip
            placement="top"
            arrow
            classes={{ tooltip: classes.tooltip }}
            title={
                <React.Fragment>
                    <b>No data found for this campaign.</b>
                    <br />
                    {performanceRow.channelName} didn’t report any revenue for this campaign over the selected time
                    period.
                </React.Fragment>
            }
        >
            <Chip component="span" label="No Data" variant="outlined" sx={{ width: "100%" }} />
        </Tooltip>
    );
};
