const {
    REACT_APP_BASE_URL,
    REACT_APP_SITE_URL,
    REACT_APP_BUGSNAG_API_KEY,
    REACT_APP_PRESCIENT_BACKEND_URL,
    REACT_APP_AMPLITUDE_ANALYTICS_KEY,
    REACT_APP_COMMIT_ID,
    REACT_APP_INTERCOM_ID,
    REACT_APP_DEPLOYMENT_NAME,
    REACT_APP_VITALLY_KEY,
    REACT_APP_VITALLY_API_URL,
} = process.env;

export const appVersion = REACT_APP_COMMIT_ID || "notprod";
export const baseEndpoint = REACT_APP_BASE_URL;
export const siteUrl = REACT_APP_SITE_URL;
export const amplitudeAnalyticsKey = REACT_APP_AMPLITUDE_ANALYTICS_KEY || "AMPLITUDE KEY NOT SET";
export const backendUrl = REACT_APP_PRESCIENT_BACKEND_URL || "https://dev-api.prescient-ai.io/api/";
export const intercomAppId = REACT_APP_INTERCOM_ID || "vdr4x5tf";
export const isCypressRunning = !!window.Cypress;

export const apiV2 = `${backendUrl}v2/`;

export const bugsnagApiKey = REACT_APP_BUGSNAG_API_KEY || "test123";
export const deploymentName = REACT_APP_DEPLOYMENT_NAME || "development";
export const vitallyApiKey = REACT_APP_VITALLY_KEY || "VITALLY KEY NOT SET"; // Please make sure not to send vitally events from dev to production
export const vitallyAPiUrl = REACT_APP_VITALLY_API_URL || "https://prescient-test.api.vitally.io/analytics/v1";
