import * as React from "react";
import { useSelector } from "react-redux";
import { PathRouteProps, Navigate } from "react-router-dom";
import ErrorBoundary from "../../errorBoundary/ErrorBoundary";
import { isSuperAdminSelector } from "../../reduxState/slices/userSlice";

interface ISuperAdminRouteProps extends PathRouteProps {
    children: any;
}

export const SuperAdminRoute: React.FC<ISuperAdminRouteProps> = ({ children }) => {
    const isSuperAdmin = useSelector(isSuperAdminSelector);
    return isSuperAdmin ? <ErrorBoundary>{children}</ErrorBoundary> : <Navigate to="/404" />;
};
